import { DraftRequestListingResponse } from '@/__generated';
import ZTable, { IColumnType, ITableRef } from '@/components/ZTable';
import RickTextInput from '@/components/app/formItem/RickTextInput';
import { formatDisplayFullDate } from '@/config';
import {
  ERROR_EXCEPTION,
  EmailRegex,
  FAX_NUMBER_VALIDATE,
  FaxNumberRegex,
  MAX_FILE_UPLOAD_SIZE,
  TourMessageType,
  VALIDATE_EMAIL,
  VALIDATE_REQUIRED,
} from '@/constants';
import { ApiSdk } from '@/httpclient';
import { fileService } from '@/services/fileService';
import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  SendOutlined,
  UploadOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Modal, Radio, Row, Space, Spin, Tooltip, Upload, message } from 'antd';
import { cloneDeep, sumBy } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

const faxIcon = require('@/assets/images/fax.png');
const mailIcon = require('@/assets/images/mail.png');

export type TourDraftRequestProps = {
  tourId: string;
  guideId: string;
  seisanNumber: string;
  tourName: string;
  guideName: string;
  onClose: () => void;
};

interface UploadFile {
  fileId: string;
  fileName: string;
}
const columns: IColumnType<DraftRequestListingResponse>[] = [
  {
    width: 70,
    title: 'tourMessagePage_Type',
    dataIndex: 'requestType',
    align: 'center',
    render: v => <img src={v === TourMessageType.EFax ? faxIcon : mailIcon} alt="request type" style={{ width: 20 }} />,
  },
  {
    width: 200,
    title: 'tourMessagePage_FacilityName',
    dataIndex: 'facilityName',
    ellipsis: true,
  },
  {
    width: 180,
    title: 'tourMessagePage_To',
    dataIndex: 'sendTo',
    ellipsis: true,
  },
  {
    title: 'tourMessagePage_Subject',
    dataIndex: 'subject',
    width: 400,
    ellipsis: true,
  },
  {
    width: 170,
    title: 'Created On',
    dataIndex: 'createdAt',
    render: v => formatDisplayFullDate(v),
  },
];
const Width = sumBy(columns, x => x.width || 0) + 150;

export default function TourDraftRequest({ tourId, onClose, guideId, guideName, seisanNumber, tourName }: TourDraftRequestProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [uploadedFiles, setUploadedFiles] = React.useState<UploadFile[]>([]);
  const [filePasswords, setFilePasswords] = React.useState<{ [fileId: string]: string }>({});
  const zTb = React.useRef<ITableRef>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [displayCreateForm, setDisplayCreateForm] = React.useState<boolean>(false);

  const onCopy = (record: DraftRequestListingResponse) => {
    setDisplayCreateForm(true);
    setUploadedFiles((record.attachments as any) || []);
    setFilePasswords(() => {
      let p: any = {};
      record.attachments?.forEach(file => {
        if (file.password && file.fileId) {
          p[file.fileId] = file.password;
        }
      });
      return p;
    });
    form.setFieldsValue({
      ...record,
      id: undefined,
      sendTo: '',
      facilityName: '',
    });
  };

  const createUpdateDraftMessage = (values: any) => {
    setLoading(true);
    let body = {
      ...values,
      sendTo: values.sendTo.replaceAll('-', '').replaceAll(' ', ''),
      attachmentFiles: uploadedFiles.map(x => ({ fileId: x.fileId, password: filePasswords[x.fileId] || undefined })) || [],
      guideId: guideId,
      tourId: tourId,
    };
    let api = values.id ? ApiSdk.DraftRequestService.update({ body }) : ApiSdk.DraftRequestService.create({ body: body });
    api
      .then(res => {
        if (res.success) {
          closeForm();
          zTb.current?.reload();
        } else {
          message.error(t(res.message || ERROR_EXCEPTION));
        }
      })
      .catch(() => {
        message.error(ERROR_EXCEPTION);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendMessage = (values: any) => {
    setLoading(true);
    ApiSdk.TourMessageService.sendMessage({
      body: {
        ...values,
        sendTo: values.sendTo.replaceAll('-', '').replaceAll(' ', ''),
        messageType: values.requestType,
        tourId: tourId,
        guideId: guideId,
        attachmentFiles: uploadedFiles.map(x => ({ fileId: x.fileId, password: filePasswords[x.fileId] || undefined })) || [],
        bodyContent: values.body,
      },
    })
      .then(res => {
        if (res.success) {
          closeForm();
          zTb.current?.reload();
        } else {
          message.error(t(res.message || ERROR_EXCEPTION));
        }
      })
      .catch(() => {
        message.error(ERROR_EXCEPTION);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onEdit = (record: DraftRequestListingResponse) => {
    setDisplayCreateForm(true);
    setUploadedFiles((record.attachments as any) || []);
    setFilePasswords(() => {
      let p: any = {};
      record.attachments?.forEach(file => {
        if (file.password && file.fileId) {
          p[file.fileId] = file.password;
        }
      });
      return p;
    });
    form.setFieldsValue({
      ...record,
      draftRequestId: record.id,
    });
  };

  const closeForm = (reload?: boolean) => {
    setDisplayCreateForm(false);
    setUploadedFiles([]);
    setFilePasswords({});
    form.resetFields();
    if (reload) {
      zTb.current?.reload();
    }
  };

  const uploadRequest = async (options: any) => {
    let fileSize = options.file.size;
    if (fileSize >= MAX_FILE_UPLOAD_SIZE) {
      Modal.error({
        title: t('EFaxFileUploadLimit25Mb'),
        okText: t('Close'),
        cancelText: t('Close'),
      });
      return;
    }
    setLoading(true);
    ApiSdk.MediaService.uploadFile({ fileName: options.file?.name ?? '', formFile: options.file })
      .then(res => {
        if (res.success) {
          setUploadedFiles(v => [...v, { fileId: res.fileId || '', fileName: res.fileName || '' }]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onRemoveUploadedFile = (id: string) => {
    setUploadedFiles(files => files.filter(x => x.fileId !== id));
  };

  const fileList = React.useMemo(() => {
    return uploadedFiles.map(f => ({ uid: f.fileId, name: f.fileName }));
  }, [uploadedFiles]);
  const onCreateNewEmailMessage = () => {
    setDisplayCreateForm(true);
    ApiSdk.TourMessageService.generateEmailContent({
      body: {
        guideId: guideId,
        tourId: tourId,
      },
    })
      .then(res => {
        form.setFieldsValue({ requestType: TourMessageType.Email, body: res, subject: `【ご連絡】${tourName}` });
      })
      .catch(() => {
        form.setFieldsValue({ requestType: TourMessageType.Email, body: '', subject: `【ご連絡】${tourName}` });
      });
  };
  const isOfficeFile = (fileName: string) => {
    return ['.doc', '.docx', '.xlsx', '.xls', '.pptx'].some(x => fileName.startsWith(fileName));
  };

  const inputFilePassword = (fileId: string) => {
    Modal.confirm({
      cancelText: t('Close'),
      okText: t('Save'),
      title: t('tourMessagePage_FileHasPassword'),
      onOk: () => {
        let password = (document.getElementById('file-input-password') as any)?.value;
        if (password) {
          setFilePasswords(x => ({ ...x, [fileId]: password }));
        } else {
          setFilePasswords(x => {
            delete x[fileId];
            return cloneDeep(x);
          });
        }
      },
      content: (
        <div>
          {t('tourMessagePage_InputFilePasswordIfNeed')}
          <br />
          <br />
          <Input.Password id="file-input-password" />
        </div>
      ),
    });
  };

  return (
    <Modal
      className="nta-custom-form create-tour-form"
      bodyStyle={{ minHeight: 600, overflow: 'auto' }}
      visible={true}
      onCancel={() => onClose()}
      style={{ top: 10 }}
      width={1200}
      title={t('tourMessagePage_DraftMessage')}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ loading }}
      cancelText={t('Close')}
      onOk={onClose}
      maskClosable={false}
    >
      <Spin spinning={loading}>
        <div style={{ textAlign: 'end', marginBottom: 10 }}>
          <Space>
            <Button
              key="create"
              type="primary"
              className="nta-create-btn"
              icon={<PlusCircleOutlined />}
              onClick={() => {
                onCreateNewEmailMessage();
              }}
            >
              {t('tourMessage_EmailType')}
            </Button>
            <Button
              key="create"
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => {
                setDisplayCreateForm(true);
                form.setFieldsValue({ requestType: TourMessageType.EFax });
              }}
            >
              {t('tourMessage_EFaxType')}
            </Button>
          </Space>
        </div>
        <ZTable<DraftRequestListingResponse>
          hidePagingOnTop={true}
          columns={columns}
          primaryKey="id"
          tableRef={zTb}
          defaultFilter={{
            tourId: tourId,
            guideId: guideId,
          }}
          scroll={{ x: Width }}
          url={`/draft-requests/get-draft-requests`}
          bordered
          order_by="createdAt"
          order_by_asc={false}
          allowEdit={true}
          onEdit={onEdit}
          allowDelete={true}
          delete_url="/draft-requests/delete"
          extraActions={[
            {
              render: record => (
                <Tooltip title={t('zTable.copy')}>
                  <Button
                    style={{ backgroundColor: '#fff95c', borderRadius: 0 }}
                    icon={<CopyOutlined />}
                    type="ghost"
                    size="small"
                    onClick={() => {
                      onCopy(record);
                    }}
                  ></Button>
                </Tooltip>
              ),
            },
          ]}
        />
      </Spin>
      {displayCreateForm ? (
        <Modal
          className="nta-custom-form"
          visible={true}
          maskClosable={false}
          bodyStyle={{ minHeight: 600, overflow: 'auto' }}
          width={1300}
          style={{ top: 10 }}
          onCancel={() => closeForm()}
          title={t('tourMessagePage_DraftMessage')}
          forceRender={true}
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button loading={loading} type="default" onClick={() => closeForm()}>
                {t('Cancel')}
              </Button>
              <Space>
                <Button
                  loading={loading}
                  className="save-btn"
                  style={{ color: 'white' }}
                  icon={<SaveOutlined />}
                  onClick={() => {
                    form
                      .validateFields()
                      .then(values => {
                        createUpdateDraftMessage(values);
                      })
                      .catch(info => {
                        console.log('Validate Failed:', info);
                      });
                  }}
                >
                  {t('Save')}
                </Button>
                <Button
                  loading={loading}
                  type="primary"
                  icon={<SendOutlined />}
                  onClick={() => {
                    form
                      .validateFields()
                      .then(values => {
                        sendMessage(values);
                      })
                      .catch(info => {});
                  }}
                >
                  {t('tourMessagePage_Send')}
                </Button>
              </Space>
            </div>
          }
        >
          <Spin spinning={loading}>
            <Form form={form} size="middle" layout="vertical" colon={false} initialValues={{ requestType: TourMessageType.EFax }}>
              <Row gutter={6}>
                <Col xs={24} md={24} xl={10} xxl={10}>
                  <Row gutter={6}>
                    <Form.Item name="draftRequestId" className="hidden">
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name="id" className="hidden">
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name="requestType" className="hidden">
                      <Input type="hidden" />
                    </Form.Item>
                    <Col md={24} xs={24}>
                      <Form.Item dependencies={['requestType']} noStyle>
                        {({ getFieldValue }) => {
                          const requestType = getFieldValue('requestType');
                          return (
                            <Form.Item name="requestType" label={t('tourMessagePage_EmailEFax')}>
                              <Radio.Group>
                                <Radio value={requestType}>{TourMessageType.__getValue(requestType)}</Radio>
                              </Radio.Group>
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                      <Form.Item label={t('tourMessagePage_Guide')}>
                        <Input disabled className="input-value-color" value={guideName} />
                      </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                      <Form.Item label={t('tourMessagePage_TourName')}>
                        <Input disabled className="input-value-color" value={tourName} />
                      </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                      <Form.Item label={t('tourMessagePage_TourId')}>
                        <Input disabled className="input-value-color" value={seisanNumber} />
                      </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                      <Form.Item
                        label={t('tourMessagePage_FacilityName')}
                        name="facilityName"
                        rules={[{ required: true, message: VALIDATE_REQUIRED }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                      <Form.Item dependencies={['requestType']} noStyle>
                        {({ getFieldValue }) => {
                          const requestType = getFieldValue('requestType');
                          return requestType === TourMessageType.EFax ? (
                            <Form.Item
                              name="sendTo"
                              label={t('tourMessagePage_FaxTo')}
                              rules={[
                                { required: true, message: VALIDATE_REQUIRED },
                                {
                                  message: FAX_NUMBER_VALIDATE,
                                  validator: ({ message }, value) => {
                                    if (value) {
                                      let isInvalid = !FaxNumberRegex.test(value);
                                      if (isInvalid) {
                                        return Promise.reject(message);
                                      }
                                      let faxNumberLength = value?.replaceAll('-', '').replaceAll(' ', '')?.length || 0;
                                      if (faxNumberLength === 10 || faxNumberLength === 11) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(message);
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <Input addonBefore="+81" />
                            </Form.Item>
                          ) : (
                            <Form.Item
                              name="sendTo"
                              label={t('tourMessagePage_EmailTo')}
                              rules={[
                                { required: true, message: VALIDATE_REQUIRED },
                                {
                                  message: VALIDATE_EMAIL,
                                  validator: ({ message }, value) => {
                                    if (value) {
                                      let _emails: string[] = value.split(/[\s,;]+/);
                                      let isInvalid = _emails.some(x => !EmailRegex.test(x));
                                      if (isInvalid) {
                                        return Promise.reject(message);
                                      }
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                      <Form.Item name="subject" label={t('tourMessagePage_Subject')} rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col md={24} xs={24} style={{ lineHeight: '32px', display: 'flex', justifyContent: 'space-between' }}>
                      <span>{t('tourMessagePage_Attachment')}</span>
                      <Upload
                        maxCount={9 - fileList.length}
                        fileList={fileList}
                        customRequest={uploadRequest}
                        showUploadList={false}
                        accept=".pdf,image/*,.doc,.docx,.xlsx,.xls,.pptx"
                        multiple
                      >
                        <Button type="primary" icon={<UploadOutlined />} disabled={fileList.length >= 9}>
                          {t('tourMessagePage_Click2Upload')}
                        </Button>
                      </Upload>
                    </Col>
                    <Col md={24}>
                      {uploadedFiles?.length ? <Divider /> : null}
                      {uploadedFiles.map(file => {
                        return (
                          <Row gutter={6}>
                            <Col md={20}>{file.fileName}</Col>
                            <Col md={4}>
                              <Space>
                                <Button
                                  size="small"
                                  icon={<DownloadOutlined />}
                                  type="primary"
                                  onClick={() => {
                                    fileService.downloadFileById(file.fileId);
                                  }}
                                />
                                {isOfficeFile(file.fileName) ? (
                                  <Button
                                    size="small"
                                    icon={<LockOutlined />}
                                    type={filePasswords[file.fileId] ? 'primary' : 'ghost'}
                                    danger
                                    onClick={() => {
                                      inputFilePassword(file.fileId);
                                    }}
                                  />
                                ) : null}

                                <Button
                                  size="small"
                                  icon={<DeleteOutlined />}
                                  type="default"
                                  danger
                                  onClick={() => {
                                    onRemoveUploadedFile(file.fileId || '');
                                  }}
                                />
                              </Space>
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={24} xl={14} xxl={14}>
                  <Form.Item shouldUpdate noStyle>
                    {({ getFieldsError }) => {
                      let error = getFieldsError(['body']).find(x => x);
                      let errorMessage = '';
                      if (error?.errors?.length) {
                        errorMessage = error?.errors[0];
                      }

                      return (
                        <div style={{ marginBottom: 8 }}>
                          <span className="form-label-required">{t('tourMessagePage_Body')}</span>
                          {errorMessage ? <span className="error-message">{errorMessage}</span> : null}
                        </div>
                      );
                    }}
                  </Form.Item>
                  <Form.Item
                    name="body"
                    className="hide-error-message"
                    rules={[
                      { required: true, message: VALIDATE_REQUIRED },
                      {
                        message: VALIDATE_REQUIRED,
                        validator: ({ message }, value) => {
                          if (value) {
                            var newDiv = document.createElement('div');
                            newDiv.innerHTML = value;
                            if (!newDiv.textContent?.trim()) {
                              return Promise.reject(message);
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <RickTextInput style={{ minHeight: 400, height: 400, display: 'inline-block' }} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      ) : null}
    </Modal>
  );
}
