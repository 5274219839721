import { Select, SelectProps } from 'antd';
import React from 'react';
import { NotificationStatus } from '@/config';
import { useTranslation } from 'react-i18next';

export default function NotificationStatusDisable({ value, ...props }: SelectProps<any>) {
  const { t } = useTranslation();
  return (
    <Select maxTagCount="responsive" {...props} value={value}>
      <Select.Option key={value} value={value}>
        {t(NotificationStatus.__getValue(value))}
      </Select.Option>
    </Select>
  );
}
