export default {
  facilityName: '施設名/会社名',
  facility: '施設',
  facilityCategory: '施設区分',
  facilityID: '施設ID',
  facilityCategory_Accommodation: '宿泊',
  facilityCategory_Bus: 'バス/ハイヤー',
  facilityCategory_Transportation: '交通',
  facilityCategory_Meal: '食事',
  facilityCategory_Sightseeing: '観光',
  facilityCategory_Experience: '体験',
  facilityCategory_Others: 'その他',
  feedback: 'フィードバック',
  feed: 'フィード',
  guideEvaluationModalTitle: 'フィードバック',
  TourGeneralComment: 'ツアー総評',
  tourArea: 'エリア',
  facilityReviews: '施設口コミ',
  facilityReviewsAdd: '追加',

  facilityReviewDate: '利用日',
  facilityReviewRate: '総合評価',
  facilityReviewType: '種別',
  facilityReviewFacilityName: '施設名/会社名',
  facilityReviewDetail: '詳細',
  facilityReview_FacilityRate: '施設(外観、ロビー等)',
  facilityReview_FacilityRate2: '施設',
  facilityReview_FacilityBusRate: '車両(清掃状況、新しさ等)',
  facilityReview_FacilityMealRate: '施設(雰囲気、衛生面等)',
  facilityReview_ContentMealRate: '内容(料理、味、量)',

  facilityReview_RoomRate: '部屋',
  facilityReview_CustomerServiceRate: '接客',
  facilityReview_BathRate: '入浴施設(大浴場、露天風呂)',
  facilityReview_MealRate: '内容(料理、味、量)',
  facilityReview_contentsRate: '内容',
  facilityReview_DriverName: 'ドライバー名',
  tourEvaluation_tourDuration: 'ツアー期間',
  tourEvaluation_tourName: 'ツアー名',
  tourEvaluation_nationality: '国籍',
  tourEvaluation_area: 'エリア',
  tourEvaluation_agentName: 'AGT名',
  tourEvaluation_useDate: '利用日',
  tourEvaluation_facility: '施設名/会社名',
  tourEvaluation_type: '種別',
  tourEvaluation_rating: '評価',
  tourEvaluation_guideName: 'ガイド',
  tourEvaluation_AdvancedSearch: '詳細検索',
  tourEvaluation_OnlyTourReview: 'ツアー総評表示',
  tourEvaluation_OnlyFacilityReview: '施設口コミ表示',
  tourEvaluation_TourArrival: '到着日',
  tourEvaluation_TourDeparture: '出発日',
  tourEvaluation_LastModifiedDate: '最終更新日時',
  tourEvaluation_tourImpression: 'ツアーの感想',
  tourEvaluation_comprehensiveRate: '総合評価',
  tourEvaluation_customerReaction: 'お客様の反応',
  tourEvaluation_tourLeaderEvaluation: 'ツアーリーダーについて（良い点、悪い点） ',
  tourEvaluation_tourLeaderName: 'ツアーリーダー名',
  tourEvaluation_serviceGoodPoints: '行程評価（良い点)',
  tourEvaluation_serviceImprovement: '行程評価（改善点、アドバイス）',
  tourEvaluation_accommodationComprehensiveEvaluation: '宿泊総合評価（全体のバランス、本ツアーへの適正）',
  tourEvaluation_accommodationComprehensiveRate: '宿泊総合評価',
  tourEvaluation_restaurantComprehensiveEvaluation: '食事店総合評価（全体のバランス、本ツアーへの適正）',
  tourEvaluation_restaurantComprehensiveRate: '食事店総合評価',
  tourEvaluation_otherComments: 'その他/自由記載',
  tourEvaluation_hotelMealFacilityRate: '施設(雰囲気、衛生面等)',
  tourEvaluation_HotelMealContentRate: '内容(料理、味、量)',
  tourEvaluation_HotelMealCustomerServiceRate: '接客',
  tourEvaluation_HotelMealCrowdedness: '混雑状況',
  tourEvaluation_hotelMealRecommendation: '同じエリアで提案したい宿泊施設',
  tourEvaluation_BusPlanedStartTime: '出発時間(行程表) ',
  tourEvaluation_BusActualStartTime: '出発時間(実際)',
  tourEvaluation_BusPlanedEndTime: '終了時間(行程表)',
  tourEvaluation_BusActualEndTime: '終了時間(実際)',

  tourEvaluation_MealPlanedStartTime: '開始時間(行程表の時間)',
  tourEvaluation_MealActualStartTime: '終了時間(行程表の時間)',
  tourEvaluation_MealPlanedEndTime: '実際の開始時間',
  tourEvaluation_MealActualEndTime: '実際の終了時間',
  tourEvaluation_MealIdealStayTime: '理想滞在時間',
  tourEvaluation_MealCustomerReaction: 'お客様の反応(完食していたか、残していたか、人気だった料理等を記載ください) ',
  tourEvaluation_MealRecommendationInSameArea: '同じエリアで提案したい食事店',
  tourEvaluation_SightseeingRecommendationInSameArea: '同じエリアで提案したい観光地',
  tourEvaluation_ExperienceRecommendationInSameArea: '同じエリアで提案したい体験施設',
  tourEvaluation_ParkingLotsEvaluation: '駐車場に関して(予約時間、推奨する別の駐車場等)',
};
