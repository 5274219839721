import { unHttpClient } from '@/httpclient';
import { GuideModel } from '@/__generated';
import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GuideInfo from '../../../components/GuideInfo';

type Props = {
  gid: string;
  visible: boolean;
  onClose: () => void;
};

export default function GuideInfoModal({ gid, visible, onClose }: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [guide, setGuide] = React.useState<GuideModel>();

  React.useEffect(() => {
    setLoading(true);
    unHttpClient
      .get<GuideModel>(`/get-guide-info/${gid}`)
      .then(res => {
        if (res.data) {
          setGuide(res.data);
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 50);
      });
  }, [gid]);
  const onSelectGuide = () => {
    onClose();
    if (guide && window.parent) {
      window.parent.postMessage(
        JSON.stringify({
          type: 'SELECT_GUIDE',
          guideId: guide.id,
          languages: guide.supportedLanguages?.map(x => x.languageCode),
          email: guide.emailAddress1,
          tel: guide.mobilePhone,
          firstNameKatakana: guide.firstNameKatakana,
          lastNameKatakana: guide.lastNameKatakana,
          firstNameKanji: guide.firstNameKanji,
          lastNameKanji: guide.lastNameKanji,
        }),
        '*',
      );
    }
  };
  return (
    <Modal
      className="nta-custom-form create-tour-form"
      bodyStyle={{ height: 'calc(100vh - 150px)', overflow: 'auto', padding: 0 }}
      width={1000}
      style={{ top: 10 }}
      visible={visible}
      title={'ガイド情報'}
      onOk={onSelectGuide}
      onCancel={onClose}
      okText={t('選択')}
      cancelText={t('Close')}
    >
      <GuideInfo guide={guide} loading={loading} />
    </Modal>
  );
}
