export default {
  // messages
  'Please input required field.': 'Please input required field',
  'Are you sure want to delete this item?': 'Are you sure want to delete this item?',
  TOUR_GUIDE_REJECT_CONFIRM_MESSAGE: 'Are you sure want to reject this tour?',
  TOUR_GUIDE_CONFIRM_CONFIRM_MESSAGE: 'Are you sure want to confirm this tour?',
  TOUR_GUIDE_ACCEPT_CONFIRM_MESSAGE: 'Are you sure want to accept this tour?',
  UPDATE_TOUR_SUCCESS: 'Update tour success!',

  SOME_CHANGES_IN_THESE_TOUR_GUIDES:
    'The below Guides have some changes, the status should be "Assigning". Are you sure of proceeding with the selected status?',
  GUIDE_ALL_DATE_UNAVAILABLE: 'The Guides below have all dates Unavailable or CXLed. Are you sure of proceeding with the selected status?',
  ALL_DATE_CANCELLATION_SHOULD_BE_AWAITING_CANCELLATION:
    'The below Guides have all dates requested for Cancellation, the status should be "Awaiting Cancellation". Are you sure of proceeding with your selected status?',

  ADD_AT_LEAST_ONE_BUSHINESS_DAY: 'Please add at least one business day for the guides below!',
  PLEASE_INPUT_NTA_COMMENT: 'Please input NTA comment for this guides!',
  KEEP_ALL_CANCELLED_DATE: 'Do you want to keep all cancelled dates?',
  CONFIRM_CANCEL_DATE: 'Are you sure you want to cancel this date?',

  RecordNotFoundWithSpecifiedId: 'Cannot find the record.',
  DeleteRecordInternalError: 'An error has occurred during deletion.',
  UpdateRecordInternalError: 'An error has occurred during update.',
  SmtpNotFound: 'The email server has not been setup.',
  LanguageAlreadyExist: 'Language has already existed.',
  EmailContentIncorrectFormat: 'Email content is blank or wrongly formatted.',
  EmailTemplateAlreadyExist: 'Email template has already existed.',
  CountryCodeAlreadyExist: 'Country has already existed.',
  DepartmentNameAlreadyExist: 'Department has already existed.',
  DivisionNameAlreadyExist: 'Division has already existed.',
  InternalErrorDuringSendingEmail: 'Email failed to be sent due to an internal error.',
  FileUploadIsInvalid: '',
  InternalErrorDuringUploadFile: 'The file upload has failed due to an internal error.',
  InternalErrorDuringDownloadFile: 'The file download has failed due to an internal error.',
  FileDownloadIsNotExist: 'The download file does not exist.',
  InternalErrorDuringUpdateGuideAvailability: 'An error has occurred during availability update.',
  InternalErrorDuringGetGuideAvailabilities: 'Cannot get guide availability.',
  CreateGuideAccountUnSuccessful: 'An error has occurred during guide account registration',
  CreateGuideInternalError: 'An error has occurred during guide registration',
  DeleteGuideInternalError: 'An error has occurred during guide deletion',
  GuideIsNotSet: 'Guide does not exist.',
  GuideDoesNotExists: 'Guide does not exist.',
  NewPasswordConfirmNotMatched: "Confirm password doesn't match New password.",
  SuccessButSendEmailFailed: 'Save record successfully, but email sending fails.',
  CannotModifyGuideEmail1: 'Guide email is not allowed to be changed.',
  CreateUserAccountUnsuccessful: 'An error has occurred during user account registration',
  SaveUserInternalError: 'An error has occurred during staff registration',
  DeleteUserInternalError: 'An error has occurred during staff deletion',
  UsernameAlreadyExist: 'Username has already existed.',
  UserEmailAlreadyExist: 'Email has already existed.',
  UserNotFoundWhenRequestForgotPassword: 'Email was not found.',
  ForgotPasswordSessionExpired: 'This link has expired. Please send password reset request again.',
  UsernameOrPasswordIsIncorrect: 'Username or password is wrong.',
  UserNotfoundOrNotActiveYet: 'Username or password is wrong.',
  UserRoleAlreadyExist: 'User role has already existed.',
  NewPasswordEqualsOldPassword: 'New password cannot be the same as the previous password.',
  ChangePasswordUnsuccessful: 'Password change has failed due to an internal error.',
  TemporaryPasswordNotFound: 'Wrong or expired password.',
  TemporaryPasswordNotMatch: 'Wrong or expired password.',
  WrongToken: 'Wrong or expired password.',
  SetPasswordError: 'Password change has failed due to an internal error.',
  CannotDeleteLanguageIsAlreadyUsedForSomeGuides: 'The selected language has been used for Guide Profile. Cannot delete this active language.	',
  CannotUpdateLanguageCodeIsAlreadyUsedForSomeGuides: 'The selected language has been used for Guide Profile. Cannot update language code.	',
  PasswordCannotContainsEmail: 'Password is not allow to input email address',
  IncorrectPassword: 'Current password is incorrect',
  ResetPasswordSessionExpired: 'Since the valid time of the initial password has passed, please reset the password from the link below.',
  NewPasswordEqualsCurrentPassword: 'New password cannot be the same as the current password.',
  UserLockedWhenRequestForgotPassword: 'The email address is invalid.',
  // new
  DeleteTourGuideConfirm: 'Are your sure to delete Guide?',
  TourNotExists: 'The tour no longer exists!',
  GuideAndLanguageExistsOtherTab: 'Guide [{{name}}] and language [{{language}}] already exists in other tabs',
  GuideAndLanguageDuplicated: `Guide <b>{{name}}</b> and language <b>{{language}}</b> is duplicated!`,
  GuideAndDateDuplicated: `Guide <b>{{name}}</b> has duplicated date: <b>{{date}}</b>!`,
  GuideUpdateProfileErrorTitle: '確認メッセージ',
  GuideUpdateProfileChangeNotSave: `The registration has not been saved. If you close the screen, the registered contents will be lost.<br/>Are you sure you want to close the screen?`,
  GuideUpdateProfileValidateError:
    'Cannot save because there is an error in the entry field. When you close the screen, your registration information will be lost. <br/>Are you sure you want to close the screen?',
  LoginSessionExpiredTitle: 'Warning',
  LoginSessionExpiredContent: 'The login session has ended. You have been forced to logout. Please click OK button to go to login page.',
  GuideLanguageDuplicatedTitle: 'Warning',
  GuideLanguageDuplicatedContent: 'Language is duplicated. Please check again.',
  // 1.5
  StaffIsAlreadyUsedForSomeTours: 'The staff has been assigned in tours. Cannot delete it.',
  CountryIsAlreadyUsedForSomeTours: 'The country has been used in tours. Cannot delete it.',
  GuideIsAlreadyUsedForSomeTours: 'The guide has been assigned in tours. Cannot delete it.',
  CannotDeleteLanguageIsAlreadyUsedForSomeTours: 'The language has been used in tours. Cannot delete it.',
  UpdateTourSessionIsInvalid: 'The editing session has ended. Your updates are not saved. Please click OK button to reload the page.',
  TourNumberIsDuplicated: 'Tour Number is duplicated with another Tour',
  TourIdIsDuplicated: 'Tour ID is duplicated with another Tour',
};
