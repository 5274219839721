import React from 'react';

import { useAppContext } from '@/contexts/AppContext';
import useAuth from '@/hooks/useAuth';
import ForgotPassword from '@/pages/Auth/ForgotPassword';
import PasswordExpired from '@/pages/Auth/PasswordExpired';
import ResetPassword from '@/pages/Auth/ResetPassword';
import SetPassword from '@/pages/Auth/SetPassword';
import { setBodyClassWithRole } from '@/services/roleService';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router-dom';
import PageLoading from '../components/PageLoading';
import NoLayoutRoute from '../components/routes/NoLayoutRoute';
import LoginPage from '@/pages/Auth/Login';
import AuthLayout from './cms';

export default function RenderRoutes() {
  const { auth, authLoaded } = useAppContext();
  const [, i18n] = useTranslation();
  useAuth();
  React.useEffect(() => {
    if (auth?.roleClaims?.length) {
      setBodyClassWithRole(auth.roleClaims[0]);
    }
  }, [auth]);
  if (!authLoaded) {
    return <PageLoading />;
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }} />
      {auth ? (
        <>
          {auth.isPasswordExpired ? (
            <Switch>
              <Route path="/cms/password-expired" component={PasswordExpired} exact />
              <Redirect from="*" to="/cms/password-expired" />
            </Switch>
          ) : (
            <Switch>
              <Route path="/cms/" component={AuthLayout} />
            </Switch>
          )}
        </>
      ) : (
        <Switch>
          <NoLayoutRoute exact path={'/cms/change-password'} component={SetPassword} />
          <NoLayoutRoute exact path={'/cms/login'} component={LoginPage} />
          <NoLayoutRoute exact path={'/cms/forgot-password'} component={ForgotPassword} />
          <NoLayoutRoute exact path={'/cms/reset-password/:token'} component={ResetPassword} />
          <Redirect from="*" to="/cms/login" />
        </Switch>
      )}
    </>
  );
}
