import { AvailableTour, DayGuideAvailability } from '@/__generated';
import { CloseCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

type Props = {
  date: DayGuideAvailability | null | undefined;
  availableTours: AvailableTour[] | undefined;
};

function DateItem({ date, availableTours }: Props) {
  if (!date || availableTours?.length) {
    return null;
  }
  if (date.isWfAnotherDepartment) {
    return (
      <Tooltip title={date.wfAnotherDepartmentDetails}>
        <CloseCircleFilled style={{ fontSize: 16 }} className={`icon other-department`} />
      </Tooltip>
    );
  }
  if (date.stayLastNight) {
    return (
      <Tooltip title={<div dangerouslySetInnerHTML={{ __html: date.stayInTours?.map(x => x.name).join('<br/>') || '' }} />}>
        <CloseCircleFilled style={{ fontSize: 16 }} className={`icon stay-last-night`} />
      </Tooltip>
    );
  }
  if (date.stayOvernight) {
    return (
      <Tooltip title={<div dangerouslySetInnerHTML={{ __html: date.stayInTours?.map(x => x.name).join('<br/>') || '' }} />}>
        <CloseCircleFilled style={{ fontSize: 16 }} className={`icon stay-over-night`} />
      </Tooltip>
    );
  }
  if (date.isUnavailable) {
    return <CloseCircleFilled style={{ fontSize: 16 }} className={`icon unavailable`} />;
  }
  return null;
}

export default DateItem;
