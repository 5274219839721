import RatingInput from '@/components/app/formItem/RatingInput';
import { Row, Col, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const requiredRule = {
  required: true,
  message: <Trans>VALIDATE_REQUIRED</Trans>,
};
type Props = {
  disabled: boolean;
};
export default function TransportationItem({ disabled }: Props) {
  const { t } = useTranslation();
  return (
    <Row gutter={6}>
      <Col md={5} xs={12}>
        <Form.Item label={t('facilityReviewRate')} name="comprehensiveRate" rules={[requiredRule]}>
          <RatingInput disabled={disabled} className="input-value-color" />
        </Form.Item>
      </Col>
      <Col md={5} xs={12}>
        <Form.Item label={t('facilityReview_FacilityBusRate')} name="facilityRate">
          <RatingInput disabled={disabled} className="input-value-color" />
        </Form.Item>
      </Col>
      <Col md={4} xs={12}>
        <Form.Item label={t('facilityReview_CustomerServiceRate')} name="customerServiceRate">
          <RatingInput disabled={disabled} className="input-value-color" />
        </Form.Item>
      </Col>
      <Col md={10} xs={12}>
        <Form.Item label={t('facilityReview_DriverName')} name="driverName">
          <Input disabled={disabled} className="input-value-color" />
        </Form.Item>
      </Col>
      <Col md={24} xs={24}>
        <Form.Item label={t('facilityReviewDetail')} name="details">
          <TextArea autoSize={{ minRows: 3 }} className="input-value-color" disabled={disabled} />
        </Form.Item>
      </Col>
    </Row>
  );
}
