import DatePicker from '@/components/DatePicker';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Form, Input, Row, Select, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT, TourMessageStatusType, TourMessageType } from '@/constants';
import GuideInput from '@/components/app/formItem/GuideInput';
import { useAppContext } from '@/contexts/AppContext';
import { isGuideOnly } from '@/services/roleService';
import SearchableInMobile from '@/components/app/SearchableInMobile';
type Props = {
  onSearch: (filter?: any) => void;
};

export default function SearchForm({ onSearch }: Props) {
  const { t } = useTranslation();
  const { auth } = useAppContext();

  const [form] = Form.useForm();
  const isGuide = React.useMemo(() => {
    return isGuideOnly(auth?.roleClaims);
  }, [auth]);
  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 6 }}
      labelAlign="left"
      colon={false}
      className="nta-custom-form"
      onFinish={v => {
        onSearch({
          ...v,
          sendTo: v.sendTo?.replaceAll('-', '').replaceAll(' ', '') || undefined,
          fromDate: v.fromDate ? moment(v.fromDate).format(DEFAULT_DATE_FORMAT) : undefined,
          toDate: v.toDate ? moment(v.toDate).format(DEFAULT_DATE_FORMAT) : undefined,
        });
      }}
      initialValues={{}}
    >
      <SearchableInMobile>
        <Row gutter={12}>
          <Col xs={12} md={12} xl={12} xxl={4}>
            <Row gutter={6}>
              <Col md={24} xs={24} xl={6} xxl={6} style={{ lineHeight: '32px' }}>
                {t('tourMessagePage_EmailEFax')}
              </Col>
              <Col md={24} xs={24} xl={18} xxl={18}>
                <Form.Item name="messageType">
                  <Select options={TourMessageType.__getSelectOptions()} allowClear />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={12} xl={12} xxl={8}>
            <Form.Item labelCol={{ md: 24, xl: 6, xxl: 4 }} label={t('tourMessagePage_Subject')} name="Subject">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={12} xxl={7}>
            <Row gutter={6}>
              <Col md={24} xs={24} xl={6} xxl={6} style={{ lineHeight: '32px' }}>
                {t('tourMessagePage_FromTo')}
              </Col>
              <Col md={12} xs={12} xl={9} xxl={9}>
                <Form.Item name="fromDate">
                  <DatePicker id="fromDate" />
                </Form.Item>
              </Col>
              <Col md={12} xs={12} xl={9} xxl={9}>
                <Form.Item name="toDate">
                  <DatePicker id="toDate" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={12} xl={12} xxl={5}>
            <Form.Item labelCol={{ md: 24, xl: 6, xxl: 6 }} label={t('tourMessagePage_Status')} name="status">
              <Select options={TourMessageStatusType.__getSelectOptions()} allowClear />
            </Form.Item>
          </Col>

          <Col xs={12} md={24} xl={12} xxl={4}>
            <Row gutter={6}>
              <Col md={24} xs={24} xl={6} xxl={6} style={{ lineHeight: '32px' }}>
                {t('tourMessagePage_TourId')}
              </Col>
              <Col md={24} xs={24} xl={18} xxl={18}>
                <Form.Item name="tourId">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={12} xl={12} xxl={8}>
            <Form.Item labelCol={{ md: 24, xl: 6, xxl: 4 }} label={t('tourMessagePage_TourName')} name="tourName">
              <Input />
            </Form.Item>
          </Col>

          <Col xs={12} md={12} xl={12} xxl={7}>
            <Form.Item labelCol={{ md: 24, xl: 6, xxl: 6 }} label={t('tourMessagePage_EmailFax')} name="sendTo">
              <Input />
            </Form.Item>
          </Col>
          {!isGuide ? (
            <Col xs={24} md={24} xl={12} xxl={5}>
              <Form.Item labelCol={{ md: 24, xl: 6, xxl: 6 }} label={t('tourMessagePage_Guide')} name="guideId">
                <GuideInput labelInValue={false} />
              </Form.Item>
            </Col>
          ) : null}
          <Col xs={12} md={12} xl={12} xxl={12}>
            <Form.Item labelCol={{ md: 24, xl: 6, xxl: 2 }} label={t('tourMessagePage_Body')} name="bodyContent">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} md={12} xl={12} xxl={7}>
            <Form.Item labelCol={{ md: 24, xl: 6, xxl: 6 }} label={t('tourMessagePage_FacilityName')} name="facilityName">
              <Input />
            </Form.Item>
          </Col>
          <Col md={12} xs={12} xl={isGuide ? 12 : 24} xxl={5} className="search-form-action" style={{ textAlign: 'right' }}>
            <Space>
              <Button type="primary" htmlType="submit">
                <SearchOutlined style={{ color: '#333' }} />
                {t('zTable.search')}
              </Button>
              <Button type="ghost" htmlType="reset">
                <CloseOutlined />
                {t('zTable.reset')}
              </Button>
            </Space>
          </Col>
        </Row>
      </SearchableInMobile>
    </Form>
  );
}
