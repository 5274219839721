import { ApiSdk } from '@/httpclient';
import { CountryModel } from '@/__generated';
import { Select, SelectProps } from 'antd';
import React from 'react';

export default function NationalityInput(props: SelectProps<any>) {
  const [countries, setCountries] = React.useState<CountryModel[]>([]);

  React.useEffect(() => {
    getCountries();
  }, []);

  const getCountries = () => {
    ApiSdk.CountryService.getCountries({ pageSize: 250, orderBy: 'name', orderByAsc: true }).then(res => {
      setCountries(res.data || []);
    });
  };

  return (
    <Select
      maxTagCount="responsive"
      allowClear
      {...props}
      filterOption={(input, option) => {
        return (option?.children as string)?.toLowerCase().includes(input.toLowerCase());
      }}
      showSearch={true}
    >
      {countries.map(c => {
        return (
          <Select.Option key={c.id} value={c.shortCode}>
            {c.name}
          </Select.Option>
        );
      })}
    </Select>
  );
}
