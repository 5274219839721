import TourLockCpn from '@/components/app/TourLockCpn';
import { TourGuideStatusType } from '@/config';
import { ApiSdk } from '@/httpclient';
import { BusinessDay, TourDetailModel, TourGuideDetailModel } from '@/__generated';
import { CloseOutlined, CheckOutlined, RightCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FormInstance, Form, message, Modal, Button, Col, Row, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GuideOtherInfo from '../_readonlyComponents/GuideOtherInfo';
import ReadOnlyGuideInfo from '../_readonlyComponents/GuideInfo';
import BriefingInput from './BriefingInput';
import GuideItemForm from './GuideItemForm';
import lodash from 'lodash';
import dayjs from 'dayjs';
import { ShortTourGuideInfo } from '../../TourFeedback/TourEvaluation';
import GuideSeisanButton from '../GuideSeisan/GuideSeisanButton';
import tourService from '@/services/tourService';

type Props = {
  guide: TourGuideDetailModel;
  sessionId: string | undefined;
  onFormChange: (form: FormInstance) => void;
  onClose: (reload?: boolean) => void;
  showGuideEvaluation: (shortGuideInfo: ShortTourGuideInfo) => void;

  tour: TourDetailModel;
};

export default function GuideTab({ guide, sessionId, onFormChange, onClose, showGuideEvaluation, tour }: Props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  React.useEffect(() => {
    onFormChange(form);
  }, []);
  const canReject = React.useMemo(() => {
    return guide.status === TourGuideStatusType.Assigning;
  }, [guide]);

  React.useEffect(() => {
    form.setFieldsValue({
      ...guide,
      businessDays: lodash.orderBy(guide.businessDays, d => {
        if (d.date) {
          return dayjs(d.date).unix();
        }
        return Number.MAX_VALUE;
      }),
    });
  }, [guide]);

  const canConfirm = React.useMemo(() => {
    const uncheckStatus = [
      TourGuideStatusType.Informing2MonthPrior,
      TourGuideStatusType.Informing3MonthsPrior,
      TourGuideStatusType.AwaitingCancellation,
      TourGuideStatusType.FinalInforming,
    ];
    return uncheckStatus.includes(guide.status as any);
  }, [guide]);

  const canAcceptAssignment = React.useMemo(() => {
    return guide.status === TourGuideStatusType.Assigning;
  }, [guide]);

  const createSubmitData = (v: any) => {
    return {
      ...guide,
      ...v,
      tourGuideId: guide.id,
      comment: v.guideComments,
      businessDays: v.businessDays,
      sessionId: sessionId,
    };
  };

  const rejectTour = () => {
    form.validateFields().then(v => {
      Modal.confirm({
        title: t('Confirm'),
        content: t('TOUR_GUIDE_REJECT_CONFIRM_MESSAGE'),
        cancelText: t('Cancel'),
        okText: t('Confirm'),
        onOk: () => {
          ApiSdk.TourService.denyTourGuide({
            body: createSubmitData(v),
          }).then(res => {
            if (res.success) {
              message.success(t('UPDATE_TOUR_SUCCESS'));
              onClose(true);
            } else {
              message.error(t(res.message || ''));
            }
          });
        },
      });
    });
  };

  const acceptTour = () => {
    form.validateFields().then(v => {
      const businessDays: BusinessDay[] = v.businessDays;
      const isAllDateUnAvailability = !businessDays.filter(x => !x.isCancelled)?.some(x => x.availability);
      Modal.confirm({
        title: t('Confirm'),
        content: isAllDateUnAvailability ? t('GUIDE_ALL_DATE_UNAVAILABLE') : t('TOUR_GUIDE_ACCEPT_CONFIRM_MESSAGE'),
        cancelText: t('Cancel'),
        okText: t('Confirm'),
        onOk: () => {
          ApiSdk.TourService.acceptTourGuide({
            body: createSubmitData(v),
          }).then(res => {
            if (res.success) {
              message.success(t('UPDATE_TOUR_SUCCESS'));
              onClose(true);
            } else {
              message.error(t(res.message || ''));
            }
          });
        },
      });
    });
  };
  const confirmTour = () => {
    form.validateFields().then(v => {
      Modal.confirm({
        title: t('Confirm'),
        content: t('TOUR_GUIDE_CONFIRM_CONFIRM_MESSAGE'),
        cancelText: t('Cancel'),
        okText: t('Confirm'),
        onOk: () => {
          ApiSdk.TourService.confirmTourGuide({
            body: createSubmitData(v),
          }).then(res => {
            if (res.success) {
              message.success(t('UPDATE_TOUR_SUCCESS'));
              onClose(true);
            } else {
              message.error(t(res.message || ''));
            }
          });
        },
      });
    });
  };
  const { guideInfoLocked, guideInfoLockedBy, briefingLocked, briefingLockedBy } = guide.lockInfo || {};

  return (
    <Form className="guide-info" layout="horizontal" form={form}>
      <TourLockCpn isLocked={guideInfoLocked} lockedBy={guideInfoLockedBy}>
        {guideInfoLocked ? <ReadOnlyGuideInfo guide={guide || {}} /> : <GuideItemForm guide={guide} />}
      </TourLockCpn>

      <TourLockCpn isLocked={briefingLocked} lockedBy={briefingLockedBy}>
        {briefingLocked ? <GuideOtherInfo guide={guide || {}} /> : <BriefingInput guide={guide} />}
      </TourLockCpn>

      {guideInfoLocked ? (
        <Row>
          <Col
            xs={{
              offset: 0,
              span: 24,
            }}
            md={{
              span: 24,
            }}
            style={{ textAlign: 'end' }}
          >
            <Space>
              {tourService.displayFeedbackBtn(guide.status) ? (
                <Button
                  onClick={() => {
                    showGuideEvaluation({
                      guideId: guide.id || '',
                    });
                  }}
                  icon={<PlusOutlined />}
                  style={{
                    border: 'none',
                    backgroundColor: '#FFBDE9',
                    color: 'white',
                  }}
                >
                  {t('feedback')}
                </Button>
              ) : null}

              <GuideSeisanButton tour={tour} tourGuide={guide} />
            </Space>
          </Col>
        </Row>
      ) : (
        <Row style={{ marginTop: 10 }}>
          <Col
            xs={{
              offset: 0,
              span: 24,
            }}
            md={{
              offset: 8,
              span: 8,
            }}
            style={{ textAlign: 'center', marginBottom: 10 }}
          >
            <Space align="center">
              <Button
                type="text"
                icon={<CloseOutlined />}
                disabled={!canReject}
                onClick={rejectTour}
                style={{ backgroundColor: '#FF5500', color: 'white' }}
              >
                {t('Not applicable')}
              </Button>
              <Button
                type="text"
                icon={<CheckOutlined />}
                disabled={!canConfirm}
                onClick={confirmTour}
                style={{ backgroundColor: '#FF957B', color: 'white' }}
              >
                {t('Confirm')}
              </Button>
              <Button
                type="text"
                icon={<RightCircleOutlined />}
                disabled={!canAcceptAssignment}
                onClick={acceptTour}
                style={{ backgroundColor: '#108EE9', color: 'white' }}
              >
                {t('Accept the assignment')}
              </Button>
            </Space>
          </Col>
          <Col
            xs={{
              offset: 0,
              span: 24,
            }}
            md={{
              span: 8,
            }}
            style={{ textAlign: 'end' }}
          >
            <Space>
              {tourService.displayFeedbackBtn(guide.status) ? (
                <Button
                  onClick={() => {
                    showGuideEvaluation({
                      guideId: guide.id || '',
                    });
                  }}
                  icon={<PlusOutlined />}
                  style={{
                    border: 'none',
                    backgroundColor: '#FFBDE9',
                    color: 'white',
                  }}
                >
                  {t('feedback')}
                </Button>
              ) : null}

              <GuideSeisanButton tour={tour} tourGuide={guide} />
            </Space>
          </Col>
        </Row>
      )}
    </Form>
  );
}
