import RatingInput from '@/components/app/formItem/RatingInput';
import { Row, Col, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const requiredRule = {
  required: true,
  message: <Trans>VALIDATE_REQUIRED</Trans>,
};
type Props = {
  disabled: boolean;
};
export default function ExperienceItem({ disabled }: Props) {
  const { t } = useTranslation();
  return (
    <>
      <Row gutter={6}>
        <Col md={6} xs={12}>
          <Form.Item label={t('facilityReviewRate')} name="comprehensiveRate" rules={[requiredRule]}>
            <RatingInput disabled={disabled} className="input-value-color" />
          </Form.Item>
        </Col>
        <Col md={6} xs={12}>
          <Form.Item label={t('facilityReview_FacilityRate2')} name="facilityRate">
            <RatingInput disabled={disabled} className="input-value-color" />
          </Form.Item>
        </Col>
        <Col md={6} xs={12}>
          <Form.Item label={t('facilityReview_contentsRate')} name="contentsRate">
            <RatingInput disabled={disabled} className="input-value-color" />
          </Form.Item>
        </Col>
        <Col md={6} xs={12}>
          <Form.Item label={t('facilityReview_CustomerServiceRate')} name="customerServiceRate">
            <RatingInput disabled={disabled} className="input-value-color" />
          </Form.Item>
        </Col>
      </Row>
      {window.innerWidth > 700 ? (
        <Row gutter={6}>
          <Col flex={1}>
            <Form.Item label={t('tourEvaluation_MealPlanedStartTime')} name="planedStartTime">
              <Input type="time" disabled={disabled} className="input-value-color w-100" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item label={t('tourEvaluation_MealActualStartTime')} name="actualStartTime">
              <Input type="time" disabled={disabled} className="input-value-color w-100" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item label={t('tourEvaluation_MealPlanedEndTime')} name="planedEndTime">
              <Input type="time" disabled={disabled} className="input-value-color w-100" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item label={t('tourEvaluation_MealActualEndTime')} name="actualEndTime">
              <Input type="time" disabled={disabled} className="input-value-color w-100" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item label={t('tourEvaluation_MealIdealStayTime')} name="idealStayTime">
              <Input type="time" disabled={disabled} className="input-value-color w-100" />
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <Row gutter={6}>
          <Col xs={24}>
            <Form.Item label={t('tourEvaluation_MealPlanedStartTime')} name="planedStartTime">
              <Input type="time" disabled={disabled} className="input-value-color w-100" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label={t('tourEvaluation_MealActualStartTime')} name="actualStartTime">
              <Input type="time" disabled={disabled} className="input-value-color w-100" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label={t('tourEvaluation_MealPlanedEndTime')} name="planedEndTime">
              <Input type="time" disabled={disabled} className="input-value-color w-100" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label={t('tourEvaluation_MealActualEndTime')} name="actualEndTime">
              <Input type="time" disabled={disabled} className="input-value-color w-100" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label={t('tourEvaluation_MealIdealStayTime')} name="idealStayTime">
              <Input type="time" disabled={disabled} className="input-value-color w-100" />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={6}>
        <Col md={24} xs={24}>
          <Form.Item label={t('tourEvaluation_customerReaction')} name="customerReaction">
            <TextArea autoSize={{ minRows: 3 }} className="input-value-color" disabled={disabled} />
          </Form.Item>
        </Col>
        <Col md={24} xs={24}>
          <Form.Item label={t('facilityReviewDetail')} name="details">
            <TextArea autoSize={{ minRows: 3 }} className="input-value-color" disabled={disabled} />
          </Form.Item>
        </Col>
        <Col md={24} xs={24}>
          <Form.Item label={t('tourEvaluation_ParkingLotsEvaluation')} name="parkingLotsEvaluation">
            <TextArea autoSize={{ minRows: 3 }} className="input-value-color" disabled={disabled} />
          </Form.Item>
        </Col>

        <Col md={24} xs={24}>
          <Form.Item label={t('tourEvaluation_ExperienceRecommendationInSameArea')} name="recommendationInSameArea">
            <TextArea autoSize={{ minRows: 3 }} className="input-value-color" disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
