import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageModel } from '@/__generated';
import { Form, Col, Row, Checkbox, Radio, Input, Select, Button, Divider, InputNumber } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import DatePicker from '@/components/DatePicker';
import { SupportedLanguageLicenseType } from '@/config';
import { VALIDATE_REQUIRED } from '@/constants';

type Props = {
  form: any;
  languages: LanguageModel[];
  isMyProfile: boolean;
};

export default function LanguageContentSM({ languages, form, isMyProfile }: Props) {
  const { t } = useTranslation();
  const onMainLanguageChange = (key: number) => {
    const supportedLanguages: { isMain: boolean }[] = form.getFieldValue('supportedLanguages');
    form.setFieldsValue({
      supportedLanguages: supportedLanguages?.map((lang, i) => {
        return {
          ...lang,
          isMain: i === key,
        };
      }),
    });
  };
  return (
    <div>
      <Form.List name="supportedLanguages">
        {(fields, { add, remove }) => (
          <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
              <div className="form-label-required">{t('guideSerchsupportedLanguages')}</div>
              <Button
                type="primary"
                className="save-btn"
                onClick={() => add({ isMain: fields.length === 0 })}
                icon={<PlusOutlined />}
                style={{ marginLeft: 10 }}
              >
                {t('guideSerchadd')}
              </Button>
            </div>

            <Row>
              <Col xs={4}> {t('guideSerchmain')}</Col>
            </Row>

            <div className="w-100 language-list">
              {fields.map(field => (
                <div key={field.key}>
                  <Form.Item noStyle name={[field.name, 'id']}>
                    <Input type="hidden" />
                  </Form.Item>
                  <Row gutter={6} className="language-row">
                    <Col xs={4}>
                      <Form.Item name={[field.name, 'isMain']} valuePropName="checked">
                        <Radio
                          onClick={() => {
                            onMainLanguageChange(field.key);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={4}>{t('guideSerchlanguage')}</Col>
                    <Col xs={12}>
                      <Form.Item name={[field.name, 'languageCode']} wrapperCol={{ xs: 24 }} rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                          {languages.map((language, index) => (
                            <Select.Option key={index} value={language.code || ''}>
                              {language?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={4}>
                      <Button
                        disabled={fields.length <= 1 && isMyProfile}
                        icon={<DeleteOutlined />}
                        onClick={() => remove(field.name)}
                        className="delete-btn"
                        size="small"
                        danger
                      >
                        削除
                      </Button>
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col xs={4}></Col>
                    <Col xs={4}>種類</Col>
                    <Col xs={8}>
                      <Form.Item name={[field.name, 'license']} rules={[{ required: true, message: VALIDATE_REQUIRED }]} wrapperCol={{ xs: 24 }}>
                        <Select>
                          <Select.Option value={SupportedLanguageLicenseType.National}>
                            {t(SupportedLanguageLicenseType.__customLabel(SupportedLanguageLicenseType.National))}
                          </Select.Option>
                          <Select.Option value={SupportedLanguageLicenseType.Regional}>
                            {t(SupportedLanguageLicenseType.__customLabel(SupportedLanguageLicenseType.Regional))}
                          </Select.Option>

                          <Select.Option value={SupportedLanguageLicenseType.None}>
                            {t(SupportedLanguageLicenseType.__customLabel(SupportedLanguageLicenseType.None))}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={8}>
                      <Form.Item name={[field.name, 'licenseDetails']} wrapperCol={{ xs: 24 }}>
                        <Input maxLength={500} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col xs={4}></Col>
                    <Col xs={4}>{t('guideSerchobtainedDate')}</Col>
                    <Col xs={12}>
                      <Form.Item name={[field.name, 'obtainedDate']} wrapperCol={{ xs: 24 }}>
                        <DatePicker className="w-100" lessThanToday />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                </div>
              ))}
            </div>
          </div>
        )}
      </Form.List>

      {/*  */}

      <Row gutter={6}>
        <Col md={6} xs={24}>
          <Form.Item label={t('guideSerchpersonalBusinessRegistration')} name="isPersonalBusinessRegistration">
            <Radio.Group>
              <Radio value={true}>{t('staffSearch_yes')}</Radio>
              <Radio value={false}>{t('staffSearch_no')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col md={6} xs={24}>
          <Form.Item label={t('guideSerchregistrationNumber')} name="personalBusinessRegistrationNumber">
            <Input />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Row gutter={6}>
            <Col md={14} xs={24}>
              <Form.Item label={t('guideSerchmembershipOfProfessionalInstitutions')} name="professionalInstitutions" className="nta-checkbox-group">
                <Checkbox.Group
                  onChange={v => {
                    if (!v.includes('OTHER')) {
                      form.setFieldsValue({ otherProfessionalInstitution: '' });
                    }
                  }}
                >
                  <Checkbox value="JFG">{t('guideSerchJFG')}</Checkbox>
                  <Checkbox value="JGA">{t('guideSerchJGA')}</Checkbox>
                  <Checkbox value="OTHER">{t('guideSerchOther')}</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
            <Col md={10} xs={24}>
              <Form.Item noStyle dependencies={['professionalInstitutions']}>
                {({ getFieldValue }) => {
                  let professionalInstitutions = getFieldValue('professionalInstitutions') || [];
                  return (
                    <Form.Item name="otherProfessionalInstitution" label="  ">
                      <Input disabled={!professionalInstitutions?.includes('OTHER')} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col md={6} xs={24}>
          <Form.Item
            label={t('guideSerchmaxNumberOfNights')}
            name="maxNumberOfNights"
            rules={[{ required: isMyProfile, message: VALIDATE_REQUIRED }]}
          >
            <InputNumber className="w-100" min={0} addonAfter={t('guideSerchDay')} />
          </Form.Item>
        </Col>
        <Col md={5} xs={24}>
          <Form.Item rules={[{ required: true, message: VALIDATE_REQUIRED }]} label={t('guideSerchUnitExperience')} name="maxNumberExperience">
            <Radio.Group>
              <Radio value={true} className="m-0">
                {t('staffSearch_yes')}
              </Radio>
              <Radio
                value={false}
                className="m-0"
                onClick={() => {
                  form.setFieldsValue({ maxNumber: null });
                }}
              >
                {t('staffSearch_no')}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item noStyle dependencies={['maxNumberExperience']}>
            {({ getFieldValue }) => {
              const maxNumberExperience = getFieldValue('maxNumberExperience');
              return (
                <Form.Item label={t('guideSerchmaxNumber')} name="maxNumber">
                  <InputNumber min={1} disabled={!maxNumberExperience} className="w-100" addonAfter="台口" />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col md={5} xs={24}>
          <Form.Item
            rules={[{ required: isMyProfile, message: VALIDATE_REQUIRED }]}
            label={t('guideSerchinterpretationExperience')}
            name="hasInterpretationExperience"
          >
            <Radio.Group>
              <Radio value={true}>{t('staffSearch_yes')}</Radio>
              <Radio value={false}>{t('staffSearch_no')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item name="interpretationExperienceDetails" wrapperCol={{ xs: 24 }}>
            <Input.TextArea placeholder="詳細" className="w-100 input-value-color" rows={2} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
