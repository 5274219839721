import { useLocation } from 'react-router';

export function useQuery<T = any>(): T {
  const query = new URLSearchParams(useLocation().search);
  console.log(query);
  return query as any;
}

export const getUrlQueryValue = (query: string) => {
  return new URLSearchParams(window.location.search)?.get(query);
};
