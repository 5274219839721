import RatingInput from '@/components/app/formItem/RatingInput';
import { HotelMealCrowdedness } from '@/constants';
import { Row, Col, Form, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  disabled: boolean;
};
const requiredRule = {
  required: true,
  message: <Trans>VALIDATE_REQUIRED</Trans>,
};
export default function AccommodationRateItem({ disabled }: Props) {
  const { t } = useTranslation();
  return (
    <>
      <Row gutter={6}>
        <Col md={5} xs={12}>
          <Form.Item label={t('facilityReviewRate')} name="comprehensiveRate" rules={[requiredRule]}>
            <RatingInput disabled={disabled} className="input-value-color" />
          </Form.Item>
        </Col>
        <Col md={5} xs={12}>
          <Form.Item label={t('facilityReview_FacilityRate')} name="facilityRate">
            <RatingInput disabled={disabled} className="input-value-color" />
          </Form.Item>
        </Col>
        <Col md={4} xs={12}>
          <Form.Item label={t('facilityReview_RoomRate')} name="roomRate">
            <RatingInput disabled={disabled} className="input-value-color" />
          </Form.Item>
        </Col>
        <Col md={4} xs={12}>
          <Form.Item label={t('facilityReview_CustomerServiceRate')} name="customerServiceRate">
            <RatingInput disabled={disabled} className="input-value-color" />
          </Form.Item>
        </Col>
        <Col md={6} xs={12}>
          <Form.Item label={t('facilityReview_BathRate')} name="bathRate">
            <RatingInput disabled={disabled} className="input-value-color" />
          </Form.Item>
        </Col>
      </Row>
      <div>朝食会場について*夕食は食事に登録下さい</div>
      <Row gutter={6}>
        <Col md={6} xs={12}>
          <Form.Item label={t('tourEvaluation_hotelMealFacilityRate')} name="hotelMealFacilityRate">
            <RatingInput disabled={disabled} className="input-value-color" />
          </Form.Item>
        </Col>
        <Col md={6} xs={12}>
          <Form.Item label={t('tourEvaluation_HotelMealContentRate')} name="hotelMealContentRate">
            <RatingInput disabled={disabled} className="input-value-color" />
          </Form.Item>
        </Col>
        <Col md={6} xs={12}>
          <Form.Item label={t('tourEvaluation_HotelMealCustomerServiceRate')} name="hotelMealCustomerServiceRate">
            <RatingInput disabled={disabled} className="input-value-color" />
          </Form.Item>
        </Col>
        <Col md={6} xs={12}>
          <Form.Item label={t('tourEvaluation_HotelMealCrowdedness')} name="hotelMealCrowdedness">
            <Select options={HotelMealCrowdedness.__getSelectOptions()} disabled={disabled} className="input-value-color" />
          </Form.Item>
        </Col>
        <Col md={24} xs={24}>
          <Form.Item label={t('facilityReviewDetail')} name="details">
            <TextArea autoSize={{ minRows: 3 }} className="input-value-color" disabled={disabled} />
          </Form.Item>
        </Col>
        <Col md={24} xs={24}>
          <Form.Item label={t('tourEvaluation_hotelMealRecommendation')} name="recommendationInSameArea">
            <TextArea autoSize={{ minRows: 3 }} className="input-value-color" disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
