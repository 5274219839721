export default {
  guideSearch_startDate: '表示開始日',
  guideSearch_rank: 'ランク',
  guideSearch_prefecture: '都道府県',
  guideSearch_guideName: 'ガイド名',
  guideSearch_language: '言語',
  guideSearch_maxNights: '最大宿泊日数',
  guideSearch_displayWithLicense: 'ライセンス有りのみ表示',
  guideSearch_types: '対応可能種別',

  guideSearch_Fit: 'FIT',
  guideSearch_Group: '団体',
  guideSearch_Incentive: 'インセンティブ',
  guideSearch_Student: '学生',
  guideSearch_SimultaneousInterpretation: '同時通訳',
  guideSearch_ConsecutiveInterpretation: '逐次通訳',
  guideSearch_MedicalInterpretation: '医療通訳',
  guideSearch_AreasGuide: '対応可能エリア',

  guideSearch_Hokkaido: '北海道',
  guideSearch_Tohoku: '東北',
  guideSearch_Nikko: '日光',
  guideSearch_Tokyo: '東京',
  guideSearch_Kawaguchiko: '箱根',
  guideSearch_Hakone: '河口湖',
  guideSearch_Nagoya: '名古屋',
  guideSearch_Kyoto: '京都',
  guideSearch_Osaka: '大阪',
  guideSearch_Nara: '奈良',
  guideSearch_Takayama: '高山',
  guideSearch_Kanazawa: '金沢',
  guideSearch_Shirakawago: '白川郷',
  guideSearch_AlpineRoute: 'アルペンルート',
  guideSearch_Kumanokodo: '熊野古道',
  guideSearch_Sanin: '山陰',
  guideSearch_Okayama: '岡山',
  guideSearch_Hiroshima: '広島',
  guideSearch_Shikoku: '四国',
  guideSearch_Kyushu: '九州',
  guideSearch_Okinawa: '沖縄',
  guideSearch_OkinawaIsolatedIsland: '沖縄（離島）',
  guideSearch_title: 'ガイド検索',
  guideSearch_usageSituation: '利用状況',
  guideSearch_valid: '有効',
  guideSearch_invalid: '無効',
  guideSearch_membershipOfProfessionalInstitution: '所属団体',
  guideSearch_others: 'その他',
  guideSearch_new: '新規',
  guideSearch_copy: 'コピー',
  guideSearch_edit: '編集',
  guideSearch_delete: '削除',
  guideSearch_search: '検索',
  guideSearch_noNumber: 'No.',
  guideSearch_name: '氏名(漢字)',
  guideSearch_lastUpdatedDate: '最終更新日',
  guideSearch_lastCreateDate: '作成日',
  guideSearch_lastUpdatedBy: '最終更新者',
  guideSearch_type: '種別',
  guideSearch_area: 'エリア',
  guideSearch_guideRating: '評価',
};
