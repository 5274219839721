import { Rate, Select, SelectProps } from 'antd';
import React from 'react';

export default function RatingInput(props: SelectProps<any>) {
  return (
    <Select {...props} className="input-value-color">
      {[5, 4, 3, 2, 1].map(rate => {
        return (
          <Select.Option value={rate}>
            <Rate value={rate} allowHalf={true} disabled style={{ fontSize: 12, color: '#666' }} />
          </Select.Option>
        );
      })}
    </Select>
  );
}
