import { Modal, Form, message, Button, Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.less';
import { ApiSdk } from '@/httpclient';
import { CreateUpdateTourGuideModel, TourDetailModel } from '@/__generated';
import PageLoading from '@/components/PageLoading';
import { DEFAULT_DATE_FORMAT, ERROR_EXCEPTION } from '@/constants';
import dayjs from 'dayjs';
import { TourSourceType, scrollableModalBodyStyle } from '@/config';
import GLUpdateTour from './GLUpdateTour';
import UNUpdateTour from './UNUpdateTour';
import { checkTourDataBeforeUpdate } from './checkTourData';
import { createTourDataBeforeSubmit } from './validation';
import { getDateOnlyAsString, hasAnyFieldValue } from '@/helpers/string';
import { LockOutlined, SaveOutlined } from '@ant-design/icons';
import { useAppContext } from '@/contexts/AppContext';
import tourService from '@/services/tourService';
import { ShortTourGuideInfo } from '../TourFeedback/TourEvaluation';
import { TourProvider } from '@/contexts/TourContext';
type Props = {
  onClose: (reload?: boolean) => void;
  visible: boolean;
  tourId?: string;
  showGuideEvaluation: (shortGuideInfo: ShortTourGuideInfo) => void;
};
interface UnUpdateTourGuideModel extends CreateUpdateTourGuideModel {
  tourSource: number;
}
const keepAliveTimeout = 5 * 60 * 1000; //5 minutes
let keepAliveInterval: any = null;

export default function AdminUpdateTourModal({ visible, onClose, tourId, showGuideEvaluation }: Props) {
  const { t } = useTranslation();
  const [tour, setTour] = React.useState<TourDetailModel>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [onUpdating, setUpdating] = React.useState<boolean>(false);
  const { auth } = useAppContext();
  const [form] = Form.useForm();
  const [tabKey, setTabKey] = React.useState<string>('guide-0');
  const [sessionId, setSessionId] = React.useState<string>();
  React.useEffect(() => {
    if (tourId && auth?.roleClaims.length && sessionId) {
      setLoading(true);
      ApiSdk.TourService.getTourForUpdate({ id: tourId, currentRole: auth?.roleClaims[0], sessionId: sessionId })
        .then(tour => {
          setTour(tour);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [tourId, auth, sessionId]);

  React.useEffect(() => {
    if (visible && tourId) {
      setSessionId(tourService.createSessionId());
    }
  }, [visible, tourId]);

  React.useEffect(() => {
    if (sessionId && tour && auth?.roleClaims.length && tour.lockInfo?.locked === false) {
      const hasGuideLockedByMe = tour?.tourGuides?.some(x => x.lockInfo?.guideInfoLocked === false || x.lockInfo?.briefingLocked === false);
      const isLocked = hasGuideLockedByMe || tour.lockInfo?.locked === false;
      if (isLocked) {
        keepAliveInterval = window.setInterval(() => {
          tourService.keepAlive(tour.id || '', auth?.roleClaims[0]);
        }, keepAliveTimeout);
        const onUnlock = () => {
          tourService.unlockTour(tour.id || '', auth?.roleClaims[0]);
        };
        const unlockOnCloseTab = () => {
          tourService.unlockOnTabClose(tour.id || '', auth?.roleClaims[0], sessionId);
        };
        const onTabActiveCallback = () => {
          if (!document.hidden) {
            tourService.checkLockSessionAlive(tour.id || '', auth?.roleClaims[0], sessionId);
          }
        };
        const onBrowserTabClose = tourService.onBrowserTabClose(unlockOnCloseTab);
        const onTabReActive = tourService.onTabReActive(onTabActiveCallback);
        return () => {
          onUnlock();
          onBrowserTabClose();
          onTabReActive();
          if (keepAliveInterval) {
            window.clearInterval(keepAliveInterval);
          }
        };
      }
    }
    return () => {};
  }, [tour, sessionId, auth]);
  const _saveGLTour = (formBody: any) => {
    setUpdating(true);
    createTourDataBeforeSubmit(formBody);
    if (sessionId) {
      ApiSdk.TourService.update({
        body: {
          ...formBody,
          sessionId: sessionId,
        },
      })
        .then(res => {
          if (res.success) {
            onClose(true);
            message.success(t('UPDATE_TOUR_SUCCESS'));
          } else {
            message.error(t(res.message || ''));
          }
        })
        .catch(() => {
          message.error(ERROR_EXCEPTION);
        })
        .finally(() => {
          setUpdating(false);
        });
    }
  };

  const onGLUpdateTour = () => {
    form
      .validateFields()
      .then(v => {
        const formBody: any = {
          id: tour?.id,
          fromDate: dayjs(v.fromDate).format(DEFAULT_DATE_FORMAT) as any,
          toDate: dayjs(v.toDate).format(DEFAULT_DATE_FORMAT) as any,
          status: v.status,
          reservationForm: v.reservationForm,
          tourPurpose: v.tourPurpose,
          tourId: v.tourId,
          tourName: v.tourName,
          tourNumber: v.tourNumber,
          guestNationalityCode: v.guestNationalityCode,
          agentName: v.agentName,
          saleStaffId: v.saleStaffId,
          saleStaffName: v.saleStaffId ? null : v.saleStaffName,
          arrangementPicId: v.arrangementPicId,
          arrangementPicName: v.arrangementPicId ? null : v.arrangementPicName,
          picId: v.picId,
          picName: v.picId ? null : v.picName,
          noOfAdultEstimated: v.noOfAdultEstimated,
          noOfChildAEstimated: v.noOfChildAEstimated,
          noOfChildBEstimated: v.noOfChildBEstimated,
          noOfInfantEstimated: v.noOfInfantEstimated,
          noOfLeadEstimated: v.noOfLeadEstimated,
          noOfAdultBooked: v.noOfAdultBooked,
          noOfChildABooked: v.noOfChildABooked,
          noOfChildBBooked: v.noOfChildBBooked,
          noOfInfantBooked: v.noOfInfantBooked,
          noOfLeadBooked: v.noOfLeadBooked,
          hideGuides: v.hideGuides,
          tourGuides: v.tourGuides?.map(tourGuide => {
            const guide: CreateUpdateTourGuideModel = {
              id: tourGuide.id,
              guideId: tourGuide.guide?.value,
              guideName: tourGuide.guide?.label,
              languageCode: tourGuide.language?.value || '',
              languageName: tourGuide.language?.label,
              status: tourGuide.status,
              briefingType: tourGuide.briefingType,
              briefingDate: getDateOnlyAsString(tourGuide.briefingDate),
              briefingTime: tourGuide.briefingTime,
              expectedDateOfMaterials: getDateOnlyAsString(tourGuide.expectedDateOfMaterials),
              desiredBriefingDate1: getDateOnlyAsString(tourGuide.desiredBriefingDate1),
              desiredBriefingDate2: getDateOnlyAsString(tourGuide.desiredBriefingDate2),
              desiredBriefingDate3: getDateOnlyAsString(tourGuide.desiredBriefingDate3),
              desiredBriefingTime1: tourGuide.desiredBriefingTime1,
              desiredBriefingTime2: tourGuide.desiredBriefingTime2,
              desiredBriefingTime3: tourGuide.desiredBriefingTime3,
              desiredDateOfMaterial1: getDateOnlyAsString(tourGuide.desiredDateOfMaterial1),
              desiredDateOfMaterial2: getDateOnlyAsString(tourGuide.desiredDateOfMaterial2),
              desiredDateOfMaterial3: getDateOnlyAsString(tourGuide.desiredDateOfMaterial3),
              desiredTimeOfMaterial1: tourGuide.desiredTimeOfMaterial1,
              desiredTimeOfMaterial2: tourGuide.desiredTimeOfMaterial2,
              desiredTimeOfMaterial3: tourGuide.desiredTimeOfMaterial3,
              ntaComments: tourGuide.ntaComments,
              attachments: tourGuide.attachments,
              businessDays: tourGuide.businessDays?.filter(hasAnyFieldValue)?.map(day => ({
                ...day,
                date: day.date ? dayjs(day.date).format(DEFAULT_DATE_FORMAT) : day.date,
              })),
            };
            return guide;
          }),
        };

        checkTourDataBeforeUpdate(formBody, tour).then(res => {
          if (res.submit) {
            _saveGLTour(res.formData);
          }
        });
      })
      .catch(err => {
        if (err?.errorFields.length) {
          const fieldsName = err.errorFields[0].name;
          if (err.errorFields[0].name.length === 1) {
            form.scrollToField(fieldsName, { behavior: 'smooth' });
          } else if (err.errorFields[0].name.length >= 2) {
            setTabKey(`guide-${fieldsName[1]}`);
            form.scrollToField(fieldsName, { behavior: 'smooth' });
          }
        }
      });
  };

  const onSubmit = () => {
    if (tour) {
      onGLUpdateTour();
    }
  };

  const modalTitle = React.useMemo(() => {
    if (tour?.lockInfo?.locked) {
      return (
        <div>
          {t('Update tour')}
          <span className="tour-lock-icon" style={{ marginLeft: 5 }}>
            [
            <LockOutlined style={{ marginRight: 5 }} />
            {tour?.lockInfo.lockedBy}]
          </span>
        </div>
      );
    }
    return t('Update tour');
  }, [tour]);

  return (
    <Modal
      className="nta-custom-form create-tour-form"
      bodyStyle={scrollableModalBodyStyle}
      visible={visible}
      onCancel={() => onClose()}
      style={{ top: 10, maxWidth: 1200 }}
      width={'100%'}
      title={modalTitle}
      okButtonProps={{ loading: onUpdating, disabled: !tour || tour?.lockInfo?.locked, icon: <SaveOutlined />, className: 'save-btn' }}
      cancelButtonProps={{ style: { float: 'left' }, loading: onUpdating, disabled: !tour }}
      okText={t('Update tour')}
      cancelText={t('Close')}
      onOk={onSubmit}
      maskClosable={false}
    >
      <TourProvider>
        <div>
          {loading ? (
            <div style={{ marginTop: 200 }}>
              <PageLoading />
            </div>
          ) : tour ? (
            <GLUpdateTour
              tabKey={tabKey}
              setTabKey={setTabKey}
              form={form}
              tour={tour}
              visible={visible}
              loading={onUpdating}
              showGuideEvaluation={showGuideEvaluation}
            />
          ) : (
            <Result
              status="404"
              title="404"
              subTitle={t('TourNotExists')}
              extra={
                <Button onClick={() => onClose(true)} type="primary">
                  Close
                </Button>
              }
            />
          )}
        </div>
      </TourProvider>
    </Modal>
  );
}
