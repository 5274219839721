export default {
  notification_Title: 'お知らせ管理',
  notification_Search_From: '掲載期間(From)',
  notification_Search_To: '掲載期間(To)',
  notification_Search_Status: '状態',
  notification_Search_Subject: '件名',
  notification_Search_Contents: '内容',
  notification_Action_Search: '搜索',
  notification_Action_Create: '新規',
  notification_Action_Copy: 'コピー',
  notification_Action_Edit: '編集',
  notification_Action_Save: '登録',
  notification_Action_Cancel: '削除',
  notification_Action_Delete: '削除',
  notification_Search_Search_Results: '検索件数',
  notification_Search_Displayed_Results: '表示件数',
  notification_Result_No: '順番',
  notification_Result_Status: '状態',
  notification_Result_Post_Period: '掲載期間',
  notification_Result_Subject: '件名',
  notification_Result_Contents: '内容',
  notification_Result_Created_Date_And_Time: '作成日時',
  notification_Result_Created_By: '作成者',
  notification_Result_Last_Updated_Date: '更新日時',
  notification_Result_Last_Updated_By: '更新者',
  notification_attachment_upload: '添付ファイル',
  notification_attachment_delete: '削除',
  notification_update_date: '更新日時',
  notification_update_by: '更新者',
  notification_date_from_to_error: '掲載の開始日は終了日より前である必要があります。',
  notification_required_error: '必須項目です。',
  notification_mail_send_confirm: 'ガイドにメールを送信しますか。',
};
