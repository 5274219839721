import React from 'react';
import { Layout } from 'antd';
import { Route, Switch, Redirect } from 'react-router-dom';
import GuideListing from '@/pages/UN/GuideListing';
import GuideInfo from '@/pages/UN/GuideInfo';

import Page403 from '@/pages/UN/Page403';
import { useLocation } from 'react-router';
import Helmet from 'react-helmet';
import i18n from '@/helpers/i18n';
type Props = {};

const AppRoutes = [
  { path: '/un/guide-listing', component: GuideListing, exact: true },
  { path: '/un/guide-info/:gid', component: GuideInfo, exact: true },
];
export default function UNLayout({}: Props) {
  const { search } = useLocation();
  const token = React.useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('token');
  }, [search]);
  return (
    <Layout style={{ minHeight: '100vh', minWidth: 1350 }}>
      <Helmet htmlAttributes={{ lang: i18n.language }} />

      <Layout.Content>
        <Switch>
          {token
            ? AppRoutes.map((r, idx) => {
                return <Route key={idx} {...r} />;
              })
            : null}
          <Route path="/un/403" component={Page403} exact={true} />;
          <Redirect path="*" to="/un/403" />;
        </Switch>
      </Layout.Content>
    </Layout>
  );
}
