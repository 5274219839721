import { DEFAULT_DISPLAY_DATE_FORMAT } from '@/constants';
import {} from '@/__generated';
import { CloseCircleFilled, SaveOutlined } from '@ant-design/icons';
import { Col, Input, Modal, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  day: string;
  visible: boolean;
  onClose: () => void;
  onChange: (isUnavailable: boolean, isWfAnotherDepartment: boolean, wfAnotherDepartmentDetails: string | null) => void;
  isUnavailable?: boolean;
  isWfAnotherDepartment: boolean;
  wfAnotherDepartmentDetails?: string;
};

const elementStyle = {
  display: 'flex',
  borderRadius: 10,
  alignItems: 'center',
  cursor: 'pointer',
  border: '1px solid #f0f0f0',
  transition: 'all 300ms ease 0s',
  marginBottom: 5,
};
export default function DayStatusSelectModal({ day, visible, onChange, onClose, ...props }: Props) {
  const { t } = useTranslation();
  const [isUnavailable, setIsUnavailable] = React.useState<boolean>(!!props.isUnavailable);
  const [isWfAnotherDepartment, setIsWfAnotherDepartment] = React.useState<boolean>(props.isWfAnotherDepartment);

  const [text, setText] = React.useState<string | null | undefined>(props.wfAnotherDepartmentDetails);

  React.useEffect(() => {
    setIsUnavailable(!!props.isUnavailable);
  }, [props.isUnavailable]);
  React.useEffect(() => {
    if (visible) {
      setText('');
    }
  }, [visible]);
  React.useEffect(() => {
    setIsWfAnotherDepartment(props.isWfAnotherDepartment);
  }, [props.isWfAnotherDepartment]);

  React.useEffect(() => {
    setText(props.wfAnotherDepartmentDetails);
  }, [props.wfAnotherDepartmentDetails]);
  return (
    <Modal
      className="nta-custom-form create-tour-form"
      visible={visible}
      onCancel={() => onClose()}
      width={400}
      title={moment(day).format(DEFAULT_DISPLAY_DATE_FORMAT)}
      okText={t('Save')}
      cancelText={t('Close')}
      maskClosable={false}
      okButtonProps={{ icon: <SaveOutlined />, className: 'save-btn' }}
      onOk={() => {
        onChange(isUnavailable, isWfAnotherDepartment, text || '');
      }}
    >
      <Row>
        <Col
          xs={24}
          md={24}
          onClick={() => {
            setIsUnavailable(d => !d);
          }}
          style={{ ...elementStyle, ...(isUnavailable ? { borderColor: '#e91e63' } : { borderColor: '#f0f0f0' }) }}
        >
          <div
            style={{
              width: 70,
              height: 70,
              borderRadius: 70,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CloseCircleFilled style={{ fontSize: 50, color: '#e91e63' }} />
          </div>
          <div style={{ marginLeft: 20, fontSize: 20 }}>{t('Unavailable')}</div>
        </Col>
        <Col
          xs={24}
          onClick={() => {
            setIsWfAnotherDepartment(d => !d);
          }}
          md={24}
          style={{
            ...elementStyle,
            ...(isWfAnotherDepartment ? { borderColor: '#4176d9' } : { borderColor: '#f0f0f0' }),
          }}
        >
          <div
            style={{
              width: 70,
              height: 70,
              borderRadius: 70,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CloseCircleFilled style={{ fontSize: 50, color: '#3f51b5' }} />
          </div>
          <div style={{ marginLeft: 20, fontSize: 20 }}>{t('Works in another department')}</div>
        </Col>

        {isWfAnotherDepartment && (
          <Col style={{ marginTop: 10 }} md={24} xs={24}>
            <Input.TextArea
              className="w-100"
              value={text || ''}
              rows={3}
              onChange={e => {
                setText(e.target.value);
              }}
            />
          </Col>
        )}
      </Row>
    </Modal>
  );
}
