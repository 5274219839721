export default {
  tourSearchFrom: 'From',
  tourSearchTo: 'To',
  'Reservation form': 'Reservation form',
  'Travel purpose': 'Travel purpose',
  'In charge of tour': 'PIC',
  'Business Day': 'Business Day',
  'Daily Allowance': 'Daily Allowance',
  Availability: 'Availability',
  'Stay overnight?': 'Stay overnight?',
  'Pre night?': 'Pre night?',
  Briefing: 'Briefing',
  'Briefing Date And Time': 'Briefing Date And Time',
  'Expected Date Of Arrival Of Materials': 'Expected Date Of Arrival Of Materials',
  'Briefing desired day 1': 'Briefing desired day 1',
  'Briefing desired date 2': 'Briefing desired date 2',
  'Briefing desired date 3': 'Briefing desired date 3',
  'Material arrival desired date and time 1': 'Material arrival desired date and time 1',
  'Material arrival desired date and time 2': 'Material arrival desired date and time 2',
  'Material arrival desired date and time 3': 'Material arrival desired date and time 3',
  'Notes for Guides': 'Notes for Guides',
  'Feedback(Tour)': 'Feedback(Tour)',
  'Add Date': 'Add Date',
  'Delete Date': 'Delete Date',
  'Hide delete date': 'Hide delete date',
  'Cancel Date': 'Cancel Date',
  'Hide cancel date': 'Hide cancel date',
  'Display cancelled dates': 'Display cancelled dates',
  hideOtherGuideInfo: 'Hide other guide information.',
  'Show previous comments': 'Show previous comments',
  'Contact Items From NTA': 'Contact Items From NTA',
  EDIT_TOUR_SESSION_TIMEOUT_TITLE: 'Warning',
  EDIT_TOUR_SESSION_TIMEOUT_CONTENT: 'The editing session has ended. Your updates are not saved. Please click OK button to reload the page.',
  tourMessagePage_Title: 'Tour messages',
  tourMessagePage_EmailEFax: 'Email/EFax',
  tourMessagePage_FromTo: 'From/To',
  tourMessagePage_Subject: 'Subject',
  tourMessagePage_Status: 'Status',
  tourMessagePage_TourNo: 'Tour No',
  tourMessagePage_TourName: 'Tour Name',
  tourMessagePage_Guide: 'Guide',
  tourMessagePage_EmailFax: 'Email/Fax number',
  tourMessagePage_FromDate: 'From date',
  tourMessagePage_ToDate: 'ToDate',
};
