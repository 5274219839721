import ZTable, { IColumnType, ITableRef } from '@/components/ZTable';
import MainContent from '@/Layout/MainContent';
import { CopyOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Tag, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CreateTourModal from './CreateTourModal';
import lodash from 'lodash';
import { Tour, TourDto } from '@/__generated';
import { formatDisplayDate, TourGuideStatusType, TourSourceType, TourStatusType } from '@/config';
import UpdateTourModal from './UpdateTourModal';
import TourGuideEditModal from './TourGuideEditModal';
import TourInfoModal from './TourInfoModal';
import { useAppContext } from '@/contexts/AppContext';
import { hasPermissionCreateTour, hasPermissionUpdateTour, isGuide, isGuideOnly } from '@/services/roleService';
import { useHistory, useLocation } from 'react-router';
import { totalBookedPassengers, totalEstimatedPassengers } from './validation';
import TourEvaluation, { ShortTourGuideInfo } from '../TourFeedback/TourEvaluation';
import { createCopyTourData } from './checkTourData';
import FullPageLoading from '@/components/FullPageLoading';
import { ApiSdk } from '@/httpclient';
import NTADatePicker from '@/components/DatePicker';
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import GuideInfoModal from '../GuidesSchedule/GuideInfoModal';
import TourSearchForm from './SearchForm';
import i18n from '@/helpers/i18n';
import { GuideSeisanStatus, TourSeisanStatus } from '@/constants';

const PAGE_TITLE = 'Manage Tours';
const { Paragraph } = Typography;

const displayBookedNoPax = (tour: Tour) => {
  return [
    TourStatusType.Confirmed,
    TourStatusType.ConfirmedAcceptingGuest,
    TourStatusType.ConfirmingBilling,
    TourStatusType.BillingComplete,
  ].includes(tour.status);
};
const getGuideTooltip = (tour: TourDto) => {
  const guides = lodash.sortBy(
    tour.tourGuides,
    x => x.guideName,
    x => x.status,
    x => x.languageName,
  );
  return (
    <ul>
      {guides?.map((g, i) => {
        const name = `<span>${g.guideName?.trim() || 'N/A'} - ${i18n.t(TourGuideStatusType.__getValue(g.status))} - <span style="color:${
          g.hasLicense ? 'inherit' : '#e91e63'
        }">${g.languageName}</span></span>`;
        return <li key={i} dangerouslySetInnerHTML={{ __html: name }}></li>;
      })}
    </ul>
  );
};
const getGuideSeisanTooltip = (tour: TourDto) => {
  const guides = lodash.sortBy(
    tour.tourGuides,
    x => x.guideName,
    x => x.status,
    x => x.languageName,
  );
  return (
    <ul>
      {guides?.map((g, i) => {
        const name = `<span>${g.guideName?.trim() || 'N/A'} - ${i18n.t(GuideSeisanStatus.__getLabel(g.guideSeisanStatus))}</span>`;
        return <li key={i} dangerouslySetInnerHTML={{ __html: name }}></li>;
      })}
    </ul>
  );
};
export default function Tours() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { auth } = useAppContext();
  const zTb = React.useRef<ITableRef>();
  const [displayCreateTour, setDisplayCreateTour] = useState<boolean>(false);
  const [editingTourId, setEditingTourId] = useState<string>();
  const [tourInfoId, setTourInfoId] = useState<string | undefined>();
  const [guideEvaluation, setGuideEvaluation] = React.useState<ShortTourGuideInfo>();
  const [copyTourData, setCopyTourData] = useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [guideInfoId, setGuideInfoId] = React.useState<string>();
  React.useEffect(() => {
    const query = new URLSearchParams(search);
    const tourId = query.get('tourId');
    if (tourId) {
      if (hasPermissionUpdateTour(auth)) {
        setEditingTourId(tourId);
      } else {
        setTourInfoId(tourId);
      }
      history.replace('/cms/tours');
    }
  }, []);
  const editable = React.useMemo(() => {
    return hasPermissionUpdateTour(auth);
  }, [auth]);
  const canCreateTour = React.useMemo(() => {
    return hasPermissionCreateTour(auth);
  }, [auth]);
  const extraBts = React.useMemo(() => {
    if (!editable) {
      return [];
    }
    return [
      <Button
        key="create"
        type="primary"
        className="nta-create-btn"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          setDisplayCreateTour(true);
        }}
      >
        {t('Create Tour')}
      </Button>,
    ];
  }, [editable, i18n.language]);

  const { columns, W } = React.useMemo(() => {
    const fixedLeft = window.innerWidth > 1000 ? 'left' : undefined;

    let cols: IColumnType<TourDto>[] = [
      {
        width: 50,
        fixed: 'left',
        title: 'No.',
        dataIndex: 'createdAt',
        render: (v, t, i) => <b>{`${i + 1}`}</b>,
        align: 'center',
        sorter: true,
      },

      {
        width: 150,
        fixed: fixedLeft,
        title: 'Tour duration(From)',
        dataIndex: 'fromDate',
        sorter: true,
        render: v => {
          return formatDisplayDate(v);
        },
        ellipsis: true,
      },
      {
        width: 150,
        fixed: fixedLeft,
        title: 'Tour duration(To)',
        dataIndex: 'toDate',
        sorter: true,
        render: v => {
          return formatDisplayDate(v);
        },
        ellipsis: true,
      },
      {
        width: 200,
        fixed: fixedLeft,
        title: 'Tour Name',
        dataIndex: 'tourName',
        ellipsis: true,
        sorter: true,
        render: (v, tour) => {
          return (
            <span
              className="span-link"
              onClick={() => {
                setTourInfoId(tour.id);
              }}
            >
              {v}
            </span>
          );
        },
      },
      {
        width: 160,
        title: 'tourDetail_TourNoTourSeisan',
        dataIndex: 'tourNumber',
        ellipsis: true,
        sorter: true,
      },
      {
        width: 100,
        title: 'ツアーID',
        dataIndex: 'tourId',
        ellipsis: true,
        sorter: true,
      },
      {
        width: 140,
        title: 'Tour Status',
        dataIndex: 'status',
        ellipsis: true,
        sorter: true,
        render: v => {
          return t(TourStatusType.__getValue(v));
        },
      },
      // number of pax
      {
        width: 80,
        title: 'Number of pax',
        ellipsis: true,
        align: 'center',
        render: (v, tour) => {
          if (displayBookedNoPax(tour as any)) {
            return <span style={{ color: 'blue' }}>{totalBookedPassengers(tour as any)}</span>;
          }
          return <span>{totalEstimatedPassengers(tour as any)}</span>;
        },
      },
      {
        width: 120,
        title: 'AGT名',
        dataIndex: 'agentName',
        ellipsis: true,
        sorter: true,
      },

      {
        width: 120,
        title: 'guideSerchnationality',
        dataIndex: 'guestNationalityName',
        ellipsis: true,
        sorter: true,
      },
      {
        width: 100,
        title: 'Language',
        dataIndex: 'guideLanguage',
        render: (v, tour) => {
          const guides = lodash.sortBy(
            tour.tourGuides,
            x => x.guideName,
            x => x.status,
            x => x.languageName,
          );
          return (
            <Tooltip title={getGuideTooltip(tour)}>
              <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: guides
                      .map(g => `<span style="color:${g.hasLicense ? '#272B41' : '#e91e63'}">${g.languageName || '&nbsp;'}</span>`)
                      .join('<br/>'),
                  }}
                ></span>
              </Paragraph>
            </Tooltip>
          );
        },
      },
      {
        width: 130,
        title: 'Guide Status',
        dataIndex: 'guideStatus',
        render: (v, tour) => {
          const guides = lodash.sortBy(
            tour.tourGuides,
            x => x.guideName,
            x => x.status,
            x => x.languageCode,
          );
          return (
            <Tooltip title={getGuideTooltip(tour)}>
              <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}>
                <span dangerouslySetInnerHTML={{ __html: guides.map(g => t(TourGuideStatusType.__getValue(g.status))).join('<br/>') }}></span>
              </Paragraph>
            </Tooltip>
          );
        },
      },
      {
        width: 150,
        title: 'tourDetail_GuideSeisanStatus',
        dataIndex: 'guideSeisanStatus',
        render: (v, tour) => {
          const guides = lodash
            .sortBy(
              tour.tourGuides,
              x => x.guideName,
              x => x.status,
              x => x.languageName,
            )
            .filter(x => x.guideSeisanStatus);
          return (
            <Tooltip title={getGuideSeisanTooltip(tour)}>
              <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}>
                <span dangerouslySetInnerHTML={{ __html: guides.map(g => GuideSeisanStatus.__getLabel(g.guideSeisanStatus)).join('<br/>') }}></span>
              </Paragraph>
            </Tooltip>
          );
        },
      },

      { width: 130, title: 'Sales', ellipsis: true, dataIndex: 'saleStaffName', sorter: true },
      { width: 130, title: 'Tour Manager', ellipsis: true, dataIndex: 'picName', sorter: true },
      {
        width: 100,
        title: '報告書',
        align: 'center',
        render: (record: TourDto) => {
          const guidesHasEvaluations = record.tourGuides?.filter(x => x.hasEvaluation);
          return guidesHasEvaluations?.length ? (
            <Tooltip
              title={
                <ul>
                  {guidesHasEvaluations.map(guide => {
                    return (
                      <li
                        key={guide.id}
                        style={{ color: 'white', cursor: 'pointer' }}
                        onClick={() => {
                          setGuideEvaluation({ guideId: guide.id || '' });
                        }}
                      >
                        {guide.guideName}
                      </li>
                    );
                  })}
                </ul>
              }
            >
              <FeatherIcon icon="edit" style={{ width: 16 }} />
            </Tooltip>
          ) : (
            <></>
          );
        },
      },
    ];
    const isGuide = isGuideOnly(auth?.roleClaims);
    if (!isGuide) {
      cols.splice(1, 0, {
        width: 50,
        fixed: fixedLeft,
        title: 'UN',
        dataIndex: 'tourSource',
        sorter: true,
        render: tourSource => (tourSource === TourSourceType.UnityNext ? '〇' : ''),
        align: 'center',
      });
      cols.splice(11, 0, {
        width: 150,
        title: 'Guide Name',
        dataIndex: 'guideName',
        render: (v, tour) => {
          const guides = lodash.sortBy(
            tour.tourGuides,
            x => x.guideName,
            x => x.status,
            x => x.languageName,
          );
          return (
            <Tooltip title={getGuideTooltip(tour)}>
              <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}>
                {guides.map(g => {
                  return (
                    <Button
                      type="link"
                      style={{ display: 'block' }}
                      size="small"
                      key={g.id}
                      onClick={() => {
                        if (g.guideId) {
                          setGuideInfoId(g.guideId);
                        }
                      }}
                    >
                      {g.guideName?.trim() || 'N/A'}
                    </Button>
                  );
                })}
              </Paragraph>
            </Tooltip>
          );
        },
      });
      cols.splice(15, 0, {
        width: 130,
        title: 'tourDetail_TourSeisanStatus',
        ellipsis: true,
        dataIndex: 'seisanStatus',
        render: v => (
          <Tag color={TourSeisanStatus.__getColor(v)} style={{ color: TourSeisanStatus.__getTextColor(v) }}>
            {TourSeisanStatus.__getLabel(v)}
          </Tag>
        ),
      });
    }
    return {
      columns: cols,
      W: lodash.sumBy(cols, t => t.width as any),
    };
  }, [i18n.language, auth]);

  const onCopy = (tour: TourDto) => {
    Modal.confirm({
      cancelText: t('Cancel'),
      okText: t('Save'),
      title: 'Select tour start date',
      content: <NTADatePicker className="w-100" id="copy-date-picker" allowClear={false} defaultValue={moment(tour.fromDate)} />,
      onOk: () => {
        const startDate = (document.getElementById('copy-date-picker') as any)?.value;
        if (startDate) {
          setLoading(true);
          ApiSdk.TourService.getTourDetail({ id: tour.id || '' })
            .then(res => {
              setCopyTourData(createCopyTourData(res, moment(startDate)));
              setDisplayCreateTour(true);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      },
    });
  };
  const copyButton = React.useMemo(() => {
    if (isGuide(auth?.roleClaims)) {
      return undefined;
    }
    return editable
      ? [
          {
            render: (record: any) => {
              return (
                <Tooltip title={t('zTable.copy')}>
                  <Button
                    style={{ backgroundColor: '#fff95c', borderRadius: 0 }}
                    icon={<CopyOutlined />}
                    type="ghost"
                    size="small"
                    onClick={() => {
                      onCopy(record);
                    }}
                  ></Button>
                </Tooltip>
              );
            },
          },
        ]
      : undefined;
  }, [editable, auth]);
  return (
    <MainContent title={t(PAGE_TITLE)} extraBtns={canCreateTour ? extraBts : []}>
      <Helmet title={t(PAGE_TITLE)} />
      <FullPageLoading loading={loading} />
      <div className="ant-layout-content-body">
        <ZTable
          defaultFilter={{
            displayTourUn: true,
            displayTourGl: true,
          }}
          columns={columns}
          primaryKey="id"
          tableRef={zTb}
          url="/tours/get-tours"
          bordered
          order_by="createdAt"
          order_by_asc={false}
          allowEdit={editable}
          onEdit={record => {
            setEditingTourId(record.id);
          }}
          allowDelete={false}
          searchForm={TourSearchForm}
          scroll={{ x: W + 80 }}
          size="small"
          actionColumnWidth={80}
          extraActions={copyButton}
        ></ZTable>
      </div>
      {displayCreateTour && (
        <CreateTourModal
          intValues={copyTourData}
          visible={displayCreateTour}
          onClose={(reload?: boolean) => {
            setDisplayCreateTour(false);
            setCopyTourData(undefined);
            if (reload) {
              zTb.current?.reload();
            }
          }}
        />
      )}
      {!!editingTourId ? (
        isGuide(auth?.roleClaims) ? (
          <TourGuideEditModal
            showGuideEvaluation={setGuideEvaluation}
            visible={!!editingTourId}
            tourId={editingTourId}
            onClose={(reload?: boolean) => {
              setEditingTourId(undefined);
              if (reload) {
                zTb.current?.reload();
              }
            }}
          />
        ) : (
          <UpdateTourModal
            showGuideEvaluation={setGuideEvaluation}
            visible={!!editingTourId}
            tourId={editingTourId}
            onClose={(reload?: boolean) => {
              setEditingTourId(undefined);
              if (reload) {
                zTb.current?.reload();
              }
            }}
          />
        )
      ) : null}
      {tourInfoId && <TourInfoModal tourId={tourInfoId} onClose={() => setTourInfoId(undefined)} visible={!!tourInfoId} />}
      {guideEvaluation ? (
        <TourEvaluation
          tourInfo={guideEvaluation}
          onClose={() => {
            setGuideEvaluation(undefined);
          }}
        />
      ) : null}
      {guideInfoId && <GuideInfoModal visible={!!guideInfoId} gid={guideInfoId} onClose={() => setGuideInfoId(undefined)} />}
    </MainContent>
  );
}
