import React, { useState, useEffect, useCallback } from 'react';
import { useAppContext } from '@/contexts/AppContext';
import { Button, Tag, Form, Modal, Row, Input, Col, Select, Radio, Checkbox, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PlusCircleOutlined, UserOutlined, SaveOutlined, AppstoreOutlined } from '@ant-design/icons';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import moment, { Moment } from 'moment';
import lodash from 'lodash';

import FullPageLoading from '@/components/FullPageLoading';
import DatePicker from '@/components/DatePicker';
import MainContent from '@/Layout/MainContent';
import { APP_ROLE } from '@/services/roleService';
import { SubdivisionModel, DepartmentModel, StaffModel } from '@/__generated';
import { ApiSdk } from '../../httpclient';
import {
  VALIDATE_REQUIRED,
  VALIDATE_EMAIL,
  RoleList,
  ViewingPermissionList,
  RoleSupport,
  RoleListDisplay,
  ERROR_EXCEPTION,
  RegularEnglish,
  PhoneNumberRegex,
  KATAKANA_REGEX,
  AccountingRoleList,
  AccountingRoleType,
  KANJI_REGEX,
} from '../../constants';
import './style.less';
import ZTable, { IFilterItem, ITableRef } from '@/components/ZTable';
import PhoneNumberInput from '@/components/app/formItem/PhoneNumberInput';
import { formatDate } from '@/config';

export default function StaffSearch() {
  const { auth } = useAppContext();
  const history = useHistory();
  const [t] = useTranslation();
  const zTb = React.useRef<ITableRef>();

  const [formVisible, setFormVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isNewForm, setIsNewForm] = useState(false);
  const [subdivisions, setSubdivisions] = useState<SubdivisionModel[]>([]);
  const [departments, setDepartments] = useState<DepartmentModel[]>([]);
  const [subdivisionModal, setSubdivisionModal] = useState<SubdivisionModel[]>([]);

  const [form] = Form.useForm();

  const filters = React.useMemo(() => {
    const f: Array<IFilterItem> = [
      {
        type: 'LIST',
        name: 'department',
        label: 'staffSearch_department',
        listData: departments?.map(x => ({ value: x.id || '', label: x.departmentName || '' })) || [],
      },
      {
        type: 'LIST',
        name: 'subDivision',
        label: 'staffSearch_subdivision',
        listData: subdivisions?.map(x => ({ value: x.id || '', label: x.subdivisionName || '' })) || [],
      },
      {
        type: 'TEXT',
        name: 'name',
        label: 'staffSearch_staff',
      },
      {
        type: 'LIST',
        name: 'userPermission',
        multiple: true,
        label: 'staffSearch_userPermission_search',
        listData: RoleList,
        xlSize: 6,
      },
      {
        type: 'CHECKBOX',
        name: 'displayTerminatedUsers',
        label: 'staffSearch_displayTerminatedUsers',
      },
    ];
    return f;
  }, [subdivisions, departments]);

  const getDepartments = async () => {
    const res = await ApiSdk.DepartmentService.getDepartments({ pageSize: 500 });
    setDepartments(res.data || []);
  };

  const getSubdivisions = async departmentId => {
    const res = await ApiSdk.SubdivisionService.getSubdivisions({ pageSize: 1000, departmentId: departmentId });
    setSubdivisions(res.data || []);
  };

  const getSubdivisionModal = useCallback(async departmentId => {
    const res = await ApiSdk.SubdivisionService.getSubdivisions({ pageSize: 1000, departmentId: departmentId });
    setSubdivisionModal(res.data || []);
  }, []);

  const openDetailStaff = useCallback(
    async record => {
      try {
        const staffDetail = await ApiSdk.StaffService.detail({ id: record.id });
        getSubdivisionModal(staffDetail.departmentId);
        const accountingRoles = [AccountingRoleType.ReferenceAccounting, AccountingRoleType.RegularAccounting, AccountingRoleType.SuperAccounting];
        const usagePermissionCustom = staffDetail.usagePermission?.find(x => !accountingRoles.some(acc => acc === x));
        const dataEdit = {
          ...staffDetail,
          accountID: staffDetail.accountId,
          firstNameKanji: staffDetail.firstNameKJ,
          firstNameKatakana: staffDetail.firstNameKK,
          firstNameEnglish: staffDetail.firstNameEN,
          lastNameKanji: staffDetail.lastNameKJ,
          lastNameKatakana: staffDetail.lastNameKK,
          lastNameEnglish: staffDetail.lastNameEN,
          subDivisionId: staffDetail.subdivisionId,
          usagePermissionCustom: usagePermissionCustom,
          approvalPermissionCustom: staffDetail.approvalPermission ? 1 : 2,
          usageStatusCustom: staffDetail.usageStatus ? 1 : 2,
          isUserSupport: staffDetail.usagePermission?.includes(RoleSupport),
          accountingPermission: staffDetail.usagePermission?.find(x => accountingRoles.some(acc => acc === x)) || 'None',
        };
        setIsNewForm(false);
        setFormVisible(true);
        form.setFieldsValue(dataEdit);
      } catch (error) {
        console.log(error);
      }
    },
    [form, getSubdivisionModal],
  );

  useEffect(() => {
    getDepartments();
    getSubdivisions('');
  }, []);

  const { columns, W } = React.useMemo(() => {
    const _columns: ColumnsType<any> = [
      {
        title: 'No.',
        width: 50,
        fixed: 'left',
        align: 'center',
        render: (v, r, i) => {
          return <b>{i + 1}</b>;
        },
      },
      { title: t('staffSearch_accountId'), sorter: true, width: 150, fixed: 'left', dataIndex: 'accountId', ellipsis: true, render: v => <b>{v}</b> },
      { title: t('staffSearch_name'), width: 150, dataIndex: 'name', ellipsis: true },
      {
        title: t('staffSearch_department'),
        width: 150,
        dataIndex: 'departmentId',
        render: (v, record) => record.department,
        ellipsis: true,
        sorter: true,
      },
      {
        title: t('staffSearch_subdivision'),
        width: 150,
        dataIndex: 'subDivisionId',
        render: (v, record) => record.subDivision,
        ellipsis: true,
      },
      {
        title: t('staffSearch_userPermission_search'),
        width: 100,
        ellipsis: true,
        dataIndex: 'usagePermission',
        render: (value: any) => {
          return value?.length > 0
            ? value.map(valuePermission => (
                <p key={valuePermission} className="m-0">
                  {convertNamePermission(valuePermission)}
                </p>
              ))
            : '';
        },
      },
      {
        title: t('staffSearch_approvalPermission'),
        width: 100,
        align: 'center',
        render: (value: any) => {
          return value ? <Tag color="#108ee9">〇</Tag> : <Tag color="#f50">X</Tag>;
        },
        dataIndex: 'approvalPermission',
      },
      {
        title: t('staffSearch_viewingPermission'),
        width: 120,
        dataIndex: 'viewingPermission',
        render: (value: any) => {
          return value && convertNameView(value) ? convertNameView(value) : '';
        },
        ellipsis: true,
      },
      {
        title: t('staffSearch_usageStatus'),
        width: 100,
        render: (value: any) => {
          return value ? <Tag color="#108ee9">有効</Tag> : <Tag color="#f50">無効</Tag>;
        },
        dataIndex: 'usageStatus',
        ellipsis: true,
      },
      {
        title: t('staffSearch_terminationDate'),
        width: 120,
        dataIndex: 'terminationDate',
        render: (value: any) => (value ? moment(value).format('YYYY/MM/DD') : ''),
        ellipsis: true,
      },
      {
        title: t('staffSearch_lastUpdatedDate'),
        width: 150,
        sorter: true,
        defaultSortOrder: 'descend',
        dataIndex: 'updatedAt',
        render: (value: any, record) => (record.lastUpdatedDate ? moment(record.lastUpdatedDate).format('YYYY/MM/DD HH:mm') : ''),
        ellipsis: true,
      },
      { title: t('staffSearch_lastUpdatedBy'), width: 120, dataIndex: 'lastUpdatedBy', ellipsis: true },
    ];

    return {
      columns: _columns,
      W: lodash.sumBy(_columns, t => t.width as any) + 120,
    };
  }, [t]);

  const isAdmin = React.useMemo(() => {
    return (
      auth?.roleClaims.includes(APP_ROLE.Admin) ||
      auth?.roleClaims.includes(APP_ROLE.SupervisorUser) ||
      auth?.roleClaims.includes(APP_ROLE.SuperAdmin)
    );
  }, [auth]);

  const convertNamePermission = permission => {
    const roleGet = RoleListDisplay.find(role => role.value === permission);
    return roleGet?.label;
  };

  const convertNameView = permission => {
    const roleGet = ViewingPermissionList.find(view => view.value === permission);
    return roleGet?.label;
  };

  const saveStaff = values => {
    setLoading(true);
    const dataPermission: string[] = [values.usagePermissionCustom];
    if (values.isUserSupport) {
      dataPermission.push(RoleSupport);
    }
    if (values.accountingPermission && values.accountingPermission !== 'None') {
      dataPermission.push(values.accountingPermission);
    }
    const dataPost = {
      ...values,
      usagePermission: dataPermission,
      approvalPermission: values.approvalPermissionCustom === 1,
      usageStatus: values.usageStatusCustom === 1,
      emergencyContactAddress: values.emergencyContactAddress ?? null,
      numberOfTheHandler: values.numberOfTheHandler ?? null,
      terminationDate: values.terminationDate ? formatDate(values.terminationDate) : undefined,
    };

    if (isNewForm) {
      ApiSdk.StaffService.create({ body: dataPost })
        .then(res => {
          if (res?.success) {
            message.success(t('save_success'));
            zTb.current?.reload();
            setFormVisible(false);
          } else {
            message.error(t(res?.message || ''));
          }
        })
        .catch(() => {
          message.error(ERROR_EXCEPTION);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      ApiSdk.StaffService.update({ body: dataPost })
        .then(res => {
          if (res?.success) {
            message.success(t('save_success'));
            zTb.current?.reload();
            setFormVisible(false);
          } else {
            message.error(t(res?.message || ''));
          }
        })
        .catch(() => {
          message.error(ERROR_EXCEPTION);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getExtraButtons = () => {
    let extrabtns: any[] = [];
    if (auth?.roleClaims.includes(APP_ROLE.Admin)) {
      extrabtns.push(
        <Button
          key="departmant"
          style={{ background: '#f59c1a', color: '#fff' }}
          icon={<AppstoreOutlined />}
          onClick={() => {
            history.push('/cms/master-data/department');
          }}
        >
          {t('staffSearch_department')}
        </Button>,
      );
    }
    if (isAdmin) {
      extrabtns.push(
        <Button
          key="create"
          type="primary"
          className="nta-create-btn"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            form.resetFields();
            setIsNewForm(true);
            setFormVisible(true);
            form.setFieldsValue({
              usagePermissionCustom: RoleList[0]?.value,
              approvalPermissionCustom: 1,
              viewingPermission: 'AL',
              usageStatusCustom: 1,
              accountingPermission: 'None',
            });
          }}
        >
          {t('guideSearch_new')}
        </Button>,
      );
    }
    return extrabtns;
  };

  const changeSelectDepartment = async event => {
    await getSubdivisionModal(event);
  };
  const onTerminationDateChange = (date: Moment | null) => {
    if (date) {
      if (date.endOf('day').isAfter(moment())) {
        form.setFieldsValue({
          usageStatusCustom: 1,
        });
      } else {
        form.setFieldsValue({
          usageStatusCustom: 2,
        });
      }
    }
  };

  return (
    <MainContent title={t('menu.Staffs')} extraBtns={getExtraButtons()} icon={<UserOutlined style={{ fontSize: 16 }} />}>
      <FullPageLoading loading={loading} />
      <Helmet title={t('menu.Staffs')} />
      <div className="ant-layout-content-body">
        <ZTable<StaffModel>
          scroll={{ x: W }}
          columns={columns}
          primaryKey="id"
          tableRef={zTb}
          url="/staffs/search"
          delete_url="/staffs/delete"
          bordered
          order_by="updatedAt"
          order_by_asc={false}
          allowEdit={isAdmin}
          onEdit={record => {
            openDetailStaff(record);
          }}
          allowDelete={isAdmin}
          showEdit={staff => staff.accountId !== auth?.username}
          showDelete={staff => staff.accountId !== auth?.username}
          filters={filters}
          layoutFilter={'horizontal'}
          rowDisableName="usageStatus"
          rowDisableValue={false}
        />

        <Modal
          className="nta-custom-form modal-create-guide-search responsive-modal"
          visible={formVisible}
          maskClosable={false}
          width={500}
          style={{ top: 10 }}
          title={!isNewForm ? t('staffSearch_edit') : '新規登録'}
          forceRender={true}
          okText={!isNewForm ? t('staffSearch_save') : t('staffSearch_new')}
          okButtonProps={{ icon: <SaveOutlined />, className: 'save-btn' }}
          onCancel={() => {
            form.resetFields();
            setFormVisible(false);
          }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                saveStaff(values);
              })
              .catch(err => {
                if (err?.errorFields.length) {
                  const fieldsName = err.errorFields[0].name;
                  form.scrollToField(fieldsName, { behavior: 'smooth' });
                }
              });
          }}
        >
          <Form form={form} size="middle" layout="vertical">
            <Form.Item name="id" className="hidden">
              <Input type="hidden" />
            </Form.Item>
            <Row gutter={6}>
              <Col md={12} xs={12}>
                <Form.Item
                  label={t('staffSearch_accountId')}
                  name="accountID"
                  rules={[
                    { required: true, message: VALIDATE_REQUIRED },
                    {
                      pattern: /^[0-9a-zA-Z]+$/,
                      message: '英数字を入力してください',
                    },
                  ]}
                >
                  <Input disabled={!isNewForm} className="input-value-color" />
                </Form.Item>
              </Col>
              <Col md={12} xs={12}></Col>
              <Col md={12} xs={12}>
                <Form.Item
                  label={t('staffSearch_lastNameKanji')}
                  name="lastNameKJ"
                  rules={[
                    { required: true, message: VALIDATE_REQUIRED },
                    {
                      pattern: KANJI_REGEX,
                      message: t('staffSearch_validateJanji'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12} xs={12}>
                <Form.Item
                  label={t('staffSearch_firstNameKanji')}
                  name="firstNameKJ"
                  rules={[
                    { required: true, message: VALIDATE_REQUIRED },
                    {
                      pattern: KANJI_REGEX,
                      message: t('staffSearch_validateJanji'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12} xs={12}>
                <Form.Item
                  label={t('staffSearch_lastNameKatagana')}
                  name="lastNameKK"
                  rules={[
                    { required: true, message: VALIDATE_REQUIRED },
                    {
                      pattern: KATAKANA_REGEX,
                      message: t('staffSearch_validateKatagana'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12} xs={12}>
                <Form.Item
                  label={t('staffSearch_firstNameKatagana')}
                  name="firstNameKK"
                  rules={[
                    { required: true, message: VALIDATE_REQUIRED },
                    {
                      pattern: KATAKANA_REGEX,
                      message: t('staffSearch_validateKatagana'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col md={12} xs={12}>
                <Form.Item
                  label={t('staffSearch_lastNameEnglish')}
                  name="lastNameEN"
                  rules={[
                    { required: true, message: VALIDATE_REQUIRED },
                    {
                      pattern: RegularEnglish,
                      message: t('staffSearch_validationEnglish'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12} xs={12}>
                <Form.Item
                  label={t('staffSearch_firstNameEnglish')}
                  name="firstNameEN"
                  rules={[
                    { required: true, message: VALIDATE_REQUIRED },
                    {
                      pattern: RegularEnglish,
                      message: t('staffSearch_validationEnglish'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12} xs={12}>
                <Form.Item
                  label={t('staffSearch_email')}
                  name="emailAddress"
                  rules={[
                    { required: true, message: VALIDATE_REQUIRED },
                    { type: 'email', message: VALIDATE_EMAIL },
                  ]}
                >
                  <Input type="email" />
                </Form.Item>
              </Col>
              <Col md={12} xs={12}>
                <Form.Item
                  label={t('staffSearch_numberOfTheHandler')}
                  name="numberOfTheHandler"
                  rules={[
                    {
                      pattern: /^[0-9a-zA-Z]{1,2}$/,
                      message: '英数字を入力してください',
                    },
                  ]}
                >
                  <Input maxLength={2} />
                </Form.Item>
              </Col>
              <Col md={12} xs={12}>
                <Form.Item
                  name="mobilePhoneNumber"
                  label={t('staffSearch_phoneNumber')}
                  rules={[{ pattern: PhoneNumberRegex, message: t('validatorNumber') }]}
                >
                  <PhoneNumberInput placeholder={t('staffSearch_phoneNumber')} />
                </Form.Item>
              </Col>
              <Col md={12} xs={12}>
                <Form.Item
                  name="emergencyContactAddress"
                  label={t('staffSearch_emergencyContactAddress')}
                  rules={[{ pattern: PhoneNumberRegex, message: t('validatorNumber') }]}
                >
                  <PhoneNumberInput placeholder={t('staffSearch_emergencyContactAddress')} />
                </Form.Item>
              </Col>

              <Col md={12} xs={12}>
                <Form.Item label={t('staffSearch_department')} name="departmentId" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Select onChange={changeSelectDepartment}>
                    {departments.map(department => (
                      <Select.Option key={department.id} value={department.id || ''}>
                        {department.departmentName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12} xs={12}>
                <Form.Item label={t('staffSearch_subdivision')} name="subDivisionId" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Select>
                    {subdivisionModal.map(subdivision => (
                      <Select.Option key={subdivision.id} value={subdivision.id || ''}>
                        {subdivision.subdivisionName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={6}>
              <Col md={6} xs={6}>
                {t('staffSearch_userPermission')}
              </Col>
              <Col md={18} xs={18}>
                <Form.Item name="usagePermissionCustom" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Radio.Group>
                    <Row gutter={0}>
                      {RoleList.map(role => (
                        <Col md={12} xs={12} key={role.value}>
                          <Radio value={role.value} key={role.value}>
                            {role.label}
                          </Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col md={6} xs={6}>
                経理権限
              </Col>
              <Col md={18} xs={18}>
                <Form.Item name="accountingPermission">
                  <Radio.Group>
                    <Row gutter={0}>
                      <Col md={12} xs={12}>
                        <Radio value={'None'} key={'None'}>
                          None
                        </Radio>
                      </Col>
                      {AccountingRoleList.map(role => (
                        <Col md={12} xs={12} key={role.value}>
                          <Radio value={role.value} key={role.value}>
                            {role.label}
                          </Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={6}>
              <Col md={6} xs={6}>
                {t('staffSearch_isUserSupport')}
              </Col>
              <Col md={18} xs={18}>
                <Form.Item name="isUserSupport" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={6}>
              <Col md={6} xs={6}>
                {t('staffSearch_approvalPermission')}
              </Col>
              <Col md={18} xs={18}>
                <Form.Item name="approvalPermissionCustom">
                  <Radio.Group>
                    <Radio value={1}>{t('staffSearch_yes')}</Radio>
                    <Radio value={2}>{t('staffSearch_no')}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col md={6} xs={6}>
                {t('staffSearch_viewingPermission')}
              </Col>
              <Col md={18} xs={18}>
                <Form.Item name="viewingPermission">
                  <Radio.Group>
                    <Row>
                      {ViewingPermissionList.map(ViewingPermission => (
                        <Col key={ViewingPermission.value} md={12} xs={12}>
                          <Radio key={ViewingPermission.value} value={ViewingPermission.value}>
                            {t(ViewingPermission.label)}
                          </Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col md={6} xs={6}>
                {t('staffSearch_terminationDate')}
              </Col>
              <Col md={12} xs={12}>
                <Form.Item name="terminationDate">
                  <DatePicker
                    className="w-100"
                    autoComplete="off"
                    onChange={d => {
                      onTerminationDateChange(d);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col md={6} xs={6}>
                {t('staffSearch_usageStatus')}
              </Col>
              <Col md={18} xs={18}>
                <Form.Item name="usageStatusCustom">
                  <Radio.Group>
                    <Radio
                      onClick={() => {
                        form.setFieldsValue({
                          terminationDate: null,
                        });
                      }}
                      value={1}
                    >
                      有効
                    </Radio>
                    <Radio
                      onClick={() => {
                        form.setFieldsValue({
                          terminationDate: moment().add(-1, 'day'),
                        });
                      }}
                      value={2}
                    >
                      無効
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </MainContent>
  );
}
