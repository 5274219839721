import { GuideSeisanStatus, GuideSeisanStatusLabel } from '@/constants';
import { useAppContext } from '@/contexts/AppContext';
import { Select, SelectProps } from 'antd';
import React from 'react';
import * as roleService from '@/services/roleService';
import { orderBy } from 'lodash';

interface Props extends SelectProps<any> {
  currentStatus: any;
}
export default function AccountingSeisanStatusInput({ currentStatus, ...props }: Props) {
  const { auth } = useAppContext();
  const options = React.useMemo(() => {
    if (roleService.isRegularAccounting(auth?.originRoleClaims)) {
      let status = [
        GuideSeisanStatus.NoNeedSeisan,
        GuideSeisanStatus.NoFilesUploaded,
        GuideSeisanStatus.Uploaded,
        GuideSeisanStatus.Confirming,
        GuideSeisanStatus.Confirmed,
        GuideSeisanStatus.Rejected,
        GuideSeisanStatus.Refunded,
        currentStatus,
      ];
      let keys = Object.keys(GuideSeisanStatus).filter(x => status.includes(GuideSeisanStatus[x]));
      return orderBy(keys, x => GuideSeisanStatus[x]).map(k => {
        return { label: GuideSeisanStatusLabel[k], value: GuideSeisanStatus[k] };
      });
    } else if (roleService.isSuperAccounting(auth?.originRoleClaims)) {
      return GuideSeisanStatus.__getSelectOptions();
    }
    return [];
  }, [auth, currentStatus]);
  return <Select options={options} {...props} />;
}
