import DatePicker from '@/components/DatePicker';
import { BooleanEnumType, TourGuideStatusType } from '@/config';
import { formatCurrency } from '@/helpers/form';
import { DeleteFilled, LineOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, InputNumber, Modal } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { minBy, maxBy, orderBy, findLast } from 'lodash';
import dayjs from 'dayjs';
import { getBusinessDateDuration, ICreateTourValidation } from '../validation';
import { BusinessDay, TourGuide, TourGuideDetailModel, TourGuideStatus } from '@/__generated';
import { staffHasBall } from '@/services/roleService';
import BooleanEnumInput from '@/components/app/formItem/BooleanEnumInput';
import EnumSelect from '@/components/app/formItem/EnumSelect';
import { DEFAULT_DATE_FORMAT } from '@/constants';
type Props = {
  fieldsData: FormListFieldData[];
  deleting: boolean;
  canceling: boolean;
  onDelete: (index: number) => void;
  form: FormInstance;
  guideField: FormListFieldData;
  add: (value: any, index?: number) => void;
  remove: (index: number | number[]) => void;
  displayCanceledDate: boolean;
  formValidation: ICreateTourValidation;
  isUNTour?: boolean;
  tourGuide?: TourGuideDetailModel;
};

const hasDayChange = (originData: any, currentData: BusinessDay) => {
  if (!originData || !currentData) {
    return false;
  }
  if (!currentData.isConfirmed) {
    return false;
  }
  return (
    originData.stayLastNight !== currentData.stayLastNight ||
    originData.stayOvernight !== currentData.stayOvernight ||
    originData.fee !== currentData.fee ||
    !!originData.availability !== !!currentData.availability ||
    dayjs(originData.date).format(DEFAULT_DATE_FORMAT) !== dayjs(currentData.date).format(DEFAULT_DATE_FORMAT)
  );
};
export default function BusinessDayInfo({
  form,
  canceling,
  formValidation,
  displayCanceledDate,
  remove,
  add,
  guideField,
  fieldsData,
  deleting,
  onDelete,
  isUNTour,
  tourGuide,
}: Props) {
  const { t } = useTranslation();
  const onDateChangeOrStatus = (id?: string) => {
    const formValues = form.getFieldsValue(true);
    const { tourGuides }: { tourGuides: TourGuide[] } = formValues;
    if (tourGuides?.length) {
      const guideBusinessDays = tourGuides.map(x => x.businessDays?.filter(d => d.date)) || [];
      let dates: BusinessDay[] = [];
      guideBusinessDays.forEach(d => {
        if (d) {
          dates = dates.concat(d);
        }
      });
      const { fromDate, toDate } = getBusinessDateDuration(dates);

      form.setFieldsValue({
        fromDate: fromDate?.date,
        toDate: toDate?.date,
      });
    }
    // re order

    const businessDays = form.getFieldValue(['tourGuides', guideField.name, 'businessDays']);

    const orderedBusinessDays = orderBy(businessDays, d => {
      if (d.date) {
        return dayjs(d.date).unix();
      }
      return Number.MAX_VALUE;
    });
    remove(orderedBusinessDays.map((_, i) => i));
    orderedBusinessDays.forEach((d, i) => {
      add(d, i);
    });
    if (id) {
      window.setTimeout(() => {
        document.getElementById(id)?.focus();
      }, 100);
    }
  };
  const _onCancel = (dayField: FormListFieldData, index: number) => {
    const dayInfo = form.getFieldValue(['tourGuides', guideField.name, 'businessDays', dayField.name]);
    if (dayInfo) {
      remove(index);
      add(
        {
          ...dayInfo,
          isCancelled: true,
          isConfirmed: false,
        },
        index,
      );
      onDateChangeOrStatus();
    }
  };
  const onCancel = (isConfirmed: boolean | undefined, dayField: FormListFieldData, index: number) => {
    if (isConfirmed) {
      Modal.confirm({
        title: t('CONFIRM_CANCEL_DATE'),
        onOk: () => {
          _onCancel(dayField, index);
        },
      });
    } else {
      _onCancel(dayField, index);
    }
  };
  return (
    <div className="business-day-info">
      <div style={{ display: 'inline-flex' }}>
        <div className="title-container">
          <div className="title-item">{t('Business Day')}</div>
          <div className="title-item">{t('Daily Allowance')}</div>
          <div className="title-item">{t('Availability')}</div>
          {/* <div className="title-item">{t('Stay overnight?')}</div>
          <div className="title-item">{t('Pre night?')}</div> */}
        </div>
        <div className="day-container">
          {fieldsData.map((field, index) => {
            return (
              <Form.Item
                key={index}
                noStyle
                shouldUpdate={(prev, current) => {
                  const prevGuideData = prev.tourGuides[guideField.key];
                  const currentGuideData = current.tourGuides[guideField.key];
                  return prevGuideData?.businessDays !== currentGuideData?.businessDays;
                }}
              >
                {({ getFieldValue }) => {
                  const guideStatus: TourGuideStatus = getFieldValue(['tourGuides', guideField.name, 'status']);
                  const businessDayInfo: BusinessDay = getFieldValue(['tourGuides', guideField.name, 'businessDays', field.name]);
                  const { isCancelled, isConfirmed, id, date: currentDate, availability } = businessDayInfo;
                  const staffBall = staffHasBall((tourGuide?.status || TourGuideStatusType.Unassigned) as any);

                  let allowPreNight = field.name === 0;
                  if (!allowPreNight && currentDate) {
                    const businessDays: BusinessDay[] = getFieldValue(['tourGuides', guideField.name, 'businessDays']) || [];
                    const preDay = findLast(businessDays, (x, i) => !x.isCancelled && i < field.name && x.availability);

                    if (preDay?.date) {
                      allowPreNight = !dayjs(currentDate)
                        .add(-1, 'day')
                        .isSame(preDay?.date, 'day');
                    } else {
                      allowPreNight = true;
                    }
                  }

                  if (isCancelled || !availability) {
                    allowPreNight = false;
                  }
                  if (!displayCanceledDate && isCancelled && isConfirmed) {
                    return null;
                  }

                  // only allow edit if staff has ball
                  let disabledAvailability = !staffBall || isCancelled;
                  let disabledOtherInfo = !staffBall || isCancelled;
                  let disabledDate = !staffBall || isCancelled;
                  if (isUNTour) {
                    disabledDate = true;
                    disabledOtherInfo = ![TourGuideStatusType.Checking, TourGuideStatusType.FinalChecking].includes(guideStatus);
                    disabledAvailability = ![TourGuideStatusType.Checking, TourGuideStatusType.FinalChecking].includes(guideStatus);
                  }
                  const hasChange = hasDayChange(
                    tourGuide?.businessDays?.find(x => x.id === id),
                    businessDayInfo,
                  );

                  let showDeleteBtn = staffBall && deleting;
                  if (showDeleteBtn) {
                    let checkStatus = [TourGuideStatusType.Denied, TourGuideStatusType.Cancelled, TourGuideStatusType.Unassigned];
                    if (checkStatus.includes((tourGuide?.status || TourGuideStatusType.Unassigned) as any)) {
                      showDeleteBtn = true;
                    } else {
                      checkStatus = [
                        TourGuideStatusType.Checking,
                        TourGuideStatusType.Assigned,
                        TourGuideStatusType.Checked,
                        TourGuideStatusType.FinalChecking,
                        TourGuideStatusType.Finalized,
                      ];
                      showDeleteBtn = checkStatus.includes(tourGuide?.status as any) && !isConfirmed;
                    }
                  }
                  const availabilityID = `${field.name}-${guideField.name}-availability`;
                  const dateID = `${field.name}-${guideField.name}-date`;
                  return (
                    <div className={`day-item ${isCancelled ? ' --canceled' : ''}  ${hasChange ? '--has-change' : ''}`} key={field.key}>
                      {isCancelled && <div className="canceled-text">{t('Cancelled')}</div>}
                      <Form.Item name={[field.name, 'date']} rules={disabledDate ? [] : [formValidation.guideInfo]}>
                        <DatePicker
                          id={dateID}
                          tabIndex={index + 1}
                          className="w-100 input-value-color"
                          onChange={() => onDateChangeOrStatus(dateID)}
                          disabled={disabledDate}
                        />
                      </Form.Item>
                      <Form.Item name={[field.name, 'fee']} rules={disabledOtherInfo ? [] : [formValidation.guideInfo]}>
                        <InputNumber
                          tabIndex={index + 101}
                          className="w-100 input-value-color no-arrow"
                          min={0}
                          defaultValue={0}
                          precision={0}
                          formatter={v => formatCurrency(v)}
                          disabled={disabledOtherInfo}
                        />
                      </Form.Item>
                      <Form.Item name={[field.name, 'availability']}>
                        <BooleanEnumInput
                          id={availabilityID}
                          tabIndex={index + 201}
                          allowClear={false}
                          className="input-value-color"
                          disabled={disabledAvailability}
                          onChange={() => onDateChangeOrStatus(availabilityID)}
                        />
                      </Form.Item>
                      {/* <Form.Item name={[field.name, 'stayOvernight']} rules={disabledOtherInfo ? [] : formValidation.businessDayInfo}>
                        <EnumSelect
                          tabIndex={index + 301}
                          enumData={BooleanEnumType}
                          allowClear={false}
                          className="input-value-color"
                          disabled={disabledOtherInfo}
                        />
                      </Form.Item>
                      {allowPreNight && (
                        <Form.Item name={[field.name, 'stayLastNight']} rules={disabledOtherInfo ? [] : formValidation.businessDayInfo}>
                          <EnumSelect
                            tabIndex={index + 401}
                            enumData={BooleanEnumType}
                            allowClear={false}
                            className="input-value-color"
                            disabled={disabledOtherInfo}
                          />
                        </Form.Item>
                      )} */}
                      <Form.Item name={[field.name, 'id']} noStyle>
                        <Input type="hidden" />
                      </Form.Item>
                      <Form.Item name={[field.name, 'isCancelled']} noStyle>
                        <Input type="hidden" />
                      </Form.Item>
                      {showDeleteBtn && (
                        <Button
                          className="delete-btn"
                          onClick={() => {
                            onDelete(index);
                          }}
                          shape="circle"
                          danger
                          size="small"
                          icon={<DeleteFilled />}
                        ></Button>
                      )}
                      {canceling && !isCancelled && (
                        <Button
                          className="delete-btn"
                          onClick={() => {
                            onCancel(isConfirmed, field, index);
                          }}
                          shape="circle"
                          type="primary"
                          danger={!isCancelled}
                          size="small"
                          icon={isCancelled ? <PlusOutlined /> : <LineOutlined />}
                        ></Button>
                      )}
                    </div>
                  );
                }}
              </Form.Item>
            );
          })}
        </div>
      </div>
    </div>
  );
}
