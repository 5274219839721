import React from 'react';
import { Form, Row, Input, Col, Select, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { VALIDATE_REQUIRED, Ranks, RegularEnglish, PhoneNumberRegex, KATAKANA_REGEX, KANJI_REGEX } from '@/constants';
import DatePicker from '@/components/DatePicker';

import PrefectureInput from '@/components/app/formItem/PrefectureInput';
import PhoneNumberInput from '@/components/app/formItem/PhoneNumberInput';
import NationalityInput from '@/components/app/formItem/NationalityInput';

type Props = {
  isCreateForm: boolean;
};

export default function GuideBasicInfo({}: Props) {
  const [t] = useTranslation();

  return (
    <Col md={14} xs={24}>
      <Row gutter={6}>
        <Col md={12} xs={24}>
          <Form.Item label={t('guideSerchaccountId')} name="accountId">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Row gutter={6}>
            <Col md={8} xs={24}>
              <Form.Item label={t('guideSerchrank')} name="guideRank">
                <Select allowClear>
                  {Ranks.map(rank => (
                    <Select.Option key={rank.value} value={rank.value}>
                      {rank.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={16} xs={24}>
              <Form.Item label={t('guideSerchstatus')} name="enabled" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                <Radio.Group>
                  <Radio value={true}>{t('staffSearch_active')}</Radio>
                  <Radio value={false}>{t('staffSearch_inactive')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label={t('guideSerchlastNameKanji')}
            id="lastNameKanji"
            name="lastNameKanji"
            rules={[
              { required: true, message: VALIDATE_REQUIRED },
              {
                pattern: KANJI_REGEX,
                message: t('staffSearch_validateJanji'),
              },
            ]}
          >
            <Input maxLength={255} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label={t('guideSerchfirstNameKanji')}
            name="firstNameKanji"
            rules={[
              { required: true, message: VALIDATE_REQUIRED },
              {
                pattern: KANJI_REGEX,
                message: t('staffSearch_validateJanji'),
              },
            ]}
          >
            <Input maxLength={255} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label={t('guideSerchlastNameKatagana')}
            name="lastNameKatakana"
            rules={[
              {
                pattern: KATAKANA_REGEX,
                message: t('staffSearch_validateKatagana'),
              },
            ]}
          >
            <Input maxLength={255} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label={t('guideSerchfirstNameKatagana')}
            id="firstNameKatakana"
            name="firstNameKatakana"
            rules={[
              {
                pattern: KATAKANA_REGEX,
                message: t('staffSearch_validateKatagana'),
              },
            ]}
          >
            <Input maxLength={255} />
          </Form.Item>
        </Col>

        <Col md={12} xs={24}>
          <Form.Item
            label={t('guideSerchlastNameEnglish')}
            id="firstNameKatakana"
            name="lastNameEnglish"
            rules={[
              {
                pattern: RegularEnglish,
                message: t('staffSearch_validationEnglish'),
              },
            ]}
          >
            <Input maxLength={255} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label={t('guideSerchfirstNameEnglish')}
            id="firstNameEnglish"
            name="firstNameEnglish"
            rules={[
              {
                pattern: RegularEnglish,
                message: t('staffSearch_validationEnglish'),
              },
            ]}
          >
            <Input maxLength={255} />
          </Form.Item>
        </Col>

        <Col md={12} xs={24}>
          <Form.Item name="nickName" label={t('guideSerchnickname')}>
            <Input maxLength={255} />
          </Form.Item>
        </Col>

        <Col md={12} xs={24}>
          <Form.Item label={t('guideSerchgender')} name="gender">
            <Radio.Group>
              <Radio value={1}>{t('guideCreateGenderMale')}</Radio>
              <Radio value={2}>{t('guideCreateGenderFemale')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label={t('guideSerchbirthdate')} name="birthDate">
            <DatePicker className="w-100" lessThanToday />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label={t('guideSerchnationality')} name="nationality">
            <NationalityInput placeholder={t('guideSerchnationality')} />
          </Form.Item>
        </Col>

        <Col md={12} xs={24}>
          <Form.Item
            name="mobilePhone"
            rules={[{ pattern: PhoneNumberRegex, message: t('validatorPhoneNumber') }]}
            label={t('guideSerchphoneNumberMobile')}
          >
            <PhoneNumberInput placeholder={t('guideSerchphoneNumberMobile')} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            name="homePhone"
            rules={[{ pattern: PhoneNumberRegex, message: t('validatorPhoneNumber') }]}
            label={t('guideSerchPhoneNumberHome')}
          >
            <PhoneNumberInput placeholder={t('guideSerchPhoneNumberHome')} />
          </Form.Item>
        </Col>

        <Col md={12} xs={24}>
          <Form.Item
            label={t('guideSerchemailAddress1')}
            rules={[{ required: true, message: VALIDATE_REQUIRED }]}
            id="emailAddress1"
            name="emailAddress1"
          >
            <Input type="email" maxLength={255} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item id="emailAddress2" name="emailAddress2" label={t('guideSerchemailAddress2')}>
            <Input type="email" maxLength={255} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label={t('guideSerchzipCode')} name="zipCode">
            <Input maxLength={12} />
          </Form.Item>
        </Col>

        <input type="hidden" className="p-country-name" value="Japan" />

        <Col md={12} xs={24}>
          <Form.Item label={t('guideSerchaddressPrefecture')} name="prefecture">
            <PrefectureInput />
          </Form.Item>
        </Col>
        <Col md={24} xs={24}>
          <Form.Item name="address" label="住所">
            <Input type="text" maxLength={255} className="ant-input p-locality p-street-address p-extended-address" />
          </Form.Item>
        </Col>
        <Col md={24} xs={24}>
          <Form.Item id="buildingName" name="buildingName" label={t('guideSerchbuildingName')}>
            <Input maxLength={255} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item id="nearestStation" name="nearestStation" label={t('guideSerchnearestStation')}>
            <Input maxLength={255} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item id="trainLine" name="trainLine" label={t('guideSerchtrainLine')}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col md={24} xs={24}>
          <h3>口座情報</h3>
        </Col>
        <Col md={24} xs={24}>
          <Row gutter={6}>
            <Col md={12} xs={24}>
              <Form.Item label={t('guideSerchbankName')} name="bankName">
                <Input maxLength={200} />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label={t('guideSerchbranchName')} name="branchName">
                <Input maxLength={200} />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label={t('guideSerchaccountType')} name="bankAccountType">
                <Radio.Group>
                  <Radio value={1}>{t('guideSerchaccountTypeUsually')}</Radio>
                  <Radio value={2}>{t('guideSerchaccountTypeCurrent')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label={t('guideSerchaccountNumber')} name="bankAccountNumber">
                <Input maxLength={20} />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label={t('guideSerchaccountHolder')} name="bankAccountHolder">
                <Input autoComplete="off" maxLength={50} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}
