import tour from './tour';
import guide from './guide';
import message from './message';
import staff from './staff';
import notifications from './notifications';
import guideSearch from './guideSearch';
import tourEvaluation from './tourEvaluation';

export default {
  //
  ...tour,
  ...guide,
  ...message,
  ...staff,
  ...notifications,
  ...guideSearch,
  ...tourEvaluation,
  //
  'menu.MainMenu': 'Main Menu',
  'menu.SystemSettings': 'System Settings',
  'menu.Dashboard': 'Dashboard',
  'menu.Tours': 'Tours',
  'menu.Calendars': 'Calendars',
  'menu.Billings': 'Billings',
  'menu.Notifications': 'Notifications',
  'menu.Users': 'Users',
  'menu.ApiAccessLogs': 'Api Access Logs',
  'menu.MasterData': 'Master Data',
  'menu.EmailTemplates': 'Email Templates',
  'menu.SMTPSettings': 'SMTP Settings',
  'menu.Guides': 'Guides',
  'menu.Staffs': 'Staff',
  'menu.StaffSearch': 'Staff List',
  'menu.StaffAdd': 'Add Staff',
  'menu.GuideSearch': 'Guide List',
  'menu.Countries': 'Countries',
  'menu.Languages': 'Languages',
  'menu.Department': 'Department',
  'menu.Subdivision': 'Subdivision',
  'menu.Holidays': 'Holidays',
  'menu.Facilities': 'Facilities',
  'menu.TourFeedbacks': 'Feedbacks',
  'menu.EmailMessages': 'Email Messages',

  //base table
  'zTable.edit': 'Edit',
  'zTable.copy': 'copy',
  'zTable.delete': 'Delete',
  'zTable.actions': 'Actions',
  'zTable.search': 'Search',
  'zTable.reset': 'Reset',
  'zTable.records': 'Number of searches：',
  'zTable.pageSize': 'Displayed results ',

  // enum
  Yes: 'Yes',
  No: 'No',
  EnumBlank: 'Blank',
  Sightseeing: 'Sight seeing',
  Education: 'Education',
  Medical: 'Medical',
  Inspection: 'Inspection',
  Mice: 'MICE',
  Honeymoon: 'Honey moon',
  Family: 'Family',
  Friends: 'Friends',
  // Guide Status
  Unassigned: 'Unassigned',
  Assigning: 'Assigning',
  Checking: 'NTA Checking',
  Assigned: 'Assigned',
  Informing3MonthsPrior: 'Informing 3 Months Prior',
  Informing2MonthPrior: 'Informing 2Month Prior',
  Checked: 'Checked',
  FinalInforming: 'Final Informing',
  FinalChecking: 'Final Checking',
  Finalized: 'Finalized',
  AwaitingCancellation: 'Awaiting Cancellation',
  Cancelled: 'Cancelled',
  Denied: 'Denied',
  CheckingSeisan: 'Checking Seisan',
  SeisanFinished: 'Seisan Finished',
  // tour status
  Confirming: 'Confirming',
  ConfirmedAcceptingGuest: 'Confirmed Accepting Guest',
  Confirmed: 'Confirmed',
  ConfirmingBilling: 'Confirming Billing',
  BillingComplete: 'Billing Complete',
  Save: 'Save',
  Close: 'Close',
  'Update tour': 'Update tour',
  // roles
  Admin: 'Admin',
  SupervisorUser: 'Supervisor User',
  RegularUser: 'Regular User',
  ReferenceUser: 'Reference User',
  Guide: 'Guide',
  Assign: 'Assign',
  GuideSupport: 'Guide Support',
  Role: 'Role',
  Email: 'Email',
  UserName: 'UserName',
  UserNameOrEmail: 'Email/ Account ID',
  User: 'User',
  EmailConfirmed: 'Email Confirmed',
  LockoutEnabled: 'Lockout Enabled',
  RoleUpdateTile: 'Role Update',
  RegisterUser: 'Register User',
  UpdateUser: 'Update User',
  CreateNewRole: 'Create New Role',
  UpdateNewRole: 'Update New Role',
  Password: 'Password',
  ConfirmPassword: 'Confirm Password',
  NameRole: 'Name',
  // messages
  'Please input required field.': 'Required field.',
  'Are you sure want to delete this item?': 'Are you sure want to delete this item?',

  // menu

  Cancel: 'Cancel',
  Language: 'Language',
  'Only display licensed guides': 'Only display licensed guides',
  'Guide Status': 'Guide Status',
  'Tour Status': 'Tour Status',
  'Tour Name': 'Tour Name',
  'Tour Number': 'Tour Number',

  'Guide Name': 'Guide Name',
  'Display tours from UN': 'Display tours from UN',
  'Display tours from GL': 'Display tours from GL',
  'Tour duration': 'Tour duration',
  Nationality: 'Nationality',
  'Number of pax': 'No.Pax',
  Sales: 'Sales',
  'Tour Manager': 'Tour Manager',
  Status: 'Status',
  Agent: 'Agent',
  'Number of Passenger': 'No.Pass',
  'Number of Passengers (Estimated)': 'No.Pass (Est)',
  Adult: 'Adult',
  'Child A': 'Child A',
  'Child B': 'Child B',
  Infant: 'Infant',
  'T/L': 'T/L',
  Total: 'Total',
  Duration: 'Duration',
  Confirm: 'Confirm',
  Register: 'Register',
  Delete: 'Delete',
  'Select date': 'Select date',
  'Manage Tours': 'Manage Tours',
  'Create Tour': 'Create Tour',
  'Select Guide': 'Select Guide',
  Calendars: 'Calendars',

  Before: 'Before',
  During: 'During',
  EndOfPublication: 'End of publication',
  Copy: 'Copy',
  Mon: 'Mon',
  Tue: 'Tue',
  Wed: 'Wed',
  Thu: 'Thu',
  Fri: 'Fri',
  Sat: 'Sat',
  Sun: 'Sun',
  page: 'page',

  // logs
  SessionId: 'Session Id',
  StartTime: 'Start Time',
  Method: 'Method',
  Endpoint: 'Endpoint',
  DurationSecond: 'Duration (ms)',
  // countries
  CreateCountry: 'Create Country',
  UpdateCountry: 'Update Country',
  PhoneCode: 'Phone Code',
  Continent: 'Continent',
  Name: 'Name',
  Code: 'Code',
  ShortCode: 'Short Code',
  // languages
  CreateLanguage: 'Create Language',
  UpdateLanguage: 'Update Language',
  // email template
  CreateEmailTemplate: 'Create Email Template',
  UpdateEmailTemplate: 'Update Email Template',
  NameEmailTemplate: 'Name Email Template',
  ToEmail: 'allow multi emails, separate by ;',
  CcEmail: 'allow multi emails, separate by ;',
  BccEmail: 'allow multi emails, separate by ;',
  Subject: 'Subject',
  Body: 'Body',
  NameEmailTemplatePlaceholder: 'Chosen Email Template',
  // smtp setting
  NameSmtp: 'Name',
  EmailAddress: 'Email',
  Host: 'Host',
  Port: 'Port',
  EnableSsl: 'EnableSsl',
  UseDefaultCredentials: 'Use Default Credentials',
  // layout header
  SeeAllNotification: 'See All Notification',
  English: 'English',
  Japanese: 'Japanese',
  SignOut: 'SignOut',
  PasswordHint: 'Password Hint',
  MinimumEightCharacters: 'Minimum 8 characters:',
  PasswordDescription: 'At least one uppercase letter, one lowercase letter, one number and one special character',
  CurrentPassword: 'Current Password',
  NewPassword: 'New Password',
  ChangePasswordSuccess: 'Change Password Success',
  // login
  TitleLogin: 'Sign in to ',
  UserNameValidation: 'Please input Email/ Account ID',
  PasswordValidation: 'Please input password',
  ForgotPassword: 'Forgot Password',
  LoginButton: 'Login',
  // password expired
  TitlePasswordExpired: 'Password Expired',
  // forgot password
  TitleForgotPassword: 'Forgot Password',
  LoginBackButton: 'Login screen back',
  EmailValidator: 'Please input email',
  // reset password
  TitleResetPassword: 'Reset Password',
  ResetPasswordSuccess: 'Reset Password Success',
  TokenInvalidOrExpired: 'Token Invalid Or Expired',
  // Calendar
  Calendar: 'Calendar',
  // holidays
  Holiday: 'holidays',
  CreateHoliday: 'Create New Holiday',
  UpdateHoliday: 'Update Holiday',
  BackToChangePassword: 'Go to Reset password',

  // auditlog
  'Audit Logs': 'Audit Logs',
  'auditlog.actor': 'Actor',
  'auditlog.entityName': 'Entity Name',
  'auditlog.primaryKey': 'Primary Key',
  'auditlog.From': 'From',
  'auditlog.To': 'To',
  'auditlog.auditType': 'Audit Type',
  'auditlog.logTime': 'Log Time',
  'auditlog.changeValues': 'Change Values',
  Year: 'Year',
  // Continent
  Europe: 'Europe',
  Africa: 'Africa',
  Asia: 'Asia',
  Oceania: 'Oceania',
  'Middle East': 'Middle East',
  'North America': 'North America',
  'South America': 'South America',
  LicenseType_National: 'National',
  LicenseType_Regional: 'Regional',
  LicenseType_None: 'None',

  // Prefectures
  Hokkaido: 'Hokkaido',
  Aomori: 'Aomori',
  Iwate: 'Iwate',
  Miyagi: 'Miyagi',
  Akita: 'Akita',
  Yamagata: 'Yamagata',
  Fukushima: 'Fukushima',
  Ibaraki: 'Ibaraki',
  Tochigi: 'Tochigi',
  Gunma: 'Gunma',
  Saitama: 'Saitama',
  Chiba: 'Chiba',
  Tokyo: 'Tokyo',
  Kanagawa: 'Kanagawa',
  Niigata: 'Niigata',
  Toyama: 'Toyama',
  Ishikawa: 'Ishikawa',
  Fukui: 'Fukui',
  Yamanashi: 'Yamanashi',
  Nagano: 'Nagano',
  Gifu: 'Gifu',
  Shizuoka: 'Shizuoka',
  Aichi: 'Aichi',
  Mie: 'Mie',
  Shiga: 'Shiga',
  Kyoto: 'Kyoto',
  Osaka: 'Osaka',
  Hyogo: 'Hyogo',
  Nara: 'Nara',
  Wakayama: 'Wakayama',
  Tottori: 'Tottori',
  Shimane: 'Shimane',
  Okayama: 'Okayama',
  Hiroshima: 'Hiroshima',
  Yamaguchi: 'Yamaguchi',
  Tokushima: 'Tokushima',
  Kagawa: 'Kagawa',
  Ehime: 'Ehime',
  Kochi: 'Kochi',
  Fukuoka: 'Fukuoka',
  Saga: 'Saga',
  Nagasaki: 'Nagasaki',
  Kumamoto: 'Kumamoto',
  Oita: 'Oita',
  Miyazaki: 'Miyazaki',
  Kagoshima: 'Kagoshima',
  Okinawa: 'Okinawa',
  Overseas: 'Overseas',
  // enum
  ReservationForm_Group: 'GROUP',
  'Reference Id': 'Reference Id',
  'To email': 'To email',
  'From email': 'From email',
  Error: 'Error',
  'Email content': 'Email content',
  'Created On': 'Created On',
  'Sent On': 'Sent On',
};
