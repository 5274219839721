import { DayGuideAvailability } from '@/__generated';
import { CloseCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

type Props = {
  day: DayGuideAvailability;
};
const fontSize = window.innerWidth < 800 ? 14 : 20;
export default function ExtraDateIcon({ day }: Props) {
  const icons = React.useMemo(() => {
    const _icons: any[] = [];
    if (day.isWfAnotherDepartment) {
      if (day.stayLastNight || day.stayOvernight) {
        _icons.push(
          <Tooltip title={<div dangerouslySetInnerHTML={{ __html: day.stayInTours?.map(x => x.name).join('<br/>') || '' }} />}>
            <CloseCircleFilled style={{ fontSize }} className={`icon stay-last-night`} />
          </Tooltip>,
        );
      }
      if (day.isUnavailable) {
        _icons.push(<CloseCircleFilled style={{ fontSize }} className={`icon unavailable`} />);
      }
    } else if (day.stayLastNight || day.stayOvernight) {
      if (day.isUnavailable) {
        _icons.push(<CloseCircleFilled style={{ fontSize }} className={`icon unavailable`} />);
      }
    }
    return _icons;
  }, [day]);
  return <div>{icons.map(i => i)}</div>;
}
