export default {
  guideSearch_startDate: 'Start Date',
  guideSearch_rank: 'Rank',
  guideSearch_prefecture: 'Prefecture',
  guideSearch_guideName: 'Guide Name',
  guideSearch_language: 'Language',
  guideSearch_maxNights: 'Maximum nights',
  guideSearch_displayWithLicense: 'Licensed',
  guideSearch_types: 'Available types',

  guideSearch_Fit: 'FIT',

  guideSearch_Group: 'Groups',
  guideSearch_Incentive: 'Incentives',
  guideSearch_Student: 'Students',
  guideSearch_SimultaneousInterpretation: 'Simultaneous interpretation',
  guideSearch_ConsecutiveInterpretation: 'Consecutive interpretation',
  guideSearch_MedicalInterpretation: 'Medical interpretation',
  guideSearch_AreasGuide: 'Areas that can be guided',
  guideSearch_Hokkaido: 'Hokkaido',
  guideSearch_Tohoku: 'Tohoku',
  guideSearch_Nikko: 'Nikko',
  guideSearch_Tokyo: 'Tokyo',
  guideSearch_Kawaguchiko: 'Kawaguchiko',
  guideSearch_Hakone: 'Hakone',
  guideSearch_Nagoya: 'Nagoya',
  guideSearch_Kyoto: 'Kyoto',
  guideSearch_Osaka: 'Osaka',
  guideSearch_Nara: 'Nara',
  guideSearch_Takayama: 'Takayama',
  guideSearch_Kanazawa: 'Kanazawa',
  guideSearch_Shirakawago: 'ShirakawaGo',
  guideSearch_AlpineRoute: 'Alpine Route',
  guideSearch_Kumanokodo: 'Kumano-kodo',
  guideSearch_Sanin: 'San-in,',
  guideSearch_Okayama: 'Okayama',
  guideSearch_Hiroshima: 'Hiroshima',
  guideSearch_Shikoku: 'Shikoku',
  guideSearch_Kyushu: 'Kyushu',
  guideSearch_Okinawa: 'Okinawa',
  guideSearch_OkinawaIsolatedIsland: 'Okinawa (isolated islands)',

  guideSearch_title: 'Guide List',
  guideSearch_usageSituation: 'Is Enabled',
  guideSearch_valid: 'Valid',
  guideSearch_invalid: 'Invalid',
  guideSearch_membershipOfProfessionalInstitution: 'Professional Institutions',
  guideSearch_others: 'Others',
  guideSearch_new: 'New',
  guideSearch_copy: 'Copy',
  guideSearch_edit: 'Edit',
  guideSearch_delete: 'Delete',
  guideSearch_search: 'Search',
  guideSearch_no: 'No.',
  guideSearch_name: 'Name (Kanji)',
  guideSearch_lastUpdatedDate: 'Last Updated Date',
  guideSearch_lastCreateDate: 'Created Date',
  guideSearch_lastUpdatedBy: 'Last Updated By',
  guideSearch_type: 'Type',
  guideSearch_area: 'Area',
  guideSearch_guideRating: 'Guide rating',
};
