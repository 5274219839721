import { TourDetailModel } from '@/__generated';
import React, { Component, useContext } from 'react';
export interface TourContextInterface {
  tour: TourDetailModel | undefined | null;
  setTour: (tour: TourDetailModel | null | undefined) => void;
}
export const TourContext = React.createContext<TourContextInterface>({
  tour: null,
  setTour: () => {},
});

export const useTourContext = () => {
  return useContext<TourContextInterface>(TourContext);
};
export class TourProvider extends Component<any, TourContextInterface> {
  onUpdateTour = (tour: TourDetailModel | null | undefined) => {
    this.setState({
      tour: tour,
    });
  };

  state = {
    tour: undefined,
    setTour: this.onUpdateTour,
  };

  render() {
    return <TourContext.Provider value={{ ...this.state }}>{this.props.children} </TourContext.Provider>;
  }
}
