import React, { useEffect } from 'react';
import { Button, Col, Layout, Row } from 'antd';
import { RouteComponentProps, Link } from 'react-router-dom';
import renderRoutes from './router';
import { useAppContext } from '../../contexts/AppContext';
import Scrollbars from 'react-custom-scrollbars';
import { Div } from './style';
import FeatherIcon from 'feather-icons-react';
import TopMenu from './components/TopMenu';
import { AppLogo } from '@/config';
import MenuItems from './components/MenuItems';
import AuthInfo from './components/UserInfo';

const headerLogo = require('@/assets/guidelink/headerlogo.png');
interface BaseProps extends RouteComponentProps {}
const { Header, Sider, Content } = Layout;

const SideBarStyle: any = {
  margin: '63px 0 0 0',
  padding: '15px 15px 55px 15px',
  overflowY: 'auto',
  height: '100vh',
  position: 'fixed',
  left: 0,
  zIndex: 998,
};

const App = React.memo((props: BaseProps) => {
  const { theme, auth } = useAppContext();
  const [collapsed, setCollapsed] = React.useState<boolean>(window.innerWidth < 900);
  //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname]);
  React.useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }, [collapsed]);
  const renderThumbHorizontal = ({ style }) => {
    const { darkMode } = theme;
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: darkMode ? '#ffffff16' : '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} />;
  };
  const renderThumbVertical = ({ style }) => {
    const { rtl, darkMode } = theme;
    const left = !rtl ? 'left' : 'right';
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: darkMode ? '#ffffff16' : '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} />;
  };
  const renderView = ({ style, ...props }) => {
    const { rtl } = theme;
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'marginLeft' : 'marginRight']: '-17px',
    };
    return <div {...props} style={{ ...style, ...customStyle }} />;
  };
  const renderTrackVertical = () => {
    const { rtl } = theme;
    const thumbStyle: any = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  //
  const { darkMode, rtl, topMenu } = theme;
  return (
    <Div darkMode={darkMode}>
      <Layout className="layout">
        <Header
          className="nta-header"
          style={{
            position: 'fixed',
            width: '100%',
            top: 0,
            [!rtl ? 'left' : 'right']: 0,
          }}
        >
          <Row>
            <Col lg={10} xl={8} md={16} sm={12} xs={12} className={`align-center-v navbar-brand ${topMenu ? 'justify-content-center' : ''}`}>
              {!topMenu && (
                <Button type="link" onClick={() => setCollapsed(h => !h)}>
                  <FeatherIcon icon="menu" style={{ color: 'black' }} />
                </Button>
              )}

              <Link className="striking-logo" to="/" style={{ display: 'flex' }}>
                <AppLogo style={{ marginRight: 10 }} className="hide-on-mobile" />
                <img src={headerLogo} alt="guidelink" />
              </Link>
            </Col>
            <Col lg={8} xl={10} md={0} sm={0} xs={0}>
              {topMenu && <TopMenu />}
            </Col>
            <Col lg={6} md={8} sm={12} xs={12}>
              <AuthInfo />
            </Col>
          </Row>
        </Header>

        <Layout>
          {!topMenu && (
            <Sider width={280} style={SideBarStyle} collapsed={collapsed} theme={!darkMode ? 'light' : 'dark'} className="nta-left-menu">
              <Scrollbars
                className="custom-scrollbar"
                autoHide
                autoHideTimeout={500}
                autoHideDuration={200}
                renderThumbHorizontal={renderThumbHorizontal}
                renderThumbVertical={renderThumbVertical}
                renderView={renderView}
                renderTrackVertical={renderTrackVertical}
              >
                <MenuItems onCloseSider={() => setCollapsed(true)} />
              </Scrollbars>
            </Sider>
          )}

          <Layout className={`atbd-main-layout ${topMenu ? 'has-top-menu' : ''}`}>
            <Content>
              <div style={{ minHeight: 'calc(100vh - 65px)' }}>{renderRoutes(auth)}</div>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </Div>
  );
});

export default App;
