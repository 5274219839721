import { DEFAULT_DATE_FORMAT, DEFAULT_DISPLAY_DATE_FORMAT } from '@/constants';
import { Guide, NtaComment } from '@/__generated';
import dayjs from 'dayjs';
import moment from 'moment';

export const guidHasValue = (id: string | undefined | null) => id && id !== '00000000-0000-0000-0000-000000000000';

export const getDateOnlyAsString = (date: any): any => {
  return date ? dayjs(date).format(DEFAULT_DATE_FORMAT) : null;
};

export const getGuideFullName = (guide: Guide | undefined | null) => {
  return `${guide?.lastNameKanji} ${guide?.firstNameKanji}`;
};

export const timeOnly2String = (value: string | null | undefined): string | null => {
  if (value && value.length > 5) {
    return value.slice(0, 5);
  }
  return null;
};

export const createNtaCommentsHtml = (comments: NtaComment[]) => {
  return (
    comments
      .map(c => {
        return `<div>
        <h4 style="margin-bottom:0">${moment(c.createdAt).format(DEFAULT_DISPLAY_DATE_FORMAT)} ${c.actor}</h4>
        <span>${c.comment}</span>
    </div>`;
      })
      .join('<div style="border-top: 1px dashed #ccc;margin-top: 5px;margin-bottom: 5px;"> </div>') || ''
  );
};

export const hasAnyFieldValue = (data: any) => {
  if (!data) {
    return false;
  }
  const keys = Object.keys(data);
  return keys.some(key => data[key] !== undefined);
};
