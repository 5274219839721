import { ApiSdk } from '@/httpclient';
import { GuideAutocompletionDto } from '@/__generated';
import { Select, SelectProps } from 'antd';
import { debounce } from 'lodash';
import React from 'react';

interface Props extends SelectProps<any> {
  languageCode?: string | undefined;
  isIncludeDisabledGuide?: boolean;
  actionRef?: any;
  idValue?: string | null;
}
const toLabel = (firstName: string, lastName: string) => `${lastName} ${firstName}`;

export default function GuideInput({ idValue, value, actionRef, isIncludeDisabledGuide, onChange, ...props }: Props) {
  const [searchValue, setSearchValue] = React.useState<string>('');

  const [guides, setGuides] = React.useState<GuideAutocompletionDto[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const languageCode = React.useMemo(() => {
    return props.languageCode || undefined;
  }, [props.languageCode]);

  React.useEffect(() => {
    getGuides();
  }, [languageCode, searchValue, idValue]);

  React.useImperativeHandle(
    actionRef,
    () => {
      return {
        setValue: (v: any) => {
          if (onChange) {
            onChange(v, {} as any);
          }
        },
      };
    },
    [],
  );
  const _getGuides = () => {
    setLoading(true);
    ApiSdk.GuideService.guideAutocompletion({
      query: searchValue || undefined,
      languageCode: languageCode || undefined,
      includeSelectedGuideId: idValue || undefined,
      isIncludeDisabledGuide: false,
      orderBy: 'lastNameKanji',
    })
      .then(res => {
        setGuides(res || []);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getGuides = debounce(_getGuides, 300);
  const options: any[] = React.useMemo(() => {
    return guides.map(g => ({ value: g.id, label: toLabel(g.firstNameKanji || '', g.lastNameKanji || '') })) || [];
  }, [guides]);

  return (
    <Select
      maxTagCount="responsive"
      labelInValue={true}
      {...props}
      loading={loading}
      showSearch
      onSearch={v => setSearchValue(v)}
      allowClear
      value={value}
      onChange={onChange}
      options={options}
      filterOption={false}
    ></Select>
  );
}
