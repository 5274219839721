import ZTable, { IColumnType, IFilterItem, ITableRef } from '@/components/ZTable';
import { VALIDATE_REQUIRED } from '@/constants';
import { ApiSdk } from '@/httpclient';
import MainContent from '@/Layout/MainContent';
import { LanguageModel } from '@/__generated';
import { GlobalOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, message, Modal, Row } from 'antd';
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

const PAGE_TITLE = '言語管理';

// code?: string;

//   /**  */
//   name?: string;

//   /**  */
//   nameKanji?: string;

//   /**  */
//   nameEnglish?: string;

//   /**  */
//   nameAbbreviation?: string;
const columns: IColumnType<LanguageModel>[] = [
  {
    width: 50,
    fixed: 'left',
    title: 'No.',
    dataIndex: 'createdAt',
    render: (v, t, i) => <b>{`${i + 1}`}</b>,
    align: 'center',
  },
  {
    title: '言語',
    dataIndex: 'name',
    sorter: true,
    defaultSortOrder: 'ascend',
  },
  {
    title: '言語(英語)',
    dataIndex: 'nameEnglish',
    sorter: true,
  },
  {
    title: '言語(漢字)',
    dataIndex: 'nameKanji',
    sorter: true,
  },
  {
    title: '言語(略語)',
    dataIndex: 'nameAbbreviation',
    sorter: true,
  },
  {
    title: 'コード',
    dataIndex: 'code',
    sorter: true,
  },
  {
    title: '順番',
    dataIndex: 'order',
    sorter: true,
    defaultSortOrder: 'ascend',
  },
];
const filters: Array<IFilterItem> = [
  { type: 'TEXT', name: 'name', label: '言語' },
  { type: 'TEXT', name: 'code', label: 'Code' },
];

export default () => {
  const [t] = useTranslation();
  const zTb = React.useRef<ITableRef>();

  const [form] = Form.useForm();
  const [formVisible, setFormVisible] = useState(false);
  const [isNewForm, setIsNewForm] = useState(false);

  const createLanguage = values => {
    ApiSdk.LanguageService.create({ body: values })
      .then(response => {
        if (response?.success) {
          setFormVisible(false);
          zTb.current?.reload();
        } else {
          if (response.message) {
            message.error(t(response.message || ''));
          }
        }
      })
      .catch(error => {
        message.error(error);
      });
  };
  const updateLanguage = values => {
    ApiSdk.LanguageService.update({ body: values })
      .then(response => {
        if (response?.success) {
          setFormVisible(false);
          zTb.current?.reload();
        } else {
          if (response.message) {
            message.error(t(response.message || ''));
          }
        }
      })
      .catch(error => {
        message.error(error);
      });
  };

  return (
    <MainContent
      title={PAGE_TITLE}
      extraBtns={[
        <Button
          key="create"
          type="primary"
          className="nta-create-btn"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            form.resetFields();
            setIsNewForm(true);
            setFormVisible(true);
          }}
        >
          {t('guideSearch_new')}
        </Button>,
      ]}
      icon={<GlobalOutlined style={{ fontSize: 16 }} />}
    >
      <Helmet title={PAGE_TITLE} />
      <div className="ant-layout-content-body">
        <ZTable
          columns={columns}
          primaryKey="id"
          tableRef={zTb}
          url="/languages/get-languages"
          bordered
          order_by="order"
          order_by_asc={true}
          allowEdit={true}
          onEdit={record => {
            setIsNewForm(false);
            setFormVisible(true);
            form.setFieldsValue(record);
          }}
          allowDelete={true}
          delete_url="/languages/delete"
          filters={filters}
        ></ZTable>
        <Modal
          visible={formVisible}
          maskClosable={false}
          width={600}
          className="nta-nta-custom-form"
          title={isNewForm ? t('CreateLanguage') : t('UpdateLanguage')}
          forceRender={true}
          okText={t('staffSearch_save')}
          okButtonProps={{ icon: <SaveOutlined />, className: 'save-btn' }}
          onCancel={() => {
            form.resetFields();
            setFormVisible(false);
          }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                if (values.id) {
                  updateLanguage(values);
                } else {
                  createLanguage(values);
                }
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Form form={form} size="middle" layout="vertical">
            <Form.Item name="id" className="hidden">
              <Input type="hidden" />
            </Form.Item>
            <Row gutter={16}>
              <Col md={20}>
                <Form.Item label={'言語(日本語)'} name="name" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item label={'順番'} name="order" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <InputNumber min={0} precision={0} className="w-100" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label={'言語(英語)'} name="nameEnglish" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col md={12}>
                <Form.Item label={'言語(漢字)'} name="nameKanji" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col md={12}>
                <Form.Item label="言語(略語)" name="nameAbbreviation" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label={t('Code')} name="code" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input disabled={!isNewForm} className="input-value-color" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </MainContent>
  );
};
