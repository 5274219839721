import { Modal, Button, Result, Tabs } from 'antd';
import React from 'react';
import '../style.less';
import { ApiSdk } from '@/httpclient';
import { TourDetailModel } from '@/__generated';
import PageLoading from '@/components/PageLoading';
import TourHeader from '../_readonlyComponents/TourHeader';
import ReadOnlyGuideInfo from '../_readonlyComponents/GuideInfo';
import { useTranslation } from 'react-i18next';
import GuideOtherInfo from '../_readonlyComponents/GuideOtherInfo';
import { scrollableModalBodyStyle } from '@/config';
type Props = {
  onClose: (reload?: boolean) => void;
  visible: boolean;
  tourId?: string;
};

export default function UpdateTourModal({ visible, onClose, tourId }: Props) {
  const [tour, setTour] = React.useState<TourDetailModel>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (tourId) {
      setLoading(true);
      ApiSdk.TourService.getTourDetail({ id: tourId })
        .then(tour => {
          setTour(tour);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [tourId]);

  return (
    <Modal
      className="nta-custom-form create-tour-form tour-info-modal"
      bodyStyle={scrollableModalBodyStyle}
      visible={visible}
      onCancel={() => onClose()}
      style={{ top: 10, maxWidth: 1200 }}
      width={'100%'}
      title={tour?.tourName}
      okText={t('Close')}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={() => onClose()}
      maskClosable={false}
    >
      <div>
        {loading ? (
          <div style={{ marginTop: 200 }}>
            <PageLoading />
          </div>
        ) : tour ? (
          <div>
            <TourHeader tour={tour} />
            <div className="guide-info-tab-container">
              <Tabs type="card" defaultActiveKey="guide-0">
                {tour.tourGuides?.map((guide, i) => {
                  return (
                    <Tabs.TabPane key={`guide-${i}`} tab={<span style={{ textShadow: 'none', fontWeight: 'bold' }}>{guide.guideName || 'N/A'}</span>}>
                      <ReadOnlyGuideInfo guide={guide} />
                      <GuideOtherInfo guide={guide} tour={tour} />
                    </Tabs.TabPane>
                  );
                })}
              </Tabs>
            </div>
          </div>
        ) : (
          <Result
            status="404"
            title="404"
            subTitle={t('TourNotExists')}
            extra={
              <Button onClick={() => onClose(true)} type="primary">
                Close
              </Button>
            }
          />
        )}
      </div>
    </Modal>
  );
}
