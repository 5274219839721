import { ApiSdk } from '@/httpclient';
import { AutoComplete, AutoCompleteProps, FormInstance } from 'antd';
import { debounce } from 'lodash';
import React from 'react';

interface Props extends AutoCompleteProps {
  form: FormInstance<any>;
  idField: string;
  nameField: string;
}
const toLabel = (firstName: string, lastName: string) => `${lastName} ${firstName}`;

export default function NTAStaffSearchField({ form, className, value: originValue, nameField, idField, onChange, ...props }: Props) {
  const [value, setValue] = React.useState<string>('');
  const [data, setData] = React.useState<{ label: string; value: string }[]>([]);
  const [isTextValue, setIsTextValue] = React.useState<boolean>(true);

  React.useEffect(() => {
    getData();
  }, [value]);
  const _getData = () => {
    ApiSdk.StaffService.staffAutocompletion({ query: value, limit: 20 }).then(res => {
      setData(res?.map(staff => ({ label: toLabel(staff.firstNameKanji || '', staff.lastNameKanji || ''), value: staff.id || '' })) || []);
    });
  };
  const getData = debounce(_getData, 300);
  React.useEffect(() => {
    if (!originValue) {
      setValue('');
    }
  }, [originValue]);

  const fieldClassName = React.useMemo(() => {
    if (isTextValue) {
      return `nta-autocomplete is-text-value ${className || ''}`;
    }
    return `nta-autocomplete ${className || ''}`;
  }, [className, isTextValue]);
  return (
    <AutoComplete
      allowClear={true}
      value={value}
      onSearch={v => {
        setValue(v);
        setIsTextValue(true);
        form.setFieldsValue({
          [nameField]: v,
          [idField]: undefined,
        });
      }}
      onSelect={(v, op) => {
        setValue(op.label as any);
        setIsTextValue(false);
        form.setFieldsValue({
          [nameField]: undefined,
          [idField]: v,
        });
      }}
      {...props}
      options={data}
      className={fieldClassName}
    />
  );
}
