export default {
  staffSearch_department: '部署',
  staffSearch_department_title: '部署検索',
  staffSearch_subdivision: '課',
  staffSearch_subdivision_title: '課検索',
  staffSearch_staff: 'スタッフ名',
  staffSearch_userPermission: '利用権限',
  staffSearch_userPermission_search: '利用者権限',
  staffSearch_displayTerminatedUsers: '退職者の表示',
  staffSearch_new: '登録',
  staffSearch_copy: 'コピー',
  staffSearch_edit: '編集',
  staffSearch_delete: '削除',
  staffSearch_search: '検索',
  staffSearch_noNumber: '順番',
  staffSearch_accountId: 'アカウントID',
  staffSearch_name: '氏名(漢字)',
  staffSearch_approvalPermission: '承認権限',
  staffSearch_viewingPermission: '閲覧権限',
  staffSearch_usageStatus: '利用状況',
  staffSearch_terminationDate: '退職日',
  staffSearch_lastUpdatedDate: '最終更新日時',
  staffSearch_lastCreateDate: '作成日時',
  staffSearch_lastUpdatedBy: '最終更新者',
  staffSearch_lastNameKanji: '氏名(漢字)/姓',
  staffSearch_firstNameKanji: '氏名(漢字)/名',
  staffSearch_lastNameKatagana: '氏名(カナ)/セイ',
  staffSearch_firstNameKatagana: '氏名(カナ)/メイ',
  staffSearch_validateKatagana: 'カタカナを入力してください',
  staffSearch_validateJanji: '漢字を入力してください',
  staffSearch_lastNameEnglish: 'Last Name',
  staffSearch_firstNameEnglish: 'First Name',
  staffSearch_validationEnglish: '英語を入力してください',
  staffSearch_email: 'メールアドレス',
  staffSearch_phoneNumber: '携帯電話',
  staffSearch_emergencyContactAddress: '緊急連絡先',
  staffSearch_numberOfTheHandler: '扱者番号',
  staffSearch_isUserSupport: 'Gサポート',
  staffSearch_passwordTitle: 'パスワード変更',
  staffSearch_passwordTitleUpdate: 'パスワード更新',
  staffSearch_passwordRule: 'パスワードルール',
  staffSearch_passwordNew: '新しいパスワード',
  staffSearch_passwordMail: 'パスワード',
  staffSearch_confirmPassword: '確認',
  staffSearch_register: '登録',
  staffSearch_userReference: 'ユーザー(参照)',
  staffSearch_userGeneral: 'ユーザー(一般)',
  staffSearch_userAssignment: 'ユーザー(アサイン)',
  staffSearch_userManagement: 'ユーザー(管理職)',
  staffSearch_userAdmin: 'Admin',
  staffSearch_yes: 'あり',
  staffSearch_no: 'なし',
  staffSearch_active: '有効',
  staffSearch_inactive: '無効',
  staffSearch_permissionAll: '全て',
  staffSearch_permissionDepartment: '部署のみ',
  staffSearch_permissionSection: '課のみ',
  staffSearch_permissionOnlyMe: '自分のみ',
  staffSearch_passwordDescription: 'パスワードルール',
  staffSearch_passwordDescription1: '・８文字から３２文字まで',
  staffSearch_passwordDescription2: '・数字、英小文字、英大文字、およびスペース以外の記号から、２種以上',
  staffSearch_passwordDescription3: '英大文字：[A-Z]（２６文字）',
  staffSearch_passwordDescription4: '英小文字：[a-z]（２６文字）',
  staffSearch_passwordDescription5: '数字：[0-9]（１０文字）',
  staffSearch_passwordDescription6: '・メールアドレスが含まない',
  staffSearch_passwordValidation: 'パスワード設定ルールに従って入力してください。',
  save_success: '保存が成功されました。',
  staffSearch_save: '保存',
  staffSearch_cancel: 'キャンセル',
  staffPasswordNotMatch: '入力されたパスワードが一致しません。',
};
