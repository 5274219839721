import EnumSelect from '@/components/app/formItem/EnumSelect';
import LanguageInput from '@/components/app/formItem/LanguageInput';
import DatePicker from '@/components/DatePicker';
import { TourGuideStatusType, TourStatusType } from '@/config';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT, TourSeisanStatus } from '@/constants';
import GuideAutoComplete from '@/components/app/formItem/GuideSearchField';
import NTAStaffSearchField from '@/components/app/formItem/NTAStaffSearchField';
import { useAppContext } from '@/contexts/AppContext';
import { isGuideOnly } from '@/services/roleService';
import SearchableInMobile from '@/components/app/SearchableInMobile';

type Props = {
  onSearch: (filter?: any) => void;
};

export default function SearchFormForGuide({ onSearch }: Props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { auth } = useAppContext();
  const isGuide = React.useMemo(() => {
    return isGuideOnly(auth?.roleClaims);
  }, [auth]);
  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 8 }}
      labelAlign="left"
      colon={false}
      className="nta-custom-form"
      onFinish={v => {
        onSearch({
          displayTourUn: true,
          displayTourGl: true,
          ...v,
          languageCodes: v.languageCodes?.map(x => x.value || x),
          fromDate: v.fromDate ? moment(v.fromDate).format(DEFAULT_DATE_FORMAT) : undefined,
          toDate: v.toDate ? moment(v.toDate).format(DEFAULT_DATE_FORMAT) : undefined,
        });
      }}
      initialValues={{
        displayTourUn: true,
        displayTourGl: true,
      }}
    >
      <SearchableInMobile>
        <Form.Item noStyle name="guideId">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item noStyle name="guideName">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item noStyle name="picName">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item noStyle name="picId">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item noStyle name="saleId">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item noStyle name="saleName">
          <Input type="hidden" />
        </Form.Item>
        <Row gutter={6}>
          <Col xs={24} md={24} xl={6} xxl={6}>
            <Row gutter={6}>
              <Col md={24} xs={24} xl={6} xxl={6} style={{ lineHeight: '32px' }}>
                ツアー期間
              </Col>
              <Col md={12} xs={12} xl={9} xxl={9}>
                <Form.Item name="fromDate">
                  <DatePicker placeholder={t('tourSearchFrom')} id="search_fromDate" />
                </Form.Item>
              </Col>
              <Col md={12} xs={12} xl={9} xxl={9}>
                <Form.Item name="toDate">
                  <DatePicker placeholder={t('tourSearchTo')} id="search_toDate" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={12} xl={5} xxl={5}>
            <Form.Item label={t('Language')} name="languageCodes">
              <LanguageInput mode="multiple" placeholder={t('Language')} id="search_languageCodes" />
            </Form.Item>
          </Col>

          <Col xs={12} md={12} xl={5} xxl={5}>
            <Form.Item name="agentName" label="AGT名">
              <Input placeholder="AGT名" id="search_agentName" />
            </Form.Item>
          </Col>

          <Col xs={24} md={24} xl={5} xxl={5}>
            <Form.Item label={t('Guide Status')} name="guideStatus">
              <EnumSelect mode="multiple" enumData={TourGuideStatusType} placeholder={t('Guide Status')} id="search_guideStatus" allowClear={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col xs={12} md={12} xl={6} xxl={6}>
            <Row gutter={6}>
              <Col md={24} xs={24} xl={6} xxl={6} style={{ lineHeight: '32px' }}>
                {t('Tour Name')}
              </Col>
              <Col md={24} xs={24} xl={18} xxl={18}>
                <Form.Item name="tourName">
                  <Input placeholder={t('Tour Name')} id="search_tourName" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={12} xl={5} xxl={5}>
            <Form.Item name="tourNumber" label={t('Tour Number')}>
              <Input placeholder={t('Tour Number')} id="search_tourNumber" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={5} xxl={5}>
            <Form.Item label="ツアーID" name="tourId">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col xs={12} md={12} xl={6} xxl={6}>
            <Row gutter={6}>
              <Col md={24} xs={24} xl={6} xxl={6} style={{ lineHeight: '32px', width: 100 }}>
                {t('Sales')}
              </Col>
              <Col md={24} xs={24} xl={18} xxl={18}>
                <Form.Item name="saleStaff">
                  <NTAStaffSearchField form={form} idField="saleId" nameField="saleName" placeholder={t('Sales')} id="search_saleStaff" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={12} xl={5} xxl={5}>
            <Form.Item name="pic" label={t('In charge of tour')}>
              <NTAStaffSearchField form={form} id="search_pic" idField="picId" nameField="picName" placeholder={t('In charge of tour')} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={5} xxl={5}>
            <Form.Item label={t('Tour Status')} name="tourStatus">
              <EnumSelect mode="multiple" enumData={TourStatusType} placeholder={t('Tour Status')} id="search_tourStatus" allowClear={true} />
            </Form.Item>
          </Col>

          <Col className="search-form-action">
            <Button type="primary" htmlType="submit">
              <SearchOutlined style={{ color: '#333' }} />
              {t('zTable.search')}
            </Button>
            <Button type="ghost" htmlType="reset" style={{ marginLeft: 5 }}>
              <CloseOutlined />
              {t('zTable.reset')}
            </Button>
          </Col>
        </Row>
      </SearchableInMobile>
    </Form>
  );
}
