import { Modal } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import { HIDE_MESSAGE_401_PARAMS } from './config';
import i18n from './helpers/i18n';
import authService from './services/authService';
import * as apiInfo from './__generated';
import { getSelectedRole } from './services/roleService';

// interceptors
let isRefreshing: boolean = false;
const waitForRefreshTokenFinish = () => {
  return new Promise(resolve => {
    const interval = window.setInterval(() => {
      if (!isRefreshing) {
        if (interval) {
          window.clearInterval(interval);
        }
        resolve(null);
      }
    }, 50);
  });
};

const handleResponse401Interceptors = async (err: any) => {
  const { config, response } = err;
  if (response?.status === 401) {
    const logout = () => {
      const noDisplayMessage = (config?.params || {})[HIDE_MESSAGE_401_PARAMS];
      if (noDisplayMessage) {
        authService.clearOnLogout();
        window.location.reload();
        return Promise.reject(err);
      } else {
        authService.clearOnLogout();
        Modal.error({
          title: i18n.t('LoginSessionExpiredTitle'),
          content: i18n.t('LoginSessionExpiredContent'),
          onOk: () => {
            window.location.reload();
          },
        });
        return Promise.reject(err);
      }
    };
    if (!isRefreshing) {
      isRefreshing = true;
      const refreshToken = authService.getRefreshToken();
      const loginInfo = authService.getLoginInfo();
      if (!refreshToken) {
        return logout();
      }

      try {
        const response = await ApiSdk.AuthService.refreshToken({
          body: {
            refreshToken,
            userId: loginInfo?.userId,
          },
        });
        if (response.success) {
          const expiredTime = moment(new Date()).add(response.expiryDuration, 'minutes');
          authService.saveLoginInfo({
            token: response.accessToken || '',
            expiredTime,
            userId: response.userId || '',
          });
          authService.saveRefreshToken(response.refreshToken || '');
          config.headers.Authorization = `Bearer ${response.accessToken}`;
          config.headers['user-role'] = getSelectedRole();
          isRefreshing = false;
          return Axios.request(config);
        } else {
          return logout();
        }
      } catch (error) {
        return logout();
      }
    } else {
      // other request call renew token
      await waitForRefreshTokenFinish();
      const token = authService.getAccessToken();
      config.headers.Authorization = token ? `Bearer ${token}` : '';
      config.headers['user-role'] = getSelectedRole();
      return Axios.request(config);
    }
  } else {
    return Promise.reject(err);
  }
};

const httpClient = Axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT || ''}/api/cms`,
  paramsSerializer: (params: any) => {
    const _params = new URLSearchParams();
    if (params) {
      Object.keys(params).forEach((k: string) => {
        if (Array.isArray(params[k])) {
          params[k]?.forEach(c => {
            _params.append(k, c);
          });
        } else if (params[k] !== undefined) {
          _params.append(k, params[k]);
        }
      });
    }
    return _params as any;
  },
  validateStatus: status => {
    return status >= 200 && status < 400;
  },
});

httpClient.interceptors.request.use(config => {
  const token = authService.getAccessToken();
  if (token) {
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }
  config.headers['user-role'] = getSelectedRole();
  return config;
});
httpClient.interceptors.response.use(res => res, handleResponse401Interceptors);
export default httpClient;
// axios codegen
const apiSdkAxios = Axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || '',
  paramsSerializer: (params: any) => {
    const _params = new URLSearchParams();
    if (params) {
      Object.keys(params).forEach((k: string) => {
        if (Array.isArray(params[k])) {
          params[k]?.forEach(c => {
            _params.append(k, c);
          });
        } else if (params[k] !== undefined) {
          _params.append(k, params[k]);
        }
      });
    }
    return _params as any;
  },
  validateStatus: status => {
    return status >= 200 && status < 400;
  },
});
apiSdkAxios.interceptors.request.use(config => {
  const token = authService.getAccessToken();
  if (token) {
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }
  config.headers['user-role'] = getSelectedRole();
  return config;
});
apiSdkAxios.interceptors.response.use(res => res, handleResponse401Interceptors);

apiInfo.serviceOptions.axios = apiSdkAxios;
export const ApiSdk = apiInfo;

// UN http client
export const unHttpClient = Axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT || ''}/api/un`,
  paramsSerializer: (params: any) => {
    const _params = new URLSearchParams();
    if (params) {
      Object.keys(params).forEach((k: string) => {
        if (Array.isArray(params[k])) {
          params[k]?.forEach(c => {
            _params.append(k, c);
          });
        } else if (params[k] !== undefined) {
          _params.append(k, params[k]);
        }
      });
    }
    return _params as any;
  },
  validateStatus: status => {
    if (status === 401) {
      window.location.href = '/un/403';
    }
    return status >= 200 && status < 400;
  },
});

unHttpClient.interceptors.request.use(config => {
  const token = new URLSearchParams(window.location.search)?.get('token') || '';
  if (token) {
    config.headers['x-api-token'] = token;
  }
  config.headers['user-role'] = getSelectedRole();
  return config;
});
