import React from 'react';
import { DEFAULT_DATE_FORMAT, VALIDATE_REQUIRED } from '@/constants';
import { Trans } from 'react-i18next';
import lodash, { minBy, maxBy, groupBy, orderBy, sumBy } from 'lodash';
import dayjs from 'dayjs';
import {
  BusinessDay,
  CreateUpdateBusinessDayModel,
  CreateUpdateTourModel,
  Tour,
  TourDetailModel,
  TourGuide,
  TourGuideDetailModel,
  TourGuideStatus,
} from '@/__generated';
import { TourGuideStatusType } from '@/config';
import { Rule } from 'antd/lib/form';
import i18n from '@/helpers/i18n';
import { X } from 'react-feather';
import moment from 'moment';
interface IRuleMessage {
  required: boolean;
  message: any;
}

export interface ICreateTourValidation {
  nationality: IRuleMessage;
  pic: IRuleMessage;
  noOfPassengerEst: IRuleMessage;
  noOfPassengerBooked: IRuleMessage;
  guideInfo: IRuleMessage;
  availability: [IRuleMessage] | Rule[];
  businessDayInfo: [IRuleMessage] | Rule[];
}
export const tourCreationValidation: ICreateTourValidation = {
  nationality: {
    required: false,
    message: <Trans>{VALIDATE_REQUIRED}</Trans>,
  },
  pic: {
    required: false,
    message: <Trans>{VALIDATE_REQUIRED}</Trans>,
  },
  noOfPassengerEst: {
    required: false,
    message: <Trans>{VALIDATE_REQUIRED}</Trans>,
  },
  noOfPassengerBooked: {
    required: false,
    message: <Trans>{VALIDATE_REQUIRED}</Trans>,
  },
  guideInfo: {
    required: false,
    message: <Trans>{VALIDATE_REQUIRED}</Trans>,
  },
  availability: [
    {
      required: false,
      message: <Trans>{VALIDATE_REQUIRED}</Trans>,
    },
  ],
  businessDayInfo: [
    {
      required: false,
      message: <Trans>{VALIDATE_REQUIRED}</Trans>,
    },
  ],
};

export const tourRequestValidation: ICreateTourValidation = {
  nationality: {
    required: true,
    message: <Trans>{VALIDATE_REQUIRED}</Trans>,
  },
  pic: {
    required: false,
    message: <Trans>{VALIDATE_REQUIRED}</Trans>,
  },
  noOfPassengerEst: {
    required: true,
    message: <Trans>{VALIDATE_REQUIRED}</Trans>,
  },
  noOfPassengerBooked: {
    required: false,
    message: <Trans>{VALIDATE_REQUIRED}</Trans>,
  },
  guideInfo: {
    required: true,
    message: <Trans>{VALIDATE_REQUIRED}</Trans>,
  },
  availability: [
    {
      required: true,
      message: <Trans>{VALIDATE_REQUIRED}</Trans>,
    },
    {
      validator: (_, value) => {
        return [1, 2].includes(value) ? Promise.resolve() : Promise.reject(<Trans>{VALIDATE_REQUIRED}</Trans>);
      },
    },
  ],
  businessDayInfo: [
    {
      required: true,
      message: <Trans>{VALIDATE_REQUIRED}</Trans>,
    },
    {
      validator: (_, value) => {
        return [1, 2].includes(value) ? Promise.resolve() : Promise.reject(<Trans>{VALIDATE_REQUIRED}</Trans>);
      },
    },
  ],
};
export const tourRequestAssigningValidation: ICreateTourValidation = {
  ...tourRequestValidation,
  availability: [
    {
      required: false,
      message: <Trans>{VALIDATE_REQUIRED}</Trans>,
    },
  ],
};

export const tourFinalValidation: ICreateTourValidation = {
  nationality: {
    required: true,
    message: <Trans>{VALIDATE_REQUIRED}</Trans>,
  },
  pic: {
    required: true,
    message: <Trans>{VALIDATE_REQUIRED}</Trans>,
  },
  noOfPassengerEst: {
    required: true,
    message: <Trans>{VALIDATE_REQUIRED}</Trans>,
  },
  noOfPassengerBooked: {
    required: true,
    message: <Trans>{VALIDATE_REQUIRED}</Trans>,
  },
  guideInfo: {
    required: true,
    message: <Trans>{VALIDATE_REQUIRED}</Trans>,
  },
  availability: [
    {
      required: true,
      message: <Trans>{VALIDATE_REQUIRED}</Trans>,
    },
    {
      validator: (_, value) => {
        return [1, 2].includes(value) ? Promise.resolve() : Promise.reject(<Trans>{VALIDATE_REQUIRED}</Trans>);
      },
    },
  ],
  businessDayInfo: [
    {
      required: true,
      message: <Trans>{VALIDATE_REQUIRED}</Trans>,
    },
    {
      validator: (_, value) => {
        return [1, 2].includes(value) ? Promise.resolve() : Promise.reject(<Trans>{VALIDATE_REQUIRED}</Trans>);
      },
    },
  ],
};

export const getMinDateOfGuide = tourGuide => {
  const days = tourGuide?.businessDays?.filter(x => x.date);
  if (days?.length) {
    return minBy(days, (x: any) => dayjs(x.date)).date;
  }
  return null;
};
export const getMaxDateOfGuide = tourGuide => {
  const days = tourGuide?.businessDays?.filter(x => x.date);
  if (days?.length) {
    return maxBy(days, (x: any) => dayjs(x.date)).date;
  }
  return null;
};

export const totalEstimatedPassengers = (tour: TourDetailModel) => {
  return getTotalPax([tour.noOfAdultEstimated, tour.noOfChildAEstimated, tour.noOfChildBEstimated, tour.noOfInfantEstimated, tour.noOfLeadEstimated]);
};
export const totalBookedPassengers = (tour: TourDetailModel) => {
  return getTotalPax([tour.noOfAdultBooked, tour.noOfChildABooked, tour.noOfChildBBooked, tour.noOfInfantBooked, tour.noOfLeadBooked]);
};

export const getTotalPax = (paxes: any[]) => {
  if (paxes.some(x => !['', null, undefined].includes(x))) {
    return sumBy(paxes, x => x || 0);
  }
  return '';
};

export const FORM_VALIDATION = {
  required: { required: true, message: <Trans>{VALIDATE_REQUIRED}</Trans> },
};

/**
 * Filter the array to only include items that have an indexOf that is not equal to the current index.
 * @param {string[]} arr - string[] - the array to search
 */
const toFindStringDuplicates = (arr: string[]) => arr.filter((item, index) => arr.indexOf(item) !== index);

/**
 * It checks if there are any duplicates in the tour guides and their languages, and also checks if
 * there are any duplicates in the tour guides and their dates
 * @param {CreateUpdateTourModel} formValues - CreateUpdateTourModel
 */
export const validateDataOnSubmit = (formValues: CreateUpdateTourModel): { isValid: boolean; errMessage?: string[] } => {
  // dont check status = uncheckStatus
  const uncheckStatus = [TourGuideStatusType.Denied, TourGuideStatusType.Cancelled, TourGuideStatusType.AwaitingCancellation];
  const guides = formValues.tourGuides?.filter(x => !uncheckStatus.includes(x.status as any)) || [];
  if (!guides.length) {
    return { isValid: true };
  }

  // check guide same language
  const guidesHasLanguage = guides.filter(x => x.guideId && x.languageCode);
  const guidesHasLanguageGrouped = groupBy(guidesHasLanguage, x => `${x.guideId}-${x.languageCode}`);

  let isValid = true;
  const errMessage: string[] = [];
  Object.keys(guidesHasLanguageGrouped).forEach(key => {
    const guidesSameLang = guidesHasLanguageGrouped[key];
    if (guidesSameLang.length > 1) {
      isValid = false;
      errMessage.push(
        i18n.t('GuideAndLanguageDuplicated', {
          name: guidesSameLang[0].guideName,
          language: guidesSameLang[0].languageName,
        }),
      );
    }
  });

  //check same date
  const groupedGuides = groupBy(guidesHasLanguage, x => `${x.guideId}`);
  Object.keys(groupedGuides).forEach(k => {
    let date: string[] = [];
    groupedGuides[k].forEach(guideInfo => {
      date = date.concat(guideInfo.businessDays?.filter(x => x.date && !x.isCancelled).map(x => x.date as any) || []);
    });
    const duplicateDate = toFindStringDuplicates(date);
    if (duplicateDate.length) {
      duplicateDate.forEach(d => {
        errMessage.push(
          i18n.t('GuideAndDateDuplicated', {
            name: groupedGuides[k][0].guideName,
            date: d,
          }),
        );
      });
      isValid = false;
    }
  });

  return { isValid, errMessage };
};

/**
 * It checks if the guide data has changed
 * @param {CreateUpdateTourModel} formValues - CreateUpdateTourModel
 * @param {Tour} tour - Tour
 * @returns An object with the following properties:
 */

export const checkGuideDataChange = (
  formValues: CreateUpdateTourModel,
  tour: Tour,
): { hasChange: boolean; guides?: { guide: string; language: string; status: any }[]; newFormData: CreateUpdateTourModel } => {
  const formGuides = formValues.tourGuides?.filter(x => x.id) || [];
  let hasChange: boolean = false;
  const guides: { guide: string; language: string; status: any }[] = [];
  formGuides.forEach(guide => {
    const originGuideData = tour.tourGuides?.find(x => x.id === guide.id);
    if (originGuideData) {
      const uncheckCurrentStatus = [
        TourGuideStatusType.Unassigned,
        TourGuideStatusType.Assigning,
        TourGuideStatusType.FinalInforming,
        TourGuideStatusType.AwaitingCancellation,
      ];
      const uncheckPrevStatus = [TourGuideStatusType.Unassigned];
      const needCheckStatus = !uncheckPrevStatus.includes(originGuideData.status as any) && !uncheckCurrentStatus.includes(guide.status as any);
      // dont check is current status  = uncheckCurrentStatus
      // dont check prev status = uncheckPrevStatus
      // dont check if not have guide
      // ont check if not have guide
      if (needCheckStatus && originGuideData.guideId && originGuideData.languageCode) {
        // check status
        if (originGuideData.guideId !== guide.guideId) {
          hasChange = true;
          guides.push({
            guide: guide.guideName || '',
            language: guide.languageName || '',
            status: guide.status,
          });
          return;
        }
        // check language
        if (originGuideData.languageCode !== guide.languageCode) {
          hasChange = true;
          guides.push({
            guide: guide.guideName || '',
            language: guide.languageName || '',
            status: guide.status,
          });
          return;
        }

        // check date
        const businessDays = guide.businessDays?.filter(x => x.date) || [];
        const originBusinessDays = orderBy(
          originGuideData.businessDays?.filter(x => x.date),
          x => dayjs(x.date),
        );
        if (businessDays.length !== originBusinessDays.length) {
          hasChange = true;
          guides.push({
            guide: guide.guideName || '',
            language: guide.languageName || '',
            status: guide.status,
          });
          return;
        }

        const hasDaysChange = businessDays.some((day, index) => {
          const originDay: BusinessDay = originBusinessDays[index];
          return (
            day.stayLastNight !== originDay.stayLastNight ||
            day.stayOvernight !== originDay.stayOvernight ||
            day.fee !== originDay.fee ||
            !!day.availability !== !!originDay.availability ||
            !!day.isCancelled !== !!originDay.isCancelled ||
            day.date !== (dayjs(originDay.date).format(DEFAULT_DATE_FORMAT) as any)
          );
        });
        if (hasDaysChange) {
          hasChange = true;
          guides.push({
            guide: guide.guideName || '',
            language: guide.languageName || '',
            status: guide.status,
          });
          return;
        }
      }
    }
  });
  return {
    hasChange,
    guides,
    newFormData: formValues,
  };
};

export const M2ValidationStatus = [
  TourGuideStatusType.Assigning,
  TourGuideStatusType.Checking,
  TourGuideStatusType.Assigned,
  TourGuideStatusType.Checked,
  TourGuideStatusType.AwaitingCancellation,
  TourGuideStatusType.Denied,
];
export const M3ValidationStatus = [TourGuideStatusType.Finalized, TourGuideStatusType.FinalChecking, TourGuideStatusType.FinalInforming];

/**
 * If the previous status is null or Unassigned, and the next status is Denied, then return true.
 * @param {null | TourGuideStatus} preStatus - The previous status of the tour guide.
 * @param {TourGuideStatus} nextStatus - The status that the tour guide is being changed to.
 * @returns A boolean value
 */
const needAddNtaComment = (preStatus: null | TourGuideStatus, nextStatus: TourGuideStatus) => {
  if (!preStatus) {
    return [TourGuideStatusType.Denied].includes(nextStatus);
  }
  if (preStatus === TourGuideStatusType.Unassigned) {
    return [TourGuideStatusType.Denied].includes(nextStatus);
  }
  if (preStatus === TourGuideStatusType.Assigning) {
    return [TourGuideStatusType.Unassigned].includes(nextStatus);
  }
  if (preStatus === TourGuideStatusType.AwaitingCancellation) {
    return [TourGuideStatusType.Checking, TourGuideStatusType.Finalized].includes(nextStatus);
  }
  if (preStatus === TourGuideStatusType.Cancelled) {
    return [TourGuideStatusType.Checking].includes(nextStatus);
  }
  if (preStatus === TourGuideStatusType.Denied) {
    return [TourGuideStatusType.Checking].includes(nextStatus);
  }
  if (nextStatus === TourGuideStatusType.Denied) {
    return [
      TourGuideStatusType.Unassigned,
      TourGuideStatusType.Checking,
      TourGuideStatusType.Assigned,
      TourGuideStatusType.Informing2MonthPrior,
      TourGuideStatusType.Informing3MonthsPrior,
      TourGuideStatusType.Checked,
      TourGuideStatusType.FinalInforming,
      TourGuideStatusType.FinalChecking,
      TourGuideStatusType.Finalized,
    ].includes(preStatus);
  }
  return false;
};

/**
 * It checks if a tour guide needs to add a comment when creating a tour
 * @param {CreateUpdateTourModel} formValues - CreateUpdateTourModel
 * @returns An object with two properties: needComment and guides.
 */
export const checkNeedNtaCommentForCreateTour = (
  formValues: CreateUpdateTourModel,
): { needComment: boolean; guides: { name: string; language: string; status: any }[] } => {
  const guides = formValues.tourGuides || [];
  if (guides.length) {
    const guidesNeedComment = guides.filter(x => !x.ntaComments?.trim() && needAddNtaComment(null, x.status || 1));
    if (guidesNeedComment.length) {
      return {
        needComment: true,
        guides: guidesNeedComment.map(g => {
          return {
            name: g.guideName || '',
            language: g.languageName || '',
            status: g.status,
          };
        }),
      };
    }
  }
  return {
    needComment: false,
    guides: [],
  };
};

/**
 * It checks if a tour guide needs to add a comment when updating a tour
 * @param {CreateUpdateTourModel} formValues - CreateUpdateTourModel
 * @param {Tour} tour - Tour - the original tour object
 * @returns An object with two properties: needComment and guides.
 */
export const checkNeedNtaCommentForUpdateTour = (formValues: CreateUpdateTourModel, tour: Tour) => {
  const guides = formValues.tourGuides || [];
  if (guides.length) {
    const guidesNeedComment = guides.filter(guide => {
      if (guide.id) {
        const originStatus = tour?.tourGuides?.find(x => x.id === guide.id)?.status || 1;
        return !guide.ntaComments?.trim() && needAddNtaComment(originStatus, guide.status || 1);
      } else {
        return !guide.ntaComments?.trim() && needAddNtaComment(null, guide.status || 1);
      }
    });
    if (guidesNeedComment.length) {
      return {
        needComment: true,
        guides: guidesNeedComment.map(g => {
          return {
            name: g.guideName || '',
            language: g.languageName || '',
            status: g.status,
          };
        }),
      };
    }
  }
  return {
    needComment: false,
    guides: [],
  };
};

// when status changes to Assigned, email is sent out
// when status is kept as Assigned but there are changes (date, fee, avai, pre, last)
// Show popup: ガイドにメールを送りますか？(Do you want to send an email to the guide?)
// - If yes, send emtail
// - If no, don't send email
export const checkNeedSendMailWhenStatusAssignedAndDateChange = (formValues: CreateUpdateTourModel, tour: Tour) => {
  let hasChange: boolean = false;
  const formGuides = formValues.tourGuides?.filter(x => x.id) || [];
  const changeGuides: { guide: string; language: string; status: any }[] = [];
  formGuides.forEach(guide => {
    const originGuideData = tour.tourGuides?.find(x => x.id === guide.id);
    if (originGuideData) {
      if (
        originGuideData.status === TourGuideStatusType.Assigned &&
        guide.status === TourGuideStatusType.Assigned &&
        originGuideData.guideId &&
        originGuideData.languageCode
      ) {
        // check date
        const businessDays = guide.businessDays?.filter(x => x.date) || [];
        const originBusinessDays = orderBy(
          originGuideData.businessDays?.filter(x => x.date),
          x => dayjs(x.date),
        );
        if (businessDays.length !== originBusinessDays.length) {
          hasChange = true;
          changeGuides.push({
            guide: guide.guideName || '',
            language: guide.languageName || '',
            status: guide.status,
          });
          return;
        }
        const hasDaysChange = businessDays.some((day, index) => {
          const originDay: BusinessDay = originBusinessDays[index];
          return (
            day.stayLastNight !== originDay.stayLastNight ||
            day.stayOvernight !== originDay.stayOvernight ||
            day.fee !== originDay.fee ||
            !!day.availability !== !!originDay.availability ||
            !!day.isCancelled !== !!originDay.isCancelled ||
            day.date !== (dayjs(originDay.date).format(DEFAULT_DATE_FORMAT) as any)
          );
        });
        if (hasDaysChange) {
          hasChange = true;
          changeGuides.push({
            guide: guide.guideName || '',
            language: guide.languageName || '',
            status: guide.status,
          });
          return;
        }
      }
    }
  });
  return { hasChange, changeGuides };
};

/**
 * It checks if there are any guides that are assigned to the tour and if they are available for the
 * tour
 * @param {CreateUpdateTourModel} formValues - CreateUpdateTourModel
 * @returns An object with two properties: showMessage and guides.
 */
export const checkGuideUnAvailability = (formValues: CreateUpdateTourModel) => {
  const guides = formValues.tourGuides || [];
  if (guides.length) {
    const checkStatus = [
      TourGuideStatusType.Checking,
      TourGuideStatusType.Assigned,
      TourGuideStatusType.Checked,
      TourGuideStatusType.Assigning,
      TourGuideStatusType.FinalInforming,
    ];
    const guidesUnAvailability = guides.filter(guide => {
      if (checkStatus.includes(guide.status as any)) {
        return !guide.businessDays?.filter(x => !x.isCancelled)?.some(x => x.availability);
      }
      return false;
    });
    if (guidesUnAvailability.length) {
      return {
        showMessage: true,
        guides: guidesUnAvailability.map(g => {
          return {
            name: g.guideName || '',
            language: g.languageName || '',
            status: g.status,
          };
        }),
      };
    }
  }
  return {
    showMessage: false,
    guides: [],
  };
};

/**
 * It checks if all the dates of a tour guide are cancelled and if the tour guide is not awaiting
 * cancellation
 * @param {CreateUpdateTourModel} formValues - CreateUpdateTourModel
 */
export const checkGuideCancellationAllDateIsCancelled = (formValues: CreateUpdateTourModel, tour: any) => {
  const guides = formValues.tourGuides || [];
  const originGuides: TourGuide[] = tour?.tourGuides || [];

  if (guides.length) {
    const guidesUnAvailability = guides.filter(guide => {
      const isAllCancelled = !guide.businessDays?.some(x => !x.isCancelled);
      // check has is at least one date is NEWLY cancelled
      if (guide.status == TourGuideStatusType.AwaitingCancellation || !isAllCancelled) {
        return false;
      }
      let originGuide = originGuides.find(x => x.id === guide.id);

      return guide.businessDays?.some(x => {
        let originDate = originGuide?.businessDays?.find(d => d.id === x.id);
        return originDate?.isCancelled !== x.isCancelled;
      });
    });
    if (guidesUnAvailability.length) {
      return {
        showMessage: true,
        guides: guidesUnAvailability.map(g => {
          return {
            name: g.guideName || '',
            language: g.languageName || '',
            status: g.status,
          };
        }),
      };
    }
  }
  return {
    showMessage: false,
    guides: [],
  };
};

/**
 * It checks if there are any guides that are not unassigned and have no business days
 * @param {CreateUpdateTourModel} formValues - CreateUpdateTourModel
 * @returns An object with two properties: showMessage and guides.
 */
export const checkUnassignedNoDate = (formValues: CreateUpdateTourModel) => {
  const guides = formValues.tourGuides || [];
  if (guides.length) {
    const guidesUnAvailability = guides.filter(guide => {
      if (guide.status !== TourGuideStatusType.Unassigned) {
        return !guide.businessDays?.length;
      }
      return false;
    });
    if (guidesUnAvailability.length) {
      return {
        showMessage: true,
        guides: guidesUnAvailability.map(g => {
          return {
            name: g.guideName || '',
            language: g.languageName || '',
            status: g.status,
          };
        }),
      };
    }
  }
  return {
    showMessage: false,
    guides: [],
  };
};

/**
 * It checks if there are any guides in the tour that are unassigned and have cancelled dates. If so,
 * it returns an object with a list of those guides
 * @param {CreateUpdateTourModel} formValues - CreateUpdateTourModel
 * @returns An object with two properties: showMessage and guides.
 */
export const deleteCancelledDateIfNeed = (formValues: CreateUpdateTourModel) => {
  const guides = formValues.tourGuides || [];
  if (guides.length) {
    const checkStatus = [TourGuideStatusType.Unassigned];
    const guidesUnAvailability = guides.filter(guide => {
      if (checkStatus.includes(guide.status as any)) {
        return guide.businessDays?.some(x => x.isCancelled);
      }
      return false;
    });
    if (guidesUnAvailability.length) {
      return {
        showMessage: true,
        guides: guidesUnAvailability.map(g => {
          return {
            name: g.guideName || '',
            language: g.languageName || '',
            status: g.status,
          };
        }),
      };
    }
  }
  return {
    showMessage: false,
    guides: [],
  };
};

export const getBusinessDateDuration = (businessDays: CreateUpdateBusinessDayModel[]) => {
  let _businessDays = businessDays?.filter(x => x.date) || [];
  const allDayCancelled = !_businessDays.some(x => !x.isCancelled);

  if (allDayCancelled) {
    const fromDate = lodash.minBy(_businessDays, x => moment(x.date));
    const toDate = lodash.maxBy(_businessDays, x => moment(x.date));
    return { fromDate, toDate };
  }
  _businessDays = _businessDays.filter(x => !x.isCancelled);
  if (_businessDays.some(x => x.availability)) {
    _businessDays = _businessDays?.filter(x => x.availability);
  }

  const fromDate = lodash.minBy(_businessDays, x => moment(x.date));
  const toDate = lodash.maxBy(_businessDays, x => moment(x.date));
  return { fromDate, toDate };
};

/**
 * If the status of a tour guide is changed to cancelled, all dates are cancelled. If the status of a
 * tour guide is changed from cancelled to something else, all dates are uncancelled
 * @param {CreateUpdateTourModel} formBody - CreateUpdateTourModel
 * @param {Tour | undefined | null} [tour] - Tour | undefined | null
 */
export const createTourDataBeforeSubmit = (formBody: CreateUpdateTourModel, tour?: Tour | undefined | null) => {
  formBody.tourGuides?.forEach(guide => {
    const cancelledStatus = [TourGuideStatusType.AwaitingCancellation, TourGuideStatusType.Cancelled];
    // If status changes to CXLing/ CXLed, all dates are IsCancelled = true
    if (cancelledStatus.includes(guide.status as any)) {
      guide.businessDays = guide.businessDays?.map(d => ({ ...d, isCancelled: true }));
    }
    //
    // IF Old status = cancelled/ CXLing, next status != Cancelled/ CXLing, update all dates: IsCancelled = false
    if (tour) {
      const originGuide = tour.tourGuides?.find(x => x.id === guide.id);
      if (originGuide && cancelledStatus.includes(originGuide.status as any) && !cancelledStatus.includes(guide.status as any)) {
        guide.businessDays = guide.businessDays?.map(d => ({ ...d, isCancelled: false }));
      }
    }
    // set stayLastNight = blank if date is not first of range
    if (guide?.businessDays?.length) {
      guide.businessDays
        ?.filter(x => x.date && !x.isCancelled && x.availability)
        .forEach((date, index) => {
          let allowPreNight = index === 0;
          const preDay = index === 0 ? null : (guide.businessDays as any)[index - 1];
          if (preDay?.date) {
            allowPreNight = !dayjs(date.date)
              .add(-1, 'day')
              .isSame(preDay?.date, 'day');
          } else {
            allowPreNight = true;
          }
          if (!allowPreNight) {
            date.stayLastNight = 0;
          }
        });
    }
  });
};

export const getTourAvailableArea = (tour: TourDetailModel, guide: TourGuideDetailModel) => {
  return '';
};
