import { message } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';
import client from '../../httpclient';

export default (url: string, onCompleted?: () => void, onError?: (data: any) => void) => {
  client
    .delete(url)
    .then(res => {
      if (!res?.data?.success && res?.data?.message) {
        if (onError) {
          onError(res?.data?.message);
        } else {
          message.error(<Trans>{res?.data?.message}</Trans>);
        }
      } else {
        onCompleted && onCompleted();
      }
    })
    .catch(error => {
      if (onError) {
        onError(error);
      } else {
        message.error(error);
      }
    });
};
