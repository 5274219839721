import { DEFAULT_DATE_FORMAT, DEFAULT_PAGE_SIZE, ERROR_EXCEPTION, PAGE_SIZE_OPTION } from '@/constants';
import { ApiSdk } from '@/httpclient';
import MainContent from '@/Layout/MainContent';
import { TourEvaluationDto } from '@/__generated';
import { ColumnHeightOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, message, Select, Table } from 'antd';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { sumBy } from 'lodash';
import React, { useCallback, useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import SearchForm from './SearchForm';
import TourEvaluation, { ShortTourGuideInfo } from './TourEvaluation';
import { getFacilityOnlyCols, getTourGuideOnlyCols } from './utils';
import { fileService } from '@/services/fileService';
import moment from 'moment';
import SearchableInMobile from '@/components/app/SearchableInMobile';
type Props = {};
const PAGE_TITLE = 'feedback';
const pageSize = 20;
type filterType = {
  showOnlyTourReview?: boolean;
  showOnlyFacilityReview?: boolean;
  pageSize: number;
  pageIndex: number;
};
interface CustomTourEvaluationDto extends TourEvaluationDto {
  index: number;
}
const isGuideEvaluationObject = obj => obj.hasOwnProperty('facilityEvaluation');

export default function TourFeedback({}: Props) {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [feedbacks, setFeedbacks] = useState<CustomTourEvaluationDto[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  const [guideEvaluation, setGuideEvaluation] = React.useState<ShortTourGuideInfo>();
  const [facilityEvaluationId, setFacilityEvaluationId] = React.useState<string>();

  const [filters, setFilters] = useState<filterType>({
    pageSize,
    pageIndex: 1,
  });

  const onFilterChange = f => {
    setFilters(filter => ({ ...filter, ...f, pageIndex: 1 }));
  };
  const getData = () => {
    setLoading(true);
    ApiSdk.TourEvaluationService.getTourEvaluations(filters)
      .then(res => {
        setFeedbacks(
          res.data?.map((d, i) => {
            return { ...d, index: i + 1 };
          }) || [],
        );
        setTotal(res.total || 0);
        setExpandedRowKeys(res.data?.map(x => x.id || '') || []);
      })
      .catch(() => {
        setFeedbacks([]);
        setTotal(0);
        setExpandedRowKeys([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getData();
  }, [filters]);

  const onTableChange = useCallback(
    pagination => {
      const p = pagination?.current || 1;
      setFilters({
        ...filters,
        pageIndex: p,
        pageSize: pagination.pageSize,
      });
    },
    [filters],
  );

  const onEdit = (record: CustomTourEvaluationDto) => {
    setGuideEvaluation({
      guideId: record.tourGuideId || '',
    });
    if (!isGuideEvaluationObject(record)) {
      setFacilityEvaluationId(record.facilityEvaluation?.id || record.id);
    }
  };
  const { columns, W } = React.useMemo(() => {
    let cols = getTourGuideOnlyCols(t, onEdit);
    if (filters.showOnlyFacilityReview && !filters.showOnlyTourReview) {
      cols = getFacilityOnlyCols(t, onEdit);
    }

    return { columns: cols, W: sumBy(cols, x => (x.width as any) || 0) };
  }, [filters]);

  const expandable = React.useMemo(() => {
    const hasExpandable =
      (filters.showOnlyFacilityReview && filters.showOnlyTourReview) || (!filters.showOnlyFacilityReview && !filters.showOnlyTourReview);
    if (hasExpandable) {
      const ex: ExpandableConfig<any> = {
        childrenColumnName: 'facilityEvaluations',
        expandedRowKeys,
        onExpandedRowsChange: ex => setExpandedRowKeys(ex as any),
      };
      return ex;
    }
    return undefined;
  }, [feedbacks, expandedRowKeys]);

  const exportFile = () => {
    const loader = message.loading('Downloading');
    ApiSdk.TourEvaluationService.export(filters, {
      responseType: 'blob',
    })
      .then(res => {
        fileService.saveByte2File(`フィードバック${moment().format('YYYYMMDD')}.xlsx`, res);
      })
      .catch(() => {
        message.error(ERROR_EXCEPTION);
      })
      .finally(() => {
        loader();
      });
  };
  const actionBtns = React.useMemo(() => {
    return [
      <Button key="create" type="default" icon={<DownloadOutlined />} onClick={exportFile}>
        Excelダウンロード
      </Button>,
    ];
  }, [filters]);

  return (
    <MainContent title={t(PAGE_TITLE)} extraBtns={actionBtns}>
      <Helmet title={t(PAGE_TITLE)} />
      <div className="ant-layout-content-body" style={{ marginBottom: 10 }}>
        <SearchableInMobile>
          <SearchForm onFilterChange={onFilterChange} />
        </SearchableInMobile>

        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
          <div>{`${t('zTable.records')} ${total}`}</div>
          <div style={{ marginLeft: 20 }}>
            {t('zTable.pageSize')}
            <Select
              value={filters.pageSize}
              style={{ width: 90 }}
              onChange={v => {
                setFilters(q => ({ ...q, pageSize: parseInt((v as any) || filters.pageSize || DEFAULT_PAGE_SIZE) }));
              }}
            >
              {PAGE_SIZE_OPTION.map(page => (
                <Select.Option key={page} value={page}>
                  {page}
                </Select.Option>
              ))}
            </Select>
            <Button
              style={{ marginLeft: 10 }}
              icon={<ColumnHeightOutlined />}
              onClick={() => {
                setExpandedRowKeys(ex => (ex?.length ? [] : feedbacks?.map(x => x.id || '') || []));
              }}
            />
          </div>
        </div>
        <Table
          scroll={{ x: W }}
          loading={loading}
          columns={columns}
          dataSource={feedbacks}
          expandable={expandable}
          rowKey="id"
          showSorterTooltip={false}
          pagination={{
            hideOnSinglePage: true,
            current: filters.pageIndex,
            pageSize: filters.pageSize,
            total,
            showSizeChanger: true,
            pageSizeOptions: PAGE_SIZE_OPTION,
          }}
          onChange={onTableChange}
          bordered
          size="small"
          className="tour-evaluations-table"
        />
      </div>
      {guideEvaluation ? (
        <TourEvaluation
          tourInfo={guideEvaluation}
          facilityEvaluationId={facilityEvaluationId}
          onClose={reload => {
            setGuideEvaluation(undefined);
            setFacilityEvaluationId(undefined);
            if (reload) {
              getData();
            }
          }}
        />
      ) : null}
    </MainContent>
  );
}
