import MainContent from '@/Layout/MainContent';
import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VALIDATE_REQUIRED } from '@/constants';
import { ApiSdk } from '@/httpclient';
import { SmtpSetting } from '@/__generated';

export default () => {
  const [t] = useTranslation();
  const [smtpSetting, setSmtpSetting] = useState<SmtpSetting>();
  const [form] = Form.useForm();

  const getSmtpSetting = () => {
    ApiSdk.SmtpSettingService.smtpSetting()
      .then(response => {
        if (response && response) {
          response.password = '[NoChange]';
          setSmtpSetting(response);
          form.setFieldsValue(response);
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  useEffect(() => {
    getSmtpSetting();
  }, []);

  const updateSmtpSetting = values => {
    ApiSdk.SmtpSettingService.update({ body: values })
      .then(response => {
        if (response?.success) {
          message.success('Update success!');
        } else {
          if (response.message) {
            message.error(t(response.message || ''));
          }
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  return (
    <MainContent title="SMTP設定">
      <div className="ant-layout-content-body" style={{ maxWidth: 800, marginTop: 10 }}>
        <Card>
          <Form form={form} initialValues={smtpSetting || {}} size="middle" layout="vertical" onFinish={updateSmtpSetting}>
            <Form.Item name="id" className="hidden">
              <Input type="hidden" />
            </Form.Item>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label={t('NameSmtp')} name="name" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label={t('EmailAddress')} name="emailAddress" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input type="email" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label={t('Host')} name="host" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label={t('Port')} name="port" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item name="enableSsl" label="" valuePropName="checked">
                  <Checkbox>{t('EnableSsl')}</Checkbox>
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item name="useDefaultCredentials" label="" valuePropName="checked">
                  <Checkbox>{t('UseDefaultCredentials')}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label={t('FullName')} name="userName" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label={t('Password')} name="password" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input.Password />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24}>
                <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
                  {t('staffSearch_save')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </MainContent>
  );
};
