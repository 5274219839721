import { ApiSdk } from '@/httpclient';
import { fileService } from '@/services/fileService';
import { TourGuideAttachment, TourGuideDetailModel } from '@/__generated';
import { DeleteOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  guide?: TourGuideDetailModel | null | undefined;
  onChange?: (v: any) => void;
  value?: any;
};

export default function GuideAttachment({ guide, onChange, value }: Props) {
  const { t } = useTranslation();
  const [files, setFiles] = React.useState<TourGuideAttachment[]>(guide?.attachmentFiles || value || []);
  let ref = React.useRef<HTMLInputElement>();
  const upload = (uploadFiles: FileList | null) => {
    if (uploadFiles && uploadFiles.length) {
      const file = uploadFiles[0];
      const loader = message.loading('Uploading');
      ApiSdk.MediaService.uploadFile({ fileName: file.name, formFile: file })
        .then(res => {
          if (res.success) {
            setFiles(f => [...f, { id: res.fileId, name: res.fileName }]);
          } else {
            message.error(t(res.message || ''));
          }
        })
        .finally(() => {
          if (loader) {
            loader();
          }
        });
    }
    (ref?.current as any)!.value = '';
  };
  React.useEffect(() => {
    if (onChange) {
      onChange(files.map(f => f.id));
    }
  }, [files]);
  return (
    <Row style={{ marginTop: 10, marginBottom: 10, backgroundColor: '#dddddd80', padding: 10, borderRadius: 10 }}>
      <Col md={4} xs={12}>
        <h3 style={{ fontWeight: 'bold', marginBottom: 0, lineHeight: '32px' }}>{t('Attachment')}</h3>
      </Col>
      <Col md={0} xs={12}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            ref.current?.click();
          }}
        >
          {t('Upload Attachment')}
        </Button>
        <input
          style={{ display: 'none' }}
          ref={ref as any}
          type="file"
          onChange={evt => {
            upload(evt.target.files);
          }}
        />
      </Col>
      <Col md={16} xs={24} style={{ borderLeft: '1px solid #ccc', paddingLeft: 10 }}>
        {files.map(file => {
          return (
            <Row key={file.id} style={{ alignItems: 'center', marginBottom: 5 }}>
              <Col md={20} xs={18}>
                {file.name}
              </Col>
              <Col md={4} xs={6}>
                <Space>
                  <Button
                    icon={<DownloadOutlined />}
                    type="default"
                    onClick={() => {
                      fileService.downloadFileById(file.id || '', file.url, file.name);
                    }}
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => {
                      setFiles(f => f.filter(x => x.id !== file.id));
                    }}
                  />
                </Space>
              </Col>
            </Row>
          );
        })}
      </Col>
      <Col md={4} xs={0}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            ref.current?.click();
          }}
        >
          {t('Upload Attachment')}
        </Button>
        <input
          style={{ display: 'none' }}
          ref={ref as any}
          type="file"
          onChange={evt => {
            upload(evt.target.files);
          }}
        />
      </Col>
    </Row>
  );
}
