import GuideInput from '@/components/app/formItem/GuideInput';
import LanguageInput from '@/components/app/formItem/LanguageInput';
import DatePicker from '@/components/DatePicker';
import { BooleanEnumType, TourGuideStatusType } from '@/config';
import { CreateUpdateBusinessDayModel, TourGuideDetailModel, TourGuideStatus } from '@/__generated';
import { DeleteOutlined, EyeInvisibleOutlined, EyeOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Col, Row, Form, Space, Button, Checkbox, FormInstance, Input, Modal } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ICreateTourValidation,
  tourCreationValidation,
  tourFinalValidation,
  tourRequestValidation,
  M3ValidationStatus,
  M2ValidationStatus,
  getBusinessDateDuration,
} from '../validation';
import BusinessDayInfo from './BusinessDayInput';
import { GuideOtherInfo } from './GuideOtherInfo';
import { formItem2Value } from '@/helpers/form';
import GuideStatusInput from './GuideStatusInput';
import { staffHasBall } from '@/services/roleService';
import GuideAttachment from './GuideAttachment';
import SelectGuideModal from '@/pages/GuidesSchedule/SelectGuideModal';
import moment from 'moment';
import { range } from 'lodash';

type Props = {
  fieldData: FormListFieldData;
  onRemove: () => void;
  form: FormInstance;
  tourGuide?: TourGuideDetailModel;
  onGuideStatusChange: () => void;
};
export default function CreateTourGuideInfo({ form, tourGuide, onGuideStatusChange, fieldData, onRemove }: Props) {
  const { t } = useTranslation();
  const [formValidation, setFormValidation] = React.useState<ICreateTourValidation>(tourCreationValidation);
  const [displayGuidePicker, toggleGuidePicker] = React.useState<boolean>(false);
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [canceling, setCanceling] = React.useState<boolean>(false);
  const [displayCanceledDate, setDisplayCanceledDate] = React.useState<boolean>(false);
  const guideRef = React.useRef<{ setValue: (v: any) => void }>();
  React.useEffect(() => {
    if (tourGuide?.status) {
      initFormValidation(tourGuide.status);
      form.validateFields();
    }
  }, [tourGuide]);

  const initFormValidation = (status: TourGuideStatus) => {
    const isFinalized = M3ValidationStatus.includes(status);
    if (isFinalized) {
      setFormValidation(tourFinalValidation);
      return;
    }

    const isRequestingStatus = M2ValidationStatus.includes(status);
    if (isRequestingStatus) {
      setFormValidation(tourRequestValidation);
      return;
    }
    setFormValidation(tourCreationValidation);
  };

  const onStatusChange = (status: TourGuideStatus) => {
    initFormValidation(status);
    onGuideStatusChange();
  };
  const canDelete = React.useMemo(() => {
    return [TourGuideStatusType.Denied, TourGuideStatusType.Cancelled, TourGuideStatusType.Unassigned].includes(
      tourGuide?.status || TourGuideStatusType.Unassigned,
    );
  }, [tourGuide]);

  const removeGuide = () => {
    Modal.confirm({
      title: t('DeleteTourGuideConfirm'),
      okText: t('Delete'),
      cancelText: t('Cancel'),
      onOk: () => {
        onRemove();
      },
    });
  };
  return (
    <div className="guide-info">
      <Row gutter={6}>
        <Col md={8} xs={24}>
          <Row gutter={6}>
            <Col md={4} xs={6} className="form-item-label mobile-row">
              {t('Guide Name')}
            </Col>
            <Col md={17} xs={15}>
              <Form.Item
                noStyle
                shouldUpdate={(prev, current) => {
                  return prev.tourGuides[fieldData.name]?.language?.value !== current.tourGuides[fieldData.name]?.language?.value;
                }}
              >
                {({ getFieldValue }) => {
                  const languageCode = getFieldValue(['tourGuides', fieldData.name, 'language'])?.value;
                  return (
                    <Form.Item rules={[formValidation.guideInfo]} name={[fieldData.name, 'guide']}>
                      <GuideInput
                        actionRef={guideRef}
                        languageCode={languageCode}
                        className="input-value-color"
                        disabled={tourGuide?.isRequested}
                        placeholder={t('Guide Name')}
                        idValue={tourGuide?.id}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col md={3} xs={3} style={{ textAlign: 'right' }}>
              <Button
                icon={<SearchOutlined />}
                disabled={tourGuide?.isRequested}
                onClick={() => {
                  toggleGuidePicker(true);
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col md={6} xs={24}>
          <Row gutter={6}>
            <Col md={8} xs={6} className="form-item-label mobile-row">
              {t('Status')}
            </Col>
            <Col md={16} xs={18}>
              <Form.Item name={[fieldData.name, 'status']} rules={[formValidation.guideInfo]}>
                <GuideStatusInput
                  prevStatus={tourGuide?.status}
                  className="input-value-color"
                  placeholder={t('Status')}
                  onChange={v => {
                    setCanceling(false);
                    setDeleting(false);
                    onStatusChange(v);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={10} xs={24} style={{ textAlign: 'right' }}>
          <Row gutter={6}>
            <Col md={4} xs={6} className="form-item-label mobile-row">
              {t('Duration')}
            </Col>
            <Col md={18} xs={18}>
              <Form.Item
                noStyle
                shouldUpdate={(prev, current) => {
                  const prevGuideData = prev.tourGuides[fieldData.key];
                  const currentGuideData = current.tourGuides[fieldData.key];
                  return prevGuideData?.status !== currentGuideData?.status || prevGuideData?.businessDays !== currentGuideData?.businessDays;
                }}
              >
                {({ getFieldValue }) => {
                  const businessDays: CreateUpdateBusinessDayModel[] = getFieldValue(['tourGuides', fieldData.name, 'businessDays']);
                  const { fromDate, toDate } = getBusinessDateDuration(businessDays);
                  return (
                    <Space>
                      <DatePicker className="w-100 input-value-color" disabled value={fromDate?.date as any} />
                      <DatePicker className="w-100 input-value-color" disabled value={toDate?.date as any} />
                    </Space>
                  );
                }}
              </Form.Item>
            </Col>
            <Col md={2} xs={0} style={{ textAlign: 'right' }}>
              <Button disabled={!canDelete} icon={<DeleteOutlined />} danger onClick={removeGuide} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={6}>
        <Col md={8} xs={24}>
          <Row gutter={6}>
            <Col md={4} xs={6} className="form-item-label mobile-row">
              {t('Language')}
            </Col>
            <Col md={20} xs={18}>
              <Form.Item
                noStyle
                shouldUpdate={(prev, current) => {
                  return prev.tourGuides[fieldData.name]?.guide?.value !== current.tourGuides[fieldData.name]?.guide?.value;
                }}
              >
                {({ getFieldValue }) => {
                  const guideId: string | undefined = getFieldValue(['tourGuides', fieldData.name, 'guide'])?.value;
                  return (
                    <Form.Item
                      className="input-value-color"
                      rules={[formValidation.guideInfo]}
                      labelCol={{ style: { width: 100 } }}
                      name={[fieldData.name, 'language']}
                    >
                      <LanguageInput guideId={guideId} disabled={tourGuide?.isRequested} placeholder={t('Language')} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Form.List name={[fieldData.name, 'businessDays']}>
          {(fields, { add, remove }) => {
            const canAddDate = staffHasBall(tourGuide?.status || 1);
            const canDeleteDate = staffHasBall(tourGuide?.status || 1);
            const canCancelDate = staffHasBall(tourGuide?.status || 1);

            return (
              <>
                <Col md={6} xs={0} style={{ textAlign: 'right' }}>
                  <Button
                    disabled={!!fields.length && !canCancelDate}
                    icon={canceling ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    type={'default'}
                    danger
                    onClick={() => {
                      setCanceling(d => !d);
                      setDeleting(false);
                    }}
                  >
                    {canceling ? t('Hide cancel date') : t('Cancel Date')}
                  </Button>
                </Col>
                <Col md={5} xs={0} style={{ textAlign: 'end', paddingRight: 3 }}>
                  <Form.Item name={[fieldData.name, 'id']} noStyle>
                    <Input type="hidden" />
                  </Form.Item>
                  <Space>
                    <Button
                      disabled={!canAddDate}
                      onClick={() =>
                        add({
                          availability: BooleanEnumType.True,
                          stayOvernight: BooleanEnumType.False,
                          stayLastNight: BooleanEnumType.False,
                          fee: 0,
                        })
                      }
                      icon={<PlusOutlined />}
                      type="primary"
                    >
                      {t('Add Date')}
                    </Button>
                    <Button
                      disabled={!!fields.length && !canDeleteDate}
                      icon={<DeleteOutlined />}
                      danger={!deleting}
                      type={deleting ? 'dashed' : 'default'}
                      onClick={() => {
                        setDeleting(d => !d);
                        setCanceling(false);
                      }}
                    >
                      {deleting ? t('Hide delete date') : t('Delete Date')}
                    </Button>
                  </Space>
                </Col>
                <Col md={5} xs={24} style={{ textAlign: 'right', lineHeight: '32px' }}>
                  <Checkbox checked={displayCanceledDate} onChange={() => setDisplayCanceledDate(c => !c)}>
                    {t('Display cancelled dates')}
                  </Checkbox>
                </Col>
                <Col md={24} xs={24}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, current) => {
                      return prev.tourGuides !== current.tourGuides;
                    }}
                  >
                    {({ getFieldValue }) => {
                      const tourGuides: any[] = getFieldValue('tourGuides') || [];
                      const guide = getFieldValue(['tourGuides', fieldData.name, 'guide']);
                      const language = getFieldValue(['tourGuides', fieldData.name, 'language']);
                      if (guide?.value && language?.value) {
                        const isSameGuideLanguage =
                          tourGuides.filter(
                            x =>
                              formItem2Value(x.guide?.value) === formItem2Value(guide?.value) &&
                              formItem2Value(x.language?.value) === formItem2Value(language.value),
                          )?.length > 1;

                        return isSameGuideLanguage ? (
                          <label style={{ marginBottom: 5 }} className="error-message">
                            {t('GuideAndLanguageExistsOtherTab', {
                              name: guide.label,
                              language: language.label,
                            })}
                          </label>
                        ) : null;
                      }
                      return null;
                    }}
                  </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                  <BusinessDayInfo
                    add={add}
                    tourGuide={tourGuide}
                    formValidation={formValidation}
                    remove={remove}
                    form={form}
                    guideField={fieldData}
                    fieldsData={fields}
                    deleting={deleting}
                    canceling={canceling}
                    onDelete={remove}
                    displayCanceledDate={displayCanceledDate}
                  />
                </Col>
              </>
            );
          }}
        </Form.List>
      </Row>
      <Form.Item noStyle name={[fieldData.name, 'attachments']}>
        <GuideAttachment guide={tourGuide} />
      </Form.Item>

      {displayGuidePicker && (
        <SelectGuideModal
          onClose={(guide: any) => {
            toggleGuidePicker(false);
            if (guide) {
              guideRef.current?.setValue(guide);
            }
          }}
          visible={true}
          onlyActiveGuide={true}
        />
      )}
    </div>
  );
}
