import * as React from 'react';
import { Button, Checkbox, Col, Form, Input, InputNumber, Row, Select, Space } from 'antd';
import { ColSize } from 'antd/es/grid/col';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import DatePicker from '../DatePicker';
import { useTranslation } from 'react-i18next';
import NTADateTimePicker from '../DateTimePicker';

declare type ColSpanType = number | string;

export interface ITableFilterItem {
  name: string;
  type: 'NUMBER' | 'TEXT' | 'QUERY' | 'LIST' | 'DATE' | 'DATETIME' | 'SELECT' | 'CHECKBOX';
  label: string;
  mdSize?: ColSpanType | ColSize;
  xlSize?: ColSpanType | ColSize;
  xxlSize?: ColSpanType | ColSize;
  lgSize?: ColSpanType | ColSize;
  listData?: Array<{ value: string | number; label: string }>;
  defaultValue?: any;
  parseValue?: (v) => any;
  customElm?: React.ReactElement;
  enablePreInputSelect?: boolean;
  placeHolder?: string;
  checkboxInline?: boolean;
  multiple?: boolean;
}

interface IZFilterProps {
  onSearch: (query: any) => void;
  filters: Array<ITableFilterItem>;
  extraBtns?: any;
  layout: any;
  hideResetButton?: boolean;
}

const ZFilter: React.FunctionComponent<IZFilterProps> = ({ onSearch, filters, extraBtns, layout, hideResetButton }) => {
  let filterObj = {};
  const { t, i18n } = useTranslation();
  const createInputSelectBefore = (name, cData) => (
    <Select
      defaultValue={cData[name]}
      className="select-before"
      onChange={v => {
        cData[name] = v;
      }}
    >
      <Select.Option value="contains">{t('Contains')}</Select.Option>
      <Select.Option value="ends_with">{t('Ends with')}</Select.Option>
      <Select.Option value="starts_with">{t('Starts with')}</Select.Option>
    </Select>
  );
  const [searchForm] = Form.useForm();
  let cData = {};
  const _search = values => {
    let condition = {};
    Object.keys(values).forEach(k => {
      const parseData =
        filterObj[k] ||
        (v => {
          return v;
        });
      if (values[k] !== null && values[k] !== undefined && values[k] !== '') {
        if (cData[k]) {
          condition[`${k}_${cData[k]}`] = parseData(values[k]);
        } else {
          condition[k] = parseData(values[k]);
        }
      }
    });
    onSearch(condition);
  };

  const initialValues = {};
  filters.forEach(f => {
    initialValues[f.name] = f.defaultValue;
  });
  React.useEffect(() => {
    console.log(i18n.language);
  }, [i18n.language]);

  const clearFormValue = () => {
    const values = searchForm.getFieldsValue(true);
    const newValue = {};
    Object.keys(values).map(k => {
      newValue[k] = undefined;
    });
    searchForm.setFieldsValue(newValue);
  };
  return (
    <Form
      colon={false}
      form={searchForm}
      layout={layout}
      initialValues={initialValues}
      onFinish={_search}
      style={{ overflow: 'hidden', paddingTop: '10px' }}
      className="search-form"
    >
      <Row gutter={16}>
        {filters.map(
          (
            {
              name,
              type,
              label,
              placeHolder,
              checkboxInline,
              lgSize,
              xlSize,
              xxlSize,
              mdSize,
              listData,
              parseValue,
              customElm,
              enablePreInputSelect,
              multiple,
            },
            i,
          ) => {
            filterObj[name] = parseValue;
            const size = {
              xs: 24,
              xxl: xxlSize || 4,
              lg: lgSize || 6,
              xl: xlSize || 4,
              md: mdSize || 6,
            };
            label = t(label);
            placeHolder = placeHolder ? t(placeHolder) : label;
            switch (type) {
              case 'CHECKBOX':
                return (
                  <Col {...size} key={i}>
                    {label ? (
                      <Form.Item name={name} label={checkboxInline ? (layout === 'vertical' ? '   ' : '') : label} valuePropName="checked">
                        <Checkbox>{checkboxInline ? t(label) : ''}</Checkbox>
                      </Form.Item>
                    ) : (
                      <Form.Item name={name} valuePropName="checked">
                        <Checkbox>{checkboxInline ? t(label) : ''}</Checkbox>
                      </Form.Item>
                    )}
                  </Col>
                );
              case 'SELECT':
                return (
                  <Col {...size} key={i}>
                    <Form.Item name={name} label={label} key={i18n.language}>
                      {customElm}
                    </Form.Item>
                  </Col>
                );
              case 'LIST':
                return (
                  <Col {...size} key={i}>
                    <Form.Item name={name} label={label}>
                      <Select maxTagCount="responsive" placeholder={placeHolder} allowClear mode={multiple ? 'multiple' : undefined}>
                        {listData?.map((l, index) => {
                          return (
                            <Select.Option key={index} value={l.value}>
                              {t(l.label)}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                );
              case 'QUERY':
                return (
                  <Col {...size} key={i}>
                    <Form.Item name={name} label={label}>
                      <Input placeholder={placeHolder} />
                    </Form.Item>
                  </Col>
                );
              case 'DATE':
                return (
                  <Col {...size} key={i}>
                    <Form.Item name={name} label={label}>
                      <DatePicker className="w-100" placeholder={placeHolder} />
                    </Form.Item>
                  </Col>
                );
              case 'DATETIME':
                return (
                  <Col {...size} key={i}>
                    <Form.Item name={name} label={label}>
                      <NTADateTimePicker className="w-100" placeholder={placeHolder} />
                    </Form.Item>
                  </Col>
                );
              case 'NUMBER':
                return (
                  <Col {...size} key={i}>
                    <Form.Item name={name} label={label}>
                      <InputNumber placeholder={placeHolder} className="w-100" />
                    </Form.Item>
                  </Col>
                );

              default:
                if (enablePreInputSelect) {
                  cData[name] = 'contains';
                  return (
                    <Col {...size} key={i}>
                      <Form.Item name={name} label={label}>
                        <Input addonBefore={createInputSelectBefore(name, cData)} placeholder={placeHolder} />
                      </Form.Item>
                    </Col>
                  );
                }

                return (
                  <Col {...size} key={i}>
                    <Form.Item name={name} label={label}>
                      <Input placeholder={placeHolder} />
                    </Form.Item>
                  </Col>
                );
            }
          },
        )}
        <Col flex={'auto'} style={{ textAlign: 'end' }}>
          {layout === 'vertical' ? (
            <Form.Item label="&nbsp;">
              <Space>
                <Button type="primary" htmlType="submit" style={{ backgroundColor: '#5f63f2' }}>
                  <SearchOutlined style={{ color: '#333' }} />
                  {t('zTable.search')}
                </Button>
                <Button
                  type="ghost"
                  onClick={() => {
                    clearFormValue();
                  }}
                >
                  <CloseOutlined />
                  {t('zTable.reset')}
                </Button>
              </Space>
            </Form.Item>
          ) : (
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" style={{ backgroundColor: '#5f63f2' }}>
                  <SearchOutlined style={{ color: '#333' }} />
                  {t('zTable.search')}
                </Button>
                {!hideResetButton && (
                  <Button
                    type="ghost"
                    onClick={() => {
                      clearFormValue();
                    }}
                  >
                    <CloseOutlined />
                    {t('zTable.reset')}
                  </Button>
                )}
              </Space>
            </Form.Item>
          )}
        </Col>
        {extraBtns && (
          <Col style={{ flex: 1, textAlign: 'right' }}>
            <Form.Item label="&nbsp;">
              <Space>{extraBtns}</Space>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default ZFilter;
