import { ApiSdk } from '@/httpclient';
import { AutoComplete, AutoCompleteProps, FormInstance } from 'antd';
import { debounce } from 'lodash';
import React from 'react';

interface Props extends AutoCompleteProps {
  form: FormInstance<any>;
  codeField: string;
  nameField: string;

  category?: number | null;
}

export default function FacilityInput({
  form,
  className,
  value: originValue,
  category,

  nameField,
  codeField,
  disabled,
  ...props
}: Props) {
  const [value, setValue] = React.useState<string>('');
  const [data, setData] = React.useState<{ label: string; value: string }[]>([]);
  const [isTextValue, setIsTextValue] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (category) {
      getData();
    }
  }, [value, category]);

  React.useEffect(() => {
    if (!disabled) {
      setValue('');
      form.setFieldsValue({
        [nameField]: undefined,
        [codeField]: undefined,
      });
      setIsTextValue(false);
    }
  }, [category]);

  const _getData = () => {
    ApiSdk.FacilityService.getFacilitiesAutocompletion({
      name: value,
      category: category as any,
      orderBy: 'category',
    }).then(res => {
      if (res) {
        setData(res?.map(fa => ({ label: fa.name || '', value: fa.code || '' })) || []);
      } else {
        setData([]);
      }
    });
  };

  const getData = debounce(_getData, 300);

  const fieldClassName = React.useMemo(() => {
    if (isTextValue) {
      return `input-value-color nta-autocomplete is-text-value ${className || ''}`;
    }
    return ` input-value-color nta-autocomplete ${className || ''}`;
  }, [className, isTextValue]);
  return (
    <AutoComplete
      disabled={disabled}
      allowClear={true}
      onSearch={v => {
        setValue(v);
        setIsTextValue(true);
        form.setFieldsValue({
          [nameField]: v,
          [codeField]: undefined,
        });
      }}
      onSelect={(v, op) => {
        setValue(op.label as any);
        setIsTextValue(false);

        form.setFieldsValue({
          [nameField]: op.label,
          [codeField]: v,
        });
      }}
      {...props}
      options={data}
      value={value}
      className={fieldClassName}
    />
  );
}
