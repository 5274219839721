import { PasswordRegex } from '@/config';
import { ApiSdk } from '@/httpclient';
import { useTranslation } from 'react-i18next';
import { Form, Input, message, Modal } from 'antd';
import React, { ReactElement } from 'react';
import { SaveOutlined } from '@ant-design/icons';

interface Props {
  visible: boolean;
  onClose: () => void;
}

export default function ChangePassword({ visible, onClose }: Props): ReactElement {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const handleOk = () => {
    form.validateFields().then(v => {
      ApiSdk.UserService.changePassword({
        body: { oldPassword: v.oldpassword, password: v.password },
      }).then(res => {
        if (res.success) {
          message.success(t('ChangePasswordSuccess'));
          onClose();
        } else {
          message.error(t(res.message || ''));
        }
      });
    });
  };
  return (
    <Modal
      width={400}
      title={t('Change password')}
      visible={visible}
      onOk={handleOk}
      onCancel={() => {
        onClose();
      }}
      okText={t('staffSearch_save')}
      okButtonProps={{ icon: <SaveOutlined />, className: 'save-btn' }}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="oldpassword" label={t('CurrentPassword')} rules={[{ required: true, message: t('PasswordValidation') }]}>
          <Input.Password className="w-100" />
        </Form.Item>

        <Form.Item
          name="password"
          label={t('NewPassword')}
          rules={[
            { required: true, message: t('staffSearch_passwordValidation') },
            { pattern: new RegExp(PasswordRegex), message: t('staffSearch_passwordValidation') },
          ]}
        >
          <Input.Password className="w-100" />
        </Form.Item>
        <Form.Item
          name="repassword"
          label={t('ConfirmPassword')}
          rules={[
            { required: true, message: t('staffSearch_passwordValidation') },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(t('staffPasswordNotMatch'));
              },
            }),
          ]}
        >
          <Input.Password className="w-100" />
        </Form.Item>
        <div style={{ color: '#E91E63' }}>※入力間違いを防ぐため、コピー、貼り付けはせずに入力してください。</div>
        <div style={{ marginTop: 10 }}>
          <b>***パスワード設定ルール***</b>
          <div>&bull;&nbsp; 8 文字以上</div>
          <div>&bull;&nbsp; 数字、英小文字、英大文字、およびスペース以外の記号を全て使用する</div>
          <div>&bull;&nbsp;メールアドレスを含まない</div>
        </div>
      </Form>
    </Modal>
  );
}
