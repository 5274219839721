import React, { useState, useContext } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Card, Form, Input, message } from 'antd';
import { AppContext, createAppUser } from '../../contexts/AppContext';
import '../Auth/styles.less';
import { useHistory } from 'react-router';
import moment from 'moment';
import { ApiSdk } from '@/httpclient';
import { Link } from 'react-router-dom';
import { getUrlQueryValue } from '@/hooks/useQuery';
import authService from '@/services/authService';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
const bgImage = require('@/assets/images/appbg.jpeg');
const guidelinkLogo = require('@/assets/guidelink/loginlogo.png');

export default () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [t] = useTranslation();
  const context = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();
  const callback = getUrlQueryValue('callback');
  const login = (values: any) => {
    if (executeRecaptcha) {
      executeRecaptcha('login')
        .then(token => {
          setLoading(true);
          ApiSdk.AuthService.login({ body: { ...values, recaptchaToken: token } })
            .then(response => {
              setLoading(false);
              if (response && response.success && response.currentUser) {
                const expiredTime = moment(new Date()).add(response.expiryDuration, 'minutes');
                authService.saveLoginInfo({
                  token: response.accessToken || '',
                  expiredTime,
                  userId: response.currentUser.id || '',
                });
                authService.logUserInfo2Sentry(response.currentUser.id, response.currentUser.email, response.currentUser.username);
                authService.saveRefreshToken(response.refreshToken || '');
                context.onUpdateContext({
                  auth: createAppUser({
                    ...response.currentUser,
                    isPasswordExpired: response.isPasswordExpired,
                  } as any),
                  authLoaded: true,
                });
                if (!response.isPasswordExpired) {
                  history.push(callback || '/cms');
                }
              } else {
                if (response.message) {
                  setError(t(response.message));
                }
              }
            })
            .catch(error => {
              message.error(error);
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch();
    }
  };

  return (
    <div
      className="container-login-form"
      style={{ minHeight: '100vh', backgroundImage: `url(${bgImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
    >
      <Card className="login-form">
        <Form
          layout="vertical"
          name="normal_login"
          size="large"
          initialValues={{ remember: true }}
          onFinish={values => {
            login(values);
          }}
        >
          {error ? (
            <Alert
              message={error}
              type="warning"
              closable
              style={{ marginBottom: 10 }}
              onClose={() => {
                setError('');
              }}
            />
          ) : null}
          <div style={{ textAlign: 'center', marginBottom: 10 }}>
            <img src={guidelinkLogo} style={{ width: '90%' }} />
          </div>
          <Form.Item label={t('UserNameOrEmail')} name="username" rules={[{ required: true, message: t('UserNameValidation') }]}>
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t('UserNameOrEmail')} style={{ alignItems: 'center' }} />
          </Form.Item>
          <Form.Item label={t('Password')} name="password" rules={[{ required: true, message: t('PasswordValidation') }]}>
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder={t('Password')}
              style={{ alignItems: 'center' }}
              className="center"
            />
          </Form.Item>
          <div style={{ textAlign: 'end', marginBottom: 20 }}>
            <Link to={'/cms/forgot-password'} style={{ color: '#5F63F2' }}>
              {t('ForgotPassword')}
            </Link>
          </div>

          <div style={{ textAlign: 'center' }}>
            <Button loading={loading} type="primary" disabled={!executeRecaptcha} htmlType="submit" className="login-form-button">
              {t('LoginButton')}
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};
