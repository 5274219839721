import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface Iprops {
  loading?: boolean;
  oppacity?: number;
}

export default ({ loading, oppacity = 0.7 }: Iprops) => {
  return (
    <div className={`full-loading-wraper ${loading ? 'active' : ''}`} style={{ opacity: oppacity }}>
      <div className="loading-background"> </div>
      <div className="spin">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
      </div>
    </div>
  );
};
