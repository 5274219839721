import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space, Spin, Table, message } from 'antd';
import React from 'react';
import {
  SaveOutlined,
  PlusOutlined,
  DeleteOutlined,
  DownloadOutlined,
  CheckOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ERROR_EXCEPTION, GuideSeisanStatus, VALIDATE_REQUIRED } from '@/constants';
import { ColumnsType } from 'antd/lib/table';
import BooleanTag from '@/components/app/BooleanTag';
import { SEISAN_REGEX, formatDisplayFullDate, scrollableModalBodyStyle } from '@/config';
import { ApiSdk } from '@/httpclient';
import { GuideSeisanDetailModel, GuideSeisanFileModel } from '@/__generated';
import guideSeisanService from '@/services/guideSeisanService';
import tourSeisanAction from './action';
import NTADatePicker from '@/components/DatePicker';
import lockService from '@/services/lockService';
import TourLockCpn from '@/components/app/TourLockCpn';
import { sumBy } from 'lodash';
import SeisanFileSubmittedTag from '@/components/app/SeisanFileSubmittedTag';
import { formatCurrency } from '@/helpers/form';
type Props = {
  onClose: (reload?: boolean) => void;
  tourGuideId: string;
};
const requiredRule = {
  required: true,
  message: VALIDATE_REQUIRED,
};
const keepAliveTimeout = 5 * 60 * 1000; //5 minutes
let keepAliveInterval: any = null;
export default function GuideUpdateSeisan({ onClose, tourGuideId }: Props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [hasFileChange, setHasFileChange] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(true);
  const [guideSeisan, setGuideSeisan] = React.useState<GuideSeisanDetailModel>();
  const [guideSeisanFiles, setGuideSeisanFiles] = React.useState<GuideSeisanFileModel[]>([]);
  const fileInputRef = React.useRef<HTMLInputElement>();
  const [seisanStatus, setSeisanStatus] = React.useState<number>();
  const [sessionId, setSessionId] = React.useState<string>();

  const guideSeisanDetails = () => {
    if (sessionId && tourGuideId) {
      ApiSdk.GuideSeisanService.getGuideSeisanForUpdate({ tourGuideId: tourGuideId, sessionId })
        .then(res => {
          if (res) {
            setGuideSeisan(res);
            setSeisanStatus(res.status);
          } else {
            Modal.error({ title: t('tourDetail_TourSeisanNotExist') });
            onClose();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const getGuideSeisanFiles = () => {
    ApiSdk.GuideSeisanService.getGuideSeisanFiles({ tourGuideId: tourGuideId }).then(res => {
      setGuideSeisanFiles(res);
    });
  };
  React.useEffect(() => {
    if (sessionId && tourGuideId) {
      guideSeisanDetails();
      getGuideSeisanFiles();
      setHasFileChange(false);
    }
  }, [tourGuideId, sessionId]);

  React.useEffect(() => {
    if (sessionId && guideSeisan && guideSeisan.isLocked === false) {
      keepAliveInterval = window.setInterval(() => {
        lockService.keepAlive(guideSeisan.id || '', sessionId);
      }, keepAliveTimeout);
      const onUnlock = () => {
        lockService.unlock(guideSeisan.id || '', sessionId);
      };
      const unlockOnCloseTab = () => {
        lockService.unlockOnTabClose(guideSeisan.id || '', sessionId);
      };
      const onTabActiveCallback = () => {
        if (!document.hidden) {
          lockService.checkLockSessionAlive(guideSeisan.id || '', sessionId);
        }
      };
      const onBrowserTabClose = lockService.onBrowserTabClose(unlockOnCloseTab);
      const onTabReActive = lockService.onTabReActive(onTabActiveCallback);
      return () => {
        onUnlock();
        onBrowserTabClose();
        onTabReActive();
        if (keepAliveInterval) {
          window.clearInterval(keepAliveInterval);
        }
      };
    }
    return () => {};
  }, [sessionId, guideSeisan]);

  React.useEffect(() => {
    form.setFieldsValue({ ...guideSeisan });
  }, [guideSeisan]);

  React.useEffect(() => {
    if (tourGuideId) {
      setSessionId(lockService.createSessionId());
    } else {
    }
  }, [tourGuideId]);

  const tableCols: ColumnsType<any> = React.useMemo(() => {
    let canDeleteFile = guideSeisanService.guideCanDeleteFile(guideSeisan?.status || 0);
    let cols = [
      { title: t('tourDetail_TourSeisanFileName'), dataIndex: 'fileName', ellipsis: true, width: 250 },
      { title: t('tourDetail_TourSeisanUploadedBy'), dataIndex: 'createdBy', width: 130 },
      { title: t('tourDetail_TourSeisanUploadedTime'), dataIndex: 'createdAt', width: 170, render: v => formatDisplayFullDate(v) },
      {
        title: t('guideSeisan_IsSubmitted'),
        dataIndex: 'submittedStatus',
        width: 100,
        align: 'center',
        render: v => <SeisanFileSubmittedTag status={v} />,
      },
      {
        title: t('tourDetail_TourSeisanDownload'),
        dataIndex: 'id',
        width: 130,
        align: 'center',
        render: (id, { fileName }: any) => (
          <Button
            onClick={() => {
              guideSeisanService.downloadSeisanFile(id, fileName);
            }}
            size="small"
            icon={<DownloadOutlined />}
          />
        ),
      },
    ];
    if (canDeleteFile) {
      cols.push({
        title: t('tourDetail_TourSeisanDelete'),
        width: 80,
        align: 'center',
        dataIndex: 'id',
        render: v => (
          <Button
            disabled={!!guideSeisan?.isLocked && canDeleteFile}
            onClick={() => {
              onDeleteFile(v);
            }}
            danger
            size="small"
            icon={<DeleteOutlined />}
          />
        ),
      });
    }
    return cols as any;
  }, [guideSeisan]);

  const onClick2Upload = () => {
    fileInputRef.current?.click();
  };
  const showError = (error: any) => {
    message.error(t(error || ERROR_EXCEPTION));
  };
  const onChangeFile = async (e: { currentTarget: HTMLInputElement }) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      const file = e.currentTarget.files[0];
      setLoading(true);
      ApiSdk.GuideSeisanService.uploadSeisanFile({
        file: file,
        fileName: file.name,
        seisanId: guideSeisan?.id || '',
      })
        .then(() => {
          getGuideSeisanFiles();
          setHasFileChange(true);
        })
        .catch(err => {
          message.error(err as any);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    e.currentTarget.value = '';
  };

  const onDeleteFile = (fileId: string) => {
    Modal.confirm({
      title: t('CONFIRM_DELETE_FILE'),
      icon: <ExclamationCircleOutlined />,
      okText: t('Delete'),
      cancelText: t('Cancel'),
      onOk: () => {
        setLoading(true);
        ApiSdk.GuideSeisanService.deleteSeisanFile({ id: fileId })
          .finally(() => {
            setLoading(false);
          })
          .then(res => {
            if (res.success) {
              getGuideSeisanFiles();
              setHasFileChange(true);
            } else {
              showError(res.message);
            }
          });
      },
    });
  };

  const guideSaveSeisan = () => {
    form.validateFields().then(value => {
      setLoading(true);
      tourSeisanAction.guideSaveSeisan({ ...value, id: guideSeisan?.id, sessionId }, setLoading, () => {
        onClose(true);
      });
    });
  };
  const guideSubmitSeisan = () => {
    form.validateFields().then(value => {
      let hasChange = hasFileChange || (guideSeisan?.guideRemark?.trim() || '') !== (value?.guideRemark?.trim() || '');
      if (hasChange) {
        setLoading(true);
        tourSeisanAction.guideSubmitSeisan({ ...value, id: guideSeisan?.id, sessionId }, setLoading, () => {
          onClose(true);
        });
      } else {
        Modal.confirm({
          title: t('tourDetail_TourSeisanGuideSubmitNoChangeMessage'),
          okText: t('tourDetail_TourSeisanYes'),
          cancelText: t('Cancel'),
          onOk: () => {
            setLoading(true);
            tourSeisanAction.guideSubmitSeisan({ ...value, id: guideSeisan?.id }, setLoading, () => {
              onClose(true);
            });
          },
        });
      }
    });
  };
  const guideCompleteRefund = () => {
    form.validateFields().then(value => {
      setLoading(true);
      tourSeisanAction.guideCompleteRefund({ ...value, id: guideSeisan?.id, sessionId }, setLoading, () => {
        onClose(true);
      });
    });
  };

  const seisanValidation = React.useMemo(() => {
    if ((guideSeisan as any)?.seisanNumber) {
      return [];
    }
    let seisanRule = { pattern: new RegExp(SEISAN_REGEX), message: t('SeisanNumberValidate') };
    return [GuideSeisanStatus.NoFilesUploaded, GuideSeisanStatus.NoNeedSeisan].includes(seisanStatus || GuideSeisanStatus.NoFilesUploaded)
      ? [seisanRule]
      : [requiredRule, seisanRule];
  }, [seisanStatus, guideSeisan]);

  const canUploadFile = React.useMemo(() => {
    return guideSeisanService.guideCanUploadFile(guideSeisan?.status || 0);
  }, [guideSeisan]);
  const allowGuideSubmitSeisan = React.useMemo(() => {
    return guideSeisanService.guideCanSubmitSeisan(guideSeisan?.tourGuideStatus || 0, guideSeisan?.status || 0);
  }, [guideSeisan]);
  const allowRefundSeisan = React.useMemo(() => {
    return guideSeisanService.guideCanRefund(guideSeisan);
  }, [guideSeisan]);

  return (
    <Modal
      className="nta-custom-form"
      bodyStyle={scrollableModalBodyStyle}
      visible={true}
      onCancel={() => onClose()}
      style={{ top: 10, maxWidth: 1200 }}
      width={'100%'}
      title="ガイド精算"
      footer={
        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => onClose()}>{t('Close')}</Button>

          {allowGuideSubmitSeisan ? (
            <Button
              onClick={guideSubmitSeisan}
              disabled={guideSeisan?.isLocked}
              style={{ backgroundColor: '#FF957B', color: 'white' }}
              loading={loading}
              icon={<CheckOutlined />}
            >
              {t('tourDetail_TourSeisanAmountSubmit')}
            </Button>
          ) : null}
          {allowRefundSeisan ? (
            <Button disabled={guideSeisan?.isLocked} onClick={guideCompleteRefund} type="primary" loading={loading} icon={<ReloadOutlined />}>
              {t('tourDetail_TourSeisanRefund')}
            </Button>
          ) : null}
          <Button
            type="primary"
            disabled={guideSeisan?.isLocked}
            onClick={guideSaveSeisan}
            className="save-btn"
            loading={loading}
            icon={<SaveOutlined />}
          >
            {t('下書き保存')}
          </Button>
        </div>
      }
      maskClosable={false}
    >
      <Spin spinning={loading}>
        <TourLockCpn isLocked={guideSeisan?.isLocked} lockedBy={guideSeisan?.lockedBy}>
          <Form layout="horizontal" form={form} colon={false} style={{ marginRight: 40 }}>
            <Row gutter={6}>
              <Col md={12} xs={24}>
                <Form.Item labelCol={{ md: 8 }} labelAlign="right" label={t('tourDetail_GuideSeisanStatus')} name="status" rules={[requiredRule]}>
                  <Select
                    options={GuideSeisanStatus.__getSelectOptions()}
                    disabled={true}
                    onChange={v => setSeisanStatus(v as any)}
                    className="input-value-color"
                  />
                </Form.Item>
                <Form.Item labelCol={{ md: 8 }} labelAlign="right" label={t('tourMessagePage_TourName')} name="tourName">
                  <Input disabled className="input-value-color" />
                </Form.Item>

                <Form.Item
                  labelCol={{ md: 8 }}
                  labelAlign="right"
                  label={t('tourDetail_TourNoTourSeisan')}
                  name="seisanNumber"
                  rules={seisanValidation}
                  className="input-value-color"
                >
                  <Input maxLength={9} disabled={!!guideSeisan?.seisanNumber || guideSeisan?.isLocked} className="input-value-color" />
                </Form.Item>

                <Form.Item
                  labelCol={{ md: 8 }}
                  labelAlign="right"
                  label={t('tourDetail_TourSeisanAmountCarrying')}
                  name="amountCarrying"
                  rules={[requiredRule]}
                >
                  <InputNumber min={0} disabled={!!guideSeisan?.isLocked} className="input-value-color w-100" />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item labelAlign="left" labelCol={{ md: 7 }} label={t('tourDetail_TourSeisanGuideRemark')} name="guideRemark">
                  <Input.TextArea rows={5} disabled={!!guideSeisan?.isLocked} className="input-value-color w-100" />
                </Form.Item>
              </Col>

              <Col md={24} style={{ marginBottom: 20 }}>
                <Row gutter={6}>
                  <Col md={4} style={{ textAlign: 'right', paddingRight: 5 }}>
                    {t('tourDetail_TourSeisanAttachment')}
                  </Col>
                  <Col md={20}>
                    {canUploadFile ? (
                      <div style={{ marginBottom: 5 }}>
                        <Button size="small" type="primary" disabled={!!guideSeisan?.isLocked} icon={<PlusOutlined />} onClick={onClick2Upload}>
                          {t('tourDetail_TourSeisanUpload')}
                        </Button>
                      </div>
                    ) : null}

                    <Table
                      scroll={{ x: sumBy(tableCols, x => (x.width as any) || 0) }}
                      size="small"
                      pagination={false}
                      bordered
                      columns={tableCols}
                      dataSource={guideSeisanFiles}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col md={24} xs={24}>
                <Form.Item labelCol={{ md: 4 }} labelAlign="right" label={t('tourDetail_TourSeisanNTARemark')} name="ntaRemark">
                  <Input.TextArea disabled className="input-value-color w-100" placeholder="" rows={5} />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  labelCol={{ md: 12 }}
                  labelAlign="right"
                  label={t('tourDetail_TourSeisanPaymentTaxWithoutAmount')}
                  name="taxWithheldAmount"
                >
                  <InputNumber disabled className="input-value-color w-100" formatter={v => formatCurrency(v)} />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item labelCol={{ md: 12 }} labelAlign="right" label={t('tourDetail_TourSeisanPaymentPaymentCost')} name="paymentCost">
                  <InputNumber disabled className="input-value-color w-100" formatter={v => formatCurrency(v)} />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item labelCol={{ md: 12 }} labelAlign="right" label={t('tourDetail_TourSeisanPaymentDueDate')} name="paymentDueDate">
                  <NTADatePicker disabled className="input-value-color w-100" placeholder="" />
                </Form.Item>
              </Col>
            </Row>
            <input
              multiple
              style={{ display: 'none' }}
              ref={fileInputRef as any}
              type="file"
              name="attachmentFile"
              onChange={evt => {
                onChangeFile(evt);
              }}
            />
          </Form>
        </TourLockCpn>
      </Spin>
    </Modal>
  );
}
