import { SupportedLanguageLicenseType, GuideSupportedType, GuideSupportedArea, formatDateWithoutSecond } from '@/config';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTION } from '@/constants';
import { ApiSdk } from '@/httpclient';
import MainContent from '@/Layout/MainContent';
import { GuidePagingResultModel, LanguageModel } from '@/__generated';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Select, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import lodash from 'lodash';
import React, { useCallback, useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import GuideNameCell from '../GuidesSchedule/components/GuideNameCell';
import SearchForm from './components/SearchForm';
import CreateGuideForm from './create';
import UpdateGuideForm from './update';
const { Text } = Typography;

const PAGE_TITLE = 'guideSearch_title';
const pageSize = 20;
type Props = {};

export default function GuidesProfile({}: Props) {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const history = useHistory();

  const [total, setTotal] = useState<number>(0);
  const [listGuide, setListGuide] = useState<GuidePagingResultModel[]>([]);
  const [displayForm, setDisplayForm] = React.useState<boolean>(false);
  const [guideId, setGuideId] = React.useState<string | undefined>(undefined);
  const [languages, setLanguages] = useState<LanguageModel[]>([]);
  const [filters, setFilters] = useState<any>({
    hasLicense: true,
    orderBy: 'GuideName',
    orderByAsc: true,
    pageIndex: 1,
    pageSize,
    isEnabled: true,
  });
  React.useEffect(() => {
    const query = new URLSearchParams(search);
    const _guideId = query.get('guideId');
    if (_guideId) {
      setGuideId(_guideId);
      setDisplayForm(true);
      history.replace('/cms/guides-profile');
    }
  }, []);
  const getGuideData = () => {
    setLoading(true);
    ApiSdk.GuideService.search({ ...filters })
      .then(res => {
        setListGuide(res.data || []);
        setTotal(res.total || 0);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    ApiSdk.LanguageService.getLanguages({ pageSize: 250, orderBy: 'order', orderByAsc: true }).then(res => {
      setLanguages(res.data || []);
    });
  }, []);
  React.useEffect(() => {
    getGuideData();
  }, [filters]);

  const onTableChange = useCallback(
    (pagination, _, sorter) => {
      let { field, order } = sorter;
      let p = pagination?.current || 1;
      let order_by_asc = order ? (order === 'ascend' ? true : false) : true;
      setFilters({
        ...filters,
        orderBy: order ? field : 'GuideName',
        orderByAsc: order_by_asc,
        pageIndex: p,
        pageSize: pagination.pageSize,
      });
    },
    [filters],
  );

  const updateFilters = newFilters => {
    setFilters({ ...filters, ...newFilters });
  };

  const getExtraButtons = () => [
    <Button
      key="create"
      type="primary"
      className="nta-create-btn"
      icon={<PlusCircleOutlined />}
      onClick={() => {
        setDisplayForm(true);
      }}
    >
      {t('guideSearch_new')}
    </Button>,
  ];
  const { columns, W } = React.useMemo(() => {
    const _columns: ColumnsType<GuidePagingResultModel> = [
      {
        title: 'No.',
        width: 40,
        align: 'center',
        fixed: 'left',
        render: (v, r, i) => {
          return <b>{i + 1}</b>;
        },
      },
      {
        title: t('guideSearch_guideName'),
        dataIndex: 'guideName',
        width: 280,
        fixed: 'left',
        render: (text, guide) => {
          return (
            <GuideNameCell
              gender={guide.gender}
              id={guide.id}
              maxNumberOfNight={guide.maxNumberOfNights}
              name={guide.guideName || ''}
              dateOfBirth={guide.birthDate}
              onGuideNameClick={() => {
                setGuideId(guide.id);
                setDisplayForm(true);
              }}
              rank={guide.rank}
            />
          );
        },
      },
      {
        title: t('guideSearch_prefecture'),
        dataIndex: 'prefecture',
        width: 130,
        render: value => {
          return t(value);
        },
      },
      {
        title: t('guideSearch_language'),
        dataIndex: 'languages',
        width: 200,
        render: (v, guide) => {
          const languages = lodash.orderBy(guide.languages || [], (x, i) => (x.isMain ? -1 : i));
          const text = languages
            ?.map(lang => {
              const name = `${lang.isMain ? '*' : ''}${lang.languageName}`;
              if (lang.license === SupportedLanguageLicenseType.None) {
                return `<div style="color:red">${name}</div>`;
              } else {
                return `<div >${name}</div>`;
              }
            })
            .join('');
          return (
            <Text style={{ width: 180 }} ellipsis={{ tooltip: <span dangerouslySetInnerHTML={{ __html: text || '' }} /> }}>
              {languages.map((lang, i) => {
                let name = `${lang.isMain ? '*' : ''}${lang.languageName}`;
                if (i !== (languages.length || 0) - 1) {
                  name += ', ';
                }
                return (
                  <Text key={i} style={{ color: lang.license === SupportedLanguageLicenseType.None ? 'red' : '' }}>
                    {name}
                  </Text>
                );
              })}
            </Text>
          );
        },
      },
      {
        title: t('guideSearch_type'),
        dataIndex: 'supportedTypes',
        width: 200,
        render: text => {
          if (text?.length) {
            const typesText = text?.map(area => t(GuideSupportedType.__getValue(area))).join(', ');
            return <Text ellipsis={{ tooltip: typesText }}>{typesText}</Text>;
          }
          return '';
        },
      },
      {
        title: t('guideSearch_area'),
        dataIndex: 'supportedAreas',
        width: 200,
        render: text => {
          if (text?.length) {
            const areaText = text?.map(area => t(GuideSupportedArea.__getValue(area))).join(', ');
            return <Text ellipsis={{ tooltip: areaText }}>{areaText}</Text>;
          }
          return '';
        },
      },
      {
        title: t('guideSearch_membershipOfProfessionalInstitution'),
        dataIndex: 'professionalInstitutions',
        width: 100,

        ellipsis: true,
        render: text => {
          if (text?.length) {
            const typesText = text.join(', ');
            return <Tooltip title={typesText}>{typesText}</Tooltip>;
          }
          return '';
        },
      },
      {
        title: t('guideSearch_guideRating'),
        dataIndex: 'ratings',
        width: 80,
        align: 'center',
        render: dataStarList => {
          const sumStar: number = dataStarList.reduce((previousValue, currentValue) => previousValue + currentValue.rating, 0);
          return <b>{dataStarList.length > 0 ? parseFloat((sumStar / dataStarList.length).toString()).toFixed(2) : ''}</b>;
        },
      },
      {
        title: t('guideSearch_lastUpdatedDate'),
        dataIndex: 'updatedAt',
        width: 150,
        ellipsis: true,
        render: text => <span>{formatDateWithoutSecond(text)}</span>,
      },
      { title: t('guideSearch_lastUpdatedBy'), ellipsis: true, dataIndex: 'updatedBy', width: 100 },
      {
        title: t('zTable.actions'),
        dataIndex: '_actions',
        width: 120,
        align: 'center',
        render(_: any, record: any) {
          return (
            <Tooltip title={t('zTable.edit')}>
              <Button
                icon={<EditOutlined />}
                style={{ backgroundColor: '#d6eaf8', borderRadius: 0 }}
                type="ghost"
                size="small"
                onClick={() => {
                  //   edit
                  setGuideId(record.id);
                  setDisplayForm(true);
                }}
              ></Button>
            </Tooltip>
          );
        },
      },
    ];
    return {
      columns: _columns,
      W: lodash.sumBy(_columns, t => t.width as any),
    };
  }, []);
  return (
    <MainContent title={t(PAGE_TITLE)} extraBtns={getExtraButtons()}>
      <Helmet title={t(PAGE_TITLE)} />
      <div className="ant-layout-content-body">
        <SearchForm filters={filters} updateFilters={updateFilters} />

        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
          <div>{`${t('zTable.records')} ${total}`}</div>
          <div style={{ marginLeft: 20 }}>
            {t('zTable.pageSize')}
            <Select
              value={filters.pageSize}
              style={{ width: 90 }}
              onChange={v => {
                setFilters(q => ({ ...q, pageSize: parseInt((v as any) || filters.pageSize || DEFAULT_PAGE_SIZE) }));
              }}
            >
              {PAGE_SIZE_OPTION.map(page => (
                <Select.Option key={page} value={page}>
                  {page}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>

        <Table
          loading={loading}
          columns={columns}
          dataSource={listGuide}
          rowKey="id"
          showSorterTooltip={false}
          pagination={{
            hideOnSinglePage: true,
            current: filters.pageIndex,
            pageSize: filters.pageSize,
            total,
            showSizeChanger: true,
            pageSizeOptions: PAGE_SIZE_OPTION,
          }}
          onChange={onTableChange}
          bordered
          size="small"
          scroll={{ x: W }}
          rowClassName={record => (!record.isEnabled ? 'disabled-row' : '')}
        />
      </div>
      {displayForm ? (
        guideId ? (
          <UpdateGuideForm
            languages={languages}
            onClose={reload => {
              if (reload) {
                getGuideData();
              }
              setDisplayForm(false);
              if (guideId) {
                setGuideId(undefined);
              }
            }}
            guideId={guideId}
          />
        ) : (
          <CreateGuideForm
            languages={languages}
            onClose={reload => {
              if (reload) {
                getGuideData();
              }
              setDisplayForm(false);
              if (guideId) {
                setGuideId(undefined);
              }
            }}
          />
        )
      ) : null}
    </MainContent>
  );
}
