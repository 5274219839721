import { fileService } from '@/services/fileService';
import { TourGuideDetailModel } from '@/__generated';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  guide: TourGuideDetailModel;
};

export default function GuideAttachment({ guide }: Props) {
  const { t } = useTranslation();

  return (
    <Row style={{ marginTop: 20, marginBottom: 20 }}>
      <Col md={4} xs={24}>
        <h3 style={{ fontWeight: 'bold', marginBottom: 0, lineHeight: '32px' }}>{t('Attachment')}</h3>
      </Col>
      <Col md={20} xs={24} style={{ borderLeft: '1px solid #ccc', paddingLeft: 10 }}>
        {guide?.attachmentFiles?.map(file => {
          return (
            <Row key={file.id} style={{ alignItems: 'center', marginBottom: 5 }}>
              <Col md={21} xs={21}>
                {file.name}
              </Col>
              <Col md={3} xs={3}>
                <Button
                  icon={<DownloadOutlined />}
                  type="default"
                  onClick={() => {
                    fileService.downloadFileById(file.id || '', file.url, file.name);
                  }}
                />
              </Col>
            </Row>
          );
        })}
      </Col>
    </Row>
  );
}
