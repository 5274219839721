import React from 'react';
import { Badge, Popover, Typography } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { AtbdTopDropdwon } from './UserInfoStyle';
import { ApiSdk } from '@/httpclient';
import { NotificationDtoPagingResponse } from '@/__generated';
import { NotificationOutlined } from '@ant-design/icons';
import { NotificationStatus } from '@/config';
import NotificationInfoModal from '@/pages/Notifications/NotificationInfoModal';
import { useLocation } from 'react-router';
const rtl = false;
const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: '#F1F2F6',
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const renderTrackVertical = () => {
  const thumbStyle = {
    position: 'absolute',
    width: '6px',
    transition: 'opacity 200ms ease 0s',
    opacity: 0,
    [rtl ? 'left' : 'right']: '2px',
    bottom: '2px',
    top: '2px',
    borderRadius: '3px',
  };
  return <div className="hello" style={thumbStyle as any} />;
};

const renderView = ({ style, ...props }) => {
  const customStyle = {
    marginRight: rtl && 'auto',
    [rtl ? 'marginLeft' : 'marginRight']: '-17px',
  };
  return <div {...props} style={{ ...style, ...customStyle }} />;
};
const NotificationBox = () => {
  const [t] = useTranslation();
  const [showMenu, toggleMenu] = React.useState<boolean>(false);
  const { search } = useLocation();

  const [notiId, setNotiId] = React.useState<string>();

  React.useEffect(() => {
    console.log(search);
    const query = new URLSearchParams(search);
    const notificationId = query.get('displayNotificationId');
    if (notificationId) {
      setNotiId(notificationId);
    }
  }, [search]);

  const [notifications, setNotifications] = React.useState<NotificationDtoPagingResponse>({
    total: 0,
    data: [],
  });

  React.useEffect(() => {
    ApiSdk.NotificationService.search({ status: [NotificationStatus.During as any], pageSize: 10, orderBy: 'updatedAt', orderByAsc: false })
      .then(res => {
        setNotifications(res);
      })
      .catch(() => {
        setNotifications({
          total: 0,
          data: [],
        });
      });
  }, []);

  const content = (
    <AtbdTopDropdwon className="atbd-top-dropdwon">
      <h5 className="atbd-top-dropdwon__title">
        <span className="title-text">{t('menu.Notifications')}</span>
        <Badge className="badge-success" count={notifications.total} showZero />
      </h5>
      <Scrollbars
        autoHeightMax={500}
        autoHeight
        autoHide
        renderThumbVertical={renderThumb}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
      >
        <ul
          onClick={() => {
            toggleMenu(false);
          }}
          className="atbd-top-dropdwon__nav notification-list"
        >
          {notifications.data?.map(noti => {
            return (
              <li key={noti.id}>
                <a
                  href={`/cms/notifications?id=${noti.id}`}
                  onClick={e => {
                    e.preventDefault();
                    setNotiId(noti.id);
                  }}
                >
                  <div className="atbd-top-dropdwon__content notifications">
                    <div className="notification-icon bg-primary" style={{ minWidth: 40 }}>
                      <NotificationOutlined />
                    </div>
                    <div className="notification-content d-flex">
                      <div className="notification-text" style={{ marginRight: 20 }}>
                        <h3 style={{ color: '#5F63F2', marginBottom: 0, fontWeight: 'bold' }}>{noti.subject}</h3>
                        <Typography.Paragraph ellipsis={{ rows: 3, symbol: '...' }} style={{ marginBottom: 5 }}>
                          {noti.contents}
                        </Typography.Paragraph>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
      </Scrollbars>
    </AtbdTopDropdwon>
  );

  return (
    <div style={{ display: 'contents' }}>
      <Popover onVisibleChange={v => toggleMenu(v)} trigger="click" visible={showMenu} placement="bottomLeft" content={content}>
        <Badge count={notifications.total} showZero offset={[-8, -5]}>
          <div
            onClick={() => {
              toggleMenu(true);
            }}
            className="head-example"
          >
            <FeatherIcon icon="bell" size={20} />
          </div>
        </Badge>
      </Popover>
      <NotificationInfoModal
        visible={!!notiId}
        onClose={() => {
          setNotiId('');
        }}
        notificationId={notiId}
      />
    </div>
  );
};

export default NotificationBox;
