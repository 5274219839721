import { FlagOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Modal, Row, Select } from 'antd';
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { ApiSdk } from '@/httpclient';
import ZTable, { IFilterItem, ITableRef, IColumnType } from '@/components/ZTable';
import { Continent, VALIDATE_REQUIRED } from '@/constants';
import MainContent from '@/Layout/MainContent';
import { CountryModel } from '@/__generated';
import { Trans, useTranslation } from 'react-i18next';
const PAGE_TITLE = '国';

const columns: IColumnType<CountryModel>[] = [
  {
    width: 50,
    fixed: 'left',
    title: 'No.',
    dataIndex: 'createdAt',
    render: (v, t, i) => <b>{`${i + 1}`}</b>,
    align: 'center',
  },
  {
    title: '国名',
    dataIndex: 'name',
    sorter: true,
    defaultSortOrder: 'ascend',
  },
  {
    title: '3レター',
    dataIndex: 'code',
    sorter: true,
  },
  {
    title: '2レター',
    dataIndex: 'shortCode',
    sorter: true,
  },
  {
    title: '国番号',
    dataIndex: 'phoneCode',
    sorter: true,
  },
  {
    title: 'エリア',
    dataIndex: 'continent',
    sorter: true,
    render: v => <Trans>{Continent[v]}</Trans>,
  },
];

const filters: Array<IFilterItem> = [
  { type: 'TEXT', name: 'name', label: '国名' },
  { type: 'TEXT', name: 'code', label: '3レター' },
  { type: 'TEXT', name: 'shortCode', label: '2レター' },
  {
    type: 'LIST',
    name: 'continent',
    label: 'エリア	',
    listData: Object.keys(Continent).map(k => ({
      value: k,
      label: Continent[k],
    })),
  },
];

export default () => {
  const [t] = useTranslation();
  const zTb = React.useRef<ITableRef>();
  const [form] = Form.useForm();
  const [formVisible, setFormVisible] = useState(false);
  const [isNewForm, setIsNewForm] = useState(false);

  const createCountry = values => {
    ApiSdk.CountryService.update({ body: values })
      .then(response => {
        if (response?.success) {
          setFormVisible(false);
          zTb.current?.reload();
        } else {
          if (response.message) {
            message.error(t(response.message || ''));
          }
        }
      })
      .catch(error => {
        message.error(error);
      });
  };
  const updateCountry = values => {
    ApiSdk.CountryService.update({ body: values })
      .then(response => {
        if (response?.success) {
          setFormVisible(false);
          zTb.current?.reload();
        } else {
          if (response.message) {
            message.error(t(response.message || ''));
          }
        }
      })
      .catch(error => {
        message.error(error);
      });
  };

  return (
    <MainContent
      title={PAGE_TITLE}
      extraBtns={[
        <Button
          key="create"
          type="primary"
          className="nta-create-btn"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            form.resetFields();
            setIsNewForm(true);
            setFormVisible(true);
          }}
        >
          {t('guideSearch_new')}
        </Button>,
      ]}
      icon={<FlagOutlined style={{ fontSize: 16 }} />}
    >
      <Helmet title={PAGE_TITLE} />
      <div className="ant-layout-content-body">
        <ZTable
          columns={columns}
          primaryKey="id"
          tableRef={zTb}
          url="/countries/get-countries"
          bordered
          order_by="Name"
          order_by_asc={true}
          allowEdit={true}
          onEdit={record => {
            setIsNewForm(false);
            setFormVisible(true);
            form.setFieldsValue({
              ...record,
              continent: `${record.continent}`,
            });
          }}
          allowDelete={true}
          delete_url="/countries/delete"
          filters={filters}
        ></ZTable>
        <Modal
          visible={formVisible}
          maskClosable={false}
          width={600}
          className="nta-custom-form"
          title={isNewForm ? t('CreateCountry') : t('UpdateCountry')}
          forceRender={true}
          okText={t('staffSearch_save')}
          okButtonProps={{ icon: <SaveOutlined />, className: 'save-btn' }}
          onCancel={() => {
            form.resetFields();
            setFormVisible(false);
          }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                if (values.id) {
                  updateCountry(values);
                } else {
                  createCountry(values);
                }
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Form form={form} size="middle" layout="vertical">
            <Form.Item name="id" className="hidden">
              <Input type="hidden" />
            </Form.Item>
            <Row gutter={6}>
              <Col md={24}>
                <Form.Item label={'国名'} name="name" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col md={12}>
                <Form.Item
                  label={'3レター'}
                  name="code"
                  rules={[
                    { required: true, message: VALIDATE_REQUIRED },
                    { min: 3, message: t('ValidateCountry3Code') },
                    { max: 3, message: t('ValidateCountry3Code') },
                  ]}
                >
                  <Input disabled={!isNewForm} className="input-value-color" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={'2レター'}
                  name="shortCode"
                  rules={[
                    { required: true, message: VALIDATE_REQUIRED },
                    { min: 2, message: t('ValidateCountry2Code') },
                    { max: 2, message: t('ValidateCountry2Code') },
                  ]}
                >
                  <Input disabled={!isNewForm} className="input-value-color" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col md={12}>
                <Form.Item label={t('PhoneCode')} name="phoneCode" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label={t('Continent')} name="continent" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Select>
                    {Object.keys(Continent).map(k => {
                      return (
                        <Select.Option value={`${k}`} key={k}>
                          <Trans>{Continent[k]}</Trans>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </MainContent>
  );
};
