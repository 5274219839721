import authService from '@/services/authService';
import { getDefaultSelectedRole } from '@/services/roleService';
import { getTheme, saveTheme } from '@/services/themeService';
import { CurrentUserModel } from '@/__generated';
import React, { Component, useContext } from 'react';
import { ThemeProvider } from 'styled-components';

type ITheme = { topMenu: boolean; rtl: boolean; darkMode: boolean };
export interface IAppUser {
  /**  */
  id?: string;

  /**  */
  username?: string;

  /**  */
  email?: string;

  /**  */
  fullname?: string;

  originRoleClaims: string[];

  /**  */
  roleClaims: string[];

  staffId?: string;

  viewPermission?: string;

  departmentId?: string;

  subDivisionId?: string;

  isPasswordExpired?: boolean;
}
export const createAppUser = (originUser: CurrentUserModel): IAppUser => {
  return {
    ...originUser,
    originRoleClaims: originUser.roleClaims || [],
    roleClaims: [getDefaultSelectedRole(originUser.roleClaims)],
  };
};
export interface AppContextInterface {
  theme: ITheme;
  authLoaded: boolean;
  auth: IAppUser | null;
  onUpdateContext: (...params: any) => void;
  onResetContext: (...params: any) => void;
  onUpdateTheme: (_theme: Partial<ITheme>) => void;
  logout: () => void;
}

export const AppContext = React.createContext<AppContextInterface>({
  theme: getTheme() || { topMenu: false, rtl: false, darkMode: false },
  authLoaded: false,
  auth: null,
  onUpdateContext: context => context,
  onResetContext: context => context,
  onUpdateTheme: v => {},
  logout: () => {},
});

export const useAppContext = () => {
  return useContext<AppContextInterface>(AppContext);
};
export class AppProvider extends Component {
  onUpdateContext = (context: any) => {
    const newContext = { ...this.state, ...context };
    this.setState(newContext);
  };

  onResetContext = () => {
    this.setState({
      authLoaded: true,
      auth: null,
    });
  };
  logout = () => {
    authService.clearOnLogout();
    this.setState({
      authLoaded: true,
      auth: null,
    });
  };
  onUpdateTheme = (_theme: Partial<ITheme>) => {
    const { theme } = this.state;
    const newTheme = {
      ...theme,
      ..._theme,
    };
    saveTheme(newTheme);
    this.setState({
      theme: newTheme,
    });
  };
  state = {
    theme: getTheme() || { topMenu: false, rtl: false, darkMode: false },
    authLoaded: false,
    auth: null,
    onUpdateContext: this.onUpdateContext,
    onResetContext: this.onResetContext,
    onUpdateTheme: this.onUpdateTheme,
    logout: this.logout,
  };

  render() {
    return (
      <AppContext.Provider value={{ ...this.state }}>
        <ThemeProvider theme={this.state.theme}>{this.props.children}</ThemeProvider>
      </AppContext.Provider>
    );
  }
}

export default AppProvider;
