export default {
  notification_Title:"Notification management",
  notification_Search_From:"From",
  notification_Search_To:"To",
  notification_Search_Status:"Status",
  notification_Search_Subject:"Subject",
  notification_Search_Contents:"Contents",
  notification_Action_Search:"Search",
  notification_Action_Create:"Create",
  notification_Action_Copy:"Copy",
  notification_Action_Edit:"Edit",
  notification_Action_Save:"Save",
  notification_Action_Cancel:"Cancel",
  notification_Action_Delete:"Delete",
  notification_Search_Search_Results:"Search Results",
  notification_Search_Displayed_Results:"Displayed Results",
  notification_Result_No:"No.",
  notification_Result_Status:"Status",
  notification_Result_Post_Period:"Post Period",
  notification_Result_Subject:"Subject",
  notification_Result_Contents:"Contents",
  notification_Result_Created_Date_And_Time:"Created Date And Time",
  notification_Result_Created_By:"Created By",
  notification_Result_Last_Updated_Date:"Last Updated Date",
  notification_Result_Last_Updated_By:"Last Updated By",
  notification_attachment_upload:"Attachment",
  notification_attachment_delete:"Delete",
  notification_update_date:"Updated date and time",
  notification_update_by:"Updated by",
  notification_date_from_to_error:"The start date of the listing must be before the end date.",
  notification_required_error:"Please fill in the required field.",
  notification_mail_send_confirm:"Would you like to send an email to the guide?"
};
