import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CmsLayout from './CmsLayout';
import UNLayout from './UNLayout';

export default function RenderRoutes() {
  return (
    <Switch>
      <Route path="/un" component={UNLayout} />
      <Route path="/cms" component={CmsLayout} />
      <Redirect from="*" to="/cms" />
    </Switch>
  );
}
