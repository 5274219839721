import EnumSelect from '@/components/app/formItem/EnumSelect';
import TourLockCpn from '@/components/app/TourLockCpn';
import ZTable, { IColumnType, ITableRef } from '@/components/ZTable';
import { FacilityCategoryType, formatDisplayDate, formatDisplayFullDate, TourAvailableAreaType } from '@/config';
import { ApiSdk } from '@/httpclient';
import lockService from '@/services/lockService';
import { Facility, FacilityEvaluation, TourEvaluationDetailModel } from '@/__generated';
import {
  ArrowsAltOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  ShrinkOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Button, Col, Form, Input, message, Modal, Rate, Row, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FacilityReviewModal from './FacilityReviewModal';
import RatingInput from '@/components/app/formItem/RatingInput';
const { Text } = Typography;

export interface ShortTourGuideInfo {
  guideId: string;
}
type Props = {
  tourInfo: ShortTourGuideInfo;
  facilityEvaluationId?: string;
  onClose: (reload?: boolean) => void;
};
const keepAliveTimeout = 5 * 60 * 1000; //5 minutes
let keepAliveInterval: any = null;

const requiredRule = {
  required: true,
  message: <Trans>VALIDATE_REQUIRED</Trans>,
};

export default function TourEvaluation({ onClose, tourInfo, facilityEvaluationId }: Props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [hasFieldChange, setHasFieldChange] = React.useState(false);

  const zTb = React.useRef<ITableRef>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [guideEvaluation, setGuideEvaluation] = React.useState<TourEvaluationDetailModel>();
  const [displayInputFacility, setDisplayInputFacility] = React.useState<boolean>(false);
  const [editingFacilityEvaluationId, setEditingFacilityEvaluationId] = React.useState<string>();
  const [sessionId, setSessionId] = React.useState<string>();
  const [isDisplayAllField, setDisplayAllField] = React.useState(true);
  React.useEffect(() => {
    if (tourInfo.guideId && sessionId) {
      setLoading(true);
      ApiSdk.TourEvaluationService.detailByGuide({
        tourGuideId: tourInfo.guideId,
        sessionId,
      })
        .then(res => {
          setGuideEvaluation(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [tourInfo, sessionId]);

  React.useEffect(() => {
    if (facilityEvaluationId) {
      setEditingFacilityEvaluationId(facilityEvaluationId);
      setDisplayInputFacility(true);
    } else {
      setEditingFacilityEvaluationId(undefined);
      setDisplayInputFacility(false);
    }
  }, [facilityEvaluationId]);

  React.useEffect(() => {
    if (tourInfo) {
      setSessionId(lockService.createSessionId());
    }
  }, [tourInfo]);

  React.useEffect(() => {
    if (sessionId && guideEvaluation && guideEvaluation.isLocked === false) {
      keepAliveInterval = window.setInterval(() => {
        lockService.keepAlive(guideEvaluation.id || '', sessionId);
      }, keepAliveTimeout);
      const onUnlock = () => {
        lockService.unlock(guideEvaluation.id || '', sessionId);
      };
      const unlockOnCloseTab = () => {
        lockService.unlockOnTabClose(guideEvaluation.id || '', sessionId);
      };
      const onTabActiveCallback = () => {
        if (!document.hidden) {
          lockService.checkLockSessionAlive(guideEvaluation.id || '', sessionId);
        }
      };
      const onBrowserTabClose = lockService.onBrowserTabClose(unlockOnCloseTab);
      const onTabReActive = lockService.onTabReActive(onTabActiveCallback);
      return () => {
        onUnlock();
        onBrowserTabClose();
        onTabReActive();
        if (keepAliveInterval) {
          window.clearInterval(keepAliveInterval);
        }
      };
    }
    return () => {};
  }, [sessionId, guideEvaluation]);

  React.useEffect(() => {
    form.setFieldsValue(guideEvaluation?.tourEvaluationInfo || {});
  }, [guideEvaluation]);

  const onSubmit = () => {
    form
      .validateFields()
      .then(v => {
        setLoading(true);
        setHasFieldChange(false);
        ApiSdk.TourEvaluationService.update({
          body: {
            ...v,
            tourGuideId: tourInfo.guideId,
            sessionId,
          },
        })
          .then(res => {
            if (res.success) {
              message.success(t('UpdateSuccess'));
            } else {
              message.error(t(res.message || ''));
            }
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(err => {
        if (err?.errorFields.length) {
          console.log(err);
          const fieldsName = err.errorFields[0].name;
          form.scrollToField(fieldsName, { behavior: 'smooth' });
        }
      });
  };

  const deleteRecord = (id: string) => {
    Modal.confirm({
      okText: t('Delete'),
      cancelText: t('Cancel'),
      title: t('Are you sure want to delete this item?'),
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        ApiSdk.FacilityEvaluationService.delete({ id: id }).then(res => {
          if (res.success) {
            zTb.current?.reload();
          } else {
            message.error(t(res.message || ''));
          }
        });
      },
    });
  };
  const columns: IColumnType<FacilityEvaluation>[] = React.useMemo(() => {
    const cols: IColumnType<FacilityEvaluation>[] = [
      {
        width: 50,
        fixed: 'left',
        title: 'No.',
        dataIndex: 'createdAt',
        render: (v, t, i) => <b>{`${i + 1}`}</b>,
        align: 'center',
        sorter: true,
      },
      {
        title: 'facilityReviewDate',
        dataIndex: 'useDate',
        render: v => formatDisplayDate(v),
        width: 140,
        sorter: true,
      },
      {
        title: 'facilityReviewRate',
        dataIndex: 'comprehensiveRate',
        render: v => <Rate value={v} allowHalf={true} disabled style={{ fontSize: 12, color: '#666' }} />,
        width: 140,
        sorter: true,
      },
      {
        title: 'facilityReviewType',
        dataIndex: 'category',
        render: v => t(FacilityCategoryType.__getValue(v)),
        width: 160,
        sorter: true,
      },
      {
        title: 'facilityReviewFacilityName',
        dataIndex: 'facilityName',
        ellipsis: true,
        render: v => (
          <Text style={{ width: 180 }} ellipsis={{ tooltip: v }}>
            {v}
          </Text>
        ),
      },
      {
        title: 'facilityReviewDetail',
        dataIndex: 'details',
        ellipsis: true,
        render: v => (
          <Text style={{ width: 180 }} ellipsis={{ tooltip: v }}>
            {v}
          </Text>
        ),
      },
    ];
    if (window.innerWidth > 700) {
      return cols;
    }
    return [
      {
        title: '詳細',
        dataIndex: '',
        align: 'center',

        render: (v: FacilityEvaluation) => {
          return (
            <div
              onClick={() => {
                setEditingFacilityEvaluationId(v.id);
                setDisplayInputFacility(true);
              }}
              style={{ position: 'relative' }}
            >
              <Button
                icon={<DeleteOutlined />}
                size="small"
                style={{ backgroundColor: '#f97d81', borderRadius: 0, position: 'absolute', top: 5, right: 5 }}
                type="ghost"
                onClick={e => {
                  e.stopPropagation();
                  deleteRecord(v.id || '');
                }}
              ></Button>

              <div style={{ display: 'flex' }}>
                {formatDisplayDate(v.useDate)}
                <Rate value={v.comprehensiveRate} allowHalf={true} disabled style={{ fontSize: 12, color: '#333', marginLeft: 10 }} />
              </div>
              <div style={{ display: 'flex' }}>{`${v.category ? t(FacilityCategoryType.__getValue(v.category)) : ''} - ${v.facilityName}`}</div>
              <div style={{ display: 'flex' }}>{v.details}</div>
            </div>
          );
        },
      },
    ];
  }, []);

  const check2Close = () => {
    if (hasFieldChange) {
      Modal.confirm({
        title: '保存されていない項目があります。本当に閉じますか？',
        okText: t('Yes'),
        cancelText: t('No'),
        onOk: () => {
          onClose();
        },
      });
    } else {
      onClose();
    }
  };
  return (
    <Modal
      className="nta-custom-form create-tour-form"
      visible={true}
      onCancel={check2Close}
      style={{ top: 10, maxWidth: 900 }}
      width={'100%'}
      title={t('guideEvaluationModalTitle')}
      footer={() => null}
      maskClosable={false}
    >
      <Form
        layout="vertical"
        form={form}
        scrollToFirstError
        onValuesChange={() => {
          setHasFieldChange(true);
        }}
      >
        <Row gutter={6} style={{ marginBottom: 10 }}>
          {guideEvaluation?.lastUpdatedBy ? (
            <>
              <Col md={12} xs={24} style={{ textAlign: 'center', marginBottom: 10 }}>
                <span>
                  {t('guideSearch_lastUpdatedBy')} : <b>{guideEvaluation?.lastUpdatedBy}</b>
                </span>
              </Col>
              <Col md={12} xs={24} style={{ textAlign: 'center', marginBottom: 10 }}>
                <span style={{ marginLeft: 40 }}>
                  {t('staffSearch_lastUpdatedDate')} : <b>{formatDisplayFullDate(guideEvaluation?.lastUpdatedAt)}</b>
                </span>
              </Col>
            </>
          ) : null}

          <Col md={12} xs={24}>
            <Form.Item label={t('Tour Name')}>
              <Input className="input-value-color" value={guideEvaluation?.tourName} disabled />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label={t('Agent')}>
              <Input className="input-value-color" value={guideEvaluation?.agentName} disabled />
            </Form.Item>
          </Col>
          <Col md={7} xs={12}>
            <Form.Item label={t('guideSerchnationality')}>
              <Input className="input-value-color" value={guideEvaluation?.nationality} disabled />
            </Form.Item>
          </Col>
          <Col md={5} xs={12}>
            <Form.Item label={t('tourArea')}>
              <EnumSelect className="input-value-color" enumData={TourAvailableAreaType} value={guideEvaluation?.area} disabled />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label={t('Guide Name')}>
              <Input className="input-value-color" value={guideEvaluation?.guideName} disabled />
            </Form.Item>
          </Col>
        </Row>

        <div style={{ backgroundColor: '#EDF9FF', padding: 10 }}>
          <TourLockCpn isLocked={guideEvaluation?.isLocked} lockedBy={guideEvaluation?.lockedBy}>
            <>
              <div style={{ textAlign: 'right' }}>
                <Button onClick={onSubmit} loading={loading} icon={<SaveOutlined />} className="save-btn" disabled={guideEvaluation?.isLocked}>
                  {t('Save')}
                </Button>
              </div>
              <Row gutter={4}>
                <Col md={6} xs={24}>
                  <Form.Item label={<b> {t('tourEvaluation_comprehensiveRate')}</b>} name="comprehensiveRate" rules={[requiredRule]}>
                    <RatingInput disabled={guideEvaluation?.isLocked} className="input-value-color" />
                  </Form.Item>
                </Col>
                <Col md={18} xs={24}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <b>{t('tourEvaluation_tourImpression')}</b>
                    <Button
                      type="link"
                      icon={
                        isDisplayAllField ? (
                          <ShrinkOutlined style={{ fontSize: 18, color: '#4176d9' }} />
                        ) : (
                          <ArrowsAltOutlined style={{ fontSize: 18, color: '#4176d9' }} />
                        )
                      }
                      onClick={() => setDisplayAllField(x => !x)}
                    />
                  </div>
                  <Form.Item name="tourImpression">
                    <TextArea autoSize={{ minRows: 3 }} className="input-value-color" disabled={guideEvaluation?.isLocked} />
                  </Form.Item>
                </Col>
              </Row>

              {isDisplayAllField ? (
                <>
                  <Form.Item name="customerReaction" label={<b>{t('tourEvaluation_customerReaction')}</b>}>
                    <TextArea autoSize={{ minRows: 3 }} className="input-value-color" disabled={guideEvaluation?.isLocked} />
                  </Form.Item>
                  <Row gutter={4}>
                    <Col md={8} xs={24}>
                      <Form.Item label={<b>{t('tourEvaluation_tourLeaderName')}</b>} name="tourLeaderName">
                        <TextArea autoSize={{ minRows: 3 }} className="input-value-color" disabled={guideEvaluation?.isLocked} />
                      </Form.Item>
                    </Col>
                    <Col md={16} xs={24}>
                      <Form.Item label={<b>{t('tourEvaluation_tourLeaderEvaluation')}</b>} name="tourLeaderEvaluation">
                        <TextArea autoSize={{ minRows: 3 }} className="input-value-color" disabled={guideEvaluation?.isLocked} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item label={<b>{t('tourEvaluation_serviceGoodPoints')}</b>} name="serviceGoodPoints">
                    <TextArea autoSize={{ minRows: 3 }} className="input-value-color" disabled={guideEvaluation?.isLocked} />
                  </Form.Item>
                  <Form.Item label={<b>{t('tourEvaluation_serviceImprovement')}</b>} name="serviceImprovement">
                    <TextArea autoSize={{ minRows: 3 }} className="input-value-color" disabled={guideEvaluation?.isLocked} />
                  </Form.Item>
                  <Row gutter={4}>
                    <Col md={6} xs={24}>
                      <Form.Item label={<b> {t('tourEvaluation_accommodationComprehensiveRate')}</b>} name="accommodationComprehensiveRate">
                        <RatingInput disabled={guideEvaluation?.isLocked} className="input-value-color" />
                      </Form.Item>
                    </Col>
                    <Col md={18} xs={24}>
                      <Form.Item
                        label={<b>{t('tourEvaluation_accommodationComprehensiveEvaluation')}</b>}
                        name="accommodationComprehensiveEvaluation"
                      >
                        <TextArea autoSize={{ minRows: 3 }} className="input-value-color" disabled={guideEvaluation?.isLocked} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={4}>
                    <Col md={6} xs={24}>
                      <Form.Item label={<b> {t('tourEvaluation_restaurantComprehensiveRate')}</b>} name="restaurantComprehensiveRate">
                        <RatingInput disabled={guideEvaluation?.isLocked} className="input-value-color" />
                      </Form.Item>
                    </Col>
                    <Col md={18} xs={24}>
                      <Form.Item label={<b>{t('tourEvaluation_restaurantComprehensiveEvaluation')}</b>} name="restaurantComprehensiveEvaluation">
                        <TextArea autoSize={{ minRows: 3 }} className="input-value-color" disabled={guideEvaluation?.isLocked} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item label={<b>{t('tourEvaluation_otherComments')}</b>} name="otherComments">
                    <TextArea autoSize={{ minRows: 3 }} className="input-value-color" disabled={guideEvaluation?.isLocked} />
                  </Form.Item>
                </>
              ) : null}
            </>
          </TourLockCpn>
          <div style={{ textAlign: 'right' }}>
            <Button onClick={onSubmit} loading={loading} icon={<SaveOutlined />} className="save-btn" disabled={guideEvaluation?.isLocked}>
              {t('Save')}
            </Button>
          </div>
        </div>
      </Form>
      <Row style={{ marginTop: 10 }}>
        <h3>
          {t('facilityReviews')}
          <Button
            className="nta-create-btn"
            icon={<PlusOutlined />}
            size="small"
            onClick={() => {
              setDisplayInputFacility(true);
            }}
          >
            {t('facilityReviewsAdd')}
          </Button>
        </h3>
      </Row>
      <ZTable<Facility>
        hidePagingOnTop={true}
        columns={columns}
        primaryKey="id"
        tableRef={zTb}
        defaultFilter={{ tourGuideId: tourInfo.guideId }}
        url={`/facility-evaluations/get-facility-evaluations/`}
        bordered
        order_by="createdAt"
        order_by_asc={true}
        actionColumnWidth={100}
        allowEdit={window.innerWidth > 700}
        onEdit={record => {
          setEditingFacilityEvaluationId(record.id);
          setDisplayInputFacility(true);
        }}
        allowDelete={window.innerWidth > 700}
        delete_url="/facility-evaluations/delete"
      />
      {displayInputFacility ? (
        <FacilityReviewModal
          guideId={tourInfo.guideId || ''}
          editingFacilityEvaluationId={editingFacilityEvaluationId}
          onClose={reload => {
            setEditingFacilityEvaluationId(undefined);
            setDisplayInputFacility(false);
            if (reload) {
              zTb.current?.reload();
            }
          }}
        />
      ) : null}
    </Modal>
  );
}
