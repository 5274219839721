import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onChange: (e: { currentTarget: HTMLInputElement }) => void;
  title: string;
  fileName: string;
  onRemove: () => void;
};

export default function GuideAttachment({ title, fileName, onChange, onRemove }: Props) {
  const { t } = useTranslation();
  const ref = React.useRef<HTMLInputElement>();

  return (
    <Row className="guide-attachments">
      <Col md={24} className="guide-attachment-title" xs={24}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ flex: 1 }}>{title}</div>
          <div style={{ display: 'flex' }}>
            <Space>
              <Button
                type="primary"
                className="save-btn"
                icon={<PlusOutlined />}
                onClick={() => {
                  ref.current?.click();
                }}
                size={'small'}
              >
                {t('guideSerchupload')}
              </Button>
              <input
                style={{ display: 'none' }}
                ref={ref as any}
                type="file"
                name={`${title}-fileUpload`}
                id={`${title}-fileUpload`}
                accept="image/*"
                onChange={evt => {
                  onChange(evt);
                }}
                onClick={(event: any) => (event.target.value = null)}
              />

              <Button
                icon={<DeleteOutlined />}
                className="delete-btn"
                danger
                onClick={() => {
                  onRemove();
                  ref.current?.setAttribute('value', '');
                }}
                size={'small'}
              >
                {t('guideSerchdelete')}
              </Button>
            </Space>
          </div>
        </div>
      </Col>
      <Col md={24} style={{ paddingLeft: 10 }} xs={24}>
        <div className="images-preview">
          <img src={fileName} alt="" />
        </div>
      </Col>
    </Row>
  );
}
