import { FormInstance } from 'antd';

export const formItem2OldValue = v => (v ? `${v}[old]` : v);
export const formItem2Value = (v: string | null | undefined) => (v ? v.replace('[old]', '') : v);

export const formatCurrency = v =>
  [undefined, null, ''].includes(v) ? '' : new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(v);

export const clearFormValue = (form: FormInstance<any>) => {
  const values = form.getFieldsValue(true);
  const newValue = {};
  Object.keys(values).map(k => {
    newValue[k] = undefined;
  });
  form.setFieldsValue(newValue);
};
