import { unHttpClient } from '@/httpclient';
import MainContent from '@/Layout/MainContent';
import { GuideModel } from '@/__generated';
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import GuideInfo from '../../../components/GuideInfo';

type Props = {};
const PAGE_TITLE = 'Guide Info';

export default function GuideInfoPage({}: Props) {
  const { gid } = useParams<{ gid: string }>();
  const [t] = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [guide, setGuide] = React.useState<GuideModel>();

  React.useEffect(() => {
    setLoading(true);
    unHttpClient
      .get<GuideModel>(`/get-guide-info/${gid}`)
      .then(res => {
        if (res.data) {
          setGuide(res.data);
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 50);
      });
  }, [gid]);
  return (
    <MainContent title={t(PAGE_TITLE)}>
      <Helmet title={t(PAGE_TITLE)} />
      <GuideInfo guide={guide} loading={loading} />
    </MainContent>
  );
}
