import React from 'react';
import client from '../../httpclient';

const useTQuery = (url: string) => {
  const [data, setData] = React.useState<any>();

  const [loading, setLoading] = React.useState(false);
  const getData = (query: any) => {
    setLoading(true);
    client
      .get(url, { params: query })
      .then(d => {
        setData(d.data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return [
    getData,
    {
      data: data,
      loading: loading,
    } as any,
  ];
};
export default useTQuery;
