import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DatePicker, DatePickerProps } from 'antd';
import localeJA from 'antd/es/date-picker/locale/ja_JP';
import localeEN from 'antd/es/date-picker/locale/en_US';
import { DEFAULT_DISPLAY_DATETIME_FORMAT } from '@/constants';

export default function NTADateTimePicker({ value, ...props }: DatePickerProps) {
  const { t, i18n } = useTranslation();

  if (value && typeof value === 'string') {
    value = moment(value);
  }
  const locale = React.useMemo(() => {
    if (i18n.language === 'en') {
      return localeEN;
    }
    return {
      ...localeJA,
      lang: {
        ...localeJA.lang,
        shortMonths: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        shortWeekDays: ['日', '月', '火', '水', '木', '金', '土'],
      },
    };
  }, [i18n.language]);
  return (
    <DatePicker
      format={DEFAULT_DISPLAY_DATETIME_FORMAT}
      placeholder={t('Select date')}
      {...(props as any)}
      value={value}
      locale={locale}
      inputReadOnly={false}
      showTime={true}
    />
  );
}
