import EnumSelect from '@/components/app/formItem/EnumSelect';
import FacilityInput from '@/components/app/formItem/FacilityInput';
import GuideInput from '@/components/app/formItem/GuideInput';
import NationalityInput from '@/components/app/formItem/NationalityInput';
import NTADatePicker from '@/components/DatePicker';
import { FacilityCategoryType, formatDate, TourAvailableAreaType } from '@/config';
import { SearchOutlined, CloseOutlined, StarOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Row, Select, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onFilterChange: (filter: any) => void;
};
const gutter = 6;
export default function SearchForm({ onFilterChange }: Props) {
  const { t } = useTranslation();

  const [searchForm] = Form.useForm();
  const clearFormValue = () => {
    const values = searchForm.getFieldsValue(true);
    const newValue = {};
    Object.keys(values).map(k => {
      newValue[k] = undefined;
    });
    searchForm.setFieldsValue(newValue);
  };
  const onSearch = v => {
    onFilterChange({
      ...v,
      tourFromDate: v.tourFromDate ? formatDate(v.tourFromDate) : undefined,
      tourToDate: v.tourToDate ? formatDate(v.tourToDate) : undefined,
      useDayFrom: v.useDayFrom ? formatDate(v.useDayFrom) : undefined,
      useDayTo: v.useDayTo ? formatDate(v.useDayTo) : undefined,
    });
  };
  return (
    <Form className="nta-custom-form" form={searchForm} onFinish={onSearch}>
      <Row gutter={gutter}>
        <Col md={6} xs={24}>
          <Row gutter={gutter}>
            <Col md={6} xs={24} className="form-item-label">
              {t('tourEvaluation_tourDuration')}
            </Col>
            <Col md={9} xs={12}>
              <Form.Item name="tourFromDate">
                <NTADatePicker />
              </Form.Item>
            </Col>
            <Col md={9} xs={12}>
              <Form.Item name="tourToDate">
                <NTADatePicker />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={6} xs={24}>
          <Row gutter={gutter}>
            <Col md={6} xs={24} className="form-item-label">
              {t('tourEvaluation_tourName')}
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name="tourName">
                <Input placeholder={t('tourEvaluation_tourName')} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={4} xs={12}>
          <Row gutter={gutter}>
            <Col md={6} xs={24} className="form-item-label">
              {t('tourEvaluation_nationality')}
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name="nationalityShortCodes">
                <NationalityInput mode="multiple" placeholder={t('tourEvaluation_nationality')} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={4} xs={12}>
          <Row gutter={gutter}>
            <Col md={6} xs={24} className="form-item-label">
              {t('tourEvaluation_area')}
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name="areas">
                <EnumSelect mode="multiple" enumData={TourAvailableAreaType} placeholder={t('tourEvaluation_area')} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={4} xs={24}>
          <Row gutter={gutter}>
            <Col md={6} xs={24} className="form-item-label">
              {t('tourEvaluation_agentName')}
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name="agentName">
                <Input placeholder={t('tourEvaluation_agentName')} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={gutter}>
        <Col md={6} xs={24}>
          <Row gutter={gutter}>
            <Col md={6} xs={24} className="form-item-label">
              {t('tourEvaluation_useDate')}
            </Col>
            <Col md={9} xs={12}>
              <Form.Item name="useDayFrom">
                <NTADatePicker />
              </Form.Item>
            </Col>
            <Col md={9} xs={12}>
              <Form.Item name="useDayTo">
                <NTADatePicker />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={6} xs={24}>
          <Row gutter={gutter}>
            <Col md={6} xs={24} className="form-item-label">
              {t('tourEvaluation_facility')}
            </Col>
            <Col md={18} xs={24}>
              <Form.Item noStyle name="facilityCode">
                <Input hidden />
              </Form.Item>
              <Form.Item noStyle name="facilityName">
                <Input hidden />
              </Form.Item>
              <Form.Item>
                <FacilityInput form={searchForm} codeField="facilityCode" nameField="facilityName" placeholder={t('tourEvaluation_facility')} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={4} xs={12}>
          <Row gutter={gutter}>
            <Col md={6} xs={24} className="form-item-label">
              {t('tourEvaluation_type')}
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name="category">
                <EnumSelect mode="multiple" enumData={FacilityCategoryType} placeholder={t('tourEvaluation_type')} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={4} xs={12}>
          <Row gutter={gutter}>
            <Col md={6} xs={24} className="form-item-label">
              {t('tourEvaluation_rating')}
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name="evaluationRates">
                <Select maxTagCount="responsive" allowClear mode="multiple" placeholder={t('tourEvaluation_rating')}>
                  {[5, 4, 3, 2, 1].map(star => {
                    return (
                      <Select.Option key={star} value={star}>
                        {star}
                        <StarOutlined style={{ color: '#333', fontSize: 14 }} />
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={4} xs={24}>
          <Row gutter={gutter}>
            <Col md={6} xs={24} className="form-item-label">
              {t('tourEvaluation_guideName')}
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name="guideIds">
                <GuideInput placeholder={t('tourEvaluation_guideName')} mode="multiple" labelInValue={false} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={gutter}>
        <Col md={12} xs={24}>
          <Row gutter={gutter}>
            <Col md={3} xs={24} className="form-item-label">
              {t('tourEvaluation_AdvancedSearch')}
            </Col>
            <Col md={21} xs={24}>
              <Form.Item name="advanceSearch">
                <Input placeholder="詳細・ドライバー名" />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col md={4} xs={12}>
          <Form.Item name="showOnlyTourReview" valuePropName="checked">
            <Checkbox>{t('tourEvaluation_OnlyTourReview')}</Checkbox>
          </Form.Item>
        </Col>
        <Col md={4} xs={12}>
          <Form.Item name="showOnlyFacilityReview" valuePropName="checked">
            <Checkbox>{t('tourEvaluation_OnlyFacilityReview')}</Checkbox>
          </Form.Item>
        </Col>
        <Col md={4} xs={24} style={{ textAlign: 'end' }}>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" style={{ backgroundColor: '#5f63f2' }}>
                <SearchOutlined style={{ color: '#333' }} />
                {t('zTable.search')}
              </Button>

              <Button
                type="ghost"
                onClick={() => {
                  clearFormValue();
                }}
              >
                <CloseOutlined />
                {t('zTable.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
