import { useAppContext } from '@/contexts/AppContext';
import { isGuideOnly } from '@/services/roleService';
import React from 'react';
import SearchFormForGuide from './SearchFormForGuide';
import DefaultSearchForm from './DefaultSearchForm';

type Props = {
  onSearch: (filter?: any) => void;
};

export default function TourSearchForm({ onSearch }: Props) {
  const { auth } = useAppContext();
  const isGuide = React.useMemo(() => {
    return isGuideOnly(auth?.roleClaims);
  }, [auth]);
  return isGuide ? <SearchFormForGuide onSearch={onSearch} /> : <DefaultSearchForm onSearch={onSearch} />;
}
