import MainContent from '@/Layout/MainContent';
import ZTable, { IColumnType, ITableRef } from '@/components/ZTable';
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { defaultSelectedGuideSeisanStatus } from './GuideSearchForm';
import { GuideSeisanPagingResponse } from '@/__generated';
import { formatDisplayDate } from '@/config';
import { formatCurrency } from '@/helpers/form';
import { GuideSeisanStatus } from '@/constants';
import { sumBy } from 'lodash';
import { Button, Popover, Tag } from 'antd';
import i18n from '@/helpers/i18n';
import * as roleService from '@/services/roleService';
import { useAppContext } from '@/contexts/AppContext';
import GuideSeisanModal from '../Tours/GuideSeisan/GuideSeisanModal';
import { useHistory, useLocation } from 'react-router';
import GuideSearchForm from './GuideSearchForm';
import StaffSearchForm from './StaffSearchForm';
const PAGE_TITLE = 'guideSeisan_PageTitle';
const fixedLeft = window.innerWidth > 1000 ? 'left' : undefined;

export default function GuideSeisanListing() {
  const [editingGuideId, setEditingGuideId] = React.useState<string>();
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();

  const { auth } = useAppContext();
  const zTb = React.useRef<ITableRef>();
  React.useEffect(() => {
    const query = new URLSearchParams(search);
    const tourId = query.get('tourGuideId');
    if (tourId) {
      setEditingGuideId(tourId);
      history.replace('/cms/guide-seisans');
    }
  }, []);
  const canEdit = React.useMemo(() => {
    return roleService.hasPermissionUpdateGuideSeisan(auth);
  }, [auth]);

  const columns = React.useMemo(() => {
    const isGuideOnly = roleService.isGuideOnly(auth?.roleClaims);
    let _cols: IColumnType<GuideSeisanPagingResponse>[] = [
      {
        width: 50,
        fixed: fixedLeft,
        title: 'No.',
        dataIndex: 'createdAt',
        render: (v, t, i) => <b>{`${i + 1}`}</b>,
        align: 'center',
        ellipsis: true,
      },
      {
        title: 'tourMessagePage_TourName',
        dataIndex: 'tourName',
        width: 200,
        ellipsis: true,
        fixed: fixedLeft,
        sorter: true,
      },
      {
        title: 'guideSeisan_TourFromDate',
        dataIndex: 'tourDateFrom',
        width: 140,
        render: v => formatDisplayDate(v),
        ellipsis: true,
        sorter: true,
      },
      {
        title: 'guideSeisan_TourToDate',
        dataIndex: 'tourDateTo',
        width: 130,
        render: v => formatDisplayDate(v),
        ellipsis: true,
        sorter: true,
        defaultSortOrder: 'ascend',
      },
      {
        title: 'tourMessagePage_TourId',
        dataIndex: 'tourIdStr',
        width: 130,
        ellipsis: true,
      },
      {
        title: 'tourDetail_TourNoTourSeisan',
        dataIndex: 'seisanNumber',
        width: 150,
        ellipsis: true,
      },
      {
        title: 'guideSeisan_FileName',
        dataIndex: 'seisanFiles',
        width: 130,
        align: 'center',
        ellipsis: true,
        render: (v: string[]) => (
          <Popover
            title={i18n.t('guideSeisan_FileName')}
            style={{ width: 'fit-content', listStyle: 'decimal' }}
            content={
              <div style={{ padding: 10 }}>
                <ul style={{ width: 'fit-content', listStyle: 'decimal' }}>
                  {v.map(s => {
                    return <li key={s}>{s}</li>;
                  })}
                </ul>
              </div>
            }
          >
            <Button type="link" size="small">
              ...
            </Button>
          </Popover>
        ),
      },
      {
        title: 'guideSeisan_GuideName',
        dataIndex: 'guideName',
        width: 130,
        ellipsis: true,
        sorter: true,
      },
      {
        title: 'guideSeisan_SubmissionDate',
        dataIndex: 'submittedAt',
        width: 130,
        render: v => formatDisplayDate(v),
        ellipsis: true,
        sorter: true,
      },
      {
        title: 'tourDetail_TourSeisanPaymentPaymentCost',
        dataIndex: 'paymentCost',
        render: v => formatCurrency(v),
        width: 130,
        ellipsis: true,
      },
      {
        title: 'tourDetail_TourSeisanPaymentDueDate',
        dataIndex: 'paymentDueDate',
        render: v => formatDisplayDate(v),
        width: 130,
        ellipsis: true,
        sorter: true,
      },
      {
        title: 'tourDetail_GuideSeisanStatus',
        dataIndex: 'guideSeisanStatus',
        render: v => (
          <Tag color={GuideSeisanStatus.__getColor(v)} style={{ color: GuideSeisanStatus.__getTextColor(v) }}>
            {GuideSeisanStatus.__getLabel(v)}
          </Tag>
        ),
        width: 130,
        ellipsis: true,
      },
    ];
    if (isGuideOnly) {
      _cols = [
        ..._cols,
        ...[
          {
            title: 'guideSeisan_SalesStaff',
            dataIndex: 'saleStaff',
            width: 130,
            ellipsis: true,
          },

          {
            title: 'guideSeisan_Agent',
            dataIndex: 'agentName',
            width: 130,
            ellipsis: true,
          },
        ],
      ];
    } else {
      _cols = [
        ..._cols,
        ...[
          {
            title: 'guideSeisan_SeisanPIC',
            dataIndex: 'seisanPic',
            width: 130,
            ellipsis: true,
            sorter: true,
          },
          {
            title: 'guideSeisan_SettlementDate',
            dataIndex: 'settlementDate',
            render: v => formatDisplayDate(v),
            width: 130,
            ellipsis: true,
            sorter: true,
          },

          {
            title: 'guideSeisan_SuperAccounting',
            dataIndex: 'superAccounting',
            width: 130,
            ellipsis: true,
            sorter: true,
          },
          {
            title: 'guideSeisan_AccountingDate',
            dataIndex: 'accountingDate',
            width: 130,
            render: v => formatDisplayDate(v),
            ellipsis: true,
            sorter: true,
          },
          {
            title: 'guideSeisan_TransferPerson',
            dataIndex: 'transferPerson',
            width: 130,
            ellipsis: true,
            sorter: true,
          },
          {
            title: 'guideSeisan_TransferDate',
            dataIndex: 'transferDate',
            width: 130,
            render: v => formatDisplayDate(v),
            ellipsis: true,
            sorter: true,
          },

          {
            title: 'guideSeisan_SalesStaff',
            dataIndex: 'saleStaff',
            width: 130,
            ellipsis: true,
          },

          {
            title: 'guideSeisan_Agent',
            dataIndex: 'agentName',
            width: 130,
            ellipsis: true,
          },
        ],
      ];
    }
    return _cols;
  }, [auth]);

  const W = React.useMemo(() => {
    return sumBy(columns, x => x.width || 0) + 80;
  }, [columns]);
  const isGuide = React.useMemo(() => {
    return roleService.isGuideOnly(auth?.roleClaims);
  }, [auth]);
  return (
    <MainContent title={t(PAGE_TITLE)}>
      <Helmet title={t(PAGE_TITLE)} />
      <div className="ant-layout-content-body">
        <ZTable
          columns={columns}
          primaryKey="id"
          tableRef={zTb}
          defaultFilter={{ guideSeisanStatus: defaultSelectedGuideSeisanStatus }}
          url="/guide-seisans/get-guide-seisans"
          bordered
          order_by="tourDateTo"
          order_by_asc={true}
          allowEdit={canEdit}
          onEdit={record => {
            setEditingGuideId(record.tourGuideId);
          }}
          allowDelete={false}
          searchForm={isGuide ? GuideSearchForm : StaffSearchForm}
          scroll={{ x: W }}
          size="small"
          actionColumnWidth={80}
        ></ZTable>
      </div>
      {editingGuideId ? (
        <GuideSeisanModal
          tourGuideId={editingGuideId}
          onClose={reload => {
            if (reload) {
              zTb.current?.reload();
            }
            setEditingGuideId(undefined);
          }}
        />
      ) : null}
    </MainContent>
  );
}
