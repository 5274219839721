import { GuideGenderType, GuideRankType } from '@/config';
import { GuideGender, GuideRank } from '@/__generated';
import { CalendarFilled, UserOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router';

type Props = {
  name: string;
  dateOfBirth?: Date;
  rank: GuideRank | undefined;
  gender: GuideGender | undefined;
  id: string | undefined;
  maxNumberOfNight: number | undefined;
  onGuideNameClick: (id: string | undefined) => void;
};

function GuideNameCell({ name, dateOfBirth, maxNumberOfNight, rank, gender, id, onGuideNameClick }: Props) {
  const history = useHistory();
  const age = dateOfBirth ? dayjs().diff(dateOfBirth, 'year') : 0;

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
      <Button size="small" type="link" onClick={() => onGuideNameClick(id)} style={{ fontWeight: 'bold' }}>
        {gender ? (
          <UserOutlined size={12} style={{ color: gender === GuideGenderType.Female ? '#DA836F' : '#2C76CB' }} />
        ) : (
          <UserOutlined size={12} style={{ color: 'transparent' }} />
        )}
        <Typography.Text ellipsis={true} style={{ color: '#1890ff', width: 110, textAlign: 'left' }}>
          {name}
        </Typography.Text>
        {dateOfBirth ? <span style={{ fontWeight: 'bold' }}>({age})</span> : null}
      </Button>

      <div style={{ width: 90, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end' }}>
        {rank ? (
          <div
            style={{
              width: 18,
              height: 18,
              borderRadius: 18,
              fontSize: 12,
              textAlign: 'center',
              marginRight: 5,
              backgroundColor: GuideRankType.__getColor(rank),
            }}
          >
            {GuideRankType.__getValue(rank)}
          </div>
        ) : null}
        <span style={{ width: 30, minWidth: 30, textAlign: 'right' }}>{maxNumberOfNight}日</span>
        <Button
          type="link"
          icon={<CalendarFilled />}
          onClick={() => {
            history.push(`/cms/calendars?guideId=${id}`);
          }}
        />
      </div>
    </div>
  );
}

export default React.memo(GuideNameCell, (prevProps, props) => {
  return prevProps?.id === props?.id;
});
