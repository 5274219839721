import SearchableInMobile from '@/components/app/SearchableInMobile';
import GuideAutoComplete from '@/components/app/formItem/GuideSearchField';
import NTAStaffSearchField from '@/components/app/formItem/NTAStaffInput';
import { formatDate } from '@/config';
import { GuideSeisanStatus, TourSeisanStatus } from '@/constants';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, Input, Row, Col, DatePicker, Select, Button, InputNumber } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as roleService from '@/services/roleService';
import { useAppContext } from '@/contexts/AppContext';
import { formatCurrency } from '@/helpers/form';
import NTAAccountingInput from '@/components/app/formItem/NTAAccountingInput';
type Props = {
  onSearch: (filter?: any) => void;
};
export const defaultSelectedGuideSeisanStatus = [
  GuideSeisanStatus.NoFilesUploaded,
  GuideSeisanStatus.Uploaded,
  GuideSeisanStatus.Confirming,
  GuideSeisanStatus.Confirmed,
  GuideSeisanStatus.FinalConfirmed,
  GuideSeisanStatus.Paying,
  GuideSeisanStatus.Rejected,
];

export default function StaffSearchForm({ onSearch }: Props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { auth } = useAppContext();
  const isGuideOnly = React.useMemo(() => {
    return roleService.isGuideOnly(auth?.roleClaims);
  }, [auth]);
  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ xl: 10, xxl: 9 }}
      labelAlign="left"
      colon={false}
      initialValues={{ guideSeisanStatus: defaultSelectedGuideSeisanStatus }}
      className="nta-custom-form"
      onFinish={v => {
        onSearch({
          ...v,
          tourDateFrom: formatDate(v.tourDateFrom) || undefined,
          tourDateTo: formatDate(v.tourDateTo) || undefined,
          submissionDateFrom: formatDate(v.submissionDateFrom) || undefined,
          submissionDateTo: formatDate(v.submissionDateTo) || undefined,
          paymentDueDateFrom: formatDate(v.paymentDueDateFrom) || undefined,
          paymentDueDateTo: formatDate(v.paymentDueDateTo) || undefined,
        });
      }}
    >
      <SearchableInMobile>
        <Row gutter={6}>
          {/* Row 1 */}
          <Col md={24} xs={24} xl={8} xxl={6}>
            <Form.Item name="tourSeisanStatus" label={t('tourDetail_TourSeisanStatus')} labelCol={{ xl: 8, xxl: 8 }}>
              <Select options={TourSeisanStatus.__getSelectOptions()} allowClear mode="multiple" maxTagCount="responsive" />
            </Form.Item>
          </Col>
          <Col md={24} xs={24} xl={16} xxl={18}>
            <Row gutter={6}>
              <Col md={12} xs={12} xl={6} xxl={6}>
                <Form.Item name="tourName" label={t('tourMessagePage_TourName')}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={12} md={12} xl={6} xxl={6}>
                <Form.Item label={t('guideSeisan_Agent')} name="agentName">
                  <Input placeholder={t('guideSeisan_Agent')} />
                </Form.Item>
              </Col>
              <Col xs={12} md={12} xl={6} xxl={6}>
                <Form.Item noStyle name="guideId">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item noStyle name="guideName">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item label={t('guideSeisan_GuideName')} name="guide">
                  <GuideAutoComplete form={form} placeholder={t('guideSeisan_GuideName')} disabled={isGuideOnly} />
                </Form.Item>
              </Col>
              <Col md={12} xs={12} xl={6} xxl={6}>
                <Form.Item name="fileName" label={t('guideSeisan_FileName')}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Row 2 */}
        <Row gutter={6}>
          <Col md={24} xs={24} xl={8} xxl={6}>
            <Form.Item name="seisanNumber" label={t('tourDetail_TourNoTourSeisan')} labelCol={{ xl: 8, xxl: 8 }}>
              <Input />
            </Form.Item>
          </Col>
          <Col md={24} xs={24} xl={16} xxl={18}>
            <Row gutter={6}>
              <Col md={12} xs={12} xl={6} xxl={6}>
                <Form.Item name="tourDateFrom" label={t('guideSeisan_TourFromDate')}>
                  <DatePicker className="w-100" />
                </Form.Item>
              </Col>
              <Col md={12} xs={12} xl={6} xxl={6}>
                <Form.Item name="tourDateTo" label={t('guideSeisan_TourToDate')}>
                  <DatePicker className="w-100" />
                </Form.Item>
              </Col>
              <Col md={12} xs={12} xl={6} xxl={6}>
                <Form.Item name="submissionDateFrom" label={t('guideSeisan_SubmissionFromDate')}>
                  <DatePicker className="w-100" />
                </Form.Item>
              </Col>
              <Col md={12} xs={12} xl={6} xxl={6}>
                <Form.Item name="submissionDateTo" label={t('guideSeisan_SubmissionToDate')}>
                  <DatePicker className="w-100" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        {/*  Row 3*/}
        <Row gutter={6}>
          <Col xs={12} md={12} xl={8} xxl={6}>
            <Form.Item name="guideSeisanStatus" label={t('tourDetail_GuideSeisanStatus')} labelCol={{ xl: 8, xxl: 8 }}>
              <Select options={GuideSeisanStatus.__getSelectOptions()} allowClear mode="multiple" maxTagCount="responsive" />
            </Form.Item>
          </Col>
          <Col md={24} xs={24} xl={16} xxl={18}>
            <Row gutter={6}>
              <Col md={12} xs={12} xl={6} xxl={6}>
                <Form.Item name="billingAmountFrom" label={t('guideSeisan_BillingAmountFrom')}>
                  <InputNumber className="w-100" formatter={v => formatCurrency(v)} />
                </Form.Item>
              </Col>
              <Col md={12} xs={12} xl={6} xxl={6}>
                <Form.Item name="billingAmountTo" label={t('guideSeisan_BillingAmountTo')}>
                  <InputNumber className="w-100" formatter={v => formatCurrency(v)} />
                </Form.Item>
              </Col>
              <Col md={12} xs={12} xl={6} xxl={6}>
                <Form.Item name="paymentDueDateFrom" label={t('guideSeisan_PaymentDueFromDate')}>
                  <DatePicker className="w-100" />
                </Form.Item>
              </Col>
              <Col md={12} xs={12} xl={6} xxl={6}>
                <Form.Item name="paymentDueDateTo" label={t('guideSeisan_PaymentDueToDate')}>
                  <DatePicker className="w-100" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Row 4 */}
        <Row gutter={6}>
          <Col md={12} xs={12} xl={8} xxl={6}>
            <Form.Item name="tourIdStr" label={t('tourMessagePage_TourId')} labelCol={{ xl: 8, xxl: 8 }}>
              <Input />
            </Form.Item>
          </Col>
          <Col md={24} xs={24} xl={16} xxl={18}>
            <Row gutter={6}>
              <Col md={12} xs={12} xl={6} xxl={6}>
                <Form.Item noStyle name="seisanPicUserName">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item noStyle name="seisanPicName">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item name="saleStaff" label={t('guideSeisan_SeisanPIC')}>
                  <NTAAccountingInput form={form} idField="seisanPicUserName" nameField="seisanPicName" placeholder={t('guideSeisan_SeisanPIC')} />
                </Form.Item>
              </Col>
              <Col md={12} xs={12} xl={6} xxl={6}>
                <Form.Item noStyle name="superAccountingUserName">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item noStyle name="superAccountingName">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item name="superAccounting" label={t('guideSeisan_SuperAccounting')}>
                  <NTAAccountingInput
                    form={form}
                    idField="superAccountingUserName"
                    nameField="superAccountingName"
                    placeholder={t('guideSeisan_SuperAccounting')}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={12} xl={6} xxl={6}>
                <Form.Item noStyle name="transferPersonUserName">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item noStyle name="transferPersonName">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item name="transferPerson" label={t('guideSeisan_TransferPerson')}>
                  <NTAAccountingInput
                    form={form}
                    idField="transferPersonUserName"
                    nameField="transferPersonName"
                    placeholder={t('guideSeisan_TransferPerson')}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={12} xl={6} xxl={6}>
                <Form.Item noStyle name="saleStaffId">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item noStyle name="saleStaffName">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item name="saleStaff" label={t('guideSeisan_SaleStaff')}>
                  <NTAStaffSearchField
                    form={form}
                    idField="saleStaffId"
                    nameField="saleStaffName"
                    placeholder={t('guideSeisan_SaleStaff')}
                    id="search_saleStaff"
                  />
                </Form.Item>
              </Col>

              <Col flex={1} className="search-form-action" style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit">
                  <SearchOutlined style={{ color: '#333' }} />
                  {t('zTable.search')}
                </Button>
                <Button type="ghost" htmlType="reset" style={{ marginLeft: 5 }}>
                  <CloseOutlined />
                  {t('zTable.reset')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </SearchableInMobile>
    </Form>
  );
}
