import { guidHasValue } from '@/helpers/string';
import { ApiSdk } from '@/httpclient';
import { AutoComplete, AutoCompleteProps, FormInstance } from 'antd';
import { debounce } from 'lodash';
import React from 'react';

interface Props extends AutoCompleteProps {
  form: FormInstance<any>;
  idField: string;
  nameField: string;
  idValue?: string | undefined;
  nameValue?: string | undefined;
}
const toLabel = (firstName: string, lastName: string) => `${lastName} ${firstName}`;

export default function NTAAccountingInput({
  form,
  className,
  value: originValue,
  idValue,
  nameValue,
  nameField,
  idField,

  ...props
}: Props) {
  const [value, setValue] = React.useState<string>(nameValue || '');
  const [data, setData] = React.useState<{ label: string; value: string }[]>([]);
  const [isTextValue, setIsTextValue] = React.useState<boolean>(true);

  React.useEffect(() => {
    getData();
  }, [value]);

  const _getData = () => {
    ApiSdk.StaffService.accountingStaffAutocompletion({ query: value, limit: 20 }).then(res => {
      setData(res?.map(staff => ({ label: toLabel(staff.firstNameKanji || '', staff.lastNameKanji || ''), value: staff.userName || '' })) || []);
    });
  };

  React.useEffect(() => {
    if (guidHasValue(idValue)) {
      form.setFieldsValue({
        [nameField]: undefined,
        [idField]: idValue,
      });
      setIsTextValue(false);
    } else if (nameValue) {
      setIsTextValue(true);
      form.setFieldsValue({
        [nameField]: nameValue,
        [idField]: undefined,
      });
    }
  }, [idValue, nameValue, idField, nameField, form]);

  const getData = debounce(_getData, 300);
  React.useEffect(() => {
    setValue(nameValue || '');
  }, [nameValue]);

  const fieldClassName = React.useMemo(() => {
    if (isTextValue) {
      return `nta-autocomplete is-text-value ${className || ''}`;
    }
    return `nta-autocomplete ${className || ''}`;
  }, [className, isTextValue]);
  return (
    <AutoComplete
      allowClear={true}
      onSearch={v => {
        setValue(v);
        setIsTextValue(true);
        form.setFieldsValue({
          [nameField]: v,
          [idField]: undefined,
        });
      }}
      onSelect={(v, op) => {
        setValue(op.label as any);
        setIsTextValue(false);

        form.setFieldsValue({
          [nameField]: undefined,
          [idField]: v,
        });
      }}
      {...props}
      options={data}
      value={value}
      className={fieldClassName}
    />
  );
}
