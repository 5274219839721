import MainContent from '@/Layout/MainContent';
import ZTable, { IColumnType, ITableRef } from '@/components/ZTable';
import { DeleteOutlined, DownloadOutlined, HistoryOutlined, InfoCircleOutlined, SendOutlined } from '@ant-design/icons';
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import SearchForm from './searchForm';
import { Button, Col, Modal, Row, Space, Spin, Tag, Tooltip, message } from 'antd';
import { formatDisplayFullDate } from '@/config';
import { ERROR_EXCEPTION, TourMessageStatusType, TourMessageType } from '@/constants';
import { ApiSdk } from '@/httpclient';
import { TourMessageAttachmentModel, TourMessagePagingResponse } from '@/__generated';
import { fileService } from '@/services/fileService';
import { sumBy } from 'lodash';
import useAuth from '@/hooks/useAuth';
import { useAppContext } from '@/contexts/AppContext';
import { isGuideOnly } from '@/services/roleService';

const filters = [];
const PAGE_TITLE = 'tourMessagePage_Title';

interface TourMessageDetailProps extends TourMessagePagingResponse {
  bodyContent: string | null;
  attachments: TourMessageAttachmentModel[];
}
const faxIcon = require('@/assets/images/fax.png');
const mailIcon = require('@/assets/images/mail.png');

const contentStyle = {
  border: '1px dashed #dddddd',
  padding: 10,
  backgroundColor: '#ededed',
  marginTop: 10,
  borderRadius: 5,
  marginBottom: 10,
};
export default function TourMessagePage() {
  const { t } = useTranslation();
  const zTb = React.useRef<ITableRef>();
  const [loading, showLoading] = React.useState<boolean>(false);
  const [messageDetail, setMessageDetail] = React.useState<TourMessageDetailProps>();
  const { auth } = useAppContext();
  const showMessageDetail = (tourMessage: TourMessagePagingResponse) => {
    showLoading(true);
    ApiSdk.TourMessageService.getDetails({ id: tourMessage.id || '' })
      .then(res => {
        setMessageDetail({
          ...tourMessage,
          bodyContent: res.bodyContent || '',
          attachments: res.attachments || [],
        });
      })
      .catch(() => {
        message.error(ERROR_EXCEPTION);
      })
      .finally(() => {
        showLoading(false);
      });
  };

  const reSendMessage = (id: string) => {
    Modal.confirm({
      title: t('tourMessagePage_ReSendMessage'),
      content: t('tourMessagePage_ReSendConfirmMessage'),
      cancelText: t('Close'),
      okText: t('tourMessagePage_ReSendMessage'),
      onOk: () => {
        const loader = message.loading(t('tourMessagePage_Sending'));
        ApiSdk.TourMessageService.resendMessage({ id: id }).finally(() => {
          zTb.current?.reload();
          setMessageDetail(undefined);

          if (loader) {
            loader();
          }
        });
      },
    });
  };
  const columns: IColumnType<TourMessagePagingResponse>[] = React.useMemo(() => {
    return [
      {
        width: 70,
        title: 'tourMessagePage_Type',
        dataIndex: 'messageType',
        align: 'center',
        render: v => <img src={v === TourMessageType.EFax ? faxIcon : mailIcon} alt="request type" style={{ width: 20 }} />,
      },
      {
        width: 250,
        title: 'tourMessagePage_Subject',
        dataIndex: 'subject',
        ellipsis: true,
      },
      {
        width: 170,
        title: 'tourMessagePage_FacilityName',
        dataIndex: 'facilityName',
        ellipsis: true,
      },
      {
        width: 180,
        title: 'tourMessagePage_To',
        dataIndex: 'sendTo',
        ellipsis: true,
      },
      {
        title: 'tourMessagePage_DateTime',
        dataIndex: 'createdOnUtc',
        width: 170,
        render: v => formatDisplayFullDate(v),
      },
      {
        width: 200,
        title: 'tourMessagePage_TourName',
        dataIndex: 'tourName',
        ellipsis: true,
      },
      {
        width: 150,
        title: 'tourMessagePage_TourId',
        dataIndex: 'tourId',
        ellipsis: true,
      },
      {
        width: 150,
        title: 'tourMessagePage_Guide',
        dataIndex: 'guideName',
        ellipsis: true,
      },
      {
        title: 'tourMessagePage_Status',
        dataIndex: 'status',
        render: v => <Tag color={TourMessageStatusType.__getColor(v)}>{TourMessageStatusType.__getValue(v)}</Tag>,
        width: 100,
      },
      {
        width: 80,
        title: 'tourMessagePage_Error',
        dataIndex: 'error',
        render(_: any, record) {
          const isNotGuide = !isGuideOnly(auth?.roleClaims);
          return record.error ? (
            <Button
              size="small"
              type="default"
              danger
              onClick={() => {
                Modal.info({
                  title: t('Error'),
                  content: (
                    <div>
                      {record.eFaxStatusCode && isNotGuide ? <h3>{record.eFaxStatusCode}</h3> : ''}
                      {record.error}
                    </div>
                  ),
                  width: 700,
                });
              }}
            >
              {t('tourMessagePage_Error')}
            </Button>
          ) : (
            <div />
          );
        },
      },
      {
        width: 70,
        title: 'zTable.actions',
        dataIndex: '',
        align: 'center',
        fixed: 'right',
        render(_: any, record: any) {
          return (
            <Space>
              <span>
                <Tooltip title={t('tourMessagePage_ShowDetail')}>
                  <InfoCircleOutlined
                    style={{ color: '#4176d9' }}
                    onClick={() => {
                      showMessageDetail(record);
                    }}
                  />
                </Tooltip>
              </span>
            </Space>
          );
        },
      },
    ] as IColumnType<TourMessagePagingResponse>[];
  }, [auth]);

  const Width = React.useMemo(() => {
    return sumBy(columns, x => x.width || 0);
  }, [columns]);
  return (
    <MainContent title={t(PAGE_TITLE)} icon={<HistoryOutlined style={{ fontSize: 16 }} />}>
      <Helmet title={t(PAGE_TITLE)} />
      <div className="ant-layout-content-body">
        <ZTable<any>
          columns={columns}
          primaryKey="id"
          tableRef={zTb}
          url="/tour-messages/get-messages"
          bordered
          order_by="createdOnUtc"
          order_by_asc={false}
          allowEdit={false}
          allowDelete={false}
          filters={filters}
          layoutFilter={'horizontal'}
          searchForm={SearchForm}
          scroll={{ x: Width }}
        />
      </div>
      {messageDetail ? (
        <Modal
          className="nta-custom-form create-tour-form"
          bodyStyle={{ minHeight: 600, overflow: 'auto', maxHeight: 'calc(100vh - 150px)' }}
          visible={true}
          maskClosable={false}
          width={800}
          style={{ top: 10 }}
          title={t('tourMessagePage_Title')}
          forceRender={true}
          onCancel={() => setMessageDetail(undefined)}
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                type="default"
                onClick={() => {
                  setMessageDetail(undefined);
                }}
              >
                {t('Close')}
              </Button>
              <Button
                type="primary"
                icon={<SendOutlined />}
                onClick={() => {
                  reSendMessage(messageDetail.id || '');
                }}
              >
                {t('tourMessagePage_ReSent')}
              </Button>
            </div>
          }
        >
          <Spin spinning={loading}>
            <div>
              <b>{t('tourMessagePage_Subject')}</b>
            </div>

            <div style={contentStyle}>{messageDetail.subject}</div>
            <div>
              <b>{t('tourMessagePage_Body')}</b>
            </div>

            <div style={contentStyle} dangerouslySetInnerHTML={{ __html: messageDetail.bodyContent || '' }} />
            {messageDetail.attachments?.length ? (
              <>
                <div>
                  <b>{t('tourMessagePage_Attachment')}</b>
                </div>
                <div style={contentStyle}>
                  {messageDetail.attachments?.map(file => {
                    return (
                      <Row gutter={6}>
                        <Col md={22}>{file.fileName}</Col>
                        <Col md={2} style={{ textAlign: 'right' }}>
                          <Button
                            size="small"
                            icon={<DownloadOutlined />}
                            type="primary"
                            onClick={() => {
                              fileService.downloadFileById(file.fileId || '');
                            }}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              </>
            ) : null}
          </Spin>
        </Modal>
      ) : null}
    </MainContent>
  );
}
