import i18n from '@/helpers/i18n';
import { GuideModel } from '@/__generated';
import { Modal } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { uniq } from 'lodash';

export const checkValidGuideDataBeforeSave = (guideInfo: GuideModel, form: FormInstance): boolean => {
  // check duplicated language

  const languagesCode = guideInfo.supportedLanguages?.map(x => x.languageCode) || [];
  if (uniq(languagesCode).length !== languagesCode.length) {
    form.scrollToField(['bankAccountHolder'], {
      behavior: 'smooth',
    });
    Modal.error({
      title: i18n.t('GuideLanguageDuplicatedTitle'),
      content: i18n.t('GuideLanguageDuplicatedContent'),
    });

    return false;
  }

  return true;
};
