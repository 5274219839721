import EnumSelect from '@/components/app/formItem/EnumSelect';
import FacilityInput from '@/components/app/formItem/FacilityInput';
import TourLockCpn from '@/components/app/TourLockCpn';
import NTADatePicker from '@/components/DatePicker';
import { FacilityCategoryType, formatDate } from '@/config';
import { ERROR_EXCEPTION } from '@/constants';
import { ApiSdk } from '@/httpclient';
import lockService from '@/services/lockService';
import { FacilityEvaluationDetailModel } from '@/__generated';
import { SaveOutlined } from '@ant-design/icons';
import { Form, Modal, Row, Col, Input, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AccommodationRateItem from './items/AccommodationRateItem';
import MealItem from './items/MealItem';
import OtherItem from './items/OtherItem';
import TransportationItem from './items/TransportationItem';
import BusItem from './items/BusItem';
import ExperienceItem from './items/ExperienceItem';
import SightseeingItem from './items/SightseeingItem';
const requiredRule = {
  required: true,
  message: <Trans>VALIDATE_REQUIRED</Trans>,
};
type Props = {
  editingFacilityEvaluationId: string | null | undefined;
  onClose: (reload?: boolean) => void;
  guideId: string;
};

const keepAliveTimeout = 5 * 60 * 1000; //5 minutes
let keepAliveInterval: any = null;

export default function FacilityReviewModal({ editingFacilityEvaluationId, guideId, onClose }: Props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [hasFieldChange, setHasFieldChange] = React.useState(false);
  const [sessionId, setSessionId] = React.useState<string>();
  const [facilityEvaluation, setFacilityEvaluation] = React.useState<FacilityEvaluationDetailModel>();
  const [loading, setLoading] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (editingFacilityEvaluationId && sessionId) {
      ApiSdk.FacilityEvaluationService.getFacilityEvaluation({ id: editingFacilityEvaluationId, sessionId }).then(res => {
        form.setFieldsValue({ ...res, facility: res.facilityName });
        setFacilityEvaluation(res);
      });
    } else {
      setFacilityEvaluation(undefined);
      form.setFieldsValue({
        category: FacilityCategoryType.Accommodation,
      });
    }
  }, [editingFacilityEvaluationId, sessionId]);

  React.useEffect(() => {
    if (editingFacilityEvaluationId) {
      setSessionId(lockService.createSessionId());
    }
  }, [editingFacilityEvaluationId]);

  React.useEffect(() => {
    if (sessionId && facilityEvaluation && facilityEvaluation.isLocked === false) {
      keepAliveInterval = window.setInterval(() => {
        lockService.keepAlive(facilityEvaluation.id || '', sessionId);
      }, keepAliveTimeout);
      const onUnlock = () => {
        lockService.unlock(facilityEvaluation.id || '', sessionId);
      };
      const unlockOnCloseTab = () => {
        lockService.unlockOnTabClose(facilityEvaluation.id || '', sessionId);
      };
      const onTabActiveCallback = () => {
        if (!document.hidden) {
          lockService.checkLockSessionAlive(facilityEvaluation.id || '', sessionId);
        }
      };
      const onBrowserTabClose = lockService.onBrowserTabClose(unlockOnCloseTab);
      const onTabReActive = lockService.onTabReActive(onTabActiveCallback);
      return () => {
        onUnlock();
        onBrowserTabClose();
        onTabReActive();
        if (keepAliveInterval) {
          window.clearInterval(keepAliveInterval);
        }
      };
    }
    return () => {};
  }, [sessionId, facilityEvaluation]);

  const onCreate = values => {
    setLoading(true);
    ApiSdk.FacilityEvaluationService.create({
      body: {
        ...values,
        useDate: formatDate(values.useDate),
        tourGuideId: guideId,
      },
    })
      .then(res => {
        if (res.success) {
          onClose(true);
        } else {
          message.error(t(res.message || ''));
        }
      })
      .catch(() => {
        message.error(ERROR_EXCEPTION);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onUpdate = values => {
    setLoading(true);
    ApiSdk.FacilityEvaluationService.update({
      body: {
        ...values,
        id: facilityEvaluation?.id || '',
        useDate: formatDate(values.useDate),
        tourGuideId: guideId,
        sessionId,
      },
    })
      .then(res => {
        if (res.success) {
          onClose(true);
        } else {
          message.error(t(res.message || ''));
        }
      })
      .catch(() => {
        message.error(ERROR_EXCEPTION);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onSubmit = () => {
    form
      .validateFields()
      .then(values => {
        setHasFieldChange(false);
        if (facilityEvaluation) {
          onUpdate(values);
        } else {
          onCreate(values);
        }
      })
      .catch(err => {
        if (err?.errorFields.length) {
          console.log(err);
          const fieldsName = err.errorFields[0].name;
          form.scrollToField(fieldsName, { behavior: 'smooth' });
        }
      });
  };

  const check2Close = () => {
    if (hasFieldChange) {
      Modal.confirm({
        title: '保存されていない項目があります。本当に閉じますか？',
        okText: t('Yes'),
        cancelText: t('No'),
        onOk: () => {
          onClose();
        },
      });
    } else {
      onClose();
    }
  };
  const isLocked = !!facilityEvaluation?.isLocked;

  return (
    <Modal
      className="nta-custom-form create-tour-form"
      visible={true}
      onCancel={check2Close}
      style={{ top: 10, maxWidth: 900 }}
      width={'100%'}
      title={t('guideEvaluationModalTitle')}
      okButtonProps={{ loading, icon: <SaveOutlined />, className: 'save-btn', disabled: facilityEvaluation?.isLocked }}
      cancelButtonProps={{ style: { float: 'left' }, loading }}
      okText={t('Save')}
      cancelText={t('Close')}
      onOk={onSubmit}
      maskClosable={false}
    >
      <TourLockCpn isLocked={isLocked} lockedBy={facilityEvaluation?.lockedBy}></TourLockCpn>
      <Form
        onValuesChange={() => {
          setHasFieldChange(true);
        }}
        layout="vertical"
        form={form}
      >
        <Row gutter={6}>
          <Col md={5} xs={12}>
            <Form.Item label={t('facilityReviewDate')} name="useDate" rules={[requiredRule]}>
              <NTADatePicker disabled={isLocked} className="input-value-color" />
            </Form.Item>
          </Col>
          <Col md={5} xs={12}>
            <Form.Item label={t('facilityReviewType')} name="category" rules={[requiredRule]}>
              <EnumSelect enumData={FacilityCategoryType} disabled={!!facilityEvaluation || isLocked} className="input-value-color" />
            </Form.Item>
          </Col>
          <Col md={14} xs={24}>
            <Form.Item noStyle name="facilityCode">
              <Input hidden />
            </Form.Item>
            <Form.Item noStyle name="facilityName">
              <Input hidden />
            </Form.Item>
            <Form.Item noStyle dependencies={['category']}>
              {({ getFieldValue }) => {
                const category = getFieldValue(['category']);
                return (
                  <Form.Item
                    label={t('facilityReviewFacilityName')}
                    name="facility"
                    requiredMark={true}
                    rules={
                      facilityEvaluation
                        ? []
                        : [
                            requiredRule,
                            ({ getFieldValue }) => ({
                              validator() {
                                const facilityCode = getFieldValue('facilityCode');
                                const facilityName = getFieldValue('facilityName');
                                if (facilityName || facilityCode) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error(t('VALIDATE_REQUIRED')));
                              },
                            }),
                          ]
                    }
                  >
                    {facilityEvaluation ? (
                      <Input
                        disabled
                        className={`input-value-color ${facilityEvaluation.facilityCode ? '' : ' nta-autocomplete is-text-value'}`}
                        value={facilityEvaluation.facilityName}
                      />
                    ) : (
                      <FacilityInput
                        category={category}
                        disabled={!category || !!facilityEvaluation || isLocked}
                        codeField="facilityCode"
                        nameField="facilityName"
                        form={form}
                        className="input-value-color"
                      />
                    )}
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        {
          <Form.Item noStyle dependencies={['category']}>
            {({ getFieldValue }) => {
              const category = getFieldValue(['category']);
              switch (category) {
                case FacilityCategoryType.Accommodation:
                  return <AccommodationRateItem disabled={isLocked} />;
                case FacilityCategoryType.Bus:
                  return <BusItem disabled={isLocked} />;
                case FacilityCategoryType.Transportation:
                  return <TransportationItem disabled={isLocked} />;
                case FacilityCategoryType.Meal:
                  return <MealItem disabled={isLocked} />;
                case FacilityCategoryType.Sightseeing:
                  return <SightseeingItem disabled={isLocked} />;
                case FacilityCategoryType.Experience:
                  return <ExperienceItem disabled={isLocked} />;
                default:
                  return <OtherItem disabled={isLocked} />;
              }
            }}
          </Form.Item>
        }
      </Form>
    </Modal>
  );
}
