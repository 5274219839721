import EnumSelect from '@/components/app/formItem/EnumSelect';
import TimeInput from '@/components/app/formItem/TimeInput';
import DatePicker from '@/components/DatePicker';
import { BriefingType } from '@/config';
import { time24hRegex } from '@/constants';
import { createNtaCommentsHtml } from '@/helpers/string';
import { TourDetailModel, TourGuideDetailModel } from '@/__generated';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Form, Input, Modal, Button, Space, InputNumber } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { orderBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShortTourGuideInfo } from '../../TourFeedback/TourEvaluation';
import GuideSeisanButton from '../GuideSeisan/GuideSeisanButton';
import { useAppContext } from '@/contexts/AppContext';
import { useTourContext } from '@/contexts/TourContext';
import tourService from '@/services/tourService';

type Props = {
  fieldData: FormListFieldData;
  tourGuide?: TourGuideDetailModel | null | undefined;
  isUnTour?: boolean;
  tour?: TourDetailModel | null | undefined;
  showGuideEvaluation?: (shortGuideInfo: ShortTourGuideInfo) => void;
};
const gutter = 8;
const labeCol = {
  md: 11,
  xs: 24,
};
const dateCol = {
  md: 8,
  xs: 14,
};
const timeCol = {
  md: 5,
  xs: 10,
};
export const GuideOtherInfo = ({ fieldData, isUnTour, tourGuide, tour, showGuideEvaluation }: Props) => {
  const { t } = useTranslation();
  const tourContext = useTourContext();
  const comments = React.useMemo(() => {
    if (tourGuide?.ntaComments) {
      return orderBy(tourGuide?.ntaComments, x => moment(x.createdAt), 'desc');
    }
    return [];
  }, [tourGuide]);
  const amountCarrying = React.useMemo(() => {
    if (tourContext.tour && tourGuide) {
      return tourContext.tour.tourGuides?.find(x => x.id === tourGuide.id)?.seisanAmountCarrying || 0;
    }
    return 0;
  }, [tourContext.tour, tourGuide]);
  return (
    <>
      {/* <Row gutter={6} style={{ marginTop: 10 }}>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col md={11} xs={24} className="form-item-label">
              {t('Briefing')}
            </Col>
            <Col md={13} xs={24}>
              <Form.Item name={[fieldData.name, 'briefingType']}>
                <EnumSelect enumData={BriefingType} placeholder={t('Briefing')} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col {...labeCol} className="form-item-label">
              {t('Briefing Date And Time')}
            </Col>
            <Col {...dateCol}>
              <Form.Item name={[fieldData.name, 'briefingDate']}>
                <DatePicker />
              </Form.Item>
            </Col>
            <Col {...timeCol}>
              <Form.Item
                name={[fieldData.name, 'briefingTime']}
                rules={[
                  {
                    pattern: time24hRegex,
                  },
                ]}
              >
                <TimeInput />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col md={11} xs={24} className="form-item-label">
              {t('Expected Date Of Arrival Of Materials')}
            </Col>
            <Col md={13} xs={24}>
              <Form.Item name={[fieldData.name, 'expectedDateOfMaterials']}>
                <DatePicker className="w-100" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col {...labeCol} className="form-item-label">
              {t('Briefing desired day 1')}
            </Col>
            <Col {...dateCol}>
              <Form.Item name={[fieldData.name, 'desiredBriefingDate1']}>
                <DatePicker className="w-100 input-value-color" disabled />
              </Form.Item>
            </Col>
            <Col {...timeCol}>
              <Form.Item name={[fieldData.name, 'desiredBriefingTime1']}>
                <Input disabled className="w-100 input-value-color" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col {...labeCol} className="form-item-label">
              {t('Briefing desired date 2')}
            </Col>
            <Col {...dateCol}>
              <Form.Item name={[fieldData.name, 'desiredBriefingDate2']}>
                <DatePicker className="w-100 input-value-color" disabled />
              </Form.Item>
            </Col>
            <Col {...timeCol}>
              <Form.Item name={[fieldData.name, 'desiredBriefingTime2']}>
                <Input disabled className="w-100 input-value-color" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col {...labeCol} className="form-item-label">
              {t('Briefing desired date 3')}
            </Col>
            <Col {...dateCol}>
              <Form.Item name={[fieldData.name, 'desiredBriefingDate3']}>
                <DatePicker className="w-100 input-value-color" disabled />
              </Form.Item>
            </Col>
            <Col {...timeCol}>
              <Form.Item name={[fieldData.name, 'desiredBriefingTime3']}>
                <Input disabled className="w-100 input-value-color" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col {...labeCol} className="form-item-label">
              {t('Material arrival desired date and time 1')}
            </Col>
            <Col {...dateCol}>
              <Form.Item name={[fieldData.name, 'desiredDateOfMaterial1']}>
                <DatePicker className="w-100 input-value-color" disabled />
              </Form.Item>
            </Col>
            <Col {...timeCol}>
              <Form.Item name={[fieldData.name, 'desiredTimeOfMaterial1']}>
                <Input disabled className="w-100 input-value-color" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col {...labeCol} className="form-item-label">
              {t('Material arrival desired date and time 2')}
            </Col>
            <Col {...dateCol}>
              <Form.Item name={[fieldData.name, 'desiredDateOfMaterial2']}>
                <DatePicker className="w-100 input-value-color" disabled />
              </Form.Item>
            </Col>
            <Col {...timeCol}>
              <Form.Item name={[fieldData.name, 'desiredTimeOfMaterial2']}>
                <Input disabled className="w-100 input-value-color" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col {...labeCol} className="form-item-label">
              {t('Material arrival desired date and time 3')}
            </Col>
            <Col {...dateCol}>
              <Form.Item name={[fieldData.name, 'desiredDateOfMaterial3']}>
                <DatePicker className="w-100 input-value-color" disabled />
              </Form.Item>
            </Col>
            <Col {...timeCol}>
              <Form.Item name={[fieldData.name, 'desiredTimeOfMaterial3']}>
                <Input disabled className="w-100 input-value-color" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row> */}

      <Row gutter={gutter}>
        <Col flex={'175px'} xs={24}>
          {t('Contact Items From NTA')}
          {comments?.length ? (
            <div
              style={{ fontSize: 12, marginTop: 5 }}
              className="span-link"
              onClick={() => {
                Modal.info({
                  title: t('Contact Items From NTA'),
                  width: 700,
                  content: <div dangerouslySetInnerHTML={{ __html: createNtaCommentsHtml(comments) }} style={{ maxHeight: 600, overflow: 'auto' }} />,
                });
              }}
            >
              <InfoCircleOutlined style={{ marginRight: 5 }} />
              {t('Show previous comments')}
            </div>
          ) : null}
        </Col>
        <Col xs={24} flex="auto">
          <Form.Item name={[fieldData.name, 'ntaComments']}>
            <Input.TextArea disabled={isUnTour} className="input-value-color" rows={5} />
          </Form.Item>
        </Col>
      </Row>

      {tourGuide && (
        <Row gutter={gutter}>
          <Col flex={'175px'} xs={24} className="form-item-label">
            {t('Notes for Guides')}
          </Col>
          <Col xs={24} flex="auto">
            <Input.TextArea disabled={true} className="input-value-color" value={tourGuide.guideComments} rows={5} />
          </Col>
        </Row>
      )}
      {/* <Row gutter={gutter} style={{ marginTop: 5 }}>
        <Col flex={'175px'} xs={24} className="form-item-label">
          {t('tourDetail_TourSeisanAmountCarrying')}
        </Col>
        <Col xs={24} flex="auto">
          <InputNumber disabled={true} className="input-value-color" value={amountCarrying} />
        </Col>
      </Row> */}
      <div style={{ marginTop: 10, textAlign: 'end' }}>
        <Space>
          {tour && tourGuide && tourGuide.isRequested && showGuideEvaluation && tourService.displayFeedbackBtn(tourGuide.status) ? (
            <Button
              onClick={() => {
                showGuideEvaluation({
                  guideId: tourGuide.id || '',
                });
              }}
              icon={<PlusOutlined />}
              style={{
                border: 'none',
                backgroundColor: '#FFBDE9',
                color: 'white',
              }}
            >
              {t('feedback')}
            </Button>
          ) : null}
          <GuideSeisanButton tour={tour} tourGuide={tourGuide} />
        </Space>
      </div>
    </>
  );
};
