import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Spin, Table } from 'antd';
import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { GuideSeisanStatus } from '@/constants';
import { ColumnsType } from 'antd/lib/table';
import BooleanTag from '@/components/app/BooleanTag';
import { formatDisplayFullDate, scrollableModalBodyStyle } from '@/config';
import { ApiSdk } from '@/httpclient';
import { GuideSeisanDetailModel, GuideSeisanFileModel } from '@/__generated';
import guideSeisanService from '@/services/guideSeisanService';
import NTADatePicker from '@/components/DatePicker';
import SeisanFileSubmittedTag from '@/components/app/SeisanFileSubmittedTag';
type Props = {
  onClose: (reload?: boolean) => void;
  tourGuideId: string;
};

export default function ReadOnlySeisan({ onClose, tourGuideId }: Props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(true);
  const [guideSeisan, setGuideSeisan] = React.useState<GuideSeisanDetailModel>();
  const [guideSeisanFiles, setGuideSeisanFiles] = React.useState<GuideSeisanFileModel[]>([]);

  const guideSeisanDetails = () => {
    if (tourGuideId) {
      ApiSdk.GuideSeisanService.guideSeisanDetails({ tourGuideId: tourGuideId })
        .then(res => {
          if (res) {
            setGuideSeisan(res);
          } else {
            Modal.error({ title: t('tourDetail_TourSeisanNotExist') });
            onClose();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const getGuideSeisanFiles = () => {
    ApiSdk.GuideSeisanService.getGuideSeisanFiles({ tourGuideId: tourGuideId }).then(res => {
      setGuideSeisanFiles(res);
    });
  };
  
  React.useEffect(() => {
    if (tourGuideId) {
      guideSeisanDetails();
      getGuideSeisanFiles();
    }
  }, [tourGuideId]);

  React.useEffect(() => {
    form.setFieldsValue(guideSeisan);
  }, [guideSeisan]);

  const tableCols: ColumnsType<any> = React.useMemo(() => {
    let cols = [
      { title: t('tourDetail_TourSeisanFileName'), dataIndex: 'fileName', ellipsis: true },
      { title: t('tourDetail_TourSeisanUploadedBy'), dataIndex: 'createdBy', width: 130 },
      { title: t('tourDetail_TourSeisanUploadedTime'), dataIndex: 'createdAt', width: 160, render: v => formatDisplayFullDate(v) },
      { title: t('guideSeisan_IsSubmitted'), dataIndex: 'submittedStatus', width: 130, render: v => <SeisanFileSubmittedTag status={v} /> },
      { title: t('tourDetail_TourSeisanDownloaded'), dataIndex: 'downloaded', width: 130, align: 'center', render: v => <BooleanTag value={v} /> },
      {
        title: t('tourDetail_TourSeisanDownload'),
        dataIndex: 'id',
        width: 120,
        align: 'center',
        render: (id, { fileName }: any) => (
          <Button
            onClick={() => {
              guideSeisanService.downloadSeisanFile(id, fileName);
            }}
            size="small"
            icon={<DownloadOutlined />}
          />
        ),
      },
    ];

    return cols as any;
  }, [guideSeisan]);

  return (
    <Modal
      className="nta-custom-form"
      bodyStyle={scrollableModalBodyStyle}
      visible={true}
      onCancel={() => onClose()}
      style={{ top: 10, maxWidth: 1200 }}
      width={'100%'}
      title="ガイド精算"
      cancelText={t('Close')}
      okButtonProps={{ style: { display: 'none' } }}
      maskClosable={false}
    >
      <Spin spinning={loading}>
        <Form layout="horizontal" form={form} colon={false} style={{ marginRight: 40 }}>
          <Row gutter={6}>
            <Col md={12}>
              <Form.Item labelCol={{ md: 8 }} labelAlign="right" label={t('tourDetail_GuideSeisanStatus')} name="status">
                <Select options={GuideSeisanStatus.__getSelectOptions()} disabled={true} className="input-value-color" />
              </Form.Item>
              <Form.Item labelCol={{ md: 8 }} labelAlign="right" label={t('tourMessagePage_TourName')} name="tourName">
                <Input disabled className="input-value-color" />
              </Form.Item>
              <Form.Item
                labelCol={{ md: 8 }}
                labelAlign="right"
                label={t('tourDetail_TourNoTourSeisan')}
                name="seisanNumber"
                className="input-value-color"
              >
                <Input disabled className="input-value-color" />
              </Form.Item>

              <Form.Item labelCol={{ md: 8 }} labelAlign="right" label={t('tourDetail_TourSeisanAmountCarrying')} name="amountCarrying">
                <InputNumber disabled className="input-value-color w-100" />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item labelCol={{ md: 7 }} label={t('tourDetail_TourSeisanGuideRemark')} name="guideRemark">
                <Input.TextArea rows={5} disabled className="input-value-color w-100" />
              </Form.Item>
            </Col>

            <Col md={24} style={{ marginBottom: 20 }}>
              <Row gutter={6}>
                <Col md={4} style={{ textAlign: 'right', paddingRight: 5 }}>
                  {t('tourDetail_TourSeisanAttachment')}
                </Col>
                <Col md={20}>
                  <Table size="small" pagination={false} bordered columns={tableCols} dataSource={guideSeisanFiles} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col md={24} xs={24}>
              <Form.Item labelCol={{ md: 4 }} labelAlign="right" label={t('tourDetail_TourSeisanNTARemark')} name="ntaRemark">
                <Input.TextArea disabled className="input-value-color w-100" placeholder="" rows={5} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item labelCol={{ md: 12 }} labelAlign="right" label={t('tourDetail_TourSeisanPaymentTaxWithoutAmount')} name="taxWithheldAmount">
                <Input disabled className="input-value-color w-100" addonAfter="JPY" />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item labelCol={{ md: 12 }} labelAlign="right" label={t('tourDetail_TourSeisanPaymentPaymentCost')} name="paymentCost">
                <Input disabled className="input-value-color w-100" addonAfter="JPY" />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item labelCol={{ md: 12 }} labelAlign="right" label={t('tourDetail_TourSeisanPaymentDueDate')} name="paymentDueDate">
                <NTADatePicker disabled className="input-value-color w-100" placeholder="" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}
