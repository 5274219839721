import React, { useEffect, useCallback } from 'react';
import { useAppContext } from '@/contexts/AppContext';
import { Form, Row, Input, Col, Select, message, Modal, Radio, Spin } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import FullPageLoading from '@/components/FullPageLoading';

import DatePicker from '@/components/DatePicker';
import { SubdivisionModel, DepartmentModel } from '@/__generated';
import { ApiSdk } from '../../httpclient';
import {
  VALIDATE_REQUIRED,
  VALIDATE_EMAIL,
  RoleList,
  ViewingPermissionList,
  RoleSupport,
  ERROR_EXCEPTION,
  RegularEnglish,
  PhoneNumberRegex,
  KATAKANA_REGEX,
  AccountingRoleType,
  AccountingRoleList,
  KANJI_REGEX,
} from '../../constants';
import './style.less';
import moment, { Moment } from 'moment';
import PhoneNumberInput from '@/components/app/formItem/PhoneNumberInput';
import { formatDate } from '@/config';

interface Props {
  visible: boolean;
  onClose: () => void;
}

export default function StaffProfile({ visible, onClose }: Props) {
  const { auth } = useAppContext();
  const [t] = useTranslation();
  const [loading, setLoading] = React.useState(false);

  const [departments, setDepartments] = React.useState<DepartmentModel[]>([]);
  const [subdivisionModal, setSubdivisionModal] = React.useState<SubdivisionModel[]>([]);

  const [form] = Form.useForm();

  const getDepartments = useCallback(async () => {
    const res = await ApiSdk.DepartmentService.getDepartments({ pageSize: 500 });
    setDepartments(res.data || []);
  }, []);

  const getSubdivisionModal = useCallback(async departmentId => {
    const res = await ApiSdk.SubdivisionService.getSubdivisions({ pageSize: 1000, departmentId: departmentId });
    setSubdivisionModal(res.data || []);
  }, []);

  const getInformationStaff = useCallback(async () => {
    if (auth?.staffId) {
      setLoading(true);
      const staffDetail = await ApiSdk.StaffService.detail({ id: auth.staffId });
      getSubdivisionModal(staffDetail?.departmentId);
      const accountingRoles = [AccountingRoleType.ReferenceAccounting, AccountingRoleType.RegularAccounting, AccountingRoleType.SuperAccounting];
      const usagePermissionCustom = staffDetail.usagePermission?.find(x => !accountingRoles.some(acc => acc === x));
      const dataEdit = {
        ...staffDetail,
        accountID: staffDetail.accountId,
        firstNameKanji: staffDetail.firstNameKJ,
        firstNameKatakana: staffDetail.firstNameKK,
        firstNameEnglish: staffDetail.firstNameEN,
        lastNameKanji: staffDetail.lastNameKJ,
        lastNameKatakana: staffDetail.lastNameKK,
        lastNameEnglish: staffDetail.lastNameEN,
        subDivisionId: staffDetail.subdivisionId,
        usagePermissionCustom: usagePermissionCustom,
        approvalPermissionCustom: staffDetail.approvalPermission ? 1 : 2,
        usageStatusCustom: staffDetail.usageStatus ? 1 : 2,
        isUserSupport: staffDetail.usagePermission?.includes(RoleSupport),
        accountingPermission: staffDetail.usagePermission?.find(x => accountingRoles.some(acc => acc === x)) || 'None',
      };
      form.setFieldsValue(dataEdit);
      setLoading(false);
    }
  }, [auth, form, getSubdivisionModal]);

  useEffect(() => {
    getDepartments();
    getInformationStaff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeSelectDepartment = async event => {
    await getSubdivisionModal(event);
  };

  const saveStaff = values => {
    setLoading(true);
    const dataPost = {
      ...values,
      usagePermission: form.getFieldValue('usagePermission'),
      approvalPermission: form.getFieldValue('approvalPermission'),
      usageStatus: values.usageStatusCustom === 1,
      viewingPermission: form.getFieldValue('viewingPermission'),
      emergencyContactAddress: values.emergencyContactAddress ?? null,
      numberOfTheHandler: values.numberOfTheHandler ?? null,
      terminationDate: values.terminationDate ? formatDate(values.terminationDate) : undefined,
    };

    ApiSdk.StaffService.update({ body: dataPost })
      .then(res => {
        //
        if (res?.success) {
          message.success(t('save_success'));
          onClose();
        } else {
          message.error(t(res.message || ''));
        }
      })
      .catch(() => {
        message.error(ERROR_EXCEPTION);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onTerminationDateChange = (date: Moment | null) => {
    if (date) {
      if (date.endOf('day').isAfter(moment())) {
        form.setFieldsValue({
          usageStatusCustom: 1,
        });
      } else {
        form.setFieldsValue({
          usageStatusCustom: 2,
        });
      }
    }
  };

  return (
    <Modal
      className="nta-custom-form modal-create-guide-search responsive-modal"
      visible={visible}
      maskClosable={false}
      width={500}
      style={{ top: 10 }}
      title={'マイページ'}
      forceRender={true}
      okText={t('Save')}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            saveStaff(values);
          })
          .catch(err => {
            if (err?.errorFields.length) {
              const fieldsName = err.errorFields[0].name;
              form.scrollToField(fieldsName, { behavior: 'smooth' });
            }
          });
      }}
      okButtonProps={{ icon: <SaveOutlined />, className: 'save-btn', loading: loading }}
    >
      <Spin spinning={loading}>
        <Form form={form} size="middle" layout="vertical" onFinish={saveStaff}>
          <Form.Item name="id" className="hidden">
            <Input type="hidden" />
          </Form.Item>
          <Row gutter={6}>
            <Col md={12} xs={12}>
              <Form.Item label={t('staffSearch_accountId')} name="accountID" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={12} />
            <Col md={12} xs={12}>
              <Form.Item
                label={t('staffSearch_lastNameKanji')}
                name="lastNameKJ"
                rules={[
                  { required: true, message: VALIDATE_REQUIRED },
                  {
                    pattern: KANJI_REGEX,
                    message: t('staffSearch_validateJanji'),
                  },
                ]}
              >
                <Input maxLength={10} />
              </Form.Item>
            </Col>
            <Col md={12} xs={12}>
              <Form.Item
                label={t('staffSearch_firstNameKanji')}
                name="firstNameKJ"
                rules={[
                  { required: true, message: VALIDATE_REQUIRED },
                  {
                    pattern: KANJI_REGEX,
                    message: t('staffSearch_validateJanji'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={12}>
              <Form.Item
                label={t('staffSearch_lastNameKatagana')}
                name="lastNameKK"
                rules={[
                  { required: true, message: VALIDATE_REQUIRED },
                  {
                    pattern: KATAKANA_REGEX,
                    message: t('staffSearch_validateKatagana'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={12}>
              <Form.Item
                label={t('staffSearch_firstNameKatagana')}
                name="firstNameKK"
                rules={[
                  { required: true, message: VALIDATE_REQUIRED },
                  {
                    pattern: KATAKANA_REGEX,
                    message: t('staffSearch_validateKatagana'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={12} xs={12}>
              <Form.Item
                label={t('staffSearch_lastNameEnglish')}
                name="lastNameEN"
                rules={[
                  { required: true, message: VALIDATE_REQUIRED },
                  {
                    pattern: RegularEnglish,
                    message: t('staffSearch_validationEnglish'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={12}>
              <Form.Item
                label={t('staffSearch_firstNameEnglish')}
                name="firstNameEN"
                rules={[
                  { required: true, message: VALIDATE_REQUIRED },
                  {
                    pattern: RegularEnglish,
                    message: t('staffSearch_validationEnglish'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={12}>
              <Form.Item
                label={t('staffSearch_email')}
                name="emailAddress"
                rules={[
                  { required: true, message: VALIDATE_REQUIRED },
                  { type: 'email', message: VALIDATE_EMAIL },
                ]}
              >
                <Input type="email" />
              </Form.Item>
            </Col>
            <Col md={12} xs={12}>
              <Form.Item
                label={t('staffSearch_numberOfTheHandler')}
                name="numberOfTheHandler"
                rules={[
                  {
                    pattern: /^[0-9a-zA-Z]{1,2}$/,
                    message: '英数字を入力してください',
                  },
                ]}
              >
                <Input maxLength={2} />
              </Form.Item>
            </Col>
            <Col md={12} xs={12}>
              <Form.Item
                name="mobilePhoneNumber"
                label={t('staffSearch_phoneNumber')}
                rules={[{ pattern: PhoneNumberRegex, message: t('validatorNumber') }]}
              >
                <PhoneNumberInput placeholder={t('staffSearch_phoneNumber')} />
              </Form.Item>
            </Col>
            <Col md={12} xs={12}>
              <Form.Item
                name="emergencyContactAddress"
                label={t('staffSearch_emergencyContactAddress')}
                rules={[{ pattern: PhoneNumberRegex, message: t('validatorNumber') }]}
              >
                <PhoneNumberInput placeholder={t('staffSearch_emergencyContactAddress')} />
              </Form.Item>
            </Col>
            <Col md={12} xs={12}>
              <Form.Item label={t('staffSearch_department')} name="departmentId" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                <Select onChange={changeSelectDepartment}>
                  {departments.map(department => (
                    <Select.Option key={department.id} value={department.id || ''}>
                      {department.departmentName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={12}>
              <Form.Item label={t('staffSearch_subdivision')} name="subDivisionId" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                <Select>
                  {subdivisionModal.map(subdivision => (
                    <Select.Option key={subdivision.id} value={subdivision.id || ''}>
                      {subdivision.subdivisionName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={6}>
            <Col md={6} xs={6} style={{ paddingTop: 5 }}>
              {t('staffSearch_userPermission')}
            </Col>
            <Col md={18} xs={18} style={{ fontWeight: 'bold' }}>
              <Form.Item style={{ marginBottom: 0 }}>
                {RoleList.find(role => role.value === form.getFieldValue('usagePermissionCustom'))?.label}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col md={6} xs={6} style={{ paddingTop: 5 }}>
              {t('staffSearch_isUserSupport')}
            </Col>
            <Col md={18} xs={18} style={{ fontWeight: 'bold' }}>
              <Form.Item style={{ marginBottom: 0 }}>{form.getFieldValue('isUserSupport') ? t('staffSearch_yes') : t('staffSearch_no')}</Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col md={6} xs={6} style={{ paddingTop: 5 }}>
              経理権限
            </Col>
            <Col md={18} xs={18} style={{ fontWeight: 'bold' }}>
              <Form.Item style={{ marginBottom: 0 }}>
                {AccountingRoleList.find(role => role.value === form.getFieldValue('accountingPermission'))?.label || 'None'}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col md={6} xs={6} style={{ paddingTop: 5 }}>
              {t('staffSearch_approvalPermission')}
            </Col>
            <Col md={18} xs={18} style={{ fontWeight: 'bold' }}>
              <Form.Item style={{ marginBottom: 0 }}>
                {form.getFieldValue('approvalPermissionCustom') === 1 ? t('staffSearch_yes') : t('staffSearch_no')}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col md={6} xs={6} style={{ paddingTop: 5 }}>
              {t('staffSearch_viewingPermission')}
            </Col>
            <Col md={18} xs={18} style={{ fontWeight: 'bold' }}>
              <Form.Item style={{ marginBottom: 0 }}>
                {ViewingPermissionList.find(viewingPermission => viewingPermission.value === form.getFieldValue('viewingPermission'))?.label}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col md={6} xs={6}>
              {t('staffSearch_terminationDate')}
            </Col>
            <Col md={12} xs={12}>
              <Form.Item name="terminationDate">
                <DatePicker
                  className="w-100 input-value-color"
                  onChange={d => {
                    onTerminationDateChange(d);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col md={6} xs={6} style={{ paddingTop: 5 }}>
              {t('staffSearch_usageStatus')}
            </Col>
            <Col md={18} xs={18} style={{ fontWeight: 'bold' }}>
              <Form.Item style={{ marginBottom: 0 }} name="usageStatusCustom">
                <Radio.Group disabled>
                  <Radio value={1}>
                    <span className="input-value-color">有効</span>
                  </Radio>
                  <Radio value={2}>
                    <span className="input-value-color">無効</span>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}
