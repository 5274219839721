import { ApiSdk } from '@/httpclient';
import { GuideModel } from '@/__generated';
import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GuideInfo from '@/components/GuideInfo';

type Props = {
  gid: string;
  visible: boolean;
  onClose: (guide?: any) => void;
  isGuidePicker?: boolean;
};

export default function GuideInfoModal({ gid, visible, onClose, isGuidePicker }: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [guide, setGuide] = React.useState<GuideModel>();

  React.useEffect(() => {
    setLoading(true);
    ApiSdk.GuideService.getById({
      id: gid,
    })
      .then(res => {
        if (res) {
          setGuide(res);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [gid]);

  return (
    <Modal
      className="nta-custom-form create-tour-form"
      bodyStyle={{ height: 'calc(100vh - 150px)', overflow: 'auto', padding: 0 }}
      width={1000}
      style={{ top: 10 }}
      visible={visible}
      title={'Guide Info'}
      onOk={() => {
        if (isGuidePicker) {
          onClose(guide);
        } else {
          onClose();
        }
      }}
      onCancel={() => {
        onClose();
      }}
      okText={isGuidePicker ? t('Select Guide') : t('Close')}
      cancelButtonProps={{ style: { display: 'none' } }}
      maskClosable={false}
    >
      <GuideInfo guide={guide} loading={loading} />
    </Modal>
  );
}
