import { Collapse } from 'antd';
import React from 'react';

type Props = {
  children?: any;
};
const isMobile = window.innerWidth < 800;

export default function SearchableInMobile({ children }: Props) {
  if (!isMobile) {
    return children;
  }
  return (
    <Collapse style={{ marginBottom: 10 }}>
      <Collapse.Panel key={'1'} header="検索オプション">
        {children}
      </Collapse.Panel>
    </Collapse>
  );
}
