import { useEffect, useContext } from 'react';
import { AppContext, createAppUser } from '../contexts/AppContext';
import { useHistory } from 'react-router';
import { ApiSdk } from '../httpclient';
import authService from '@/services/authService';
import { HIDE_MESSAGE_401_PARAMS } from '@/config';

const listRouteNotProtect = ['/cms/login', '/cms/change-password', '/cms/forgot-password', '/cms/reset-password', '/cms/password-expired'];
const getCallBackUrl = () => {
  const { pathname, search } = window.location;
  return encodeURI(`${pathname}${search}`);
};

const useAuth = () => {
  const context = useContext(AppContext);
  const history = useHistory();
  const { pathname } = window.location;

  const getCurrentUser = () => {
    ApiSdk.UserService.currentUser({ params: { [HIDE_MESSAGE_401_PARAMS]: true } })
      .then(auth => {
        if (auth) {
          context.onUpdateContext({
            auth: createAppUser(auth),
            authLoaded: true,
          });
          authService.logUserInfo2Sentry(auth.id, auth.email, auth.username);
        } else {
          authService.clearOnLogout();
          context.onUpdateContext({
            auth: null,
            authLoaded: true,
          });
          if (
            !listRouteNotProtect.includes(pathname) &&
            pathname.indexOf('/cms/reset-password') === -1 &&
            pathname.indexOf('/cms/set-password') === -1
          ) {
            history.push(`/cms/login?callback=${getCallBackUrl()}`);
          }
        }
      })
      .catch(error => {
        authService.clearOnLogout();
        context.onResetContext();
        if (
          !listRouteNotProtect.includes(pathname) &&
          pathname.indexOf('/cms/reset-password') === -1 &&
          pathname.indexOf('/cms/set-password') === -1
        ) {
          history.push(`/cms/login?callback=${getCallBackUrl()}`);
        }
      });
  };

  useEffect(() => {
    const tokenStr = authService.getAccessToken();
    if (tokenStr) {
      getCurrentUser();
    } else {
      context.onUpdateContext({
        auth: null,
        authLoaded: true,
      });
      if (!listRouteNotProtect.includes(pathname) && pathname.indexOf('/cms/reset-password') === -1 && pathname.indexOf('/cms/set-password') === -1) {
        history.push(`/cms/login?callback=${getCallBackUrl()}`);
      }
    }
  }, []); // run one time
  return true;
};

export default useAuth;
