import { GuideListingAvailabilityResponse } from '@/__generated';
import { Typography } from 'antd';
import lodash from 'lodash';
import React from 'react';

type Props = {
  guide: GuideListingAvailabilityResponse;
};
const { Text } = Typography;

function GuideLanguageCell({ guide }: Props) {
  const languages = lodash.orderBy(guide.supportedLanguages || [], (x, i) => (x.isMain ? -1 : i));
  const text = languages
    ?.map(lang => {
      const name = `${lang.isMain ? '*' : ''}${lang.name}`;
      if (lang.hasLicense) {
        return `<div >${name}</div>`;
      } else {
        return `<div style="color:red">${name}</div>`;
      }
    })
    .join('');
  return (
    <Text style={{ width: 100 }} ellipsis={{ tooltip: <span dangerouslySetInnerHTML={{ __html: text || '' }} /> }}>
      {languages.map((lang, i) => {
        let name = `${lang.isMain ? '*' : ''}${lang.name}`;
        if (i !== (languages.length || 0) - 1) {
          name += ', ';
        }
        return (
          <Text key={i} style={{ color: !lang.hasLicense ? 'red' : '' }}>
            {name}
          </Text>
        );
      })}
    </Text>
  );
}
export default React.memo(GuideLanguageCell, (prevProps, props) => {
  return prevProps?.guide.id === props?.guide?.id;
});
