const appThemeKey = 'app-theme';

export const saveTheme = (theme: any = {}) => {
  localStorage.setItem(appThemeKey, JSON.stringify(theme));
};
export const getTheme = () => {
  try {
    let theme = JSON.parse(localStorage.getItem(appThemeKey) || '');
    if (theme) {
      return {
        topMenu: window.innerWidth < 1200 ? false : theme.topMenu,
        rtl: theme.rtl,
        darkMode: theme.darkMode,
      };
    }
    return null;
  } catch (error) {
    return null;
  }
};
