import { HistoryOutlined, InfoOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React from 'react';
import Helmet from 'react-helmet';
import MainContent from '../../Layout/MainContent';
import moment from 'moment';
import ZTable, { IColumnType, IFilterItem } from '@/components/ZTable';
import { useTranslation } from 'react-i18next';
import { formatDisplayFullDate } from '@/config';
const PAGE_TITLE = 'Email Messages';

const filters: Array<IFilterItem> = [
  { type: 'TEXT', name: 'subject', label: 'Subject' },
  { type: 'TEXT', name: 'toEmail', label: 'To email' },
  { type: 'TEXT', name: 'referenceId', label: 'Reference Id' },
];
export default () => {
  const { t, i18n } = useTranslation();
  const columns: IColumnType<any>[] = React.useMemo(() => {
    return [
      {
        title: t('Reference Id'),
        dataIndex: 'referenceId',
        ellipsis: true,
        width: 230,
      },
      {
        title: 'Subject',
        dataIndex: 'subject',
        ellipsis: true,
        width: 300,
      },
      {
        title: t('To email'),
        dataIndex: 'toEmails',
        ellipsis: true,
        width: 200,
      },
      {
        title: t('From email'),
        dataIndex: 'fromName',
        render: (v, email) => `${v} - ${email.fromAddress}`,
        width: 250,
        ellipsis: true,
      },

      {
        title: t('Error'),
        dataIndex: 'error',
        align: 'center',
        width: 100,
        render: v => {
          return v ? (
            <Button
              danger
              size="small"
              shape="circle"
              icon={<InfoOutlined />}
              onClick={() => {
                Modal.info({
                  title: t('Error'),
                  content: <div dangerouslySetInnerHTML={{ __html: v }} />,
                  width: 700,
                });
              }}
            ></Button>
          ) : null;
        },
      },
      {
        title: t('Created On'),
        dataIndex: 'createdOnUtc',
        render: formatDisplayFullDate,
        width: 140,
      },
      {
        title: t('Sent On'),
        dataIndex: 'sentOnUtc',
        render: formatDisplayFullDate,
        width: 140,
      },

      {
        width: 120,
        align: 'center',
        title: t('Email content'),
        dataIndex: 'bodyContent',
        render(bodyContent: any) {
          return (
            <Button
              size="small"
              shape="circle"
              icon={<InfoOutlined />}
              onClick={() => {
                Modal.info({
                  title: t('Email content'),
                  content: <div dangerouslySetInnerHTML={{ __html: bodyContent }} />,
                  width: 700,
                });
              }}
            ></Button>
          );
        },
      },
    ] as IColumnType<any>[];
  }, [i18n.language]);

  return (
    <MainContent title={t(PAGE_TITLE)} icon={<HistoryOutlined style={{ fontSize: 16 }} />}>
      <Helmet title={t(PAGE_TITLE)} />
      <div className="ant-layout-content-body">
        <ZTable<any>
          columns={columns}
          primaryKey="id"
          url="/email-messages/get-email-messages"
          bordered
          order_by="createdOnUtc"
          order_by_asc={false}
          allowEdit={false}
          allowDelete={false}
          filters={filters}
        />
      </div>
    </MainContent>
  );
};
