import React from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { DatePicker, DatePickerProps } from 'antd';
import localeJA from 'antd/es/date-picker/locale/ja_JP';
import localeEN from 'antd/es/date-picker/locale/en_US';
import { DEFAULT_DISPLAY_DATE_FORMAT } from '@/constants';

type DatePickerPropNTA = {
  lessThanToday?: boolean;
} & DatePickerProps;

export default function NTADatePicker({ value, lessThanToday, onChange, ...props }: DatePickerPropNTA) {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const onDateChange = v => {
    if (onChange) {
      onChange(v ? moment(v).endOf('day') : null, '');
    }
  };
  if (value && typeof value === 'string') {
    value = moment(value).endOf('day');
  }
  const locale = React.useMemo(() => {
    if (i18n.language === 'en') {
      return localeEN;
    }
    return {
      ...localeJA,
      lang: {
        ...localeJA.lang,
        shortMonths: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        shortWeekDays: ['日', '月', '火', '水', '木', '金', '土'],
      },
    };
  }, [i18n.language]);

  const otherConfig = React.useMemo(() => {
    if (lessThanToday) {
      return {
        disabledDate: (date: Moment) => date.startOf('day').isAfter(moment()),
      };
    }
    return {};
  }, [lessThanToday]);
  return (
    <DatePicker
      format={open ? 'YYYYMMDD' : DEFAULT_DISPLAY_DATE_FORMAT}
      placeholder={t('Select date')}
      {...(props as any)}
      value={value}
      locale={locale}
      inputReadOnly={false}
      showTime={false}
      onChange={onDateChange}
      onOpenChange={_open => setOpen(_open)}
      autoComplete="off"
      {...otherConfig}
    />
  );
}
