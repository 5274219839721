import { ApiSdk } from '@/httpclient';
import React from 'react';
import { AutoComplete, AutoCompleteProps, FormInstance } from 'antd';
import { debounce } from 'lodash';
const toLabel = (firstName: string, lastName: string) => `${lastName} ${firstName}`;
interface Props extends AutoCompleteProps {
  form: FormInstance<any>;
  isIncludeDisabledGuide?: boolean;
}
export default function GuideAutoComplete({ form, value: originValue, isIncludeDisabledGuide = true, className, onChange, ...props }: Props) {
  const [value, setValue] = React.useState<string>('');
  const [guides, setGuides] = React.useState<{ label: string; value: string }[]>([]);
  const [isTextValue, setIsTextValue] = React.useState<boolean>(true);

  React.useEffect(() => {
    getGuides();
  }, [value]);

  const _getGuides = () => {
    ApiSdk.GuideService.guideAutocompletion({ isIncludeDisabledGuide, query: value || undefined, orderBy: 'lastNameKanji' }).then(res => {
      setGuides(
        res.map(g => ({
          label: toLabel(g.firstNameKanji || '', g.lastNameKanji || ''),
          value: g.id || '',
        })),
      );
    });
  };
  const getGuides = debounce(_getGuides, 300);

  React.useEffect(() => {
    if (!originValue) {
      setValue('');
    }
  }, [originValue]);
  React.useEffect(() => {
    if (onChange) {
      onChange(value, {} as any);
    }
  }, [value]);

  const fieldClassName = React.useMemo(() => {
    if (isTextValue) {
      return `nta-autocomplete is-text-value ${className || ''}`;
    }
    return `nta-autocomplete ${className || ''}`;
  }, [className, isTextValue]);
  return (
    <AutoComplete
      allowClear={true}
      value={value}
      onSearch={v => {
        setValue(v);
        setIsTextValue(true);
        form.setFieldsValue({
          guideName: v,
          guideId: undefined,
        });
      }}
      onSelect={(v, op) => {
        setValue(op.label as any);
        setIsTextValue(false);
        form.setFieldsValue({
          guideName: undefined,
          guideId: v,
        });
      }}
      {...props}
      options={guides}
      className={fieldClassName}
    />
  );
}
