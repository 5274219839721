import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { APP_ROLE } from './services/roleService';

export interface MenuItemProps {
  title: string;
  icon?: React.ReactElement;
  subMenu?: MenuItemProps[];
  to?: string;
  key: string;
  roles?: APP_ROLE[] | '*';
}
export interface MenuGroupProps {
  groupName: string;
  items: MenuItemProps[];
}
const ROLES_ADMIN = [APP_ROLE.SuperAdmin, APP_ROLE.Admin];
const ROLES_STAFF = [APP_ROLE.ReferenceUser, APP_ROLE.RegularUser, APP_ROLE.SupervisorUser, APP_ROLE.Assign];

const AppMenu: MenuGroupProps[] = [
  {
    groupName: 'menu.MainMenu',
    items: [
      // {
      //   icon: <FeatherIcon icon="home" />,
      //   title: 'menu.Dashboard',
      //   key: 'dashboard',
      //   to: '/cms/dashboard',
      //   roles: [APP_ROLE.Admin, APP_ROLE.SupervisorUser, APP_ROLE.RegularUser, APP_ROLE.ReferenceUser, APP_ROLE.Assign],
      // },

      {
        title: 'menu.Tours',
        to: '/cms/tours',
        icon: <FeatherIcon icon="award" />,
        key: 'tours',
        roles: '*',
      },
      {
        title: 'menu.TourFeedbacks',
        to: '/cms/tour-feedbacks',
        icon: <FeatherIcon icon="edit" />,
        key: 'tour-feedbacks',
        roles: [...ROLES_ADMIN, ...ROLES_STAFF],
      },
      {
        title: 'menu.Guides',
        to: '/cms/guides-schedule',
        icon: <FeatherIcon icon="hash" />,
        key: 'guides-schedule',
        roles: [...ROLES_ADMIN, ...ROLES_STAFF],
      },
      {
        title: 'menu.Calendars',
        to: '/cms/calendars',
        icon: <FeatherIcon icon="calendar" />,
        key: 'calendars',
        roles: '*',
      },
      {
        title: 'menu.TourMessages',
        to: '/cms/tour-messages',
        icon: <FeatherIcon icon="send" />,
        key: 'tour-messages',
        roles: '*',
      },
      {
        title: 'menu.GuideSeisans',
        to: '/cms/guide-seisans',
        icon: <FeatherIcon icon="hexagon" />,
        key: 'guide-seisans',
        roles: '*',
      },
      {
        title: 'menu.GuideSearch',
        to: '/cms/guides-profile',
        icon: <FeatherIcon icon="book-open" />,
        key: 'guides-profile',
        roles: [...ROLES_ADMIN, ...ROLES_STAFF],
      },
      {
        title: 'menu.Notifications',
        to: '/cms/notifications',
        icon: <FeatherIcon icon="bell" />,
        key: 'notifications',
        roles: [...ROLES_ADMIN, ...ROLES_STAFF],
      },
      {
        icon: <FeatherIcon icon="user" />,
        to: '/cms/staffs',
        title: 'menu.Staffs',
        key: 'staffs',
        roles: [...ROLES_ADMIN, ...ROLES_STAFF],
      },
    ],
  },

  {
    groupName: 'menu.SystemSettings',
    items: [
      {
        title: 'menu.ApiAccessLogs',
        to: '/cms/api-access-logs',
        icon: <FeatherIcon icon="codepen" />,
        key: 'api-access-logs',
        roles: ROLES_ADMIN,
      },
      {
        title: 'Audit Logs',
        to: '/cms/audit-logs',
        icon: <FeatherIcon icon="codepen" />,
        key: 'audit-logs',
        roles: ROLES_ADMIN,
      },
      {
        title: 'menu.EmailMessages',
        to: '/cms/email-messages',
        icon: <FeatherIcon icon="send" />,
        key: 'email-messages',
        roles: ROLES_ADMIN,
      },
      {
        title: 'menu.EmailTemplates',
        icon: <FeatherIcon icon="mail" />,
        key: 'email-templates',
        to: '/cms/email-templates',
        roles: ROLES_ADMIN,
      },
      {
        icon: <FeatherIcon icon="layers" />,
        title: 'menu.MasterData',
        key: 'master-data',
        roles: ROLES_ADMIN,
        subMenu: [
          {
            title: 'menu.Facilities',
            key: 'facilities',
            to: '/cms/master-data/facilities',
            roles: ROLES_ADMIN,
          },
          {
            title: 'menu.Holidays',
            key: 'holidays',
            to: '/cms/master-data/holidays',
            roles: ROLES_ADMIN,
          },
          {
            title: 'menu.Countries',
            key: 'countries',
            to: '/cms/master-data/countries',
            roles: ROLES_ADMIN,
          },
          {
            title: 'menu.Languages',
            key: 'languages',
            to: '/cms/master-data/languages',
            roles: ROLES_ADMIN,
          },
          {
            title: 'menu.Department',
            key: 'department',
            to: '/cms/master-data/department',
            roles: ROLES_ADMIN,
          },
          {
            title: 'menu.Subdivision',
            key: 'subdivision',
            to: '/cms/master-data/subdivision',
            roles: ROLES_ADMIN,
          },
        ],
      },

      {
        title: 'menu.Users',
        to: '/cms/users',
        icon: <FeatherIcon icon="users" />,
        key: 'users',
        roles: [APP_ROLE.SuperAdmin],
      },
      {
        title: 'menu.SMTPSettings',
        icon: <FeatherIcon icon="settings" />,
        key: 'smtp-settings',
        to: '/cms/smtp-settings',
        roles: [APP_ROLE.SuperAdmin],
      },
    ],
  },
];
export default AppMenu;
