import MainContent from '@/Layout/MainContent';
import ZTable, { IFilterItem, ITableRef } from '@/components/ZTable';
import { RoleListTable, VALIDATE_REQUIRED } from '@/constants';
import { ApiSdk } from '@/httpclient';
import { KeyOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Form, Input, message, Modal, Row, Select } from 'antd';
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
const PAGE_TITLE = 'Manage User';

const filters: Array<IFilterItem> = [{ type: 'TEXT', name: 'name', label: '名前' }];

export default () => {
  const [t] = useTranslation();
  const zTb = React.useRef<ITableRef>();
  const [form] = Form.useForm();
  const [formVisible, setFormVisible] = useState(false);
  const [isNewForm, setIsNewForm] = useState(false);
  const [roles, setRoles] = useState<string[]>([]);

  React.useEffect(() => {
    ApiSdk.RoleService.getRoleNames().then(res => {
      setRoles(res);
    });
  }, []);
  const saveUser = values => {
    ApiSdk.RoleService.save({ body: values })
      .then(response => {
        if (response?.success) {
          setFormVisible(false);
          zTb.current?.reload();
        } else {
          if (response.message) {
            message.error(t(response.message || ''));
          }
        }
      })
      .catch(error => {
        message.error(error);
      });
  };

  const columns = [
    {
      title: '名前',
      dataIndex: 'name',
      render: text => {
        const dataText = RoleListTable.find(role => role.value === text);
        if (dataText) {
          return dataText.label;
        }
        return '';
      },
    },
  ];

  const getExtraButtons = () => [
    <Button
      key="create"
      type="primary"
      icon={<PlusCircleOutlined />}
      onClick={() => {
        form.resetFields();
        setIsNewForm(true);
        setFormVisible(true);
      }}
    >
      {t('guideSearch_new')}
    </Button>,
  ];

  return (
    <MainContent
      title={
        <Breadcrumb className="cms-breadcrumb">
          <Breadcrumb.Item>
            <a href="/users" style={{ fontSize: 20, fontWeight: 'bold' }}>
              {t('User')}
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <label style={{ fontSize: 20, fontWeight: 'bold' }}>{t('Role')}</label>
          </Breadcrumb.Item>
        </Breadcrumb>
      }
      extraBtns={getExtraButtons()}
      icon={<KeyOutlined style={{ fontSize: 16 }} />}
    >
      <Helmet title={PAGE_TITLE} />
      <div className="ant-layout-content-body">
        <ZTable
          columns={columns}
          primaryKey="id"
          tableRef={zTb}
          url="/roles/get-roles"
          bordered
          order_by="Name"
          order_by_asc={true}
          allowEdit={false}
          allowDelete={false}
          filters={filters}
        ></ZTable>
        <Modal
          width={300}
          visible={formVisible}
          maskClosable={false}
          title={isNewForm ? t('CreateNewRole') : t('UpdateNewRole')}
          forceRender={true}
          okText={t('staffSearch_save')}
          okButtonProps={{ icon: <SaveOutlined /> }}
          onCancel={() => {
            form.resetFields();
            setFormVisible(false);
          }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                saveUser(values);
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Form form={form} size="middle" layout="vertical">
            <Form.Item name="id" className="hidden">
              <Input type="hidden" />
            </Form.Item>
            <Row gutter={16}>
              <Col md={24}>
                <Form.Item label={t('NameRole')} name="name" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Select>
                    {roles.map(r => {
                      return (
                        <Select.Option value={r} key={r}>
                          {r}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </MainContent>
  );
};
