import React, { useState } from 'react';
import { LeftOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import './styles.less';
import { useHistory, useParams } from 'react-router';
import { ApiSdk } from '@/httpclient';
import FullPageLoading from '@/components/FullPageLoading';
import { PasswordRegex } from '@/config';
const bgImage = require('@/assets/images/appbg.jpeg');

export default () => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tokenStatus, setTokenStatus] = React.useState<0 | 1 | 2>(0); //0:loading,1:invalid,2 valid
  const { token } = useParams<{ token: string }>();
  const history = useHistory();

  React.useEffect(() => {
    setTokenStatus(0);
    ApiSdk.AuthService.checkForgotPasswordToken({
      token,
    })
      .then(res => {
        if (res) {
          setTokenStatus(2);
        } else {
          setTokenStatus(1);
        }
      })
      .catch(() => {
        setTokenStatus(1);
      });
  }, [token]);
  const resetPassword = (password: string) => {
    setLoading(true);
    ApiSdk.AuthService.resetPassword({
      body: {
        token,
        newPassword: password,
      },
    })
      .then(res => {
        if (res.success) {
          message.success(t('ResetPasswordSuccess'));
          history.push('/cms/login');
        } else {
          message.error(t(res.message || ''));
        }
      })

      .finally(() => {
        setLoading(false);
      });
  };

  if (tokenStatus === 0) {
    return <FullPageLoading loading={true} />;
  }

  return (
    <div
      className="container-login-form"
      style={{ minHeight: '100vh', backgroundImage: `url(${bgImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
    >
      <Card className="login-form">
        {tokenStatus === 1 ? (
          <div style={{ textAlign: 'center', padding: 50 }}>
            <div style={{ textAlign: 'center', fontSize: 20, color: '#f44336', marginBottom: 20 }}>{t('ForgotPasswordSessionExpired')}</div>
            <Button
              icon={<LeftOutlined />}
              type="ghost"
              onClick={() => {
                history.push('/cms/forgot-password');
              }}
            >
              {t('BackToChangePassword')}
            </Button>
          </div>
        ) : (
          <Form
            layout="vertical"
            name="normal_login"
            size="large"
            initialValues={{ remember: true }}
            onFinish={values => {
              resetPassword(values.password);
            }}
          >
            <h1 style={{ textAlign: 'center' }}>パスワードの再設定</h1>
            <h4 style={{ textAlign: 'center', color: '#666', marginTop: 10 }}>新しいパスワードを設定してください。</h4>
            <Form.Item
              name="password"
              label={t('NewPassword')}
              rules={[
                { required: true, message: t('staffSearch_passwordValidation') },
                { pattern: new RegExp(PasswordRegex), message: t('staffSearch_passwordValidation') },
              ]}
            >
              <Input.Password prefix={<LockOutlined />} className="w-100" />
            </Form.Item>
            <Form.Item
              name="repassword"
              label={t('ConfirmPassword')}
              rules={[
                { required: true, message: t('staffSearch_passwordValidation') },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t('staffPasswordNotMatch'));
                  },
                }),
              ]}
            >
              <Input.Password className="w-100" prefix={<LockOutlined />} />
            </Form.Item>
            <div style={{ color: '#E91E63' }}>※入力間違いを防ぐため、コピー、貼り付けはせずに入力してください。</div>
            <div style={{ marginTop: 10 }}>
              <b>***パスワード設定ルール***</b>
              <div>&bull;&nbsp; 8 文字以上</div>
              <div>&bull;&nbsp; 数字、英小文字、英大文字、およびスペース以外の記号を全て使用する</div>
              <div>&bull;&nbsp;メールアドレスを含まない</div>
            </div>
            <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
              <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                送信
              </Button>
            </div>
            <Button
              icon={<LeftOutlined />}
              type="dashed"
              onClick={() => {
                history.push('/cms/login');
              }}
            >
              {t('LoginBackButton')}
            </Button>
          </Form>
        )}
      </Card>
    </div>
  );
};
