import React, { useState } from 'react';
import { Button, Col, Form, Input, message, Modal, Row, Typography, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CopyOutlined, DeleteOutlined, PlusCircleOutlined, PlusOutlined, SaveOutlined, UserOutlined } from '@ant-design/icons';
import Helmet from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import ZTable, { IFilterItem, ITableRef } from '@/components/ZTable';
import MainContent from '@/Layout/MainContent';
import { DEFAULT_DATE_FORMAT, FullDateWithoutSecondFormat, VALIDATE_REQUIRED } from '../../constants';
import './style.less';
import DatePicker from '@/components/DatePicker';
import EnumSelect from '@/components/app/formItem/EnumSelect';
import TextArea from 'antd/es/input/TextArea';
import { ApiSdk } from '@/httpclient';
import { formatDisplayDate, NotificationStatus, SendEmailStatus } from '@/config';
import NotificationInfoModal from '@/pages/Notifications/NotificationInfoModal';
import { useHistory, useLocation } from 'react-router';
import { useAppContext } from '@/contexts/AppContext';
import { hasPermissionEditNotification, isGuideOnly } from '@/services/roleService';
import moment from 'moment';
import NotificationStatusDisable from '@/components/app/formItem/NotificationStatusDisable';
import lodash from 'lodash';
import FullPageLoading from '@/components/FullPageLoading';
import FileDownload from '@/components/app/FileDownload';
import { NotificationDto } from '@/__generated';
import { v4 as uuidv4 } from 'uuid';

type Props = {};

const getColumsbyRole = (roles, t, setNotificationInfoId) => {
  const getStatusColor = (status: any) => {
    switch (status) {
      case NotificationStatus.Before:
        return '#a7d971';
      case NotificationStatus.During:
        return '#add8e6';
      default:
        return '#eff0f3';
    }
  };

  const _columns: ColumnsType<NotificationDto> = [
    {
      title: 'No.',
      width: 40,
      align: 'center',
      render: (v, r, i) => {
        return <b>{i + 1}</b>;
      },
    },
    {
      title: t('notification_Result_Status'),
      dataIndex: 'status',
      width: 80,
      sorter: true,
      defaultSortOrder: 'ascend',
      render: v => {
        return <Tag color={getStatusColor(v)}>{<Trans>{NotificationStatus.__getValue(v)}</Trans>}</Tag>;
      },
    },
    {
      title: t('notification_Result_Post_Period'),
      width: 250,
      sorter: true,
      dataIndex: 'fromDate',
      defaultSortOrder: 'descend',
      render: (v, data) => {
        return `${formatDisplayDate(data.fromDate)} ~ ${formatDisplayDate(data.toDate)}`;
      },
    },
    {
      title: t('notification_Result_Subject'),
      dataIndex: 'subject',
      width: 200,
      sorter: true,
      ellipsis: true,
      render: (v, notificationInfo) => {
        return (
          <Tooltip title={v}>
            <Typography.Text
              style={{ color: '#5f63f2' }}
              ellipsis={true}
              className="span-link"
              onClick={() => {
                setNotificationInfoId(notificationInfo.id);
              }}
            >
              {v}
            </Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: t('notification_Result_Contents'),
      dataIndex: 'contents',
      width: 200,
      ellipsis: true,
      render: v => (
        <Tooltip title={v}>
          <Typography.Text ellipsis={true}>{v}</Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: t('notification_Result_Created_Date_And_Time'),
      dataIndex: 'createdAt',
      width: 120,
      render: (value: any) => (value ? moment(value).format(FullDateWithoutSecondFormat) : ''),
    },
    { title: t('notification_Result_Created_By'), dataIndex: 'createdByName', width: 150, render: (v, noti) => v || noti?.createdByUserName },
    {
      title: t('notification_Result_Last_Updated_Date'),
      dataIndex: 'updatedAt',
      width: 150,
      sorter: true,
      render: (value: any) => (value ? moment(value).format(FullDateWithoutSecondFormat) : ''),
    },
    {
      title: t('notification_Result_Last_Updated_By'),
      dataIndex: 'updatedByName',
      width: 100,
      ellipsis: true,
      render: (v, noti) => v || noti?.updatedByUserName,
    },
  ];
  return _columns;
};
let FormHasChange = false;

export default function Notifications({}: Props) {
  const [t] = useTranslation();
  const zTb = React.useRef<ITableRef>();
  const [form] = Form.useForm();
  const { search } = useLocation();
  const history = useHistory();
  const { auth } = useAppContext();
  const [formVisible, setFormVisible] = useState(false);
  const [notificationInfoId, setNotificationInfoId] = useState<string>();
  const [isNewForm, setIsNewForm] = useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = React.useState<{ id: string; name: string }[]>([]);
  const [uploadingFiles, setUploadingFiles] = React.useState<{ id: string; name: string }[]>([]);

  const refAttachFile = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    const query = new URLSearchParams(search);
    const notificationId = query.get('id');
    if (notificationId) {
      setNotificationInfoId(notificationId);
      history.replace('/cms/notifications');
    }
  }, [search]);

  const { columns, W } = React.useMemo(() => {
    let _columns = getColumsbyRole(auth?.roleClaims, t, setNotificationInfoId);
    return { columns: _columns, W: 120 + lodash.sumBy(_columns, t => t.width as any) };
  }, [t, auth]);

  const filters: IFilterItem[] = React.useMemo(() => {
    const filters: IFilterItem[] = [
      {
        type: 'DATE',
        name: 'fromDate',
        placeHolder: 'notification_Search_From',
        label: 'notification_Result_Post_Period',
        parseValue: v => (v ? v.format(DEFAULT_DATE_FORMAT) : undefined),
        mdSize: 6,
        lgSize: 6,
        xlSize: 5,
        xxlSize: 5,
      },
      {
        type: 'DATE',
        name: 'toDate',
        placeHolder: 'notification_Search_To',
        label: '',
        xlSize: 4,
        xxlSize: 4,
        parseValue: v => (v ? v.format(DEFAULT_DATE_FORMAT) : undefined),
      },
      isGuideOnly(auth?.roleClaims)
        ? {
            type: 'SELECT',
            name: 'status',
            label: 'notification_Search_Status',
            defaultValue: NotificationStatus.During,
            customElm: <NotificationStatusDisable value={NotificationStatus.During} disabled />,
            xlSize: 4,
            xxlSize: 4,
          }
        : {
            type: 'SELECT',
            name: 'status',
            label: 'notification_Search_Status',
            customElm: <EnumSelect enumData={NotificationStatus} placeholder={t('Status')} allowClear={true} mode="multiple" />,
            xlSize: 4,
            xxlSize: 4,
          },
      { type: 'TEXT', name: 'subject', label: 'notification_Search_Subject', xlSize: 3, xxlSize: 3 },
      {
        type: 'TEXT',
        name: 'contents',
        label: 'notification_Search_Contents',
        xlSize: 3,
        xxlSize: 3,
      },
    ];
    return filters;
  }, [t, auth]);
  const requiredRule = {
    required: true,
    message: t(VALIDATE_REQUIRED),
  };

  React.useEffect(() => {
    if (!formVisible) {
      FormHasChange = false;
    }
  }, [formVisible]);

  const closeAndReload = (reload: boolean) => {
    setFormVisible(false);
    setUploadedFiles([]);
    setUploadingFiles([]);
    if (reload) {
      zTb.current?.reload();
    }
  };
  const saveNotifications = async values => {
    setIsLoading(true);
    ApiSdk.NotificationService.create({
      body: {
        ...values,
        toDate: moment(values.toDate).format(DEFAULT_DATE_FORMAT),
        fromDate: moment(values.fromDate).format(DEFAULT_DATE_FORMAT),
        attachments: uploadedFiles?.map(x => x.id) || [],
      },
    })
      .then(res => {
        if (res.success) {
          closeAndReload(true);
        } else {
          message.error(t(res.message || ''));
        }
      })
      .catch(err => {
        message.error(err as any);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateNotifications = values => {
    setIsLoading(true);
    ApiSdk.NotificationService.update({
      body: {
        ...values,
        toDate: moment(values.toDate).format(DEFAULT_DATE_FORMAT),
        fromDate: moment(values.fromDate).format(DEFAULT_DATE_FORMAT),
        attachments: uploadedFiles?.map(x => x.id) || [],
      },
    })
      .then(res => {
        if (res.success) {
          closeAndReload(true);
        } else {
          message.error(t(res.message || ''));
        }
      })
      .catch(err => {
        message.error(err as any);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onEditNotification = (id: string) => {
    setFormVisible(true);
    setIsLoading(true);
    ApiSdk.NotificationService.detail({
      id: id,
    })
      .then(res => {
        if (res) {
          form.setFieldsValue(res);
          setUploadedFiles(res.attachments?.map(x => ({ id: x.id || '', name: x.name || '' })) || []);
          setIsNewForm(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getExtraButtons = hasPermissionEdit => {
    if (!hasPermissionEdit) {
      return [];
    }
    let extrabtns = [
      <Button
        className="nta-create-btn"
        key="create"
        type="primary"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          form.resetFields();
          setIsNewForm(true);
          setFormVisible(true);
          form.setFieldsValue({});
        }}
      >
        {t('notification_Action_Create')}
      </Button>,
    ];
    return extrabtns;
  };

  const onChangeFile = async (e: { currentTarget: HTMLInputElement }) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      const files = e.currentTarget.files;
      for (let i = 0; i < files.length; i++) {
        let id = uuidv4();
        const file = files[i];
        setUploadingFiles(x => [...x, { id: id, name: file.name }]);
        ApiSdk.MediaService.uploadFile({
          fileName: file.name,
          formFile: file,
        })
          .then(res => {
            if (res.success) {
              setUploadingFiles(x => x.filter(u => u.id !== id));
              setUploadedFiles(x => [...x, { id: res.fileId || '', name: res.fileName || '' }]);
            } else {
              message.error(t(res.message || ''));
            }
          })
          .catch(err => {
            message.error(err as any);
          })
          .finally(() => {
            setUploadingFiles(x => x.filter(u => u.id !== id));
          });
      }
    }
    e.currentTarget.value = '';
  };

  const removeFile = (id: string) => {
    setUploadedFiles(x => x.filter(u => u.id !== id));
  };

  const hasPermissionEdit = React.useMemo(() => {
    return hasPermissionEditNotification(auth);
  }, [auth]);

  const getStatusValue = (fromDate, toDate) => {
    const toDay = moment().format('YYYY-MM-DD');
    if (moment(fromDate).isSameOrBefore(toDay) && moment(toDate).isSameOrAfter(toDay)) {
      return NotificationStatus.During;
    }
    if (moment(toDate).isBefore(toDay)) {
      return NotificationStatus.EndOfPublication;
    }
    return NotificationStatus.Before;
  };

  const copyButton = [
    {
      render: (record: any) => {
        return (
          <Tooltip title={t('zTable.copy')}>
            <Button
              style={{ backgroundColor: '#fff95c', borderRadius: 0 }}
              icon={<CopyOutlined />}
              type="ghost"
              size="small"
              onClick={() => {
                form.resetFields();
                setIsNewForm(true);
                setFormVisible(true);
                const copyRecord = { ...record, id: undefined };
                form.setFieldsValue(copyRecord);
              }}
            ></Button>
          </Tooltip>
        );
      },
    },
  ];
  const onSubmitForm = () => {
    form
      .validateFields()
      .then(values => {
        if (moment(values['fromDate']).isAfter(moment(values['toDate']))) {
          message.error(`${t('notification_date_from_to_error')}`);
          return;
        }
        if (isNewForm) {
          saveNotifications(values);
        } else {
          const toDay = moment().format('YYYY-MM-DD');
          if (moment(values['fromDate']).isSameOrBefore(toDay) && moment(values['toDate']).isSameOrAfter(toDay) && FormHasChange) {
            Modal.confirm({
              title: t('notification_mail_send_confirm'),
              okText: t('Yes'),
              cancelText: t('No'),
              onOk: () => {
                updateNotifications({ ...values, SendEmailStatus: SendEmailStatus.Send });
              },
              onCancel: () => {
                updateNotifications({ ...values, SendEmailStatus: SendEmailStatus.NotSend });
              },
            });
          } else {
            updateNotifications(values);
          }
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <MainContent title={t('menu.Notifications')} extraBtns={getExtraButtons(hasPermissionEdit)} icon={<UserOutlined style={{ fontSize: 16 }} />}>
      <Helmet title={t('menu.Notifications')} />
      <FullPageLoading loading={isLoading} />
      <div className="ant-layout-content-body">
        <ZTable<any>
          scroll={{ x: W }}
          columns={columns}
          primaryKey="id"
          tableRef={zTb}
          url="/notifications/search"
          defaultFilter={!isGuideOnly(auth?.roleClaims) ? {} : { status: NotificationStatus.During }}
          bordered
          order_by="status"
          order_by_asc={true}
          allowEdit={hasPermissionEdit}
          filters={filters}
          onEdit={record => {
            onEditNotification(record.id);
          }}
          allowDelete={hasPermissionEdit}
          delete_url="/notifications/delete"
          layoutFilter={'horizontal'}
          rowDisableName={'status'}
          rowDisableValue={NotificationStatus.EndOfPublication}
          extraActions={hasPermissionEdit ? copyButton : []}
        />
        <Modal
          width={750}
          className="nta-custom-form responsive-modal"
          visible={formVisible}
          maskClosable={false}
          title={isNewForm ? 'お知らせ登録' : 'お知らせ'}
          forceRender={true}
          okText={t('notification_Action_Save')}
          cancelText={t('Close')}
          okButtonProps={{ icon: <SaveOutlined />, className: 'save-btn', loading: !!uploadingFiles.length }}
          onCancel={() => {
            closeAndReload(false);
          }}
          onOk={() => {
            onSubmitForm();
          }}
        >
          <Form
            form={form}
            size="middle"
            layout="horizontal"
            labelAlign="left"
            labelCol={{ xs: 24, md: 24, xl: 4 }}
            onValuesChange={() => {
              FormHasChange = true;
            }}
          >
            <Form.Item name="id" className="hidden">
              <Input type="hidden" />
            </Form.Item>

            <Row gutter={6}>
              <Col md={24} xs={24}>
                <Form.Item label={t('notification_Result_Subject')} rules={[requiredRule]} name="subject" labelAlign="left">
                  <Input placeholder={t('notification_Result_Subject')} />
                </Form.Item>
              </Col>

              <Col md={isNewForm ? 0 : 8} xs={isNewForm ? 0 : 24}>
                <Form.Item label={t('notification_Result_Status')} rules={[requiredRule]} name="status" labelCol={{ xs: 24, md: 24, xl: 12 }}>
                  <EnumSelect disabled placeholder={t('notification_Result_Status')} enumData={NotificationStatus} />
                </Form.Item>
              </Col>
              <Col md={isNewForm ? 12 : 8} xs={24}>
                <Form.Item
                  dependencies={['toDate']}
                  labelCol={{ xs: 24, md: 24, xl: 8 }}
                  label={'掲載開始'}
                  name="fromDate"
                  hasFeedback
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const toDate = getFieldValue('toDate');
                        if (!toDate || !value) {
                          return Promise.resolve();
                        }
                        const dateBefore = moment(value).format('YYYY-MM-DD');
                        const dateAfter = moment(getFieldValue('toDate')).format('YYYY-MM-DD');
                        if (moment(dateBefore).isBefore(dateAfter)) {
                          form.setFieldsValue({
                            status: getStatusValue(dateBefore, dateAfter),
                          });
                          return Promise.resolve();
                        }
                        return Promise.reject(t('notification_date_from_to_error'));
                      },
                    }),
                    { required: true, message: t('notification_required_error') },
                  ]}
                >
                  <DatePicker className="w-100 input-value-color" placeholder={t('notification_Search_From')} />
                </Form.Item>
              </Col>

              <Col md={isNewForm ? 12 : 8} xs={24}>
                <Form.Item
                  dependencies={['fromDate']}
                  labelCol={{ xs: 24, md: 24, xl: 8 }}
                  name="toDate"
                  label="掲載終了"
                  hasFeedback
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const fromDate = getFieldValue('fromDate');
                        if (!fromDate || !value) {
                          return Promise.resolve();
                        }
                        const dateBefore = moment(fromDate).format('YYYY-MM-DD');
                        const dateAfter = moment(value).format('YYYY-MM-DD');
                        if (moment(dateBefore).isBefore(dateAfter)) {
                          form.setFieldsValue({
                            status: getStatusValue(dateBefore, dateAfter),
                          });
                          return Promise.resolve();
                        }
                        return Promise.reject(t('notification_date_from_to_error'));
                      },
                    }),
                    { required: true, message: t('notification_required_error') },
                  ]}
                >
                  <DatePicker className="w-100 input-value-color" placeholder={t('notification_Search_To')} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={6}>
              <Col md={24}>
                <Form.Item name="uploadDocument" label={t('notification_attachment_upload')}>
                  <Row gutter={6}>
                    <Col md={16} xs={24}>
                      {uploadedFiles.map(file => {
                        return (
                          <div key={file.id} style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1, maxWidth: 350 }}>
                              <FileDownload fileName={file.name} fileId={file.id} className="btn-text-left" />
                            </div>
                            <Button
                              size="small"
                              style={{ marginLeft: 5 }}
                              icon={<DeleteOutlined />}
                              danger
                              onClick={() => {
                                removeFile(file.id);
                              }}
                            ></Button>
                          </div>
                        );
                      })}
                      {uploadingFiles.map(file => {
                        return (
                          <Row key={file.id}>
                            <Col flex={1}>
                              <FileDownload fileName={file.name} fileId={file.id} loading className="btn-text-left" />
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>

                    <Col md={8} xs={24} style={{ textAlign: 'end' }}>
                      <Button
                        type="primary"
                        className="save-btn"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          refAttachFile.current?.click();
                        }}
                      >
                        {t('guideSerchupload')}
                      </Button>

                      <input
                        multiple
                        style={{ display: 'none' }}
                        ref={refAttachFile as any}
                        type="file"
                        name="attachmentFile"
                        onChange={evt => {
                          onChangeFile(evt);
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col md={24} xs={24}>
                <Form.Item label={t('notification_Result_Contents')} rules={[requiredRule]} name="contents">
                  <TextArea placeholder={t('notification_Result_Contents')} rows={10} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
      {notificationInfoId && (
        <NotificationInfoModal notificationId={notificationInfoId} onClose={() => setNotificationInfoId(undefined)} visible={!!notificationInfoId} />
      )}
    </MainContent>
  );
}
