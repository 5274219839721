import { formItem2OldValue, formItem2Value } from '@/helpers/form';
import { ApiSdk } from '@/httpclient';
import { LanguageModel } from '@/__generated';
import { Select, SelectProps } from 'antd';
import React from 'react';

interface Props extends SelectProps<any> {
  guideId?: string | null | undefined;
}

export default function LanguageInput({ value, onChange, ...props }: Props) {
  const [languages, setLanguages] = React.useState<LanguageModel[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const guideId = React.useMemo(() => {
    return props.guideId ? formItem2Value(props.guideId) : undefined;
  }, [props.guideId]);
  React.useEffect(() => {
    getData();
  }, [guideId]);

  const getData = () => {
    setLoading(true);
    ApiSdk.LanguageService.languageAutocompletion({ pageSize: 250, guideId: guideId || undefined, orderBy: 'order', orderByAsc: true })
      .then(res => {
        setLanguages(res || []);
        onGetDataCompleted(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onGetDataCompleted = (_languages: LanguageModel[]) => {
    if (!_languages?.some(x => x.code === value?.value)) {
      if (onChange) {
        onChange(undefined, {} as any);
      }
    }
  };

  return (
    <Select
      maxTagCount="responsive"
      {...props}
      onChange={onChange}
      loading={loading}
      labelInValue
      value={value}
      allowClear
      filterOption={(input, option) => {
        return (option?.children as string)?.toLowerCase().includes(input.toLowerCase());
      }}
    >
      {languages.map(c => {
        return (
          <Select.Option key={c.code} value={c.code || ''}>
            {c.isMain ? `*${c.name}` : c.name}
          </Select.Option>
        );
      })}
    </Select>
  );
}
