import i18n from '@/helpers/i18n';
import { ApiSdk } from '@/httpclient';
import { Modal } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import authService from './authService';
import { TourGuideStatusType } from '@/config';
import { TourDetailModel } from '@/__generated';

class TourService {
  editTourSessionId: string | null = null;

  createSessionId = () => {
    this.editTourSessionId = uuidv4();
    return this.editTourSessionId as any;
  };

  unlockTour = (tourId: string, role: string) => {
    if (this.editTourSessionId) {
      ApiSdk.TourService.unlockTour(
        {
          body: {
            currentRole: role,
            sessionId: this.editTourSessionId,
            tourId,
          },
        },
        {
          headers: {
            'Keep-Alive': 'timeout=5, max=1000',
            Connection: 'Keep-Alive',
          },
        },
      )
        .catch(() => {
          // no handle
        })
        .finally(() => {
          this.editTourSessionId = null;
        });
    }
  };

  unlockOnTabClose = (tourId: string, role: string, sessionId: string) => {
    const token = authService.getAccessToken();
    var params = new FormData();
    params.append('tourId', tourId);
    params.append('currentRole', role);
    params.append('sessionId', sessionId);
    params.append('accessToken', token || '');
    navigator.sendBeacon(`${process.env.REACT_APP_API_ENDPOINT}/api/cms/tours/lock/unlock-closed-tab`, params);
  };

  onBrowserTabClose = (callbackFunction: () => void) => {
    if (this.editTourSessionId) {
      window.addEventListener('beforeunload', callbackFunction);
    }
    return () => {
      window.removeEventListener('beforeunload', callbackFunction);
    };
  };

  keepAlive = (tourId: string, role: string) => {
    if (this.editTourSessionId) {
      ApiSdk.TourService.keepTourUpdateAlive({
        body: {
          currentRole: role,
          sessionId: this.editTourSessionId,
          tourId,
        },
      }).catch(() => {
        // no handle
      });
    }
  };

  checkLockSessionAlive = (tourId: string, role: string, sessionId: string) => {
    ApiSdk.TourService.checkTourSessionAlive({
      currentRole: role,
      sessionId: sessionId,
      tourId: tourId,
    })
      .then(res => {
        if (!res) {
          Modal.error({
            title: i18n.t('EDIT_TOUR_SESSION_TIMEOUT_TITLE'),
            content: i18n.t('EDIT_TOUR_SESSION_TIMEOUT_CONTENT'),

            onOk: () => {
              window.location.reload();
            },
          });
        }
      })
      .catch(() => {});
  };
  onTabReActive = (callbackFunction: () => void) => {
    document.addEventListener('visibilitychange', callbackFunction);
    return () => {
      document.removeEventListener('visibilitychange', callbackFunction);
    };
  };

  displayEmailEFaxBtn = (tour: TourDetailModel) => {
    let guideStatus = tour?.tourGuides?.map(x => x.status) || [];
    return guideStatus.some(
      x =>
        ![
          TourGuideStatusType.Unassigned,
          TourGuideStatusType.Assigning,
          TourGuideStatusType.Checking,
          TourGuideStatusType.Denied,
          TourGuideStatusType.AwaitingCancellation,
          TourGuideStatusType.Cancelled,
        ].includes(x as any),
    );
  };
  displayFeedbackBtn = (guideStatus: any) => {
    return ![
      TourGuideStatusType.Unassigned,
      TourGuideStatusType.Assigning,
      TourGuideStatusType.Checking,
      TourGuideStatusType.Denied,
      TourGuideStatusType.AwaitingCancellation,
      TourGuideStatusType.Cancelled,
    ].includes(guideStatus);
  };
}

export default new TourService();
