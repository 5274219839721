import PrefectureInput from '@/components/app/formItem/PrefectureInput';
import EnumSelect from '@/components/app/formItem/EnumSelect';
import GuideInput from '@/components/app/formItem/GuideInput';
import LanguageInput from '@/components/app/formItem/LanguageInput';
import { GuideRankType } from '@/config';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, ColProps, Form, Input, Row, Select, Space } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GuideAutoComplete from '@/components/app/formItem/GuideSearchField';
import { clearFormValue } from '@/helpers/form';

type Props = {
  filters: {};
  updateFilters: Dispatch<SetStateAction<{}>>;
};
const labelStyle: ColProps = {
  md: 12,
  xl: 10,
  xxl: 8,
};

export default function SearchForm({ filters = {}, updateFilters }: Props) {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [otherProfessionalInstitution, setOtherProfessionalInstitution] = useState([]);
  const [disabledSelectOther, setDisabledSelectOther] = useState(true);

  const isEnableOptions = [
    { value: true, label: t('guideSearch_valid') },
    { value: false, label: t('guideSearch_invalid') },
  ];

  const membershipOfProfessionalInstitutions = [
    { value: 'JFG', label: 'JFG' },
    { value: 'JGA', label: 'JGA' },
    { value: 'OTHER', label: t('guideSearch_others') },
  ];

  const onChangeProfessionalInstitution = value => {
    if (value && value.includes('OTHER')) {
      setDisabledSelectOther(false);
    } else {
      setDisabledSelectOther(true);
    }
  };

  const onFinish = (values: any) => {
    const { isEnabled, supportedLanguages } = values;
    let filters = {
      ...values,
      supportedLanguages: supportedLanguages ? supportedLanguages.map(x => x.value) : undefined,
      isEnabled: !isEnabled || isEnabled.length === 0 || isEnabled.length === 2 ? undefined : isEnabled[0],
    };

    updateFilters(filters);
  };

  const onReset = () => {
    setOtherProfessionalInstitution([]);
    setDisabledSelectOther(true);
    clearFormValue(form);
  };

  return (
    <Form
      layout="horizontal"
      className="nta-custom-form guide-search-form form-checkbox-small-padding"
      labelCol={labelStyle}
      colon={false}
      form={form}
      onFinish={onFinish}
      initialValues={{
        hasLicense: true,
        isEnabled: [true],
        professionalInstitutions: [],
      }}
    >
      <Row gutter={6}>
        <Form.Item noStyle name="guideId">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item noStyle name="guideName">
          <Input type="hidden" />
        </Form.Item>
        <Col xs={24} md={12} xl={6} xxl={4}>
          <Form.Item label={t('guideSearch_guideName')} name="guideSearchField" labelCol={{ md: 12, xl: 10, xxl: 7 }}>
            <GuideAutoComplete form={form} placeholder={t('guideSearch_guideName')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6} xxl={3}>
          <Form.Item label={t('guideSearch_rank')} name="ranks" labelCol={{ md: 12, xl: 10, xxl: 8 }}>
            <EnumSelect mode="multiple" placeholder={t('guideSearch_rank')} enumData={GuideRankType} showSearch={false} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6} xxl={4}>
          <Form.Item label={t('guideSearch_prefecture')} name="prefectures" labelCol={{ md: 12, xl: 10, xxl: 7 }}>
            <PrefectureInput placeholder={t('guideSearch_prefecture')} mode={'multiple'} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6} xxl={3}>
          <Form.Item label={t('guideSearch_language')} name="supportedLanguages" labelCol={{ md: 10, xl: 8, xxl: 5 }}>
            <LanguageInput mode="multiple" placeholder={t('Language')} showSearch={false} maxTagCount="responsive" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={8} xxl={6}>
          <div style={{ display: 'flex', marginLeft: 15 }}>
            <Space>
              {t('guideSearch_membershipOfProfessionalInstitution')}
              <Form.Item name="professionalInstitutions" noStyle>
                <Checkbox.Group onChange={onChangeProfessionalInstitution}>
                  {membershipOfProfessionalInstitutions.map(item => (
                    <Checkbox key={item.value} value={item.value}>
                      {item.label}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </Space>
            <Form.Item noStyle name="otherProfessionalInstitution">
              <Select style={{ flex: 1 }} mode="tags" allowClear disabled={disabledSelectOther} value={otherProfessionalInstitution} />
            </Form.Item>
          </div>
        </Col>

        <Col xs={24} md={12} xl={16} xxl={4}>
          <div style={{ display: 'flex', marginTop: 5, marginLeft: 15 }}>
            <Space>
              {t('guideSearch_usageSituation')}
              <Form.Item name="isEnabled" noStyle>
                <Checkbox.Group>
                  {isEnableOptions.map((item, index) => (
                    <Checkbox value={item.value} key={index}>
                      {item.label}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </Space>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={12} xl={14} xxl={18}></Col>
        <Col xs={24} md={12} xl={10} xxl={6}>
          <div style={{ display: 'flex' }}>
            <Space style={{ marginRight: 15 }}>
              <Form.Item name="hasLicense" valuePropName="checked" noStyle>
                <Checkbox />
              </Form.Item>
              {t('guideSearch_displayWithLicense')}
            </Space>

            <Form.Item noStyle>
              <Space align="end">
                <Button type="primary" htmlType="submit">
                  <SearchOutlined style={{ color: '#333' }} />
                  {t('zTable.search')}
                </Button>
                <Button type="ghost" onClick={onReset}>
                  <CloseOutlined />
                  {t('zTable.reset')}
                </Button>
              </Space>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
