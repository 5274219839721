import React from 'react';
import NumberFormat from 'react-number-format';

type Props = {
  value?: any;
  onChange?: (v: any) => void;
  placeholder?: string;
};
export default function PhoneNumberInput({ onChange, value, ...props }: Props) {
  return (
    <NumberFormat
      {...props}
      role="presentation"
      autoComplete="off"
      value={value}
      onValueChange={v => {
        if (onChange) {
          onChange(v.value);
        }
      }}
      format="###-####-####"
      mask="_"
      allowEmptyFormatting={true}
      className="nta-masked-input"
    />
  );
}
