import { TourGuideStatusType } from '@/config';
import { CurrentUserModel, TourGuideStatus } from '@/__generated';
import { IAppUser } from '@/contexts/AppContext';

export enum APP_ROLE {
  Admin = 'Admin',
  SupervisorUser = 'SupervisorUser',
  RegularUser = 'RegularUser',
  ReferenceUser = 'ReferenceUser',
  Guide = 'Guide',
  Assign = 'Assign',
  GuideSupport = 'GuideSupport',
  SuperAdmin = 'SuperAdmin',
  SuperAccounting = 'SuperAccounting',
  ReferenceAccounting = 'ReferenceAccounting',
  RegularAccounting = 'RegularAccounting',
}
const USER_SELECTED_ROLE = 'USER_SELECTED_ROLE';
export const saveSelectedRole = (role: string) => {
  localStorage.setItem(USER_SELECTED_ROLE, role);
};
export const getSelectedRole = () => {
  return localStorage.getItem(USER_SELECTED_ROLE) || '';
};
export const getDefaultSelectedRole = originRoles => {
  const _getRoles = () => {
    let roles = excludeAccountingRoles(originRoles);
    if (!roles?.length) {
      return '';
    }
    const prevRoles = localStorage.getItem(USER_SELECTED_ROLE);
    if (prevRoles) {
      if (roles.includes(prevRoles)) {
        return prevRoles;
      }
    }
    if (roles.includes(APP_ROLE.SuperAdmin)) {
      return APP_ROLE.SuperAdmin;
    }
    if (roles.includes(APP_ROLE.Admin)) {
      return APP_ROLE.Admin;
    }
    if (roles.includes(APP_ROLE.SupervisorUser)) {
      return APP_ROLE.SupervisorUser;
    }
    if (roles.includes(APP_ROLE.Assign)) {
      return APP_ROLE.Assign;
    }
    return roles[0];
  };
  let role = _getRoles();
  saveSelectedRole(role);
  return role;
};
export const setBodyClassWithRole = role => {
  document.body.classList.add(`role-${role}`);
};
export const excludeAccountingRoles = (roles: string[]) => {
  return roles?.filter(x => ![APP_ROLE.SuperAccounting, APP_ROLE.ReferenceAccounting, APP_ROLE.RegularAccounting].includes(x as any)) || [];
};
export const isSupperAdmin = (role: string[] | null | undefined) => role?.includes(APP_ROLE.Admin) || role?.includes(APP_ROLE.SuperAdmin);
export const isGuide = (role: string[] | null | undefined) => role?.some(r => [APP_ROLE.Guide, APP_ROLE.GuideSupport].includes(r as any));
export const isGuideOnly = (role: string[] | null | undefined) => role?.some(r => [APP_ROLE.Guide].includes(r as any));
export const isGuideSupport = (role: string[] | null | undefined) => role?.some(r => [APP_ROLE.GuideSupport].includes(r as any));
export const isReferenceAccounting = (role: string[] | null | undefined) => role?.some(r => [APP_ROLE.ReferenceAccounting].includes(r as any));
export const isSuperAccounting = (role: string[] | null | undefined) => role?.some(r => [APP_ROLE.SuperAccounting].includes(r as any));
export const isRegularAccounting = (role: string[] | null | undefined) => role?.some(r => [APP_ROLE.RegularAccounting].includes(r as any));

export const isAccounting = (role: string[] | null | undefined) =>
  role?.some(r => [APP_ROLE.SuperAccounting, APP_ROLE.ReferenceAccounting, APP_ROLE.RegularAccounting].includes(r as any));

export const hasRoles = (roles: APP_ROLE[] | '*' | undefined, auth: CurrentUserModel | null) => {
  return roles === '*' || auth?.roleClaims?.some(x => roles?.includes(x as APP_ROLE));
};
export const hasPermissionUpdateTour = (auth: CurrentUserModel | null) => {
  return auth?.roleClaims?.some(x =>
    [APP_ROLE.SuperAdmin, APP_ROLE.Admin, APP_ROLE.Assign, APP_ROLE.SupervisorUser, APP_ROLE.Guide, APP_ROLE.GuideSupport].includes(x as any),
  );
};
export const hasPermissionUpdateGuideSeisan = (auth: IAppUser | null) => {
  return isGuide(auth?.roleClaims) || isAccounting(auth?.originRoleClaims);
};
export const hasPermissionCreateTour = (auth: CurrentUserModel | null) => {
  return auth?.roleClaims?.some(x => [APP_ROLE.SuperAdmin, APP_ROLE.Admin, APP_ROLE.Assign, APP_ROLE.SupervisorUser].includes(x as any));
};

export const hasPermissionEditNotification = (auth: CurrentUserModel | null) => {
  return auth?.roleClaims?.some(x => [APP_ROLE.SuperAdmin, APP_ROLE.Admin, APP_ROLE.Assign, APP_ROLE.SupervisorUser].includes(x as any));
};

export const guideHasBall = (status: TourGuideStatus) => {
  return [
    TourGuideStatusType.Assigning,
    TourGuideStatusType.Informing3MonthsPrior,
    TourGuideStatusType.Informing2MonthPrior,
    TourGuideStatusType.FinalInforming,
    TourGuideStatusType.AwaitingCancellation,
  ].includes(status);
};
export const staffHasBall = (status: TourGuideStatus) => {
  return [
    TourGuideStatusType.Unassigned,
    TourGuideStatusType.Checking,
    TourGuideStatusType.Assigned,
    TourGuideStatusType.Checked,
    TourGuideStatusType.Finalized,
    TourGuideStatusType.FinalChecking,
    TourGuideStatusType.Cancelled,
    TourGuideStatusType.Denied,
  ].includes(status);
};
