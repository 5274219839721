import FullPageLoading from '@/components/FullPageLoading';
import { GuideModel, GuideSupportedLanguageModel } from '@/__generated';
import { Checkbox, Col, Divider, Result, Row, Table, Tag } from 'antd';
import React from 'react';
import { Descriptions } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { GuideGenderType, GuideRankType, GuideSupportedArea, GuideSupportedType, SupportedLanguageLicenseType } from '@/config';
import dayjs from 'dayjs';
import { AddressPrefecture, DEFAULT_DISPLAY_DATE_FORMAT, getCollectionValue } from '@/constants';
import { ColumnsType } from 'antd/lib/table';

type Props = {
  guide: GuideModel | null | undefined;
  loading: boolean;
};
const labelStyle: React.CSSProperties = {
  width: 150,
};

const languagesColumns: ColumnsType<GuideSupportedLanguageModel> = [
  {
    title: <Trans>guideSerchlanguage</Trans>,
    dataIndex: 'languageName',
  },
  {
    title: <Trans>guideSerchmain</Trans>,
    dataIndex: 'isMain',
    render: v => <Tag>{v ? 'Yes' : 'No'}</Tag>,
  },

  {
    title: <Trans>guideSerchlicense</Trans>,
    dataIndex: 'license',
    render: v => <Trans>{SupportedLanguageLicenseType.__getValue(v)}</Trans>,
  },
  {
    title: <Trans>guideSerchobtainedDate</Trans>,
    dataIndex: 'obtainedDate',
    render: v => (v ? dayjs(v).format(DEFAULT_DISPLAY_DATE_FORMAT) : ''),
  },
];

export default function GuideInfo({ guide, loading }: Props) {
  const { t } = useTranslation();

  return (
    <div className="ant-layout-content-body">
      {loading ? (
        <FullPageLoading loading={true} />
      ) : guide ? (
        <>
          {/* guide info */}
          <Row gutter={6}>
            <Col md={12} xs={24} className="m-b-10">
              <Descriptions size="small" bordered labelStyle={labelStyle} column={1}>
                <Descriptions.Item label={t('氏名(漢字)')}>
                  <b>{[guide.lastNameKanji, guide.firstNameKanji].filter(x => x).join('　')}</b>
                </Descriptions.Item>
                <Descriptions.Item label={t('氏名(カナ)')}>
                  <b>{[guide.lastNameKatakana, guide.firstNameKatakana].filter(x => x).join('　')}</b>
                </Descriptions.Item>
                <Descriptions.Item label={t('Full Name')}>
                  <b>{[guide.lastNameEnglish, guide.firstNameEnglish].filter(x => x).join('　')}</b>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col md={12} xs={24} className="m-b-10">
              <Descriptions size="small" bordered labelStyle={labelStyle} column={1}>
                <Descriptions.Item label={t('guideSerchnickname')}>
                  <b>{guide.nickName}</b>
                </Descriptions.Item>
                <Descriptions.Item label={t('guideSerchgender')}>
                  <b>{t(GuideGenderType.__getValue(guide.gender))}</b>
                </Descriptions.Item>
                <Descriptions.Item label={t('guideSerchbirthdate')}>
                  <b>{guide.birthDate ? dayjs(guide.birthDate).format(DEFAULT_DISPLAY_DATE_FORMAT) : ''}</b>
                </Descriptions.Item>
                <Descriptions.Item label={t('guideSerchaccountId')}>
                  <b>{guide.accountId}</b>
                </Descriptions.Item>
                <Descriptions.Item label={t('guideSerchrank')}>
                  {guide.guideRank ? (
                    <div
                      style={{
                        width: 18,
                        height: 18,
                        borderRadius: 18,
                        fontSize: 12,
                        textAlign: 'center',
                        marginRight: 5,
                        backgroundColor: GuideRankType.__getColor(guide.guideRank),
                      }}
                    >
                      {GuideRankType.__getValue(guide.guideRank)}
                    </div>
                  ) : null}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>

          <Divider />
          {/* guide address */}
          <Row gutter={6}>
            <Col md={12} xs={24} className="m-b-10">
              <Descriptions size="small" bordered labelStyle={labelStyle} column={1}>
                <Descriptions.Item label={t('guideSerchnationality')}>
                  <b>{guide.nationalityName}</b>
                </Descriptions.Item>
                <Descriptions.Item label={t('guideSerchphoneNumberMobile')}>
                  <b>{guide.mobilePhone}</b>
                </Descriptions.Item>

                <Descriptions.Item label={t('guideSerchPhoneNumberHome')}>
                  <b>{guide.homePhone}</b>
                </Descriptions.Item>

                <Descriptions.Item label={t('guideSerchemailAddress1')}>
                  <b>{guide.emailAddress1}</b>
                </Descriptions.Item>
                <Descriptions.Item label={t('guideSerchemailAddress2')}>
                  <b>{guide.emailAddress2}</b>
                </Descriptions.Item>
              </Descriptions>
            </Col>

            <Col md={12} xs={24} className="m-b-10">
              <Descriptions size="small" bordered labelStyle={labelStyle} column={1}>
                <Descriptions.Item label={t('guideSerchzipCode')}>
                  <b>{guide.zipCode}</b>
                </Descriptions.Item>
                <Descriptions.Item label={t('guideSerchaddressPrefecture')}>
                  <b>{getCollectionValue(AddressPrefecture, guide.prefecture)}</b>
                </Descriptions.Item>

                <Descriptions.Item label={'住所'}>
                  <b>{guide.address}</b>
                </Descriptions.Item>
                <Descriptions.Item label={t('guideSerchbuildingName')}>
                  <b>{guide.buildingName}</b>
                </Descriptions.Item>
                <Descriptions.Item label={t('guideSerchnearestStation')}>
                  <b>{guide.nearestStation}</b>
                </Descriptions.Item>
                <Descriptions.Item label={t('guideSerchtrainLine')}>
                  <b>{guide.trainLine}</b>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Divider />
          <Row gutter={6}>
            <Col md={12} xs={24} className="m-b-10">
              <Descriptions size="small" bordered labelStyle={labelStyle} column={1}>
                <Descriptions.Item label={t('guideSerchbankName')} style={{ borderBottom: 0 }}>
                  <b>{guide.bankName}</b>
                </Descriptions.Item>
                <Descriptions.Item label={t('guideSerchbranchName')}>
                  <b>{guide.branchName}</b>
                </Descriptions.Item>
                <Descriptions.Item label={t('guideSerchaccountType')}>
                  <b>{guide.bankAccountType === 2 ? t('guideSerchaccountTypeCurrent') : t('guideSerchaccountTypeUsually')}</b>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col md={12} xs={24} className="m-b-10">
              <Descriptions size="small" bordered labelStyle={labelStyle} column={1}>
                <Descriptions.Item label={t('guideSerchaccountNumber')}>
                  <b>{guide.bankAccountNumber}</b>
                </Descriptions.Item>
                <Descriptions.Item label={t('guideSerchaccountHolder')}>
                  <b>{guide.bankAccountHolder}</b>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Divider />
          <Row gutter={6}>
            <Col md={16} xs={24} className="m-b-10">
              <Table size="small" bordered pagination={false} columns={languagesColumns} dataSource={guide.supportedLanguages} />
            </Col>
            <Col md={8} xs={24} className="m-b-10">
              <Descriptions size="small" bordered labelStyle={labelStyle} column={1}>
                <Descriptions.Item label={t('guideSerchpersonalBusinessRegistration')}>
                  <Tag>{guide.isPersonalBusinessRegistration ? 'Yes' : 'No'}</Tag>
                </Descriptions.Item>
                <Descriptions.Item label={t('guideSerchregistrationNumber')}>
                  <b>{guide.personalBusinessRegistrationNumber}</b>
                </Descriptions.Item>
                <Descriptions.Item label={t('guideSerchmembershipOfProfessionalInstitutions')}>
                  <b>{guide.professionalInstitutions?.join(', ')}</b>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>

          <Divider />
          <Descriptions size="small" bordered labelStyle={labelStyle} column={1}>
            <Descriptions.Item label={t('guideSerchsupportedTypes')}>
              {Object.keys(GuideSupportedType)
                .filter(x => !x.startsWith('__'))
                ?.map(key => {
                  const v = GuideSupportedType[key];
                  return <Checkbox checked={guide.supportedTypes?.includes(v)}>{t(GuideSupportedType.__getValue(v))}</Checkbox>;
                })}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Descriptions size="small" bordered labelStyle={labelStyle} column={1}>
            <Descriptions.Item label={t('guideSerchsupportedAreas')}>
              {Object.keys(GuideSupportedArea)
                .filter(x => !x.startsWith('__'))
                ?.map(key => {
                  const v = GuideSupportedArea[key];
                  return <Checkbox checked={guide.supportedAreas?.includes(v)}>{t(GuideSupportedArea.__getValue(v))}</Checkbox>;
                })}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
        </>
      ) : (
        <Result status="404" title="404" subTitle="Sorry, this guide not found." />
      )}
    </div>
  );
}
