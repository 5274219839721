import React from 'react';
import { useAppContext } from '@/contexts/AppContext';
import changeRoleIcon from '@/assets/images/change-user-xxl.png';
import AppAtag from '@/components/AppAtag';
import { excludeAccountingRoles, saveSelectedRole } from '@/services/roleService';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const ChangeRoleComponent = () => {
  const { auth, onUpdateContext } = useAppContext();
  const { t } = useTranslation();
  const roles = React.useMemo(() => {
    return excludeAccountingRoles(auth?.originRoleClaims || []);
  }, [auth]);
  const onChangeRole = () => {
    if (auth) {
      const { roleClaims } = auth;
      if (roleClaims.length && roles) {
        const currentIndex = roles.indexOf(roleClaims[0]);
        const nextRole = currentIndex === roles.length - 1 ? roles[0] : roles[currentIndex + 1];
        saveSelectedRole(nextRole);
        window.location.reload();
        onUpdateContext({
          auth: { ...auth, roleClaims: [nextRole] },
        });
      }
    }
  };

  if (roles?.length > 1) {
    return (
      <div className="notification hide-on-mobile">
        <AppAtag className="head-example">
          <Tooltip title={t(auth?.roleClaims?.length ? auth.roleClaims[0] : '')}>
            <img
              src={changeRoleIcon}
              style={{ width: 30, height: 30 }}
              alt="change role"
              onClick={() => {
                onChangeRole();
              }}
            />
          </Tooltip>
        </AppAtag>
      </div>
    );
  }
  return null;
};

export default ChangeRoleComponent;
