import React, { useState } from 'react';
import { useAppContext } from '@/contexts/AppContext';
import { Form, Row, Input, Col, Divider, Select, Radio, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { SaveOutlined } from '@ant-design/icons';
import { LanguageModel, CountryModel, GuideSupportedLanguageModel, GuideModel, GuideOverseaExperience } from '@/__generated';

import DatePicker from '@/components/DatePicker';
import FullPageLoading from '@/components/FullPageLoading';
import { VALIDATE_REQUIRED, ERROR_EXCEPTION, RegularEnglish, PhoneNumberRegex, KATAKANA_REGEX, KANJI_REGEX } from '../../constants';
import GuideAttachment from '../GuidesProfile/components/GuideAttachment';
import LanguageContent from '../GuidesProfile/components/LanguageContent';
import SelfIntroduction from '../GuidesProfile/components/SelfIntroduction';
import RatingContent from '../GuidesProfile/components/RatingContent';
import { ApiSdk } from '../../httpclient';
import './style.less';
import PrefectureInput from '@/components/app/formItem/PrefectureInput';
import PhoneNumberInput from '@/components/app/formItem/PhoneNumberInput';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import moment from 'moment';
import { checkValidGuideDataBeforeSave } from '../GuidesProfile/checkGuideDataBeforeSave';
import NationalityInput from '@/components/app/formItem/NationalityInput';
import { formatDate } from '@/config';

class GuideUpdateProfileValidateError extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'GuideUpdateProfileValidateError';
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, GuideUpdateProfileValidateError.prototype);
  }
}
interface Props {
  visible: boolean;
  onClose: () => void;
}

export interface DataLanguageType extends GuideSupportedLanguageModel {
  languageCodeOption: number;
}

type listStarType = {
  value: number;
  index: number;
};

export default function GuideProfile({ onClose, visible }: Props) {
  const { auth } = useAppContext();
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [previewPhoto, setPreviewPhoto] = useState('');
  const [previewCardFront, setPreviewCardFront] = useState('');
  const [previewCardBack, setPreviewCardBack] = useState('');
  const [previewResume, setPreviewResume] = useState('');
  const [previewCV, setPreviewCV] = useState('');
  const [selectedFilePhoto, setSelectedFilePhoto] = useState<File | null>(null);
  const [selectedFileCardFront, setSelectedFileCardFront] = useState<File | null>(null);
  const [selectedFileCardBack, setSelectedFileCardBack] = useState<File | null>(null);
  const [selectedFileResume, setSelectedFileResume] = useState<File | null>(null);
  const [selectedFileCV, setSelectedFileCV] = useState<File | null>(null);
  const [guideInfo, setGuideInfo] = React.useState<GuideModel>();
  const [languages, setLanguages] = useState<LanguageModel[]>([]);
  const [isFormChange, setIsFormChange] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    ApiSdk.GuideService.getByUserId({ userId: auth?.id || '' })
      .then(data => {
        setGuideInfo(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth]);
  React.useEffect(() => {
    ApiSdk.LanguageService.getLanguages({ pageSize: 250, orderBy: 'order', orderByAsc: true }).then(res => {
      setLanguages(res.data || []);
    });
  }, []);

  React.useEffect(() => {
    if (guideInfo) {
      if (guideInfo?.profileImageId) setPreviewPhoto(`${process.env.REACT_APP_API_ENDPOINT}/api/cms/file/view/${guideInfo.profileImageId}`);
      if (guideInfo?.businessCardFrontId)
        setPreviewCardFront(`${process.env.REACT_APP_API_ENDPOINT}/api/cms/file/view/${guideInfo.businessCardFrontId}`);
      if (guideInfo?.businessCardBackId)
        setPreviewCardBack(`${process.env.REACT_APP_API_ENDPOINT}/api/cms/file/view/${guideInfo.businessCardBackId}`);
      if (guideInfo?.cvUpload) setPreviewCV(guideInfo.cvUpload?.name ?? '');
      if (guideInfo?.resumeUpload) setPreviewResume(guideInfo.resumeUpload?.name ?? '');
      form.setFieldsValue({
        ...guideInfo,
        maxNumberExperience: !!guideInfo.maxNumber,
        maxNumber: guideInfo.maxNumber || null,
        overseaExperiences: guideInfo.overseaExperiences?.length
          ? guideInfo.overseaExperiences
          : [
              {
                from: undefined,
                to: undefined,
                place: '',
                details: '',
              },
            ],
      });
    }
  }, [guideInfo]);

  const onImageChange = async (e: { currentTarget: HTMLInputElement }) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      setSelectedFilePhoto(e.currentTarget.files[0]);
      const objectUrl = URL.createObjectURL(e.currentTarget.files[0]);
      setPreviewPhoto(objectUrl);
    }
  };

  const onImageCardFront = async (e: { currentTarget: HTMLInputElement }) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      setSelectedFileCardFront(e.currentTarget.files[0]);
      const objectUrl = URL.createObjectURL(e.currentTarget.files[0]);
      setPreviewCardFront(objectUrl);
    }
  };

  const onImageChangeCardBack = async (e: { currentTarget: HTMLInputElement }) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      setSelectedFileCardBack(e.currentTarget.files[0]);
      const objectUrl = URL.createObjectURL(e.currentTarget.files[0]);
      setPreviewCardBack(objectUrl);
    }
  };

  const onImageChangeResume = async (e: { currentTarget: HTMLInputElement }) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      setSelectedFileResume(e.currentTarget.files[0]);
      setPreviewResume(e.currentTarget.files[0].name);
    }
  };

  const onFileChangeCV = async (e: { currentTarget: HTMLInputElement }) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      setSelectedFileCV(e.currentTarget.files[0]);
      setPreviewCV(e.currentTarget.files[0].name);
    }
  };

  const onRemovePhoto = () => {
    setPreviewPhoto('');
    setSelectedFilePhoto(null);
  };
  const onRemoveCardFront = () => {
    setPreviewCardFront('');
    setSelectedFileCardFront(null);
  };
  const onRemoveCardBack = () => {
    setPreviewCardBack('');
    setSelectedFileCardBack(null);
  };
  const onRemoveResume = () => {
    setPreviewResume('');
    setSelectedFileResume(null);
  };
  const onRemoveCV = () => {
    setPreviewCV('');
    setSelectedFileCV(null);
  };

  const processUploadImage = async fileUpload => {
    const response = await ApiSdk.MediaService.uploadFile({ fileName: fileUpload?.name ?? '', formFile: fileUpload });
    if (response.success) {
      return response;
    } else {
      return {};
    }
  };
  const saveGuide = values => {
    ApiSdk.GuideService.update({ body: values })
      .then(res => {
        if (res.success) {
          onClose();
          Sentry.captureMessage(`GUIDE [${auth?.username}]:UPDATE PROFILE SUCCESS`, Severity.Log);
        } else {
          message.error(res.message || '');
          Sentry.captureMessage(`GUIDE [${auth?.username}]:UPDATE PROFILE ERROR`, Severity.Critical);
        }
      })
      .catch(() => {
        message.error(ERROR_EXCEPTION, 10);
        Sentry.captureMessage(`GUIDE [${auth?.username}]:UPDATE PROFILE ERROR`, Severity.Critical);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onSubmit = async values => {
    if (!checkValidGuideDataBeforeSave(values, form)) {
      return;
    }
    setLoading(true);

    try {
      const dataSend = { ...values };
      if (dataSend.birthDate) {
        dataSend.birthDate = formatDate(dataSend.birthDate);
      }
      if (selectedFilePhoto) {
        const imagePhoto = await processUploadImage(selectedFilePhoto);
        if (imagePhoto?.fileId) {
          dataSend.profileImage = imagePhoto;
          dataSend.profileImageId = imagePhoto.fileId;
        }
      } else {
        dataSend.profileImage = previewPhoto ? guideInfo?.profileImage : null;
        dataSend.profileImageId = previewPhoto ? guideInfo?.profileImageId : null;
      }

      if (selectedFileCardFront) {
        const imageCardFront = await processUploadImage(selectedFileCardFront);
        if (imageCardFront?.fileId) {
          dataSend.businessCardFront = imageCardFront;
          dataSend.businessCardFrontId = imageCardFront.fileId;
        }
      } else {
        dataSend.businessCardFront = previewCardFront ? guideInfo?.businessCardFront : null;
        dataSend.businessCardFrontId = previewCardFront ? guideInfo?.businessCardFrontId : null;
      }

      if (selectedFileCardBack) {
        const imageCardBack = await processUploadImage(selectedFileCardBack);
        if (imageCardBack?.fileId) {
          dataSend.businessCardBack = imageCardBack;
          dataSend.businessCardBackId = imageCardBack.fileId;
        }
      } else {
        dataSend.businessCardBack = previewCardBack ? guideInfo?.businessCardBack : null;
        dataSend.businessCardBackId = previewCardBack ? guideInfo?.businessCardBackId : null;
      }

      if (selectedFileResume) {
        const fileResume = await processUploadImage(selectedFileResume);
        if (fileResume?.fileId) {
          dataSend.resumeUploadId = fileResume.fileId;
          dataSend.resumeUpload = fileResume;
        }
      } else {
        dataSend.resumeUploadId = previewResume ? guideInfo?.resumeUploadId : null;
        dataSend.resumeUpload = previewResume ? guideInfo?.resumeUpload : null;
      }

      if (selectedFileCV) {
        const fileCV = await processUploadImage(selectedFileCV);
        if (fileCV?.fileId) {
          dataSend.cvUploadId = fileCV.fileId;
          dataSend.cvUpload = fileCV;
        }
      } else {
        dataSend.cvUploadId = previewCV ? guideInfo?.cvUploadId : null;
        dataSend.cvUpload = previewCV ? guideInfo?.cvUpload : null;
      }

      dataSend.supportedTypes = values.supportedTypes ?? [];
      dataSend.supportedAreas = values.supportedAreas ?? [];
      dataSend.maxNumber = dataSend.maxNumber || 0;
      dataSend.overseaExperiences = (values.overseaExperiences as GuideOverseaExperience[])
        ?.filter(oversea => {
          return oversea.from || oversea.to || oversea.place || oversea.details;
        })
        .map(d => {
          return {
            ...d,
            from: d.from ? formatDate(d.from) : undefined,
            to: d.to ? formatDate(d.to) : undefined,
          };
        });
      dataSend.supportedLanguages = dataSend.supportedLanguages?.map(d => {
        return {
          ...d,
          obtainedDate: d.obtainedDate ? formatDate(d.obtainedDate) : undefined,
        };
      });

      saveGuide(dataSend);
    } catch (error) {
      Sentry.captureMessage(`GUIDE [${auth?.username}]:UPDATE PROFILE ERROR`, Severity.Critical);
      Sentry.captureException(error);
      setLoading(false);
      if ((error as any)?.message) {
        message.warning((error as any)?.response?.data?.message || ERROR_EXCEPTION, 10);
      } else {
        message.warning(ERROR_EXCEPTION, 10);
      }
    }
  };

  const onValuesChangeForm = values => {
    const isFieldsTouched = form.isFieldsTouched(true);
    if (!isFieldsTouched && !isFormChange) {
      setIsFormChange(true);
    }
    if (Object.keys(values)[0] === 'maxNumberExperience') {
      if (form.getFieldValue('maxNumber') === undefined || form.getFieldValue('maxNumber') === '') {
        form.setFieldsValue({ maxNumber: null });
      }
    }
  };
  const { formLayout, labelCol, wrapperCol } = React.useMemo(() => {
    if (window.innerWidth > 800) {
      return {
        formLayout: 'vertical',
        labelCol: undefined,
        wrapperCol: undefined,
      };
    }
    return {
      formLayout: 'horizontal',
      labelCol: { span: 10, sm: 10, xs: 10 },
      wrapperCol: { span: 14, sm: 14, xs: 14 },
    };
  }, []);
  const onValidateError = () => {
    Modal.error({
      maskClosable: false,
      title: t('GuideUpdateProfileErrorTitle'),
      okText: t('Close'),
      okButtonProps: { type: 'default' },
      cancelButtonProps: { style: { display: 'none' } },
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('GuideUpdateProfileValidateError'),
          }}
        />
      ),
    });
  };
  const onModalClose = () => {
    if (isFormChange) {
      Modal.confirm({
        maskClosable: false,
        title: t('GuideUpdateProfileErrorTitle'),
        okText: t('Yes'),
        cancelText: t('No'),
        okButtonProps: { type: 'default' },
        cancelButtonProps: { type: 'primary' },
        onOk: () => {
          form.resetFields();
          onClose();
        },
        content: (
          <div
            dangerouslySetInnerHTML={{
              __html: t('GuideUpdateProfileChangeNotSave'),
            }}
          />
        ),
      });
    } else {
      form.resetFields();
      onClose();
    }
  };
  return (
    <Modal
      className="nta-custom-form modal-create-guide-search responsive-modal create-guide-modal"
      visible={visible}
      maskClosable={false}
      width={900}
      style={{ top: 10 }}
      title={'マイページ'}
      forceRender={true}
      okText={t('Save')}
      onCancel={() => {
        onModalClose();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onSubmit(values);
          })
          .catch(err => {
            if (err?.errorFields.length) {
              const fieldsName = err.errorFields[0].name;
              form.scrollToField(fieldsName, { behavior: 'smooth' });
              Sentry.captureMessage(`GUIDE [${auth?.username}]:UPDATE PROFILE INVALID VALIDATE FORM`, Severity.Warning);
              Sentry.captureException(new GuideUpdateProfileValidateError(JSON.stringify(err?.errorFields)));
              onValidateError();
            }
          });
      }}
      okButtonProps={{ icon: <SaveOutlined />, className: 'save-btn' }}
    >
      <FullPageLoading loading={loading} />
      <Form
        form={form}
        className="guide-info h-adr"
        layout={formLayout as any}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        labelAlign={'left'}
        onValuesChange={onValuesChangeForm}
      >
        <Form.Item name="id" className="hidden">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="userId" className="hidden">
          <Input type="hidden" />
        </Form.Item>
        <Row gutter={6} style={{ marginBottom: 5 }}>
          <Col md={24} xs={24}>
            <h3>プロフィール</h3>
          </Col>
          <Col md={10} xs={24}>
            <Row gutter={6}>
              <Col md={24} xs={24}>
                <GuideAttachment title={t('guideSerchphoto')} fileName={previewPhoto} onChange={onImageChange} onRemove={onRemovePhoto} />
              </Col>
              <Col md={24} xs={24}>
                <GuideAttachment
                  title={t('guideSerchbusinessCardFrontPhoto')}
                  fileName={previewCardFront}
                  onChange={onImageCardFront}
                  onRemove={onRemoveCardFront}
                />
              </Col>
              <Col md={24} xs={24}>
                <GuideAttachment
                  title={t('guideSerchbusinessCardBackPhoto')}
                  fileName={previewCardBack}
                  onChange={onImageChangeCardBack}
                  onRemove={onRemoveCardBack}
                />
              </Col>
            </Row>
          </Col>

          <Col md={14} xs={24}>
            <Row gutter={6}>
              <Col md={12} xs={24}>
                <Form.Item label={t('guideSerchaccountId')} name="accountId">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col md={12} xs={0} />
              <Col md={12} xs={24}>
                <Form.Item
                  label={t('guideSerchlastNameKanji')}
                  id="lastNameKanji"
                  name="lastNameKanji"
                  rules={[
                    { required: true, message: VALIDATE_REQUIRED },
                    {
                      pattern: KANJI_REGEX,
                      message: t('staffSearch_validateJanji'),
                    },
                  ]}
                >
                  <Input maxLength={255} />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label={t('guideSerchfirstNameKanji')}
                  name="firstNameKanji"
                  rules={[
                    { required: true, message: VALIDATE_REQUIRED },
                    {
                      pattern: KANJI_REGEX,
                      message: t('staffSearch_validateJanji'),
                    },
                  ]}
                >
                  <Input maxLength={255} />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label={t('guideSerchlastNameKatagana')}
                  name="lastNameKatakana"
                  rules={[
                    { required: true, message: VALIDATE_REQUIRED },
                    {
                      pattern: KATAKANA_REGEX,
                      message: t('staffSearch_validateKatagana'),
                    },
                  ]}
                >
                  <Input maxLength={255} />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label={t('guideSerchfirstNameKatagana')}
                  id="firstNameKatakana"
                  name="firstNameKatakana"
                  rules={[
                    { required: true, message: VALIDATE_REQUIRED },
                    {
                      pattern: KATAKANA_REGEX,
                      message: t('staffSearch_validateKatagana'),
                    },
                  ]}
                >
                  <Input maxLength={255} />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  label={t('guideSerchlastNameEnglish')}
                  id="firstNameKatakana"
                  name="lastNameEnglish"
                  rules={[
                    {
                      pattern: RegularEnglish,
                      message: t('staffSearch_validationEnglish'),
                    },
                  ]}
                >
                  <Input maxLength={255} />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label={t('guideSerchfirstNameEnglish')}
                  id="firstNameEnglish"
                  name="firstNameEnglish"
                  rules={[
                    {
                      pattern: RegularEnglish,
                      message: t('staffSearch_validationEnglish'),
                    },
                  ]}
                >
                  <Input maxLength={255} />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item name="nickName" label={t('guideSerchnickname')}>
                  <Input maxLength={255} />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item label={t('guideSerchgender')} name="gender" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Radio.Group>
                    <Radio value={1}>{t('guideCreateGenderMale')}</Radio>
                    <Radio value={2}>{t('guideCreateGenderFemale')}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label={t('guideSerchbirthdate')} name="birthDate" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <DatePicker className="w-100" defaultPickerValue={moment('1960-01-01')} lessThanToday />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label={t('guideSerchnationality')} name="nationality" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <NationalityInput placeholder={t('guideSerchnationality')} />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  name="mobilePhone"
                  rules={[
                    { required: true, message: VALIDATE_REQUIRED },
                    { pattern: PhoneNumberRegex, message: t('validatorPhoneNumber') },
                  ]}
                  label={t('guideSerchphoneNumberMobile')}
                >
                  <PhoneNumberInput placeholder={t('guideSerchphoneNumberMobile')} />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  name="homePhone"
                  rules={[
                    { required: true, message: VALIDATE_REQUIRED },
                    { pattern: PhoneNumberRegex, message: t('validatorPhoneNumber') },
                  ]}
                  label={t('guideSerchPhoneNumberHome')}
                >
                  <PhoneNumberInput placeholder={t('guideSerchPhoneNumberHome')} />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label={t('guideSerchemailAddress1')} id="emailAddress1" name="emailAddress1">
                  <Input type="email" disabled maxLength={255} />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item id="emailAddress2" name="emailAddress2" label={t('guideSerchemailAddress2')}>
                  <Input type="email" maxLength={255} />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item label={t('guideSerchzipCode')} name="zipCode" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input maxLength={12} />
                </Form.Item>
              </Col>

              <input type="hidden" className="p-country-name" value="Japan" />

              <Col md={12} xs={24}>
                <Form.Item label={t('guideSerchaddressPrefecture')} name="prefecture" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <PrefectureInput />
                </Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item name="address" label="住所" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input type="text" maxLength={255} className="ant-input p-locality p-street-address p-extended-address" />
                </Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item id="buildingName" name="buildingName" label={t('guideSerchbuildingName')}>
                  <Input maxLength={255} />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  id="nearestStation"
                  name="nearestStation"
                  label={t('guideSerchnearestStation')}
                  rules={[{ required: true, message: VALIDATE_REQUIRED }]}
                >
                  <Input maxLength={255} />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item id="trainLine" name="trainLine" label={t('guideSerchtrainLine')} rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} xs={24}>
                <h3>口座情報</h3>
              </Col>
              <Col md={24} xs={24}>
                <Row gutter={6}>
                  <Col md={12} xs={24}>
                    <Form.Item label={t('guideSerchbankName')} name="bankName">
                      <Input maxLength={200} />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item label={t('guideSerchbranchName')} name="branchName">
                      <Input maxLength={200} />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item label={t('guideSerchaccountType')} name="bankAccountType">
                      <Radio.Group>
                        <Radio value={1}>{t('guideSerchaccountTypeUsually')}</Radio>
                        <Radio value={2}>{t('guideSerchaccountTypeCurrent')}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item label={t('guideSerchaccountNumber')} name="bankAccountNumber">
                      <Input maxLength={20} />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item label={t('guideSerchaccountHolder')} name="bankAccountHolder">
                      <Input autoComplete="off" maxLength={50} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider />

        <LanguageContent form={form} languages={languages} isMyProfile={true} />
        <Divider />
        <SelfIntroduction
          previewResume={previewResume}
          previewCV={previewCV}
          onRemoveResume={onRemoveResume}
          onImageChangeResume={onImageChangeResume}
          onRemoveCV={onRemoveCV}
          onFileChangeCV={onFileChangeCV}
          isNewForm={false}
          form={form}
          formVisible={true}
        />
        <Divider />
        <RatingContent auth={auth} isProfile={true} isNewForm={false} form={form} />
      </Form>
    </Modal>
  );
}
