export default {
  tourSearchFrom: 'ツアー期間(From)',
  tourSearchTo: '期ツアー期間(To)',
  'Reservation form': '予約形態',
  'Travel purpose': '旅行目的',
  'In charge of tour': 'ツアー担当',
  'Business Day': '業務日',
  'Daily Allowance': '日当',
  Availability: '業務可否',
  'Stay overnight?': '宿泊',
  'Pre night?': '前泊',
  Briefing: 'ブリーフィング',
  'Briefing Date And Time': 'ブリーフィング日時',
  'Expected Date Of Arrival Of Materials': '資料発送予定日',
  'Briefing desired day 1': 'ブリーフィング希望日①',
  'Briefing desired date 2': 'ブリーフィング希望日②',
  'Briefing desired date 3': 'ブリーフィング希望日③',
  'Material arrival desired date and time 1': '資料到着希望日時①',
  'Material arrival desired date and time 2': '資料到着希望日時②',
  'Material arrival desired date and time 3': '資料到着希望日時③',
  'Notes for Guides': 'ガイド様⇒NTAへの連絡用',
  'Feedback(Tour)': 'フィードバック(ツアー総評)',
  'Add Date': '日付追加',
  'Delete Date': '日付削除',
  'Hide delete date': '削除日の非表示',
  'Cancel Date': 'キャンセル日',
  'Hide cancel date': 'キャンセル日を隠す',
  'Display cancelled dates': '取消済の日付を表示する',
  'Not applicable': '対応不可',
  'Accept the assignment': '引き受け',
  hideOtherGuideInfo: '他のガイド情報を非表示にする',
  'Show previous comments': '前のコメントを表示する',
  'Contact Items From NTA': 'NTA⇒ガイド様への連絡用',
  EDIT_TOUR_SESSION_TIMEOUT_TITLE: '警告',
  EDIT_TOUR_SESSION_TIMEOUT_CONTENT:
    '編集セッションが終了しました。編集内容が保存されていません。ページを再読み込むために、OKボタンをクリックしてください。',
  tourMessagePage_Title: 'メッセージ一覧',
  tourMessagePage_EmailEFax: 'タイプ',
  tourMessagePage_FromTo: '送信日',
  tourMessagePage_Subject: '件名',
  tourMessagePage_Status: 'ステータス',
  tourMessagePage_TourNo: 'ツアーNo.',
  tourMessagePage_TourName: 'ツアー名',
  tourMessagePage_Guide: 'ガイド名',
  tourMessagePage_EmailFax: 'FAX番号/メール',
  tourMessagePage_FromDate: '日付から',
  tourMessagePage_ToDate: 'これまで',
  tourMessagePage_To: 'FAX番号/メール',
  tourMessagePage_DateTime: '送信日',
  tourMessagePage_Error: 'エラー',
  tourMessagePage_ShowDetail: '詳細',
  tourMessagePage_ReSent: '再送',
  tourMessagePage_ReSendConfirmMessage: 'このメッセージを再送しますか？',
  tourMessagePage_ReSendMessage: '再送',
  tourMessagePage_Sending: '送信',
  tourMessagePage_Type: 'タイプ',
  tourMessagePage_Content: '内容',
  tourMessagePage_DraftMessage: '下書き',
  tourMessagePage_CreateMessage: 'メッセージの作成',
  tourMessagePage_Send: '送信',
  tourMessagePage_SeisanNumber: 'ツアーNo.',
  tourMessagePage_Body: '本文',
  tourMessagePage_Attachment: '添付',
  tourMessagePage_Click2Upload: 'アップロードファイル',
  tourMessagePage_SendFax: '施設へ連絡',
  tourMessagePage_FacilityName: '施設名/会社名',
  tourMessagePage_EmailTo: 'Email (アドレスは複数入力が可能、アドレスは；で区切ってください)',
  tourMessagePage_FaxTo: '送信先',
  tourMessagePage_TourId: 'ツアーID',
  tourMessagePage_FileHasPassword: 'このファイルにパスワードはありますか？',
  tourMessagePage_InputFilePasswordIfNeed: 'このファイルにパスワードがある場合は入力してください。',
  // tour seisan
  tourDetail_TourNoTourSeisan: 'ツアーNo/精算番号',
  tourDetail_TourSeisanStatus: 'ツアー精算STS',
  tourDetail_GuideSeisanStatus: 'ガイド精算STS',
  tourDetail_TourSeisanAttachment: '添付ファイル',
  tourDetail_TourSeisanGuideRemark: 'ガイドからの連絡事項',
  tourDetail_TourSeisanNTARemark: 'NTAからの連絡事項',
  tourDetail_TourSeisanFileName: '添付ファイル',
  tourDetail_TourSeisanUploadedTime: 'アップロード日時',
  tourDetail_TourSeisanUploadedBy: 'アップロード者',
  tourDetail_TourSeisanDownloaded: 'ダウンロード済み',
  tourDetail_TourSeisanUpload: 'アップロード',
  tourDetail_TourSeisanDelete: '削除',
  tourDetail_TourSeisanDownload: 'ダウンロード',
  tourDetail_TourSeisanPaymentDueDate: '支払予定日',
  tourDetail_TourSeisanPaymentPaymentCost: '振込予定金額',
  tourDetail_TourSeisanPaymentTaxWithoutAmount: '源泉金額',
  tourDetail_TourSeisanAmountCarrying: '携行金',
  tourDetail_TourSeisanAmountSubmit: '提出する',
  tourDetail_TourSeisanRefund: '返金完了',
  tourDetail_TourSeisanYes: '提出',
  tourDetail_TourSeisanGuideSubmitNoChangeMessage: '何も変更されていません。提出しますか？',
  tourDetail_TourSeisanAutoConfirmingMessage: `精算ステータスを"NTA確認中"に変更しますか？`,
  tourDetail_TourSeisanAutoConfirmingYes: '変更する',
  tourDetail_TourSeisanAutoConfirmingNo: '変更しない',
  tourDetail_TourSeisanNotExist: 'ツアー決済ない',
  // guide seisan listing
  guideSeisan_PageTitle: 'ガイド精算一覧',
  guideSeisan_FileName: 'ファイル名',
  guideSeisan_TourFromDate: 'ツアー期間(From)',
  guideSeisan_TourToDate: 'ツアー期間(To)',
  guideSeisan_SubmissionFromDate: '提出日(From)',
  guideSeisan_SubmissionToDate: '提出日(To)',
  guideSeisan_SubmissionDate: '提出日',
  guideSeisan_SaleStaff: '営業担当',
  guideSeisan_GuideName: 'ガイド名',
  guideSeisan_Agent: 'AGT名',
  guideSeisan_BillingAmountFrom: '支払額(From)',
  guideSeisan_BillingAmountTo: '支払額(To)',
  guideSeisan_PaymentDueFromDate: '支払予定日(From)',
  guideSeisan_PaymentDueToDate: '支払予定日(To)',
  guideSeisan_SeisanPIC: '精算担当',
  guideSeisan_SettlementDate: '精算処理日',
  guideSeisan_SuperAccounting: '経理担当',
  guideSeisan_AccountingDate: '経理処理日',
  guideSeisan_TransferPerson: '振込担当',
  guideSeisan_TransferDate: '振込処理日',
  guideSeisan_SalesStaff: '営業担当',
  guideSeisan_IsSubmitted: '提出済',
};
