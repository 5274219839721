import { HistoryOutlined, InfoOutlined } from '@ant-design/icons';
import { Button, List, Modal } from 'antd';
import React from 'react';
import Helmet from 'react-helmet';
import MainContent from '../../Layout/MainContent';
import moment from 'moment';
import ZTable, { IColumnType, IFilterItem } from '@/components/ZTable';
import { AuditLogType, DEFAULT_DATE_FORMAT } from '@/constants';
import { useTranslation } from 'react-i18next';
import { AuditLog } from '@/__generated';
const PAGE_TITLE = 'Audit Logs';
const filters: Array<IFilterItem> = [
  { type: 'TEXT', name: 'actor', label: 'auditlog.actor' },
  { type: 'TEXT', name: 'entityName', label: 'auditlog.entityName' },
  { type: 'TEXT', name: 'primaryKey', label: 'auditlog.primaryKey' },
  {
    type: 'DATE',
    name: 'dateTimeFrom',
    label: 'auditlog.From',
    parseValue: v => (v ? v.format(DEFAULT_DATE_FORMAT) : undefined),
  },
  {
    type: 'DATE',
    name: 'dateTimeTo',
    label: 'auditlog.To',
    parseValue: v => (v ? v.format(DEFAULT_DATE_FORMAT) : undefined),
  },
];
export default () => {
  const { t, i18n } = useTranslation();
  const columns: IColumnType<any>[] = React.useMemo(() => {
    return [
      {
        title: t('auditlog.actor'),
        dataIndex: 'actor',
      },
      {
        title: t('auditlog.auditType'),
        dataIndex: 'auditType',
        render: (v: any) => {
          return AuditLogType.__getValue(v);
        },
      },

      {
        title: t('auditlog.entityName'),
        dataIndex: 'entityName',
      },
      {
        title: t('auditlog.primaryKey'),
        dataIndex: 'primaryKey',
      },
      {
        title: t('auditlog.logTime'),
        dataIndex: 'logTime',
        render: (value: any) => {
          return moment(value).format('YYYY/MM/DD hh:mm:ss');
        },
      },

      {
        width: 150,
        align: 'center',
        title: t('auditlog.changeValues'),
        dataIndex: 'changeValues',
        render(changeValues: any, value: AuditLog) {
          return (
            <Button
              size="small"
              type="primary"
              shape="circle"
              icon={<InfoOutlined />}
              onClick={() => {
                Modal.info({
                  title: t('auditlog.changeValues'),
                  content: (
                    <List
                      size="small"
                      bordered
                      dataSource={changeValues}
                      renderItem={(item: any) => {
                        let v = ` ${item.oldValue} -> ${item.newValue}`;
                        if (value.auditType === AuditLogType.Create) {
                          v = item.newValue;
                        } else if (value.auditType === AuditLogType.Delete) {
                          v = item.oldValue;
                        }
                        return <List.Item>{`[${item.propertyName}] ${v}`}</List.Item>;
                      }}
                    />
                  ),
                  width: 700,
                });
              }}
            ></Button>
          );
        },
      },
    ];
  }, [i18n.language]);

  return (
    <MainContent title={t(PAGE_TITLE)} icon={<HistoryOutlined style={{ fontSize: 16 }} />}>
      <Helmet title={t(PAGE_TITLE)} />
      <div className="ant-layout-content-body">
        <ZTable<any>
          columns={columns}
          primaryKey="id"
          url="/audit-logs/get-audit-logs"
          bordered
          order_by="logTime"
          order_by_asc={false}
          allowEdit={false}
          allowDelete={false}
          filters={filters}
        />
      </div>
    </MainContent>
  );
};
