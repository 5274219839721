/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class ApiAccessLogService {
  /**
   *
   */
  static getApiAccessLogs(
    params: {
      /**  */
      tourId?: string;
      /**  */
      method?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApiAccessLogPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/api-access-logs/get-api-access-logs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        TourId: params['tourId'],
        Method: params['method'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuditLogService {
  /**
   *
   */
  static getAuditLogs(
    params: {
      /**  */
      actor?: string;
      /**  */
      entityName?: string;
      /**  */
      primaryKey?: string;
      /**  */
      dateTimeFrom?: string;
      /**  */
      dateTimeTo?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditLogPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/audit-logs/get-audit-logs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Actor: params['actor'],
        EntityName: params['entityName'],
        PrimaryKey: params['primaryKey'],
        DateTimeFrom: params['dateTimeFrom'],
        DateTimeTo: params['dateTimeTo'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAuditLog(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditLog> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/audit-logs/get-audit-log/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuthService {
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: LoginModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LoginResponseModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/auth/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static refreshToken(
    params: {
      /** requestBody */
      body?: RefreshTokenRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RefreshTokenResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/auth/refresh-token';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgotPassword(
    params: {
      /** requestBody */
      body?: RequestForgotPasswordModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BaseResponseModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/auth/forgot-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /** requestBody */
      body?: ForgotPasswordModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BaseResponseModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/auth/reset-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkForgotPasswordToken(
    params: {
      /**  */
      token: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/auth/check-forgot-password-token/{token}';
      url = url.replace('{token}', params['token'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CmsEmailMessageService {
  /**
   *
   */
  static getEmailMessages(
    params: {
      /**  */
      subject?: string;
      /**  */
      toEmail?: string;
      /**  */
      referenceId?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailMessageDtoPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/email-messages/get-email-messages';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Subject: params['subject'],
        ToEmail: params['toEmail'],
        ReferenceId: params['referenceId'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetail(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailMessage> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/email-messages/get-detail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CountryService {
  /**
   *
   */
  static getCountries(
    params: {
      /**  */
      code?: string;
      /**  */
      shortCode?: string;
      /**  */
      name?: string;
      /**  */
      continent?: Continent;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CountryModelPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/countries/get-countries';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Code: params['code'],
        ShortCode: params['shortCode'],
        Name: params['name'],
        Continent: params['continent'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CountryModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/countries/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CountryModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/countries/update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/countries/delete/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DashboardReportService {
  /**
   *
   */
  static totalTourReport(
    params: {
      /**  */
      startDate: string;
      /**  */
      totalDays?: number;
      /**  */
      status?: TourStatus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TotalTourReportResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/dashboard-reports/total-tour-report';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { StartDate: params['startDate'], TotalDays: params['totalDays'], Status: params['status'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static totalTourByGuide(
    params: {
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TotalTourByGuideResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/dashboard-reports/total-tour-by-guide';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DepartmentService {
  /**
   *
   */
  static getDepartments(
    params: {
      /**  */
      departmentName?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DepartmentPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/departments/get-departments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DepartmentName: params['departmentName'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: DepartmentModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/departments/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: DepartmentModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/departments/update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DraftRequestService {
  /**
   * Get Email/EFax draft request listing by tour ID and guide ID
   */
  static getDraftRequests(
    params: {
      /**  */
      tourId: string;
      /**  */
      guideId: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DraftRequestListingResponsePagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/draft-requests/get-draft-requests';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        TourId: params['tourId'],
        GuideId: params['guideId'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create Email/EFax draft request
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateDraftRequestModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/draft-requests/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update an existing Email/EFax draft request
   */
  static update(
    params: {
      /** requestBody */
      body?: UpdateDraftRequestModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/draft-requests/update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete an existing Email/EFax draft request
   */
  static delete(
    params: {
      /** Id of record that needs to delete */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/draft-requests/delete/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EfaxWebhookService {
  /**
   *
   */
  static webhookCallback(
    params: {
      /** requestBody */
      body?: EFaxCallbackModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/e-fax/webhook-callback';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EmailTemplateService {
  /**
   *
   */
  static getEmailTemplates(
    params: {
      /**  */
      name?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailTemplateModelPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/email-templates/get-email-templates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static emailTemplateNames(options: IRequestOptions = {}): Promise<EmailTemplateNameModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/email-templates/email-template-names';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: EmailTemplateModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/email-templates/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: EmailTemplateModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/email-templates/update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/email-templates/delete/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FacilityService {
  /**
   *
   */
  static getFacilities(
    params: {
      /**  */
      category?: FacilityCategory;
      /**  */
      name?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FacilityPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/facilities/get-facilities';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Category: params['category'],
        Name: params['name'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFacilitiesAutocompletion(
    params: {
      /**  */
      includeSelectedFacilityCode?: string;
      /**  */
      category?: FacilityCategory;
      /**  */
      name?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FacilityAutocompletionModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/facilities/get-facilities-autocompletion';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IncludeSelectedFacilityCode: params['includeSelectedFacilityCode'],
        Category: params['category'],
        Name: params['name'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static import(
    params: {
      /**  */
      fileName: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FacilityImportResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/facilities/import';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['fileName']) {
        if (Object.prototype.toString.call(params['fileName']) === '[object Array]') {
          for (const item of params['fileName']) {
            data.append('FileName', item as any);
          }
        } else {
          data.append('FileName', params['fileName'] as any);
        }
      }

      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('File', item as any);
          }
        } else {
          data.append('File', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/facilities/delete/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FacilityEvaluationService {
  /**
   *
   */
  static getFacilityEvaluations(
    params: {
      /**  */
      tourGuideId?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FacilityEvaluationPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/facility-evaluations/get-facility-evaluations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        TourGuideId: params['tourGuideId'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFacilityEvaluation(
    params: {
      /**  */
      id: string;
      /**  */
      sessionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FacilityEvaluationDetailModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/facility-evaluations/get-facility-evaluation/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { sessionId: params['sessionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateUpdateFacilityEvaluationModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/facility-evaluations/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CreateUpdateFacilityEvaluationModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/facility-evaluations/update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/facility-evaluations/delete/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FileService {
  /**
   *
   */
  static view(
    params: {
      /**  */
      fileId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/file/view/{fileId}';
      url = url.replace('{fileId}', params['fileId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class GuideService {
  /**
   *
   */
  static guideAutocompletion(
    params: {
      /**  */
      languageCode?: string;
      /**  */
      isIncludeDisabledGuide?: boolean;
      /**  */
      includeSelectedGuideId?: string;
      /**  */
      limit?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
      /**  */
      query?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuideAutocompletionDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guides/guide-autocompletion';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        LanguageCode: params['languageCode'],
        IsIncludeDisabledGuide: params['isIncludeDisabledGuide'],
        IncludeSelectedGuideId: params['includeSelectedGuideId'],
        Limit: params['limit'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc'],
        Query: params['query']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static search(
    params: {
      /**  */
      guideId?: string;
      /**  */
      guideName?: string;
      /**  */
      ranks?: GuideRank[];
      /**  */
      prefectures?: any | null[];
      /**  */
      isEnabled?: boolean;
      /**  */
      supportedLanguages?: any | null[];
      /**  */
      hasLicense?: boolean;
      /**  */
      professionalInstitutions?: any | null[];
      /**  */
      otherProfessionalInstitution?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidePagingResultModelPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guides/search';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GuideId: params['guideId'],
        GuideName: params['guideName'],
        Ranks: params['ranks'],
        Prefectures: params['prefectures'],
        IsEnabled: params['isEnabled'],
        SupportedLanguages: params['supportedLanguages'],
        HasLicense: params['hasLicense'],
        ProfessionalInstitutions: params['professionalInstitutions'],
        OtherProfessionalInstitution: params['otherProfessionalInstitution'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByAccountId(
    params: {
      /**  */
      accountId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuideModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guides/get-by-account-id';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { accountId: params['accountId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByUserId(
    params: {
      /**  */
      userId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuideModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guides/get-by-user-id';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuideModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guides/get-by-id';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static register(
    params: {
      /** requestBody */
      body?: GuideModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guides/register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: GuideUpdateModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guides/update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guides/delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class GuideAvailabilityService {
  /**
   *
   */
  static getGuideAvailabilities(
    params: {
      /**  */
      guideId: string;
      /**  */
      month: number;
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetGuideAvailabilityResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guide-availabilities/get-guide-availabilities';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { GuideId: params['guideId'], Month: params['month'], Year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setDateStatus(
    params: {
      /** requestBody */
      body?: SetDateStatusRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BaseResponseModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guide-availabilities/set-date-status';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getGuideListingAvailabilities(
    params: {
      /**  */
      startDate?: string;
      /**  */
      guideId?: string;
      /**  */
      guideName?: string;
      /**  */
      prefectures?: any | null[];
      /**  */
      languages?: any | null[];
      /**  */
      hasLicense?: boolean;
      /**  */
      ranks?: GuideRank[];
      /**  */
      maximumNightAllowed?: number;
      /**  */
      supportedTypes?: GuideSupportedType[];
      /**  */
      supportedAreas?: GuideSupportedArea[];
      /**  */
      enabled?: boolean;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuideListingAvailabilityResponsePagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guide-availabilities/get-guide-listing-availabilities';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StartDate: params['startDate'],
        GuideId: params['guideId'],
        GuideName: params['guideName'],
        Prefectures: params['prefectures'],
        Languages: params['languages'],
        HasLicense: params['hasLicense'],
        Ranks: params['ranks'],
        MaximumNightAllowed: params['maximumNightAllowed'],
        SupportedTypes: params['supportedTypes'],
        SupportedAreas: params['supportedAreas'],
        Enabled: params['enabled'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class GuideSeisanService {
  /**
   *
   */
  static getGuideSeisans(
    params: {
      /**  */
      tourSeisanStatus?: TourSeisanStatus[];
      /**  */
      fileName?: string;
      /**  */
      tourDateFrom?: string;
      /**  */
      tourDateTo?: string;
      /**  */
      submissionDateFrom?: string;
      /**  */
      submissionDateTo?: string;
      /**  */
      tourIdStr?: string;
      /**  */
      seisanNumber?: string;
      /**  */
      tourId?: string;
      /**  */
      tourName?: string;
      /**  */
      saleStaffId?: string;
      /**  */
      saleStaffName?: string;
      /**  */
      agentName?: string;
      /**  */
      guideId?: string;
      /**  */
      guideName?: string;
      /**  */
      guideSeisanStatus?: GuideSeisanStatus[];
      /**  */
      billingAmountFrom?: number;
      /**  */
      billingAmountTo?: number;
      /**  */
      paymentDueDateFrom?: string;
      /**  */
      paymentDueDateTo?: string;
      /**  */
      seisanPicUserName?: string;
      /**  */
      seisanPicName?: string;
      /**  */
      superAccountingUserName?: string;
      /**  */
      superAccountingName?: string;
      /**  */
      transferPersonUserName?: string;
      /**  */
      transferPersonName?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuideSeisanPagingResponsePagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guide-seisans/get-guide-seisans';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        TourSeisanStatus: params['tourSeisanStatus'],
        FileName: params['fileName'],
        TourDateFrom: params['tourDateFrom'],
        TourDateTo: params['tourDateTo'],
        SubmissionDateFrom: params['submissionDateFrom'],
        SubmissionDateTo: params['submissionDateTo'],
        TourIdStr: params['tourIdStr'],
        SeisanNumber: params['seisanNumber'],
        TourId: params['tourId'],
        TourName: params['tourName'],
        SaleStaffId: params['saleStaffId'],
        SaleStaffName: params['saleStaffName'],
        AgentName: params['agentName'],
        GuideId: params['guideId'],
        GuideName: params['guideName'],
        GuideSeisanStatus: params['guideSeisanStatus'],
        BillingAmountFrom: params['billingAmountFrom'],
        BillingAmountTo: params['billingAmountTo'],
        PaymentDueDateFrom: params['paymentDueDateFrom'],
        PaymentDueDateTo: params['paymentDueDateTo'],
        SeisanPicUserName: params['seisanPicUserName'],
        SeisanPicName: params['seisanPicName'],
        SuperAccountingUserName: params['superAccountingUserName'],
        SuperAccountingName: params['superAccountingName'],
        TransferPersonUserName: params['transferPersonUserName'],
        TransferPersonName: params['transferPersonName'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static guideSeisanDetails(
    params: {
      /**  */
      tourGuideId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuideSeisanDetailModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guide-seisans/guide-seisan-details/{tourGuideId}';
      url = url.replace('{tourGuideId}', params['tourGuideId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getGuideSeisanForUpdate(
    params: {
      /**  */
      tourGuideId: string;
      /**  */
      sessionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuideSeisanDetailModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guide-seisans/get-guide-seisan-for-update/{tourGuideId}';
      url = url.replace('{tourGuideId}', params['tourGuideId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { sessionId: params['sessionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getGuideSeisanFiles(
    params: {
      /**  */
      tourGuideId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuideSeisanFileModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guide-seisans/get-guide-seisan-files/{tourGuideId}';
      url = url.replace('{tourGuideId}', params['tourGuideId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadSeisanFile(
    params: {
      /**  */
      seisanId: string;
      /**  */
      fileName: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guide-seisans/upload-seisan-file';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['seisanId']) {
        if (Object.prototype.toString.call(params['seisanId']) === '[object Array]') {
          for (const item of params['seisanId']) {
            data.append('SeisanId', item as any);
          }
        } else {
          data.append('SeisanId', params['seisanId'] as any);
        }
      }

      if (params['fileName']) {
        if (Object.prototype.toString.call(params['fileName']) === '[object Array]') {
          for (const item of params['fileName']) {
            data.append('FileName', item as any);
          }
        } else {
          data.append('FileName', params['fileName'] as any);
        }
      }

      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('File', item as any);
          }
        } else {
          data.append('File', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadSeisanFile(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guide-seisans/download-seisan-file/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteSeisanFile(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guide-seisans/delete-seisan-file/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static guideUpdateSeisan(
    params: {
      /** requestBody */
      body?: GuideUpdateSeisanModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guide-seisans/guide-update-seisan';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static guideSubmitSeisan(
    params: {
      /** requestBody */
      body?: GuideUpdateSeisanModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guide-seisans/guide-submit-seisan';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static guideCompleteRefundSeisan(
    params: {
      /** requestBody */
      body?: GuideCompleteRefundSeisanModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guide-seisans/guide-complete-refund-seisan';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accountingUpdateSeisan(
    params: {
      /** requestBody */
      body?: AccountingUpdateGuideSeisanModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guide-seisans/accounting-update-seisan';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accountingAutoConfirmingSeisan(
    params: {
      /** requestBody */
      body?: AutoConfirmingSeisanRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guide-seisans/accounting-auto-confirming-seisan';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static migrateSeisanRecords(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/guide-seisans/migrate-seisan-records';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class HolidayService {
  /**
   *
   */
  static getHolidays(
    params: {
      /**  */
      name?: string;
      /**  */
      year?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HolidayModelPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/holidays/get-holidays';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        Year: params['year'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllHolidays(
    params: {
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HolidayModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/holidays/get-all-holidays/{year}';
      url = url.replace('{year}', params['year'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: HolidayModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/holidays/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: HolidayModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/holidays/update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/holidays/delete/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LanguageService {
  /**
   *
   */
  static getLanguages(
    params: {
      /**  */
      code?: string;
      /**  */
      name?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LanguageModelPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/languages/get-languages';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Code: params['code'],
        Name: params['name'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static languageAutocompletion(
    params: {
      /**  */
      guideId?: string;
      /**  */
      code?: string;
      /**  */
      name?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LanguageModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/languages/language-autocompletion';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GuideId: params['guideId'],
        Code: params['code'],
        Name: params['name'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: LanguageModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/languages/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: LanguageModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/languages/update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/languages/delete/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LockService {
  /**
   *
   */
  static lock(
    params: {
      /** requestBody */
      body?: LockUnlockRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserLockInfo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/basic-lock/lock';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unlock(
    params: {
      /** requestBody */
      body?: LockUnlockRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/basic-lock/unlock';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static keepSessionAlive(
    params: {
      /** requestBody */
      body?: LockUnlockRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/basic-lock/keep-session-alive';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkSessionAlive(
    params: {
      /** requestBody */
      body?: LockUnlockRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/basic-lock/check-session-alive';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unlockClosedTab(
    params: {
      /**  */
      entityId: string;
      /**  */
      sessionId: string;
      /**  */
      accessToken: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/basic-lock/unlock-closed-tab';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['entityId']) {
        if (Object.prototype.toString.call(params['entityId']) === '[object Array]') {
          for (const item of params['entityId']) {
            data.append('EntityId', item as any);
          }
        } else {
          data.append('EntityId', params['entityId'] as any);
        }
      }

      if (params['sessionId']) {
        if (Object.prototype.toString.call(params['sessionId']) === '[object Array]') {
          for (const item of params['sessionId']) {
            data.append('SessionId', item as any);
          }
        } else {
          data.append('SessionId', params['sessionId'] as any);
        }
      }

      if (params['accessToken']) {
        if (Object.prototype.toString.call(params['accessToken']) === '[object Array]') {
          for (const item of params['accessToken']) {
            data.append('AccessToken', item as any);
          }
        } else {
          data.append('AccessToken', params['accessToken'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MediaService {
  /**
   *
   */
  static uploadFile(
    params: {
      /**  */
      fileName: string;
      /**  */
      formFile: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileUploadResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/media/upload-file';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['fileName']) {
        if (Object.prototype.toString.call(params['fileName']) === '[object Array]') {
          for (const item of params['fileName']) {
            data.append('FileName', item as any);
          }
        } else {
          data.append('FileName', params['fileName'] as any);
        }
      }

      if (params['formFile']) {
        if (Object.prototype.toString.call(params['formFile']) === '[object Array]') {
          for (const item of params['formFile']) {
            data.append('FormFile', item as any);
          }
        } else {
          data.append('FormFile', params['formFile'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadFile(
    params: {
      /**  */
      fileId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/media/download-file/{fileId}';
      url = url.replace('{fileId}', params['fileId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class NotificationService {
  /**
   *
   */
  static search(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      status?: NotificationStatus[];
      /**  */
      subject?: string;
      /**  */
      contents?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotificationDtoPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/notifications/search';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        Status: params['status'],
        Subject: params['subject'],
        Contents: params['contents'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static detail(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotificationDetailModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/notifications/detail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: NotificationModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/notifications/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: NotificationModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/notifications/update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/notifications/delete/{Id}';
      url = url.replace('{Id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RoleService {
  /**
   *
   */
  static getRoles(
    params: {
      /**  */
      name?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RolePagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/roles/get-roles';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoleNames(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/roles/get-role-names';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static save(
    params: {
      /** requestBody */
      body?: CreateUpdateRoleModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/roles/save';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePermissions(
    params: {
      /** requestBody */
      body?: UpdatePermissionsModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/roles/update-permissions';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPermissions(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/roles/get-permissions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllRoles(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/roles/get-all-roles';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/roles/delete/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SmtpSettingService {
  /**
   *
   */
  static smtpSetting(options: IRequestOptions = {}): Promise<SmtpSetting> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/smtp-setting';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: SmtpSettingModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/smtp-setting/update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StaffService {
  /**
   *
   */
  static search(
    params: {
      /**  */
      department?: string;
      /**  */
      subDivision?: string;
      /**  */
      name?: string;
      /**  */
      userPermission?: any | null[];
      /**  */
      displayTerminatedUsers?: boolean;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StaffDtoPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/staffs/search';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Department: params['department'],
        SubDivision: params['subDivision'],
        Name: params['name'],
        UserPermission: params['userPermission'],
        DisplayTerminatedUsers: params['displayTerminatedUsers'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static staffAutocompletion(
    params: {
      /**  */
      isIncludeDisabledStaff?: boolean;
      /**  */
      includeSelectedStaffId?: string;
      /**  */
      limit?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
      /**  */
      query?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StaffAutocompletionModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/staffs/staff-autocompletion';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsIncludeDisabledStaff: params['isIncludeDisabledStaff'],
        IncludeSelectedStaffId: params['includeSelectedStaffId'],
        Limit: params['limit'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc'],
        Query: params['query']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accountingStaffAutocompletion(
    params: {
      /**  */
      limit?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
      /**  */
      query?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StaffAutocompletionModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/staffs/accounting-staff-autocompletion';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Limit: params['limit'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc'],
        Query: params['query']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static detail(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StaffDetailModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/staffs/detail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static query(
    params: {
      /**  */
      q?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/staffs/query';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { q: params['q'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: StaffModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/staffs/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: StaffModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/staffs/update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BaseResponseModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/staffs/delete/{Id}';
      url = url.replace('{Id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SubdivisionService {
  /**
   *
   */
  static getSubdivisions(
    params: {
      /**  */
      subdivisionName?: string;
      /**  */
      departmentId?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubdivisionPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/subdivisions/get-subdivisions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SubdivisionName: params['subdivisionName'],
        DepartmentId: params['departmentId'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: SubdivisionModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/subdivisions/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: SubdivisionModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/subdivisions/update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TemporaryPasswordService {
  /**
   *
   */
  static checkSetPasswordSession(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/temporary/check-set-password-session/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setPassword(
    params: {
      /** requestBody */
      body?: SetPasswordModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BaseResponseModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/temporary/set-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TourService {
  /**
   *
   */
  static getTours(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      tourName?: string;
      /**  */
      tourNumber?: string;
      /**  */
      tourId?: string;
      /**  */
      tourStatus?: TourStatus[];
      /**  */
      displayTourUn?: boolean;
      /**  */
      displayTourGl?: boolean;
      /**  */
      guideId?: string;
      /**  */
      guideName?: string;
      /**  */
      languageCodes?: any | null[];
      /**  */
      guideStatus?: TourGuideStatus[];
      /**  */
      agentName?: string;
      /**  */
      picId?: string;
      /**  */
      picName?: string;
      /**  */
      saleId?: string;
      /**  */
      saleName?: string;
      /**  */
      seisanStatus?: TourSeisanStatus;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TourDtoPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/get-tours';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        TourName: params['tourName'],
        TourNumber: params['tourNumber'],
        TourId: params['tourId'],
        TourStatus: params['tourStatus'],
        DisplayTourUn: params['displayTourUn'],
        DisplayTourGl: params['displayTourGl'],
        GuideId: params['guideId'],
        GuideName: params['guideName'],
        LanguageCodes: params['languageCodes'],
        GuideStatus: params['guideStatus'],
        AgentName: params['agentName'],
        PicId: params['picId'],
        PicName: params['picName'],
        SaleId: params['saleId'],
        SaleName: params['saleName'],
        SeisanStatus: params['seisanStatus'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTourDetail(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TourDetailModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/get-tour-detail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTourForUpdate(
    params: {
      /**  */
      id: string;
      /**  */
      currentRole?: string;
      /**  */
      sessionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TourDetailModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/get-tour-for-update/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { currentRole: params['currentRole'], sessionId: params['sessionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateUpdateTourModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CreateUpdateTourModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateExternalTour(
    params: {
      /** requestBody */
      body?: UpdateExternalTourModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/update-external-tour';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static acceptTourGuide(
    params: {
      /** requestBody */
      body?: GuideActionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuideActionResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/accept-tour-guide';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static denyTourGuide(
    params: {
      /** requestBody */
      body?: GuideActionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuideActionResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/deny-tour-guide';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmTourGuide(
    params: {
      /** requestBody */
      body?: GuideActionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuideActionResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/confirm-tour-guide';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static guideUpdateTour(
    params: {
      /** requestBody */
      body?: GuideUpdateTourModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/guide-update-tour';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadAttachment(
    params: {
      /**  */
      fileId: string;
      /**  */
      url?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/download-attachment/{fileId}';
      url = url.replace('{fileId}', params['fileId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { url: params['url'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unlockTour(
    params: {
      /** requestBody */
      body?: UnlockTourRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BaseResponseModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/unlock-tour';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static keepTourUpdateAlive(
    params: {
      /** requestBody */
      body?: KeepTourAliveRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/keep-tour-update-alive';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkTourSessionAlive(
    params: {
      /**  */
      tourId?: string;
      /**  */
      currentRole?: string;
      /**  */
      sessionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/check-tour-session-alive';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { TourId: params['tourId'], CurrentRole: params['currentRole'], SessionId: params['sessionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTourSeisanInfo(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TourSeisanInfoModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/get-tour-seisan-info/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static testSendEmail(options: IRequestOptions = {}): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/test-send-email';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TourBookingApiService {
  /**
   *
   */
  static getOneTimeToken(options: IRequestOptions = {}): Promise<OneTimeTokenResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/tour/get-one-time-token';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bookGuide(
    params: {
      /** requestBody */
      body?: BookGuideRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BaseApiResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/tour/book-guide';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateGuideBooking(
    params: {
      /** requestBody */
      body?: UpdateGuideBookingRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BaseApiResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/tour/update-guide-booking';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static finalizeGuide(
    params: {
      /** requestBody */
      body?: FinalizeGuideRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BaseApiResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/tour/finalize-guide';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelGuide(
    params: {
      /** requestBody */
      body?: CancelGuideRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BaseApiResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/tour/cancel-guide';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateTour(
    params: {
      /** requestBody */
      body?: UpdateTourRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BaseApiResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/tour/update-tour';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static guideBookingInfo(
    params: {
      /**  */
      tourId: string;
      /**  */
      guideId: string;
      /**  */
      language?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TourBookingData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/tour/guide-booking-info/{tourId}/{guideId}';
      url = url.replace('{tourId}', params['tourId'] + '');
      url = url.replace('{guideId}', params['guideId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { language: params['language'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static guideInfo(
    params: {
      /**  */
      guideId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuideInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/tour/guide-info/{guideId}';
      url = url.replace('{guideId}', params['guideId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TourEvaluationService {
  /**
   *
   */
  static getTourEvaluations(
    params: {
      /**  */
      tourFromDate?: string;
      /**  */
      tourToDate?: string;
      /**  */
      tourName?: string;
      /**  */
      nationalityShortCodes?: any | null[];
      /**  */
      areas?: TourAvailableArea[];
      /**  */
      agentName?: string;
      /**  */
      guideIds?: any | null[];
      /**  */
      facilityCode?: string;
      /**  */
      facilityName?: string;
      /**  */
      category?: FacilityCategory;
      /**  */
      useDayFrom?: string;
      /**  */
      useDayTo?: string;
      /**  */
      evaluationRates?: any | null[];
      /**  */
      advanceSearch?: string;
      /**  */
      showOnlyTourReview?: boolean;
      /**  */
      showOnlyFacilityReview?: boolean;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TourEvaluationDtoPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tour-evaluations/get-tour-evaluations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        TourFromDate: params['tourFromDate'],
        TourToDate: params['tourToDate'],
        TourName: params['tourName'],
        NationalityShortCodes: params['nationalityShortCodes'],
        Areas: params['areas'],
        AgentName: params['agentName'],
        GuideIds: params['guideIds'],
        FacilityCode: params['facilityCode'],
        FacilityName: params['facilityName'],
        Category: params['category'],
        UseDayFrom: params['useDayFrom'],
        UseDayTo: params['useDayTo'],
        EvaluationRates: params['evaluationRates'],
        AdvanceSearch: params['advanceSearch'],
        ShowOnlyTourReview: params['showOnlyTourReview'],
        ShowOnlyFacilityReview: params['showOnlyFacilityReview'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CreateUpdateTourEvaluationModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tour-evaluations/update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static detailByGuide(
    params: {
      /**  */
      tourGuideId: string;
      /**  */
      sessionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TourEvaluationDetailModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tour-evaluations/detail-by-guide/{tourGuideId}';
      url = url.replace('{tourGuideId}', params['tourGuideId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { sessionId: params['sessionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static export(
    params: {
      /**  */
      tourFromDate?: string;
      /**  */
      tourToDate?: string;
      /**  */
      tourName?: string;
      /**  */
      nationalityShortCodes?: any | null[];
      /**  */
      areas?: TourAvailableArea[];
      /**  */
      agentName?: string;
      /**  */
      guideIds?: any | null[];
      /**  */
      facilityCode?: string;
      /**  */
      facilityName?: string;
      /**  */
      category?: FacilityCategory;
      /**  */
      useDayFrom?: string;
      /**  */
      useDayTo?: string;
      /**  */
      evaluationRates?: any | null[];
      /**  */
      advanceSearch?: string;
      /**  */
      showOnlyTourReview?: boolean;
      /**  */
      showOnlyFacilityReview?: boolean;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tour-evaluations/export';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        TourFromDate: params['tourFromDate'],
        TourToDate: params['tourToDate'],
        TourName: params['tourName'],
        NationalityShortCodes: params['nationalityShortCodes'],
        Areas: params['areas'],
        AgentName: params['agentName'],
        GuideIds: params['guideIds'],
        FacilityCode: params['facilityCode'],
        FacilityName: params['facilityName'],
        Category: params['category'],
        UseDayFrom: params['useDayFrom'],
        UseDayTo: params['useDayTo'],
        EvaluationRates: params['evaluationRates'],
        AdvanceSearch: params['advanceSearch'],
        ShowOnlyTourReview: params['showOnlyTourReview'],
        ShowOnlyFacilityReview: params['showOnlyFacilityReview'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TourLockUnLockService {
  /**
   *
   */
  static unlockClosedTab(
    params: {
      /**  */
      tourId: string;
      /**  */
      currentRole: string;
      /**  */
      sessionId: string;
      /**  */
      accessToken: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tours/lock/unlock-closed-tab';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['tourId']) {
        if (Object.prototype.toString.call(params['tourId']) === '[object Array]') {
          for (const item of params['tourId']) {
            data.append('TourId', item as any);
          }
        } else {
          data.append('TourId', params['tourId'] as any);
        }
      }

      if (params['currentRole']) {
        if (Object.prototype.toString.call(params['currentRole']) === '[object Array]') {
          for (const item of params['currentRole']) {
            data.append('CurrentRole', item as any);
          }
        } else {
          data.append('CurrentRole', params['currentRole'] as any);
        }
      }

      if (params['sessionId']) {
        if (Object.prototype.toString.call(params['sessionId']) === '[object Array]') {
          for (const item of params['sessionId']) {
            data.append('SessionId', item as any);
          }
        } else {
          data.append('SessionId', params['sessionId'] as any);
        }
      }

      if (params['accessToken']) {
        if (Object.prototype.toString.call(params['accessToken']) === '[object Array]') {
          for (const item of params['accessToken']) {
            data.append('AccessToken', item as any);
          }
        } else {
          data.append('AccessToken', params['accessToken'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TourMessageService {
  /**
   * Get tour message listing
   */
  static getMessages(
    params: {
      /**  */
      messageType?: TourMessageType;
      /**  */
      subject?: string;
      /**  */
      bodyContent?: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      tourName?: string;
      /**  */
      tourId?: string;
      /**  */
      facilityName?: string;
      /**  */
      sendTo?: string;
      /**  */
      status?: TourMessageStatus;
      /**  */
      guideId?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TourMessagePagingResponsePagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tour-messages/get-messages';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        MessageType: params['messageType'],
        Subject: params['subject'],
        BodyContent: params['bodyContent'],
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        TourName: params['tourName'],
        TourId: params['tourId'],
        FacilityName: params['facilityName'],
        SendTo: params['sendTo'],
        Status: params['status'],
        GuideId: params['guideId'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Tour Message Detail By Id
   */
  static getDetails(
    params: {
      /** id of requesting record */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TourMessageDetailModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tour-messages/get-details/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendMessage(
    params: {
      /** requestBody */
      body?: SendTourMessageRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BaseResponseModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tour-messages/send-message';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resendMessage(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BaseResponseModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tour-messages/resend-message/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateEmailContent(
    params: {
      /** requestBody */
      body?: GenerateTourMessageContentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tour-messages/generate-email-content';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static registerEfaxWebhook(options: IRequestOptions = {}): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tour-messages/register-efax-webhook';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadEfaxPdf(
    params: {
      /** requestBody */
      body?: SendTourMessageRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/tour-messages/download-efax-pdf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UnityNextApiService {
  /**
   *
   */
  static getGuideListingAvailabilities(
    params: {
      /**  */
      startDate?: string;
      /**  */
      guideId?: string;
      /**  */
      guideName?: string;
      /**  */
      prefectures?: any | null[];
      /**  */
      languages?: any | null[];
      /**  */
      hasLicense?: boolean;
      /**  */
      ranks?: GuideRank[];
      /**  */
      maximumNightAllowed?: number;
      /**  */
      supportedTypes?: GuideSupportedType[];
      /**  */
      supportedAreas?: GuideSupportedArea[];
      /**  */
      enabled?: boolean;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuideListingAvailabilityResponsePagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/un/get-guide-listing-availabilities';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StartDate: params['startDate'],
        GuideId: params['guideId'],
        GuideName: params['guideName'],
        Prefectures: params['prefectures'],
        Languages: params['languages'],
        HasLicense: params['hasLicense'],
        Ranks: params['ranks'],
        MaximumNightAllowed: params['maximumNightAllowed'],
        SupportedTypes: params['supportedTypes'],
        SupportedAreas: params['supportedAreas'],
        Enabled: params['enabled'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getGuideInfo(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuideModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/un/get-guide-info/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLanguages(
    params: {
      /**  */
      code?: string;
      /**  */
      name?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LanguageModelPagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/un/get-languages';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Code: params['code'],
        Name: params['name'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static guideAutocompletion(
    params: {
      /**  */
      languageCode?: string;
      /**  */
      isIncludeDisabledGuide?: boolean;
      /**  */
      includeSelectedGuideId?: string;
      /**  */
      limit?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
      /**  */
      query?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuideAutocompletionDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/un/guide-autocompletion';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        LanguageCode: params['languageCode'],
        IsIncludeDisabledGuide: params['isIncludeDisabledGuide'],
        IncludeSelectedGuideId: params['includeSelectedGuideId'],
        Limit: params['limit'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc'],
        Query: params['query']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllHolidays(
    params: {
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HolidayModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/un/get-all-holidays/{year}';
      url = url.replace('{year}', params['year'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UserService {
  /**
   *
   */
  static getUsers(
    params: {
      /**  */
      fullName?: string;
      /**  */
      userName?: string;
      /**  */
      email?: string;
      /**  */
      role?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      orderBy?: string;
      /**  */
      orderByAsc?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserPagingResponsePagingResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/users/get-users';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FullName: params['fullName'],
        UserName: params['userName'],
        Email: params['email'],
        Role: params['role'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        OrderBy: params['orderBy'],
        OrderByAsc: params['orderByAsc']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static currentUser(options: IRequestOptions = {}): Promise<CurrentUserModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/users/current-user';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static save(
    params: {
      /** requestBody */
      body?: CreateUpdateUserModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/users/save';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateRoles(
    params: {
      /** requestBody */
      body?: User;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringBaseUpdateModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/users/update-roles';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /** requestBody */
      body?: ChangePasswordModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BaseResponseModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cms/users/change-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface AccountingUpdateGuideSeisanModel {
  /**  */
  id: string;

  /**  */
  sessionId: string;

  /**  */
  seisanNumber?: string;

  /**  */
  status: GuideSeisanStatus;

  /**  */
  amountCarrying: number;

  /**  */
  paymentDueDate?: Date;

  /**  */
  paymentCost?: number;

  /**  */
  taxWithheldAmount?: number;

  /**  */
  ntaRemark?: string;
}

export interface ApiAccessLog {
  /**  */
  id?: string;

  /**  */
  tourNumber?: string;

  /**  */
  url?: string;

  /**  */
  actionName?: string;

  /**  */
  request?: string;

  /**  */
  response?: string;

  /**  */
  startTime?: Date;

  /**  */
  endTime?: Date;

  /**  */
  duration?: number;
}

export interface ApiAccessLogPagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: ApiAccessLog[];
}

export interface AuditLog {
  /**  */
  id?: string;

  /**  */
  logTime?: Date;

  /**  */
  actor?: string;

  /**  */
  entityName?: string;

  /**  */
  primaryKey?: string;

  /**  */
  changeValues?: ValueChange[];

  /**  */
  auditType?: AuditType;
}

export interface AuditLogPagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: AuditLog[];
}

export interface AutoConfirmingSeisanRequest {
  /**  */
  id: string;

  /**  */
  fileId: string;

  /**  */
  sessionId: string;

  /**  */
  amountCarrying: number;

  /**  */
  ntaRemark?: string;

  /**  */
  seisanNumber: string;

  /**  */
  paymentDueDate?: Date;

  /**  */
  paymentCost?: number;

  /**  */
  taxWithheldAmount?: number;
}

export interface AvailableTour {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  area?: TourAvailableArea;

  /**  */
  tourStatus?: TourStatus;

  /**  */
  tourGuides?: AvailableTourGuide[];
}

export interface AvailableTourGuide {
  /**  */
  id?: string;

  /**  */
  status?: TourGuideStatus;

  /**  */
  businessDays?: string[];
}

export interface BaseApiResponse {
  /**  */
  respCode: string;

  /**  */
  respDesc: string;
}

export interface BaseResponseModel {
  /**  */
  success?: boolean;

  /**  */
  message?: string;
}

export interface BookGuideRequest {
  /**  */
  fromDate: Date;

  /**  */
  toDate: Date;

  /**  */
  bookingCategory?: ReservationForm;

  /**  */
  tourPurpose?: TourPurpose;

  /**  */
  tourName: string;

  /**  */
  tourNumber?: string;

  /**  */
  tourId: string;

  /**  */
  guestNationality: string;

  /**  */
  agentName?: string;

  /**  */
  saleStaff?: string;

  /**  */
  saleStaffEmail?: string;

  /**  */
  tourPIC?: string;

  /**  */
  tourPICEmail?: string;

  /**  */
  requesterEmail: string;

  /**  */
  requesterName: string;

  /**  */
  noOfAdultEstimated: number;

  /**  */
  noOfChildAEstimated: number;

  /**  */
  noOfChildBEstimated: number;

  /**  */
  noOfInfantEstimated: number;

  /**  */
  noOfLeadEstimated: number;

  /**  */
  noOfAdultBooked?: number;

  /**  */
  noOfChildABooked?: number;

  /**  */
  noOfChildBBooked?: number;

  /**  */
  noOfInfantBooked?: number;

  /**  */
  noOfLeadBooked?: number;

  /**  */
  status: TourBookingStatus;

  /**  */
  guideId: string;

  /**  */
  language: string;

  /**  */
  remark?: string;

  /**  */
  attachments?: string[];

  /**  */
  businessDays: BusinessDayRequestForBook[];
}

export interface BusinessDay {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  tourGuideId: string;

  /**  */
  code: string;

  /**  */
  date?: Date;

  /**  */
  fee?: number;

  /**  */
  availability: boolean;

  /**  */
  stayOvernight: BooleanEnumType;

  /**  */
  stayLastNight: BooleanEnumType;

  /**  */
  isConfirmed?: boolean;

  /**  */
  isCancelled?: boolean;

  /**  */
  tourGuide?: TourGuide;
}

export interface BusinessDayBookingInfo {
  /**  */
  businessDayId: string;

  /**  */
  businessDay: Date;

  /**  */
  guideFee?: number;

  /**  */
  isCancelled: boolean;

  /**  */
  availability: boolean;

  /**  */
  stayOvernight: BooleanEnumType;

  /**  */
  stayLastNight: BooleanEnumType;
}

export interface BusinessDayDetailModel {
  /**  */
  id?: string;

  /**  */
  tourGuideId?: string;

  /**  */
  code?: string;

  /**  */
  date?: Date;

  /**  */
  fee?: number;

  /**  */
  availability?: boolean;

  /**  */
  stayOvernight?: BooleanEnumType;

  /**  */
  stayLastNight?: BooleanEnumType;

  /**  */
  isConfirmed?: boolean;

  /**  */
  isCancelled?: boolean;
}

export interface BusinessDayRequestForBook {
  /**  */
  businessDay: Date;

  /**  */
  guideFee: number;

  /**  */
  stayOvernight?: boolean;

  /**  */
  stayLastNight?: boolean;
}

export interface BusinessDayRequestForFinalize {
  /**  */
  businessDay: Date;

  /**  */
  guideFee: number;

  /**  */
  stayOvernight?: boolean;

  /**  */
  stayLastNight?: boolean;

  /**  */
  businessDayId?: string;
}

export interface BusinessDayRequestForUpdate {
  /**  */
  businessDay: Date;

  /**  */
  guideFee: number;

  /**  */
  stayOvernight?: boolean;

  /**  */
  stayLastNight?: boolean;

  /**  */
  businessDayId: string;
}

export interface CancelGuideRequest {
  /**  */
  tourId: string;

  /**  */
  guideId: string;

  /**  */
  language: string;

  /**  */
  businessDayIds: string[];

  /**  */
  requesterName: string;

  /**  */
  requesterEmail: string;
}

export interface ChangePasswordModel {
  /**  */
  oldPassword?: string;

  /**  */
  password?: string;
}

export interface Country {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  code: string;

  /**  */
  shortCode: string;

  /**  */
  phoneCode: string;

  /**  */
  continent: Continent;

  /**  */
  name: string;

  /**  */
  tours?: Tour[];

  /**  */
  guides?: Guide[];
}

export interface CountryModel {
  /**  */
  id?: string;

  /**  */
  code: string;

  /**  */
  shortCode: string;

  /**  */
  phoneCode: string;

  /**  */
  continent: Continent;

  /**  */
  name?: string;
}

export interface CountryModelPagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: CountryModel[];
}

export interface CreateDraftRequestModel {
  /**  */
  tourId: string;

  /**  */
  guideId: string;

  /**  */
  requestType: TourMessageType;

  /**  */
  facilityName?: string;

  /**  */
  sendTo?: string;

  /**  */
  subject?: string;

  /**  */
  body?: string;

  /**  */
  attachmentFiles?: TourMessageFileAttachment[];
}

export interface CreateUpdateBusinessDayModel {
  /**  */
  id?: string;

  /**  */
  date?: Date;

  /**  */
  fee?: number;

  /**  */
  isCancelled?: boolean;

  /**  */
  availability?: boolean;

  /**  */
  stayOvernight: BooleanEnumType;

  /**  */
  stayLastNight: BooleanEnumType;
}

export interface CreateUpdateFacilityEvaluationModel {
  /**  */
  id?: string;

  /**  */
  tourGuideId: string;

  /**  */
  useDate: Date;

  /**  */
  category: FacilityCategory;

  /**  */
  facilityCode?: string;

  /**  */
  facilityName?: string;

  /**  */
  comprehensiveRate: number;

  /**  */
  facilityRate?: number;

  /**  */
  roomRate?: number;

  /**  */
  customerServiceRate?: number;

  /**  */
  bathRate?: number;

  /**  */
  mealRate?: number;

  /**  */
  contentsRate?: number;

  /**  */
  mealType?: MealType;

  /**  */
  driverName?: string;

  /**  */
  details?: string;

  /**  */
  hotelMealFacilityRate?: number;

  /**  */
  hotelMealContentRate?: number;

  /**  */
  hotelMealCustomerServiceRate?: number;

  /**  */
  hotelMealCrowdedness?: HotelMealCrowdedness;

  /**  */
  planedStartTime?: string;

  /**  */
  actualStartTime?: string;

  /**  */
  planedEndTime?: string;

  /**  */
  actualEndTime?: string;

  /**  */
  idealStayTime?: string;

  /**  */
  recommendationInSameArea?: string;

  /**  */
  customerReaction?: string;

  /**  */
  parkingLotsEvaluation?: string;

  /**  */
  sessionId?: string;
}

export interface CreateUpdateRoleModel {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface CreateUpdateTourEvaluationModel {
  /**  */
  tourGuideId: string;

  /**  */
  tourImpression?: string;

  /**  */
  comprehensiveRate?: number;

  /**  */
  customerReaction?: string;

  /**  */
  tourLeaderEvaluation?: string;

  /**  */
  tourLeaderName?: string;

  /**  */
  serviceGoodPoints?: string;

  /**  */
  serviceImprovement?: string;

  /**  */
  accommodationComprehensiveEvaluation?: string;

  /**  */
  accommodationComprehensiveRate?: number;

  /**  */
  restaurantComprehensiveEvaluation?: string;

  /**  */
  restaurantComprehensiveRate?: number;

  /**  */
  otherComments?: string;

  /**  */
  sessionId?: string;
}

export interface CreateUpdateTourGuideModel {
  /**  */
  id?: string;

  /**  */
  guideId?: string;

  /**  */
  guideName?: string;

  /**  */
  languageCode?: string;

  /**  */
  languageName?: string;

  /**  */
  status?: TourGuideStatus;

  /**  */
  displayCancelledDates?: boolean;

  /**  */
  businessDays?: CreateUpdateBusinessDayModel[];

  /**  */
  attachments?: string[];

  /**  */
  briefingType?: BriefingType;

  /**  */
  briefingDate?: Date;

  /**  */
  briefingTime?: string;

  /**  */
  expectedDateOfMaterials?: Date;

  /**  */
  desiredBriefingDate1?: Date;

  /**  */
  desiredBriefingTime1?: string;

  /**  */
  desiredDateOfMaterial1?: Date;

  /**  */
  desiredTimeOfMaterial1?: string;

  /**  */
  desiredBriefingDate2?: Date;

  /**  */
  desiredBriefingTime2?: string;

  /**  */
  desiredDateOfMaterial2?: Date;

  /**  */
  desiredTimeOfMaterial2?: string;

  /**  */
  desiredBriefingDate3?: Date;

  /**  */
  desiredBriefingTime3?: string;

  /**  */
  desiredDateOfMaterial3?: Date;

  /**  */
  desiredTimeOfMaterial3?: string;

  /**  */
  ntaComments?: string;
}

export interface CreateUpdateTourModel {
  /**  */
  id?: string;

  /**  */
  fromDate: Date;

  /**  */
  toDate: Date;

  /**  */
  status: TourStatus;

  /**  */
  reservationForm?: ReservationForm;

  /**  */
  tourPurpose?: TourPurpose;

  /**  */
  tourName: string;

  /**  */
  tourId?: string;

  /**  */
  tourNumber?: string;

  /**  */
  guestNationalityCode?: string;

  /**  */
  agentName?: string;

  /**  */
  saleStaffId?: string;

  /**  */
  saleStaffName?: string;

  /**  */
  picId?: string;

  /**  */
  picName?: string;

  /**  */
  arrangementPicId?: string;

  /**  */
  arrangementPicName?: string;

  /**  */
  noOfAdultEstimated?: number;

  /**  */
  noOfChildAEstimated?: number;

  /**  */
  noOfChildBEstimated?: number;

  /**  */
  noOfInfantEstimated?: number;

  /**  */
  noOfLeadEstimated?: number;

  /**  */
  noOfAdultBooked?: number;

  /**  */
  noOfChildABooked?: number;

  /**  */
  noOfChildBBooked?: number;

  /**  */
  noOfInfantBooked?: number;

  /**  */
  noOfLeadBooked?: number;

  /**  */
  hideGuides: boolean;

  /**  */
  sessionId?: string;

  /**  */
  tourGuides?: CreateUpdateTourGuideModel[];

  /**  */
  allowSendConfirmGuideAcceptanceEmail?: boolean;
}

export interface CreateUpdateUserModel {
  /**  */
  id?: string;

  /**  */
  fullName?: string;

  /**  */
  userName?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  password?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  roleClaims?: string[];

  /**  */
  lockoutEnabled?: boolean;
}

export interface CurrentUserModel {
  /**  */
  id?: string;

  /**  */
  username?: string;

  /**  */
  email?: string;

  /**  */
  fullname?: string;

  /**  */
  roleClaims?: string[];

  /**  */
  departmentId?: string;

  /**  */
  subDivisionId?: string;

  /**  */
  viewPermission?: string;

  /**  */
  staffId?: string;
}

export interface DayGuideAvailability {
  /**  */
  date?: Date;

  /**  */
  isUnavailable?: boolean;

  /**  */
  isWfAnotherDepartment?: boolean;

  /**  */
  stayOvernight?: boolean;

  /**  */
  stayLastNight?: boolean;

  /**  */
  stayInTours?: TourStayInOvernightOrLastNight[];

  /**  */
  wfAnotherDepartmentDetails?: string;
}

export interface DayOfTour {
  /**  */
  date?: Date;

  /**  */
  manualTour?: number;

  /**  */
  unTour?: number;
}

export interface Department {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  departmentName: string;

  /**  */
  staffs?: Staff[];
}

export interface DepartmentModel {
  /**  */
  id?: string;

  /**  */
  departmentName: string;

  /**  */
  createdAt?: Date;
}

export interface DepartmentPagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: Department[];
}

export interface DraftRequestAttachmentModel {
  /**  */
  fileId?: string;

  /**  */
  fileName?: string;

  /**  */
  password?: string;
}

export interface DraftRequestListingResponse {
  /**  */
  id?: string;

  /**  */
  requestType?: TourMessageType;

  /**  */
  facilityName?: string;

  /**  */
  sendTo?: string;

  /**  */
  subject?: string;

  /**  */
  body?: string;

  /**  */
  attachments?: DraftRequestAttachmentModel[];

  /**  */
  createdAt?: Date;

  /**  */
  updatedAt?: Date;
}

export interface DraftRequestListingResponsePagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: DraftRequestListingResponse[];
}

export interface EFaxCallbackModel {
  /**  */
  eventType?: string;

  /**  */
  faxId?: string;
}

export interface EmailMessage {
  /**  */
  id?: string;

  /**  */
  referenceId?: string;

  /**  */
  fromAddress?: string;

  /**  */
  fromName?: string;

  /**  */
  toEmails?: string;

  /**  */
  ccEmails?: string;

  /**  */
  bccEmails?: string;

  /**  */
  subject?: string;

  /**  */
  bodyContent?: string;

  /**  */
  createdOnUtc?: Date;

  /**  */
  sentOnUtc?: Date;

  /**  */
  error?: string;

  /**  */
  isSent?: boolean;
}

export interface EmailMessageDto {
  /**  */
  id?: string;

  /**  */
  referenceId?: string;

  /**  */
  fromAddress?: string;

  /**  */
  fromName?: string;

  /**  */
  toEmails?: string;

  /**  */
  ccEmails?: string;

  /**  */
  bccEmails?: string;

  /**  */
  subject?: string;

  /**  */
  bodyContent?: string;

  /**  */
  createdOnUtc?: Date;

  /**  */
  sentOnUtc?: Date;

  /**  */
  error?: string;
}

export interface EmailMessageDtoPagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: EmailMessageDto[];
}

export interface EmailTemplateModel {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  subject?: string;

  /**  */
  body?: string;

  /**  */
  includeAttachment?: boolean;

  /**  */
  enabled?: boolean;

  /**  */
  toEmails?: string;

  /**  */
  ccEmails?: string;

  /**  */
  bccEmails?: string;
}

export interface EmailTemplateModelPagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: EmailTemplateModel[];
}

export interface EmailTemplateNameModel {
  /**  */
  key?: string;

  /**  */
  name?: string;
}

export interface Facility {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  code?: string;

  /**  */
  category?: FacilityCategory;

  /**  */
  name?: string;

  /**  */
  facilityEvaluations?: FacilityEvaluation[];
}

export interface FacilityAutocompletionModel {
  /**  */
  code?: string;

  /**  */
  name?: string;
}

export interface FacilityEvaluation {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  tourGuideId?: string;

  /**  */
  tourId?: string;

  /**  */
  guideId?: string;

  /**  */
  useDate?: Date;

  /**  */
  category?: FacilityCategory;

  /**  */
  facilityCode?: string;

  /**  */
  facilityName?: string;

  /**  */
  comprehensiveRate?: number;

  /**  */
  facilityRate?: number;

  /**  */
  roomRate?: number;

  /**  */
  customerServiceRate?: number;

  /**  */
  bathRate?: number;

  /**  */
  mealRate?: number;

  /**  */
  contentsRate?: number;

  /**  */
  mealType?: MealType;

  /**  */
  driverName?: string;

  /**  */
  details?: string;

  /**  */
  hotelMealFacilityRate?: number;

  /**  */
  hotelMealContentRate?: number;

  /**  */
  hotelMealCustomerServiceRate?: number;

  /**  */
  hotelMealCrowdedness?: HotelMealCrowdedness;

  /**  */
  planedStartTime?: string;

  /**  */
  actualStartTime?: string;

  /**  */
  planedEndTime?: string;

  /**  */
  actualEndTime?: string;

  /**  */
  idealStayTime?: string;

  /**  */
  recommendationInSameArea?: string;

  /**  */
  customerReaction?: string;

  /**  */
  parkingLotsEvaluation?: string;

  /**  */
  tour?: Tour;

  /**  */
  tourGuide?: TourGuide;

  /**  */
  guide?: Guide;

  /**  */
  facility?: Facility;
}

export interface FacilityEvaluationDetailModel {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  tourGuideId?: string;

  /**  */
  tourId?: string;

  /**  */
  guideId?: string;

  /**  */
  useDate?: Date;

  /**  */
  category?: FacilityCategory;

  /**  */
  facilityCode?: string;

  /**  */
  facilityName?: string;

  /**  */
  comprehensiveRate?: number;

  /**  */
  facilityRate?: number;

  /**  */
  roomRate?: number;

  /**  */
  customerServiceRate?: number;

  /**  */
  bathRate?: number;

  /**  */
  mealRate?: number;

  /**  */
  contentsRate?: number;

  /**  */
  mealType?: MealType;

  /**  */
  driverName?: string;

  /**  */
  details?: string;

  /**  */
  hotelMealFacilityRate?: number;

  /**  */
  hotelMealContentRate?: number;

  /**  */
  hotelMealCustomerServiceRate?: number;

  /**  */
  hotelMealCrowdedness?: HotelMealCrowdedness;

  /**  */
  planedStartTime?: string;

  /**  */
  actualStartTime?: string;

  /**  */
  planedEndTime?: string;

  /**  */
  actualEndTime?: string;

  /**  */
  idealStayTime?: string;

  /**  */
  recommendationInSameArea?: string;

  /**  */
  customerReaction?: string;

  /**  */
  parkingLotsEvaluation?: string;

  /**  */
  isLocked?: boolean;

  /**  */
  lockedBy?: string;
}

export interface FacilityEvaluationDto {
  /**  */
  id?: string;

  /**  */
  tourGuideId?: string;

  /**  */
  guideId?: string;

  /**  */
  guideName?: string;

  /**  */
  useDate?: Date;

  /**  */
  category?: FacilityCategory;

  /**  */
  facilityCode?: string;

  /**  */
  facilityName?: string;

  /**  */
  comprehensiveRate?: number;

  /**  */
  facilityRate?: number;

  /**  */
  roomRate?: number;

  /**  */
  customerServiceRate?: number;

  /**  */
  mealRate?: number;

  /**  */
  contentsRate?: number;

  /**  */
  driverName?: string;

  /**  */
  details?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  lastUpdatedAt?: Date;

  /**  */
  lastUpdatedBy?: string;
}

export interface FacilityEvaluationPagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: FacilityEvaluation[];
}

export interface FacilityImportResponse {
  /**  */
  success?: boolean;

  /**  */
  message?: string;

  /**  */
  errorDetail?: string;
}

export interface FacilityPagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: Facility[];
}

export interface FileUploadResponse {
  /**  */
  success?: boolean;

  /**  */
  message?: string;

  /**  */
  fileId?: string;

  /**  */
  fileName?: string;
}

export interface FinalizeGuideRequest {
  /**  */
  fromDate: Date;

  /**  */
  toDate: Date;

  /**  */
  bookingCategory?: ReservationForm;

  /**  */
  tourPurpose?: TourPurpose;

  /**  */
  tourName: string;

  /**  */
  tourNumber?: string;

  /**  */
  tourId: string;

  /**  */
  guestNationality: string;

  /**  */
  agentName?: string;

  /**  */
  saleStaff?: string;

  /**  */
  saleStaffEmail?: string;

  /**  */
  tourPIC?: string;

  /**  */
  tourPICEmail?: string;

  /**  */
  requesterEmail: string;

  /**  */
  requesterName: string;

  /**  */
  noOfAdultEstimated: number;

  /**  */
  noOfChildAEstimated: number;

  /**  */
  noOfChildBEstimated: number;

  /**  */
  noOfInfantEstimated: number;

  /**  */
  noOfLeadEstimated: number;

  /**  */
  noOfAdultBooked?: number;

  /**  */
  noOfChildABooked?: number;

  /**  */
  noOfChildBBooked?: number;

  /**  */
  noOfInfantBooked?: number;

  /**  */
  noOfLeadBooked?: number;

  /**  */
  status: TourBookingStatus;

  /**  */
  guideId: string;

  /**  */
  language: string;

  /**  */
  remark?: string;

  /**  */
  attachments?: string[];

  /**  */
  businessDays: BusinessDayRequestForFinalize[];
}

export interface ForgotPasswordModel {
  /**  */
  token?: string;

  /**  */
  newPassword?: string;
}

export interface GenerateTourMessageContentRequest {
  /**  */
  tourId: string;

  /**  */
  guideId: string;
}

export interface GetGuideAvailabilityResponse {
  /**  */
  success?: boolean;

  /**  */
  message?: string;

  /**  */
  days?: DayGuideAvailability[];

  /**  */
  availableTours?: AvailableTour[];
}

export interface GuidBaseUpdateModel {
  /**  */
  success?: boolean;

  /**  */
  message?: string;

  /**  */
  id?: string;
}

export interface Guide {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  userId: string;

  /**  */
  profileImageId?: string;

  /**  */
  profileImage?: Media;

  /**  */
  businessCardFrontId?: string;

  /**  */
  businessCardFront?: Media;

  /**  */
  businessCardBackId?: string;

  /**  */
  businessCardBack?: Media;

  /**  */
  accountId: string;

  /**  */
  guideRank?: GuideRank;

  /**  */
  enabled: boolean;

  /**  */
  lastNameKanji: string;

  /**  */
  firstNameKanji: string;

  /**  */
  lastNameKatakana?: string;

  /**  */
  firstNameKatakana?: string;

  /**  */
  lastNameEnglish?: string;

  /**  */
  firstNameEnglish?: string;

  /**  */
  fullName?: string;

  /**  */
  nickName?: string;

  /**  */
  gender?: GuideGender;

  /**  */
  birthDate?: Date;

  /**  */
  nationality?: string;

  /**  */
  mobilePhone?: string;

  /**  */
  homePhone?: string;

  /**  */
  fax?: string;

  /**  */
  emailAddress1?: string;

  /**  */
  emailAddress2?: string;

  /**  */
  zipCode?: string;

  /**  */
  prefecture?: string;

  /**  */
  address?: string;

  /**  */
  buildingName?: string;

  /**  */
  nearestStation?: string;

  /**  */
  trainLine?: string;

  /**  */
  isPersonalBusinessRegistration: boolean;

  /**  */
  personalBusinessRegistrationNumber?: string;

  /**  */
  professionalInstitutions?: string[];

  /**  */
  otherProfessionalInstitution?: string;

  /**  */
  maxNumberOfNights: number;

  /**  */
  maxNumber: number;

  /**  */
  hasInterpretationExperience?: boolean;

  /**  */
  interpretationExperienceDetails?: string;

  /**  */
  supportedTypes?: GuideSupportedType[];

  /**  */
  supportedAreas?: GuideSupportedArea[];

  /**  */
  selfIntroduction?: string;

  /**  */
  overseaExperiences?: GuideOverseaExperience[];

  /**  */
  resumeUploadId?: string;

  /**  */
  resumeUpload?: Media;

  /**  */
  cvUploadId?: string;

  /**  */
  cvUpload?: Media;

  /**  */
  memo?: string;

  /**  */
  bankName?: string;

  /**  */
  branchName?: string;

  /**  */
  bankAccountType?: BankAccountType;

  /**  */
  bankAccountNumber?: string;

  /**  */
  bankAccountHolder?: string;

  /**  */
  internalMemo?: string;

  /**  */
  ratings?: GuideRating[];

  /**  */
  country?: Country;

  /**  */
  tourGuides?: TourGuide[];

  /**  */
  supportedLanguages?: GuideSupportedLanguage[];

  /**  */
  tourEvaluations?: TourEvaluation[];

  /**  */
  facilityEvaluations?: FacilityEvaluation[];
}

export interface GuideActionBusinessDayRequest {
  /**  */
  id: string;

  /**  */
  availability: boolean;

  /**  */
  stayOvernight: BooleanEnumType;

  /**  */
  stayLastNight: BooleanEnumType;
}

export interface GuideActionRequest {
  /**  */
  tourGuideId: string;

  /**  */
  sessionId: string;

  /**  */
  businessDays: GuideActionBusinessDayRequest[];

  /**  */
  briefingType?: BriefingType;

  /**  */
  desiredBriefingDate1?: Date;

  /**  */
  desiredBriefingTime1?: string;

  /**  */
  desiredDateOfMaterial1?: Date;

  /**  */
  desiredTimeOfMaterial1?: string;

  /**  */
  desiredBriefingDate2?: Date;

  /**  */
  desiredBriefingTime2?: string;

  /**  */
  desiredDateOfMaterial2?: Date;

  /**  */
  desiredTimeOfMaterial2?: string;

  /**  */
  desiredBriefingDate3?: Date;

  /**  */
  desiredBriefingTime3?: string;

  /**  */
  desiredDateOfMaterial3?: Date;

  /**  */
  desiredTimeOfMaterial3?: string;

  /**  */
  comment?: string;
}

export interface GuideActionResponse {
  /**  */
  success?: boolean;

  /**  */
  message?: string;

  /**  */
  id?: string;

  /**  */
  tourGuide?: TourGuide;
}

export interface GuideAutocompletionDto {
  /**  */
  id?: string;

  /**  */
  firstNameKanji?: string;

  /**  */
  lastNameKanji?: string;

  /**  */
  lastNameKatakana?: string;

  /**  */
  firstNameKatakana?: string;

  /**  */
  lastNameEnglish?: string;

  /**  */
  firstNameEnglish?: string;

  /**  */
  nickName?: string;
}

export interface GuideBankAccountInformation {
  /**  */
  bankName?: string;

  /**  */
  branchName?: string;

  /**  */
  accountType?: string;

  /**  */
  accountNumber?: string;

  /**  */
  accountHolder?: string;
}

export interface GuideBookingInfo {
  /**  */
  guideId: string;

  /**  */
  guideName: string;

  /**  */
  language: string;

  /**  */
  status: TourGuideStatus;

  /**  */
  guideComments?: string;

  /**  */
  businessDays?: BusinessDayBookingInfo[];
}

export interface GuideCompleteRefundSeisanModel {
  /**  */
  id: string;

  /**  */
  sessionId: string;

  /**  */
  amountCarrying: number;

  /**  */
  guideRemark?: string;
}

export interface GuideInfo {
  /**  */
  guideId?: string;

  /**  */
  lastNameKanji: string;

  /**  */
  firstNameKanji: string;

  /**  */
  lastNameKatakana?: string;

  /**  */
  firstNameKatakana?: string;

  /**  */
  lastNameEnglish?: string;

  /**  */
  firstNameEnglish?: string;

  /**  */
  nickName?: string;

  /**  */
  gender?: GuideGender;

  /**  */
  birthDate?: Date;

  /**  */
  nationalityName?: string;

  /**  */
  mobilePhoneNumber?: string;

  /**  */
  homePhoneNumber?: string;

  /**  */
  faxNumber?: string;

  /**  */
  emailAddress1: string;

  /**  */
  emailAddress2?: string;

  /**  */
  zipCode?: string;

  /**  */
  prefectureName?: string;

  /**  */
  address?: string;

  /**  */
  buildingName?: string;

  /**  */
  nearestStation?: string;

  /**  */
  trainLine?: string;

  /**  */
  supportedLanguages?: GuideSupportedLanguageInfo[];

  /**  */
  supportedTypes?: string[];

  /**  */
  supportedAreas?: string[];

  /**  */
  selfIntroduction?: string;

  /**  */
  overseasLivingExperiences?: GuideOverseaExperienceInfo[];

  /**  */
  rating?: number;

  /**  */
  bankAccountInformation?: GuideBankAccountInformation;
}

export interface GuideInfoResponse {
  /**  */
  respCode: string;

  /**  */
  respDesc: string;

  /**  */
  guideInfo?: GuideInfo;
}

export interface GuideLanguageDto {
  /**  */
  code?: string;

  /**  */
  name?: string;

  /**  */
  nameAbbreviation?: string;

  /**  */
  hasLicense?: boolean;

  /**  */
  isMain?: boolean;
}

export interface GuideListingAvailabilityResponse {
  /**  */
  id?: string;

  /**  */
  lastNameKanji?: string;

  /**  */
  firstNameKanji?: string;

  /**  */
  gender?: GuideGender;

  /**  */
  prefecture?: string;

  /**  */
  rank?: GuideRank;

  /**  */
  maxNumberOfNight?: number;

  /**  */
  dateOfBirth?: Date;

  /**  */
  enabled?: boolean;

  /**  */
  supportedTypes?: GuideSupportedType[];

  /**  */
  supportedAreas?: GuideSupportedArea[];

  /**  */
  supportedLanguages?: GuideLanguageDto[];

  /**  */
  days?: DayGuideAvailability[];

  /**  */
  availableTours?: AvailableTour[];
}

export interface GuideListingAvailabilityResponsePagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: GuideListingAvailabilityResponse[];
}

export interface GuideModel {
  /**  */
  id?: string;

  /**  */
  userId?: string;

  /**  */
  profileImageId?: string;

  /**  */
  profileImage?: Media;

  /**  */
  businessCardFrontId?: string;

  /**  */
  businessCardFront?: Media;

  /**  */
  businessCardBackId?: string;

  /**  */
  businessCardBack?: Media;

  /**  */
  accountId?: string;

  /**  */
  guideRank?: GuideRank;

  /**  */
  enabled?: boolean;

  /**  */
  lastNameKanji?: string;

  /**  */
  firstNameKanji?: string;

  /**  */
  lastNameKatakana?: string;

  /**  */
  firstNameKatakana?: string;

  /**  */
  lastNameEnglish?: string;

  /**  */
  firstNameEnglish?: string;

  /**  */
  nickName?: string;

  /**  */
  gender?: GuideGender;

  /**  */
  birthDate?: Date;

  /**  */
  nationality?: string;

  /**  */
  nationalityName?: string;

  /**  */
  mobilePhone?: string;

  /**  */
  homePhone?: string;

  /**  */
  fax?: string;

  /**  */
  emailAddress1?: string;

  /**  */
  emailAddress2?: string;

  /**  */
  zipCode?: string;

  /**  */
  prefecture?: string;

  /**  */
  address?: string;

  /**  */
  buildingName?: string;

  /**  */
  nearestStation?: string;

  /**  */
  trainLine?: string;

  /**  */
  supportedLanguages?: GuideSupportedLanguageModel[];

  /**  */
  isPersonalBusinessRegistration?: boolean;

  /**  */
  personalBusinessRegistrationNumber?: string;

  /**  */
  professionalInstitutions?: string[];

  /**  */
  otherProfessionalInstitution?: string;

  /**  */
  maxNumberOfNights?: number;

  /**  */
  maxNumber?: number;

  /**  */
  hasInterpretationExperience?: boolean;

  /**  */
  interpretationExperienceDetails?: string;

  /**  */
  supportedTypes?: GuideSupportedType[];

  /**  */
  supportedAreas?: GuideSupportedArea[];

  /**  */
  selfIntroduction?: string;

  /**  */
  overseaExperiences?: GuideOverseaExperienceModel[];

  /**  */
  resumeUploadId?: string;

  /**  */
  resumeUpload?: Media;

  /**  */
  cvUploadId?: string;

  /**  */
  cvUpload?: Media;

  /**  */
  memo?: string;

  /**  */
  bankName?: string;

  /**  */
  branchName?: string;

  /**  */
  bankAccountType?: BankAccountType;

  /**  */
  bankAccountNumber?: string;

  /**  */
  bankAccountHolder?: string;

  /**  */
  internalMemo?: string;

  /**  */
  ratings?: GuideRatingModel[];

  /**  */
  newPassword?: string;

  /**  */
  newPasswordConfirm?: string;
}

export interface GuideOverseaExperience {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  guideId: string;

  /**  */
  from?: Date;

  /**  */
  to?: Date;

  /**  */
  place?: string;

  /**  */
  details?: string;

  /**  */
  guide?: Guide;
}

export interface GuideOverseaExperienceInfo {
  /**  */
  fromDate?: Date;

  /**  */
  toDate?: Date;

  /**  */
  place: string;

  /**  */
  details?: string;
}

export interface GuideOverseaExperienceModel {
  /**  */
  id?: string;

  /**  */
  guideId?: string;

  /**  */
  from?: Date;

  /**  */
  to?: Date;

  /**  */
  place?: string;

  /**  */
  details?: string;
}

export interface GuidePagingResultModel {
  /**  */
  id?: string;

  /**  */
  guideName?: string;

  /**  */
  birthDate?: Date;

  /**  */
  gender?: GuideGender;

  /**  */
  emailAddress1?: string;

  /**  */
  isEnabled?: boolean;

  /**  */
  prefecture?: string;

  /**  */
  languages?: GuideSupportedLanguageDto[];

  /**  */
  rank?: GuideRank;

  /**  */
  supportedTypes?: GuideSupportedType[];

  /**  */
  supportedAreas?: GuideSupportedArea[];

  /**  */
  maxNumberOfNights?: number;

  /**  */
  professionalInstitutions?: string[];

  /**  */
  otherProfessionalInstitution?: string;

  /**  */
  ratings?: GuideRatingModel[];

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;
}

export interface GuidePagingResultModelPagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: GuidePagingResultModel[];
}

export interface GuideRating {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  guideId?: string;

  /**  */
  guide?: Guide;

  /**  */
  date?: Date;

  /**  */
  rating?: number;

  /**  */
  type?: GuideRatingType;

  /**  */
  reviewerId?: string;

  /**  */
  reviewerName?: string;

  /**  */
  reviewer?: User;

  /**  */
  nationality?: string;

  /**  */
  carteName?: string;

  /**  */
  details?: string;
}

export interface GuideRatingModel {
  /**  */
  id?: string;

  /**  */
  guideId?: string;

  /**  */
  date?: Date;

  /**  */
  rating?: number;

  /**  */
  type?: GuideRatingType;

  /**  */
  reviewerId?: string;

  /**  */
  reviewerName?: string;

  /**  */
  nationality?: string;

  /**  */
  carteName?: string;

  /**  */
  details?: string;
}

export interface GuideSeisan {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  tourGuideId?: string;

  /**  */
  paymentDueDate?: Date;

  /**  */
  paymentCost?: number;

  /**  */
  amountCarrying?: number;

  /**  */
  taxWithheldAmount?: number;

  /**  */
  guideRemark?: string;

  /**  */
  ntaRemark?: string;

  /**  */
  status?: GuideSeisanStatus;

  /**  */
  seisanPic?: string;

  /**  */
  submittedAt?: Date;

  /**  */
  settlementDate?: Date;

  /**  */
  superAccounting?: string;

  /**  */
  accountingDate?: Date;

  /**  */
  transferPerson?: string;

  /**  */
  transferDate?: Date;

  /**  */
  tourGuide?: TourGuide;

  /**  */
  seisanPicUser?: User;

  /**  */
  superAccountingUser?: User;

  /**  */
  transferPersonUser?: User;

  /**  */
  guideSeisanFiles?: GuideSeisanFile[];
}

export interface GuideSeisanDetailModel {
  /**  */
  id?: string;

  /**  */
  tourGuideId?: string;

  /**  */
  tourGuideStatus?: TourGuideStatus;

  /**  */
  tourName?: string;

  /**  */
  tourSeisanStatus?: TourSeisanStatus;

  /**  */
  seisanNumber?: string;

  /**  */
  paymentDueDate?: Date;

  /**  */
  paymentCost?: number;

  /**  */
  amountCarrying?: number;

  /**  */
  taxWithheldAmount?: number;

  /**  */
  guideRemark?: string;

  /**  */
  ntaRemark?: string;

  /**  */
  status?: GuideSeisanStatus;

  /**  */
  seisanPic?: string;

  /**  */
  settlementDate?: Date;

  /**  */
  superAccounting?: string;

  /**  */
  accountingDate?: Date;

  /**  */
  transferPerson?: string;

  /**  */
  transferDate?: Date;

  /**  */
  isLocked?: boolean;

  /**  */
  lockedBy?: string;
}

export interface GuideSeisanFile {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  guideSeisanId?: string;

  /**  */
  tourGuideId?: string;

  /**  */
  fileId?: string;

  /**  */
  fileName?: string;

  /**  */
  downloaded?: boolean;

  /**  */
  submittedStatus?: SeisanFileSubmittedStatus;

  /**  */
  uploadedUserRole?: string;

  /**  */
  guideSeisan?: GuideSeisan;
}

export interface GuideSeisanFileModel {
  /**  */
  id?: string;

  /**  */
  guideSeisanId?: string;

  /**  */
  fileName?: string;

  /**  */
  downloaded?: boolean;

  /**  */
  submittedStatus?: SeisanFileSubmittedStatus;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;
}

export interface GuideSeisanPagingResponse {
  /**  */
  id?: string;

  /**  */
  tourId?: string;

  /**  */
  tourGuideId?: string;

  /**  */
  tourName?: string;

  /**  */
  tourIdStr?: string;

  /**  */
  seisanNumber?: string;

  /**  */
  seisanFiles?: string[];

  /**  */
  guideName?: string;

  /**  */
  submittedAt?: Date;

  /**  */
  paymentCost?: number;

  /**  */
  paymentDueDate?: Date;

  /**  */
  guideSeisanStatus?: GuideSeisanStatus;

  /**  */
  seisanPic?: string;

  /**  */
  settlementDate?: Date;

  /**  */
  superAccounting?: string;

  /**  */
  accountingDate?: Date;

  /**  */
  transferPerson?: string;

  /**  */
  transferDate?: Date;

  /**  */
  saleStaff?: string;

  /**  */
  tourDateFrom?: Date;

  /**  */
  tourDateTo?: Date;

  /**  */
  agentName?: string;
}

export interface GuideSeisanPagingResponsePagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: GuideSeisanPagingResponse[];
}

export interface GuideSupportedLanguage {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  guideId: string;

  /**  */
  languageCode: string;

  /**  */
  isMain: boolean;

  /**  */
  license: SupportedLanguageLicense;

  /**  */
  obtainedDate?: Date;

  /**  */
  licenseDetails?: string;

  /**  */
  guide?: Guide;

  /**  */
  languageName?: string;
}

export interface GuideSupportedLanguageDto {
  /**  */
  languageCode?: string;

  /**  */
  languageName?: string;

  /**  */
  nameKanji?: string;

  /**  */
  nameEnglish?: string;

  /**  */
  nameAbbreviation?: string;

  /**  */
  isMain?: boolean;

  /**  */
  license?: SupportedLanguageLicense;

  /**  */
  obtainedDate?: Date;

  /**  */
  licenseDetails?: string;
}

export interface GuideSupportedLanguageInfo {
  /**  */
  languageCode: string;

  /**  */
  languageName: string;

  /**  */
  isMain: boolean;

  /**  */
  licenseType?: string;

  /**  */
  obtainedDate?: Date;

  /**  */
  licenseDetails?: string;
}

export interface GuideSupportedLanguageModel {
  /**  */
  id?: string;

  /**  */
  guideId?: string;

  /**  */
  languageCode?: string;

  /**  */
  languageName?: string;

  /**  */
  isMain?: boolean;

  /**  */
  license?: SupportedLanguageLicense;

  /**  */
  obtainedDate?: Date;

  /**  */
  licenseDetails?: string;
}

export interface GuideUpdateModel {
  /**  */
  id?: string;

  /**  */
  userId?: string;

  /**  */
  profileImageId?: string;

  /**  */
  profileImage?: Media;

  /**  */
  businessCardFrontId?: string;

  /**  */
  businessCardFront?: Media;

  /**  */
  businessCardBackId?: string;

  /**  */
  businessCardBack?: Media;

  /**  */
  accountId?: string;

  /**  */
  guideRank?: GuideRank;

  /**  */
  enabled?: boolean;

  /**  */
  lastNameKanji?: string;

  /**  */
  firstNameKanji?: string;

  /**  */
  lastNameKatakana?: string;

  /**  */
  firstNameKatakana?: string;

  /**  */
  lastNameEnglish?: string;

  /**  */
  firstNameEnglish?: string;

  /**  */
  nickName?: string;

  /**  */
  gender?: GuideGender;

  /**  */
  birthDate?: Date;

  /**  */
  nationality?: string;

  /**  */
  nationalityName?: string;

  /**  */
  mobilePhone?: string;

  /**  */
  homePhone?: string;

  /**  */
  fax?: string;

  /**  */
  emailAddress1?: string;

  /**  */
  emailAddress2?: string;

  /**  */
  zipCode?: string;

  /**  */
  prefecture?: string;

  /**  */
  address?: string;

  /**  */
  buildingName?: string;

  /**  */
  nearestStation?: string;

  /**  */
  trainLine?: string;

  /**  */
  supportedLanguages?: GuideSupportedLanguageModel[];

  /**  */
  isPersonalBusinessRegistration?: boolean;

  /**  */
  personalBusinessRegistrationNumber?: string;

  /**  */
  professionalInstitutions?: string[];

  /**  */
  otherProfessionalInstitution?: string;

  /**  */
  maxNumberOfNights?: number;

  /**  */
  maxNumber?: number;

  /**  */
  hasInterpretationExperience?: boolean;

  /**  */
  interpretationExperienceDetails?: string;

  /**  */
  supportedTypes?: GuideSupportedType[];

  /**  */
  supportedAreas?: GuideSupportedArea[];

  /**  */
  selfIntroduction?: string;

  /**  */
  overseaExperiences?: GuideOverseaExperienceModel[];

  /**  */
  resumeUploadId?: string;

  /**  */
  resumeUpload?: Media;

  /**  */
  cvUploadId?: string;

  /**  */
  cvUpload?: Media;

  /**  */
  memo?: string;

  /**  */
  bankName?: string;

  /**  */
  branchName?: string;

  /**  */
  bankAccountType?: BankAccountType;

  /**  */
  bankAccountNumber?: string;

  /**  */
  bankAccountHolder?: string;

  /**  */
  internalMemo?: string;

  /**  */
  ratings?: GuideRatingModel[];

  /**  */
  newPassword?: string;

  /**  */
  newPasswordConfirm?: string;
}

export interface GuideUpdateSeisanModel {
  /**  */
  id: string;

  /**  */
  sessionId: string;

  /**  */
  seisanNumber?: string;

  /**  */
  amountCarrying: number;

  /**  */
  guideRemark?: string;
}

export interface GuideUpdateTourGuideModel {
  /**  */
  id: string;

  /**  */
  businessDays?: GuideActionBusinessDayRequest[];

  /**  */
  briefingType?: BriefingType;

  /**  */
  desiredBriefingDate1?: Date;

  /**  */
  desiredBriefingTime1?: string;

  /**  */
  desiredDateOfMaterial1?: Date;

  /**  */
  desiredTimeOfMaterial1?: string;

  /**  */
  desiredBriefingDate2?: Date;

  /**  */
  desiredBriefingTime2?: string;

  /**  */
  desiredDateOfMaterial2?: Date;

  /**  */
  desiredTimeOfMaterial2?: string;

  /**  */
  desiredBriefingDate3?: Date;

  /**  */
  desiredBriefingTime3?: string;

  /**  */
  desiredDateOfMaterial3?: Date;

  /**  */
  desiredTimeOfMaterial3?: string;

  /**  */
  guideComments?: string;
}

export interface GuideUpdateTourModel {
  /**  */
  id?: string;

  /**  */
  sessionId?: string;

  /**  */
  tourGuides?: GuideUpdateTourGuideModel[];
}

export interface HolidayModel {
  /**  */
  id?: string;

  /**  */
  name: string;

  /**  */
  date: Date;
}

export interface HolidayModelPagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: HolidayModel[];
}

export interface KeepTourAliveRequest {
  /**  */
  tourId?: string;

  /**  */
  currentRole?: string;

  /**  */
  sessionId?: string;
}

export interface Language {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  code: string;

  /**  */
  name: string;

  /**  */
  nameKanji?: string;

  /**  */
  nameEnglish?: string;

  /**  */
  nameAbbreviation?: string;

  /**  */
  order?: number;

  /**  */
  tourGuides?: TourGuide[];
}

export interface LanguageModel {
  /**  */
  id?: string;

  /**  */
  code?: string;

  /**  */
  name?: string;

  /**  */
  nameKanji?: string;

  /**  */
  nameEnglish?: string;

  /**  */
  nameAbbreviation?: string;

  /**  */
  isMain?: boolean;

  /**  */
  order?: number;
}

export interface LanguageModelPagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: LanguageModel[];
}

export interface LockUnlockRequest {
  /**  */
  entityId?: string;

  /**  */
  sessionId?: string;
}

export interface LoginModel {
  /**  */
  recaptchaToken?: string;

  /**  */
  username: string;

  /**  */
  password: string;
}

export interface LoginResponseModel {
  /**  */
  success?: boolean;

  /**  */
  message?: string;

  /**  */
  isPasswordExpired?: boolean;

  /**  */
  accessToken?: string;

  /**  */
  refreshToken?: string;

  /**  */
  expiryDuration?: number;

  /**  */
  currentUser?: CurrentUserModel;
}

export interface Media {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  name?: string;

  /**  */
  url?: string;
}

export interface NotificationAttachmentModel {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface NotificationDetailModel {
  /**  */
  id?: string;

  /**  */
  fromDate?: Date;

  /**  */
  toDate?: Date;

  /**  */
  status?: NotificationStatus;

  /**  */
  subject?: string;

  /**  */
  attachments?: NotificationAttachmentModel[];

  /**  */
  contents?: string;

  /**  */
  sendEmailStatus?: NotificationSendEmailStatus;

  /**  */
  createdAt?: Date;

  /**  */
  createdByUserName?: string;

  /**  */
  createdByName?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedByUserName?: string;

  /**  */
  updatedByName?: string;
}

export interface NotificationDto {
  /**  */
  id?: string;

  /**  */
  status?: NotificationStatus;

  /**  */
  fromDate?: Date;

  /**  */
  toDate?: Date;

  /**  */
  subject?: string;

  /**  */
  contents?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdByUserName?: string;

  /**  */
  createdByName?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedByUserName?: string;

  /**  */
  updatedByName?: string;

  /**  */
  sendEmailStatus?: NotificationSendEmailStatus;
}

export interface NotificationDtoPagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: NotificationDto[];
}

export interface NotificationModel {
  /**  */
  id?: string;

  /**  */
  status?: NotificationStatus;

  /**  */
  postPeriod?: string;

  /**  */
  fromDate?: Date;

  /**  */
  toDate?: Date;

  /**  */
  subject?: string;

  /**  */
  contents?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  attachments?: string[];

  /**  */
  updatedBy?: string;

  /**  */
  sendEmailStatus?: NotificationSendEmailStatus;
}

export interface NtaComment {
  /**  */
  createdAt?: Date;

  /**  */
  actor?: string;

  /**  */
  comment?: string;
}

export interface OneTimeTokenResponse {
  /**  */
  respCode: string;

  /**  */
  respDesc: string;

  /**  */
  token?: string;

  /**  */
  expiredTime?: Date;
}

export interface RefreshTokenRequest {
  /**  */
  userId?: string;

  /**  */
  refreshToken?: string;
}

export interface RefreshTokenResponse {
  /**  */
  success?: boolean;

  /**  */
  message?: string;

  /**  */
  userId?: string;

  /**  */
  accessToken?: string;

  /**  */
  refreshToken?: string;

  /**  */
  expiryDuration?: number;
}

export interface RequestForgotPasswordModel {
  /**  */
  recaptchaToken?: string;

  /**  */
  email: string;
}

export interface Role {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  normalizedName?: string;

  /**  */
  concurrencyStamp?: string;

  /**  */
  permissions?: string[];
}

export interface RolePagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: Role[];
}

export interface SendTourMessageRequest {
  /**  */
  tourId: string;

  /**  */
  guideId: string;

  /**  */
  messageType: TourMessageType;

  /**  */
  facilityName: string;

  /**  */
  sendTo: string;

  /**  */
  subject: string;

  /**  */
  bodyContent: string;

  /**  */
  attachmentFiles?: TourMessageFileAttachment[];

  /**  */
  draftRequestId?: string;
}

export interface SetDateStatusRequest {
  /**  */
  date: Date;

  /**  */
  guideId: string;

  /**  */
  isUnavailable: boolean;

  /**  */
  isWfAnotherDepartment: boolean;

  /**  */
  wfAnotherDepartmentDetails?: string;
}

export interface SetPasswordModel {
  /**  */
  id?: string;

  /**  */
  temporaryPassword?: string;

  /**  */
  token?: string;

  /**  */
  password?: string;

  /**  */
  confirmationPassword?: string;
}

export interface SmtpSetting {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  name?: string;

  /**  */
  emailAddress?: string;

  /**  */
  host?: string;

  /**  */
  port?: number;

  /**  */
  enableSsl?: boolean;

  /**  */
  useDefaultCredentials?: boolean;

  /**  */
  userName?: string;

  /**  */
  password?: string;
}

export interface SmtpSettingModel {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  emailAddress?: string;

  /**  */
  host?: string;

  /**  */
  port?: number;

  /**  */
  enableSsl?: boolean;

  /**  */
  useDefaultCredentials?: boolean;

  /**  */
  userName?: string;

  /**  */
  password?: string;
}

export interface Staff {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  lastNameKJ?: string;

  /**  */
  firstNameKJ?: string;

  /**  */
  lastNameKK?: string;

  /**  */
  firstNameKK?: string;

  /**  */
  lastNameEN?: string;

  /**  */
  firstNameEN?: string;

  /**  */
  fullName?: string;

  /**  */
  email?: string;

  /**  */
  emergencyContactAddress?: string;

  /**  */
  numberOfTheHandler?: string;

  /**  */
  departmentId?: string;

  /**  */
  department?: Department;

  /**  */
  subdivisionId?: string;

  /**  */
  subdivision?: Subdivision;

  /**  */
  approvalPermission?: boolean;

  /**  */
  viewingPermission?: string;

  /**  */
  terminationDate?: Date;

  /**  */
  usageStatus?: boolean;

  /**  */
  accountId?: string;

  /**  */
  user?: User;

  /**  */
  toursOfSale?: Tour[];

  /**  */
  toursOfPic?: Tour[];
}

export interface StaffAutocompletionModel {
  /**  */
  id?: string;

  /**  */
  userName?: string;

  /**  */
  firstNameKanji?: string;

  /**  */
  lastNameKanji?: string;
}

export interface StaffDetailModel {
  /**  */
  id?: string;

  /**  */
  lastNameKJ?: string;

  /**  */
  firstNameKJ?: string;

  /**  */
  lastNameKK?: string;

  /**  */
  firstNameKK?: string;

  /**  */
  lastNameEN?: string;

  /**  */
  firstNameEN?: string;

  /**  */
  fullName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  emergencyContactAddress?: string;

  /**  */
  numberOfTheHandler?: string;

  /**  */
  departmentId?: string;

  /**  */
  subdivisionId?: string;

  /**  */
  approvalPermission?: boolean;

  /**  */
  viewingPermission?: string;

  /**  */
  terminationDate?: Date;

  /**  */
  usageStatus?: boolean;

  /**  */
  accountId?: string;

  /**  */
  mobilePhoneNumber?: string;

  /**  */
  usagePermission?: string[];
}

export interface StaffDto {
  /**  */
  id?: string;

  /**  */
  accountId?: string;

  /**  */
  name?: string;

  /**  */
  department?: string;

  /**  */
  subDivision?: string;

  /**  */
  usagePermission?: string[];

  /**  */
  approvalPermission?: boolean;

  /**  */
  viewingPermission?: string;

  /**  */
  usageStatus?: boolean;

  /**  */
  terminationDate?: Date;

  /**  */
  lastUpdatedDate?: Date;

  /**  */
  lastUpdatedBy?: string;
}

export interface StaffDtoPagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: StaffDto[];
}

export interface StaffModel {
  /**  */
  id?: string;

  /**  */
  accountId?: string;

  /**  */
  lastNameKJ?: string;

  /**  */
  firstNameKJ?: string;

  /**  */
  lastNameKK?: string;

  /**  */
  firstNameKK?: string;

  /**  */
  lastNameEN?: string;

  /**  */
  firstNameEN?: string;

  /**  */
  emailAddress?: string;

  /**  */
  mobilePhoneNumber?: string;

  /**  */
  emergencyContactAddress?: string;

  /**  */
  numberOfTheHandler?: string;

  /**  */
  departmentId?: string;

  /**  */
  subDivisionId?: string;

  /**  */
  usagePermission?: string[];

  /**  */
  approvalPermission?: boolean;

  /**  */
  viewingPermission?: string;

  /**  */
  terminationDate?: Date;

  /**  */
  newPassword?: string;

  /**  */
  usageStatus?: boolean;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  lockoutEnabled?: boolean;

  /**  */
  confirmPassword?: string;
}

export interface StringBaseUpdateModel {
  /**  */
  success?: boolean;

  /**  */
  message?: string;

  /**  */
  id?: string;
}

export interface Subdivision {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  subdivisionName: string;

  /**  */
  departmentId?: string;

  /**  */
  addGroupEmailToCc?: boolean;

  /**  */
  groupEmailAddress?: string;

  /**  */
  staffs?: Staff[];
}

export interface SubdivisionModel {
  /**  */
  id?: string;

  /**  */
  subdivisionName: string;

  /**  */
  departmentId?: string;

  /**  */
  addGroupEmailToCc?: boolean;

  /**  */
  groupEmailAddress?: string;

  /**  */
  createdAt?: Date;
}

export interface SubdivisionPagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: Subdivision[];
}

export interface TotalTourByGuideResponse {
  /**  */
  year?: number;

  /**  */
  months?: TourByMonth[];
}

export interface TotalTourReportResponse {
  /**  */
  days?: DayOfTour[];
}

export interface Tour {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  fromDate: Date;

  /**  */
  toDate: Date;

  /**  */
  status: TourStatus;

  /**  */
  seisanStatus?: TourSeisanStatus;

  /**  */
  reservationForm?: ReservationForm;

  /**  */
  tourPurpose?: TourPurpose;

  /**  */
  tourSource: TourSource;

  /**  */
  tourName: string;

  /**  */
  tourNumber?: string;

  /**  */
  tourId?: string;

  /**  */
  guestNationalityCode?: string;

  /**  */
  agentName?: string;

  /**  */
  saleStaffId?: string;

  /**  */
  saleStaffName?: string;

  /**  */
  saleStaffEmail?: string;

  /**  */
  picId?: string;

  /**  */
  picName?: string;

  /**  */
  picEmail?: string;

  /**  */
  arrangementPicId?: string;

  /**  */
  arrangementPicName?: string;

  /**  */
  arrangementPicEmail?: string;

  /**  */
  noOfAdultEstimated?: number;

  /**  */
  noOfChildAEstimated?: number;

  /**  */
  noOfChildBEstimated?: number;

  /**  */
  noOfInfantEstimated?: number;

  /**  */
  noOfLeadEstimated?: number;

  /**  */
  noOfAdultBooked?: number;

  /**  */
  noOfChildABooked?: number;

  /**  */
  noOfChildBBooked?: number;

  /**  */
  noOfInfantBooked?: number;

  /**  */
  noOfLeadBooked?: number;

  /**  */
  area?: TourAvailableArea;

  /**  */
  hideGuides: boolean;

  /**  */
  saleStaff?: Staff;

  /**  */
  picStaff?: Staff;

  /**  */
  arrangementPicStaff?: Staff;

  /**  */
  country?: Country;

  /**  */
  tourGuides?: TourGuide[];

  /**  */
  tourEvaluations?: TourEvaluation[];

  /**  */
  facilityEvaluations?: FacilityEvaluation[];
}

export interface TourBookingData {
  /**  */
  respCode: string;

  /**  */
  respDesc: string;

  /**  */
  bookingInfo?: TourBookingInfo;
}

export interface TourBookingInfo {
  /**  */
  tourNumber?: string;

  /**  */
  tourId?: string;

  /**  */
  tourName: string;

  /**  */
  guideList: GuideBookingInfo[];
}

export interface TourByMonth {
  /**  */
  month?: number;

  /**  */
  guideBallTour?: number;

  /**  */
  staffBallTour?: number;
}

export interface TourDetailModel {
  /**  */
  id?: string;

  /**  */
  fromDate?: Date;

  /**  */
  toDate?: Date;

  /**  */
  status?: TourStatus;

  /**  */
  seisanStatus?: TourSeisanStatus;

  /**  */
  reservationForm?: ReservationForm;

  /**  */
  tourPurpose?: TourPurpose;

  /**  */
  tourSource?: TourSource;

  /**  */
  tourName?: string;

  /**  */
  tourNumber?: string;

  /**  */
  tourId?: string;

  /**  */
  guestNationalityCode?: string;

  /**  */
  guestNationalityName?: string;

  /**  */
  agentName?: string;

  /**  */
  saleStaffId?: string;

  /**  */
  saleStaffName?: string;

  /**  */
  saleStaffEmail?: string;

  /**  */
  picId?: string;

  /**  */
  picName?: string;

  /**  */
  picEmail?: string;

  /**  */
  arrangementPicId?: string;

  /**  */
  arrangementPicName?: string;

  /**  */
  arrangementPicEmail?: string;

  /**  */
  noOfAdultEstimated?: number;

  /**  */
  noOfChildAEstimated?: number;

  /**  */
  noOfChildBEstimated?: number;

  /**  */
  noOfInfantEstimated?: number;

  /**  */
  noOfLeadEstimated?: number;

  /**  */
  noOfAdultBooked?: number;

  /**  */
  noOfChildABooked?: number;

  /**  */
  noOfChildBBooked?: number;

  /**  */
  noOfInfantBooked?: number;

  /**  */
  noOfLeadBooked?: number;

  /**  */
  hideGuides?: boolean;

  /**  */
  lockInfo?: TourLockInfo;

  /**  */
  tourGuides?: TourGuideDetailModel[];

  /**  */
  gridTourGuides?: TourGuideGridInfo[];
}

export interface TourDto {
  /**  */
  id?: string;

  /**  */
  fromDate?: Date;

  /**  */
  toDate?: Date;

  /**  */
  status?: TourStatus;

  /**  */
  reservationForm?: ReservationForm;

  /**  */
  tourPurpose?: TourPurpose;

  /**  */
  tourSource?: TourSource;

  /**  */
  seisanStatus?: TourSeisanStatus;

  /**  */
  tourId?: string;

  /**  */
  tourName?: string;

  /**  */
  tourNumber?: string;

  /**  */
  guestNationalityCode?: string;

  /**  */
  guestNationalityName?: string;

  /**  */
  agentName?: string;

  /**  */
  saleStaffName?: string;

  /**  */
  picName?: string;

  /**  */
  noOfAdultEstimated?: number;

  /**  */
  noOfChildAEstimated?: number;

  /**  */
  noOfChildBEstimated?: number;

  /**  */
  noOfInfantEstimated?: number;

  /**  */
  noOfLeadEstimated?: number;

  /**  */
  noOfAdultBooked?: number;

  /**  */
  noOfChildABooked?: number;

  /**  */
  noOfChildBBooked?: number;

  /**  */
  noOfInfantBooked?: number;

  /**  */
  noOfLeadBooked?: number;

  /**  */
  tourGuides?: TourGuideDto[];

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;
}

export interface TourDtoPagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: TourDto[];
}

export interface TourEvaluation {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  tourGuideId?: string;

  /**  */
  tourId?: string;

  /**  */
  guideId?: string;

  /**  */
  tourImpression?: string;

  /**  */
  comprehensiveRate?: number;

  /**  */
  customerReaction?: string;

  /**  */
  tourLeaderEvaluation?: string;

  /**  */
  tourLeaderName?: string;

  /**  */
  serviceGoodPoints?: string;

  /**  */
  serviceImprovement?: string;

  /**  */
  accommodationComprehensiveEvaluation?: string;

  /**  */
  accommodationComprehensiveRate?: number;

  /**  */
  restaurantComprehensiveEvaluation?: string;

  /**  */
  restaurantComprehensiveRate?: number;

  /**  */
  otherComments?: string;

  /**  */
  tour?: Tour;

  /**  */
  tourGuide?: TourGuide;

  /**  */
  guide?: Guide;
}

export interface TourEvaluationDetailModel {
  /**  */
  id?: string;

  /**  */
  tourGuideId?: string;

  /**  */
  tourId?: string;

  /**  */
  tourName?: string;

  /**  */
  agentName?: string;

  /**  */
  nationality?: string;

  /**  */
  area?: TourAvailableArea;

  /**  */
  guideName?: string;

  /**  */
  isLocked?: boolean;

  /**  */
  lockedBy?: string;

  /**  */
  tourEvaluationInfo?: TourEvaluationInfo;

  /**  */
  lastUpdatedAt?: Date;

  /**  */
  lastUpdatedBy?: string;
}

export interface TourEvaluationDto {
  /**  */
  id?: string;

  /**  */
  tourName?: string;

  /**  */
  tourGuideId?: string;

  /**  */
  tourId?: string;

  /**  */
  tourFromDate?: Date;

  /**  */
  tourToDate?: Date;

  /**  */
  guideName?: string;

  /**  */
  nationality?: string;

  /**  */
  agentName?: string;

  /**  */
  area?: TourAvailableArea;

  /**  */
  tourEvaluationImpression?: string;

  /**  */
  tourComprehensiveRate?: number;

  /**  */
  lastUpdatedAt?: Date;

  /**  */
  lastUpdatedBy?: string;

  /**  */
  facilityEvaluations?: FacilityEvaluationDto[];

  /**  */
  facilityEvaluation?: FacilityEvaluationDto;

  /**  */
  tourCreatedAt?: Date;

  /**  */
  tourGuideFromDate?: Date;

  /**  */
  tourGuideToDate?: Date;
}

export interface TourEvaluationDtoPagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: TourEvaluationDto[];
}

export interface TourEvaluationInfo {
  /**  */
  tourImpression?: string;

  /**  */
  comprehensiveRate?: number;

  /**  */
  customerReaction?: string;

  /**  */
  tourLeaderEvaluation?: string;

  /**  */
  tourLeaderName?: string;

  /**  */
  serviceGoodPoints?: string;

  /**  */
  serviceImprovement?: string;

  /**  */
  accommodationComprehensiveEvaluation?: string;

  /**  */
  accommodationComprehensiveRate?: number;

  /**  */
  restaurantComprehensiveEvaluation?: string;

  /**  */
  restaurantComprehensiveRate?: number;

  /**  */
  otherComments?: string;
}

export interface TourGuide {
  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdBy?: string;

  /**  */
  updatedAt?: Date;

  /**  */
  updatedBy?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  tourId: string;

  /**  */
  guideId?: string;

  /**  */
  languageCode?: string;

  /**  */
  status?: TourGuideStatus;

  /**  */
  fromDate?: Date;

  /**  */
  toDate?: Date;

  /**  */
  displayCancelledDates?: boolean;

  /**  */
  attachmentFiles?: TourGuideAttachment[];

  /**  */
  briefingType?: BriefingType;

  /**  */
  briefingDate?: Date;

  /**  */
  briefingTime?: string;

  /**  */
  expectedDateOfMaterials?: Date;

  /**  */
  desiredBriefingDate1?: Date;

  /**  */
  desiredBriefingTime1?: string;

  /**  */
  desiredDateOfMaterial1?: Date;

  /**  */
  desiredTimeOfMaterial1?: string;

  /**  */
  desiredBriefingDate2?: Date;

  /**  */
  desiredBriefingTime2?: string;

  /**  */
  desiredDateOfMaterial2?: Date;

  /**  */
  desiredTimeOfMaterial2?: string;

  /**  */
  desiredBriefingDate3?: Date;

  /**  */
  desiredBriefingTime3?: string;

  /**  */
  desiredDateOfMaterial3?: Date;

  /**  */
  desiredTimeOfMaterial3?: string;

  /**  */
  ntaComments?: NtaComment[];

  /**  */
  guideComments?: string;

  /**  */
  isRequested?: boolean;

  /**  */
  isFirstRequest?: boolean;

  /**  */
  requesterName?: string;

  /**  */
  requesterEmail?: string;

  /**  */
  tourSource?: TourSource;

  /**  */
  tour?: Tour;

  /**  */
  guide?: Guide;

  /**  */
  language?: Language;

  /**  */
  businessDays?: BusinessDay[];

  /**  */
  tourEvaluation?: TourEvaluation;

  /**  */
  facilityEvaluations?: FacilityEvaluation[];

  /**  */
  guideSeisan?: GuideSeisan;
}

export interface TourGuideAttachment {
  /**  */
  id?: string;

  /**  */
  url?: string;

  /**  */
  source?: AttachmentSource;

  /**  */
  name?: string;
}

export interface TourGuideDetailModel {
  /**  */
  id?: string;

  /**  */
  tourId?: string;

  /**  */
  guideId?: string;

  /**  */
  guideName?: string;

  /**  */
  languageCode?: string;

  /**  */
  languageName?: string;

  /**  */
  status?: TourGuideStatus;

  /**  */
  seisanAmountCarrying?: number;

  /**  */
  seisanStatus?: GuideSeisanStatus;

  /**  */
  fromDate?: Date;

  /**  */
  toDate?: Date;

  /**  */
  displayCancelledDates?: boolean;

  /**  */
  attachmentFiles?: TourGuideAttachment[];

  /**  */
  briefingType?: BriefingType;

  /**  */
  briefingDate?: Date;

  /**  */
  briefingTime?: string;

  /**  */
  expectedDateOfMaterials?: Date;

  /**  */
  desiredBriefingDate1?: Date;

  /**  */
  desiredBriefingTime1?: string;

  /**  */
  desiredDateOfMaterial1?: Date;

  /**  */
  desiredTimeOfMaterial1?: string;

  /**  */
  desiredBriefingDate2?: Date;

  /**  */
  desiredBriefingTime2?: string;

  /**  */
  desiredDateOfMaterial2?: Date;

  /**  */
  desiredTimeOfMaterial2?: string;

  /**  */
  desiredBriefingDate3?: Date;

  /**  */
  desiredBriefingTime3?: string;

  /**  */
  desiredDateOfMaterial3?: Date;

  /**  */
  desiredTimeOfMaterial3?: string;

  /**  */
  ntaComments?: NtaComment[];

  /**  */
  guideComments?: string;

  /**  */
  isRequested?: boolean;

  /**  */
  isFirstRequest?: boolean;

  /**  */
  requesterName?: string;

  /**  */
  requesterEmail?: string;

  /**  */
  tourSource?: TourSource;

  /**  */
  businessDays?: BusinessDayDetailModel[];

  /**  */
  lockInfo?: TourGuideLockInfo;
}

export interface TourGuideDto {
  /**  */
  id?: string;

  /**  */
  guideId?: string;

  /**  */
  guideName?: string;

  /**  */
  status?: TourGuideStatus;

  /**  */
  languageCode?: string;

  /**  */
  languageName?: string;

  /**  */
  hasLicense?: boolean;

  /**  */
  hasEvaluation?: boolean;

  /**  */
  guideSeisanStatus?: GuideSeisanStatus;
}

export interface TourGuideGridInfo {
  /**  */
  guideId?: string;

  /**  */
  name?: string;

  /**  */
  languageCode?: string;

  /**  */
  languageName?: string;

  /**  */
  status?: TourGuideStatus;

  /**  */
  fromDate?: Date;

  /**  */
  toDate?: Date;
}

export interface TourGuideLockInfo {
  /**  */
  guideInfoLocked?: boolean;

  /**  */
  guideInfoLockedBy?: string;

  /**  */
  briefingLocked?: boolean;

  /**  */
  briefingLockedBy?: string;
}

export interface TourLockInfo {
  /**  */
  locked?: boolean;

  /**  */
  lockedBy?: string;
}

export interface TourMessageAttachmentModel {
  /**  */
  fileId?: string;

  /**  */
  fileName?: string;
}

export interface TourMessageDetailModel {
  /**  */
  id?: string;

  /**  */
  subject?: string;

  /**  */
  bodyContent?: string;

  /**  */
  attachments?: TourMessageAttachmentModel[];
}

export interface TourMessageFileAttachment {
  /**  */
  fileId?: string;

  /**  */
  password?: string;
}

export interface TourMessagePagingResponse {
  /**  */
  id?: string;

  /**  */
  messageType?: TourMessageType;

  /**  */
  subject?: string;

  /**  */
  facilityName?: string;

  /**  */
  sendTo?: string;

  /**  */
  createdOnUtc?: Date;

  /**  */
  sentOnUtc?: Date;

  /**  */
  tourName?: string;

  /**  */
  tourId?: string;

  /**  */
  guideName?: string;

  /**  */
  status?: TourMessageStatus;

  /**  */
  error?: string;

  /**  */
  errorLogMessage?: string;

  /**  */
  eFaxStatusCode?: string;
}

export interface TourMessagePagingResponsePagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: TourMessagePagingResponse[];
}

export interface TourSeisanInfoModel {
  /**  */
  id?: string;

  /**  */
  tourNumber?: string;

  /**  */
  seisanStatus?: TourSeisanStatus;

  /**  */
  amountCarryingList?: object;
}

export interface TourStayInOvernightOrLastNight {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  tourStatus?: TourStatus;
}

export interface UnlockTourRequest {
  /**  */
  tourId?: string;

  /**  */
  currentRole?: string;

  /**  */
  sessionId?: string;

  /**  */
  accessToken?: string;
}

export interface UpdateDraftRequestModel {
  /**  */
  id: string;

  /**  */
  facilityName?: string;

  /**  */
  sendTo?: string;

  /**  */
  subject?: string;

  /**  */
  body?: string;

  /**  */
  attachmentFiles?: TourMessageFileAttachment[];
}

export interface UpdateExternalBusinessDayModel {
  /**  */
  id?: string;

  /**  */
  fee?: number;

  /**  */
  availability: boolean;

  /**  */
  stayOvernight: BooleanEnumType;

  /**  */
  stayLastNight: BooleanEnumType;
}

export interface UpdateExternalTourGuideModel {
  /**  */
  id?: string;

  /**  */
  status?: TourGuideStatus;

  /**  */
  attachments?: string[];

  /**  */
  briefingType?: BriefingType;

  /**  */
  briefingDate?: Date;

  /**  */
  briefingTime?: string;

  /**  */
  expectedDateOfMaterials?: Date;

  /**  */
  desiredBriefingDate1?: Date;

  /**  */
  desiredBriefingTime1?: string;

  /**  */
  desiredDateOfMaterial1?: Date;

  /**  */
  desiredTimeOfMaterial1?: string;

  /**  */
  desiredBriefingDate2?: Date;

  /**  */
  desiredBriefingTime2?: string;

  /**  */
  desiredDateOfMaterial2?: Date;

  /**  */
  desiredTimeOfMaterial2?: string;

  /**  */
  desiredBriefingDate3?: Date;

  /**  */
  desiredBriefingTime3?: string;

  /**  */
  desiredDateOfMaterial3?: Date;

  /**  */
  desiredTimeOfMaterial3?: string;

  /**  */
  ntaComments?: string;

  /**  */
  businessDays?: UpdateExternalBusinessDayModel[];
}

export interface UpdateExternalTourModel {
  /**  */
  id?: string;

  /**  */
  hideGuides: boolean;

  /**  */
  sessionId: string;

  /**  */
  glTourGuides?: CreateUpdateTourGuideModel[];

  /**  */
  unTourGuides?: UpdateExternalTourGuideModel[];
}

export interface UpdateGuideBookingRequest {
  /**  */
  fromDate: Date;

  /**  */
  toDate: Date;

  /**  */
  bookingCategory?: ReservationForm;

  /**  */
  tourPurpose?: TourPurpose;

  /**  */
  tourName: string;

  /**  */
  tourNumber?: string;

  /**  */
  tourId: string;

  /**  */
  guestNationality: string;

  /**  */
  agentName?: string;

  /**  */
  saleStaff?: string;

  /**  */
  saleStaffEmail?: string;

  /**  */
  tourPIC?: string;

  /**  */
  tourPICEmail?: string;

  /**  */
  requesterEmail: string;

  /**  */
  requesterName: string;

  /**  */
  noOfAdultEstimated: number;

  /**  */
  noOfChildAEstimated: number;

  /**  */
  noOfChildBEstimated: number;

  /**  */
  noOfInfantEstimated: number;

  /**  */
  noOfLeadEstimated: number;

  /**  */
  noOfAdultBooked?: number;

  /**  */
  noOfChildABooked?: number;

  /**  */
  noOfChildBBooked?: number;

  /**  */
  noOfInfantBooked?: number;

  /**  */
  noOfLeadBooked?: number;

  /**  */
  status: TourBookingStatus;

  /**  */
  guideId: string;

  /**  */
  language: string;

  /**  */
  remark?: string;

  /**  */
  attachments?: string[];

  /**  */
  businessDays: BusinessDayRequestForUpdate[];
}

export interface UpdatePermissionsModel {
  /**  */
  id?: string;

  /**  */
  permissions?: string[];
}

export interface UpdateTourRequest {
  /**  */
  fromDate: Date;

  /**  */
  toDate: Date;

  /**  */
  bookingCategory?: ReservationForm;

  /**  */
  tourPurpose?: TourPurpose;

  /**  */
  tourName: string;

  /**  */
  tourNumber?: string;

  /**  */
  tourId: string;

  /**  */
  guestNationality: string;

  /**  */
  agentName?: string;

  /**  */
  saleStaff?: string;

  /**  */
  saleStaffEmail?: string;

  /**  */
  tourPIC?: string;

  /**  */
  tourPICEmail?: string;

  /**  */
  requesterName: string;

  /**  */
  requesterEmail: string;

  /**  */
  noOfAdultEstimated: number;

  /**  */
  noOfChildAEstimated: number;

  /**  */
  noOfChildBEstimated: number;

  /**  */
  noOfInfantEstimated: number;

  /**  */
  noOfLeadEstimated: number;

  /**  */
  noOfAdultBooked?: number;

  /**  */
  noOfChildABooked?: number;

  /**  */
  noOfChildBBooked?: number;

  /**  */
  noOfInfantBooked?: number;

  /**  */
  noOfLeadBooked?: number;

  /**  */
  tourStatus: TourBookingStatus;
}

export interface User {
  /**  */
  id?: string;

  /**  */
  userName?: string;

  /**  */
  normalizedUserName?: string;

  /**  */
  email?: string;

  /**  */
  normalizedEmail?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  passwordHash?: string;

  /**  */
  securityStamp?: string;

  /**  */
  concurrencyStamp?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberConfirmed?: boolean;

  /**  */
  twoFactorEnabled?: boolean;

  /**  */
  lockoutEnd?: Date;

  /**  */
  lockoutEnabled?: boolean;

  /**  */
  accessFailedCount?: number;

  /**  */
  oldPasswordHash?: string;

  /**  */
  fullName?: string;

  /**  */
  roleClaims?: string[];

  /**  */
  passwordExpiredDate?: Date;

  /**  */
  isDeleted?: boolean;

  /**  */
  staff?: Staff;

  /**  */
  seisanOfPic?: GuideSeisan[];

  /**  */
  seisanOfAccounting?: GuideSeisan[];

  /**  */
  seisanOfTransferPerson?: GuideSeisan[];
}

export interface UserLockInfo {
  /**  */
  userId?: string;

  /**  */
  fullName?: string;

  /**  */
  sessionId?: string;
}

export interface UserPagingResponse {
  /**  */
  id?: string;

  /**  */
  fullName?: string;

  /**  */
  roleClaims?: string[];

  /**  */
  userName?: string;

  /**  */
  normalizedUserName?: string;

  /**  */
  email?: string;

  /**  */
  normalizedEmail?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberConfirmed?: boolean;

  /**  */
  twoFactorEnabled?: boolean;

  /**  */
  lockoutEnd?: Date;

  /**  */
  lockoutEnabled?: boolean;

  /**  */
  accessFailedCount?: number;
}

export interface UserPagingResponsePagingResponse {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  total?: number;

  /**  */
  data?: UserPagingResponse[];
}

export interface ValueChange {
  /**  */
  propertyName?: string;

  /**  */
  oldValue?: string;

  /**  */
  newValue?: string;
}

export type AttachmentSource = 0 | 1;

export type AuditType = 1 | 2 | 3;

export type BankAccountType = 1 | 2;

export type BooleanEnumType = 0 | 1 | 2;

export type BriefingType = 1 | 2 | 3 | 4 | 5;

export type Continent = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type FacilityCategory = 10 | 20 | 30 | 40 | 50 | 60 | 70;

export type GuideGender = 1 | 2;

export type GuideRank = 1 | 2 | 3 | 4 | 5 | 6;

export type GuideRatingType = 1 | 2 | 3 | 4;

export type GuideSeisanStatus = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export type GuideSupportedArea =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22;

export type GuideSupportedType = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type HotelMealCrowdedness = 1 | 2 | 3 | 4 | 5;

export type MealType = 1 | 2 | 3;

export type NotificationSendEmailStatus = 0 | 1;

export type NotificationStatus = 0 | 1 | 2 | 3;

export type ReservationForm = 1 | 2 | 3;

export type SeisanFileSubmittedStatus = 1 | 2 | 3;

export type SupportedLanguageLicense = 1 | 2 | 3;

export type TourAvailableArea = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export type TourBookingStatus = 1 | 2 | 3 | 4 | 5;

export type TourGuideStatus = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;

export type TourMessageStatus = 1 | 2 | 3;

export type TourMessageType = 1 | 2;

export type TourPurpose = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export type TourSeisanStatus = 1 | 2 | 3;

export type TourSource = 0 | 1 | 2;

export type TourStatus = 1 | 2 | 3 | 4 | 5 | 6 | 7;
