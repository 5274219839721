import React, { useState } from 'react';
import { useAppContext } from '@/contexts/AppContext';
import { ApiSdk } from '@/httpclient';
import { LanguageModel, CountryModel, GuideOverseaExperience, GuideModel } from '@/__generated';
import { Form, Modal, Row, Input, Col, Divider, message } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ERROR_EXCEPTION } from '../../constants';
import GuideAttachment from './components/GuideAttachment';
import LanguageContent from './components/LanguageContent';
import SelfIntroduction from './components/SelfIntroduction';
import RatingContent from './components/RatingContent';
import './style.less';
import FullPageLoading from '@/components/FullPageLoading';
import GuideBasicInfo from './components/GuideBasicInfo';
import { checkValidGuideDataBeforeSave } from './checkGuideDataBeforeSave';
import { formatDate } from '@/config';

type Props = {
  languages: LanguageModel[];
  onClose: (reload?: boolean) => void;
  guideId: string;
};
export default function Create({ languages, onClose, guideId }: Props) {
  const { auth } = useAppContext();
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [previewPhoto, setPreviewPhoto] = useState('');
  const [previewCardFront, setPreviewCardFront] = useState('');
  const [previewCardBack, setPreviewCardBack] = useState('');
  const [previewResume, setPreviewResume] = useState('');
  const [previewCV, setPreviewCV] = useState('');
  const [selectedFilePhoto, setSelectedFilePhoto] = useState<File | null>(null);
  const [selectedFileCardFront, setSelectedFileCardFront] = useState<File | null>(null);
  const [selectedFileCardBack, setSelectedFileCardBack] = useState<File | null>(null);
  const [selectedFileResume, setSelectedFileResume] = useState<File | null>(null);
  const [selectedFileCV, setSelectedFileCV] = useState<File | null>(null);
  const [guideInfo, setGuideInfo] = React.useState<GuideModel>();
  const [isFormChange, setIsFormChange] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    ApiSdk.GuideService.getById({ id: guideId })
      .then(data => {
        setGuideInfo(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [guideId]);

  React.useEffect(() => {
    if (guideInfo) {
      if (guideInfo?.profileImageId) setPreviewPhoto(`${process.env.REACT_APP_API_ENDPOINT}/api/cms/file/view/${guideInfo.profileImageId}`);
      if (guideInfo?.businessCardFrontId)
        setPreviewCardFront(`${process.env.REACT_APP_API_ENDPOINT}/api/cms/file/view/${guideInfo.businessCardFrontId}`);
      if (guideInfo?.businessCardBackId)
        setPreviewCardBack(`${process.env.REACT_APP_API_ENDPOINT}/api/cms/file/view/${guideInfo.businessCardBackId}`);
      if (guideInfo?.cvUpload) setPreviewCV(guideInfo.cvUpload?.name ?? '');
      if (guideInfo?.resumeUpload) setPreviewResume(guideInfo.resumeUpload?.name ?? '');
      form.setFieldsValue({
        ...guideInfo,
        maxNumberExperience: !!guideInfo.maxNumber,
        maxNumber: guideInfo.maxNumber || null,
        overseaExperiences: guideInfo.overseaExperiences?.length
          ? guideInfo.overseaExperiences
          : [
              {
                from: undefined,
                to: undefined,
                place: '',
                details: '',
              },
            ],
      });
    }
  }, [guideInfo]);

  const onImageChange = async (e: { currentTarget: HTMLInputElement }) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      setSelectedFilePhoto(e.currentTarget.files[0]);
      const objectUrl = URL.createObjectURL(e.currentTarget.files[0]);
      setPreviewPhoto(objectUrl);
    }
  };

  const onImageCardFront = async (e: { currentTarget: HTMLInputElement }) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      setSelectedFileCardFront(e.currentTarget.files[0]);
      const objectUrl = URL.createObjectURL(e.currentTarget.files[0]);
      setPreviewCardFront(objectUrl);
    }
  };

  const onImageChangeCardBack = async (e: { currentTarget: HTMLInputElement }) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      setSelectedFileCardBack(e.currentTarget.files[0]);
      const objectUrl = URL.createObjectURL(e.currentTarget.files[0]);
      setPreviewCardBack(objectUrl);
    }
  };

  const onImageChangeResume = async (e: { currentTarget: HTMLInputElement }) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      setSelectedFileResume(e.currentTarget.files[0]);
      setPreviewResume(e.currentTarget.files[0].name);
    }
  };

  const onFileChangeCV = async (e: { currentTarget: HTMLInputElement }) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      setSelectedFileCV(e.currentTarget.files[0]);
      setPreviewCV(e.currentTarget.files[0].name);
    }
  };

  const onRemovePhoto = () => {
    setPreviewPhoto('');
    setSelectedFilePhoto(null);
  };
  const onRemoveCardFront = () => {
    setPreviewCardFront('');
    setSelectedFileCardFront(null);
  };
  const onRemoveCardBack = () => {
    setPreviewCardBack('');
    setSelectedFileCardBack(null);
  };
  const onRemoveResume = () => {
    setPreviewResume('');
    setSelectedFileResume(null);
  };
  const onRemoveCV = () => {
    setPreviewCV('');
    setSelectedFileCV(null);
  };

  const processUploadImage = async fileUpload => {
    const response = await ApiSdk.MediaService.uploadFile({ fileName: fileUpload?.name ?? '', formFile: fileUpload });
    if (response.success) {
      return response;
    } else {
      return {};
    }
  };
  const saveGuide = values => {
    ApiSdk.GuideService.update({ body: values })
      .then(res => {
        if (res.success) {
          onClose(true);
        } else {
          message.error(t(res.message || ''));
        }
      })
      .catch(() => {
        message.error(ERROR_EXCEPTION, 10);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onSubmit = async values => {
    if (!checkValidGuideDataBeforeSave(values, form)) {
      return;
    }
    setLoading(true);
    try {
      const dataSend = { ...values };
      if (dataSend.birthDate) {
        dataSend.birthDate = formatDate(dataSend.birthDate);
      }
      if (selectedFilePhoto) {
        const imagePhoto = await processUploadImage(selectedFilePhoto);
        if (imagePhoto?.fileId) {
          dataSend.profileImage = imagePhoto;
          dataSend.profileImageId = imagePhoto.fileId;
        }
      } else {
        dataSend.profileImage = previewPhoto ? guideInfo?.profileImage : null;
        dataSend.profileImageId = previewPhoto ? guideInfo?.profileImageId : null;
      }

      if (selectedFileCardFront) {
        const imageCardFront = await processUploadImage(selectedFileCardFront);
        if (imageCardFront?.fileId) {
          dataSend.businessCardFront = imageCardFront;
          dataSend.businessCardFrontId = imageCardFront.fileId;
        }
      } else {
        dataSend.businessCardFront = previewCardFront ? guideInfo?.businessCardFront : null;
        dataSend.businessCardFrontId = previewCardFront ? guideInfo?.businessCardFrontId : null;
      }

      if (selectedFileCardBack) {
        const imageCardBack = await processUploadImage(selectedFileCardBack);
        if (imageCardBack?.fileId) {
          dataSend.businessCardBack = imageCardBack;
          dataSend.businessCardBackId = imageCardBack.fileId;
        }
      } else {
        dataSend.businessCardBack = previewCardBack ? guideInfo?.businessCardBack : null;
        dataSend.businessCardBackId = previewCardBack ? guideInfo?.businessCardBackId : null;
      }

      if (selectedFileResume) {
        const fileResume = await processUploadImage(selectedFileResume);
        if (fileResume?.fileId) {
          dataSend.resumeUploadId = fileResume.fileId;
          dataSend.resumeUpload = fileResume;
        }
      } else {
        dataSend.resumeUploadId = previewResume ? guideInfo?.resumeUploadId : null;
        dataSend.resumeUpload = previewResume ? guideInfo?.resumeUpload : null;
      }

      if (selectedFileCV) {
        const fileCV = await processUploadImage(selectedFileCV);
        if (fileCV?.fileId) {
          dataSend.cvUploadId = fileCV.fileId;
          dataSend.cvUpload = fileCV;
        }
      } else {
        dataSend.cvUploadId = previewCV ? guideInfo?.cvUploadId : null;
        dataSend.cvUpload = previewCV ? guideInfo?.cvUpload : null;
      }

      dataSend.supportedTypes = values.supportedTypes ?? [];
      dataSend.supportedAreas = values.supportedAreas ?? [];
      dataSend.maxNumber = dataSend.maxNumber || 0;
      dataSend.overseaExperiences = (values.overseaExperiences as GuideOverseaExperience[])
        ?.filter(oversea => {
          return oversea.from || oversea.to || oversea.place || oversea.details;
        })
        .map(d => {
          return {
            ...d,
            from: d.from ? formatDate(d.from) : undefined,
            to: d.to ? formatDate(d.to) : undefined,
          };
        });
      dataSend.supportedLanguages = dataSend.supportedLanguages?.map(d => {
        return {
          ...d,
          obtainedDate: d.obtainedDate ? formatDate(d.obtainedDate) : undefined,
        };
      });
      saveGuide(dataSend);
    } catch (error) {
      setLoading(false);
      if ((error as any)?.message) {
        message.warning((error as any)?.response?.data?.message || ERROR_EXCEPTION, 10);
      } else {
        message.warning(ERROR_EXCEPTION, 10);
      }
    }
  };

  const onValuesChangeForm = values => {
    const isFieldsTouched = form.isFieldsTouched(true);
    if (!isFieldsTouched && !isFormChange) {
      setIsFormChange(true);
    }
    if (Object.keys(values)[0] === 'maxNumberExperience') {
      if (form.getFieldValue('maxNumber') === undefined || form.getFieldValue('maxNumber') === '') {
        form.setFieldsValue({ maxNumber: null });
      }
    }
  };
  const { formLayout, labelCol, wrapperCol } = React.useMemo(() => {
    if (window.innerWidth > 800) {
      return {
        formLayout: 'vertical',
        labelCol: undefined,
        wrapperCol: undefined,
      };
    }
    return {
      formLayout: 'horizontal',
      labelCol: { span: 10, sm: 10, xs: 10 },
      wrapperCol: { span: 14, sm: 14, xs: 14 },
    };
  }, []);

  const onValidateError = () => {
    Modal.error({
      maskClosable: false,
      title: t('GuideUpdateProfileErrorTitle'),
      okText: t('Close'),
      okButtonProps: { type: 'default' },
      cancelButtonProps: { style: { display: 'none' } },
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('GuideUpdateProfileValidateError'),
          }}
        />
      ),
    });
  };
  const onModalClose = () => {
    if (isFormChange) {
      Modal.confirm({
        maskClosable: false,
        title: t('GuideUpdateProfileErrorTitle'),
        okText: t('Yes'),
        cancelText: t('No'),
        okButtonProps: { type: 'default' },
        cancelButtonProps: { type: 'primary' },
        onOk: () => {
          form.resetFields();
          onClose();
        },
        content: (
          <div
            dangerouslySetInnerHTML={{
              __html: t('GuideUpdateProfileChangeNotSave'),
            }}
          />
        ),
      });
    } else {
      form.resetFields();
      onClose();
    }
  };

  return (
    <Modal
      className="nta-custom-form modal-create-guide-search responsive-modal create-guide-modal"
      visible={true}
      maskClosable={false}
      style={{ top: 10 }}
      width={900}
      title={t('staffSearch_edit')}
      forceRender={true}
      okText={t('staffSearch_save')}
      okButtonProps={{ icon: <SaveOutlined />, className: 'save-btn' }}
      onCancel={() => {
        onModalClose();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onSubmit(values);
          })
          .catch(err => {
            if (err?.errorFields.length) {
              const fieldsName = err.errorFields[0].name;
              form.scrollToField(fieldsName, { behavior: 'smooth' });
              onValidateError();
            }
          });
      }}
    >
      <FullPageLoading loading={loading} />

      <Form
        form={form}
        className="guide-info h-adr"
        layout={formLayout as any}
        onValuesChange={onValuesChangeForm}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        labelAlign={'left'}
        scrollToFirstError
      >
        <Form.Item name="id" className="hidden">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="userId" className="hidden">
          <Input type="hidden" />
        </Form.Item>

        <Row gutter={6} style={{ marginBottom: 5 }}>
          <Col md={10} xs={24}>
            <Row gutter={6}>
              <Col md={24} xs={24}>
                <h3>プロフィール</h3>
              </Col>
              <Col md={24} xs={24}>
                <GuideAttachment title={t('guideSerchphoto')} fileName={previewPhoto} onChange={onImageChange} onRemove={onRemovePhoto} />
              </Col>
              <Col md={24} xs={24}>
                <GuideAttachment
                  title={t('guideSerchbusinessCardFrontPhoto')}
                  fileName={previewCardFront}
                  onChange={onImageCardFront}
                  onRemove={onRemoveCardFront}
                />
              </Col>
              <Col md={24} xs={24}>
                <GuideAttachment
                  title={t('guideSerchbusinessCardBackPhoto')}
                  fileName={previewCardBack}
                  onChange={onImageChangeCardBack}
                  onRemove={onRemoveCardBack}
                />
              </Col>
            </Row>
          </Col>

          <GuideBasicInfo isCreateForm={false} />
        </Row>
        <Divider />
        <LanguageContent form={form} languages={languages} isMyProfile={false} />
        <Divider />
        <SelfIntroduction
          previewResume={previewResume}
          previewCV={previewCV}
          onRemoveResume={onRemoveResume}
          onImageChangeResume={onImageChangeResume}
          onRemoveCV={onRemoveCV}
          onFileChangeCV={onFileChangeCV}
          isNewForm={false}
          form={form}
          formVisible={true}
        />
        <Divider />
        <RatingContent auth={auth} isProfile={false} isNewForm={false} form={form} />
      </Form>
    </Modal>
  );
}
