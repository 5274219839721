import { LockOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React from 'react';

type Props = {
  isLocked?: boolean | undefined;
  lockedBy?: string | undefined;
  children?: React.ReactElement;
};

export default function TourLockCpn({ children, isLocked, lockedBy }: Props) {
  if (!isLocked) {
    return <div>{children}</div>;
  }

  return (
    <Card
      size="small"
      title={
        <span style={{ color: '#f44336', fontWeight: 'bold' }}>
          <LockOutlined style={{ marginRight: 5 }} />
          {lockedBy}
        </span>
      }
      className="tour-update-locked"
    >
      {children}
      <div className="locked-overlay" />
    </Card>
  );
}
