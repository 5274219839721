import { DEFAULT_DISPLAY_DATE_FORMAT } from '@/constants';
import { HolidayModel } from '@/__generated';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

type Props = {
  holidays: { [key: string]: HolidayModel };
  date: Date;
};

export default function DayNumberText({ holidays, date }: Props) {
  const holiday = React.useMemo(() => {
    return holidays[dayjs(date).format(DEFAULT_DISPLAY_DATE_FORMAT)];
  }, [date, holidays]);
  return holiday ? (
    <b style={{ color: '#f3528c' }}>
      <Tooltip title={holiday.name}> {dayjs(date).format('DD')}</Tooltip>
    </b>
  ) : (
    <span>{dayjs(date).format('DD')}</span>
  );
}
