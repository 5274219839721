import { GuideSeisanDetailModel, TourDetailModel, TourGuideDetailModel } from '@/__generated';
import { TourGuideStatusType } from '@/config';
import { ERROR_EXCEPTION, GuideSeisanStatus } from '@/constants';
import { IAppUser } from '@/contexts/AppContext';
import * as roleService from './roleService';
import httpClient from '@/httpclient';
import { message } from 'antd';

class GuideSeisanService {
  canViewGuideSeisan = (
    tour: TourDetailModel | null | undefined,
    tourGuide: TourGuideDetailModel | null | undefined,
    auth: IAppUser | null | undefined,
  ) => {
    if (tour && tourGuide?.seisanStatus && auth) {
      const isGuide = roleService.isGuide(auth.roleClaims);
      const isAccounting = roleService.isAccounting(auth.originRoleClaims);
      if (isAccounting) {
        return true;
      }
      if (isGuide) {
        const allowGuideStatus = [
          TourGuideStatusType.Assigned,
          TourGuideStatusType.Informing2MonthPrior,
          TourGuideStatusType.Informing3MonthsPrior,
          TourGuideStatusType.Checked,
          TourGuideStatusType.FinalInforming,
          TourGuideStatusType.FinalChecking,
          TourGuideStatusType.Finalized,
          TourGuideStatusType.AwaitingCancellation,
          TourGuideStatusType.Cancelled,
        ];
        const notAllowSeisanStatus = [GuideSeisanStatus.NoFilesUploaded, GuideSeisanStatus.NoNeedSeisan];

        return allowGuideStatus.includes(tourGuide.status as any) || !notAllowSeisanStatus.includes(tourGuide.seisanStatus);
      }
    }
    return false;
  };
  guideCanSubmitSeisan = (tourGuideStatus: number, seisanStatus: number) => {
    const allowGuideStatus = [
      TourGuideStatusType.Assigned,
      TourGuideStatusType.Informing2MonthPrior,
      TourGuideStatusType.Informing3MonthsPrior,
      TourGuideStatusType.Checked,
      TourGuideStatusType.FinalInforming,
      TourGuideStatusType.FinalChecking,
      TourGuideStatusType.Finalized,
      TourGuideStatusType.AwaitingCancellation,
      TourGuideStatusType.Cancelled,
    ];
    const allowSeisanStatus = [
      GuideSeisanStatus.NoNeedSeisan,
      GuideSeisanStatus.NoFilesUploaded,
      GuideSeisanStatus.Rejected,
      GuideSeisanStatus.Uploaded,
      GuideSeisanStatus.Confirming,
      GuideSeisanStatus.Refunded,
    ];
    return allowGuideStatus.includes(tourGuideStatus) && allowSeisanStatus.includes(seisanStatus);
  };
  guideCanRefund = (guideSeisan: GuideSeisanDetailModel | null | undefined) => {
    return guideSeisan?.status === GuideSeisanStatus.FinalConfirmed && (guideSeisan?.paymentCost || 0) < 0;
  };
  guideCanUploadFile = (status: number) => {
    return [
      GuideSeisanStatus.NoNeedSeisan,
      GuideSeisanStatus.NoFilesUploaded,
      GuideSeisanStatus.Rejected,
      GuideSeisanStatus.Uploaded,
      GuideSeisanStatus.Confirming,
      GuideSeisanStatus.Refunded,
    ].includes(status);
  };
  guideCanDeleteFile = (status: number) => {
    return [GuideSeisanStatus.NoNeedSeisan, GuideSeisanStatus.NoFilesUploaded, GuideSeisanStatus.Rejected, GuideSeisanStatus.Uploaded].includes(
      status,
    );
  };
  downloadFileWithData = (fileName: string, data: any) => {
    try {
      const a: any = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const url = window.URL.createObjectURL(data);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      window.setTimeout(() => {
        document.body.removeChild(a);
      }, 200);
    } catch (error) {
      message.error(ERROR_EXCEPTION);
    }
  };
  downloadSeisanFile = (id: string, fileName?: string | null | undefined, onSuccess?: () => void) => {
    const loader = message.loading('Downloading');
    httpClient
      .post(`/guide-seisans/download-seisan-file/${id}`, null, {
        responseType: 'blob',
      })
      .then(res => {
        this.downloadFileWithData(fileName || '', res.data);
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {
        message.error(ERROR_EXCEPTION);
      })
      .finally(() => {
        loader();
      });
  };
}

export default new GuideSeisanService();
