import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type Props = {
  value?: any;
  onChange?: (v: any) => void;
  style?: React.CSSProperties;
};
var toolbarOptions = [
  ['bold', 'underline', 'strike'], // toggled buttons
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ align: [] }],
];

let modules = {
  toolbar: toolbarOptions,
};

export default function RickTextInput(props: Props) {
  console.log(props);
  return <ReactQuill theme="snow" modules={modules} {...props} />;
}
