import { Modal, Form, Tabs, message } from 'antd';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './style.less';
import CreateTourGuideInfo from './_editableComponents/TourGuideInput';
import CreateTourHeaderForm from './_editableComponents/TourHeaderForm';
import {
  ICreateTourValidation,
  tourCreationValidation,
  tourRequestValidation,
  tourFinalValidation,
  M3ValidationStatus,
  M2ValidationStatus,
  createTourDataBeforeSubmit,
} from './validation';
import { ApiSdk } from '@/httpclient';
import { CreateUpdateTourGuideModel, TourGuide } from '@/__generated';
import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { BooleanEnumType, TourGuideStatusType, TourStatusType, scrollableModalBodyStyle } from '@/config';
import FullPageLoading from '@/components/FullPageLoading';
import { maxBy, range } from 'lodash';
import { checkTourDataBeforeCreate } from './checkTourData';
import { getDateOnlyAsString, hasAnyFieldValue } from '@/helpers/string';
import { SaveOutlined } from '@ant-design/icons';
import { GuideOtherInfo } from './_editableComponents/GuideOtherInfo';
import moment from 'moment';

const defaultInitValue = {
  noOfChildAEstimated: 0,
  noOfChildBEstimated: 0,
  noOfInfantEstimated: 0,
  noOfLeadEstimated: 0,
  hideGuides: true,
  status: TourStatusType.Confirming,
};
type Props = {
  onClose: (reload?: boolean) => void;
  visible: boolean;
  intValues?: any;
};
let MaxKey: number = 0;

export default function CreateTourModal({ visible, onClose, intValues }: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [formValidation, setFormValidation] = React.useState<ICreateTourValidation>(tourCreationValidation);
  const [tabKey, setTabKey] = React.useState<string>('guide-0');
  const [form] = Form.useForm();
  React.useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
    MaxKey = 0;
  }, [visible]);
  const onSubmit = () => {
    form
      .validateFields()
      .then(v => {
        const formBody: any = {
          fromDate: dayjs(v.fromDate).format(DEFAULT_DATE_FORMAT) as any,
          toDate: dayjs(v.toDate).format(DEFAULT_DATE_FORMAT) as any,
          status: v.status,
          reservationForm: v.reservationForm,
          tourPurpose: v.tourPurpose,
          tourId: v.tourId,
          tourName: v.tourName,
          tourNumber: v.tourNumber,
          guestNationalityCode: v.guestNationalityCode,
          agentName: v.agentName,
          saleStaffId: v.saleStaffId,
          saleStaffName: v.saleStaffId ? null : v.saleStaffName,
          arrangementPicId: v.arrangementPicId,
          arrangementPicName: v.arrangementPicId ? null : v.arrangementPicName,
          picId: v.picId,
          picName: v.picId ? null : v.picName,
          noOfAdultEstimated: v.noOfAdultEstimated,
          noOfChildAEstimated: v.noOfChildAEstimated,
          noOfChildBEstimated: v.noOfChildBEstimated,
          noOfInfantEstimated: v.noOfInfantEstimated,
          noOfLeadEstimated: v.noOfLeadEstimated,
          noOfAdultBooked: v.noOfAdultBooked,
          noOfChildABooked: v.noOfChildABooked,
          noOfChildBBooked: v.noOfChildBBooked,
          noOfInfantBooked: v.noOfInfantBooked,
          noOfLeadBooked: v.noOfLeadBooked,
          hideGuides: v.hideGuides,
          tourGuides: v.tourGuides?.map(tourGuide => {
            const guide: CreateUpdateTourGuideModel = {
              id: tourGuide.id,
              guideId: tourGuide.guide?.value,
              guideName: tourGuide.guide?.label,
              languageCode: tourGuide.language?.value || '',
              languageName: tourGuide.language?.label,
              status: tourGuide.status,
              briefingType: tourGuide.briefingType,
              briefingDate: getDateOnlyAsString(tourGuide.briefingDate),
              briefingTime: tourGuide.briefingTime,
              expectedDateOfMaterials: getDateOnlyAsString(tourGuide.expectedDateOfMaterials),
              desiredBriefingDate1: getDateOnlyAsString(tourGuide.desiredBriefingDate1),
              desiredBriefingDate2: getDateOnlyAsString(tourGuide.desiredBriefingDate2),
              desiredBriefingDate3: getDateOnlyAsString(tourGuide.desiredBriefingDate3),
              desiredBriefingTime1: tourGuide.desiredBriefingTime1,
              desiredBriefingTime2: tourGuide.desiredBriefingTime2,
              desiredBriefingTime3: tourGuide.desiredBriefingTime3,
              desiredDateOfMaterial1: getDateOnlyAsString(tourGuide.desiredDateOfMaterial1),
              desiredDateOfMaterial2: getDateOnlyAsString(tourGuide.desiredDateOfMaterial2),
              desiredDateOfMaterial3: getDateOnlyAsString(tourGuide.desiredDateOfMaterial3),
              desiredTimeOfMaterial1: tourGuide.desiredTimeOfMaterial1,
              desiredTimeOfMaterial2: tourGuide.desiredTimeOfMaterial2,
              desiredTimeOfMaterial3: tourGuide.desiredTimeOfMaterial3,
              ntaComments: tourGuide.ntaComments,
              attachments: tourGuide.attachments,
              businessDays: tourGuide.businessDays?.filter(hasAnyFieldValue)?.map(day => ({
                ...day,
                date: day.date ? dayjs(day.date).format(DEFAULT_DATE_FORMAT) : day.date,
              })),
            };
            return guide;
          }),
        };
        createTourDataBeforeSubmit(formBody);
        checkTourDataBeforeCreate(formBody).then(res => {
          if (res.submit) {
            setLoading(true);
            ApiSdk.TourService.create({
              body: res.formData,
            })
              .then(res => {
                if (res.success) {
                  onClose(true);
                } else {
                  message.error(t(res.message || ''));
                }
              })
              .finally(() => {
                setLoading(false);
              });
          }
        });
      })
      .catch(err => {
        if (err?.errorFields.length) {
          const fieldsName = err.errorFields[0].name;
          if (fieldsName.length === 1) {
            form.scrollToField(fieldsName, { behavior: 'smooth' });
          } else if (fieldsName.length >= 2) {
            setTabKey(`guide-${fieldsName[1]}`);
            form.scrollToField(fieldsName, { behavior: 'smooth' });
          }
        }
      });
  };
  const initFormValidation = (tourGuides: TourGuide[]) => {
    const guidesStatus: number[] = tourGuides?.map(x => x.status || 1) || [];
    if (guidesStatus.length) {
      const isFinalized = guidesStatus.some(x => M3ValidationStatus.includes(x));
      if (isFinalized) {
        setFormValidation(tourFinalValidation);
        form.validateFields();
        return;
      }

      const isRequestingStatus = guidesStatus.some(s => M2ValidationStatus.includes(s));
      if (isRequestingStatus) {
        setFormValidation(tourRequestValidation);
        form.validateFields();
        return;
      }
    }
    setFormValidation(tourCreationValidation);
    form.validateFields();
  };
  const onGuideStatusChange = () => {
    const tourGuides: TourGuide[] = form.getFieldValue('tourGuides');
    initFormValidation(tourGuides);
  };
  const initialValues = React.useMemo(() => {
    return intValues
      ? { ...defaultInitValue, ...intValues }
      : {
          ...defaultInitValue,
          tourGuides: [
            {
              status: TourGuideStatusType.Unassigned,
            },
          ],
        };
  }, [intValues]);
  return (
    <Modal
      className="nta-custom-form create-tour-form"
      bodyStyle={scrollableModalBodyStyle}
      visible={visible}
      onCancel={() => onClose()}
      style={{ top: 10, maxWidth: 1200 }}
      width={'100%'}
      title={t('Create Tour')}
      okButtonProps={{ loading, icon: <SaveOutlined />, className: 'save-btn' }}
      cancelButtonProps={{ style: { float: 'left' }, loading }}
      okText={t('Register')}
      cancelText={t('Close')}
      onOk={onSubmit}
      maskClosable={false}
    >
      <div>
        <FullPageLoading loading={loading} />
        <Form layout="horizontal" form={form} colon={false} scrollToFirstError={true} initialValues={initialValues}>
          <CreateTourHeaderForm
            formValidation={formValidation}
            form={form}
            tour={{
              picId: initialValues?.picId,
              picName: initialValues?.picName,
              saleStaffId: initialValues?.saleStaffId,
              saleStaffName: initialValues?.saleStaffName,
            }}
          />
          <Form.List name="tourGuides">
            {(fields, { add, remove }) => {
              const max = maxBy(fields, x => x.key)?.key || 0;
              if (max >= MaxKey) {
                MaxKey = max;
              }
              return (
                <div className="guide-info-tab-container">
                  <Tabs
                    type="editable-card"
                    defaultActiveKey={tabKey}
                    activeKey={tabKey}
                    onChange={k => setTabKey(k)}
                    onEdit={(event, action) => {
                      if (action === 'add') {
                        let fromDate = form.getFieldValue('fromDate');
                        let toDate = form.getFieldValue('toDate');
                        let businessDays: any = [];
                        if (fromDate && toDate) {
                          let diff = moment(toDate).diff(moment(fromDate), 'day');
                          businessDays = range(0, diff + 1).map(i => {
                            return {
                              date: moment(fromDate).add(i, 'd'),
                              availability: BooleanEnumType.True,
                              stayOvernight: BooleanEnumType.False,
                              stayLastNight: BooleanEnumType.False,
                              fee: 0,
                            };
                          });
                        }
                        add({ status: TourGuideStatusType.Unassigned, businessDays: businessDays });
                        setTabKey(`guide-${MaxKey + 1}`);
                      }
                    }}
                  >
                    {fields.map((field, i) => {
                      return (
                        <Tabs.TabPane key={`guide-${field.key}`} tab={<TabName fieldData={field} />} closable={false}>
                          <CreateTourGuideInfo
                            form={form}
                            onGuideStatusChange={onGuideStatusChange}
                            fieldData={field}
                            onRemove={() => {
                              if (fields.length > 1) {
                                const nextKey = fields.find(x => x.key !== field.key)?.key;
                                setTabKey(`guide-${nextKey}`);
                              }
                              remove(i);
                            }}
                          />
                          <GuideOtherInfo fieldData={field} />
                        </Tabs.TabPane>
                      );
                    })}
                  </Tabs>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </div>
    </Modal>
  );
}

const TabName = ({ fieldData }) => {
  return (
    <Form.Item
      shouldUpdate={(prev, current) => {
        try {
          return prev.tourGuides[fieldData.key]?.guide?.value !== current.tourGuides[fieldData.key]?.guide?.value;
        } catch (error) {
          return false;
        }
      }}
      noStyle
    >
      {({ getFieldValue }) => {
        return (
          <span style={{ textShadow: 'none', fontWeight: 'bold' }}>
            {getFieldValue(['tourGuides', fieldData.name, 'guide'])?.label || <Trans>Please select guide</Trans>}
          </span>
        );
      }}
    </Form.Item>
  );
};
