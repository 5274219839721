import { PasswordRegex } from '@/config';
import { useAppContext } from '@/contexts/AppContext';
import { ApiSdk } from '@/httpclient';
import { Button, Card, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import './styles.less';

type Props = {};

const bgImage = require('@/assets/images/appbg.jpeg');

export default function PasswordExpired({}: Props) {
  const [form] = Form.useForm();
  const { logout, auth } = useAppContext();
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onChangePass = v => {
    setLoading(true);
    ApiSdk.UserService.changePassword({
      body: { oldPassword: v.oldPassword, password: v.password },
    })
      .then(res => {
        if (res.success) {
          message.success(t('UPDATE_PASSWORDS_SUCCESS'));
          logout();
          history.push('/cms/login');
        } else {
          message.error(t(res.message || ''));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const beforeUnload = () => {
    logout();
  };
  React.useEffect(() => {
    if (auth) {
      if (!auth.isPasswordExpired) {
        history.push('/cms');
      }
    } else {
      history.push('/cms/login');
    }
  }, [auth]);

  React.useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      logout();
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, []);
  return (
    <div
      className="container-login-form"
      style={{ minHeight: '100vh', backgroundImage: `url(${bgImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
    >
      <Card className="login-form">
        <h1 style={{ textAlign: 'center' }}>{t('TitlePasswordExpired')}</h1>

        <Form form={form} layout="vertical" onFinish={onChangePass}>
          <Form.Item name="oldPassword" label={t('CurrentPassword')} rules={[{ required: true, message: t('staffSearch_passwordValidation') }]}>
            <Input.Password className="w-100" />
          </Form.Item>
          <Form.Item
            name="password"
            label={t('NewPassword')}
            rules={[
              { required: true, message: t('staffSearch_passwordValidation') },
              { pattern: new RegExp(PasswordRegex), message: t('staffSearch_passwordValidation') },
            ]}
          >
            <Input.Password className="w-100" />
          </Form.Item>
          <Form.Item
            name="rePassword"
            label={t('ConfirmPassword')}
            rules={[
              { required: true, message: t('staffSearch_passwordValidation') },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(t('staffPasswordNotMatch'));
                },
              }),
            ]}
          >
            <Input.Password className="w-100" />
          </Form.Item>
          <div style={{ color: '#E91E63' }}>※入力間違いを防ぐため、コピー、貼り付けはせずに入力してください。</div>
          <div style={{ marginTop: 10 }}>
            <b>***パスワード設定ルール***</b>
            <div>&bull;&nbsp; 8 文字以上</div>
            <div>&bull;&nbsp; 数字、英小文字、英大文字、およびスペース以外の記号を全て使用する</div>
            <div>&bull;&nbsp;メールアドレスを含まない</div>
          </div>
          <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
            <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
              {t('staffSearch_save')}
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
}
