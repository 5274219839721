import { Select, SelectProps } from 'antd';
import React from 'react';

export default function BooleanEnumInput({ value, ...props }: SelectProps<any>) {
  return (
    <Select maxTagCount="responsive" value={value ? 1 : 0} allowClear {...props}>
      <Select.Option value={1}>〇</Select.Option>
      <Select.Option value={0}>✕</Select.Option>
    </Select>
  );
}
