import dayjs from 'dayjs';
import React from 'react';
import logoImage from './assets/images/logo.png';
import { DEFAULT_DATE_FORMAT, FullDateWithoutSecondFormat, DEFAULT_DISPLAY_DATE_FORMAT, DEFAULT_DISPLAY_DATETIME_FORMAT } from './constants';
import moment from 'moment';
import { Trans } from 'react-i18next';

export const AppName = 'ガイドリンク';
export const AppLogo = (props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
  return <img src={logoImage} alt="app-logo" {...props} />;
};
export const imagePrefixUrl = process.env.REACT_APP_PRE_FIX_IMAGE_URL || '';

export const formatDate = dateStr => {
  return dateStr ? dayjs(dateStr).format(DEFAULT_DATE_FORMAT) : '';
};
export const formatDisplayDate = dateStr => {
  return dateStr ? dayjs(dateStr).format(DEFAULT_DISPLAY_DATE_FORMAT) : '';
};
export const formatDisplayFullDate = dateStr => {
  return dateStr ? dayjs(dateStr).format(DEFAULT_DISPLAY_DATETIME_FORMAT) : '';
};
export const formatFullDate = (value: any) => (value ? moment(value).format('YYYY/MM/DD HH:mm') : '');

export const getDayOfWeek = (value: any) => (value ? `${DayOfWeek.__getEnglishName(moment(value).isoWeekday())}` : '');

export const formatDateWithoutSecond = dateStr => {
  return dateStr ? dayjs(dateStr).format(FullDateWithoutSecondFormat) : '';
};
export enum ReservationPricingType {
  PerParticipant = 1,
  PerBooking = 2,
  PerGroup = 3,
}
export const PasswordRegex = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
export const SEISAN_REGEX = '^[0-9]+$';
export const BooleanEnumType = {
  Blank: 0,
  True: 1,
  False: 2,
  __customLabel: {
    Blank: '  ',
    True: '〇',
    False: '✕',
  },
};

export const BriefingType = {
  Tel: 1,
  Email: 2,
  Online: 3,
  Office1: 4,
  Office2: 5,
  __customLabel: {
    Tel: 'Tel',
    Email: 'Email',
    Online: 'オンライン',
    Office1: '来社(日本橋)',
    Office2: '来社(四ッ谷)',
  },
};
export const GuideRankType = {
  S: 1,
  A: 2,
  B: 3,
  C: 4,
  D: 5,
  F: 6,
  __getValue: (v: number | null | undefined): string => {
    const keys = Object.keys(GuideRankType);
    return keys.find(k => GuideRankType[k] === v) || '';
  },
  __getColor: (v: number | null | undefined) => {
    return ['#fff', '#F2059F', '#04B2D9', '#04D94F', '#F2CB05', '#F28705', '#696969'][v as any];
  },
};

export const TourPurposeType = {
  Sightseeing: 1,
  Education: 2,
  Medical: 3,
  Inspection: 4,
  Mice: 5,
  Honeymoon: 6,
  Family: 7,
  Friends: 8,
  __getValue: (v: number | null | undefined) => {
    const keys = Object.keys(TourPurposeType);
    return keys.find(k => TourPurposeType[k] === v);
  },
};
export const TourStatusType = {
  Confirming: 1,
  ConfirmedAcceptingGuest: 2,
  Confirmed: 3,
  ConfirmingBilling: 4,
  BillingComplete: 5,
  Cancelled: 6,
  __getValue: (v: number | null | undefined): string => {
    const keys = Object.keys(TourStatusType);
    return keys.find(k => TourStatusType[k] === v) || '';
  },
};
export const TourGuideStatusType = {
  Unassigned: 1,
  Assigning: 2,
  Checking: 3,
  Assigned: 4,
  Informing3MonthsPrior: 5,
  Informing2MonthPrior: 6,
  Checked: 7,
  FinalInforming: 8,
  FinalChecking: 9,
  Finalized: 10,
  AwaitingCancellation: 11,
  Cancelled: 12,
  Denied: 13,
  __getValue: (v: number | null | undefined) => {
    const keys = Object.keys(TourGuideStatusType);
    return keys.find(k => TourGuideStatusType[k] === v) || '';
  },
};
export const ReservationForm = {
  Group: 1,
  Fit: 2,
  Ota: 3,
  __customLabel: {
    Group: 'Group',
    Fit: 'FIT',
    Ota: 'OTA',
  },
};
export const TourSourceType = {
  Others: 0,
  GuideLink: 1,
  UnityNext: 2,
};

export const DateStatusType = {
  Available: 0,
  Unavailable: 1,
  WfAnotherDepartment: 2,
};

export const SendEmailStatus = {
  Send: 0,
  NotSend: 1,
};

export const TourAvailableAreaType = {
  Europe: 1,
  Americas: 2,
  Asia: 3,
  Australia: 4,
  China: 5,
  Taiwan: 6,
  Fit: 7,
  Other: 8,
  __getText: v => {
    const keys = Object.keys(TourAvailableAreaType);
    let text = keys.find(k => TourAvailableAreaType[k] === v) || '';

    return text ? <Trans>{`tourAreaType_${text}`}</Trans> : '';
  },
  __getColor: (v, tourStatus?: any, guideStatus?: any) => {
    if (tourStatus && guideStatus) {
      if (tourStatus !== tourStatus.Cancelled && guideStatus === TourGuideStatusType.Unassigned) {
        return '#CCCCFF';
      }
    }
    if (!v) {
      return '';
    }

    return ['', '#85dfff', '#ffff65', '#9af67a', '#ff6d6d', '#ffbde9', '#ffd550', '#d869ff', '#dad9d9'][v];
  },
  __getTextColor: (bgColor: string) => {
    return { '#ffd550': '#333', '#ffff65': '#333' }[bgColor] || 'white';
  },
  __customLabel: {
    Europe: <Trans>tourAreaType_Europe</Trans>,
    Americas: <Trans>tourAreaType_Americas</Trans>,
    Asia: <Trans>tourAreaType_Asia</Trans>,
    Australia: <Trans>tourAreaType_Australia</Trans>,
    China: <Trans>tourAreaType_China</Trans>,
    Taiwan: <Trans>tourAreaType_Taiwan</Trans>,
    Fit: <Trans>tourAreaType_Fit</Trans>,
    Other: <Trans>tourAreaType_Other</Trans>,
  },
  __getTourUnCancelledAndGuideUnassignedColor: '#CCCCFF',
};

export const NotificationStatus = {
  Before: 0,
  During: 1,
  EndOfPublication: 2,
  __getValue: (v: number | null | undefined) => {
    const keys = Object.keys(NotificationStatus);
    return keys.find(k => NotificationStatus[k] === v) || '';
  },
};

export const DayOfWeek = {
  Mon: { value: 1, name: 'Mon' },
  Tue: { value: 2, name: 'Tue' },
  Wed: { value: 3, name: 'Wed' },
  Thu: { value: 4, name: 'Thu' },
  Fri: { value: 5, name: 'Fri' },
  Sat: { value: 6, name: 'Sat' },
  Sun: { value: 7, name: 'Sun' },
  __getEnglishName: (v: number | null | undefined) => {
    const keys = Object.keys(DayOfWeek);
    let key = keys.find(k => DayOfWeek[k]['value'] === v) || '';
    return key ? DayOfWeek[key]['name'] : '';
  },
};

export const GuideSupportedType = {
  // [Display(Name = "FIT")]
  Fit: 1,
  // [Display(Name = "団体")]
  Group: 2,
  // [Display(Name = "インセンティブ")]
  Incentive: 3,
  // [Display(Name = "学生")]
  Student: 4,
  // [Display(Name = "同時通訳")]
  SimultaneousInterpretation: 5,
  // [Display(Name = "逐次通訳")]
  ConsecutiveInterpretation: 6,
  // [Display(Name = "医療通訳")]
  MedicalInterpretation: 7,

  __getValue: (v: number | null | undefined) => {
    const keys = Object.keys(GuideSupportedType);
    let key = keys.find(k => GuideSupportedType[k] === v) || '';
    return `guideSearch_${key}`;
  },
};

export const GuideSupportedArea = {
  // [Display(Name = "北海道")]
  Hokkaido: 1,

  // [Display(Name = "東北")]
  Tohoku: 2,

  // [Display(Name = "日光")]
  Nikko: 3,

  // [Display(Name = "東京")]
  Tokyo: 4,

  // [Display(Name = "箱根")]
  Hakone: 5,

  // [Display(Name = "河口湖")]
  Kawaguchiko: 6,

  // [Display(Name = "名古屋")]
  Nagoya: 7,

  // [Display(Name = "京都")]
  Kyoto: 8,

  // [Display(Name = "大阪")]
  Osaka: 9,

  // [Display(Name = "奈良")]
  Nara: 10,

  // [Display(Name = "高山")]
  Takayama: 11,

  // [Display(Name = "金沢")]
  Kanazawa: 12,

  // [Display(Name = "白川郷")]
  Shirakawago: 13,

  // [Display(Name = "アルペンルート")]
  AlpineRoute: 14,

  // [Display(Name = "熊野古道")]
  Kumanokodo: 15,

  // [Display(Name = "山陰")]
  Sanin: 16,

  // [Display(Name = "岡山")]
  Okayama: 17,

  // [Display(Name = "広島")]
  Hiroshima: 18,

  // [Display(Name = "四国")]
  Shikoku: 19,

  // [Display(Name = "九州")]
  Kyushu: 20,

  // [Display(Name = "沖縄")]
  Okinawa: 21,

  // [Display(Name = "沖縄(離島)")]
  OkinawaIsolatedIsland: 22,

  __getValue: (v: number | null | undefined) => {
    const keys = Object.keys(GuideSupportedArea);
    let key = keys.find(k => GuideSupportedArea[k] === v) || '';
    return `guideSearch_${key}`;
  },
};
export const PrefecturesType = {
  Hokkaido: '北海道',
  Aomori: '青森県',
  Iwate: '岩手県',
  Miyagi: '宮城県',
  Akita: '秋田県',
  Yamagata: '山形県',
  Fukushima: '福島県',
  Ibaraki: '茨城県',
  Tochigi: '栃木県',
  Gunma: '群馬県',
  Saitama: '埼玉県',
  Chiba: '千葉県',
  Tokyo: '東京都',
  Kanagawa: '神奈川県',
  Niigata: '新潟県',
  Toyama: '富山県',
  Ishikawa: '石川県',
  Fukui: '福井県',
  Yamanashi: '山梨県',
  Nagano: '長野県',
  Gifu: '岐阜県',
  Shizuoka: '静岡県',
  Aichi: '愛知県',
  Mie: '三重県',
  Shiga: '滋賀県',
  Kyoto: '京都府',
  Osaka: '大阪府',
  Hyogo: '兵庫県',
  Nara: '奈良県',
  Wakayama: '和歌山県',
  Tottori: '鳥取県',
  Shimane: '島根県',
  Okayama: '岡山県',
  Hiroshima: '広島県',
  Yamaguchi: '山口県',
  Tokushima: '徳島県',
  Kagawa: '香川県',
  Ehime: '愛媛県',
  Kochi: '高知県',
  Fukuoka: '福岡県',
  Saga: '佐賀県',
  Nagasaki: '長崎県',
  Kumamoto: '熊本県',
  Oita: '大分県',
  Miyazaki: '宮崎県',
  Kagoshima: '鹿児島県',
  Okinawa: '沖縄県',
  Overseas: '海外',
};
export const GuideGenderType = {
  Male: 1,
  Female: 2,
  __getValue: (v: number | null | undefined) => {
    const keys = Object.keys(GuideGenderType);
    let key = keys.find(k => GuideGenderType[k] === v) || '';

    return key ? `gender_${key}` : '';
  },
};

export const SupportedLanguageLicenseType = {
  // 全国
  National: 1,

  // 地域
  Regional: 2,

  // 無し
  None: 3,

  __getValue: (v: number | null | undefined) => {
    const keys = Object.keys(SupportedLanguageLicenseType);
    let key = keys.find(k => SupportedLanguageLicenseType[k] === v) || '';
    return `LicenseType_${key}`;
  },
  __customLabel: (v: number | null | undefined) => {
    const keys = Object.keys(SupportedLanguageLicenseType);
    let key = keys.find(k => SupportedLanguageLicenseType[k] === v) || '';
    return `LicenseType_${key}`;
  },
};

export const HIDE_MESSAGE_401_PARAMS = '___No_Display_Error_Message';

export const FacilityCategoryType = {
  Accommodation: 10,
  Bus: 20,
  Transportation: 30,
  Meal: 40,
  Sightseeing: 50,
  Experience: 60,
  Others: 70,
  __getValue: (v: number | null | undefined) => {
    const keys = Object.keys(FacilityCategoryType);
    let key = keys.find(k => FacilityCategoryType[k] === v) || '';
    return `facilityCategory_${key}`;
  },
  __customLabel: {
    Accommodation: <Trans>facilityCategory_Accommodation</Trans>,
    Bus: <Trans>facilityCategory_Bus</Trans>,
    Transportation: <Trans>facilityCategory_Transportation</Trans>,
    Meal: <Trans>facilityCategory_Meal</Trans>,
    Sightseeing: <Trans>facilityCategory_Sightseeing</Trans>,
    Experience: <Trans>facilityCategory_Experience</Trans>,
    Others: <Trans>facilityCategory_Others</Trans>,
  },
};

export const scrollableModalBodyStyle = {
  height: 930,
  overflow: 'auto',
};
