import React, { useState } from 'react';
import {  LeftOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import '../Auth/styles.less';
import { useHistory } from 'react-router';
import { ApiSdk } from '@/httpclient';
import { AppName } from '@/config';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
const bgImage = require('@/assets/images/appbg.jpeg');

export default () => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const login = (email: string) => {
    if (executeRecaptcha) {
      executeRecaptcha('').then(token => {
        setLoading(true);
        ApiSdk.AuthService.forgotPassword({
          body: {
            email: email,
            recaptchaToken: token,
          },
        })
          .then(res => {
            if (res.success) {
              Modal.info({
                title: t('TitleForgotPassword'),
                content: (
                  <p>
                    {AppName}が<b>{email}</b>にパスワードのリセットリクエストのメールを送信しました
                    <br />
                    <b>30分</b>以内にリンクをクリックしてください。または、新しいリンクをリクエストする必要があります。
                    <br />
                    メールが見えない場合は、迷惑メールフォルダをご確認ください。
                  </p>
                ),
              });
            } else {
              message.error(t(res.message || ''));
            }
          })
          .catch(err => {
            message.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  };

  return (
    <div
      className="container-login-form"
      style={{ minHeight: '100vh', backgroundImage: `url(${bgImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
    >
      <Card className="login-form">
        <Form
          layout="vertical"
          name="normal_login"
          size="large"
          onFinish={values => {
            login(values.email);
          }}
        >
          <h1 style={{ textAlign: 'center' }}>{t('TitleForgotPassword')}</h1>
          <h4 style={{ textAlign: 'center', color: '#666', marginTop: 10 }}>
            パスワード再設定用URLを送信します。
            <br />
            ご登録頂いているメールアドレスを入力し、
            <br />
            「送信」ボタンをクリックしてください。
          </h4>
          <Form.Item label={'メールアドレス'} name="email" rules={[{ required: true, message: t('EmailValidator') }]}>
            <Input type="email" placeholder={t('Email')} style={{ alignItems: 'center' }} />
          </Form.Item>

          <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
            <Button loading={loading} disabled={!executeRecaptcha} type="primary" htmlType="submit" className="login-form-button">
              送信
            </Button>
          </div>
          <Button
            icon={<LeftOutlined />}
            type="ghost"
            onClick={() => {
              history.push('/cms/login');
            }}
          >
            {t('LoginBackButton')}
          </Button>
        </Form>
      </Card>
    </div>
  );
};
