import React from 'react';
import { LanguageModel } from '@/__generated';
import LanguageContentSM from './LanguageContentSM';
import LanguageContentMD from './LanguageContentMD';

type Props = {
  form: any;
  languages: LanguageModel[];
  isMyProfile: boolean;
};

export default function LanguageContent(props: Props) {
  const isMobile = React.useMemo(() => {
    return window.innerWidth < 600;
  }, []);

  return isMobile ? <LanguageContentSM {...props} /> : <LanguageContentMD {...props} />;
}
