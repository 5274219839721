import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Row, Button, Input, Rate, Select } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import DatePicker from '@/components/DatePicker';
import moment from 'moment';
import { sumBy } from 'lodash';
type Props = {
  auth: any;

  isProfile: boolean;
  isNewForm: boolean;
  form: any;
};

export default function RatingContent({ auth, isProfile, form }: Props) {
  const { t } = useTranslation();
  return (
    <Row gutter={6}>
      {!isProfile && (
        <Col md={24} xs={24}>
          <Form.Item name="internalMemo" label={t('guideSerchinternalMemo')}>
            <Input.TextArea className="w-100 input-value-color" placeholder="詳細" rows={5} />
          </Form.Item>
        </Col>
      )}
      {!isProfile && (
        <Col md={24}>
          <Form.List name="ratings">
            {(fields, { add, remove }) => (
              <>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                  <Col md={8}>{t('guideSerchguideRating')}</Col>
                  <Col md={8}>
                    <Form.Item dependencies={['ratings']} noStyle>
                      {({ getFieldValue }) => {
                        const rates = getFieldValue(['ratings']) || [];
                        const total = sumBy(rates, (x: any) => x.rating || 0);
                        console.log(rates);
                        const rate = parseFloat((total / rates.length).toFixed(2)) || 0;
                        return (
                          <>
                            <Rate value={rate} disabled allowHalf />
                            <span className="text-rating">{rate || '-'}</span>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  <Col md={8} style={{ textAlign: 'end' }}>
                    <Button
                      type="primary"
                      onClick={() =>
                        add(
                          {
                            reviewerName: auth.fullname,
                            reviewerId: auth.id,
                            date: moment(),
                            rating: 5,
                          },
                          0,
                        )
                      }
                      className="save-btn"
                      icon={<PlusOutlined />}
                    >
                      {t('guideSerchadd')}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  {fields.map(field => {
                    const id = form.getFieldValue(['ratings', field.name, 'id']);
                    const reviewerName = form.getFieldValue(['ratings', field.name, 'reviewerName']);

                    return (
                      <Col md={24} xs={24} key={field.key}>
                        <Form.Item noStyle name={[field.name, 'id']}>
                          <Input type="hidden" />
                        </Form.Item>
                        <Row gutter={6}>
                          <Col md={4}>
                            <Form.Item label={t('guideSerchdate')} name={[field.name, 'date']}>
                              <DatePicker className="w-100 input-value-color" disabled={!!id} placeholder={t('guideSerchdate')} />
                            </Form.Item>
                          </Col>
                          <Col md={4}>
                            <Form.Item label={t('guideSerchnationality')} name={[field.name, 'nationality']}>
                              <Input className="input-value-color" maxLength={50} disabled={!!id} />
                            </Form.Item>
                          </Col>

                          <Col md={5}>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevV, value) => {
                                return true;
                              }}
                            >
                              {({ getFieldValue }) => {
                                const type = getFieldValue(['ratings', field.name, 'type']);

                                return (
                                  <Form.Item label={t('guideSerchcarteName')} name={[field.name, 'carteName']}>
                                    <Input className="input-value-color" maxLength={50} disabled={type !== 1 || !!id} />
                                  </Form.Item>
                                );
                              }}
                            </Form.Item>
                          </Col>
                          <Col md={4}>
                            <Form.Item label={t('guideSerchrating')} name={[field.name, 'rating']}>
                              <Select disabled={!!id} className="input-value-color">
                                {[1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5].map(rate => {
                                  return (
                                    <Select.Option value={rate}>
                                      <Rate value={rate} allowHalf={true} disabled style={{ fontSize: 12, color: '#666' }} />
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col md={3}>
                            <Form.Item label={t('guideSerchtype')} name={[field.name, 'type']}>
                              <Select disabled={!!id} className="input-value-color">
                                <Select.Option value={1}>ツアー</Select.Option>
                                <Select.Option value={2}>面談会</Select.Option>
                                <Select.Option value={3}>懇親会</Select.Option>
                                <Select.Option value={4}>その他</Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Form.Item noStyle name={[field.name, 'reviewerId']}>
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item noStyle name={[field.name, 'reviewerName']}>
                            <Input type="hidden" />
                          </Form.Item>
                          <Col md={4}>
                            <Form.Item label={t('guideSerchreviewer')}>
                              <>{reviewerName || auth?.fullname}</>
                            </Form.Item>
                          </Col>
                          <Col md={20}>
                            <Form.Item name={[field.name, 'details']}>
                              <Input.TextArea disabled={!!id} className="w-100 input-value-color" placeholder="詳細" maxLength={1000} />
                            </Form.Item>
                          </Col>
                          <Col md={4} style={{ textAlign: 'center' }}>
                            <Button icon={<DeleteOutlined />} className="delete-btn" onClick={() => remove(field.name)} size="small" danger>
                              削除
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
              </>
            )}
          </Form.List>
        </Col>
      )}
    </Row>
  );
}
