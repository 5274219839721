import { orderBy } from 'lodash';
import i18n from './helpers/i18n';

export const DEFAULT_DATE_FORMAT = 'YYYY/MM/DD';
export const DEFAULT_DISPLAY_DATE_FORMAT = 'YYYY/MM/DD (ddd)';
export const DEFAULT_DISPLAY_DATETIME_FORMAT = 'YYYY/MM/DD (ddd) HH:mm';

export const MONTH_DAY_FORMAT = 'MM/DD';

export const DEFAULT_PAGE_SIZE = 20;
export const CONFIRM_DELETE_MESSAGE = 'このアイテムを削除してもよろしいですか？';
export const VALIDATE_REQUIRED = '必須項目です。';
export const VALIDATE_PHONE = '電話の形式が正しくありません。';
export const VALIDATE_EMAIL = 'メールアドレスの形式が正しくありません。';
export const ERROR_EXCEPTION = '以外エラーを発生しました。';
export const SAVE_SUCCESS = 'データを保存しました。';

export const FullDateFormat = 'YYYY/MM/DD HH:mm:ss A';
export const FullDateWithoutSecondFormat = 'YYYY/MM/DD HH:mm';
export const PAGE_SIZE_OPTION = ['10', '20', '30', '50', '100'];
export const FAX_NUMBER_VALIDATE = 'FAX番号は10桁か11桁です。（ハイフン以外の記号は使用できません）';

export const KATAKANA_REGEX = /^([\u30a0-\u30ff])+$/;
export const KANJI_REGEX = /^([\u3041-\u3096\u30a0-\u30ff\u3400-\u4db5\u4e00-\u9fcb\uf900-\ufa6a\u2e80-\u2fd5A-Za-z])+$/;

export const Continent = {
  1: 'Europe',
  2: 'Africa',
  3: 'Asia',
  4: 'Oceania',
  5: 'Middle East',
  6: 'North America',
  7: 'South America',
};

export const RoleList = [
  { value: 'ReferenceUser', label: 'ユーザー(参照)' },
  { value: 'RegularUser', label: 'ユーザー(一般)' },
  { value: 'Assign', label: 'ユーザー(アサイン)' },
  { value: 'SupervisorUser', label: 'ユーザー(管理職)' },
  { value: 'Admin', label: 'Admin' },
];

export const RoleListDisplay = [
  { value: 'ReferenceUser', label: '参照' },
  { value: 'RegularUser', label: '一般' },
  { value: 'Assign', label: 'アサイン' },
  { value: 'SupervisorUser', label: '管理職' },
  { value: 'Admin', label: 'Admin' },
  // { value: 'GuideSupport', label: 'サポート' },
];

export const RoleListTable = [
  { value: 'Guide', label: 'ガイド' },
  { value: 'GuideSupport', label: 'ガイドサポート' },
  { value: 'ReferenceUser', label: 'ユーザー(参照)' },
  { value: 'RegularUser', label: 'ユーザー(一般)' },
  { value: 'Assign', label: 'アサイン' },
  { value: 'SupervisorUser', label: 'ユーザー(管理職)' },
  { value: 'Admin', label: 'Admin' },
  { value: 'SuperAccounting', label: '経理担当' },
  { value: 'ReferenceAccounting', label: '参照のみ' },
  { value: 'RegularAccounting', label: '精算担当' },
];

export const RoleSupport = 'GuideSupport';

export const ViewingPermissionList = [
  { value: 'AL', label: '全て' },
  { value: 'DO', label: '部署のみ' },
  { value: 'SO', label: '課のみ' },
  { value: 'OO', label: '自分のみ' },
];

export const Ranks = [
  { value: 1, label: 'S' },
  { value: 2, label: 'A' },
  { value: 3, label: 'B' },
  { value: 4, label: 'C' },
  { value: 5, label: 'D' },
  { value: 6, label: 'F' },
];

export const NationalityList = [
  { value: 'Europe', label: '欧州' },
  { value: 'Africa', label: 'アフリカ' },
  { value: 'Asia', label: 'アジア' },
  { value: 'Oceania', label: 'オセアニア' },
  { value: 'Middle East', label: '中東' },
  { value: 'North America', label: '北米' },
  { value: 'South America', label: '南米' },
];

export const AddressPrefecture = [
  { value: 'Hokkaido', label: '北海道' },
  { value: 'Aomori', label: '青森県' },
  { value: 'Iwate', label: '岩手県' },
  { value: 'Miyagi', label: '宮城県' },
  { value: 'Akita', label: '秋田県' },
  { value: 'Yamagata', label: '山形県' },
  { value: 'Fukushima', label: '福島県' },
  { value: 'Ibaraki', label: '茨城県' },
  { value: 'Tochigi', label: '栃木県' },
  { value: 'Gunma', label: '群馬県' },
  { value: 'Saitama', label: '埼玉県' },
  { value: 'Chiba', label: '千葉県' },
  { value: 'Tokyo', label: '東京都' },
  { value: 'Kanagawa', label: '神奈川県' },
  { value: 'Niigata', label: '新潟県' },
  { value: 'Toyama', label: '富山県' },
  { value: 'Ishikawa', label: '石川県' },
  { value: 'Fukui', label: '福井県' },
  { value: 'Yamanashi', label: '山梨県' },
  { value: 'Nagano', label: '長野県' },
  { value: 'Gifu', label: '岐阜県' },
  { value: 'Shizuoka', label: '静岡県' },
  { value: 'Aichi', label: '愛知県' },
  { value: 'Mie', label: '三重県' },
  { value: 'Shiga', label: '滋賀県' },
  { value: 'Kyoto', label: '京都府' },
  { value: 'Osaka', label: '大阪府' },
  { value: 'Hyogo', label: '兵庫県' },
  { value: 'Nara', label: '奈良県' },
  { value: 'Wakayama', label: '和歌山県' },
  { value: 'Tottori', label: '鳥取県' },
  { value: 'Shimane', label: '島根県' },
  { value: 'Okayama', label: '岡山県' },
  { value: 'Hiroshima', label: '広島県' },
  { value: 'Yamaguchi', label: '山口県' },
  { value: 'Tokushima', label: '徳島県' },
  { value: 'Kagawa', label: '香川県' },
  { value: 'Ehime', label: '愛媛県' },
  { value: 'Kochi', label: '高知県' },
  { value: 'Fukuoka', label: '福岡県' },
  { value: 'Saga', label: '佐賀県' },
  { value: 'Nagasaki', label: '長崎県' },
  { value: 'Kumamoto', label: '熊本県' },
  { value: 'Oita', label: '大分県' },
  { value: 'Miyazaki', label: '宮崎県' },
  { value: 'Kagoshima', label: '鹿児島県' },
  { value: 'Okinawa', label: '沖縄県' },
  { value: 'Overseas', label: '海外' },
];

export const AvailableTypes = [
  { value: 1, label: 'FIT' },
  { value: 2, label: '団体' },
  { value: 3, label: 'インセンティブ' },
  { value: 4, label: '学生' },
  { value: 5, label: '同時通訳' },
  { value: 6, label: '逐次通訳' },
  { value: 7, label: '医療通訳' },
];

export const AvailableArea = [
  { value: 1, label: '北海道' },
  { value: 2, label: '東北' },
  { value: 3, label: '日光' },
  { value: 4, label: '東京' },
  { value: 5, label: '箱根' },
  { value: 6, label: '河口湖' },
  { value: 7, label: '名古屋' },
  { value: 8, label: '京都' },
  { value: 9, label: '大阪' },
  { value: 10, label: '奈良' },
  { value: 11, label: '高山' },
  { value: 12, label: '金沢' },
  { value: 13, label: '白川郷' },
  { value: 14, label: 'アルペンルート' },
  { value: 15, label: '熊野古道' },
  { value: 16, label: '山陰' },
  { value: 17, label: '岡山' },
  { value: 18, label: '広島' },
  { value: 19, label: '四国' },
  { value: 20, label: '九州' },
  { value: 21, label: '沖縄' },
  { value: 22, label: '沖縄(離島)' },
];

export const LanguagesList = [
  { languageCode: 'English', languageName: '英語', isMain: 'English', hasLicense: false, obtainedDate: '', licenseDetails: '' },
  { languageCode: 'French', languageName: 'フランス語', isMain: 'French', hasLicense: false, obtainedDate: '', licenseDetails: '' },
  { languageCode: 'Italian', languageName: 'イタリア語', isMain: 'Italian', hasLicense: false, obtainedDate: '', licenseDetails: '' },
  { languageCode: 'Spanish', languageName: 'スペイン語', isMain: 'Spanish', hasLicense: false, obtainedDate: '', licenseDetails: '' },
  { languageCode: 'Portuguese', languageName: 'ポルトガル語', isMain: 'Portuguese', hasLicense: false, obtainedDate: '', licenseDetails: '' },
  { languageCode: 'German', languageName: 'ドイツ語', isMain: 'German', hasLicense: false, obtainedDate: '', licenseDetails: '' },
  { languageCode: 'Russian', languageName: 'ロシア語', isMain: 'Russian', hasLicense: false, obtainedDate: '', licenseDetails: '' },
  { languageCode: 'Mandarin', languageName: '中国語/北京語', isMain: 'Mandarin', hasLicense: false, obtainedDate: '', licenseDetails: '' },
  { languageCode: 'Cantonese', languageName: '中国語/広東語', isMain: 'Cantonese', hasLicense: false, obtainedDate: '', licenseDetails: '' },
  { languageCode: 'Korean', languageName: '韓国語', isMain: 'Korean', hasLicense: false, obtainedDate: '', licenseDetails: '' },
  { languageCode: 'Thai', languageName: 'タイ語', isMain: 'Thai', hasLicense: false, obtainedDate: '', licenseDetails: '' },
  { languageCode: 'Indonesian', languageName: 'インドネシア語', isMain: 'Indonesian', hasLicense: false, obtainedDate: '', licenseDetails: '' },
  { languageCode: 'Vietnamese', languageName: 'ベトナム語', isMain: 'Vietnamese', hasLicense: false, obtainedDate: '', licenseDetails: '' },
  { languageCode: 'Other', languageName: 'その他', isMain: 'Other', hasLicense: false, obtainedDate: '', licenseDetails: '' },
];

export const RegularPhoneNumber = /^[0-9-]*$/;
export const RegularNumber = /^[0-9]*$/;
export const PhoneNumberRegex = /^[0-9]{1,11}$/;
export const EmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const FaxNumberRegex = /^[0-9-\s]*$/;

export const RegularZipCode = /^[0-9- ]*$/;
export const RegularNumberCharacter = /^[a-zA-Z0-9]*$/;
export const RegularEnglish = /^([~` !@#$%^&*()_+=[\]\\{}|;':",.\/<>?a-zA-Z0-9-])+$/;
//
export const time24hRegex = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;

export const LabelMappingWithKeyGuide = [
  { key: 'profileImage', label: '写真' },
  { key: 'businessCardFront', label: '名刺(表)' },
  { key: 'businessCardBack', label: '名刺(裏)' },
  { key: 'guideRank', label: 'ランク' },
  { key: 'enabledStatus', label: '利用状況' },
  { key: 'lastNameKanji', label: '氏名(漢字)/姓' },
  { key: 'firstNameKanji', label: '名' },
  { key: 'lastNameKatakana', label: '氏名(カナ)/セイ' },
  { key: 'firstNameKatakana', label: 'メイ' },
  { key: 'lastNameEnglish', label: 'Last Name' },
  { key: 'firstNameEnglish', label: 'First Name' },
  { key: 'nickName', label: 'ニックネーム' },
  { key: 'gender', label: '性別' },
  { key: 'birthDate', label: '生年月日' },
  { key: 'nationality', label: '国籍' },
  { key: 'mobilePhone', label: '電話　携帯' },
  { key: 'homePhone', label: '自宅' },
  { key: 'fax', label: 'FAX' },
  { key: 'emailAddress2', label: 'メールアドレス2' },
  { key: 'zipCode', label: '住所　〒' },
  { key: 'prefecture', label: '都道府県' },
  { key: 'address', label: '場所' },
  { key: 'buildingName', label: '建物名' },
  { key: 'nearestStation', label: '最寄り駅' },
  { key: 'trainLine', label: '路線' },
  { key: 'supportedLanguageList', label: '対応可能言語' },
  { key: 'isPersonalBusinessRegistrationRadio', label: '課税事業者登録' },
  { key: 'personalBusinessRegistrationNumber', label: 'インボイス番号' },
  { key: 'professionalInstitutions', label: '所属団体' },
  { key: 'maxNumberOfNights', label: '最大宿泊可能日数' },
  { key: 'maxNumberExperience', label: '台数口経験' },
  { key: 'maxNumber', label: '最大台数' },
  { key: 'hasInterpretationExperienceRadio', label: '同時通訳経験' },
  { key: 'interpretationExperienceDetails', label: '同時通訳経験テキスト' },
  { key: 'supportedTypes', label: '対応可能種別' },
  { key: 'supportedAreas', label: '対応可能エリア' },
  { key: 'selfIntroduction', label: '自己PR' },
  { key: 'overseaExperiences', label: '海外在留経験' },
  { key: 'resumeUpload', label: '履歴書' },
  { key: 'cvUpload', label: '職務経歴書' },
  { key: 'memo', label: 'メモ' },
  { key: 'bankName', label: '銀行名' },
  { key: 'branchName', label: '支店名' },
  { key: 'bankAccountNumber', label: '口座番号' },
  { key: 'bankAccountHolder', label: '名義人' },
  { key: 'bankAccountType', label: '口座種別' },
  { key: 'internalMemo', label: '社内メモ' },
  { key: 'ratings', label: 'ガイド評価' },
];

export const AuditLogType = {
  Create: 1,
  Update: 2,
  Delete: 3,
  __getValue: (v: number | null | undefined) => {
    const keys = Object.keys(AuditLogType);
    return keys.find(k => AuditLogType[k] === v) || '';
  },
};

export const getCollectionValue = (collection: { value: any; label: any }[], key: string | undefined | null) => {
  if (!key) {
    return '';
  }
  let item = collection.find(x => x.value === key);
  return item ? item.label : key;
};

const __baseObjectType = {
  __orderedKey: undefined,
  __getValue(v: number | null | undefined) {
    const keys = Object.keys(this);
    return keys.find(k => this[k] === v) || '';
  },
  __getKeys() {
    return Object.keys(this).filter(x => !x.startsWith('__'));
  },
  __getSelectOptions() {
    return (this.__orderedKey || orderBy(this.__getKeys(), x => x)).map(k => {
      return { label: k, value: this[k] };
    });
  },
};

export const TourMessageType = {
  Email: 1,
  EFax: 2,
  ...__baseObjectType,

  __getValue(v: any) {
    if (v === this.EFax) {
      return i18n.t('tourMessage_EFaxType');
    }
    if (v === this.Email) {
      return i18n.t('tourMessage_EmailType');
    }
    return '';
  },

  __getSelectOptions() {
    return [
      { label: i18n.t('tourMessage_EmailType'), value: 1 },
      { label: i18n.t('tourMessage_EFaxType'), value: 2 },
    ];
  },
};
export const TourMessageStatusType = {
  Sent: 1,
  OutGoing: 2,
  Failed: 3,
  ...__baseObjectType,
  __getColor(v: number | null | undefined) {
    if (v === this.Failed) {
      return '#ff6d6d';
    }
    if (v === this.Sent) {
      return '#9af67a';
    }
    return '#dad9d9';
  },
  __getValue(v: number | null | undefined) {
    const keys = Object.keys(this);
    let key = keys.find(k => this[k] === v) || '';
    return i18n.t(`TourMessageStatusType_${key}`);
  },
  __getSelectOptions() {
    return (this.__orderedKey || orderBy(this.__getKeys(), x => x)).map(k => {
      return { label: i18n.t(`TourMessageStatusType_${k}`), value: this[k] };
    });
  },
};
export const AccountingRoleList = [
  { value: 'SuperAccounting', label: '経理担当' },
  { value: 'ReferenceAccounting', label: '参照のみ' },
  { value: 'RegularAccounting', label: '精算担当' },
];

export const AccountingRoleType = {
  SuperAccounting: 'SuperAccounting',
  ReferenceAccounting: 'ReferenceAccounting',
  RegularAccounting: 'RegularAccounting',
};
export const MAX_FILE_UPLOAD_SIZE = 25 * 1024 * 1024; //20MB

export const GuideSeisanStatusLabel = {
  NoNeedSeisan: '精算不要',
  NoFilesUploaded: '未提出',
  Uploaded: '提出済',
  Confirming: 'NTA確認中',
  Confirmed: '確認済',
  FinalConfirmed: '最終確認済',
  Paying: '支払中',
  Paid: '支払済',
  Rejected: '要再提出',
  Refunded: '返金済',
};

export const GuideSeisanStatus = {
  NoNeedSeisan: 1,
  NoFilesUploaded: 2,
  Uploaded: 3,
  Confirming: 4,
  Confirmed: 5,
  FinalConfirmed: 6,
  Paying: 7,
  Paid: 8,
  Rejected: 9,
  Refunded: 10,

  __getLabel(v: number | null | undefined) {
    const keys = Object.keys(this);
    let key = keys.find(k => this[k] === v) || '';
    return GuideSeisanStatusLabel[key];
  },
  __getKeys() {
    return Object.keys(this).filter(x => !x.startsWith('__'));
  },
  __getSelectOptions() {
    return orderBy(this.__getKeys(), x => this[x]).map(k => {
      return { label: GuideSeisanStatusLabel[k], value: this[k] };
    });
  },
  __getColor(status: number | null | undefined) {
    if (status) {
      return ['white', '#d9d9d9', '#cc66ff', '#fff100', '#fff776', '#03c903', '#218d1c', '#ffa217', '#0000ff', '#ff8686', '#0000ff'][status];
    }
    return undefined;
  },
  __getTextColor(status: number | null | undefined) {
    if ([1, 3, 4].includes(status as any)) {
      return undefined;
    } else {
      return 'white';
    }
  },
};

export const TourSeisanStatus = {
  NotSettledYet: 1,
  PartiallySettled: 2,
  Settled: 3,
  __getLabel(v: number | null | undefined) {
    if (v && v >= 1 && v <= 3) {
      return ['未精算', '一部精算済', '精算済'][v - 1] || '';
    }
    return '';
  },
  __getSelectOptions() {
    return [1, 2, 3].map(k => {
      return { label: this.__getLabel(k), value: k };
    });
  },
  __getColor(status: number | null | undefined) {
    if (status) {
      return ['white', '#cc66ff', '#fff776', '#0000ff'][status];
    }
    return undefined;
  },
  __getTextColor(status: number | null | undefined) {
    if (status !== 2) {
      return 'white';
    }
    return undefined;
  },
};

export const GuideSeisanSubmittedType = {
  blank: 1,
  no: 2,
  yes: 3,
  __getLabel(v: number | null | undefined) {
    if (v === 3) {
      return 'Yes';
    } else if (v === 2) {
      return 'No';
    }
    return 'NTA';
  },
  __getColor(v: number | null | undefined) {
    if (v === 3) {
      return '#9af67a';
    } else if (v === 2) {
      return '#dad9d9';
    }
    return 'white';
  },
};
export const HotelMealCrowdedness = {
  'かなり混雑(16分以上並ぶ)': 1,
  '混雑(5～15分並ぶ)': 2,
  ほぼ満席: 3,
  空いている: 4,
  殆ど誰もいない: 5,
  ...__baseObjectType,
  __orderedKey: ['かなり混雑(16分以上並ぶ)', '混雑(5～15分並ぶ)', 'ほぼ満席', '空いている', '殆ど誰もいない'],
};
