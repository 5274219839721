export default {
  guideSerchupload: 'アップロード',
  guideSerchdelete: '削除',
  guideSerchphoto: '写真',
  guideSerchbusinessCardFrontPhoto: '名刺(表)',
  guideSerchbusinessCardBackPhoto: '名刺(裏)',
  guideSerchaccountId: 'アカウントID',
  guideSerchrank: 'ランク',
  guideSerchstatus: '利用状況',
  guideSerchlastNameKanji: '氏名(漢字)/姓',
  guideSerchfirstNameKanji: '氏名(漢字)/名',
  guideSerchlastNameKatagana: '氏名(カナ)/セイ',
  guideSerchfirstNameKatagana: '氏名(カナ)/メイ',
  guideSerchlastNameEnglish: 'Last Name',
  guideSerchfirstNameEnglish: 'First Name',
  guideSerchnickname: 'ニックネーム',
  guideSerchgender: '性別',
  guideSerchgenderFemale: '女',
  guideSerchgenderMale: '男',
  guideCreateGenderFemale: '女性',
  guideCreateGenderMale: '男性',
  guideSerchbirthdate: '生年月日',
  guideSerchnationality: '国籍',
  guideSerchphoneNumberMobile: '携帯電話',
  guideSerchPhoneNumberHome: '緊急連絡先',
  guideSerchPhoneNumberFAX: 'FAX',
  guideSerchemailAddress1: 'メールアドレス1',
  guideSerchemailAddress: 'メールアドレス',
  guideSerchemailAddress2: 'メールアドレス2',
  guideSerchzipCode: '郵便番号',
  guideSerchaddressSearch: '住所　検索',
  guideSerchaddressPrefecture: '都道府県',
  guideSerchaddress: '住所1',
  guideSerchbuildingName: '建物名',
  guideSerchnearestStation: '最寄り駅',
  guideSerchtrainLine: '路線名',
  guideSerchsupportedLanguages: '対応可能言語',
  guideSerchlanguage: '言語',
  guideSerchmain: 'メイン',
  guideSerchlicense: '種類',
  guideSerchlicenseDetails: '詳細',
  guideSerchobtainedDate: '取得日',
  guideSerchpersonalBusinessRegistration: '課税事業者登録',
  guideSerchregistrationNumber: 'インボイス番号',
  guideSerchmembershipOfProfessionalInstitutions: '所属団体',
  guideSerchmaxNumberOfNights: '最大宿泊可能日数',
  guideSerchUnitExperience: '台数口経験',
  guideSerchmaxNumber: '最大経験台数',
  guideSerchinterpretationExperience: '同時通訳経験',
  guideSerchdetails: '詳細',
  guideSerchsupportedTypes: '対応可能種別',
  guideSerchsupportedAreas: '対応可能エリア',
  guideSerchselfIntroduction: '自己PR',
  guideSerchComentIntroduction: '日付が新しい順にご記載ください',
  guideSerchoverseasLivingExperiencePeriod: '海外在留経験　期間',
  guideSerchplace: '場所',
  guideSerchadd: '追加',
  guideSerchresumeUpload: '履歴書',
  guideSerchcVUpload: '職務経歴書',
  guideSerchmemo: 'メモ',
  guideSerchaccountInformation: '口座情報',
  guideSerchbankName: '銀行名',
  guideSerchbranchName: '支店名',
  guideSerchaccountType: '口座種別',
  guideSerchaccountTypeUsually: '普通',
  guideSerchaccountTypeCurrent: '当座',
  guideSerchaccountNumber: '口座番号',
  guideSerchaccountHolder: '名義人',
  guideSerchinternalMemo: '社内メモ',
  guideSerchguideRating: 'ガイド評価',
  guideSerchdate: '入力日',
  guideSerchrating: '評価',
  guideSerchtype: '種別',
  guideSerchreviewer: '評価者',
  guideSerchcarteName: 'カルテ名',
  guideSerchpasswordUpdateNew: '新しいパスワード',
  guideSerchconfirmPassword: '確認',
  guideSerchEnglish: '英語（英）',
  guideSerchFrench: 'フランス語（仏）',
  guideSerchItalian: 'イタリア語（伊）',
  guideSerchSpanish: 'スペイン語（西）',
  guideSerchPortuguese: 'ポルトガル語（葡）',
  guideSerchGerman: 'ドイツ語（独）',
  guideSerchRussian: 'ロシア語（露）',
  guideSerchMandarin: '中国語/北京語（北）',
  guideSerchCantonese: '中国語/広東語（広）',
  guideSerchKorean: '韓国語（韓）',
  guideSerchThai: 'タイ語（泰）',
  guideSerchIndonesian: 'インドネシア語（尼）',
  guideSerchVietnamese: 'ホルトホール語（越）',
  guideSerchOther: 'その他',
  guideSerchJFG: 'JFG',
  guideSerchJGA: 'JGA',
  guideSerchDay: '日',
  guideSerchRoshia: '台ロ',
  validatorNumber: '数字を入力してください',
  validatorPhoneNumber: '数字とハイフンを入力ください。',
  validatorPhoneNumberCharacter: '半角文字列を入力してください。',
  validatorLangualeSelect: '一つ言語を選択してください。',
  valiationDateBeforeMoreAfter: '開始日は終了日より前である必要があります。',
  guideSerchStayingAbroadFrom: '期間(From)',
  guideSerchStayingAbroadTo: '期間(To)',
  IncludeInactiveGuides: '無効ガイドを含む',
};
