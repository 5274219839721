import { NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { TopMenuStyle } from '../style';
import AppAtag from '@/components/AppAtag';
import React from 'react';
import menu from '@/menu';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '@/contexts/AppContext';
import { hasRoles } from '@/services/roleService';
const TopMenu = () => {
  const [t] = useTranslation();
  const { auth } = useAppContext();

  return (
    <TopMenuStyle>
      <div className="hm-top-menu">
        <ul style={{ height: 64 }}>
          {menu.map((m, index) => {
            const menuItems = m.items.filter(x => hasRoles(x.roles, auth));
            if (menuItems.length === 0) {
              return null;
            }
            return (
              <li key={index}>
                <AppAtag className="parent">
                  <FeatherIcon icon="chevrons-down" />
                  {t(m.groupName || 'menu.MainMenu')}
                </AppAtag>

                {menuItems.length > 0 && (
                  <ul className="subMenu">
                    {m.items
                      .filter(x => hasRoles(x.roles, auth))
                      .map(item => {
                        return (
                          <li key={item.key} className={item.subMenu?.length ? 'has-subMenu-left' : ''}>
                            {!item.subMenu ? (
                              <NavLink to={item.to || ''}>
                                {item.icon}
                                {t(item.title)}
                              </NavLink>
                            ) : (
                              <>
                                <AppAtag className="parent">
                                  <FeatherIcon icon="chevrons-down" />
                                  {t(item.title)}
                                </AppAtag>
                                <ul className="subMenu">
                                  {item.subMenu
                                    .filter(x => hasRoles(x.roles, auth))
                                    .map(s => {
                                      return (
                                        <li key={s.key}>
                                          <NavLink to={s.to || ''}>{t(s.title)}</NavLink>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </>
                            )}
                          </li>
                        );
                      })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </TopMenuStyle>
  );
};

export default TopMenu;
