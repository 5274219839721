import { ArrowUpOutlined, FlagOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ApiSdk } from '@/httpclient';
import ZTable, { ITableRef, IColumnType, IFilterItem } from '@/components/ZTable';
import { ERROR_EXCEPTION } from '@/constants';
import MainContent from '@/Layout/MainContent';
import { Facility } from '@/__generated';
import { FacilityCategoryType } from '@/config';
const PAGE_TITLE = 'facility';

export default () => {
  const { t } = useTranslation();
  const zTb = React.useRef<ITableRef>();

  const refAttachFile = React.useRef<HTMLInputElement>();

  const columns: IColumnType<Facility>[] = React.useMemo(() => {
    return [
      {
        width: 50,
        fixed: 'left',
        title: 'No.',
        dataIndex: 'createdAt',
        render: (v, t, i) => <b>{`${i + 1}`}</b>,
        align: 'center',
      },
      {
        title: t('facilityName'),
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: t('facilityCategory'),
        dataIndex: 'category',
        sorter: true,
        render: v => t(FacilityCategoryType.__getValue(v)),
      },
    ];
  }, []);
  const filter: IFilterItem[] = React.useMemo(() => {
    return [
      { label: 'facilityName', name: 'name', type: 'TEXT' },
      {
        type: 'LIST',
        name: 'category',
        label: 'facilityCategory',
        mdSize: 8,
        xlSize: 8,
        xxlSize: 8,
        listData: Object.keys(FacilityCategoryType)
          .filter(x => !x.startsWith('__'))
          .map(k => ({
            value: k,
            label: t(FacilityCategoryType.__getValue(FacilityCategoryType[k])),
          })),
      },
    ];
  }, []);

  const onChangeFile = async (e: { currentTarget: HTMLInputElement }) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      ApiSdk.FacilityService.import({
        file: e.currentTarget?.files[0],
        fileName: e.currentTarget?.files[0].name,
      })
        .then(res => {
          if (res.success) {
            zTb.current?.reload();
          } else {
            message.error(t(res.message || ''));
          }
        })
        .catch(() => {
          message.error(ERROR_EXCEPTION);
        });
    }
    e.currentTarget.value = '';
  };
  return (
    <MainContent
      title={t(PAGE_TITLE)}
      extraBtns={[
        <Button
          key="create"
          type="primary"
          className="nta-create-btn"
          icon={<ArrowUpOutlined />}
          onClick={() => {
            refAttachFile.current?.click();
          }}
        >
          Import
        </Button>,
      ]}
      icon={<FlagOutlined style={{ fontSize: 16 }} />}
    >
      <Helmet title={t(PAGE_TITLE)} />
      <div className="ant-layout-content-body">
        <ZTable
          columns={columns}
          primaryKey="id"
          tableRef={zTb}
          url="/facilities/get-facilities"
          bordered
          filters={filter}
          order_by="CreatedAt"
          order_by_asc={false}
          allowEdit={false}
          allowDelete={true}
          delete_url="/facilities/delete"
          layoutFilter="horizontal"
        ></ZTable>
        <input
          accept=".csv"
          style={{ display: 'none' }}
          ref={refAttachFile as any}
          type="file"
          name="attachmentFile"
          onChange={evt => {
            onChangeFile(evt);
          }}
        />
      </div>
    </MainContent>
  );
};
