import { CopyOutlined, FlagOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Modal, Row, Tooltip } from 'antd';
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ApiSdk } from '@/httpclient';
import ZTable, { ITableRef, IColumnType, IFilterItem } from '@/components/ZTable';
import { DEFAULT_DATE_FORMAT, VALIDATE_REQUIRED } from '@/constants';
import MainContent from '@/Layout/MainContent';
import { HolidayModel } from '@/__generated';
import NTADatePicker from '@/components/DatePicker';
import moment from 'moment';
import { formatDisplayDate } from '@/config';
const PAGE_TITLE = '休日管理';

const currentYear = new Date().getFullYear();
const filter: IFilterItem[] = [{ label: 'Year', name: 'year', defaultValue: currentYear, type: 'NUMBER' }];
export default () => {
  const [t] = useTranslation();
  const zTb = React.useRef<ITableRef>();
  const [form] = Form.useForm();
  const [formVisible, setFormVisible] = useState(false);
  const [isNewForm, setIsNewForm] = useState(false);

  const updateData = values => {
    ApiSdk.HolidayService.update({
      body: {
        ...values,
        date: moment(values.date).format(DEFAULT_DATE_FORMAT),
      },
    })
      .then(response => {
        if (response?.success) {
          setFormVisible(false);
          zTb.current?.reload();
        } else {
          if (response.message) {
            message.error(t(response.message || ''));
          }
        }
      })
      .catch(error => {
        message.error(error);
      });
  };
  const createData = values => {
    ApiSdk.HolidayService.create({ body: { ...values, date: moment(values.date).format(DEFAULT_DATE_FORMAT) } })
      .then(response => {
        if (response?.success) {
          setFormVisible(false);
          zTb.current?.reload();
        } else {
          if (response.message) {
            message.error(t(response.message || ''));
          }
        }
      })
      .catch(error => {
        message.error(error);
      });
  };
  const columns: IColumnType<HolidayModel>[] = React.useMemo(() => {
    return [
      {
        width: 50,
        fixed: 'left',
        title: 'No.',
        dataIndex: 'createdAt',
        render: (v, t, i) => <b>{`${i + 1}`}</b>,
        align: 'center',
      },
      {
        title: t('Name'),
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: t('Date'),
        dataIndex: 'date',
        width: 200,
        defaultSortOrder: 'ascend',
        sorter: true,
        render: v => formatDisplayDate(v),
      },
    ];
  }, []);
  return (
    <MainContent
      title={PAGE_TITLE}
      extraBtns={[
        <Button
          key="create"
          type="primary"
          className="nta-create-btn"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            form.resetFields();
            setIsNewForm(true);
            setFormVisible(true);
          }}
        >
          {t('guideSearch_new')}
        </Button>,
      ]}
      icon={<FlagOutlined style={{ fontSize: 16 }} />}
    >
      <Helmet title={PAGE_TITLE} />
      <div className="ant-layout-content-body">
        <ZTable
          columns={columns}
          primaryKey="id"
          tableRef={zTb}
          url="/holidays/get-holidays"
          bordered
          filters={filter}
          order_by="Date"
          order_by_asc={true}
          allowEdit={true}
          onEdit={record => {
            setIsNewForm(false);
            setFormVisible(true);
            form.setFieldsValue(record);
          }}
          extraActions={[
            {
              render: record => {
                return [
                  <Tooltip title="コピー">
                    <Button
                      style={{ backgroundColor: '#fff95c', borderRadius: 0 }}
                      type="ghost"
                      size="small"
                      icon={<CopyOutlined />}
                      onClick={() => {
                        setIsNewForm(false);
                        setFormVisible(true);
                        form.setFieldsValue({
                          name: record.name,
                          date: moment(record.date).add(1, 'year'),
                        });
                      }}
                    ></Button>
                  </Tooltip>,
                ];
              },
            },
          ]}
          allowDelete={true}
          delete_url="/holidays/delete"
          layoutFilter="horizontal"
        ></ZTable>
        <Modal
          visible={formVisible}
          maskClosable={false}
          width={600}
          className="nta-custom-form"
          title={isNewForm ? t('CreateHoliday') : t('UpdateHoliday')}
          forceRender={true}
          okText={t('staffSearch_save')}
          okButtonProps={{ icon: <SaveOutlined />, className: 'save-btn' }}
          onCancel={() => {
            form.resetFields();
            setFormVisible(false);
          }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                if (values.id) {
                  updateData(values);
                } else {
                  createData(values);
                }
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Form form={form} size="middle" layout="vertical">
            <Form.Item name="id" className="hidden">
              <Input type="hidden" />
            </Form.Item>
            <Row gutter={16}>
              <Col md={24}>
                <Form.Item label={t('Name')} name="name" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col md={24}>
                <Row gutter={6}>
                  <Col md={24}>
                    <Form.Item label={t('Date')} name="date" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                      <NTADatePicker className="w-100" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </MainContent>
  );
};
