import { Col, Form, Input, Modal, Row, Tag } from 'antd';
import React from 'react';
import '../style.less';
import { ApiSdk } from '@/httpclient';
import { NotificationDetailModel } from '@/__generated';
import { Trans, useTranslation } from 'react-i18next';
import DatePicker from '@/components/DatePicker';
import { formatDisplayDate, NotificationStatus } from '@/config';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import { useAppContext } from '@/contexts/AppContext';
import { isGuideOnly } from '@/services/roleService';
import FileDownload from '@/components/app/FileDownload';

type Props = {
  onClose: (reload?: boolean) => void;
  visible: boolean;
  notificationId?: string;
};
export default function NotificationInfoModal({ visible, onClose, notificationId }: Props) {
  const [notification, setNotification] = React.useState<NotificationDetailModel>();
  const { t } = useTranslation();
  const { auth } = useAppContext();
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  React.useEffect(() => {
    if (notificationId) {
      ApiSdk.NotificationService.detail({ id: notificationId })
        .then(notification => {
          setNotification(notification);
        })
        .finally(() => {});
    }
  }, [notificationId]);

  return (
    <Modal
      className="nta-custom-form responsive-modal"
      maskClosable={false}
      width={750}
      onCancel={() => onClose()}
      title={'お知らせ'}
      visible={visible}
      forceRender={true}
      okText={t('Close')}
      cancelText={t('notification_Action_Cancel')}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={() => onClose()}
    >
      <Form size="middle" layout="horizontal" labelAlign="left" labelCol={{ xs: 24, md: 24, xl: 4 }}>
        {!isGuideOnly(auth?.roleClaims) && (
          <Row gutter={6} style={{ marginBottom: 10 }}>
            <Col md={24} xs={24}>
              <span>
                {t('notification_update_date')} :{formatDisplayDate(notification?.updatedAt) || formatDisplayDate(notification?.createdAt)}
              </span>
              <span style={{ marginLeft: 40 }}>
                {t('notification_update_by')} : {notification?.updatedByName || notification?.createdByName}
              </span>
            </Col>
          </Row>
        )}

        <Row gutter={6}>
          <Col md={24} xs={24}>
            <Form.Item label={t('notification_Result_Subject')}>
              <Input value={notification?.subject} placeholder={t('notification_Result_Subject')} disabled className="input-value-color" />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item label={t('notification_Result_Status')} name="status" labelCol={{ xs: 24, md: 24, xl: 12 }}>
              <Tag>{<Trans>{NotificationStatus.__getValue(notification?.status)}</Trans>}</Tag>
            </Form.Item>
          </Col>

          <Col md={8} xs={24}>
            <Form.Item labelCol={{ xs: 24, md: 24, xl: 8 }} label={'掲載開始'}>
              <DatePicker className="w-100 input-value-color" disabled value={moment(notification?.fromDate)} />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item labelCol={{ xs: 24, md: 24, xl: 8 }} label={'掲載終了'}>
              <DatePicker className="w-100 input-value-color" disabled value={moment(notification?.toDate)} />
            </Form.Item>
          </Col>
        </Row>

        <Col md={24}>
          <Form.Item name="uploadDocument" label={t('notification_attachment_upload')}>
            {notification?.attachments?.map(upload => {
              return <FileDownload key={upload.id} fileId={upload.id} fileName={upload.name} />;
            })}
          </Form.Item>
        </Col>
        <Row gutter={24}>
          <Col md={24}>
            <Form.Item label={t('notification_Result_Contents')}>
              <TextArea
                value={notification?.contents}
                placeholder={t('notification_Result_Contents')}
                disabled
                rows={10}
                className="input-value-color"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
