import React from 'react';
import FullCalendar, { DayCellContentArg } from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import MainContent from '@/Layout/MainContent';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button, Col, message, Modal, Row, Space, Tooltip } from 'antd';
import { CalendarOutlined, CheckOutlined, CloseCircleFilled, SaveOutlined } from '@ant-design/icons';
import GuideInput from '@/components/app/formItem/GuideInput';
import { ArrowLeft, ArrowRight } from 'react-feather';
import DatePicker from '@/components/DatePicker';
import moment from 'moment';
import { useAppContext } from '@/contexts/AppContext';
import { isGuide, isGuideOnly } from '@/services/roleService';
import { ApiSdk } from '@/httpclient';
import FullPageLoading from '@/components/FullPageLoading';
import { AvailableTour, DayGuideAvailability, HolidayModel } from '@/__generated';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { TourAvailableAreaType, TourStatusType } from '@/config';
import lodash from 'lodash';
import DayStatusSelectModal from './DayStatusSelectModal';
import ExtraDateIcon from './ExtraDateIcon';
import TourInfoModal from '../Tours/TourInfoModal';
import { useHistory, useLocation } from 'react-router';
import DayNumberText from './DayNumberText';
import TourGuideEditModal from '../Tours/TourGuideEditModal';
import UpdateTourModal from '../Tours/TourInfoModal';
import TourEvaluation, { ShortTourGuideInfo } from '../TourFeedback/TourEvaluation';
import AdminUpdateTourModal from '../Tours/UpdateTourModal';
type Props = {};
const PAGE_TITLE = 'カレンダー ';
interface ICalendarPageFilter {
  guideId?: string | null | undefined;
  month: number;
  year: number;
}
const toDate = (year: number, month: number) => new Date(`${year}/${month}/01`);

interface ITourEvent {
  start: Date;
  end: Date;
  id: string;
  title: string;
  color?: string;
  textColor?: string;
}
interface IDateStatusDictionary {
  [key: string]: DayGuideAvailability;
}

export default function Calendars({}: Props) {
  const [t, i18n] = useTranslation();
  const { auth } = useAppContext();
  const { search } = useLocation();
  const history = useHistory();
  const [originHolidays, setOriginHolidays] = React.useState<HolidayModel[]>([]);
  const [guideEvaluation, setGuideEvaluation] = React.useState<ShortTourGuideInfo>();

  const [dateAvailabilityDict, setDateAvailabilityDict] = React.useState<IDateStatusDictionary>({});
  const [daysInTour, setDaysInTour] = React.useState<{ [key: string]: true }>({});
  const [events, setEvents] = React.useState<ITourEvent[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [editingTourId, setEditingTourId] = React.useState<string>();

  const [dayStatusModal, setDayStatusModal] = React.useState<{
    date: string;
    isUnavailable: boolean;
    isWfAnotherDepartment: boolean;
    wfAnotherDepartmentDetails: string | null | undefined;
  }>();
  const [filter, setFilter] = React.useState<ICalendarPageFilter>({
    month: dayjs().month() + 1,
    year: dayjs().year(),
  });
  const allowChangeGuide = React.useMemo(() => {
    return !isGuideOnly(auth?.roleClaims);
  }, [auth]);

  React.useEffect(() => {
    if (isGuideOnly(auth?.roleClaims)) {
      setFilter(f => ({ ...f, guideId: auth?.id }));
    } else {
      const query = new URLSearchParams(search);
      const guideId = query.get('guideId');
      if (guideId) {
        setFilter(f => ({ ...f, guideId: guideId }));
        history.replace('/cms/calendars');
      }
    }
  }, [auth]);
  const guideId = React.useMemo(() => {
    return new URLSearchParams(search).get('guideId');
  }, []);
  React.useEffect(() => {
    ApiSdk.HolidayService.getAllHolidays({ year: filter.year }).then(res => {
      setOriginHolidays(res);
    });
  }, [filter.year]);

  const holidays = React.useMemo(() => {
    let data: { [key: string]: HolidayModel } = {};
    originHolidays.forEach(holiday => {
      const k = dayjs(holiday.date).format(DEFAULT_DATE_FORMAT);
      data[k] = holiday;
    });
    return data;
  }, [originHolidays]);
  React.useEffect(() => {
    if (filter.guideId && filter.month && filter.year) {
      setLoading(true);
      setDateAvailabilityDict({});
      setEvents([]);
      ApiSdk.GuideAvailabilityService.getGuideAvailabilities({
        guideId: filter.guideId,
        month: filter.month,
        year: filter.year,
      })
        .then(res => {
          if (res.success) {
            const days: IDateStatusDictionary = {};
            res?.days?.forEach(d => {
              days[dayjs(d.date).format(DEFAULT_DATE_FORMAT)] = d;
            });
            setDateAvailabilityDict(days);
            createEventData(res.availableTours || []);
          } else {
            message.error(t(res.message || ''));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [filter]);
  const getTourGuideStatusByDay = (tour: AvailableTour, date) => {
    return tour?.tourGuides?.find(tourGuide => {
      return tourGuide.businessDays?.some(day => {
        return dayjs(day).format(DEFAULT_DATE_FORMAT) === dayjs(date).format(DEFAULT_DATE_FORMAT);
      });
    })?.status;
  };
  const createEventData = (tours: AvailableTour[]) => {
    const _events: ITourEvent[] = [];
    const _dayInTour: { [key: string]: true } = {};
    tours.forEach(tour => {
      let businessDaysString: string[] = [];
      tour.tourGuides?.forEach(tourGuide => {
        if (tourGuide.businessDays?.length) {
          businessDaysString = [...businessDaysString, ...tourGuide.businessDays];
        }
      });
      businessDaysString = lodash.uniq(businessDaysString);
      var businessDays = lodash.orderBy(businessDaysString, x => dayjs(x)).map(x => dayjs(x).add(12, 'h'));
      if (businessDays.length === 1) {
        _dayInTour[businessDays[0].format(DEFAULT_DATE_FORMAT)] = true;
        let bgColor = TourAvailableAreaType.__getColor(tour.area, tour.tourStatus, getTourGuideStatusByDay(tour, businessDays[0]));
        _events.push({
          title: `${t(TourStatusType.__getValue(tour.tourStatus))}: ${tour.name}`,
          id: tour.id || '',
          end: businessDays[0].clone().toDate(),
          start: businessDays[0].toDate(),
          color: bgColor,
          textColor: TourAvailableAreaType.__getTextColor(bgColor),
        });
      } else if (businessDays.length > 1) {
        let start = businessDays[0].toDate();

        businessDays.forEach((day, i) => {
          _dayInTour[day.format(DEFAULT_DATE_FORMAT)] = true;
          if (i === businessDays.length - 1) {
            let bgColor = TourAvailableAreaType.__getColor(tour.area, tour.tourStatus, getTourGuideStatusByDay(tour, day));

            _events.push({
              title: `${t(TourStatusType.__getValue(tour.tourStatus))}: ${tour.name}`,
              id: tour.id || '',
              end: day
                .clone()
                .add(1, 'day')
                .toDate(),
              start,
              color: bgColor,
              textColor: TourAvailableAreaType.__getTextColor(bgColor),
            });
          } else {
            const nextDay = businessDays[i + 1].clone();
            const isSameDay = nextDay.add(-1, 'day').isSame(day);
            if (!isSameDay) {
              let bgColor = TourAvailableAreaType.__getColor(tour.area, tour.tourStatus, getTourGuideStatusByDay(tour, day));

              _events.push({
                title: `${t(TourStatusType.__getValue(tour.tourStatus))}: ${tour.name}`,
                id: tour.id || '',
                end: day.add(1, 'day').toDate(),
                start,
                color: bgColor,
                textColor: TourAvailableAreaType.__getTextColor(bgColor),
              });
              start = nextDay.toDate();
            }
          }
        });
      }
    });
    setEvents(_events);
    setDaysInTour(_dayInTour);
  };
  const inTour = (date: Date) => {
    return !!daysInTour[dayjs(date).format(DEFAULT_DATE_FORMAT)];
  };
  const onChangeMonth = (month: number) => {
    const currentMonth = moment(toDate(filter.year, filter.month));
    const nextMonth = currentMonth.add(month, 'month');
    setFilter(f => ({
      ...f,
      month: nextMonth.month() + 1,
      year: nextMonth.year(),
    }));
  };
  const updateDateAvailabilityDict = (
    day: string,
    dayInfo: DayGuideAvailability | null | undefined,
    isDeleted: boolean,
    updatedValue: Partial<DayGuideAvailability>,
  ) => {
    if (isDeleted) {
      setDateAvailabilityDict(d => {
        delete d[day];
        return { ...d };
      });
      return;
    }
    let currentDate = dateAvailabilityDict[day] || {
      ...(dayInfo || {}),
      date: day as any,
    };
    currentDate = { ...currentDate, ...updatedValue };
    setDateAvailabilityDict(d => ({
      ...d,
      [day]: currentDate as any,
    }));
  };

  // guide action
  const _onGuideCellClickOnMobile = (day: Date, dayInfo: DayGuideAvailability | undefined | null) => {
    let html = dayInfo?.stayInTours?.map(x => x.name)?.join('<br/>') || '';
    if (html) {
      html = `<div style="color:green">${html}</div>`;
    }
    if (dayInfo?.wfAnotherDepartmentDetails) {
      html += `<div style="color:#3f51b5">${dayInfo?.wfAnotherDepartmentDetails}</div>`;
    }
    const date = dayjs(day).format(DEFAULT_DATE_FORMAT);
    if (dayInfo?.isUnavailable) {
      Modal.confirm({
        title: date,
        content: <div dangerouslySetInnerHTML={{ __html: html }} />,
        cancelText: t('Close'),
        okText: '業務可能',
        okButtonProps: { type: 'primary', icon: <CheckOutlined />, className: 'save-btn' },
        onOk: () => {
          const nextValue = false;
          ApiSdk.GuideAvailabilityService.setDateStatus({
            body: {
              date: date as any,
              guideId: filter.guideId || '',
              isUnavailable: nextValue,
              isWfAnotherDepartment: dayInfo?.isWfAnotherDepartment || false,
              wfAnotherDepartmentDetails: dayInfo.wfAnotherDepartmentDetails,
            },
          }).then(() => {
            updateDateAvailabilityDict(
              date,
              dayInfo,
              !(nextValue || dayInfo?.stayOvernight || dayInfo?.stayLastNight || dayInfo?.isWfAnotherDepartment),
              {
                ...dayInfo,
                isUnavailable: nextValue,
              },
            );
          });
        },
      });
    } else {
      Modal.info({
        title: date,
        content: <div dangerouslySetInnerHTML={{ __html: html }} />,
        okText: t('Close'),
        okButtonProps: { type: 'default' },
      });
    }
  };
  const _onGuideCellClick = (day: Date, dayInfo: DayGuideAvailability | undefined | null) => {
    const isMobile = window.innerWidth < 800;
    const stayInTour = dayInfo?.stayLastNight || dayInfo?.stayOvernight || dayInfo?.isWfAnotherDepartment;
    if (isMobile && stayInTour) {
      _onGuideCellClickOnMobile(day, dayInfo);
      return;
    }
    if (dayInfo?.stayLastNight || dayInfo?.stayOvernight || dayInfo?.isWfAnotherDepartment) {
      if (!dayInfo?.isUnavailable) {
        return;
      }
    }

    const date = dayjs(day).format(DEFAULT_DATE_FORMAT);
    const nextValue = !dayInfo?.isUnavailable;
    ApiSdk.GuideAvailabilityService.setDateStatus({
      body: {
        date: date as any,
        guideId: filter.guideId || '',
        isUnavailable: nextValue,
        isWfAnotherDepartment: dayInfo?.isWfAnotherDepartment || false,
        wfAnotherDepartmentDetails: dayInfo?.wfAnotherDepartmentDetails,
      },
    }).then(() => {
      updateDateAvailabilityDict(date, dayInfo, !(nextValue || dayInfo.stayOvernight || dayInfo.stayLastNight || dayInfo.isWfAnotherDepartment), {
        ...dayInfo,
        isUnavailable: nextValue,
      });
    });
  };

  const onDayCellClick = (day: Date, dayInfo: DayGuideAvailability | undefined | null) => {
    if (isGuide(auth?.roleClaims)) {
      _onGuideCellClick(day, dayInfo);
    } else {
      // staff action
      const date = dayjs(day).format(DEFAULT_DATE_FORMAT);
      setDayStatusModal({
        ...dayInfo,
        date,
        isUnavailable: dayInfo?.isUnavailable || false,
        isWfAnotherDepartment: dayInfo?.isWfAnotherDepartment || false,
        wfAnotherDepartmentDetails: dayInfo?.wfAnotherDepartmentDetails,
      });
    }
  };
  const _onDateChangeStatus = (isUnavailable: boolean, isWfAnotherDepartment: boolean, text: string | null) => {
    ApiSdk.GuideAvailabilityService.setDateStatus({
      body: {
        date: dayStatusModal?.date as any,
        guideId: filter.guideId || '',
        isUnavailable,
        isWfAnotherDepartment,
        wfAnotherDepartmentDetails: text || '',
      },
    }).then(() => {
      let dayInfo = dateAvailabilityDict[dayStatusModal?.date || ''] || {};
      updateDateAvailabilityDict(
        dayStatusModal?.date || '',
        dateAvailabilityDict[dayStatusModal?.date || ''],
        !(isUnavailable || isWfAnotherDepartment || dayInfo.stayLastNight || dayInfo.stayOvernight),
        {
          isUnavailable,
          isWfAnotherDepartment,
          wfAnotherDepartmentDetails: text || '',
        },
      );
      setDayStatusModal(undefined);
    });
  };

  const renderDayCellContent = (dayInfo: DayCellContentArg) => {
    if (dayInfo.isDisabled || dayInfo.isOther || inTour(dayInfo.date)) {
      return <DayNumberText holidays={holidays} date={dayInfo.date} />;
    }
    const day = dateAvailabilityDict[dayjs(dayInfo.date).format(DEFAULT_DATE_FORMAT)];
    if (!day) {
      return (
        <>
          <DayNumberText holidays={holidays} date={dayInfo.date} />
          <div onClick={() => onDayCellClick(dayInfo.date, null)} className="action-button clickable"></div>
        </>
      );
    }
    let iconClass = '';
    let tooltip: string = '';

    if (day.isWfAnotherDepartment) {
      iconClass = 'other-department';
      tooltip = day.wfAnotherDepartmentDetails || '';
    } else if (day.stayLastNight) {
      iconClass = 'stay-last-night';
      tooltip = day.stayInTours?.map(x => x.name).join(', ') || '';
    } else if (day.stayOvernight) {
      iconClass = 'stay-over-night';
      tooltip = day.stayInTours?.map(x => x.name).join(', ') || '';
    } else if (day.isUnavailable) {
      iconClass = 'unavailable';
    }

    return (
      <>
        <div className="day-number">
          <ExtraDateIcon day={day} />
          <DayNumberText holidays={holidays} date={dayInfo.date} />
        </div>
        <div className="action-button" title={day.isWfAnotherDepartment ? day?.wfAnotherDepartmentDetails : ''}>
          {tooltip ? (
            <Tooltip title={tooltip} trigger="hover">
              <CloseCircleFilled onClick={() => onDayCellClick(dayInfo.date, day)} className={`icon ${iconClass}`} />
            </Tooltip>
          ) : (
            <CloseCircleFilled onClick={() => onDayCellClick(dayInfo.date, day)} className={`icon ${iconClass}`} />
          )}
        </div>
      </>
    );
  };

  return (
    <MainContent title={t('Calendar')} titleClassName="no-border-bottom">
      <Helmet title={PAGE_TITLE} />
      <FullPageLoading loading={loading} />
      <div className="ant-layout-content-body" style={{ padding: 0 }}>
        <div className="nta-calendar-container" style={{ marginLeft: 10, marginRight: 10, borderTop: '4px solid #fac863' }}>
          <Row gutter={12} style={{ marginTop: 10, marginBottom: 10 }}>
            <Col xs={24} md={24} xl={allowChangeGuide ? 8 : 14} xxl={allowChangeGuide ? 11 : 17}>
              {<h2 style={{ marginBottom: 0 }}>{`${filter.year}/${filter.month}`}</h2>}
            </Col>

            {allowChangeGuide && (
              <Col xs={24} md={24} xl={6} xxl={6} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <div className="hide-on-mobile">{t('Guide Name')}&nbsp;:&nbsp;</div>
                <GuideInput
                  labelInValue={false}
                  idValue={guideId}
                  value={filter.guideId}
                  onChange={id => setFilter(f => ({ ...f, guideId: id }))}
                  placeholder={t('Guide Name')}
                  style={{ flex: 1 }}
                  isIncludeDisabledGuide={true}
                />
              </Col>
            )}
            <Col xs={12} md={12} xl={6} xxl={4} className="row-on-mobile" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <div className="hide-on-mobile">{t('Display Month')}&nbsp;:&nbsp;</div>
              <DatePicker
                onChange={d => {
                  if (d) {
                    setFilter(f => ({ ...f, month: d.month() + 1, year: d.year() }));
                  }
                }}
                value={moment(toDate(filter.year, filter.month))}
                allowClear={false}
                picker="month"
                style={{ flex: 1 }}
              />
            </Col>
            <Col xs={12} md={12} xl={4} xxl={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Space>
                <Button
                  style={{ backgroundColor: '#ff957b' }}
                  icon={<CalendarOutlined />}
                  type="ghost"
                  onClick={() => {
                    setFilter(f => ({
                      ...f,
                      month: dayjs().month() + 1,
                      year: dayjs().year(),
                    }));
                  }}
                >
                  {t('Current month')}
                </Button>
                <Button onClick={() => onChangeMonth(-1)} icon={<ArrowLeft />} />
                <Button onClick={() => onChangeMonth(1)} icon={<ArrowRight />} />
              </Space>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              {filter.guideId ? (
                <FullCalendar
                  handleWindowResize={true}
                  key={`${filter.year}-${filter.month}-${filter.guideId}`}
                  dayCellContent={renderDayCellContent}
                  dayCellClassNames={dayInfo => {
                    if (inTour(dayInfo.date)) {
                      return '';
                    }
                    const day = dateAvailabilityDict[dayjs(dayInfo.date).format(DEFAULT_DATE_FORMAT)];
                    if (day) {
                      if (day.isUnavailable) {
                        return 'not-in-tour gray-out';
                      }
                    }
                    return 'not-in-tour';
                  }}
                  height="auto"
                  initialDate={toDate(filter.year, filter.month)}
                  themeSystem="default"
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  locales={[{ code: i18n.language }]}
                  dayMaxEventRows={3}
                  headerToolbar={false}
                  dayMaxEvents={3}
                  events={events}
                  displayEventTime={false}
                  defaultAllDay
                  aspectRatio={2}
                  eventClick={evt => {
                    setEditingTourId((evt.event as any)?.id);
                  }}
                />
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
      <DayStatusSelectModal
        day={dayStatusModal?.date || ''}
        visible={!!dayStatusModal}
        onChange={(isUnavailable, isWfAnotherDepartment, wfAnotherDepartmentDetails) => {
          _onDateChangeStatus(isUnavailable, isWfAnotherDepartment, wfAnotherDepartmentDetails);
        }}
        onClose={() => {
          setDayStatusModal(undefined);
        }}
        isUnavailable={dayStatusModal?.isUnavailable}
        isWfAnotherDepartment={!!dayStatusModal?.isWfAnotherDepartment}
        wfAnotherDepartmentDetails={dayStatusModal?.wfAnotherDepartmentDetails || ''}
      />
      {!!editingTourId ? (
        isGuide(auth?.roleClaims) ? (
          <TourGuideEditModal
            showGuideEvaluation={setGuideEvaluation}
            visible={!!editingTourId}
            tourId={editingTourId}
            onClose={() => {
              setEditingTourId(undefined);
            }}
          />
        ) : (
          <AdminUpdateTourModal
            showGuideEvaluation={setGuideEvaluation}
            visible={!!editingTourId}
            tourId={editingTourId}
            onClose={() => {
              setEditingTourId(undefined);
            }}
          />
        )
      ) : null}
      {guideEvaluation ? (
        <TourEvaluation
          tourInfo={guideEvaluation}
          onClose={() => {
            setGuideEvaluation(undefined);
          }}
        />
      ) : null}
    </MainContent>
  );
}
