import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import ProtectedRoute from '@/components/routes/ProtectedRoute';
import AppRoutes from '@/routes';
import { CurrentUserModel } from '@/__generated';
import { hasRoles, isGuideOnly } from '@/services/roleService';

export default function renderRoutes(auth: CurrentUserModel | null) {
  return (
    <Switch>
      {AppRoutes.filter(x => hasRoles(x.roles, auth)).map((r, idx) => {
        return <ProtectedRoute key={idx} {...r} />;
      })}

      <Redirect from="*" to={isGuideOnly(auth?.roleClaims) ? '/cms/calendars' : '/cms/guides-schedule'} />
    </Switch>
  );
}
