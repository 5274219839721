import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Row, Checkbox, Input, Typography, Button, Radio, Divider } from 'antd';
// import moment from 'moment';
import { MinusCircleOutlined, PlusOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import DatePicker from '@/components/DatePicker';
import { fileService } from '@/services/fileService';
import { AvailableTypes, AvailableArea, VALIDATE_REQUIRED } from '../../../constants';
import FileDownload from '@/components/app/FileDownload';
import { FormInstance } from 'antd/es/form/Form';

type Props = {
  form: FormInstance;
  previewResume: string;
  previewCV: string;
  onImageChangeResume: (e: { currentTarget: HTMLInputElement }) => void;
  onFileChangeCV: (e: { currentTarget: HTMLInputElement }) => void;
  onRemoveResume: () => void;
  onRemoveCV: () => void;
  isNewForm: boolean;
  formVisible: boolean;
};
const { Text } = Typography;
export default function SelfIntroduction({
  previewResume,
  previewCV,
  isNewForm,
  form,
  onImageChangeResume,
  onFileChangeCV,
  onRemoveResume,
  onRemoveCV,
  formVisible,
}: Props) {
  const { t } = useTranslation();
  const refResume = React.useRef<HTMLInputElement>();
  const refCV = React.useRef<HTMLInputElement>();
  const [canDownloadResume, setCanDownloadResume] = React.useState(true);
  const [canDownloadCSV, setCanDownloadCSV] = React.useState(true);

  React.useEffect(() => {
    if (formVisible) {
      if (isNewForm) {
        setCanDownloadResume(false);
        setCanDownloadCSV(false);
      } else {
        setCanDownloadResume(true);
        setCanDownloadCSV(true);
      }
    }
  }, [isNewForm, formVisible]);
  React.useEffect(() => {
    if (!formVisible) {
      refResume.current?.setAttribute('value', '');
      refCV.current?.setAttribute('value', '');
    }
  }, [formVisible]);
  return (
    <Row gutter={16}>
      <Col md={24} xs={24}>
        <Form.Item label={t('guideSerchsupportedTypes')} name="supportedTypes" labelCol={{ xs: 24 }} wrapperCol={{ xs: 24 }}>
          <Checkbox.Group>
            {AvailableTypes.map(availableType => (
              <Checkbox value={availableType.value} key={availableType.value}>
                {availableType.label}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </Col>
      <Col md={24} xs={24}>
        <Form.Item label={t('guideSerchsupportedAreas')} name="supportedAreas" labelCol={{ xs: 24 }} wrapperCol={{ xs: 24 }}>
          <Checkbox.Group>
            {AvailableArea.map(availableArea => (
              <Checkbox value={availableArea.value} key={availableArea.value}>
                {availableArea.label}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </Col>
      <Col md={24} xs={24}>
        <Form.Item label={t('guideSerchselfIntroduction')} name="selfIntroduction" wrapperCol={{ xs: 24 }}>
          <Input.TextArea className="w-100 input-value-color" rows={5} placeholder="詳細" />
        </Form.Item>
      </Col>
      <Col md={24} xs={24}>
        <Form.List name="overseaExperiences">
          {(fields, { add, remove }) => (
            <>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10, marginBottom: 10 }}>
                <div>
                  海外在留経験
                  <br /> ※日付が新しい順にご記入ください
                </div>
                <Button type="primary" className="save-btn" onClick={() => add()} icon={<PlusOutlined />}>
                  {t('guideSerchadd')}
                </Button>
              </div>
              {fields.map(field => (
                <Form.Item noStyle shouldUpdate={() => true}>
                  {({ getFieldValue }) => {
                    const values = getFieldValue(['overseaExperiences', field.name]) || {};
                    const required = !!(values.from || values.to || values.place || values.details);
                    return (
                      <Row gutter={6}>
                        <Col md={20} xs={24}>
                          <Row gutter={6}>
                            <Col xs={4} md={0}>
                              期間
                            </Col>
                            <Col md={8} xs={8}>
                              <Form.Item
                                name={[field.name, 'from']}
                                wrapperCol={{ xs: 24 }}
                                rules={[{ required: required, message: VALIDATE_REQUIRED }]}
                              >
                                <DatePicker className="w-100 input-value-color" placeholder={t('guideSerchStayingAbroadFrom')} />
                              </Form.Item>
                            </Col>
                            <Col md={8} xs={8}>
                              <Form.Item
                                name={[field.name, 'to']}
                                wrapperCol={{ xs: 24 }}
                                rules={[{ required: required, message: VALIDATE_REQUIRED }]}
                              >
                                <DatePicker className="w-100 input-value-color" placeholder={t('guideSerchStayingAbroadTo')} />
                              </Form.Item>
                            </Col>
                            <Col md={0} xs={4} style={{ textAlign: 'center' }}>
                              <Button icon={<DeleteOutlined />} danger className="delete-btn" onClick={() => remove(field.name)} size="small">
                                削除
                              </Button>
                            </Col>

                            <Col xs={4} md={0}>
                              場所
                            </Col>
                            <Col md={8} xs={20}>
                              <Form.Item
                                name={[field.name, 'place']}
                                wrapperCol={{ xs: 24 }}
                                rules={[{ required: required, message: VALIDATE_REQUIRED }]}
                              >
                                <Input placeholder="場所" maxLength={50} />
                              </Form.Item>
                            </Col>
                            <Col md={24} xs={24}>
                              <Form.Item name={[field.name, 'details']} wrapperCol={{ xs: 24 }}>
                                <Input.TextArea className="w-100 input-value-color" rows={4} placeholder="詳細" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>

                        <Col md={4} xs={0} style={{ textAlign: 'center' }}>
                          <Button icon={<DeleteOutlined />} className="delete-btn" danger onClick={() => remove(field.name)} size="small">
                            削除
                          </Button>
                        </Col>
                      </Row>
                    );
                  }}
                </Form.Item>
              ))}
            </>
          )}
        </Form.List>
      </Col>
      <Divider />
      <Col md={12} xs={24}>
        <Form.Item name="resumeUpload" wrapperCol={{ xs: 24 }}>
          <Row gutter={6}>
            <Col md={24} xs={8} style={{ marginBottom: 5 }}>
              {t('guideSerchresumeUpload')}
            </Col>

            <Col md={8} xs={16}>
              <Button
                type="primary"
                className="mb-1 save-btn"
                icon={<PlusOutlined />}
                onClick={() => {
                  refResume.current?.click();
                }}
                size={'small'}
              >
                {t('guideSerchupload')}
              </Button>
              <input
                style={{ display: 'none' }}
                ref={refResume as any}
                type="file"
                name="fileUpload-resume"
                accept=".pdf, .xls, .xlsx, .docx"
                onChange={evt => {
                  setCanDownloadResume(false);
                  onImageChangeResume(evt);
                }}
              />
            </Col>
            <Col md={12} xs={16}>
              {previewResume ? (
                <FileDownload
                  onDownloadClientFile={() => {
                    const files = refResume.current?.files;
                    if (files?.length) {
                      fileService.downloadAttachmentFile(files[0], previewResume);
                    }
                  }}
                  fileName={previewResume}
                  fileId={canDownloadResume ? form.getFieldValue('resumeUploadId') : null}
                />
              ) : null}
            </Col>
            <Col md={4} xs={8} style={{ textAlign: 'end' }}>
              <Button
                disabled={!previewResume}
                icon={<DeleteOutlined />}
                danger
                className="delete-btn"
                onClick={() => {
                  onRemoveResume();
                  refResume.current?.setAttribute('value', '');
                }}
                size={'small'}
              >
                {t('guideSerchdelete')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Col>

      <Col md={12} xs={24}>
        <Form.Item name="cvUpload" wrapperCol={{ xs: 24 }}>
          <Row gutter={6}>
            <Col md={24} xs={8} style={{ marginBottom: 5 }}>
              {t('guideSerchcVUpload')}
            </Col>
            <Col md={8} xs={16}>
              <Button
                type="primary"
                className="mb-1 save-btn"
                icon={<PlusOutlined />}
                onClick={() => {
                  refCV.current?.click();
                }}
                size={'small'}
              >
                {t('guideSerchupload')}
              </Button>
              <input
                style={{ display: 'none' }}
                ref={refCV as any}
                type="file"
                name="fileUpload-cv"
                accept=".pdf, .xls, .xlsx, .docx"
                onChange={evt => {
                  onFileChangeCV(evt);
                  setCanDownloadCSV(false);
                }}
              />
            </Col>
            <Col md={12} xs={16}>
              {previewCV ? (
                <FileDownload
                  onDownloadClientFile={() => {
                    const files = refCV.current?.files;
                    if (files?.length) {
                      fileService.downloadAttachmentFile(files[0], previewCV);
                    }
                  }}
                  fileName={previewCV}
                  fileId={canDownloadCSV ? form.getFieldValue('cvUploadId') : null}
                />
              ) : null}
            </Col>

            <Col md={4} xs={8} style={{ textAlign: 'end' }}>
              <Button
                disabled={!previewCV}
                icon={<DeleteOutlined />}
                danger
                className="delete-btn"
                onClick={() => {
                  onRemoveCV();
                  refCV.current?.setAttribute('value', '');
                }}
                size={'small'}
              >
                {t('guideSerchdelete')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Col>

      <Col md={24} xs={24}>
        <Form.Item name="memo" label={t('guideSerchmemo')} labelCol={{ xs: 24 }} wrapperCol={{ xs: 24 }}>
          <Input.TextArea className="w-100 input-value-color" rows={5} placeholder="詳細" />
        </Form.Item>
      </Col>
    </Row>
  );
}
