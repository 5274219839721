import React, { useState } from 'react';
import { Button, Form, Modal, Row, Input, Col, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PlusCircleOutlined, UserOutlined, SaveOutlined, AuditOutlined } from '@ant-design/icons';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import moment from 'moment';

import ZTable, { IFilterItem, ITableRef } from '@/components/ZTable';
import MainContent from '@/Layout/MainContent';
import { ApiSdk } from '../../httpclient';
import { FullDateWithoutSecondFormat, VALIDATE_REQUIRED } from '../../constants';
import './style.less';

type Props = {};

export default function DepartmentSearch({}: Props) {
  const history = useHistory();
  const [t] = useTranslation();
  const zTb = React.useRef<ITableRef>();
  const [formVisible, setFormVisible] = useState(false);
  const [isNewForm, setIsNewForm] = useState(false);
  const [form] = Form.useForm();

  const filters: Array<IFilterItem> = React.useMemo(() => {
    return [{ type: 'TEXT', name: 'departmentName', label: 'staffSearch_department', mdSize: 8, xlSize: 8, xxlSize: 8 }];
  }, []);

  const { columns } = React.useMemo(() => {
    const _columns: ColumnsType<any> = [
      {
        title: 'No.',
        width: 50,
        align: 'center',
        render: (v, r, i) => {
          return <b>{i + 1}</b>;
        },
      },
      { title: t('staffSearch_department'), dataIndex: 'departmentName', defaultSortOrder: 'ascend', sorter: true },
      {
        title: t('staffSearch_lastCreateDate'),
        width: 200,
        sorter: true,
        dataIndex: 'createdAt',
        render: (value: any) => {
          return value ? moment(value).format(FullDateWithoutSecondFormat) : '';
        },
      },
      {
        title: t('staffSearch_lastUpdatedDate'),
        width: 200,
        sorter: true,
        dataIndex: 'updatedAt',
        render: (value: any) => {
          return value ? moment(value).format(FullDateWithoutSecondFormat) : '';
        },
      },
      {
        title: t('staffSearch_lastUpdatedBy'),
        width: 200,
        sorter: true,
        dataIndex: 'updatedBy',
      },
    ];
    return { columns: _columns };
  }, [t]);

  const updateTable = response => {
    if (response && response.success) {
      setFormVisible(false);
      zTb.current?.reload();
    } else {
      if (response.message) {
        message.error(t(response.message || ''));
      }
    }
  };

  const saveDepartment = async values => {
    try {
      if (isNewForm) {
        const response = await ApiSdk.DepartmentService.create({ body: values });
        updateTable(response);
      } else {
        const response = await ApiSdk.DepartmentService.update({ body: values });
        updateTable(response);
      }
    } catch (error) {
      alert(error);
    }
  };

  const getExtraButtons = () => {
    let extrabtns = [
      <Button
        key="subDivision"
        style={{ background: '#f59c1a', color: '#fff' }}
        icon={<AuditOutlined style={{ color: '#333' }} />}
        onClick={() => {
          history.push('/cms/master-data/subdivision');
        }}
      >
        {t('staffSearch_subdivision')}
      </Button>,
      <Button
        key="create"
        type="primary"
        className="nta-create-btn"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          form.resetFields();
          setIsNewForm(true);
          setFormVisible(true);
          form.setFieldsValue({
            userPermission: 'RegularUser',
            approvalPermission: 1,
            viewingPermission: 4,
            usageStatus: 1,
          });
        }}
      >
        {t('staffSearch_new')}
      </Button>,
    ];

    return extrabtns;
  };

  return (
    <MainContent title={t('staffSearch_department_title')} extraBtns={getExtraButtons()} icon={<UserOutlined style={{ fontSize: 16 }} />}>
      <Helmet title={t('staffSearch_department_title')} />
      <div className="ant-layout-content-body">
        <ZTable<any>
          columns={columns}
          primaryKey="id"
          tableRef={zTb}
          url="/departments/get-departments"
          bordered
          order_by="DepartmentName"
          order_by_asc={true}
          allowEdit={true}
          onEdit={record => {
            setIsNewForm(false);
            setFormVisible(true);
            form.setFieldsValue(record);
          }}
          allowDelete={false}
          filters={filters}
          layoutFilter={'horizontal'}
        />

        <Modal
          className="nta-custom-form"
          visible={formVisible}
          maskClosable={false}
          width={600}
          title={'部署登録'}
          forceRender={true}
          okText={t('staffSearch_save')}
          okButtonProps={{ icon: <SaveOutlined />, className: 'save-btn' }}
          onCancel={() => {
            form.resetFields();
            setFormVisible(false);
          }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                saveDepartment(values);
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Form form={form} size="middle" layout="vertical">
            <Form.Item name="id" className="hidden">
              <Input type="hidden" />
            </Form.Item>
            <Row gutter={16}>
              <Col md={24}>
                <Form.Item label={t('staffSearch_department')} name="departmentName" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </MainContent>
  );
}
