import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import PageLoading from '../PageLoading';

const ProtectedRoute = ({ component: Component = null, path, children = null, user = null, ...rest }: any) => {
  const context = useContext(AppContext);
  const { authLoaded, auth } = context;

  if (!authLoaded) {
    return <PageLoading />;
  } else if (!auth) {
    return <Redirect to={`/cms/login?callback=${window.location.pathname}`} />;
  }

  if (children) {
    return (
      <Route path={path} {...rest}>
        {children}
      </Route>
    );
  }
  return <Route path={path} {...rest} render={props => <Component {...props} />} />;
};

export default ProtectedRoute;
