import httpClient from '@/httpclient';
import { message } from 'antd';
const getFileName = (header: any) => {
  var disposition = header['content-disposition'];
  if (disposition) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  return '';
};

class FileService {
  saveByte2File = (fileName: string, blob) => {
    try {
      const a: any = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      window.setTimeout(() => {
        document.body.removeChild(a);
      }, 200);
    } catch (error) {
      console.log(error);
    }
  };
  downloadAttachmentFile = (file: any, fileName: string | null | undefined) => {
    try {
      const a: any = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      let url = URL.createObjectURL(file);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      window.setTimeout(() => {
        document.body.removeChild(a);
      }, 200);
    } catch (error) {}
  };

  downloadSeisanFileById = () => {};
  downloadFileById = (id: string, url?: string | null | undefined, fileName?: string | null | undefined) => {
    const loader = message.loading('Downloading');
    httpClient
      .get(`/tours/download-attachment/${id}`, {
        params: { url: url || undefined },
        responseType: 'blob',
      })
      .then(res => {
        this.saveByte2File(fileName || getFileName(res.headers), res.data);
      })
      .catch(() => {
        message.error('Error');
      })
      .finally(() => {
        loader();
      });
  };
}

export const fileService = new FileService();
