import { TourGuideStatusType, TourSourceType, TourStatusType } from '@/config';
import tour from '@/languages/en/tour';
import { BusinessDay, BusinessDayDetailModel, CreateUpdateTourModel, TourDetailModel, TourDto } from '@/__generated';
import { Modal } from 'antd';
import dayjs from 'dayjs';
import lodash from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';
import { Trans } from 'react-i18next';
import {
  checkGuideCancellationAllDateIsCancelled,
  checkGuideDataChange,
  checkGuideUnAvailability,
  checkNeedNtaCommentForCreateTour,
  checkNeedNtaCommentForUpdateTour,
  checkNeedSendMailWhenStatusAssignedAndDateChange,
  checkUnassignedNoDate,
  deleteCancelledDateIfNeed,
  validateDataOnSubmit,
} from './validation';

// If there is any changes (Business Date including change/ new date, Fee, Availability, Overnight, Prenight, IsCancelled),
// and status is changed to NOT [Unassigned, Assigning, CXLing],
// show the warning: "The below Guides have some changes, the status should be "Assigning". Are you sure of proceeding with the selected status?
export const checkGuideDataChangePromise = (formBody: any, tour: any): Promise<{ next: boolean; nextFormData: any }> => {
  return new Promise(resolve => {
    const { hasChange, guides, newFormData } = checkGuideDataChange(formBody, tour as any);
    if (hasChange) {
      Modal.confirm({
        width: 500,
        title: <Trans>SOME_CHANGES_IN_THESE_TOUR_GUIDES</Trans>,
        cancelText: <Trans>No</Trans>,
        okText: <Trans>Yes</Trans>,
        content: (
          <ul style={{ listStyle: 'circle' }}>
            {guides?.map((g, index) => (
              <li key={index}>
                <b>
                  {g.guide} ({g.language}) - {<Trans>{TourGuideStatusType.__getValue(g.status)}</Trans>}
                </b>
              </li>
            ))}
          </ul>
        ),
        onOk: () => {
          resolve({
            next: true,
            nextFormData: newFormData,
          });
        },
        onCancel: () => {
          resolve({
            next: false,
            nextFormData: newFormData,
          });
        },
      });
    } else {
      resolve({
        next: true,
        nextFormData: newFormData,
      });
    }
  });
};
export const checkGuideUnAvailabilityPromise = (formBody: any): Promise<{ next: boolean; nextFormData: any }> => {
  return new Promise(resolve => {
    const { showMessage, guides } = checkGuideUnAvailability(formBody);
    if (showMessage) {
      Modal.confirm({
        width: 500,
        title: <Trans>GUIDE_ALL_DATE_UNAVAILABLE</Trans>,
        cancelText: <Trans>No</Trans>,
        okText: <Trans>Yes</Trans>,
        content: (
          <div>
            <ul style={{ listStyle: 'circle' }}>
              {guides?.map((g, index) => (
                <li key={index}>
                  <b>
                    {g.name} ({g.language}) - {<Trans>{TourGuideStatusType.__getValue(g.status)}</Trans>}
                  </b>
                </li>
              ))}
            </ul>
          </div>
        ),
        onOk: () => {
          resolve({
            next: true,
            nextFormData: formBody,
          });
        },
        onCancel: () => {
          resolve({
            next: false,
            nextFormData: formBody,
          });
        },
      });
    } else {
      resolve({
        next: true,
        nextFormData: formBody,
      });
    }
  });
};

export const deleteCancelledDateIfNeedPromise = (formBody: CreateUpdateTourModel): Promise<{ next: boolean; nextFormData: any }> => {
  return new Promise(resolve => {
    const { showMessage, guides } = deleteCancelledDateIfNeed(formBody);
    if (showMessage) {
      Modal.confirm({
        width: 500,
        title: <Trans>KEEP_ALL_CANCELLED_DATE</Trans>,
        cancelText: <Trans>No</Trans>,
        okText: <Trans>Yes</Trans>,
        content: (
          <ul style={{ listStyle: 'circle' }}>
            {guides?.map((g, index) => (
              <li key={index}>
                <b>
                  {g.name} ({g.language}) - {<Trans>{TourGuideStatusType.__getValue(g.status)}</Trans>}
                </b>
              </li>
            ))}
          </ul>
        ),
        onOk: () => {
          resolve({
            next: true,
            nextFormData: formBody,
          });
        },
        onCancel: () => {
          formBody.tourGuides?.forEach(guide => {
            if (guide.status === TourGuideStatusType.Unassigned) {
              guide.businessDays = guide.businessDays?.filter(x => !x.isCancelled);
            }
          });
          resolve({
            next: true,
            nextFormData: formBody,
          });
        },
      });
    } else {
      resolve({
        next: true,
        nextFormData: formBody,
      });
    }
  });
};

// Confirmation 1.1
// If the selected status != Awaiting Cancellation AND all dates: IsCancelled = true;
// of which, there is at least one date is NEWLY cancelled
// show the warning: The below Guides have all dates requested for Cancellation, the status should be "Awaiting Cancellation".
//  Are you sure of proceeding with the selected status?
export const checkGuideCancellationAllDateIsCancelledPromise = (formBody: any, tour: any): Promise<{ next: boolean; showMessage: boolean }> => {
  return new Promise(resolve => {
    const { showMessage, guides } = checkGuideCancellationAllDateIsCancelled(formBody, tour);
    if (showMessage) {
      Modal.confirm({
        width: 500,
        title: <Trans>ALL_DATE_CANCELLATION_SHOULD_BE_AWAITING_CANCELLATION</Trans>,
        cancelText: <Trans>No</Trans>,
        okText: <Trans>Yes</Trans>,
        content: (
          <ul style={{ listStyle: 'circle' }}>
            {guides?.map((g, index) => (
              <li key={index}>
                <b>
                  {g.name} ({g.language}) - {<Trans>{TourGuideStatusType.__getValue(g.status)}</Trans>}
                </b>
              </li>
            ))}
          </ul>
        ),
        onOk: () => {
          resolve({
            next: true,
            showMessage: true,
          });
        },
        onCancel: () => {
          resolve({
            next: false,
            showMessage: true,
          });
        },
      });
    } else {
      resolve({
        next: true,
        showMessage: false,
      });
    }
  });
};

// when status changes to Assigned, email is sent out
// when status is kept as Assigned but there are changes (date, fee, avai, pre, last)
// Show popup: ガイドにメールを送りますか？(Do you want to send an email to the guide?)
// - If yes, send emtail
// - If no, don't send email
export const checkNeedSendMailWhenStatusAssignedAndDateChangePromise = (formBody: any, tour: any): Promise<{ next: boolean; nextFormData: any }> => {
  return new Promise(resolve => {
    const { hasChange, changeGuides } = checkNeedSendMailWhenStatusAssignedAndDateChange(formBody, tour);
    if (hasChange) {
      Modal.confirm({
        width: 500,
        title: <Trans>ガイドにメールを送りますか？</Trans>,
        cancelText: <Trans>No</Trans>,
        okText: <Trans>Yes</Trans>,
        content: (
          <ul style={{ listStyle: 'circle' }}>
            {changeGuides?.map((g, index) => (
              <li key={index}>
                <b>
                  {g.guide} ({g.language})
                </b>
              </li>
            ))}
          </ul>
        ),
        onOk: () => {
          resolve({
            next: true,
            nextFormData: {
              ...formBody,
              allowSendConfirmGuideAcceptanceEmail: true,
            },
          });
        },
        onCancel: () => {
          resolve({
            next: true,
            nextFormData: {
              ...formBody,
              allowSendConfirmGuideAcceptanceEmail: false,
            },
          });
        },
      });
    } else {
      resolve({
        next: true,
        nextFormData: { ...formBody, allowSendConfirmGuideAcceptanceEmail: false },
      });
    }
  });
};

export const checkTourDataBeforeUpdate = async (formBody: any, tour: any): Promise<{ submit: boolean; formData: any }> => {
  // check guide multi language
  const { isValid, errMessage } = validateDataOnSubmit(formBody);
  if (!isValid) {
    Modal.error({
      title: <Trans>Error</Trans>,
      width: 500,
      content: (
        <ul style={{ listStyle: 'circle' }}>
          {errMessage?.map((err, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: err }}></li>
          ))}
        </ul>
      ),
    });
    return {
      submit: false,
      formData: formBody,
    };
  }
  // check status !==Unassigned and have no date
  const { guides, showMessage } = checkUnassignedNoDate(formBody);
  if (showMessage) {
    Modal.error({
      title: <Trans>ADD_AT_LEAST_ONE_BUSHINESS_DAY</Trans>,
      width: 500,
      content: (
        <ul style={{ listStyle: 'circle' }}>
          {guides?.map((g, index) => (
            <li key={index}>
              <b>
                {g.name} ({g.language}) - {<Trans>{TourGuideStatusType.__getValue(g.status)}</Trans>}
              </b>
            </li>
          ))}
        </ul>
      ),
    });
    return {
      submit: false,
      formData: formBody,
    };
  }

  // Confirmation 1.1
  const { next, showMessage: allDateIsCancelledMessage } = await checkGuideCancellationAllDateIsCancelledPromise(formBody, tour);
  if (!next) {
    return { submit: false, formData: formBody };
  }

  let nextData: any = {
    next,
    nextFormData: formBody,
  };

  if (!allDateIsCancelledMessage) {
    // Confirmation 1.2
    // If there is any changes (Business Date including change/ new date, Fee, Availability, Overnight, Prenight, IsCancelled),
    // and status is changed to NOT [Unassigned, Assigning, CXLing],
    // show the warning: "The below Guides have some changes, the status should be "Assigning". Are you sure of proceeding with the selected status?
    const checkDataChangeResponse = await checkGuideDataChangePromise(formBody, tour);
    nextData = checkDataChangeResponse;
  }
  if (!nextData.next) {
    return {
      submit: false,
      formData: formBody,
    };
  }

  // Confirmation 2
  // If next status is NTA Checking, Assigned, Checked, FNLed, Assigning, FNLing
  // and all dates: Availability = No:
  // show message "The Guides below have all dates unavailable. Are you sure of proceeding" Yes: proceed, No: keep form
  const checkGuideUnAvailabilityResponse = await checkGuideUnAvailabilityPromise(nextData.nextFormData);
  nextData = checkGuideUnAvailabilityResponse;
  if (!nextData.next) {
    return {
      submit: false,
      formData: nextData.nextFormData,
    };
  }
  // check nta comment
  const { needComment, guides: guidesCm } = checkNeedNtaCommentForUpdateTour(formBody, tour as any);
  if (needComment) {
    Modal.error({
      title: <Trans>PLEASE_INPUT_NTA_COMMENT</Trans>,
      width: 500,
      content: (
        <ul style={{ listStyle: 'circle' }}>
          {guidesCm?.map((g, index) => (
            <li key={index}>
              <b>{g.name}</b>-<b> {g.language}</b>
            </li>
          ))}
        </ul>
      ),
    });
    return {
      submit: false,
      formData: nextData.nextFormData,
    };
  }

  // when status changes to Assigned, email is sent out
  // when status is kept as Assigned but there are changes (date, fee, avai, pre, last)
  // Show popup: ガイドにメールを送りますか？(Do you want to send an email to the guide?)
  // - If yes, send emtail
  // - If no, don't send email
  nextData = await checkNeedSendMailWhenStatusAssignedAndDateChangePromise(nextData.nextFormData, tour);

  return {
    submit: true,
    formData: nextData.nextFormData,
  };
};

export const checkTourDataBeforeCreate = async (formBody: any): Promise<{ submit: boolean; formData: any }> => {
  // check guide multi language
  const { isValid, errMessage } = validateDataOnSubmit(formBody);
  if (!isValid) {
    Modal.error({
      title: <Trans>Error</Trans>,
      width: 500,
      content: (
        <ul style={{ listStyle: 'circle' }}>
          {errMessage?.map((err, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: err }}></li>
          ))}
        </ul>
      ),
    });
    return {
      submit: false,
      formData: formBody,
    };
  }
  // check status !==Unassigned and have no date
  const { guides, showMessage } = checkUnassignedNoDate(formBody);
  if (showMessage) {
    Modal.error({
      title: <Trans>ADD_AT_LEAST_ONE_BUSHINESS_DAY</Trans>,
      width: 500,
      content: (
        <ul style={{ listStyle: 'circle' }}>
          {guides?.map((g, index) => (
            <li key={index}>
              <b>{g.name}</b>-<b> {g.language}</b>
            </li>
          ))}
        </ul>
      ),
    });
    return {
      submit: false,
      formData: formBody,
    };
  }
  // Confirmation 1.1
  const { next } = await checkGuideCancellationAllDateIsCancelledPromise(formBody, tour);
  if (!next) {
    return { submit: false, formData: formBody };
  }
  // Confirmation 2
  // If next status is NTA Checking, Assigned, Checked, FNLed, Assigning, FNLing
  // and all dates: Availability = No:
  // show message "The Guides below have all dates unavailable. Are you sure of proceeding" Yes: proceed, No: keep form
  const checkGuideUnAvailabilityResponse = await checkGuideUnAvailabilityPromise(formBody);
  if (!checkGuideUnAvailabilityResponse.next) {
    return {
      submit: false,
      formData: checkGuideUnAvailabilityResponse.nextFormData,
    };
  }

  // check nta comment
  const { needComment, guides: guidesCm } = checkNeedNtaCommentForCreateTour(checkGuideUnAvailabilityResponse.nextFormData);
  if (needComment) {
    Modal.error({
      title: <Trans>PLEASE_INPUT_NTA_COMMENT</Trans>,
      width: 500,
      content: (
        <ul style={{ listStyle: 'circle' }}>
          {guidesCm?.map((g, index) => (
            <li key={index}>
              <b>
                {g.name} ({g.language}) - {<Trans>{TourGuideStatusType.__getValue(g.status)}</Trans>}
              </b>
            </li>
          ))}
        </ul>
      ),
    });
    return {
      submit: false,
      formData: checkGuideUnAvailabilityResponse.nextFormData,
    };
  }
  return {
    submit: true,
    formData: checkGuideUnAvailabilityResponse.nextFormData,
  };
};

export const createCopyTourData = (tour: TourDetailModel, startDate: Moment): any => {
  const guideBusinessDays = tour.tourGuides?.map(x => x.businessDays?.filter(d => d.date)) || [];
  let dates: BusinessDayDetailModel[] = [];
  guideBusinessDays.forEach(d => {
    if (d) {
      dates = dates.concat(d);
    }
  });
  const fromDate = lodash.minBy(dates, x => moment(x.date));
  const dateDiff = startDate.diff(moment(fromDate?.date), 'day');
  const getNewDate = (date: any) => {
    return moment(date).add(dateDiff, 'day');
  };
  const unCopyGuideStatus = [TourGuideStatusType.Cancelled, TourGuideStatusType.AwaitingCancellation, TourGuideStatusType.Denied];
  return {
    ...tour,
    fromDate: startDate,
    toDate: getNewDate(tour.toDate),
    status: TourStatusType.Confirming as any,
    tourNumber: undefined,
    noOfAdultBooked: undefined,
    noOfChildABooked: undefined,
    noOfChildBBooked: undefined,
    noOfInfantBooked: undefined,
    noOfLeadBooked: undefined,
    tourGuides: tour.tourGuides
      ?.filter(x => !unCopyGuideStatus.includes(x.status as any))
      .map(tourGuide => {
        return {
          ...tourGuide,
          attachments: tour.tourSource === TourSourceType.GuideLink ? tourGuide.attachmentFiles : undefined,
          ntaComments: undefined,
          guide: null,
          status: TourGuideStatusType.Unassigned,
          language: tourGuide.languageCode
            ? {
                label: tourGuide.languageName,
                value: tourGuide.languageCode,
              }
            : null,
          briefingType: undefined,
          briefingDate: undefined,
          briefingTime: undefined,
          expectedDateOfMaterials: undefined,
          desiredBriefingDate1: undefined,
          desiredBriefingDate2: undefined,
          desiredBriefingDate3: undefined,
          desiredBriefingTime1: undefined,
          desiredBriefingTime2: undefined,
          desiredBriefingTime3: undefined,
          desiredDateOfMaterial1: undefined,
          desiredDateOfMaterial2: undefined,
          desiredDateOfMaterial3: undefined,
          desiredTimeOfMaterial1: undefined,
          desiredTimeOfMaterial2: undefined,
          desiredTimeOfMaterial3: undefined,
          businessDays: lodash
            .orderBy(tourGuide.businessDays, d => {
              if (d.date) {
                return dayjs(d.date).unix();
              }
              return Number.MAX_VALUE;
            })
            .map(d => {
              return {
                ...d,
                availability: true,
                date: getNewDate(d.date),
                isConfirmed: false,
                id: undefined,
                isCancelled: false,
              };
            }),
        };
      }),
  };
};
