import React from 'react';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import AppMenu, { MenuItemProps } from '@/menu';
import FeatherIcon from 'feather-icons-react';
import { useLocation, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '@/contexts/AppContext';
import { hasRoles } from '@/services/roleService';
import { CurrentUserModel } from '@/__generated';

const { SubMenu } = Menu;

const renderMenuItem = (menu: MenuItemProps, t: any, auth: CurrentUserModel | null) => {
  if (!menu.subMenu) {
    return (
      <Menu.Item
        icon={
          menu.icon && (
            <NavLink className="menuItem-iocn" to={menu.to || ''}>
              {menu.icon}
            </NavLink>
          )
        }
        key={menu.key}
      >
        <NavLink to={menu.to || ''}>{t(menu.title)}</NavLink>
      </Menu.Item>
    );
  } else {
    return (
      <SubMenu key={menu.key} icon={menu.icon} title={t(menu.title)}>
        {menu.subMenu
          .filter(x => hasRoles(x.roles, auth))
          .map(m => {
            return renderMenuItem(m, t, auth);
          })}
      </SubMenu>
    );
  }
};

interface Props {
  onCloseSider: () => void;
}
const MenuItems = ({ onCloseSider }: Props) => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { auth } = useAppContext();
  const [t] = useTranslation();
  const [openKeys, setOpenKeys] = React.useState<string[]>();
  const mainPathSplit = React.useMemo(() => {
    let pathArray = pathname.split(path).filter(x => x && x !== 'cms');
    if (path !== '/' && pathArray.length) {
      return pathArray[0].split('/');
    }
    return pathArray;
  }, [pathname, path]);

  React.useEffect(() => {
    setOpenKeys(mainPathSplit);
  }, [mainPathSplit]);
  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) {
      setOpenKeys([]);
    }
    if (window.innerWidth < 700) {
      onCloseSider();
    }
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={'inline'}
      theme={'light'}
      selectedKeys={mainPathSplit}
      defaultOpenKeys={[`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`]}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {AppMenu.map((menuGroup, indexGroup) => {
        const menuItems = menuGroup.items.filter(x => hasRoles(x.roles, auth));
        if (menuItems.length === 0) {
          return null;
        }
        return (
          <React.Fragment key={indexGroup}>
            <p className="sidebar-nav-title" style={{ marginTop: indexGroup === 0 ? 20 : 40 }}>
              {menuGroup.groupName ? t(menuGroup.groupName) : ''}
            </p>
            {menuItems.map(menu => {
              return renderMenuItem(menu, t, auth);
            })}
          </React.Fragment>
        );
      })}
    </Menu>
  );
};

export default MenuItems;
