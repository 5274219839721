import MainContent from '@/Layout/MainContent';
import { Table, Tooltip, Typography } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import dayjs from 'dayjs';
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import SearchForm from './components/SearchForm';
import lodash from 'lodash';
import { unHttpClient } from '@/httpclient';
import {
  AvailableTour,
  DayGuideAvailability,
  GuideListingAvailabilityResponse,
  GuideListingAvailabilityResponsePagingResponse,
  HolidayModel,
} from '@/__generated';
import { GuideSupportedArea, TourAvailableAreaType, TourGuideStatusType, TourStatusType } from '@/config';
import { DEFAULT_DATE_FORMAT, MONTH_DAY_FORMAT } from '@/constants';
import DateItem from './components/DateItem';
import GuideInfoModal from '../GuideInfo/GuideInfoModal';
import GuideNameCell from '@/pages/GuidesSchedule/components/GuideNameCell';

const PAGE_TITLE = 'ガイド一覧';
const { Text } = Typography;

type Props = {};
const PERIOD_DISPLAY = 30;
const PAGE_SIZE = 20;

type GuideListingFilter = {
  startDate: string;
  guideIds?: string[];
  prefectures?: string[];
  languages?: string[];
  hasLicense?: boolean;
  ranks?: number[];
  maximumNightAllowed?: number;
  supportedTypes?: number[];
  supportedAreas?: number[];
  pageIndex: number;
  pageSize: number;
  orderBy?: string;
  orderByAsc?: boolean;
};

const getGuideFullName = (guide: GuideListingAvailabilityResponse | undefined) => {
  return [guide?.lastNameKanji, guide?.firstNameKanji].filter(x => x).join(' ');
};
interface IGuideDateDetail {
  [id: string]: { [date: string]: DayGuideAvailability } | null | undefined;
}

export default function Guides({}: Props) {
  const [t] = useTranslation();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [holidayLoading, setHolidayLoading] = React.useState<boolean>(false);

  const [guideInfoId, setGuideInfoId] = React.useState<string>();

  const [originHolidays, setOriginHolidays] = React.useState<HolidayModel[]>([]);
  const [guideListing, setGuideListing] = React.useState<GuideListingAvailabilityResponse[]>([]);
  const [guideDateDetail, setGuideDateDetail] = React.useState<IGuideDateDetail>({});

  const [total, setTotal] = React.useState<number>(0);
  const [filter, setFilter] = React.useState<GuideListingFilter>({
    startDate: dayjs().format(DEFAULT_DATE_FORMAT),
    pageIndex: 1,
    pageSize: PAGE_SIZE,
    orderBy: 'lastNameKanji',
    orderByAsc: true,
  });

  const year = React.useMemo(() => {
    return dayjs(filter.startDate).year();
  }, [filter.startDate]);
  React.useEffect(() => {
    setHolidayLoading(true);
    unHttpClient
      .get<HolidayModel[]>(`/get-all-holidays/${year}`)
      .then(({ data: res }) => {
        setOriginHolidays(res);
      })
      .finally(() => {
        setTimeout(() => {
          setHolidayLoading(false);
        });
      });
  }, [year]);

  const holidays = React.useMemo(() => {
    const data: { [key: string]: HolidayModel } = {};
    originHolidays.forEach(holiday => {
      data[dayjs(holiday.date).format(DEFAULT_DATE_FORMAT)] = holiday;
    });
    return data;
  }, [originHolidays]);
  const { columns, W } = React.useMemo(() => {
    if (holidayLoading || loading) {
      return {
        columns: [],
        W: 0,
      } as any;
    }
    const _columns: ColumnsType<GuideListingAvailabilityResponse> = [
      {
        title: 'No.',
        width: 45,
        fixed: 'left',
        align: 'center',
        render: (v, r, i) => {
          return <b>{i + 1}</b>;
        },
      },
      {
        title: t('Guide Name'),
        width: 280,
        fixed: 'left',
        dataIndex: 'lastNameKanji',
        render: (v, guide) => {
          const name = getGuideFullName(guide);
          return (
            <GuideNameCell
              gender={guide.gender}
              id={guide.id}
              maxNumberOfNight={guide.maxNumberOfNight}
              name={name}
              dateOfBirth={guide.dateOfBirth}
              onGuideNameClick={id => {
                setGuideInfoId(id);
              }}
              rank={guide.rank}
            />
          );
        },
      },
      {
        title: t('Language'),
        ellipsis: true,
        width: 120,
        fixed: 'left',
        render: (v, guide) => {
          const languages = lodash.orderBy(guide.supportedLanguages || [], (x, i) => (x.isMain ? -1 : i));
          const text = languages
            ?.map(lang => {
              const name = `${lang.isMain ? '*' : ''}${lang.name}`;
              if (lang.hasLicense) {
                return `<div >${name}</div>`;
              } else {
                return `<div style="color:red">${name}</div>`;
              }
            })
            .join('');
          return (
            <Text style={{ width: 100 }} ellipsis={{ tooltip: <span dangerouslySetInnerHTML={{ __html: text || '' }} /> }}>
              {languages.map((lang, i) => {
                let name = `${lang.isMain ? '*' : ''}${lang.name}`;
                if (i !== (languages.length || 0) - 1) {
                  name += ', ';
                }
                return (
                  <Text key={i} style={{ color: !lang.hasLicense ? 'red' : '' }}>
                    {name}
                  </Text>
                );
              })}
            </Text>
          );
        },
      },
      {
        title: t('Prefectures'),
        width: 100,
        fixed: 'left',
        dataIndex: 'prefecture',
        ellipsis: true,
        render: v => {
          return t(v);
        },
      },
      {
        title: t('Area'),
        width: 100,
        fixed: 'left',
        render: (v, guide) => {
          if (guide.supportedAreas?.length) {
            const areaText = guide.supportedAreas?.map(area => t(GuideSupportedArea.__getValue(area)));
            return (
              <Text style={{ width: 100 }} ellipsis={{ tooltip: <span dangerouslySetInnerHTML={{ __html: areaText.join('<br/>') }} /> }}>
                {areaText.join(', ')}
              </Text>
            );
          }
          return '';
        },
      },
    ];
    for (let i = 0; i < PERIOD_DISPLAY; i++) {
      const colDate = dayjs(filter.startDate).add(i, 'day');
      _columns.push({
        title: colDate.format(MONTH_DAY_FORMAT),
        width: 55,
        dataIndex: `day${i}`,
        align: 'center',
        shouldCellUpdate: () => false,
        render: (v, guide) => {
          let dateInfo: DayGuideAvailability | null = null;
          const guideDetail = guideDateDetail[guide?.id || ''];
          const dateFormatted = colDate.format(DEFAULT_DATE_FORMAT);
          if (guideDetail) {
            dateInfo = guideDetail[dateFormatted];
          }
          const className: string[] = [''];
          const holiday = holidays[dateFormatted];
          if (holiday) {
            className.push('holiday');
          } else if (colDate.day() === 6) {
            className.push('saturday');
          } else if (colDate.day() === 0) {
            className.push('sunday');
          }
          let styles: React.CSSProperties = {};
          const availableTours: AvailableTour[] | undefined = guide.availableTours?.filter(tour =>
            tour.tourGuides?.some(tourGuide => {
              return tourGuide.businessDays?.some(day => {
                return dayjs(day).format(DEFAULT_DATE_FORMAT) === dateFormatted;
              });
            }),
          );

          if (availableTours?.length) {
            let firstTour = availableTours[0];
            if (firstTour.tourStatus !== TourStatusType.Cancelled && firstTour.tourGuides?.some(x => x.status === TourGuideStatusType.Unassigned)) {
              styles = { backgroundColor: TourAvailableAreaType.__getTourUnCancelledAndGuideUnassignedColor };
            } else {
              styles = { backgroundColor: TourAvailableAreaType.__getColor(firstTour.area) };
            }
          } else if (dateInfo?.isUnavailable || dateInfo?.isWfAnotherDepartment || dateInfo?.stayLastNight || dateInfo?.stayOvernight) {
            className.push('gray-out');
          }
          return {
            children: availableTours?.length ? (
              <Tooltip
                trigger={'hover'}
                title={
                  <ul>
                    {availableTours.map(tour => {
                      return (
                        <li key={tour.id} style={{ color: 'white' }}>
                          {tour.name}
                        </li>
                      );
                    })}
                  </ul>
                }
              >
                <div data-gid={guide.id} data-date={dateFormatted} style={{ minWidth: 30, minHeight: 20 }}></div>
              </Tooltip>
            ) : (
              <DateItem date={dateInfo} availableTours={availableTours} />
            ),

            props: {
              'data-gid': guide.id,
              'data-date': dateFormatted,
              className: className.join(' '),
              style: styles,
            },
          };
        },
      });
    }
    return {
      columns: _columns,
      W: lodash.sumBy(_columns, t => t.width as any),
    };
  }, [filter.startDate, guideDateDetail, holidays, loading, holidayLoading]);

  const getData = () => {
    setLoading(true);
    unHttpClient
      .get<GuideListingAvailabilityResponsePagingResponse>('/get-guide-listing-availabilities', { params: filter })
      .then(({ data: res }) => {
        const dateDetail: IGuideDateDetail = {};
        res.data?.forEach(guide => {
          const daysDetail = {};
          guide.days?.forEach(day => {
            daysDetail[dayjs(day.date).format(DEFAULT_DATE_FORMAT)] = day;
          });
          dateDetail[guide?.id || ''] = daysDetail;
        });
        setGuideListing(res.data || []);
        setGuideDateDetail(dateDetail);
        setTotal(res.total || 0);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 100);
      });
  };

  React.useEffect(() => {
    getData();
  }, [filter]);

  const onFilterChange = (v?: any) => {
    setFilter({
      ...v,
      startDate: dayjs(v?.startDate || dayjs()).format(DEFAULT_DATE_FORMAT),
      guideIds: v?.guideIds?.map(e => e.value),
      languages: v?.languages?.map(e => e.value),
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    });
  };
  const onOrderChange = (_: any, __: any, sorter: any) => {
    setFilter(f => ({ ...f, orderBy: sorter.field?.toString() || 'lastNameKanji', orderByAsc: sorter.order !== 'descend' }));
  };

  const pagination = React.useMemo(() => {
    return {
      pageSize: PAGE_SIZE,
      current: filter.pageIndex,
      total: total,
      onChange: p => setFilter(f => ({ ...f, pageIndex: p })),
      hideOnSinglePage: true,
      showSizeChanger: false,
    } as TablePaginationConfig;
  }, [filter, total]);

  const table = React.useMemo(() => {
    return (
      <Table
        loading={loading || holidayLoading}
        dataSource={guideListing}
        scroll={{ x: W }}
        pagination={pagination}
        columns={columns}
        rowKey="id"
        size="small"
        bordered
        id="guide-table"
        onChange={onOrderChange}
      />
    );
  }, [columns, W, loading, holidayLoading, guideListing, pagination]);

  return (
    <MainContent title={t(PAGE_TITLE)}>
      <Helmet title={t(PAGE_TITLE)} />

      <div className="ant-layout-content-body nta-guide-listing">
        <SearchForm startDate={filter.startDate} onSearch={onFilterChange} />
        {table}
      </div>
      {guideInfoId && <GuideInfoModal visible={!!guideInfoId} gid={guideInfoId} onClose={() => setGuideInfoId(undefined)} />}
    </MainContent>
  );
}
