import { formatDisplayDate, TourGuideStatusType } from '@/config';
import { Form, Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getBusinessDateDuration } from '../validation';

export default function GuideListLabel() {
  const { t } = useTranslation();
  return (
    <div style={{ marginTop: 10 }}>
      <Form.Item
        noStyle
        shouldUpdate={(prev, current) => {
          return prev.tourGuides !== current.tourGuides;
        }}
      >
        {({ getFieldValue }) => {
          const tourGuides: any[] = getFieldValue('tourGuides') || [];

          return (
            <Table
              scroll={{ x: 700 }}
              bordered
              size="small"
              pagination={false}
              columns={[
                {
                  title: t('Guide Name'),
                  dataIndex: ['guide', 'label'],
                },
                {
                  title: t('Language'),
                  dataIndex: ['language', 'label'],
                },
                {
                  title: t('Status'),
                  dataIndex: 'status',
                  render: v => t(TourGuideStatusType.__getValue(v)),
                },
                {
                  title: t('Duration'),
                  dataIndex: ['businessDays'],
                  render: businessDays => {
                    let duration = getBusinessDateDuration(businessDays);
                    const fromDate = formatDisplayDate(duration.fromDate?.date);
                    const toDate = formatDisplayDate(duration.toDate?.date);
                    return `${fromDate || ''} ~ ${toDate || ''}`;
                  },
                },
              ]}
              dataSource={tourGuides}
            ></Table>
          );
        }}
      </Form.Item>
    </div>
  );
}
