import { Modal, Button, Result, Tabs, FormInstance, message } from 'antd';
import React from 'react';
import '../style.less';
import { ApiSdk } from '@/httpclient';
import { TourDetailModel } from '@/__generated';
import PageLoading from '@/components/PageLoading';
import TourHeader from '../_readonlyComponents/TourHeader';
import { useTranslation } from 'react-i18next';
import { LockOutlined, SaveOutlined } from '@ant-design/icons';
import { useAppContext } from '@/contexts/AppContext';
import tourService from '@/services/tourService';
import GuideTab from './GuideTab';
import { ShortTourGuideInfo } from '../../TourFeedback/TourEvaluation';
import { guideHasBall } from '@/services/roleService';
import { TourProvider } from '@/contexts/TourContext';
import { scrollableModalBodyStyle } from '@/config';
type Props = {
  onClose: (reload?: boolean) => void;
  visible: boolean;
  tourId?: string;
  showGuideEvaluation: (shortGuideInfo: ShortTourGuideInfo) => void;
};
const keepAliveTimeout = 5 * 60 * 1000; //5 minutes
let keepAliveInterval: any = null;

export default function TourGuideEditModal({ visible, onClose, tourId, showGuideEvaluation }: Props) {
  const { t } = useTranslation();

  const [tour, setTour] = React.useState<TourDetailModel>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [sessionId, setSessionId] = React.useState<string>();

  const { auth } = useAppContext();

  const [formList, setFormList] = React.useState<FormInstance[]>([]);

  React.useEffect(() => {
    if (visible && tourId) {
      setSessionId(tourService.createSessionId());
    }
  }, [visible, tourId]);

  React.useEffect(() => {
    if (tourId && auth?.roleClaims.length && sessionId) {
      setLoading(true);
      ApiSdk.TourService.getTourForUpdate({ id: tourId, sessionId: sessionId, currentRole: auth?.roleClaims[0] })
        .then(tour => {
          setTour(tour);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [tourId, auth, sessionId]);

  const onFormChange = (form: FormInstance) => {
    setFormList(f => [...f, form]);
  };
  const onSave = () => {
    const guidesData = formList.map(f => f.getFieldsValue(true));
    if (sessionId) {
      ApiSdk.TourService.guideUpdateTour({
        body: {
          id: tour?.id || '',
          tourGuides: guidesData,
          sessionId: sessionId,
        },
      }).then(res => {
        if (res.success) {
          message.success(t('UPDATE_TOUR_SUCCESS'));
          onClose();
        } else {
          message.error(t(res.message || ''));
        }
      });
    }
  };

  React.useEffect(() => {
    if (sessionId && tour && auth?.roleClaims.length) {
      const isLockedByMe = tour?.tourGuides?.some(x => {
        return (x.lockInfo?.guideInfoLocked === false && guideHasBall(x.status as any)) || x.lockInfo?.briefingLocked === false;
      });

      if (isLockedByMe) {
        keepAliveInterval = window.setInterval(() => {
          tourService.keepAlive(tour.id || '', auth?.roleClaims[0]);
        }, keepAliveTimeout);
        const onUnlock = () => {
          tourService.unlockTour(tour.id || '', auth?.roleClaims[0]);
        };
        const unlockOnCloseTab = () => {
          tourService.unlockOnTabClose(tour.id || '', auth?.roleClaims[0], sessionId);
        };
        const onTabActiveCallback = () => {
          if (!document.hidden) {
            tourService.checkLockSessionAlive(tour.id || '', auth?.roleClaims[0], sessionId);
          }
        };
        const onTabReActive = tourService.onTabReActive(onTabActiveCallback);
        const onBrowserTabClose = tourService.onBrowserTabClose(unlockOnCloseTab);
        return () => {
          onUnlock();
          onTabReActive();
          onBrowserTabClose();
          if (keepAliveInterval) {
            window.clearInterval(keepAliveInterval);
          }
        };
      }
    }
    return () => {};
  }, [tour, sessionId, auth]);

  return (
    <Modal
      className="nta-custom-form create-tour-form tour-info-modal"
      bodyStyle={scrollableModalBodyStyle}
      visible={visible}
      onCancel={() => onClose()}
      style={{ top: 10, maxWidth: 1200 }}
      width={'100%'}
      title={tour?.tourName}
      okButtonProps={{ loading, icon: <SaveOutlined />, className: 'save-btn', disabled: !tour }}
      cancelButtonProps={{ style: { float: 'left' }, loading }}
      okText={t('Save')}
      cancelText={t('Close')}
      onOk={onSave}
      maskClosable={false}
    >
      <TourProvider>
        {loading ? (
          <div style={{ marginTop: 200 }}>
            <PageLoading />
          </div>
        ) : tour ? (
          <div>
            <TourHeader tour={tour} />
            <div className="guide-info-tab-container">
              <Tabs type="card" defaultActiveKey="guide-0">
                {tour.tourGuides?.map((guide, i) => {
                  const { guideInfoLocked } = guide.lockInfo || {};
                  return (
                    <Tabs.TabPane
                      forceRender
                      key={`guide-${i}`}
                      tab={
                        <span style={{ textShadow: 'none', fontWeight: 'bold' }}>
                          {guide.guideName || 'N/A'}
                          {guideInfoLocked ? <LockOutlined style={{ marginLeft: 5 }} className="tour-lock-icon" /> : null}
                        </span>
                      }
                    >
                      <GuideTab
                        sessionId={sessionId}
                        guide={guide}
                        onFormChange={onFormChange}
                        onClose={onClose}
                        showGuideEvaluation={showGuideEvaluation}
                        tour={tour}
                      />
                    </Tabs.TabPane>
                  );
                })}
              </Tabs>
            </div>
          </div>
        ) : (
          <Result
            status="404"
            title="404"
            subTitle={t('TourNotExists')}
            extra={
              <Button onClick={() => onClose(true)} type="primary">
                {t('Close')}
              </Button>
            }
          />
        )}
      </TourProvider>
    </Modal>
  );
}
