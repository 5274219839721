import { Tag } from 'antd';
import React from 'react';

type Props = {
  value?: boolean;
};

export default function BooleanTag({ value }: Props) {
  return (
    <Tag color={value ? '#096609' : '#ae0b0b'}>
      <b style={{ color: 'white' }}>{value ? 'Yes' : 'No'}</b>
    </Tag>
  );
}
