/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Modal, Row, Space, Table, Tooltip, Form, Select } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';
import ZFilter, { ITableFilterItem } from '../ZFilter';
import useTQuery from './useTQuery';
import useTDelete from './useTDelete';
import { getColConfig } from './_utils';
import { FormLayout } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { GetComponentProps } from 'rc-table/lib/interface';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTION } from '@/constants';

export interface ITableRef {
  reload: () => void;
}

export interface IColumnType<T> {
  title: string;
  dataIndex?: String | String[];
  sorter?: boolean;
  width?: number;
  ellipsis?: boolean;
  align?: 'left' | 'right' | 'center';
  colSpan?: number;
  render?: (value: any, record: T, index: number) => React.ReactElement[] | React.ReactElement | string;
  fixed?: 'left' | 'right';
  defaultSortOrder?: 'ascend' | 'descend';
}
export interface IFilterItem extends ITableFilterItem {}

interface IProps<T = any> {
  columns: any[];
  primaryKey: string;
  url: string;
  defaultVariables?: {};
  defaultFilter?: {};
  extendQuery?: {};
  delete_url?: string;
  pageSize?: number;
  allowEdit?: boolean;
  hideEditIcon?: boolean;
  hideCopyIcon?: boolean;
  editText?: string;
  onEdit?(record: T): void;
  allowDelete?: boolean;
  showDelete?(record: T): boolean;
  showEdit?(record: T): boolean;
  onDelete?(record: T): void;
  deleteMessage?: string;
  transformDataSource?(data: any): {};
  filters?: Array<ITableFilterItem>;
  order_by?: string;
  order_by_asc?: boolean;
  bordered?: boolean;
  extraActions?: { render(record: T): any }[];
  dataItems?: [];
  displayColumns?: number[];
  disableColumnsConfig?: boolean;
  columnsConfigKey?: string;
  actionColumnWidth?: number;
  onFilterChange?: (filters: any) => void;
  extraBtns?: any;
  onDataSource?: (data: any) => void;
  size: SizeType;
  scroll?: { x: number };
  layoutFilter?: FormLayout;
  rowDisableValue?: any;
  rowDisableName?: string;
  hideOnSinglePage?: boolean;
  pageSizeOption?: string[];
  hideResetButton?: boolean;
  showInfoTop?: boolean;
  onRow?: GetComponentProps<T>;
  hidePagingOnTop?: boolean;
  searchForm?: React.ComponentType<{ onSearch: (values: any) => void }>;
}
function ZTable<T = any>(props: IProps<T> & { tableRef?: React.Ref<ITableRef | undefined> }) {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  const actions = React.useMemo(() => {
    const actions: any[] = [];
    if (props.extraActions) {
      props.extraActions.map(action => actions.push(action));
    }
    if (props.allowEdit) {
      actions.push({
        render: (record: any) => {
          if (props.showEdit) {
            if (props.showEdit(record)) {
              return (
                <Tooltip title={props.editText || t('zTable.edit')}>
                  <Button
                    style={{ backgroundColor: '#d6eaf8', borderRadius: 0 }}
                    type="ghost"
                    size="small"
                    icon={!props.hideEditIcon ? <EditOutlined /> : undefined}
                    onClick={() => {
                      if (props.onEdit) {
                        props.onEdit(record);
                      }
                    }}
                  ></Button>
                </Tooltip>
              );
            }
            return null;
          } else {
            return (
              <Tooltip title={props.editText || t('zTable.edit')}>
                <Button
                  style={{ backgroundColor: '#d6eaf8', borderRadius: 0 }}
                  type="ghost"
                  size="small"
                  icon={!props.hideEditIcon ? <EditOutlined /> : undefined}
                  onClick={() => {
                    if (props.onEdit) {
                      props.onEdit(record);
                    }
                  }}
                ></Button>
              </Tooltip>
            );
          }
        },
      });
    }

    if (props.allowDelete) {
      const handleDelete = (record: any) => {
        Modal.confirm({
          okText: t('Delete'),
          cancelText: t('Cancel'),
          title: props.deleteMessage ? props.deleteMessage : t('Are you sure want to delete this item?'),
          icon: <ExclamationCircleOutlined />,
          onOk: () => {
            if (props.onDelete) {
              props.onDelete(record);
            } else {
              useTDelete(`${props.delete_url}/${record.id}`, () => {
                setQuery(q => ({ ...q }));
              });
            }
          },
        });
      };

      actions.push({
        render: (record: any) => {
          if (props.showDelete) {
            if (props.showDelete(record)) {
              return (
                <Tooltip title={t('zTable.delete')}>
                  <Button
                    icon={<DeleteOutlined />}
                    style={{ backgroundColor: '#f97d81', borderRadius: 0 }}
                    size="small"
                    type="ghost"
                    onClick={() => handleDelete(record)}
                  ></Button>
                </Tooltip>
              );
            }
            return null;
          } else {
            return (
              <Tooltip title={t('zTable.delete')}>
                <Button
                  icon={<DeleteOutlined />}
                  size="small"
                  style={{ backgroundColor: '#f97d81', borderRadius: 0 }}
                  type="ghost"
                  onClick={() => handleDelete(record)}
                ></Button>
              </Tooltip>
            );
          }
        },
      });
    }
    return actions;
  }, [i18n.language]);

  const columns = React.useMemo(() => {
    let cols = [
      ...props.columns.map(column => {
        column.title = t(column.title);
        return column;
      }),
    ];
    if (actions.length > 0) {
      cols.push({
        width: props.actionColumnWidth || 120,
        title: t('zTable.actions'),
        dataIndex: '_actions',
        align: 'center',
        fixed: window.innerWidth > 1000 ? 'right' : undefined,
        render(_: any, record: any) {
          return (
            <Space>
              {actions.map((action, index) => (
                <span key={index.toString()}>{action.render(record)}</span>
              ))}
            </Space>
          );
        },
      });
    }
    return cols;
  }, [props.columns, i18n.language]);

  const defaultWhere = {};
  props.filters?.forEach(f => {
    if (f.defaultValue) {
      defaultWhere[f.name] = f.defaultValue;
    }
  });

  const [query, setQuery] = useState({
    ...props.defaultFilter,
    ...defaultWhere,
    orderBy: props.order_by,
    orderByAsc: props.order_by_asc,
    pageSize: props.pageSize || DEFAULT_PAGE_SIZE,
    pageIndex: 1,
  });

  let [getData, { data, loading }] = useTQuery(props.url);

  const { dataSource, totalItems } = useMemo(() => {
    let dataSource = props.transformDataSource ? props.transformDataSource(data) : data;
    if (!dataSource || !dataSource['data'] || dataSource['data'].length === 0) return { dataSource: [], totalItems: 0 };
    if (props.onDataSource) {
      props.onDataSource(dataSource);
    }
    return { dataSource: dataSource.data, totalItems: dataSource.total };
  }, [data]);

  useEffect(() => {
    const variables = { ...query };
    if (props.onFilterChange) {
      props.onFilterChange(variables);
    }
    getData(variables);
  }, [query]);

  const onSearch = (values: any) => {
    setQuery(q => ({
      ...props.defaultFilter,
      ...values,
      pageIndex: 1,
      pageSize: values.pageSize || q.pageSize,
      orderBy: q.orderBy,
      orderByAsc: q.orderByAsc,
    }));
  };

  React.useImperativeHandle(
    props.tableRef,
    () => {
      return {
        reload: () => {
          getData(query);
        },
      };
    },
    [query],
  );

  const onTableChange = React.useCallback(
    (pagination, _, sorter) => {
      let { field, order } = sorter;
      let p = pagination?.current || 1;
      let order_by_asc = order ? (order === 'ascend' ? true : false) : props.order_by_asc;
      setQuery({
        ...query,
        orderBy: order ? field : props.order_by,
        orderByAsc: order_by_asc,
        pageIndex: p,
        pageSize: pagination.pageSize,
      });
    },
    [query, props.order_by],
  );

  const displayColumns = React.useMemo(() => {
    return getColConfig(pathname, props.columnsConfigKey) || props.displayColumns;
  }, [pathname, props.columnsConfigKey, props.displayColumns, i18n.language]);

  const _columns = React.useMemo(() => {
    return displayColumns ? displayColumns.map(i => columns[i]) : columns;
  }, [displayColumns, columns, i18n.language]);

  const SearchForm = props.searchForm;
  return (
    <div className="zsport-main-table">
      {props.filters && props.filters.length > 0 && (
        <ZFilter
          extraBtns={props.extraBtns}
          hideResetButton={props.hideResetButton}
          onSearch={onSearch}
          filters={props.filters.map(filter => {
            filter.label = t(filter.label);
            return filter;
          })}
          layout={props.layoutFilter ?? 'vertical'}
        />
      )}
      {SearchForm ? <SearchForm onSearch={onSearch} /> : null}
      {!props.hidePagingOnTop ? (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
          <div>{`${t('zTable.records')} ${totalItems}`}</div>
          <div style={{ marginLeft: 20 }}>
            {t('zTable.pageSize')}
            <Select
              value={query.pageSize}
              style={{ width: 90 }}
              onChange={v => {
                setQuery(q => ({ ...q, pageSize: parseInt((v as any) || props.pageSize || DEFAULT_PAGE_SIZE) }));
              }}
            >
              {PAGE_SIZE_OPTION.map(page => (
                <Select.Option key={page} value={page}>
                  {page}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      ) : null}

      <Table
        size={props.size}
        columns={_columns}
        dataSource={props.dataItems ? props.dataItems : dataSource}
        rowKey={props.primaryKey}
        showSorterTooltip={false}
        loading={loading}
        pagination={{
          hideOnSinglePage: false,
          current: query.pageIndex || 1,
          pageSize: query.pageSize,
          total: totalItems,
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZE_OPTION,
          size: 'default',
        }}
        onChange={onTableChange}
        bordered={props.bordered}
        scroll={props.scroll}
        rowClassName={record => (props.rowDisableName && record[props.rowDisableName] === props.rowDisableValue ? 'disabled-row' : '')}
      />
    </div>
  );
}

ZTable.defaultProps = {
  order_by: 'createdAt',
  order_by_asc: true,
  size: 'small',
};
export default ZTable;
