import tour from './tour';
import guide from './guide';
import message from './message';
import staff from './staff';
import notifications from './notifications';
import guideSearch from './guideSearch';
import tourEvaluation from './tourEvaluation';

export default {
  ...tour,
  ...guide,
  ...message,
  ...staff,
  ...notifications,
  ...guideSearch,
  ...tourEvaluation,
  // menu

  'menu.MainMenu': 'メインメニュー',
  'menu.SystemSettings': 'システム設定',
  'menu.Dashboard': 'ダッシュボード',
  'menu.Tours': 'ツアー一覧',
  'menu.Calendars': 'カレンダー',
  'menu.Billings': 'ビリングス',
  'menu.Notifications': 'お知らせ',
  'menu.Users': 'ユーザー',
  'menu.ApiAccessLogs': 'Apiアクセスログ',
  'menu.MasterData': 'マスターデータ',
  'menu.EmailTemplates': 'メールテンプレート',
  'menu.SMTPSettings': 'SMTP設定',
  'menu.Guides': 'スケジュール管理',
  'menu.Staffs': 'スタッフ',
  'menu.StaffSearch': 'スタッフ検索',
  'menu.StaffAdd': 'スタッフ作成',
  'menu.GuideSearch': 'ガイド検索',
  'menu.Countries': '国',
  'menu.Languages': '言語',
  'menu.Department': '部署',
  'menu.Subdivision': '課',
  'menu.Holidays': '休日',
  'menu.Facilities': '施設',
  'menu.TourFeedbacks': 'フィードバック',
  'menu.EmailMessages': 'メール配信',
  'menu.TourMessages': 'メッセージ一覧',
  'menu.GuideSeisans': 'ガイド精算一覧',
  //base table
  'zTable.edit': '編集',
  'zTable.copy': 'コピー',
  'zTable.delete': '削除',
  'zTable.actions': '操作',
  'zTable.search': '検索',
  'zTable.reset': 'クリア',
  'zTable.records': '検索件数：',
  'zTable.pageSize': '表示件数 ',
  // enum
  Yes: 'はい',
  No: 'いいえ',
  EnumBlank: 'ブランク',
  Education: '教育',
  Sightseeing: '観光',
  Medical: '医療',
  Inspection: '視察',
  Mice: 'MICE',
  Honeymoon: 'ハネムーン',
  Family: 'ファミリー',
  Friends: '友人',
  Prefectures: '都道府県',
  Rank: '種別',
  Area: 'エリア',
  // Guide Status
  Unassigned: '未依頼',
  Assigning: '依頼中',
  Checking: '確認中',
  Assigned: '確定',
  Informing3MonthsPrior: '3ケ月前連絡中',
  Informing2MonthPrior: '2ケ月前連絡中',
  Checked: '確認済',
  FinalInforming: '最終連絡中',
  FinalChecking: '最終確認中',
  Finalized: '最終確認済',
  AwaitingCancellation: '取消依頼中',
  Cancelled: '取消済',
  Denied: '対応不可',
  CheckingSeisan: '精算確認中',
  SeisanFinished: '精算完了',
  // tour status
  Confirming: '催行確認中',
  ConfirmedAcceptingGuest: '催行確定(集客中)',
  Confirmed: '催行確定(FNL)',
  ConfirmingBilling: '精算確認中',
  BillingComplete: '精算完了',
  Save: '保存',
  Close: 'キャンセル',
  'Update tour': 'アップデートツアー',
  gender_Female: '女',
  gender_Male: '男',
  // roles
  Admin: 'Admin',
  SupervisorUser: 'スーパーバイザーユーザー',
  RegularUser: '一般ユーザー',
  ReferenceUser: 'リファレンスユーザー',
  Guide: 'ガイド',
  Assign: 'アサイン',
  GuideSupport: 'Gサポート',
  Role: '役割',
  Email: 'メール',
  UserName: 'フルネーム',
  UserNameOrEmail: 'メールアドレス/アカウントID',
  User: 'ユーザー',
  EmailConfirmed: 'メール確認済み',
  LockoutEnabled: 'ロックアウトが有効',
  RoleUpdateTile: '役割変更',
  RegisterUser: ' 新しいユーザーを作る',
  UpdateUser: 'ユーザー変更',
  CreateNewRole: '新しい役割を作る',
  UpdateNewRole: '役割変更',
  Password: 'パスワード',
  ConfirmPassword: '新しいパスワード　再入力',
  NameRole: '名前',
  PhoneNumber: '電話番号',
  //base
  Cancel: 'キャンセル',
  Language: '言語',
  'Please select guide': 'ガイドを選択してください',
  'Only display licensed guides': 'ライセンス有のみ表示',
  'Guide Status': 'ガイドSTS',
  'Tour Status': '状態',
  'Tour Id': 'ツアーID',
  'Tour Name': 'ツアー名',
  'Tour Number': 'ツアーNo.',
  'Guide Name': 'ガイド名',
  'Display tours from UN': 'UN連携ツアーの表示',
  'Display tours from GL': 'GL作成ツアーの表示',
  'No.': 'No.',
  'Tour duration': 'ツアー期間',
  'Tour duration(From)': 'ツアー期間(From)',
  'Tour duration(To)': 'ツアー期間(To)',
  Nationality: '国籍',
  ValidateCountry3Code: '3文字入力してください',
  ValidateCountry2Code: '2文字入力してください',
  'Number of pax': '人数',
  Sales: '営業担当',
  'Tour Manager': 'ツアー担当',
  Status: 'ステータス',
  Agent: 'AGT名',
  'Number of Passenger': '集客人数',
  'Number of Passengers (Estimated)': '設定人数',
  Adult: '大人',
  'Child A': '子供A',
  'Child B': '子供B',
  Infant: '添寝',
  'T/L': 'T/L',
  Total: '合計',
  Duration: '期間',
  Confirm: '確認',
  Register: '登録',
  Delete: '削除',
  'Select date': '日付を選択',
  'Manage Tours': 'ツアー管理',
  'Create Tour': 'ツアー作成',
  'Guide Name - Language - Guide Status': 'ガイド名 - 言語 - ガイドステータス',
  Attachment: '添付ファイル',
  'Upload Attachment': 'アップロード',
  'Display Month': '表示月',
  'Current month': '当月',
  'Works in another department': '他部署業務',
  Unavailable: '業務不可',
  Available: '利用可能',
  'Current Role': '現在の役割',
  Profile: 'プロフィール',
  'Change layout': 'レイアウト変更',
  'Change password': 'パスワードの変更',
  'Select Guide': 'セレクトガイド',
  Calendars: 'カレンダー',

  Before: '掲載前',
  During: '掲載中',
  EndOfPublication: '掲載終了',
  Copy: 'コピー',
  Mon: '月',
  Tue: '火',
  Wed: '水',
  Thu: '木',
  Fri: '金',
  Sat: '土',
  Sun: '日',
  page: 'ページ',

  // logs
  SessionId: 'セッションID',
  StartTime: '開始時間',
  Method: 'メソード',
  Endpoint: '終点',
  DurationSecond: '期間（ミリ秒）',
  // countries
  CreateCountry: '新しい国を作る',
  UpdateCountry: '国変更',
  PhoneCode: '国番号',
  Continent: 'エリア',
  Name: '名',
  Code: 'コード',
  ShortCode: 'ショートコード',
  // languages
  CreateLanguage: '言語登録',
  UpdateLanguage: '言語変更',
  // email template
  CreateEmailTemplate: 'メールテンプレート作成',
  UpdateEmailTemplate: 'メールテンプレート',
  NameEmailTemplate: '件名',
  ToEmail: 'メールへ（複数のメールを許可し、;で区切る）',
  CcEmail: 'Ccメール（複数のメールを許可し、;で区切る）',
  BccEmail: 'Bccメール（複数のメールを許可し、;で区切る）',
  Subject: 'タイトル',
  Body: '本文',
  NameEmailTemplatePlaceholder: 'メールテンプレート選択',
  // smtp setting
  NameSmtp: '氏名',
  EmailAddress: 'メールアドレス',
  Host: 'ホスト',
  Port: 'ポート',
  EnableSsl: '有効なSsl',
  UseDefaultCredentials: 'デフォルトのクレデンシャルを使用する',
  // layout header
  SeeAllNotification: 'すべての通知を見る',
  English: '英語',
  Japanese: '日本語',
  SignOut: 'サインアウト',
  PasswordHint: 'パスワードのヒント',
  MinimumEightCharacters: '最小8文字:',
  PasswordDescription: '少なくとも1つの大文字、1つの小文字、1つの数字、および1つの特殊文字',
  CurrentPassword: '現在のパスワード',
  NewPassword: '新しいパスワード',
  ChangePasswordSuccess: 'パスワードを正常に変更します。',
  // login
  TitleLogin: 'にサインイン',
  UserNameValidation: 'ユーザー名を入力してください。',
  PasswordValidation: 'パスワードを入力してください。',
  ForgotPassword: 'パスワードを忘れた場合',
  LoginButton: 'ログイン',
  // password expired
  TitlePasswordExpired: '新しいパスワードを変更ください。',
  // forgot password
  TitleForgotPassword: 'パスワードの再発行',
  LoginBackButton: 'ログイン画面に戻る',
  EmailValidator: 'メールアドレスを入力してください。',
  // reset password
  TitleResetPassword: 'パスワード変更',
  ResetPasswordSuccess: 'パスワードは正常にリセットされました',
  TokenInvalidOrExpired: '初期パスワードの有効時間を過ぎたため、下記リンクよりパスワードの再設定をお願いします。',
  // Calendar
  Calendar: 'カレンダー',
  // holidays
  Holiday: '休日',
  CreateHoliday: '新しい休日を作る',
  UpdateHoliday: '休日変更',
  BackToChangePassword: 'パスワード再設定へ',
  // auditlog
  'Audit Logs': '監査ログ',
  'auditlog.actor': '役者',
  'auditlog.entityName': 'エンティティ名',
  'auditlog.primaryKey': 'プライマリーキー',
  'auditlog.From': '日付時間',
  'auditlog.To': '日付時間',
  'auditlog.auditType': '監査タイプ',
  'auditlog.logTime': 'ログタイム',
  'auditlog.changeValues': '価値観の転換',
  tourAreaType_Europe: '欧州',
  tourAreaType_Americas: '米州',
  tourAreaType_Asia: 'アジア',
  tourAreaType_Australia: '豪州',
  tourAreaType_China: '中国',
  tourAreaType_Taiwan: '台湾',
  tourAreaType_Fit: 'FIT',
  tourAreaType_Other: 'Other',
  Year: '年',
  Date: '日付',
  // Continent
  Europe: '欧州',
  Africa: 'アフリカ',
  Asia: 'アジア',
  Oceania: 'オセアニア',
  'Middle East': '中東',
  'North America': '北米',
  'South America': '南米',
  LicenseType_National: '全国',
  LicenseType_Regional: '地域',
  LicenseType_None: '無し',

  // Prefectures
  Hokkaido: '北海道',
  Aomori: '青森県',
  Iwate: '岩手県',
  Miyagi: '宮城県',
  Akita: '秋田県',
  Yamagata: '山形県',
  Fukushima: '福島県',
  Ibaraki: '茨城県',
  Tochigi: '栃木県',
  Gunma: '群馬県',
  Saitama: '埼玉県',
  Chiba: '千葉県',
  Tokyo: '東京都',
  Kanagawa: '神奈川県',
  Niigata: '新潟県',
  Toyama: '富山県',
  Ishikawa: '石川県',
  Fukui: '福井県',
  Yamanashi: '山梨県',
  Nagano: '長野県',
  Gifu: '岐阜県',
  Shizuoka: '静岡県',
  Aichi: '愛知県',
  Mie: '三重県',
  Shiga: '滋賀県',
  Kyoto: '京都府',
  Osaka: '大阪府',
  Hyogo: '兵庫県',
  Nara: '奈良県',
  Wakayama: '和歌山県',
  Tottori: '鳥取県',
  Shimane: '島根県',
  Okayama: '岡山県',
  Hiroshima: '広島県',
  Yamaguchi: '山口県',
  Tokushima: '徳島県',
  Kagawa: '香川県',
  Ehime: '愛媛県',
  Kochi: '高知県',
  Fukuoka: '福岡県',
  Saga: '佐賀県',
  Nagasaki: '長崎県',
  Kumamoto: '熊本県',
  Oita: '大分県',
  Miyazaki: '宮崎県',
  Kagoshima: '鹿児島県',
  Okinawa: '沖縄県',
  Overseas: '海外',
  // enum
  ReservationForm_Group: '予約形態',
  'Reference Id': 'リファレンスID',
  'To email': '電子メールへ',
  'From email': 'メールから',
  Error: 'エラー',
  'Email content': 'メール内容',
  'Created On': '作成日',
  'Sent On': '送信',
  TourMessageStatusType_Sent: '送信済',
  TourMessageStatusType_OutGoing: '送信中',
  TourMessageStatusType_Failed: '失敗',
  tourMessage_EmailType: 'Email',
  tourMessage_EFaxType: 'eFAX',
};
