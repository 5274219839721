import { Button, Col, Form, Input, InputNumber, Modal, Row, Space, Spin, Table, Tag, message } from 'antd';
import React from 'react';
import { SaveOutlined, PlusOutlined, DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DEFAULT_DATE_FORMAT, ERROR_EXCEPTION, GuideSeisanStatus, GuideSeisanSubmittedType, VALIDATE_REQUIRED } from '@/constants';
import { ColumnsType } from 'antd/lib/table';
import BooleanTag from '@/components/app/BooleanTag';
import { SEISAN_REGEX, formatDisplayFullDate, scrollableModalBodyStyle } from '@/config';
import { ApiSdk } from '@/httpclient';
import { GuideSeisanDetailModel, GuideSeisanFileModel } from '@/__generated';
import guideSeisanService from '@/services/guideSeisanService';
import tourSeisanAction from './action';
import AccountingSeisanStatusInput from './components/AccountingSeisanStatusInput';
import NTADatePicker from '@/components/DatePicker';
import lockService from '@/services/lockService';
import TourLockCpn from '@/components/app/TourLockCpn';
import moment from 'moment';
import { sumBy } from 'lodash';
import SeisanFileSubmittedTag from '@/components/app/SeisanFileSubmittedTag';
import { formatCurrency } from '@/helpers/form';
type Props = {
  onClose: (reload?: boolean) => void;
  tourGuideId: string;
};
const requiredRule = {
  required: true,
  message: VALIDATE_REQUIRED,
};
const keepAliveTimeout = 5 * 60 * 1000; //5 minutes
let keepAliveInterval: any = null;

export default function AccountingUpdateSeisan({ onClose, tourGuideId }: Props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(true);
  const [guideSeisan, setGuideSeisan] = React.useState<GuideSeisanDetailModel>();
  const [guideSeisanFiles, setGuideSeisanFiles] = React.useState<GuideSeisanFileModel[]>([]);
  const fileInputRef = React.useRef<HTMLInputElement>();
  const [seisanStatus, setSeisanStatus] = React.useState<number>();
  const [sessionId, setSessionId] = React.useState<string>();
  const guideSeisanDetails = () => {
    if (sessionId && tourGuideId) {
      ApiSdk.GuideSeisanService.getGuideSeisanForUpdate({ tourGuideId: tourGuideId, sessionId })
        .then(res => {
          if (res) {
            setGuideSeisan(res);
            setSeisanStatus(res.status);
          } else {
            Modal.error({ title: t('tourDetail_TourSeisanNotExist') });
            onClose();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const getGuideSeisanFiles = () => {
    ApiSdk.GuideSeisanService.getGuideSeisanFiles({ tourGuideId: tourGuideId }).then(res => {
      setGuideSeisanFiles(res);
    });
  };
  React.useEffect(() => {
    if (sessionId && tourGuideId) {
      guideSeisanDetails();
      getGuideSeisanFiles();
    }
  }, [tourGuideId, sessionId]);

  React.useEffect(() => {
    form.setFieldsValue({ ...guideSeisan });
  }, [guideSeisan]);

  React.useEffect(() => {
    if (sessionId && guideSeisan && guideSeisan.isLocked === false) {
      keepAliveInterval = window.setInterval(() => {
        lockService.keepAlive(guideSeisan.id || '', sessionId);
      }, keepAliveTimeout);
      const onUnlock = () => {
        lockService.unlock(guideSeisan.id || '', sessionId);
      };
      const unlockOnCloseTab = () => {
        lockService.unlockOnTabClose(guideSeisan.id || '', sessionId);
      };
      const onTabActiveCallback = () => {
        if (!document.hidden) {
          lockService.checkLockSessionAlive(guideSeisan.id || '', sessionId);
        }
      };
      const onBrowserTabClose = lockService.onBrowserTabClose(unlockOnCloseTab);
      const onTabReActive = lockService.onTabReActive(onTabActiveCallback);
      return () => {
        onUnlock();
        onBrowserTabClose();
        onTabReActive();
        if (keepAliveInterval) {
          window.clearInterval(keepAliveInterval);
        }
      };
    }
    return () => {};
  }, [sessionId, guideSeisan]);

  React.useEffect(() => {
    if (tourGuideId) {
      setSessionId(lockService.createSessionId());
    } else {
    }
  }, [tourGuideId]);

  const autoConfirmingSeisan = (fileId: string, fileName: string) => {
    Modal.confirm({
      title: t('tourDetail_TourSeisanAutoConfirmingMessage'),
      okText: t('tourDetail_TourSeisanAutoConfirmingYes'),
      cancelText: t('tourDetail_TourSeisanAutoConfirmingNo'),
      onOk: () => {
        form.validateFields().then(value => {
          let data = {
            ...value,
            id: guideSeisan?.id,
            sessionId,
            fileId: fileId,
          };
          tourSeisanAction.autoConfirmingSeisan(data, fileName, setLoading, () => onClose(true));
        });
      },
      onCancel: () => {
        guideSeisanService.downloadSeisanFile(fileId, fileName, () => {
          getGuideSeisanFiles();
        });
      },
    });
  };

  const tableCols: ColumnsType<any> = React.useMemo(() => {
    const enableAutoConfirming = guideSeisan?.status === GuideSeisanStatus.Uploaded && !guideSeisan.isLocked;
    let cols = [
      { title: t('tourDetail_TourSeisanFileName'), dataIndex: 'fileName', ellipsis: true, width: 200 },
      { title: t('tourDetail_TourSeisanUploadedBy'), dataIndex: 'createdBy', width: 130 },
      { title: t('tourDetail_TourSeisanUploadedTime'), dataIndex: 'createdAt', width: 160, render: v => formatDisplayFullDate(v) },
      { title: t('guideSeisan_IsSubmitted'), dataIndex: 'submittedStatus', width: 100, render: v => <SeisanFileSubmittedTag status={v} /> },
      { title: t('tourDetail_TourSeisanDownloaded'), dataIndex: 'downloaded', width: 130, align: 'center', render: v => <BooleanTag value={v} /> },
      {
        title: t('tourDetail_TourSeisanDownload'),
        dataIndex: 'id',
        width: 120,
        align: 'center',
        render: (id, { fileName, downloaded }: any) => (
          <Button
            onClick={() => {
              if (enableAutoConfirming && !downloaded) {
                autoConfirmingSeisan(id, fileName);
              } else {
                guideSeisanService.downloadSeisanFile(id, fileName, () => {
                  getGuideSeisanFiles();
                });
              }
            }}
            size="small"
            icon={<DownloadOutlined />}
          />
        ),
      },
    ];
    cols.push({
      title: t('tourDetail_TourSeisanDelete'),
      width: 60,
      align: 'center',
      dataIndex: 'id',
      render: v => (
        <Button
          disabled={!!guideSeisan?.isLocked}
          onClick={() => {
            onDeleteFile(v);
          }}
          danger
          size="small"
          icon={<DeleteOutlined />}
        />
      ),
    });
    return cols as any;
  }, [guideSeisan]);

  const onClick2Upload = () => {
    fileInputRef.current?.click();
  };
  const showError = (error?: any) => {
    message.error(t(error || ERROR_EXCEPTION));
  };
  const onChangeFile = async (e: { currentTarget: HTMLInputElement }) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      const file = e.currentTarget.files[0];
      setLoading(true);
      ApiSdk.GuideSeisanService.uploadSeisanFile({
        file: file,
        fileName: file.name,
        seisanId: guideSeisan?.id || '',
      })
        .then(() => {
          ApiSdk.GuideSeisanService.getGuideSeisanFiles({ tourGuideId: tourGuideId }).then(res => {
            setGuideSeisanFiles(res);
          });
        })
        .catch(err => {
          showError();
        })
        .finally(() => {
          setLoading(false);
        });
    }
    e.currentTarget.value = '';
  };

  const onDeleteFile = (fileId: string) => {
    Modal.confirm({
      title: t('CONFIRM_DELETE_FILE'),
      icon: <ExclamationCircleOutlined />,
      okText: t('Delete'),
      cancelText: t('Cancel'),
      onOk: () => {
        setLoading(true);
        ApiSdk.GuideSeisanService.deleteSeisanFile({ id: fileId })
          .finally(() => {
            setLoading(false);
          })
          .then(res => {
            if (res.success) {
              getGuideSeisanFiles();
            } else {
              showError(res.message);
            }
          });
      },
    });
  };

  const accountingSaveSeisan = () => {
    form.validateFields().then(value => {
      setLoading(true);
      tourSeisanAction.accountingUpdateSeisan(
        {
          ...value,
          paymentDueDate: value.paymentDueDate ? moment(value.paymentDueDate).format(DEFAULT_DATE_FORMAT) : undefined,
          id: guideSeisan?.id,
          sessionId,
        },
        setLoading,
        () => {
          onClose(true);
        },
      );
    });
  };

  const seisanValidation = React.useMemo(() => {
    let seisanRule = { pattern: new RegExp(SEISAN_REGEX), message: t('SeisanNumberValidate') };
    return [GuideSeisanStatus.NoFilesUploaded, GuideSeisanStatus.NoNeedSeisan].includes(seisanStatus || GuideSeisanStatus.NoFilesUploaded)
      ? [seisanRule]
      : [requiredRule, seisanRule];
  }, [seisanStatus]);

  const paymentDueDateValidation = React.useMemo(() => {
    return [GuideSeisanStatus.Paying, GuideSeisanStatus.Paid].includes(seisanStatus || 0) ? [requiredRule] : [];
  }, [seisanStatus]);
  const taxWithheldAmountValidation = React.useMemo(() => {
    return [GuideSeisanStatus.FinalConfirmed, GuideSeisanStatus.Paying, GuideSeisanStatus.Paid].includes(seisanStatus || 0) ? [requiredRule] : [];
  }, [seisanStatus]);
  const onPaymentCostChange = v => {
    if ((guideSeisan?.paymentCost as any) >= 0 && v < 0) {
      form.setFieldsValue({
        paymentDueDate: moment().add(2, 'week'),
      });
    }
  };
  return (
    <Modal
      className="nta-custom-form"
      bodyStyle={scrollableModalBodyStyle}
      visible={true}
      onCancel={() => onClose()}
      style={{ top: 10, maxWidth: 1200 }}
      width={'100%'}
      title="ガイド精算"
      footer={
        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Button loading={loading} onClick={() => onClose()}>
              {t('Close')}
            </Button>
          </div>
          <div>
            <Space>
              <Button
                disabled={!!guideSeisan?.isLocked}
                onClick={accountingSaveSeisan}
                className="save-btn"
                loading={loading}
                icon={<SaveOutlined />}
              >
                {t('Save')}
              </Button>
            </Space>
          </div>
        </div>
      }
      maskClosable={false}
    >
      <Spin spinning={loading}>
        <TourLockCpn isLocked={guideSeisan?.isLocked} lockedBy={guideSeisan?.lockedBy}>
          <Form layout="horizontal" form={form} colon={false} style={{ marginRight: 40 }}>
            <Row gutter={6}>
              <Col md={12} xs={24}>
                <Form.Item labelCol={{ md: 8 }} labelAlign="right" label={t('tourDetail_GuideSeisanStatus')} name="status" rules={[requiredRule]}>
                  <AccountingSeisanStatusInput
                    currentStatus={guideSeisan?.status}
                    disabled={!!guideSeisan?.isLocked}
                    onChange={v => setSeisanStatus(v as any)}
                    className="input-value-color"
                  />
                </Form.Item>
                <Form.Item labelCol={{ md: 8 }} labelAlign="right" label={t('tourMessagePage_TourName')} name="tourName">
                  <Input disabled className="input-value-color" />
                </Form.Item>

                <Form.Item
                  labelCol={{ md: 8 }}
                  labelAlign="right"
                  label={t('tourDetail_TourNoTourSeisan')}
                  name="seisanNumber"
                  rules={seisanValidation}
                >
                  <Input maxLength={9} disabled={!!guideSeisan?.isLocked} className="input-value-color" />
                </Form.Item>

                <Form.Item
                  labelCol={{ md: 8 }}
                  labelAlign="right"
                  label={t('tourDetail_TourSeisanAmountCarrying')}
                  name="amountCarrying"
                  rules={[requiredRule]}
                >
                  <InputNumber min={0} className="w-100 input-value-color" disabled={!!guideSeisan?.isLocked} />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item labelCol={{ md: 7 }} labelAlign="right" label={t('tourDetail_TourSeisanNTARemark')} name="ntaRemark">
                  <Input.TextArea rows={5} className="w-100" disabled={!!guideSeisan?.isLocked} />
                </Form.Item>
              </Col>

              <Col md={24} style={{ marginBottom: 20 }}>
                <Row gutter={6}>
                  <Col md={4} style={{ textAlign: 'right', paddingRight: 5 }}>
                    {t('tourDetail_TourSeisanAttachment')}
                  </Col>
                  <Col md={20}>
                    <div style={{ marginBottom: 5 }}>
                      <Button size="small" disabled={!!guideSeisan?.isLocked} type="primary" icon={<PlusOutlined />} onClick={onClick2Upload}>
                        {t('tourDetail_TourSeisanUpload')}
                      </Button>
                    </div>
                    <Table
                      scroll={{ x: sumBy(tableCols, x => (x.width as any) || 0) }}
                      size="small"
                      pagination={false}
                      bordered
                      columns={tableCols}
                      dataSource={guideSeisanFiles}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col md={24} xs={24}>
                <Form.Item labelCol={{ md: 4 }} label={t('tourDetail_TourSeisanGuideRemark')} name="guideRemark">
                  <Input.TextArea placeholder="" disabled className="input-value-color w-100" rows={5} />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  labelCol={{ md: 12 }}
                  labelAlign="right"
                  label={t('tourDetail_TourSeisanPaymentTaxWithoutAmount')}
                  name="taxWithheldAmount"
                  rules={taxWithheldAmountValidation}
                >
                  <InputNumber min={0} formatter={v => formatCurrency(v)} disabled={!!guideSeisan?.isLocked} className="w-100 input-value-color" />
                </Form.Item>
              </Col>

              <Col md={8} xs={24}>
                <Form.Item
                  labelCol={{ md: 12 }}
                  labelAlign="right"
                  label={t('tourDetail_TourSeisanPaymentPaymentCost')}
                  name="paymentCost"
                  rules={taxWithheldAmountValidation}
                >
                  <InputNumber
                    onChange={onPaymentCostChange}
                    formatter={v => formatCurrency(v)}
                    disabled={!!guideSeisan?.isLocked}
                    className="w-100 input-value-color"
                  />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  labelCol={{ md: 12 }}
                  labelAlign="right"
                  label={t('tourDetail_TourSeisanPaymentDueDate')}
                  name="paymentDueDate"
                  rules={paymentDueDateValidation}
                >
                  <NTADatePicker disabled={!!guideSeisan?.isLocked} className="w-100 input-value-color" />
                </Form.Item>
              </Col>
            </Row>
            <input
              multiple
              style={{ display: 'none' }}
              ref={fileInputRef as any}
              type="file"
              name="attachmentFile"
              onChange={evt => {
                onChangeFile(evt);
              }}
            />
          </Form>
        </TourLockCpn>
      </Spin>
    </Modal>
  );
}
