import FullPageLoading from '@/components/FullPageLoading';
import { Space } from 'antd';
import React from 'react';

interface Props {
  icon?: React.ReactNode;
  title?: string | React.ReactNode;
  children: React.ReactNode;
  extraBtns?: any;
  isLoading?: boolean;
  titleClassName?: string;
}

const MainContent = (props: Props) => {
  return (
    <div className="app-page-title">
      <div className="title-header">
        {props.title && <div className={`title ${props.titleClassName || ''}`}>{props.title}</div>}

        <div className="extra-btns">
          <Space>{props.extraBtns}</Space>
        </div>
      </div>
      <div>{props.children}</div>
      <FullPageLoading loading={props.isLoading} />
    </div>
  );
};

export default MainContent;
