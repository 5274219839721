import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageModel } from '@/__generated';
import { Form, Col, Row, Checkbox, Radio, Input, Select, Button, InputNumber } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import DatePicker from '@/components/DatePicker';
import { SupportedLanguageLicenseType } from '@/config';
import { VALIDATE_REQUIRED } from '@/constants';
import { FormInstance } from 'antd/es/form/Form';

type Props = {
  form: FormInstance<any>;
  languages: LanguageModel[];
  isMyProfile: boolean;
};

export default function LanguageContentMD({ languages, form, isMyProfile }: Props) {
  const { t } = useTranslation();
  const onMainLanguageChange = (key: number) => {
    const supportedLanguages: { isMain: boolean }[] = form.getFieldValue('supportedLanguages');
    form.setFieldsValue({
      supportedLanguages: supportedLanguages?.map((lang, i) => {
        return {
          ...lang,
          isMain: i === key,
        };
      }),
    });
  };
  const onRemoveLanguage = () => {
    const supportedLanguages: { isMain: boolean }[] = form.getFieldValue('supportedLanguages');
    if (supportedLanguages.length) {
      if (!supportedLanguages.some(x => x.isMain)) {
        form.setFieldsValue({
          supportedLanguages: supportedLanguages?.map((lang, i) => {
            return {
              ...lang,
              isMain: i === 0,
            };
          }),
        });
      }
    }
  };
  return (
    <div>
      <Form.List name="supportedLanguages">
        {(fields, { add, remove }) => (
          <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
              <div className="form-label-required">{t('guideSerchsupportedLanguages')}</div>
              <Button
                type="primary"
                className="save-btn"
                onClick={() => add({ isMain: fields.length === 0 })}
                icon={<PlusOutlined />}
                style={{ marginLeft: 10 }}
              >
                {t('guideSerchadd')}
              </Button>
            </div>
            <Row gutter={6} className="mb-2">
              <Col md={5} className="header-languages-first">
                <h5>{t('guideSerchlanguage')}</h5>
              </Col>
              <Col md={3} className="header-languages">
                <h5>{t('guideSerchmain')}</h5>
              </Col>
              <Col md={9} className="header-languages">
                <h5>{t('guideSerchlicense')}</h5>
              </Col>
              <Col md={4} className="header-languages-end">
                <h5>{t('guideSerchobtainedDate')}</h5>
              </Col>
            </Row>

            {fields.map(field => (
              <Row key={field.key} gutter={6} className="language-row">
                <Col md={5}>
                  <Form.Item noStyle name={[field.name, 'id']}>
                    <Input type="hidden" />
                  </Form.Item>
                  <Form.Item name={[field.name, 'languageCode']} rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {languages.map((language, index) => (
                        <Select.Option key={index} value={language.code || ''}>
                          {language?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={3} className="text-center">
                  <Form.Item name={[field.name, 'isMain']} valuePropName="checked">
                    <Radio
                      onClick={() => {
                        onMainLanguageChange(field.key);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col md={9}>
                  <Row gutter={6}>
                    <Col md={14}>
                      <Form.Item name={[field.name, 'license']} rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                        <Select>
                          <Select.Option value={SupportedLanguageLicenseType.National}>
                            {t(SupportedLanguageLicenseType.__customLabel(SupportedLanguageLicenseType.National))}
                          </Select.Option>
                          <Select.Option value={SupportedLanguageLicenseType.Regional}>
                            {t(SupportedLanguageLicenseType.__customLabel(SupportedLanguageLicenseType.Regional))}
                          </Select.Option>

                          <Select.Option value={SupportedLanguageLicenseType.None}>
                            {t(SupportedLanguageLicenseType.__customLabel(SupportedLanguageLicenseType.None))}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={10}>
                      <Form.Item name={[field.name, 'licenseDetails']}>
                        <Input maxLength={500} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col md={4}>
                  <Form.Item name={[field.name, 'obtainedDate']}>
                    <DatePicker className="w-100" lessThanToday />
                  </Form.Item>
                </Col>
                <Col md={3} className="text-center">
                  <Button
                    disabled={fields.length <= 1 && isMyProfile}
                    icon={<DeleteOutlined />}
                    className="delete-btn"
                    danger
                    onClick={() => {
                      remove(field.name);
                      onRemoveLanguage();
                    }}
                    size="small"
                  >
                    削除
                  </Button>
                </Col>
              </Row>
            ))}
          </div>
        )}
      </Form.List>

      {/*  */}

      <Row gutter={6}>
        <Col md={5}>
          <Form.Item label={t('guideSerchpersonalBusinessRegistration')} name="isPersonalBusinessRegistration">
            <Radio.Group>
              <Radio value={true}>{t('staffSearch_yes')}</Radio>
              <Radio value={false}>{t('staffSearch_no')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label={t('guideSerchregistrationNumber')} name="personalBusinessRegistrationNumber">
            <Input />
          </Form.Item>
        </Col>
        <Col md={7}>
          <Form.Item label={t('guideSerchmembershipOfProfessionalInstitutions')} name="professionalInstitutions">
            <Checkbox.Group
              onChange={v => {
                if (!v.includes('OTHER')) {
                  form.setFieldsValue({ otherProfessionalInstitution: '' });
                }
              }}
            >
              <Checkbox value="JFG">{t('guideSerchJFG')}</Checkbox>
              <Checkbox value="JGA">{t('guideSerchJGA')}</Checkbox>
              <Checkbox value="OTHER">{t('guideSerchOther')}</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item noStyle dependencies={['professionalInstitutions']}>
            {({ getFieldValue }) => {
              let professionalInstitutions = getFieldValue('professionalInstitutions') || [];
              return (
                <Form.Item name="otherProfessionalInstitution" label="  ">
                  <Input disabled={!professionalInstitutions?.includes('OTHER')} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col md={5}>
          <Form.Item
            label={t('guideSerchmaxNumberOfNights')}
            name="maxNumberOfNights"
            rules={[{ required: isMyProfile, message: VALIDATE_REQUIRED }]}
          >
            <InputNumber className="w-100" min={0} addonAfter={t('guideSerchDay')} />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item rules={[{ required: isMyProfile, message: VALIDATE_REQUIRED }]} label={t('guideSerchUnitExperience')} name="maxNumberExperience">
            <Radio.Group>
              <Radio value={true} className="m-0">
                {t('staffSearch_yes')}
              </Radio>
              <Radio
                value={false}
                className="m-0"
                onClick={() => {
                  form.setFieldsValue({ maxNumber: null });
                }}
              >
                {t('staffSearch_no')}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item noStyle dependencies={['maxNumberExperience']}>
            {({ getFieldValue }) => {
              const maxNumberExperience = getFieldValue('maxNumberExperience');
              return (
                <Form.Item label={t('guideSerchmaxNumber')} name="maxNumber">
                  <InputNumber min={1} disabled={!maxNumberExperience} className="w-100" addonAfter="台口" />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col md={5}>
          <Form.Item
            rules={[{ required: isMyProfile, message: VALIDATE_REQUIRED }]}
            label={t('guideSerchinterpretationExperience')}
            name="hasInterpretationExperience"
          >
            <Radio.Group>
              <Radio value={true}>{t('staffSearch_yes')}</Radio>
              <Radio value={false}>{t('staffSearch_no')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item name="interpretationExperienceDetails">
            <Input.TextArea placeholder="詳細" className="w-100 input-value-color" rows={2} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
