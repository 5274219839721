import { ERROR_EXCEPTION } from '@/constants';
import i18n from '@/helpers/i18n';
import httpClient, { ApiSdk } from '@/httpclient';
import guideSeisanService from '@/services/guideSeisanService';
import { message } from 'antd';

class TourSeisanAction {
  guideSaveSeisan = (formValue: any, setLoading: (loading: boolean) => void, onSuccess: () => void) => {
    ApiSdk.GuideSeisanService.guideUpdateSeisan({ body: formValue })
      .then(res => {
        if (res.success) {
          onSuccess();
        } else {
          message.error(i18n.t(res.message || ERROR_EXCEPTION));
        }
      })
      .catch(() => {
        message.error(ERROR_EXCEPTION);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  guideSubmitSeisan = (formValue: any, setLoading: (loading: boolean) => void, onSuccess: () => void) => {
    ApiSdk.GuideSeisanService.guideSubmitSeisan({ body: formValue })
      .then(res => {
        if (res.success) {
          onSuccess();
        } else {
          message.error(i18n.t(res.message || ERROR_EXCEPTION));
        }
      })
      .catch(() => {
        message.error(ERROR_EXCEPTION);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  guideCompleteRefund = (formValue: any, setLoading: (loading: boolean) => void, onSuccess: () => void) => {
    ApiSdk.GuideSeisanService.guideCompleteRefundSeisan({ body: formValue })
      .then(res => {
        if (res.success) {
          onSuccess();
        } else {
          message.error(i18n.t(res.message || ERROR_EXCEPTION));
        }
      })
      .catch(() => {
        message.error(ERROR_EXCEPTION);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  accountingUpdateSeisan = (formValue: any, setLoading: (loading: boolean) => void, onSuccess: () => void) => {
    ApiSdk.GuideSeisanService.accountingUpdateSeisan({ body: formValue })
      .then(res => {
        if (res.success) {
          onSuccess();
        } else {
          message.error(i18n.t(res.message || ERROR_EXCEPTION));
        }
      })
      .catch(() => {
        message.error(ERROR_EXCEPTION);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  accountingUpdateSeisanNumber = (formValue: any, setLoading: (loading: boolean) => void, onSuccess: () => void) => {};

  autoConfirmingSeisan = (formValue: any, fileName: string, setLoading: (loading: boolean) => void, onSuccess: () => void) => {
    setLoading(true);
    httpClient
      .post(`/guide-seisans/accounting-auto-confirming-seisan`, formValue, {
        responseType: 'blob',
      })
      .then(res => {
        guideSeisanService.downloadFileWithData(fileName || '', res.data);
        onSuccess();
      })
      .catch(err => {
        message.error(i18n.t(err.message || ERROR_EXCEPTION));
      })
      .finally(() => {
        setLoading(false);
      });
  };
}

const tourSeisanAction = new TourSeisanAction();
export default tourSeisanAction;
