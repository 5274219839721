export default {
  // messages
  'Please input required field.': '必須項目',
  'Are you sure want to delete this item?': 'このアイテムを本当に削除しますか？',
  TOUR_GUIDE_REJECT_CONFIRM_MESSAGE: '『対応不可』の回答を送信します。よろしいですか？',
  TOUR_GUIDE_CONFIRM_CONFIRM_MESSAGE: 'ツアーの詳細を確認しましたか?',
  TOUR_GUIDE_ACCEPT_CONFIRM_MESSAGE: '『引き受け』の回答を送信します。よろしいですか？',
  UPDATE_TOUR_SUCCESS: 'アップデートツアー成功',
  UPDATE_PASSWORDS_SUCCESS: 'パスワードが正常に設定されました',
  HOME_BACK: 'ログインページに戻る',
  RESET_PASSWORD: 'パスワードを再設定する',
  SET_PASSWORD_TITLE: 'パスワード設定',
  SOME_CHANGES_IN_THESE_TOUR_GUIDES: '情報が更新されましたが、ステータスが変更されておりません。ステータスは変更は不要ですか。',
  GUIDE_ALL_DATE_UNAVAILABLE: '全て業務不可となっていますが、スタータスと一致していません。ステータスの変更は不要ですか。',
  ALL_DATE_CANCELLATION_SHOULD_BE_AWAITING_CANCELLATION:
    'ガイドの業務日が全て取消済ですが、ステータスが依頼中になっています。ステータスを再度確認してください。',

  ADD_AT_LEAST_ONE_BUSHINESS_DAY: '業務日を最低1日は指定してください。 ',
  PLEASE_INPUT_NTA_COMMENT: '以下のガイドのNTAからの連絡事項を入力してください。',
  KEEP_ALL_CANCELLED_DATE: 'キャンセルした全ての日程を保存しますか？',
  CONFIRM_CANCEL_DATE: '本当にこの日付をキャンセルして良いですか？',

  //backend message
  RecordNotFoundWithSpecifiedId: 'レコードが見つかりません。',
  DeleteRecordInternalError: '削除中にエラーが発生しました。',
  UpdateRecordInternalError: '更新中にエラーが発生しました。',
  SmtpNotFound: 'メールアドレスのサーバーが設定されていません。',
  LanguageAlreadyExist: 'その言語は既に登録されています。',
  EmailContentIncorrectFormat: '設定されているメールテンプレートが間違っている為送信できません。',
  EmailTemplateAlreadyExist: 'そのメールテンプレートは既に登録されています。',
  CountryCodeAlreadyExist: 'その国は既に登録されています。',
  DepartmentNameAlreadyExist: 'その部は既に登録されています。',
  DivisionNameAlreadyExist: 'その課は既に登録されています。',
  InternalErrorDuringSendingEmail: '内部エラーにより、メールが送信されませんでした。',
  FileUploadIsInvalid: 'ファイルの形式が間違っているか、存在していません。',
  InternalErrorDuringUploadFile: '内部エラーにより、ファイルのアップロードに失敗しました。',
  InternalErrorDuringDownloadFile: '内部エラーにより、ファイルのダウンロードに失敗しました。',
  FileDownloadIsNotExist: 'ダウンロードファイルが存在しません。',
  InternalErrorDuringUpdateGuideAvailability: 'ガイド業務可否が更新できませんでした。',
  InternalErrorDuringGetGuideAvailabilities: 'ガイドスケジュールを取得できませんでした。',
  CreateGuideAccountUnSuccessful: 'ガイド口座情報の登録時にエラーが発生しました。',
  CreateGuideInternalError: 'ガイド情報の登録時にエラーが発生しました。',
  DeleteGuideInternalError: 'ガイド情報の削除時にエラーが発生しました。',
  GuideIsNotSet: 'ガイド情報が存在しません。',
  GuideDoesNotExists: 'ガイド情報が存在しません。',
  NewPasswordConfirmNotMatched: '誤ったパスワードが入力されました。',
  SuccessButSendEmailFailed: 'データは更新されましたが、メール送信に失敗しました。',
  CannotModifyGuideEmail1: 'ガイドのメールアドレスは変更できません。',
  CreateUserAccountUnsuccessful: 'ユーザー口座情報の登録時にエラーが発生しました。',
  SaveUserInternalError: 'ユーザー情報の登録時にエラーが発生しました。',
  DeleteUserInternalError: 'ユーザー情報の削除時にエラーが発生しました。',
  UsernameAlreadyExist: 'そのユーザー名は既に存在します。別のユーザー名を入力してください。',
  UserEmailAlreadyExist: 'そのメールアドレスが既に存在します。別のメールアドレスを入力してください。',
  UserNotFoundWhenRequestForgotPassword: 'メールアドレスが存在しません。',
  ForgotPasswordSessionExpired: 'このリンクは有効時間を過ぎています。再度パスワード再設定リクエストを行ってください。',
  UsernameOrPasswordIsIncorrect: 'ユーザー名またはパスワードが誤っています。',
  UserNotfoundOrNotActiveYet: 'ユーザー名またはパスワードが誤っています。',
  UserRoleAlreadyExist: 'そのユーザー権限は既に存在します。別のユーザー権限を入力してください。',
  NewPasswordEqualsOldPassword: '新しいパスワードは前回と同じパスワードにはできません。',
  ChangePasswordUnsuccessful: '内部エラーにより、パスワードが変更できませんでした。',
  TemporaryPasswordNotFound: '入力されたパスワードは誤っているか失効しています。',
  TemporaryPasswordNotMatch: '入力されたパスワードは誤っているか失効しています。',
  WrongToken: '入力されたパスワードは誤っているか失効しています。',
  SetPasswordError: '内部エラーにより、パスワードが変更できませんでした。',
  CannotDeleteLanguageIsAlreadyUsedForSomeGuides: '選択した言語は使用されているため、削除できません。',
  CannotUpdateLanguageCodeIsAlreadyUsedForSomeGuides: '選択した言語は使用されているため、言語コードは更新できません。',
  PasswordCannotContainsEmail: 'パスワードにメールアドレスは使用できません。	',
  IncorrectPassword: '現在のパスワードが正しくありません。',
  'Incorrect password.': '現在のパスワードが間違っています。再度入力してください',
  ResetPasswordSessionExpired: '初期パスワードの有効時間を過ぎたため、下記リンクよりパスワードの再設定をお願いします。',
  NewPasswordEqualsCurrentPassword: '新しいパスワードは古いパスワードと同じにすることはできません。',
  UserLockedWhenRequestForgotPassword: '有効なメールアドレスではございません。',
  // new
  DeleteTourGuideConfirm: 'このガイドを削除してよろしいですか。',
  TourNotExists: '終了したツアーです。',
  GuideAndLanguageExistsOtherTab: 'Guide [{{name}}] and language [{{language}}] already exists in other tabs',
  GuideAndLanguageDuplicated: `Guide <b>{{name}}</b> and language <b>{{language}}</b> is duplicated!`,
  GuideAndDateDuplicated: `ガイド <b>{{name}}</b> の日付が重複されています: <b>{{date}}</b>`,
  GuideUpdateProfileErrorTitle: '確認メッセージ',
  GuideUpdateProfileChangeNotSave: `保存がされておりません。画面を閉じると登録内容が消えてしまいます。<br/>画面を閉じてよろしいですか？`,
  GuideUpdateProfileValidateError:
    '入力項目にエラーがあるため保存ができません。画面を閉じると登録内容が消えてしまいます。<br/>画面を閉じてよろしいですか？',
  LoginSessionExpiredTitle: '警告',
  LoginSessionExpiredContent: 'ログインセッションが切れました。ログイン画面に戻って再度ログインを行ってください。',
  GuideLanguageDuplicatedTitle: '警告',
  GuideLanguageDuplicatedContent: '言語が重複されています。再度ご確認ください',

  // 1.5
  StaffIsAlreadyUsedForSomeTours: 'スタッフがツアーに登録されている為、削除できません。',
  CountryIsAlreadyUsedForSomeTours: '国籍がツアーに登録されている為、削除できません。',
  GuideIsAlreadyUsedForSomeTours: 'ガイドがツアーにアサインされている為、削除できません。',
  CannotDeleteLanguageIsAlreadyUsedForSomeTours: '言語がツアーに登録されている為、削除できません。',
  UpdateTourSessionIsInvalid: 'セッションが切れました。編集内容が保存されていません。ページを再読み込みを行ってください。',
  TourNumberIsDuplicated: 'ツアーNoが重複してます。',
  TourIdIsDuplicated: 'ツアーIDが重複してます。',
  SendGuideEmailUnSuccessful: '予期せぬエラーが発生したため、メールを送信できません。',
  SendGuideEFaxUnSuccessful: '予期せぬエラーが発生したため、eFAXを送信できません。',
  EFaxInvalidFilePassword: 'ファイルのパスワードに誤りがあります。もう一度入力してください。',
  EFaxConvertPdfError: 'PDF作成に失敗したため、eFAXを送信できません',
  EFaxUploadFileError: 'サーバーにファイルをアップロードできません。',
  EFaxFileUploadLimit30Mb: '添付ファイルのサイズが最大値(30MB)を越えています。',
  EFaxFileUploadLimit10Files: 'ファイルは最大10個まで添付が可能です。',
  EFaxFileUploadLimit25Mb: '添付ファイルのサイズが最大値(25MB)を越えています。',
  SeisanNumberValidate: '数字(0-9)のみ入力してください。',
  CONFIRM_DELETE: 'このアイテムを本当に削除しますか？',
  CONFIRM_DELETE_FILE: 'ファイルを削除します。宜しいでしょうか？',
  SessionIdDoesNotExistWhenGetGuideSeisan: '精算画面を開けません。',
  GuideSeisanDoesNotExist: '精算画面が存在しません。',
  CurrentSeisanStatusIsNotAllowToUploadFile: '現在のステータスではファイルのアップロードはできません。',
  CurrentSeisanStatusIsNotAllowToRemoveFile: '現在のステータスではファイルの削除はできません。',
  UserIsNotAllowToRemoveSeisanFile: 'このファイルは削除できません。',
  UploadSeisanFileInternalError: 'ファイルのアップロードに失敗しました。',
  RemoveSeisanFileInteralError: 'ファイルの削除に失敗しました。',
  CurrentSeisanStatusIsNotAllowGuideToUpdate: '現在のステータスでは新しいファイルをアップロードできません。',
  UpdateSeisanInternalError: '精算画面の更新に失敗しました。',
  SeisanNumberIsRequired: '精算番号は必須項目です。入力してください。',
  SeisanFileUploadIsRequired: 'ファイルは最低でも１つアップロード/提出する必要があります。',
  CurrentTourGuideStatusIsNotAllowToSubmitSeisan: '現在のステータスでは新しいファイルをアップロードできません。',
  CurrentSeisanStatusIsNotAllowToCompleteRefund: '現在のステータスでは返金できません。',
  AccountingDoesNotHavePermissionOnNewSeisanStatus: 'このガイド精算ステータスに変更する権限がありません。',
  PaymentDueDateIsRequiredForThisStatus: '支払予定日は必須項目です。入力してください。',
  PaymentCostIsRequiredForThisStatus: '振込予定金額は必須項目です。入力してください。',
  PaymentCostMustBe0OrAPositiveNumber: '振込予定金額は0円またはそれ以上の金額でなければなりません。(150,000円 等)',
  PaymentCostMustBeANegativeValueForCompleteRefund: '返金額はマイナス金額でなければなりません。(-10,000円 等)',
  TaxWithheldAmountIsRequiredForThisStatus: '源泉金額は必須項目です。',
  TaxWithheldAmountMustBe0OrAPositiveNumber: '源泉金額は0円またはそれ以上の金額でなければなりません。（10,000円 等）',
  AmountCarryingMustBeZeroOrPositiveNumber: '携行金は0円またはそれ以上の金額でなければなりません。（10,000円 等）',
  GuideSeisanIsNotAllowToAutoConfirming: 'ガイド精算ステータスは"NTA確認中"に変更されません。',
  SeisanFileNotFound: 'ファイルが存在しません。',
  UpdateSuccess: '更新成功',
};
