import { HistoryOutlined } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import MainContent from '../../Layout/MainContent';
import moment from 'moment';
import ZTable, { IColumnType, IFilterItem } from '@/components/ZTable';
const PAGE_TITLE = 'APIアクセスログ';
const filters: Array<IFilterItem> = [
  { type: 'TEXT', name: 'tourId', label: 'TourId', mdSize: 8, xlSize: 8, xxlSize: 8 },
  { type: 'TEXT', name: 'method', label: 'Method', mdSize: 8, xlSize: 8, xxlSize: 8 },
];
export default () => {
  const [t] = useTranslation();

  const columns: IColumnType<any>[] = React.useMemo(() => {
    return [
      {
        width: 300,
        title: t('TourId'),
        dataIndex: 'tourId',
        ellipsis: true,
      },
      {
        title: t('Endpoint'),
        dataIndex: 'url',
        ellipsis: true,
      },
      {
        title: t('Action Name'),
        dataIndex: 'actionName',
        width: 220,
      },
      {
        width: 150,
        title: t('Start Time'),
        dataIndex: 'startTime',
        render: (value: any) => {
          return moment(value).format('YYYY/MM/DD hh:mm:ss');
        },
      },
      {
        title: t('Duration'),
        dataIndex: 'duration',
        width: 70,
      },
      {
        width: 180,
        title: t('zTable.actions'),
        dataIndex: '_actions',
        render(_: any, record: any) {
          return (
            <Space>
              <span>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    Modal.info({
                      title: 'Request',
                      content: record.request,
                      width: 700,
                    });
                  }}
                >
                  Request
                </Button>
              </span>
              <span>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    Modal.info({
                      title: 'Response',
                      content: record.response,
                      width: 700,
                    });
                  }}
                >
                  Response
                </Button>
              </span>
            </Space>
          );
        },
      },
    ];
  }, []);

  return (
    <MainContent title={PAGE_TITLE} icon={<HistoryOutlined style={{ fontSize: 16 }} />}>
      <Helmet title={PAGE_TITLE} />
      <div className="ant-layout-content-body">
        <ZTable<any>
          columns={columns}
          primaryKey="id"
          url="/api-access-logs/get-api-access-logs"
          bordered
          order_by="startTime"
          order_by_asc={false}
          allowEdit={false}
          allowDelete={false}
          filters={filters}
          layoutFilter={'horizontal'}
        />
      </div>
    </MainContent>
  );
};
