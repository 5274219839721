import { TourGuideStatusType } from '@/config';
import { TourGuideStatus } from '@/__generated';
import { Select, SelectProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const GuideStatusDict = {
  [TourGuideStatusType.Unassigned]: {
    Unassigned: TourGuideStatusType.Unassigned,
    Assigning: TourGuideStatusType.Assigning,
    Checking: TourGuideStatusType.Checking,
    Assigned: TourGuideStatusType.Assigned,
    FinalInforming: TourGuideStatusType.FinalInforming,
    AwaitingCancellation: TourGuideStatusType.AwaitingCancellation,
    Denied: TourGuideStatusType.Denied,
  },
  [TourGuideStatusType.Assigning]: {
    Unassigned: TourGuideStatusType.Unassigned,
    Assigning: TourGuideStatusType.Assigning,
    Assigned: TourGuideStatusType.Assigned,
    FinalInforming: TourGuideStatusType.FinalInforming,
    AwaitingCancellation: TourGuideStatusType.AwaitingCancellation,
  },
  [TourGuideStatusType.Checking]: {
    Assigning: TourGuideStatusType.Assigning,
    Checking: TourGuideStatusType.Checking,
    Assigned: TourGuideStatusType.Assigned,
    FinalInforming: TourGuideStatusType.FinalInforming,
    AwaitingCancellation: TourGuideStatusType.AwaitingCancellation,
    Denied: TourGuideStatusType.Denied,
  },
  [TourGuideStatusType.Assigned]: {
    Assigning: TourGuideStatusType.Assigning,
    Assigned: TourGuideStatusType.Assigned,
    FinalInforming: TourGuideStatusType.FinalInforming,
    AwaitingCancellation: TourGuideStatusType.AwaitingCancellation,
    Denied: TourGuideStatusType.Denied,
  },

  [TourGuideStatusType.Informing3MonthsPrior]: {
    Informing3MonthsPrior: TourGuideStatusType.Informing3MonthsPrior,
    FinalInforming: TourGuideStatusType.FinalInforming,
    AwaitingCancellation: TourGuideStatusType.AwaitingCancellation,
    Denied: TourGuideStatusType.Denied,
  },

  [TourGuideStatusType.Informing2MonthPrior]: {
    Informing2MonthPrior: TourGuideStatusType.Informing2MonthPrior,
    FinalInforming: TourGuideStatusType.FinalInforming,
    AwaitingCancellation: TourGuideStatusType.AwaitingCancellation,
    Denied: TourGuideStatusType.Denied,
  },

  [TourGuideStatusType.Checked]: {
    Assigning: TourGuideStatusType.Assigning,
    Checked: TourGuideStatusType.Checked,
    FinalInforming: TourGuideStatusType.FinalInforming,
    AwaitingCancellation: TourGuideStatusType.AwaitingCancellation,
    Denied: TourGuideStatusType.Denied,
  },

  [TourGuideStatusType.FinalInforming]: {
    FinalInforming: TourGuideStatusType.FinalInforming,
    FinalChecking: TourGuideStatusType.FinalChecking,
    AwaitingCancellation: TourGuideStatusType.AwaitingCancellation,
    Denied: TourGuideStatusType.Denied,
  },
  [TourGuideStatusType.FinalChecking]: {
    Assigning: TourGuideStatusType.Assigning,
    FinalInforming: TourGuideStatusType.FinalInforming,
    FinalChecking: TourGuideStatusType.FinalChecking,
    Finalized: TourGuideStatusType.Finalized,
    AwaitingCancellation: TourGuideStatusType.AwaitingCancellation,
    Denied: TourGuideStatusType.Denied,
  },
  [TourGuideStatusType.Finalized]: {
    Assigning: TourGuideStatusType.Assigning,
    FinalInforming: TourGuideStatusType.FinalInforming,
    Finalized: TourGuideStatusType.Finalized,
    AwaitingCancellation: TourGuideStatusType.AwaitingCancellation,
    Denied: TourGuideStatusType.Denied,
  },
  [TourGuideStatusType.AwaitingCancellation]: {
    Assigning: TourGuideStatusType.Assigning,
    Checking: TourGuideStatusType.Checking,
    Assigned: TourGuideStatusType.Assigned,
    Checked: TourGuideStatusType.Checked,
    FinalInforming: TourGuideStatusType.FinalInforming,
    Finalized: TourGuideStatusType.Finalized,
    AwaitingCancellation: TourGuideStatusType.AwaitingCancellation,
  },
  [TourGuideStatusType.Cancelled]: {
    Assigning: TourGuideStatusType.Assigning,
    Checking: TourGuideStatusType.Checking,
    FinalInforming: TourGuideStatusType.FinalInforming,
    Cancelled: TourGuideStatusType.Cancelled,
  },
  [TourGuideStatusType.Denied]: {
    Assigning: TourGuideStatusType.Assigning,
    Checking: TourGuideStatusType.Checking,
    Denied: TourGuideStatusType.Denied,
  },
};
interface Props extends SelectProps<any> {
  prevStatus?: TourGuideStatus | undefined | null;
  onBeforeChangeValid?: (previousValue: TourGuideStatus, nextValue: TourGuideStatus) => boolean;
}

export default function GuideStatusInput({ prevStatus, onBeforeChangeValid, value, onChange, ...props }: Props) {
  const { t } = useTranslation();
  const statusList = React.useMemo(() => {
    if (prevStatus) {
      if (GuideStatusDict[prevStatus]) {
        return GuideStatusDict[prevStatus];
      }
    }
    return {
      Unassigned: TourGuideStatusType.Unassigned,
      Assigning: TourGuideStatusType.Assigning,
      Checking: TourGuideStatusType.Checking,
      Assigned: TourGuideStatusType.Assigned,
      FinalInforming: TourGuideStatusType.FinalInforming,
      AwaitingCancellation: TourGuideStatusType.AwaitingCancellation,
      Denied: TourGuideStatusType.Denied,
    };
  }, [prevStatus]);
  const onValueChange = (v: TourGuideStatus, option: any) => {
    if (onBeforeChangeValid) {
      if (!onBeforeChangeValid(value, v)) {
        return;
      }
    }
    if (onChange) {
      onChange(v, option);
    }
  };
  return (
    <Select {...props} onChange={onValueChange} value={value}>
      {Object.keys(statusList).map(k => {
        return <Select.Option value={statusList[k]}>{t(k)}</Select.Option>;
      })}
    </Select>
  );
}
