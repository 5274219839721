import { AvailableTour, DayGuideAvailability } from '@/__generated';
import { CloseCircleFilled } from '@ant-design/icons';
import React from 'react';

type Props = {
  date: DayGuideAvailability | null | undefined;
  availableTours: AvailableTour[] | undefined;
};

function DateItem({ date, availableTours }: Props) {
  if (!date || availableTours?.length) {
    return null;
  }
  if (date.isWfAnotherDepartment) {
    return <CloseCircleFilled style={{ fontSize: 16 }} className={`icon other-department`} />;
  }
  if (date.stayLastNight) {
    return <CloseCircleFilled style={{ fontSize: 16 }} className={`icon stay-last-night`} />;
  }
  if (date.stayOvernight) {
    return <CloseCircleFilled style={{ fontSize: 16 }} className={`icon stay-over-night`} />;
  }
  if (date.isUnavailable) {
    return <CloseCircleFilled style={{ fontSize: 16 }} className={`icon unavailable`} />;
  }
  return null;
}

export default DateItem;
