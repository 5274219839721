import { TourDetailModel, TourGuideDetailModel } from '@/__generated';
import { Button } from 'antd';
import React from 'react';
import { MonitorOutlined } from '@ant-design/icons';
import { useAppContext } from '@/contexts/AppContext';
import guideSeisanService from '@/services/guideSeisanService';
import GuideSeisanModal from './GuideSeisanModal';
import { ApiSdk } from '@/httpclient';
import { useTourContext } from '@/contexts/TourContext';
import { cloneDeep } from 'lodash';
type Props = {
  tour: TourDetailModel | null | undefined;
  tourGuide: TourGuideDetailModel | null | undefined;
};

export default function GuideSeisanButton({ tour, tourGuide }: Props) {
  const { auth } = useAppContext();
  const [displayUpdateModal, setDisplayUpdateModal] = React.useState<boolean>(false);
  const tourContext = useTourContext();
  const canDisplay = React.useMemo(() => {
    return guideSeisanService.canViewGuideSeisan(tour, tourGuide, auth);
  }, [tour, tourGuide, auth]);

  if (!canDisplay) {
    return null;
  }
  const onModalClose = (reload?: boolean) => {
    if (reload) {
      ApiSdk.TourService.getTourSeisanInfo({ id: tour?.id || '' }).then(res => {
        if (res) {
          let tourCloned = cloneDeep(tourContext.tour) || {};
          tourCloned.tourGuides?.forEach(guide => {
            guide.seisanAmountCarrying = res[guide.id || 0] || 0;
          });
          tourCloned.seisanStatus = res.seisanStatus;
          tourCloned.tourNumber = res.tourNumber;
          tourContext.setTour(tourCloned);
        }
      });
    }
    setDisplayUpdateModal(false);
  };
  return (
    <>
      <Button type="primary" icon={<MonitorOutlined />} style={{ marginLeft: 10 }} onClick={() => setDisplayUpdateModal(true)}>
        精算申請
      </Button>
      {displayUpdateModal && tour && tourGuide ? <GuideSeisanModal onClose={onModalClose} tourGuideId={tourGuide.id || ''} /> : null}
    </>
  );
}
