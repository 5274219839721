import './wdyr'; // <--- first import

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.css';
import ja_JP from 'antd/es/locale/ja_JP';
import './scss/index.less';
import './scss/calendar.less';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './Layout';
import AppProvider from './contexts/AppContext';
import '@/helpers/i18n';
import history from './history';
import * as serviceWorker from './serviceWorker';
import moment from 'moment';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import 'moment/locale/ja';
import dayjs from 'dayjs';
require('dayjs/locale/ja');

moment.locale('ja');
dayjs.locale('ja');

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <AppProvider>
    <Router history={history}>
      <ConfigProvider locale={ja_JP}>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA || ''}>
          <App />
        </GoogleReCaptchaProvider>
      </ConfigProvider>
    </Router>
  </AppProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
