import { useAppContext } from '@/contexts/AppContext';
import React from 'react';
import AccountingUpdateSeisan from './AccountingUpdateSeisan';
import GuideUpdateSeisan from './GuideUpdateSeisan';
import * as roleService from '@/services/roleService';
import ReadOnlySeisan from './ReadOnlySeisan';

type Props = {
  onClose: (reload?: boolean) => void;
  tourGuideId: string;
};
export default function GuideSeisanModal({ onClose, tourGuideId }: Props) {
  const { auth } = useAppContext();

  const isGuide = React.useMemo(() => {
    return roleService.isGuide(auth?.roleClaims);
  }, [auth]);
  const isReferenceAccounting = React.useMemo(() => {
    return roleService.isReferenceAccounting(auth?.originRoleClaims);
  }, [auth]);
  return isGuide ? (
    <GuideUpdateSeisan onClose={onClose} tourGuideId={tourGuideId || ''} />
  ) : isReferenceAccounting ? (
    <ReadOnlySeisan onClose={onClose} tourGuideId={tourGuideId} />
  ) : (
    <AccountingUpdateSeisan onClose={onClose} tourGuideId={tourGuideId || ''} />
  );
}
