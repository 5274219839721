import { Form, Tabs, FormInstance } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';
import '../style.less';
import CreateTourGuideInfo from '../_editableComponents/TourGuideInput';
import CreateTourHeaderForm from '../_editableComponents/TourHeaderForm';
import {
  ICreateTourValidation,
  M2ValidationStatus,
  M3ValidationStatus,
  tourCreationValidation,
  tourFinalValidation,
  tourRequestValidation,
} from '../validation';
import { TourDetailModel, TourGuide, TourGuideDetailModel } from '@/__generated';
import FullPageLoading from '@/components/FullPageLoading';
import dayjs from 'dayjs';
import lodash, { maxBy, range } from 'lodash';
import { BooleanEnumType, TourGuideStatusType } from '@/config';
import { timeOnly2String } from '@/helpers/string';
import TourLockCpn from '@/components/app/TourLockCpn';
import TourHeader from '../_readonlyComponents/TourHeader';
import { GuideOtherInfo } from '../_editableComponents/GuideOtherInfo';
import { LockOutlined } from '@ant-design/icons';
import ReadOnlyGuideInfo from '../_readonlyComponents/GuideInfo';
import ReadOnlyGuideOtherInfo from '../_readonlyComponents/GuideOtherInfo';
import { ShortTourGuideInfo } from '../../TourFeedback/TourEvaluation';
import { useTourContext } from '@/contexts/TourContext';
import moment from 'moment';

type Props = {
  tour: TourDetailModel;
  visible: boolean;
  loading?: boolean;
  form: FormInstance;
  tabKey: string;
  setTabKey: (key: string) => void;
  showGuideEvaluation: (shortGuideInfo: ShortTourGuideInfo) => void;
};
let MaxKey: number = 0;
export default function UpdateTourModal({ tour, tabKey, setTabKey, visible, form, loading, showGuideEvaluation }: Props) {
  const [formValidation, setFormValidation] = React.useState<ICreateTourValidation>(tourCreationValidation);
  const tourContext = useTourContext();

  React.useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
    MaxKey = 0;
  }, [visible]);

  React.useEffect(() => {
    initFormValidation(tour.tourGuides || []);
    tourContext.setTour(tour);
  }, [tour]);

  React.useEffect(() => {
    if (tour) {
      form.setFieldsValue({
        ...tour,

        tourGuides: tour.tourGuides?.map(tourGuide => {
          return {
            ...tourGuide,
            ntaComments: '',
            guide: tourGuide.guideId
              ? {
                  label: tourGuide.guideName,
                  value: tourGuide.guideId,
                }
              : null,
            language: tourGuide.languageCode
              ? {
                  label: tourGuide.languageName,
                  value: tourGuide.languageCode,
                }
              : null,
            briefingTime: timeOnly2String(tourGuide.briefingTime),
            businessDays: lodash.orderBy(tourGuide.businessDays, d => {
              if (d.date) {
                return dayjs(d.date).unix();
              }
              return Number.MAX_VALUE;
            }),
          };
        }),
      });
    }
  }, [tour]);

  const initFormValidation = (tourGuides: TourGuideDetailModel[]) => {
    const guidesStatus: number[] = tourGuides?.map(x => x.status || 1) || [];
    if (guidesStatus.length) {
      const isFinalized = guidesStatus.some(x => M3ValidationStatus.includes(x));
      if (isFinalized) {
        setFormValidation(tourFinalValidation);
        form.validateFields();
        return;
      }
      const isRequestingStatus = guidesStatus.some(s => M2ValidationStatus.includes(s));
      if (isRequestingStatus) {
        setFormValidation(tourRequestValidation);
        form.validateFields();
        return;
      }
    }
    setFormValidation(tourCreationValidation);
    form.validateFields();
  };

  const onGuideStatusChange = () => {
    const tourGuides: TourGuide[] = form.getFieldValue('tourGuides');
    initFormValidation(tourGuides);
  };
  return (
    <Form layout="horizontal" form={form} scrollToFirstError colon={false}>
      <FullPageLoading loading={loading} />
      <TourLockCpn isLocked={tour.lockInfo?.locked} lockedBy={tour.lockInfo?.lockedBy}>
        {!tour.lockInfo?.locked ? (
          <CreateTourHeaderForm formValidation={formValidation} isUpdate={true} tour={tour} form={form} />
        ) : (
          <TourHeader tour={tour} editableField={[]} />
        )}
      </TourLockCpn>
      <Form.List name="tourGuides">
        {(fields, { add, remove }) => {
          const max = maxBy(fields, x => x.key)?.key || 0;
          if (max >= MaxKey) {
            MaxKey = max;
          }
          return (
            <div className="guide-info-tab-container">
              <Tabs
                type="editable-card"
                defaultActiveKey={tabKey}
                activeKey={tabKey}
                onChange={k => setTabKey(k)}
                hideAdd={tour.lockInfo?.locked}
                onEdit={(_, action) => {
                  if (action === 'add') {
                    let fromDate = form.getFieldValue('fromDate');
                    let toDate = form.getFieldValue('toDate');
                    let businessDays: any = [];
                    if (fromDate && toDate) {
                      let diff = moment(toDate).diff(moment(fromDate), 'day');
                      businessDays = range(0, diff + 1).map(i => {
                        return {
                          date: moment(fromDate).add(i, 'd'),
                          availability: BooleanEnumType.True,
                          stayOvernight: BooleanEnumType.False,
                          stayLastNight: BooleanEnumType.False,
                          fee: 0,
                        };
                      });
                    }
                    add({ status: TourGuideStatusType.Unassigned, businessDays: businessDays });
                    setTabKey(`guide-${MaxKey + 1}`);
                  }
                }}
              >
                {fields.map((field, i) => {
                  const tourGuide = tour?.tourGuides!.length > field.key ? tour.tourGuides![field.key] : undefined;
                  const { briefingLocked, briefingLockedBy, guideInfoLocked, guideInfoLockedBy } = tourGuide?.lockInfo || {};
                  return (
                    <Tabs.TabPane
                      forceRender
                      key={`guide-${field.key}`}
                      tab={<TabName fieldData={field} locked={guideInfoLocked} />}
                      closable={false}
                    >
                      <TourLockCpn isLocked={guideInfoLocked} lockedBy={guideInfoLockedBy}>
                        {guideInfoLocked ? (
                          <ReadOnlyGuideInfo guide={tourGuide || {}} />
                        ) : (
                          <CreateTourGuideInfo
                            onGuideStatusChange={onGuideStatusChange}
                            form={form}
                            fieldData={field}
                            tourGuide={tourGuide}
                            onRemove={() => {
                              if (fields.length > 1) {
                                const nextKey = fields.find(x => x.key !== field.key)?.key;
                                setTabKey(`guide-${nextKey}`);
                              }
                              remove(i);
                            }}
                          />
                        )}
                      </TourLockCpn>
                      <TourLockCpn isLocked={briefingLocked} lockedBy={briefingLockedBy}>
                        {briefingLocked ? (
                          <ReadOnlyGuideOtherInfo guide={tourGuide || {}} tour={tour} showGuideEvaluation={showGuideEvaluation} />
                        ) : (
                          <GuideOtherInfo fieldData={field} tourGuide={tourGuide} tour={tour} showGuideEvaluation={showGuideEvaluation} />
                        )}
                      </TourLockCpn>
                    </Tabs.TabPane>
                  );
                })}
              </Tabs>
            </div>
          );
        }}
      </Form.List>
    </Form>
  );
}

const TabName = ({ fieldData, locked }) => {
  return (
    <Form.Item
      shouldUpdate={(prev, current) => {
        try {
          return prev.tourGuides[fieldData.key]?.guide?.value !== current.tourGuides[fieldData.key]?.guide?.value;
        } catch (error) {
          return false;
        }
      }}
      noStyle
    >
      {({ getFieldValue }) => {
        return (
          <span style={{ textShadow: 'none', fontWeight: 'bold' }}>
            {getFieldValue(['tourGuides', fieldData.name, 'guide'])?.label || <Trans>Please select guide</Trans>}
            {locked ? <LockOutlined style={{ marginLeft: 5 }} className="tour-lock-icon" /> : null}
          </span>
        );
      }}
    </Form.Item>
  );
};
