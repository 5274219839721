import EnumSelect from '@/components/app/formItem/EnumSelect';
import NationalityInput from '@/components/app/formItem/NationalityInput';
import NTAStaffInput from '@/components/app/formItem/NTAStaffInput';
import DatePicker from '@/components/DatePicker';
import { ReservationForm, TourPurposeType, TourStatusType } from '@/config';
import { TourDetailModel } from '@/__generated';
import { Col, Row, Form, Input, InputNumber, Checkbox, FormInstance, Select } from 'antd';
import moment from 'moment';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getTotalPax, ICreateTourValidation } from '../validation';
import GuideListLabel from '../_readonlyComponents/GuideListTable';
import { TourSeisanStatus } from '@/constants';
import { useTourContext } from '@/contexts/TourContext';

type Props = {
  formValidation: ICreateTourValidation;
  isUpdate?: boolean;
  tour?: TourDetailModel;
  form: FormInstance<any>;
};
const gutter = 12;

const rowTitleStyle: any = {
  float: 'left',
  lineHeight: '32px',
  width: 100,
};
const ESTIMATED_PASSENGERS = [
  { label: 'Adult', name: 'noOfAdultEstimated' },
  { label: 'Child A', name: 'noOfChildAEstimated' },
  { label: 'Child B', name: 'noOfChildBEstimated' },
  { label: 'Infant', name: 'noOfInfantEstimated' },
  { label: 'T/L', name: 'noOfLeadEstimated' },
];
const BOOKED_PASSENGERS = [
  { label: 'Adult', name: 'noOfAdultBooked' },
  { label: 'Child A', name: 'noOfChildABooked' },
  { label: 'Child B', name: 'noOfChildBBooked' },
  { label: 'Infant', name: 'noOfInfantBooked' },
  { label: 'T/L', name: 'noOfLeadBooked' },
];
const requiredRule = {
  required: true,
  message: <Trans>VALIDATE_REQUIRED</Trans>,
};
export default function CreateTourModal({ formValidation, form }: Props) {
  const { t } = useTranslation();
  const { tour } = useTourContext();
  return (
    <div>
      {/* 1st */}
      <Row gutter={gutter}>
        <Col md={2} xs={6} className="form-item-label mobile-row form-item-required">
          {t('Tour duration')}
        </Col>
        <Col md={3} xs={9}>
          <Form.Item dependencies={['toDate']} noStyle>
            {({ getFieldValue }) => {
              const toDate = getFieldValue('toDate');
              return (
                <Form.Item rules={[requiredRule]} name="fromDate">
                  <DatePicker
                    className="w-100 input-value-color"
                    placeholder={t('tourSearchFrom')}
                    disabledDate={d => {
                      if (toDate) {
                        return d?.startOf('D') > moment(toDate).startOf('D');
                      }
                      return false;
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col md={3} xs={9}>
          <Form.Item dependencies={['fromDate']} noStyle>
            {({ getFieldValue }) => {
              const fromDate = getFieldValue('fromDate');
              return (
                <Form.Item rules={[requiredRule]} name="toDate">
                  <DatePicker
                    className="w-100 input-value-color"
                    placeholder={t('tourSearchTo')}
                    disabledDate={d => {
                      if (fromDate) {
                        return d?.startOf('D') < moment(fromDate).startOf('D');
                      }
                      return false;
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col md={2} xs={6} className="form-item-label mobile-row form-item-required">
          {t('Status')}
        </Col>
        <Col md={4} xs={18}>
          <Form.Item rules={[requiredRule]} name="status">
            <EnumSelect placeholder={t('Status')} enumData={TourStatusType} />
          </Form.Item>
        </Col>
        <Col md={2} xs={6} className="form-item-label mobile-row">
          {t('Reservation form')}
        </Col>
        <Col md={3} xs={18}>
          <Form.Item name={'reservationForm'}>
            <EnumSelect className="w-100" placeholder={t('Reservation form')} enumData={ReservationForm} />
          </Form.Item>
        </Col>
        <Col md={2} xs={6} className="form-item-label">
          {t('Travel purpose')}
        </Col>
        <Col md={3} xs={18}>
          <Form.Item name="tourPurpose">
            <EnumSelect className="w-100" placeholder={t('Travel purpose')} enumData={TourPurposeType} />
          </Form.Item>
        </Col>
      </Row>

      {/* 2nd */}
      <Row gutter={gutter}>
        <Col md={2} xs={6} className="form-item-label mobile-row form-item-required">
          {t('Tour Name')}
        </Col>
        <Col md={12} xs={18}>
          <Form.Item rules={[requiredRule]} name="tourName">
            <Input placeholder={t('Tour Name')} />
          </Form.Item>
        </Col>
        <Col md={3} xs={6} className="form-item-label">
          {t('tourDetail_TourNoTourSeisan')}
        </Col>
        <Col md={7} xs={18}>
          <Form.Item name="tourNumber">
            <Input className="input-value-color" />
          </Form.Item>
        </Col>
      </Row>
      {/* 3nd */}
      <Row gutter={gutter}>
        <Col md={2} xs={6} className="form-item-label mobile-row">
          {t('guideSerchnationality')}
        </Col>
        <Col md={6} xs={18}>
          <Form.Item rules={[formValidation.nationality]} name="guestNationalityCode">
            <NationalityInput allowClear placeholder={t('guideSerchnationality')} />
          </Form.Item>
        </Col>
        <Col md={2} xs={6} className="form-item-label ">
          {t('Agent')}
        </Col>
        <Col md={4} xs={18}>
          <Form.Item name="agentName">
            <Input placeholder={t('Agent')} />
          </Form.Item>
        </Col>
        <Col md={3} xs={6} className="form-item-label">
          {t('tourDetail_TourSeisanStatus')}
        </Col>
        <Col md={7} xs={18}>
          <Form.Item>
            <Select value={tour?.seisanStatus} options={TourSeisanStatus.__getSelectOptions()} disabled className="input-value-color" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={gutter}>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Form.Item name="saleStaffId">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="saleStaffName">
              <Input type="hidden" />
            </Form.Item>
            <Col md={6} xs={6} className="form-item-label mobile-row">
              {t('Sales')}
            </Col>
            <Col md={18} xs={18}>
              <Form.Item name="saleStaff">
                <NTAStaffInput
                  idValue={tour?.saleStaffId}
                  nameValue={tour?.saleStaffName}
                  placeholder={t('Sales')}
                  className="w-100"
                  form={form}
                  idField="saleStaffId"
                  nameField="saleStaffName"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={6} xs={24}>
          <Row gutter={gutter}>
            <Form.Item name="picId">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="picName">
              <Input type="hidden" />
            </Form.Item>
            <Col md={8} xs={6} className="form-item-label mobile-row">
              {t('In charge of tour')}
            </Col>
            <Col md={16} xs={18}>
              <Form.Item
                name="pic"
                rules={[
                  ({ getFieldValue }) => ({
                    validator() {
                      if (formValidation.pic?.required) {
                        const picId = getFieldValue('picId');
                        const picName = getFieldValue('picName');
                        if (picId || picName) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(formValidation.pic.message));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                dependencies={['picId', 'picName']}
              >
                <NTAStaffInput
                  idValue={tour?.picId}
                  nameValue={tour?.picName}
                  className="w-100"
                  allowClear
                  placeholder={t('In charge of tour')}
                  form={form}
                  idField="picId"
                  nameField="picName"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={3} xs={6} className="form-item-label">
          ツアーID
        </Col>
        <Col md={7} xs={18}>
          <Form.Item name="tourId">
            <Input placeholder="ツアーID" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={gutter}>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Form.Item name="arrangementPicId">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="arrangementPicName">
              <Input type="hidden" />
            </Form.Item>
            <Col md={6} xs={6} className="form-item-label mobile-row">
              {t('手配担当')}
            </Col>
            <Col md={18} xs={18}>
              <Form.Item
                name="arrangementPic"
                rules={[
                  ({ getFieldValue }) => ({
                    validator() {
                      if (formValidation.pic?.required) {
                        const picId = getFieldValue('arrangementPicId');
                        const picName = getFieldValue('arrangementPicName');
                        if (picId || picName) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(formValidation.pic.message));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                dependencies={['arrangementPicId', 'arrangementPicName']}
              >
                <NTAStaffInput
                  idValue={(tour as any)?.arrangementPicId}
                  nameValue={(tour as any)?.arrangementPicName}
                  className="w-100"
                  allowClear
                  placeholder={'手配担当'}
                  form={form}
                  idField="arrangementPicId"
                  nameField="arrangementPicName"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Row gutter={0} className="pax-container-5">
          <Col md={24}>
            <Row gutter={gutter} style={{ marginBottom: 5, borderBottom: '2px solid white', paddingBottom: 5 }}>
              <Col md={24}>
                <div style={rowTitleStyle}>{t('Number of Passengers (Estimated)')}:</div>
                <Row gutter={gutter}>
                  {ESTIMATED_PASSENGERS.map(est => {
                    return (
                      <Col md={4} key={est.name}>
                        <Row gutter={gutter} className="mobile-row">
                          <Col md={8} xs={8} className="form-item-label" style={{ width: 40 }}>
                            {t(est.label)}
                          </Col>
                          <Col md={16} xs={16}>
                            <Form.Item rules={[formValidation.noOfPassengerEst]} name={est.name}>
                              <InputNumber className="w-100" precision={0} min={0} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                  <Col md={4}>
                    <Row gutter={gutter} className="mobile-row">
                      <Col md={8} xs={8} className="form-item-label" style={{ width: 40 }}>
                        {t('Total')}
                      </Col>
                      <Col md={16} xs={16}>
                        <Form.Item
                          dependencies={[
                            'noOfAdultEstimated',
                            'noOfChildAEstimated',
                            'noOfChildBEstimated',
                            'noOfInfantEstimated',
                            'noOfLeadEstimated',
                          ]}
                        >
                          {({ getFieldValue }) => {
                            const fields = [
                              getFieldValue('noOfAdultEstimated'),
                              getFieldValue('noOfChildAEstimated'),
                              getFieldValue('noOfChildBEstimated'),
                              getFieldValue('noOfInfantEstimated'),
                              getFieldValue('noOfLeadEstimated'),
                            ];

                            return <Input disabled value={getTotalPax(fields)} className="input-value-color" />;
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={24}>
                <div style={rowTitleStyle}>{t('Number of Passenger')}:</div>
                <Row gutter={6}>
                  {BOOKED_PASSENGERS.map(est => {
                    return (
                      <Col md={4} key={est.name}>
                        <Row gutter={gutter} className="mobile-row">
                          <Col md={8} xs={8} className="form-item-label" style={{ width: 40 }}>
                            {t(est.label)}
                          </Col>
                          <Col md={16} xs={16}>
                            <Form.Item rules={[formValidation.noOfPassengerBooked]} name={est.name}>
                              <InputNumber className="w-100" precision={0} min={0} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                  <Col md={4}>
                    <Row gutter={gutter} className="mobile-row">
                      <Col md={8} xs={8} className="form-item-label" style={{ width: 40 }}>
                        {t('Total')}
                      </Col>
                      <Col md={16} xs={16}>
                        <Form.Item dependencies={['noOfAdultBooked', 'noOfChildABooked', 'noOfChildBBooked', 'noOfInfantBooked', 'noOfLeadBooked']}>
                          {({ getFieldValue }) => {
                            const fields = [
                              getFieldValue('noOfAdultBooked'),
                              getFieldValue('noOfChildABooked'),
                              getFieldValue('noOfChildBBooked'),
                              getFieldValue('noOfInfantBooked'),
                              getFieldValue('noOfLeadBooked'),
                            ];

                            return <Input disabled value={getTotalPax(fields)} className="input-value-color" />;
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Row>
        <Col md={18} xs={24}>
          <GuideListLabel />
        </Col>
        <Col md={6} xs={24} style={{ textAlign: 'right', marginTop: 10 }}>
          <Form.Item name="hideGuides" valuePropName="checked">
            <Checkbox>{t('hideOtherGuideInfo')} </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
