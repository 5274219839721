import React from 'react';
import { Spin } from 'antd';

// loading components from code split
// https://umijs.org/plugin/umi-plugin-react.html#dynamicimport
const PageLoading: React.FC = () => (
  <div style={{ display: 'flex', textAlign: 'center', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <Spin size="large" />
  </div>
);
export default PageLoading;
