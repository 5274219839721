import { unHttpClient } from '@/httpclient';
import { LanguageModel } from '@/__generated';
import { Select, SelectProps } from 'antd';
import React from 'react';

interface Props extends SelectProps<any> {}

export default function LanguageInput({ ...props }: Props) {
  const [countries, setCountries] = React.useState<LanguageModel[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    getCountries();
  }, []);

  const getCountries = () => {
    setLoading(true);
    unHttpClient
      .get('/get-languages', { params: { pageSize: 250 } })
      .then(res => {
        setCountries(res.data?.data || []);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Select
      {...props}
      labelInValue
      loading={loading}
      showSearch
      filterOption={(input, option) => {
        return (option?.children as string)?.toLowerCase().includes(input.toLowerCase());
      }}
    >
      {countries.map(c => {
        return (
          <Select.Option key={c.id} value={c.code || ''}>
            {c.name}
          </Select.Option>
        );
      })}
    </Select>
  );
}
