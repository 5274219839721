import { Moment } from 'moment';
import * as Sentry from '@sentry/react';

const accessTokenKey = 'accessToken';
const refreshTokenKey = 'nta-refreshtoken';

interface LoginInfo {
  token: string;
  expiredTime: Moment;
  userId: string;
}
class AuthService {
  clearOnLogout = () => {
    localStorage.removeItem(accessTokenKey);
    localStorage.removeItem(refreshTokenKey);
  };
  getAccessToken = () => {
    try {
      const info: LoginInfo | null = JSON.parse(localStorage.getItem(accessTokenKey) || '');
      return info?.token;
    } catch (error) {
      return null;
    }
  };
  getLoginInfo = () => {
    try {
      const info: LoginInfo | null = JSON.parse(localStorage.getItem(accessTokenKey) || '');
      return info;
    } catch (error) {
      return null;
    }
  };
  saveLoginInfo = (info: LoginInfo) => {
    localStorage.setItem(accessTokenKey, JSON.stringify(info));
  };

  saveRefreshToken = (token: string) => {
    localStorage.setItem(refreshTokenKey, token);
  };
  getRefreshToken = () => {
    return localStorage.getItem(refreshTokenKey);
  };
  logUserInfo2Sentry = (userId: string | undefined, email: string | undefined, username: string | undefined) => {
    Sentry.setUser({ id: userId, email: email, username: username });
  };
}

export default new AuthService();
