import React from 'react';
import SearchFormFullHdScreen from './SearchFormFullHdScreen';
import SearchFormMdScreen from './SearchFormMdScreen';

type Props = {
  onSearch: (filter?: any) => void;
  startDate: string;
};
const W = window.innerWidth;
export default function SearchForm({ onSearch, startDate }: Props) {
  return W > 1900 ? (
    <SearchFormFullHdScreen onSearch={onSearch} startDate={startDate} />
  ) : (
    <SearchFormMdScreen onSearch={onSearch} startDate={startDate} />
  );
}
