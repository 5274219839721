import PrefectureInput from '@/components/app/formItem/PrefectureInput';
import EnumSelect from '@/components/app/formItem/EnumSelect';
import LanguageInput from '@/components/app/formItem/LanguageInput';
import DatePicker from '@/components/DatePicker';
import { GuideRankType, GuideSupportedArea, GuideSupportedType } from '@/config';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, InputNumber } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GuideAutoComplete from '@/components/app/formItem/GuideSearchField';

type Props = {
  onSearch: (filter?: any) => void;
  startDate: string;
};

export default function SearchFormFullHdScreen({ onSearch, startDate }: Props) {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  React.useEffect(() => {
    if (form) {
      form.setFieldsValue({
        startDate: startDate,
      });
    }
  }, [startDate, form]);
  return (
    <div>
      <Form
        form={form}
        layout="horizontal"
        className="nta-guide-search-form"
        onFinish={v => onSearch(v)}
        onReset={() => {
          onSearch();
        }}
      >
        <div>
          <Form.Item noStyle name="guideId">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item noStyle name="guideName">
            <Input type="hidden" />
          </Form.Item>
          <div style={{ float: 'left', lineHeight: '32px', width: 120 }}>{t('guideSearch_startDate')}</div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Form.Item name="startDate">
              <DatePicker className="w-150px" allowClear={false} />
            </Form.Item>
            <div className="form-label">{t('guideSearch_rank')}</div>
            <Form.Item name="ranks">
              <EnumSelect style={{ width: 150 }} mode="multiple" placeholder={t('guideSearch_rank')} enumData={GuideRankType} />
            </Form.Item>
            <div className="form-label">{t('guideSearch_prefecture')}</div>
            <Form.Item name="prefectures">
              <PrefectureInput className="w-180px" placeholder={t('guideSearch_prefecture')} mode={'multiple'} />
            </Form.Item>
            <div className="form-label">{t('guideSearch_guideName')}</div>
            <Form.Item name="searchGuideField">
              <GuideAutoComplete isIncludeDisabledGuide={false} form={form} className="w-180px" placeholder={t('guideSearch_guideName')} />
            </Form.Item>
            <div className="form-label">{t('guideSearch_language')}</div>
            <Form.Item name="languages">
              <LanguageInput mode="multiple" className="w-180px" placeholder={t('guideSearch_language')} />
            </Form.Item>
            <div className="form-label">{t('guideSearch_maxNights')}</div>
            <Form.Item name="maximumNightAllowed">
              <InputNumber style={{ width: 80 }} />
            </Form.Item>

            <Button type="primary" htmlType="submit">
              <SearchOutlined style={{ color: '#333' }} />
              {t('zTable.search')}
            </Button>
            <Button
              type="ghost"
              onClick={() => {
                form.resetFields();
              }}
            >
              <CloseOutlined />
              {t('zTable.reset')}
            </Button>
          </div>
        </div>

        <div style={{ marginTop: 4, display: 'flex' }}>
          <div style={{ float: 'left', lineHeight: '32px', width: 112 }}>{t('guideSearch_types')}</div>
          <div style={{ flex: 1 }}>
            <Form.Item name="supportedTypes">
              <Checkbox.Group style={{ display: 'flex' }}>
                <div>
                  {[1, 2, 3, 4, 5, 6, 7].map(v => {
                    return (
                      <Checkbox key={v} value={v}>
                        {t(GuideSupportedType.__getValue(v))}
                      </Checkbox>
                    );
                  })}
                </div>
              </Checkbox.Group>
            </Form.Item>
          </div>

          <Form.Item name={'hasLicense'} valuePropName="checked">
            <Checkbox>{t('guideSearch_displayWithLicense')}</Checkbox>
          </Form.Item>
          <Form.Item name={'includeInActiveGuide'} valuePropName="checked" style={{ marginLeft: 10 }}>
            <Checkbox>{t('IncludeInactiveGuides')}</Checkbox>
          </Form.Item>
        </div>

        <div style={{ marginBottom: 5 }}>
          <div style={{ float: 'left', lineHeight: '32px', width: 112 }}>{t('guideSearch_AreasGuide')}</div>
          <Form.Item name="supportedAreas">
            <Checkbox.Group>
              <div style={{ display: 'flex' }}>
                {[1, 2, 3, 4, 6, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22].map(v => {
                  return (
                    <Checkbox value={v} key={v}>
                      {t(GuideSupportedArea.__getValue(v))}
                    </Checkbox>
                  );
                })}
              </div>
            </Checkbox.Group>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
