import EnumSelect from '@/components/app/formItem/EnumSelect';
import NTADatePicker from '@/components/DatePicker';
import { BriefingType, TourGuideStatusType, formatDisplayDate } from '@/config';
import { timeOnly2String, createNtaCommentsHtml } from '@/helpers/string';
import { TourGuideDetailModel } from '@/__generated';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Row, Col, Input, Form, Modal, InputNumber } from 'antd';
import { orderBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTourContext } from '@/contexts/TourContext';

type Props = { guide: TourGuideDetailModel };
const gutter = 8;
const labeCol = {
  md: 11,
  xs: 24,
};
const dateCol = {
  md: 8,
  xs: 14,
};
const timeCol = {
  md: 5,
  xs: 10,
};
export default function BriefingInput({ guide }: Props) {
  const { t } = useTranslation();
  const tourContext = useTourContext();

  const comments = React.useMemo(() => {
    if (guide?.ntaComments) {
      return orderBy(guide?.ntaComments, x => moment(x.createdAt), 'desc');
    }
    return [];
  }, [guide]);
  const isDisabledBriefing = React.useMemo(() => {
    return !!(guide.briefingDate && guide.briefingTime);
  }, [guide]);
  const amountCarrying = React.useMemo(() => {
    if (tourContext.tour && guide) {
      return tourContext.tour.tourGuides?.find(x => x.id === guide.id)?.seisanAmountCarrying || 0;
    }
    return 0;
  }, [tourContext.tour, guide]);

  const canEditNote = React.useMemo(() => {
    let readOnlyStatus = [TourGuideStatusType.FinalInforming, TourGuideStatusType.FinalChecking, TourGuideStatusType.Finalized];
    return !readOnlyStatus.includes(guide.status as any);
  }, [guide]);

  return (
    <div>
      {/* <Row gutter={6} style={{ marginBottom: 5, marginTop: 10 }}>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col md={11} xs={24} className="form-item-label mobile-row">
              {t('Briefing')}
            </Col>
            <Col md={13} xs={24}>
              <EnumSelect enumData={BriefingType} className="w-100 input-value-color" disabled value={guide.briefingType} />
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col {...labeCol} className="form-item-label mobile-row">
              {t('Briefing Date And Time')}
            </Col>
            <Col {...dateCol}>
              <Input className="w-100 input-value-color" disabled value={formatDisplayDate(guide.briefingDate)} />
            </Col>
            <Col {...timeCol}>
              <Input className="w-100 input-value-color" disabled value={timeOnly2String(guide.briefingTime) || ''} />
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col md={11} xs={24} className="form-item-label">
              {t('Expected Date Of Arrival Of Materials')}
            </Col>
            <Col md={13} xs={24}>
              <Input className="w-100 input-value-color" disabled value={formatDisplayDate(guide?.expectedDateOfMaterials)} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col {...labeCol} className="form-item-label">
              {t('Briefing desired day 1')}
            </Col>
            <Col {...dateCol}>
              <Form.Item name="desiredBriefingDate1">
                <NTADatePicker className="w-100 input-value-color" disabled={isDisabledBriefing} />
              </Form.Item>
            </Col>
            <Col {...timeCol}>
              <Form.Item name="desiredBriefingTime1">
                <Input disabled={isDisabledBriefing} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col {...labeCol} className="form-item-label">
              {t('Briefing desired date 2')}
            </Col>
            <Col {...dateCol}>
              <Form.Item name="desiredBriefingDate2">
                <NTADatePicker className="w-100 input-value-color" disabled={isDisabledBriefing} />
              </Form.Item>
            </Col>
            <Col {...timeCol}>
              <Form.Item name="desiredBriefingTime2">
                <Input disabled={isDisabledBriefing} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col {...labeCol} className="form-item-label">
              {t('Briefing desired date 3')}
            </Col>
            <Col {...dateCol}>
              <Form.Item name="desiredBriefingDate3">
                <NTADatePicker className="w-100 input-value-color" disabled={isDisabledBriefing} />
              </Form.Item>
            </Col>
            <Col {...timeCol}>
              <Form.Item name="desiredBriefingTime3">
                <Input disabled={isDisabledBriefing} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col {...labeCol} className="form-item-label">
              {t('Material arrival desired date and time 1')}
            </Col>
            <Col {...dateCol}>
              <Form.Item name="desiredDateOfMaterial1">
                <NTADatePicker className="w-100 input-value-color" disabled={!!guide.expectedDateOfMaterials} />
              </Form.Item>
            </Col>
            <Col {...timeCol}>
              <Form.Item name="desiredTimeOfMaterial1">
                <Input disabled={!!guide.expectedDateOfMaterials} className="w-100 input-value-color" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col {...labeCol} className="form-item-label">
              {t('Material arrival desired date and time 2')}
            </Col>
            <Col {...dateCol}>
              <Form.Item name="desiredDateOfMaterial2">
                <NTADatePicker className="w-100 input-value-color" disabled={!!guide.expectedDateOfMaterials} />
              </Form.Item>
            </Col>
            <Col {...timeCol}>
              <Form.Item name="desiredTimeOfMaterial2">
                <Input disabled={!!guide.expectedDateOfMaterials} className="w-100 input-value-color" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col {...labeCol} className="form-item-label">
              {t('Material arrival desired date and time 3')}
            </Col>
            <Col {...dateCol}>
              <Form.Item name="desiredDateOfMaterial3">
                <NTADatePicker className="w-100 input-value-color" disabled={!!guide.expectedDateOfMaterials} />
              </Form.Item>
            </Col>
            <Col {...timeCol}>
              <Form.Item name="desiredTimeOfMaterial3">
                <Input disabled={!!guide.expectedDateOfMaterials} className="w-100 input-value-color" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row> */}

      <Row gutter={gutter}>
        <Col flex={'175px'} xs={24}>
          {t('Contact Items From NTA')}
          {comments?.length ? (
            <div
              style={{ fontSize: 12, marginTop: 5 }}
              className="span-link"
              onClick={() => {
                Modal.info({
                  title: t('Contact Items From NTA'),
                  width: 700,
                  content: <div dangerouslySetInnerHTML={{ __html: createNtaCommentsHtml(comments) }} style={{ maxHeight: 600, overflow: 'auto' }} />,
                });
              }}
            >
              <InfoCircleOutlined style={{ marginRight: 5 }} />
              {t('Show previous comments')}
            </div>
          ) : null}
        </Col>
        <Col xs={24} flex="auto">
          <Input.TextArea disabled={true} value={comments?.length ? comments[0].comment : ''} className="input-value-color" rows={5} />
        </Col>
      </Row>
      <Row gutter={gutter} style={{ marginTop: 5 }}>
        <Col flex={'175px'} xs={24} className="form-item-label">
          {t('Notes for Guides')}
        </Col>
        <Col xs={24} flex="auto">
          <Form.Item name="guideComments">
            <Input.TextArea className="input-value-color" rows={5} disabled={!canEditNote} />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={gutter} style={{ marginTop: 5 }}>
        <Col flex={'175px'} xs={24} className="form-item-label">
          {t('tourDetail_TourSeisanAmountCarrying')}
        </Col>
        <Col xs={24} flex="auto">
          <InputNumber disabled={true} className="input-value-color" value={amountCarrying} />
        </Col>
      </Row> */}
    </div>
  );
}
