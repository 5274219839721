import i18n from '@/helpers/i18n';
import { ApiSdk } from '@/httpclient';
import { Modal } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import authService from './authService';

class LockService {
  createSessionId = () => {
    return uuidv4();
  };

  unlock = (entityId: string, sessionId: string) => {
    ApiSdk.LockService.unlock(
      {
        body: {
          entityId,
          sessionId,
        },
      },
      {
        headers: {
          'Keep-Alive': 'timeout=5, max=1000',
          Connection: 'Keep-Alive',
        },
      },
    ).catch(() => {
      // no handle
    });
  };

  unlockOnTabClose = (entityId: string, sessionId: string) => {
    const token = authService.getAccessToken();
    var params = new FormData();
    params.append('entityId', entityId);
    params.append('sessionId', sessionId);
    params.append('accessToken', token || '');
    navigator.sendBeacon(`${process.env.REACT_APP_API_ENDPOINT}/api/cms/basic-lock/unlock-closed-tab`, params);
  };

  onBrowserTabClose = (callbackFunction: () => void) => {
    window.addEventListener('beforeunload', callbackFunction);
    return () => {
      window.removeEventListener('beforeunload', callbackFunction);
    };
  };

  keepAlive = (entityId: string, sessionId: string) => {
    ApiSdk.LockService.keepSessionAlive({
      body: {
        sessionId: sessionId,
        entityId,
      },
    }).catch(() => {
      // no handle
    });
  };

  checkLockSessionAlive = (entityId: string, sessionId: string) => {
    ApiSdk.LockService.checkSessionAlive({
      body: { entityId: entityId, sessionId: sessionId },
    })
      .then(res => {
        if (!res) {
          Modal.error({
            title: i18n.t('EDIT_TOUR_SESSION_TIMEOUT_TITLE'),
            content: i18n.t('EDIT_TOUR_SESSION_TIMEOUT_CONTENT'),
            onOk: () => {
              window.location.reload();
            },
          });
        }
      })
      .catch(() => {});
  };
  onTabReActive = (callbackFunction: () => void) => {
    document.addEventListener('visibilitychange', callbackFunction);
    return () => {
      document.removeEventListener('visibilitychange', callbackFunction);
    };
  };
}

export default new LockService();
