import { Avatar, Popover } from 'antd';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './UserInfoStyle';
import AppAtag from '@/components/AppAtag';
import React from 'react';
import { useAppContext } from '@/contexts/AppContext';
import ChangePasswordModal from '@/components/GlobalHeader/changePassword';
import { useTranslation } from 'react-i18next';
import Notification from './Notification';
import ChangeRoleComponent from './ChangeRoles';
import { isGuideOnly } from '@/services/roleService';
import GuideProfile from '@/pages/Profile/GuideProfile';
import StaffProfile from '@/pages/Profile/StaffProfile';

const languages = [
  {
    lang: 'en',
    flag: 'https://i.pinimg.com/originals/ec/d5/58/ecd558ae00924bb3d8f3d0df891913e4.png',
    name: 'English',
  },
  {
    lang: 'ja',
    flag: 'https://cdn.countryflags.com/thumbs/japan/flag-button-round-250.png',
    name: 'Japanese',
  },
];

const LanguageDropdownContent = () => {
  const { i18n } = useTranslation();

  const [t] = useTranslation();
  const changeLanguage = (l: string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    i18n.changeLanguage(l);
  };

  return (
    <div>
      {languages.map(l => {
        return (
          <Link
            style={{ marginTop: 5, display: 'block' }}
            to="#"
            onClick={e => {
              changeLanguage(l.lang, e);
            }}
          >
            <img src={l.flag} alt={l.name} style={{ width: 30, marginRight: 5 }} />
            <span>{t(l.name)}</span>
          </Link>
        );
      })}
    </div>
  );
};
const AuthInfo = () => {
  const { i18n, t } = useTranslation();
  const [showUserMenu, toggleUserMenu] = React.useState<boolean>(false);
  const [showChangePass, toggleChangePass] = React.useState<boolean>(false);
  const [showUpdateUserProfile, toggleUpdateUserProfile] = React.useState<boolean>(false);
  const { theme, onUpdateTheme, auth, logout } = useAppContext();
  const SignOut = e => {
    e.preventDefault();
    logout();
  };

  const userContent = (
    <UserDropDwon>
      <div
        className="user-dropdwon"
        style={{ minWidth: 200 }}
        onClick={() => {
          toggleUserMenu(false);
        }}
      >
        <figure className="user-dropdwon__info">
          <figcaption>
            <h1>{auth?.fullname}</h1>
            <p>{auth?.email}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link
              to="#"
              onClick={e => {
                e.preventDefault();
                toggleUpdateUserProfile(true);
              }}
            >
              <FeatherIcon icon="user" /> {t('Profile')}
            </Link>
          </li>
          {window.innerWidth > 1200 ? (
            <li>
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault();
                  onUpdateTheme({ topMenu: !theme.topMenu });
                }}
              >
                <FeatherIcon icon="layout" /> {t('Change layout')}
              </Link>
            </li>
          ) : null}

          <li>
            <Link
              to="#"
              onClick={e => {
                e.preventDefault();
                toggleChangePass(true);
              }}
            >
              <FeatherIcon icon="lock" /> {t('Change password')}
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="/cms/login">
          <FeatherIcon icon="log-out" /> {t('SignOut')}
        </Link>
      </div>
    </UserDropDwon>
  );
  const currentLanguage = React.useMemo(() => {
    if (i18n.language === 'en') {
      return languages[0];
    }
    return languages[1];
  }, [i18n.language]);
  const isGuide = React.useMemo(() => {
    return isGuideOnly(auth?.roleClaims || []);
  }, [auth]);
  return (
    <InfoWraper>
      <Notification />
      <div className="nav-author">
        <div className="hide-on-mobile" style={{ display: 'none', alignItems: 'center', cursor: 'pointer', padding: '0 8px' }}>
          <Popover placement="bottomRight" content={<LanguageDropdownContent />}>
            <img src={currentLanguage.flag} alt="language" style={{ width: 32, height: 32 }} />
          </Popover>
        </div>
        <ChangeRoleComponent />
        <Popover onVisibleChange={v => toggleUserMenu(v)} trigger="click" visible={showUserMenu} placement="bottomRight" content={userContent}>
          <AppAtag
            onClick={() => {
              toggleUserMenu(true);
            }}
            className="head-example d-flex "
            style={{ alignItems: 'center', color: '#272B41', display: 'flex' }}
          >
            <Avatar src="https://cdn-icons-png.flaticon.com/512/149/149071.png" alt={auth?.fullname} />
            <span className="hide-on-mobile">{auth?.fullname}</span>
          </AppAtag>
        </Popover>
      </div>
      <ChangePasswordModal onClose={() => toggleChangePass(false)} visible={showChangePass} />
      {showUpdateUserProfile ? (
        isGuide ? (
          <GuideProfile
            visible={true}
            onClose={() => {
              toggleUpdateUserProfile(false);
            }}
          />
        ) : (
          <StaffProfile
            visible={true}
            onClose={() => {
              toggleUpdateUserProfile(false);
            }}
          />
        )
      ) : null}
    </InfoWraper>
  );
};

export default AuthInfo;
