import PrefectureInput from '@/components/app/formItem/PrefectureInput';
import EnumSelect from '@/components/app/formItem/EnumSelect';
import LanguageInput from '@/components/app/formItem/LanguageInput';
import DatePicker from '@/components/DatePicker';
import { GuideRankType, GuideSupportedArea, GuideSupportedType } from '@/config';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, ColProps, Form, Input, InputNumber, Row, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GuideAutoComplete from '@/components/app/formItem/GuideSearchField';

type Props = {
  onSearch: (filter?: any) => void;
  startDate: string;
};

const labelStyle: ColProps = {
  md: 12,
  xl: 10,
  xxl: 8,
};
export default function SearchFormMdScreen({ onSearch, startDate }: Props) {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  React.useEffect(() => {
    if (form) {
      form.setFieldsValue({
        startDate: startDate,
      });
    }
  }, [startDate, form]);
  return (
    <Form
      form={form}
      layout="horizontal"
      className="nta-custom-form"
      onFinish={v => onSearch(v)}
      colon={false}
      onReset={() => {
        onSearch();
      }}
    >
      <div>
        <Form.Item noStyle name="guideId">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item noStyle name="guideName">
          <Input type="hidden" />
        </Form.Item>
        <div style={{ float: 'left', lineHeight: '32px', width: 120 }}>{t('guideSearch_startDate')}</div>
        <Row gutter={6}>
          <Col md={5}>
            <Form.Item name="startDate">
              <DatePicker className="w-100" allowClear={false} />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item label={t('guideSearch_rank')} labelCol={labelStyle} name="ranks">
              <EnumSelect mode="multiple" placeholder={t('guideSearch_rank')} enumData={GuideRankType} />
            </Form.Item>
          </Col>
          <Col md={7}>
            <Form.Item label={t('guideSearch_prefecture')} labelCol={labelStyle} name="prefectures">
              <PrefectureInput placeholder={t('guideSearch_prefecture')} mode={'multiple'} />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Space align="end">
              <Button type="primary" htmlType="submit">
                <SearchOutlined style={{ color: '#333' }} />
                {t('zTable.search')}
              </Button>
              <Button
                type="ghost"
                onClick={() => {
                  form.resetFields();
                }}
              >
                <CloseOutlined />
                {t('zTable.reset')}
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
      <div>
        <div style={{ float: 'left', lineHeight: '32px', width: 120 }}>{t('guideSearch_guideName')}</div>
        <Row gutter={6}>
          <Col md={5}>
            <Form.Item name="searchGuideField">
              <GuideAutoComplete isIncludeDisabledGuide={false} form={form} placeholder={t('guideSearch_guideName')} />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item label={t('guideSearch_language')} labelCol={labelStyle} name="languages">
              <LanguageInput mode="multiple" placeholder={t('guideSearch_language')} />
            </Form.Item>
          </Col>
          <Col md={7}>
            <Form.Item label={t('guideSearch_maxNights')} labelCol={labelStyle} name="maximumNightAllowed">
              <InputNumber placeholder={t('guideSearch_maxNights')} className="w-100" />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item name={'hasLicense'} valuePropName="checked">
              <Checkbox>{t('guideSearch_displayWithLicense')}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div>
        <div style={{ float: 'left', lineHeight: '32px', width: 120 }}>{t('guideSearch_types')}</div>
        <Row gutter={0}>
          <Col md={18}>
            <Form.Item name="supportedTypes" className="mb-0">
              <Checkbox.Group>
                <Space>
                  {[1, 2, 3, 4, 5, 6, 7].map(v => {
                    return (
                      <Checkbox key={v} value={v}>
                        {t(GuideSupportedType.__getValue(v))}
                      </Checkbox>
                    );
                  })}
                </Space>
              </Checkbox.Group>
            </Form.Item>
          </Col>
          <Col md={6} style={{ paddingLeft: 3 }}>
            <Form.Item name={'includeInActiveGuide'} valuePropName="checked" className="mb-0">
              <Checkbox>{t('IncludeInactiveGuides')}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div>
        <div style={{ float: 'left', lineHeight: '32px', width: 120 }}>{t('guideSearch_AreasGuide')}</div>
        <Form.Item name="supportedAreas">
          <Checkbox.Group>
            <Row gutter={6}>
              <Col md={24} style={{ minHeight: 32, lineHeight: '32px' }}>
                {[1, 2, 3, 4, 6, 5, 7, 8, 9, 10, 11, 12, 13].map(v => {
                  return (
                    <Checkbox value={v} key={v}>
                      {t(GuideSupportedArea.__getValue(v))}
                    </Checkbox>
                  );
                })}
              </Col>
              <Col md={24}>
                {[14, 15, 16, 17, 18, 19, 20, 21, 22].map(v => {
                  return (
                    <Checkbox value={v} key={v}>
                      {t(GuideSupportedArea.__getValue(v))}
                    </Checkbox>
                  );
                })}
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </div>
    </Form>
  );
}
