/* eslint-disable react-hooks/exhaustive-deps */

import MainContent from '@/Layout/MainContent';
import { Button, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import SearchForm from './components/SearchForm';
import lodash from 'lodash';
import { ApiSdk } from '@/httpclient';
import { AvailableTour, DayGuideAvailability, GuideListingAvailabilityResponse, HolidayModel } from '@/__generated';
import { BooleanEnumType, GuideSupportedArea, TourAvailableAreaType, TourGuideStatusType, TourStatusType } from '@/config';
import { DEFAULT_DATE_FORMAT, MONTH_DAY_FORMAT } from '@/constants';
import DateItem from './components/DateItem';
import CreateTourModal from '../Tours/CreateTourModal';
import { PlusCircleOutlined } from '@ant-design/icons';
import TourInfoModal from '../Tours/TourInfoModal';
import GuideInfoModal from './GuideInfoModal';
import { useAppContext } from '@/contexts/AppContext';
import { hasPermissionCreateTour } from '@/services/roleService';
import GuideNameCell from './components/GuideNameCell';
import GuideLanguageCell from './components/GuideLanguageCell';
import { TablePaginationConfig } from 'antd/es/table/interface';

const PAGE_TITLE = 'ガイドの管理';

const PERIOD_DISPLAY = 30;
const PAGE_SIZE = 20;

let onMoving = false;
interface ITableDataSet {
  date: string;
  gid: string;
}

type GuideListingFilter = {
  startDate: string;
  guideIds?: string[];
  prefectures?: string[];
  languages?: string[];
  hasLicense?: boolean;
  ranks?: number[];
  maximumNightAllowed?: number;
  supportedTypes?: number[];
  supportedAreas?: number[];
  pageIndex: number;
  pageSize: number;
  orderBy?: string;
  orderByAsc?: boolean;
  enabled?: boolean;
};
const { Text } = Typography;

const getGuideFullName = (guide: GuideListingAvailabilityResponse | undefined) => {
  return [guide?.lastNameKanji, guide?.firstNameKanji].filter(x => x).join(' ');
};
interface IGuideDateDetail {
  [id: string]: { [date: string]: DayGuideAvailability } | null | undefined;
}
let startSelectedItem: { guideId: string; date: string } | null = null;
let endSelectedItem: { guideId: string; date: string } | null = null;

const isCtrlKey = (event: MouseEvent) => event.ctrlKey || event.metaKey;

const TourAreaInfo = () => {
  return (
    <div style={{ display: 'flex' }}>
      {[1, 2, 3, 4, 5, 6, 7].map(i => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
            <div style={{ width: 40, height: 30, borderRadius: 2, backgroundColor: TourAvailableAreaType.__getColor(i), marginRight: 2 }}></div>
            <div>{TourAvailableAreaType.__getText(i)}</div>
          </div>
        );
      })}
    </div>
  );
};
const DEFAULT_FILTER = {
  startDate: dayjs().format(DEFAULT_DATE_FORMAT),
  pageIndex: 1,
  pageSize: PAGE_SIZE,
  orderBy: 'lastNameKanji',
  orderByAsc: true,
  enabled: true,
};
export default function Guides() {
  const { auth } = useAppContext();
  const [t] = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [holidayLoading, setHolidayLoading] = React.useState<boolean>(false);

  const [guideInfoId, setGuideInfoId] = React.useState<string>();

  const [originHolidays, setOriginHolidays] = React.useState<HolidayModel[]>([]);
  const [guideListing, setGuideListing] = React.useState<GuideListingAvailabilityResponse[]>([]);
  const [guideDateDetail, setGuideDateDetail] = React.useState<IGuideDateDetail>({});

  const [selectedItems, setSelectedItems] = React.useState<{ guideId: string; date: string }[]>([]);
  const [displayCreateTour, toggleCreateTourModal] = React.useState<boolean>(false);
  const [tourInfoId, setTourInfoId] = React.useState<string>();
  const [total, setTotal] = React.useState<number>(0);
  const [filter, setFilter] = React.useState<GuideListingFilter>(DEFAULT_FILTER);

  const year = React.useMemo(() => {
    return dayjs(filter.startDate).year();
  }, [filter.startDate]);
  React.useEffect(() => {
    setHolidayLoading(true);
    ApiSdk.HolidayService.getAllHolidays({ year: year })
      .then(res => {
        setOriginHolidays(res);
      })
      .finally(() => {
        setTimeout(() => {
          setHolidayLoading(false);
        });
      });
  }, [year]);

  const holidays = React.useMemo(() => {
    const data: { [key: string]: HolidayModel } = {};
    originHolidays.forEach(holiday => {
      data[dayjs(holiday.date).format(DEFAULT_DATE_FORMAT)] = holiday;
    });
    return data;
  }, [originHolidays]);
  const { columns, W, H } = React.useMemo(() => {
    if (holidayLoading || loading) {
      return {
        columns: [],
        W: 0,
        H: undefined,
      } as any;
    }
    const _columns: ColumnsType<GuideListingAvailabilityResponse> = [
      {
        title: 'No.',
        width: 45,
        fixed: 'left',
        align: 'center',
        render: (v, r, i) => {
          return <b>{i + 1}</b>;
        },
      },
      {
        title: t('Guide Name'),
        width: 280,
        fixed: 'left',
        dataIndex: 'lastNameKanji',

        render: (v, guide) => {
          const name = getGuideFullName(guide);
          return (
            <GuideNameCell
              gender={guide.gender}
              id={guide.id}
              maxNumberOfNight={guide.maxNumberOfNight}
              name={name}
              dateOfBirth={guide.dateOfBirth}
              onGuideNameClick={id => {
                setGuideInfoId(id);
              }}
              rank={guide.rank}
            />
          );
        },
      },
      {
        title: t('Language'),
        ellipsis: true,
        width: 120,
        fixed: 'left',
        render: (v, guide) => <GuideLanguageCell guide={guide} />,
      },
      {
        title: t('Prefectures'),
        width: 150,
        fixed: 'left',
        dataIndex: 'prefecture',
        ellipsis: true,
        render: v => t(v),
      },
      {
        title: t('Area'),
        width: 150,
        fixed: 'left',
        render: (v, guide) => {
          if (guide.supportedAreas?.length) {
            const areaText = guide.supportedAreas?.map(area => t(GuideSupportedArea.__getValue(area)));
            return (
              <Text style={{ width: 130 }} ellipsis={{ tooltip: <span dangerouslySetInnerHTML={{ __html: areaText.join('<br/>') }} /> }}>
                {areaText.join(', ')}
              </Text>
            );
          }
          return '';
        },
      },
    ];
    for (let i = 0; i < PERIOD_DISPLAY; i++) {
      const colDate = dayjs(filter.startDate).add(i, 'day');
      _columns.push({
        title: (
          <div>
            {colDate.format(MONTH_DAY_FORMAT)}
            <div style={{ textAlign: 'center' }}>{colDate.format('ddd')}</div>
          </div>
        ),
        width: 55,
        dataIndex: `day${i}`,
        align: 'center',
        shouldCellUpdate: () => false,
        render: (v, guide) => {
          let dateInfo: DayGuideAvailability | null = null;
          const guideDetail = guideDateDetail[guide?.id || ''];
          const dateFormatted = colDate.format(DEFAULT_DATE_FORMAT);
          if (guideDetail) {
            dateInfo = guideDetail[dateFormatted];
          }
          const className: string[] = [''];
          const holiday = holidays[dateFormatted];
          if (holiday) {
            className.push('holiday');
          } else if (colDate.day() === 0) {
            className.push('sunday');
          } else if (colDate.day() === 6) {
            className.push('saturday');
          }
          let styles: React.CSSProperties = {};
          const availableTours: AvailableTour[] | undefined = guide.availableTours?.filter(tour =>
            tour.tourGuides?.some(tourGuide => {
              return tourGuide.businessDays?.some(day => {
                return dayjs(day).format(DEFAULT_DATE_FORMAT) === dateFormatted;
              });
            }),
          );

          if (availableTours?.length) {
            let firstTour = availableTours[0];
            if (firstTour.tourStatus !== TourStatusType.Cancelled && firstTour.tourGuides?.some(x => x.status === TourGuideStatusType.Unassigned)) {
              styles = { backgroundColor: TourAvailableAreaType.__getTourUnCancelledAndGuideUnassignedColor };
            } else {
              styles = { backgroundColor: TourAvailableAreaType.__getColor(firstTour.area) };
            }
          } else if (dateInfo?.isUnavailable || dateInfo?.isWfAnotherDepartment || dateInfo?.stayLastNight || dateInfo?.stayOvernight) {
            className.push('gray-out');
          }
          return {
            children: availableTours?.length ? (
              <Tooltip
                trigger={'hover'}
                title={
                  <ul>
                    {availableTours.map(tour => {
                      return (
                        <li
                          key={tour.id}
                          style={{ color: 'white', cursor: 'pointer' }}
                          onClick={() => {
                            setTourInfoId(tour.id);
                          }}
                        >
                          {tour.name}
                        </li>
                      );
                    })}
                  </ul>
                }
              >
                <div data-gid={guide.id} data-date={dateFormatted} style={{ minWidth: 30, minHeight: 20 }}></div>
              </Tooltip>
            ) : (
              <DateItem date={dateInfo} availableTours={availableTours} />
            ),

            props: {
              'data-gid': guide.id,
              'data-date': dateFormatted,
              className: className.join(' '),
              style: styles,
            },
          };
        },
      });
    }
    return {
      columns: _columns,
      W: lodash.sumBy(_columns, t => t.width as any),
      H: window.innerWidth > 1900 ? 575 : undefined,
    };
  }, [selectedItems, filter.startDate, guideDateDetail, holidays, loading, holidayLoading]);

  const getData = () => {
    setLoading(true);
    ApiSdk.GuideAvailabilityService.getGuideListingAvailabilities(filter as any)
      .then(res => {
        const dateDetail: IGuideDateDetail = {};
        res.data?.forEach(guide => {
          const daysDetail = {};
          guide.days?.forEach(day => {
            daysDetail[dayjs(day.date).format(DEFAULT_DATE_FORMAT)] = day;
          });
          dateDetail[guide?.id || ''] = daysDetail;
        });
        setGuideListing(res.data || []);
        setGuideDateDetail(dateDetail);
        setTotal(res.total || 0);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 100);
      });
  };

  React.useEffect(() => {
    getData();
  }, [filter]);

  const handleMouseDown = (event: MouseEvent) => {
    if (!isCtrlKey(event)) {
      onMoving = true;
      setSelectedItems([]);

      const dataset: ITableDataSet = (event.target as any)?.dataset;
      if (dataset?.gid && dataset?.date) {
        startSelectedItem = {
          date: dataset.date,
          guideId: dataset.gid,
        };
      }
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (onMoving && startSelectedItem) {
      const dataset: ITableDataSet = (event.target as any)?.dataset;
      if (dataset?.gid && dataset?.date && startSelectedItem?.guideId === dataset.gid && dataset.date !== startSelectedItem?.date) {
        endSelectedItem = {
          date: dataset.date,
          guideId: dataset.gid,
        };
        renderSelectedCell();
      }
    }
  };

  const handleMouseUp = (event: MouseEvent) => {
    if (onMoving) {
      const tableElement = document.getElementById('guide-table');
      if (tableElement) {
        tableElement.querySelectorAll('.ant-table-cell.selected').forEach(x => x.classList.remove('selected'));
      }

      const dataset: ITableDataSet = (event.target as any)?.dataset;
      if (dataset?.gid && dataset?.date && dataset.gid === startSelectedItem?.guideId && dataset.date !== startSelectedItem.date) {
        const startDate = lodash.min([dayjs(startSelectedItem.date), dayjs(dataset?.date)]);
        const endDate = lodash.max([dayjs(startSelectedItem.date), dayjs(dataset?.date)]);
        if (startDate && endDate) {
          const items: { guideId: string; date: string }[] = [];
          for (let i = 0; startDate?.clone().add(i, 'day') <= endDate; i++) {
            const currentDate = startDate
              ?.clone()
              .add(i, 'day')
              .format(DEFAULT_DATE_FORMAT);

            items.push({
              date: currentDate,
              guideId: dataset.gid,
            });
          }
          setSelectedItems(items);
        }
      } else {
        setSelectedItems([]);
      }
      onMoving = false;
      startSelectedItem = null;
      endSelectedItem = null;
    }
  };
  const handleClick = (event: MouseEvent) => {
    if (isCtrlKey(event)) {
      const dataset: ITableDataSet = (event.target as any)?.dataset;
      if (dataset?.gid && dataset?.date) {
        setSelectedItems(f => {
          if (f.some(x => x.date === dataset.date && x.guideId === dataset?.gid)) {
            return f.filter(x => !(x.date === dataset.date && x.guideId === dataset?.gid));
          }
          return [
            ...f.filter(x => x.guideId === dataset.gid),
            {
              date: dataset?.date,
              guideId: dataset.gid,
            },
          ];
        });
      }
    }
  };
  const renderSelectedCell = () => {
    const tableElement = document.getElementById('guide-table');
    if (!tableElement) {
      return;
    }
    tableElement.querySelectorAll('.ant-table-cell.selected').forEach(x => x.classList.remove('selected'));
    if (startSelectedItem && endSelectedItem) {
      const startDate = lodash.min([dayjs(startSelectedItem.date), dayjs(endSelectedItem.date)]);
      const endDate = lodash.max([dayjs(startSelectedItem.date), dayjs(endSelectedItem.date)]);
      if (startDate && endDate) {
        for (let i = 0; startDate?.clone().add(i, 'day') <= endDate; i++) {
          const currentDate = startDate
            ?.clone()
            .add(i, 'day')
            .format(DEFAULT_DATE_FORMAT);

          tableElement.querySelector(`[data-gid="${startSelectedItem.guideId}"][data-date="${currentDate}"]`)?.classList.add('selected');
        }
      }
    }
  };
  React.useEffect(() => {
    const tableElement = document.getElementById('guide-table');
    if (!tableElement) {
      return;
    }
    tableElement.querySelectorAll('.ant-table-cell.selected').forEach(x => x.classList.remove('selected'));
    if (selectedItems?.length) {
      selectedItems.forEach(item => {
        tableElement.querySelector(`[data-gid="${item.guideId}"][data-date="${item.date}"]`)?.classList.add('selected');
      });
    }
  }, [selectedItems]);
  React.useLayoutEffect(() => {
    const tableElement = document.getElementById('guide-table');
    if (tableElement) {
      tableElement.addEventListener('mousedown', handleMouseDown);
      tableElement.addEventListener('mousemove', handleMouseMove);
      tableElement.addEventListener('mouseup', handleMouseUp);
      tableElement.addEventListener('click', handleClick);
    }

    return () => {
      tableElement?.removeEventListener('mousedown', handleMouseDown);
      tableElement?.removeEventListener('mousemove', handleMouseMove);
      tableElement?.removeEventListener('mouseup', handleMouseUp);
      tableElement?.removeEventListener('click', handleClick);
    };
  }, []);
  const onMouseEnter = (evt: KeyboardEvent) => {
    if (selectedItems?.length && evt.key === 'Enter') {
      toggleCreateTourModal(true);
    }
  };
  React.useEffect(() => {
    if (selectedItems.length) {
      document.addEventListener('keypress', onMouseEnter);
    } else {
      document.removeEventListener('keypress', onMouseEnter);
    }
    return () => {
      document.removeEventListener('keypress', onMouseEnter);
    };
  }, [selectedItems]);

  const onFilterChange = (v?: any) => {
    let { includeInActiveGuide } = v;
    setFilter({
      ...DEFAULT_FILTER,
      ...v,
      enabled: includeInActiveGuide ? undefined : true,
      startDate: dayjs(v?.startDate || dayjs()).format(DEFAULT_DATE_FORMAT),
      guideIds: v?.guideIds?.map(e => e.value),
      languages: v?.languages?.map(e => e.value),
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    });
  };

  const enableCreateTour = React.useMemo(() => {
    return hasPermissionCreateTour(auth);
  }, [auth]);
  const pagination = React.useMemo(() => {
    return {
      pageSize: PAGE_SIZE,
      current: filter.pageIndex,
      total: total,
      onChange: p => setFilter(f => ({ ...f, pageIndex: p })),
      hideOnSinglePage: true,
      showSizeChanger: false,
      style: { margin: '5px 0' },
    } as TablePaginationConfig;
  }, [filter, total]);
  const extraBtns = React.useMemo(() => {
    if (enableCreateTour) {
      return [
        <TourAreaInfo />,
        <Button
          disabled={!selectedItems.length}
          key="create"
          className="nta-create-btn"
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            toggleCreateTourModal(true);
          }}
        >
          {t('Create Tour')}
        </Button>,
      ];
    }
    return <TourAreaInfo />;
  }, [enableCreateTour, selectedItems]);

  const table = React.useMemo(() => {
    return (
      <Table<GuideListingAvailabilityResponse>
        loading={loading || holidayLoading}
        dataSource={guideListing}
        scroll={{ x: W, y: H }}
        pagination={pagination}
        columns={columns}
        rowKey="id"
        size="small"
        bordered
        id="guide-table"
        rowClassName={record => (record?.enabled ? '' : 'in-active')}
      />
    );
  }, [columns, W, loading, holidayLoading, guideListing, pagination]);
  return (
    <MainContent title={t(PAGE_TITLE)} extraBtns={extraBtns}>
      <Helmet title={t(PAGE_TITLE)} />
      <div className="ant-layout-content-body nta-guide-listing">
        <SearchForm startDate={filter.startDate} onSearch={onFilterChange} />
        {table}
      </div>
      {displayCreateTour && selectedItems.length ? (
        <CreateTourModal
          visible={displayCreateTour}
          onClose={reload => {
            toggleCreateTourModal(false);
            if (selectedItems.length) {
              setSelectedItems([]);
            }

            if (reload) {
              getData();
            }
          }}
          intValues={{
            hideGuides: true,
            status: TourStatusType.Confirming,
            fromDate: selectedItems.length ? selectedItems[0].date : null,
            toDate: selectedItems.length ? selectedItems[selectedItems.length - 1].date : null,
            tourGuides: [
              {
                guide: {
                  value: selectedItems[0].guideId,
                  label: getGuideFullName(guideListing.find(x => x.id === selectedItems[0].guideId)),
                },
                status: TourGuideStatusType.Unassigned,
                businessDays: selectedItems?.map(item => {
                  return {
                    date: item.date,
                    availability: BooleanEnumType.True,
                    stayOvernight: BooleanEnumType.False,
                    stayLastNight: BooleanEnumType.False,
                    fee: 0,
                  };
                }),
              },
            ],
          }}
        />
      ) : null}
      {tourInfoId && <TourInfoModal tourId={tourInfoId} onClose={() => setTourInfoId(undefined)} visible={!!tourInfoId} />}
      {guideInfoId && <GuideInfoModal visible={!!guideInfoId} gid={guideInfoId} onClose={() => setGuideInfoId(undefined)} />}
    </MainContent>
  );
}

Guides.whyDidYouRender = true;
