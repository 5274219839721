import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Modal, Row, Input, Col, Select, Checkbox } from 'antd';
import { PlusCircleOutlined, UserOutlined, SaveOutlined } from '@ant-design/icons';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ZTable, { IFilterItem, ITableRef } from '@/components/ZTable';
import { DepartmentModel } from '@/__generated';
import MainContent from '@/Layout/MainContent';
import { ApiSdk } from '../../httpclient';
import { FullDateWithoutSecondFormat, VALIDATE_REQUIRED } from '../../constants';
import './style.less';

type Props = {};

export default function SubDivisionSearch({}: Props) {
  const [t] = useTranslation();
  const zTb = React.useRef<ITableRef>();
  const [formVisible, setFormVisible] = useState(false);
  const [isNewForm, setIsNewForm] = useState(false);
  const [departments, setDepartments] = React.useState<DepartmentModel[]>([]);
  const [form] = Form.useForm();

  const getDepartments = useCallback(async () => {
    const res = await ApiSdk.DepartmentService.getDepartments({ pageSize: 500 });
    setDepartments(res.data || []);
  }, []);

  useEffect(() => {
    getDepartments();
  }, []);

  const filters: Array<IFilterItem> = React.useMemo(() => {
    return [{ type: 'TEXT', name: 'subdivisionName', label: 'staffSearch_subdivision', mdSize: 8, xlSize: 8, xxlSize: 8 }];
  }, []);

  const columns = [
    {
      title: 'No.',
      width: 50,
      align: 'center',
      render: (v, r, i) => {
        return <b>{i + 1}</b>;
      },
    },
    { title: t('staffSearch_subdivision'), dataIndex: 'subdivisionName', defaultSortOrder: 'ascend', sorter: true },
    {
      title: t('staffSearch_department'),
      width: 200,
      fixed: 'left',
      dataIndex: 'departmentId',
      render: (value: any) => {
        const departmentFind = departments.find(department => department.id === value);
        return value && departmentFind ? departmentFind.departmentName : '';
      },
    },
    {
      title: '作成日時',
      width: 200,
      fixed: 'left',
      dataIndex: 'createdAt',
      render: (value: any) => {
        return value ? moment(value).format(FullDateWithoutSecondFormat) : '';
      },
    },
    {
      title: t('staffSearch_lastUpdatedDate'),
      width: 200,
      sorter: true,
      dataIndex: 'updatedAt',
      render: (value: any) => {
        return value ? moment(value).format(FullDateWithoutSecondFormat) : '';
      },
    },
    {
      title: t('staffSearch_lastUpdatedBy'),
      width: 200,
      sorter: true,
      dataIndex: 'updatedBy',
    },
  ];

  const updateTable = response => {
    if (response && response.success) {
      setFormVisible(false);
      zTb.current?.reload();
    } else {
      if (response.message) {
        alert(response.message);
      }
    }
  };

  const saveSubdivision = async values => {
    try {
      if (isNewForm) {
        const response = await ApiSdk.SubdivisionService.create({ body: values });
        updateTable(response);
      } else {
        const response = await ApiSdk.SubdivisionService.update({ body: values });
        updateTable(response);
      }
    } catch (error) {
      alert(error);
    }
  };

  const getExtraButtons = () => [
    <Button
      key="create"
      type="primary"
      className="nta-create-btn"
      icon={<PlusCircleOutlined />}
      onClick={() => {
        form.resetFields();
        setIsNewForm(true);
        setFormVisible(true);
      }}
    >
      {t('staffSearch_new')}
    </Button>,
  ];

  return (
    <MainContent title={t('staffSearch_subdivision_title')} extraBtns={getExtraButtons()} icon={<UserOutlined style={{ fontSize: 16 }} />}>
      <Helmet title={t('staffSearch_subdivision_title')} />
      <div className="ant-layout-content-body">
        <ZTable<any>
          columns={columns}
          primaryKey="id"
          tableRef={zTb}
          url="/subdivisions/get-subdivisions"
          bordered
          order_by="subdivisionName"
          order_by_asc={true}
          allowEdit={true}
          onEdit={record => {
            setIsNewForm(false);
            setFormVisible(true);
            form.setFieldsValue(record);
          }}
          allowDelete={false}
          filters={filters}
          layoutFilter={'horizontal'}
        />

        <Modal
          className="nta-custom-form"
          visible={formVisible}
          maskClosable={false}
          width={600}
          title={'課登録'}
          forceRender={true}
          okText={t('staffSearch_save')}
          okButtonProps={{ icon: <SaveOutlined />, className: 'save-btn' }}
          onCancel={() => {
            form.resetFields();
            setFormVisible(false);
          }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                saveSubdivision(values);
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Form form={form} size="middle" layout="vertical">
            <Form.Item name="id" className="hidden">
              <Input type="hidden" />
            </Form.Item>
            <Row gutter={16}>
              <Col md={24}>
                <Form.Item label={t('staffSearch_department')} name="departmentId" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Select>
                    {departments.map(department => (
                      <Select.Option key={department.id} value={department.id || ''}>
                        {department.departmentName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item label={t('staffSearch_subdivision')} name="subdivisionName" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item noStyle dependencies={['addGroupEmailToCc']}>
                  {({ getFieldValue }) => {
                    let isRequired = getFieldValue('addGroupEmailToCc');
                    return (
                      <Form.Item
                        label="グループメールアドレス"
                        name="groupEmailAddress"
                        rules={[{ required: isRequired, message: VALIDATE_REQUIRED }]}
                      >
                        <Input />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item name="addGroupEmailToCc" valuePropName="checked">
                  <Checkbox>グループアドレスをメールのccに追加する </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}></Row>
          </Form>
        </Modal>
      </div>
    </MainContent>
  );
}
