import React from 'react';
import { FacilityCategoryType, formatDisplayDate, formatFullDate, TourAvailableAreaType } from '@/config';
import { TourEvaluationDto } from '@/__generated';
import { ColumnsType } from 'antd/lib/table';
import { EditOutlined, DeleteOutlined, StarOutlined, StarFilled } from '@ant-design/icons';
import { Space, Tooltip, Button } from 'antd';
import { render } from '@testing-library/react';

export const getTourGuideOnlyCols = (t: any, onEdit: (r: any) => void) => {
  if (window.innerWidth < 700) {
    return [
      {
        title: <div style={{ textAlign: 'center' }}>ツアー詳細</div>,
        width: 100,
        shouldCellUpdate: () => false,
        render: (record: any) => {
          let area = TourAvailableAreaType.__getText(record.area) || '';
          const detail = record.hasOwnProperty('tourEvaluationDetail') ? record.tourEvaluationDetail : record.details;
          const isFacilityObject = !record.hasOwnProperty('tourEvaluationDetail');
          return (
            <div onClick={() => onEdit(record)} style={isFacilityObject ? { display: 'flex', flexDirection: 'column' } : {}}>
              {record.tourFromDate ? (
                <>
                  <div>{record.tourName}</div>
                  <div>
                    {`${t('tourEvaluation_nationality')}:${record.nationality || ''}`}
                    &nbsp;&nbsp;&nbsp;
                    {`${t('tourEvaluation_area')}:`}
                    {area}
                    &nbsp;&nbsp;&nbsp;
                    {`${t('tourEvaluation_agentName')}:${record.agentName || ''}`}
                  </div>
                </>
              ) : null}
              <div>{`${t('tourEvaluation_guideName')}:${record.guideName}`}</div>
              {record.useDate ? (
                <div>
                  {`${t('tourEvaluation_useDate')}:${formatDisplayDate(record.useDate)}`}
                  &nbsp;&nbsp;&nbsp;
                  {`${t('tourEvaluation_type')}:${t(FacilityCategoryType.__getValue(record.category))}`}
                  &nbsp;&nbsp;&nbsp;
                  {`${t('tourEvaluation_rating')}:${record.comprehensiveRate}`}
                </div>
              ) : null}
              <div>{detail}</div>
            </div>
          );
        },
      },
    ] as ColumnsType<any>;
  }
  const cols: ColumnsType<TourEvaluationDto> = [
    {
      width: 60,
      fixed: 'left',
      title: t('No.'),
      dataIndex: 'index',
      shouldCellUpdate: () => false,
      render: v => <b>{v}</b>,
      align: 'center',
    },
    {
      width: 180,
      title: t('Tour Name'),
      dataIndex: 'tourName',
      ellipsis: {
        showTitle: false,
      },
      render: v => <Tooltip title={v}>{v}</Tooltip>,
    },
    {
      width: 120,
      title: t('tourEvaluation_TourArrival'),
      dataIndex: 'tourFromDate',
      render: v => formatDisplayDate(v),
    },
    {
      width: 120,
      title: t('tourEvaluation_TourDeparture'),
      dataIndex: 'tourToDate',
      render: v => {
        return formatDisplayDate(v);
      },
      ellipsis: true,
    },
    {
      width: 100,
      title: t('tourEvaluation_nationality'),
      dataIndex: 'nationality',
      ellipsis: {
        showTitle: false,
      },
      render: v => <Tooltip title={v}>{v}</Tooltip>,
    },
    {
      width: 100,
      title: t('tourEvaluation_area'),
      dataIndex: 'area',
      ellipsis: {
        showTitle: false,
      },
      render: v => {
        let area = TourAvailableAreaType.__getText(v);
        return <Tooltip title={area}>{area}</Tooltip>;
      },
    },
    {
      width: 100,
      title: t('tourEvaluation_agentName'),
      dataIndex: 'agentName',
      ellipsis: {
        showTitle: false,
      },
      render: v => <Tooltip title={v}>{v}</Tooltip>,
    },
    {
      width: 100,
      title: t('tourEvaluation_guideName'),
      dataIndex: 'guideName',
      ellipsis: {
        showTitle: false,
      },
      render: v => <Tooltip title={v}>{v}</Tooltip>,
    },
    {
      width: 120,
      title: t('tourEvaluation_useDate'),
      dataIndex: 'useDate',
      render: v => (v ? formatDisplayDate(v) : null),
    },
    {
      width: 60,
      title: t('tourEvaluation_type'),
      dataIndex: 'category',
      render: v => (v ? t(FacilityCategoryType.__getValue(v)) : null),
    },
    {
      width: 120,
      title: t('facilityReviewFacilityName'),
      dataIndex: 'facilityName',
      ellipsis: {
        showTitle: false,
      },
      render: v => <Tooltip title={v}>{v}</Tooltip>,
    },
    {
      width: 80,
      title: t('tourEvaluation_rating'),
      dataIndex: 'comprehensiveRate',
      align: 'center',
      render: (v, record) => {
        let rate = record.tourComprehensiveRate != null ? record.tourComprehensiveRate : v;
        return rate ? (
          <b>
            {rate}
            <StarFilled style={{ fontSize: 14 }} />
          </b>
        ) : null;
      },
    },
    {
      width: 120,
      title: t('facilityReviewDetail'),
      ellipsis: {
        showTitle: false,
      },
      render: v => {
        const detail = v.hasOwnProperty('tourEvaluationImpression') ? v.tourEvaluationImpression : v.details;
        return <Tooltip title={detail}>{detail}</Tooltip>;
      },
    },
    {
      width: 140,
      title: t('tourEvaluation_LastModifiedDate'),
      dataIndex: 'lastUpdatedAt',
      render: v => formatFullDate(v),
    },
    {
      title: t('zTable.actions'),
      dataIndex: '_actions',
      width: 70,
      fixed: 'right',
      align: 'center',
      render(_: any, record: any) {
        return (
          <Tooltip title={t('zTable.edit')}>
            <Button
              icon={<EditOutlined />}
              style={{ backgroundColor: '#d6eaf8', borderRadius: 0 }}
              type="ghost"
              size="small"
              onClick={() => {
                onEdit(record);
              }}
            ></Button>
          </Tooltip>
        );
      },
    },
  ];
  return cols;
};

export const getFacilityOnlyCols = (t: any, onEdit: (r: any) => void) => {
  if (window.innerWidth < 700) {
    return [
      {
        title: <div style={{ textAlign: 'center' }}>ツアー詳細</div>,
        width: 100,
        shouldCellUpdate: () => false,
        render: (record: TourEvaluationDto) => {
          let area = TourAvailableAreaType.__getText(record.area) || '';
          const isFacilityObject = !record.hasOwnProperty('tourEvaluationDetail');
          return (
            <div onClick={() => onEdit(record)} style={isFacilityObject ? { display: 'flex', flexDirection: 'column' } : {}}>
              {record.tourFromDate ? (
                <>
                  <div>{record.tourName}</div>
                  <div>
                    {`${t('tourEvaluation_nationality')}:${record.nationality || ''}`}
                    &nbsp;&nbsp;&nbsp;
                    {`${t('tourEvaluation_area')}:`}
                    {area}
                    &nbsp;&nbsp;&nbsp;
                    {`${t('tourEvaluation_agentName')}:${record.agentName || ''}`}
                  </div>
                </>
              ) : null}
              <div>{`${t('tourEvaluation_guideName')}:${record.guideName}`}</div>
              {record.facilityEvaluation?.useDate ? (
                <div>
                  {`${t('tourEvaluation_useDate')}:${formatDisplayDate(record.facilityEvaluation?.useDate)}`}
                  &nbsp;&nbsp;&nbsp;
                  {`${t('tourEvaluation_type')}:${t(FacilityCategoryType.__getValue(record.facilityEvaluation?.category))}`}
                  &nbsp;&nbsp;&nbsp;
                  {`${t('tourEvaluation_rating')}:${record.facilityEvaluation?.comprehensiveRate}`}
                </div>
              ) : null}
              <div>{record.facilityEvaluation?.details}</div>
            </div>
          );
        },
      },
    ] as ColumnsType<any>;
  }
  const cols: ColumnsType<TourEvaluationDto> = [
    {
      width: 60,
      fixed: 'left',
      title: t('No.'),
      dataIndex: 'index',
      shouldCellUpdate: () => false,
      render: v => <b>{v}</b>,
      align: 'center',
    },
    {
      width: 180,
      title: t('Tour Name'),
      dataIndex: 'tourName',
      ellipsis: {
        showTitle: false,
      },
      render: v => <Tooltip title={v}>{v}</Tooltip>,
    },
    {
      width: 120,
      title: t('tourEvaluation_TourArrival'),
      dataIndex: 'tourFromDate',
      render: v => formatDisplayDate(v),
    },
    {
      width: 120,
      title: t('tourEvaluation_TourDeparture'),
      dataIndex: 'tourToDate',
      render: v => {
        return formatDisplayDate(v);
      },
      ellipsis: true,
    },
    {
      width: 100,
      title: t('tourEvaluation_nationality'),
      dataIndex: 'nationality',
      ellipsis: {
        showTitle: false,
      },
      render: v => <Tooltip title={v}>{v}</Tooltip>,
    },
    {
      width: 100,
      title: t('tourEvaluation_area'),
      dataIndex: 'area',
      ellipsis: {
        showTitle: false,
      },
      render: v => {
        let area = TourAvailableAreaType.__getText(v);
        return <Tooltip title={area}>{area}</Tooltip>;
      },
    },
    {
      width: 100,
      title: t('tourEvaluation_agentName'),
      dataIndex: 'agentName',
      ellipsis: {
        showTitle: false,
      },
      render: v => <Tooltip title={v}>{v}</Tooltip>,
    },
    {
      width: 100,
      title: t('tourEvaluation_guideName'),
      dataIndex: 'guideName',
      ellipsis: {
        showTitle: false,
      },
      render: v => <Tooltip title={v}>{v}</Tooltip>,
    },
    {
      width: 120,
      title: t('tourEvaluation_useDate'),
      dataIndex: ['facilityEvaluation', 'useDate'],
      render: v => (v ? formatDisplayDate(v) : null),
    },
    {
      width: 60,
      title: t('tourEvaluation_type'),
      dataIndex: ['facilityEvaluation', 'category'],
      render: v => (v ? t(FacilityCategoryType.__getValue(v)) : null),
    },
    {
      width: 120,
      title: t('facilityReviewFacilityName'),
      dataIndex: ['facilityEvaluation', 'facilityName'],
      ellipsis: {
        showTitle: false,
      },
      render: v => <Tooltip title={v}>{v}</Tooltip>,
    },
    {
      width: 80,
      title: t('tourEvaluation_rating'),
      dataIndex: ['facilityEvaluation', 'comprehensiveRate'],
      align: 'center',
      render: (v, record) => {
        return v ? (
          <b>
            {v}
            <StarFilled style={{ fontSize: 14 }} />
          </b>
        ) : null;
      },
    },
    {
      width: 120,
      title: t('facilityReviewDetail'),
      dataIndex: ['facilityEvaluation', 'details'],
      ellipsis: {
        showTitle: false,
      },
      render: v => <Tooltip title={v}>{v}</Tooltip>,
    },
    {
      width: 140,
      title: t('tourEvaluation_LastModifiedDate'),
      dataIndex: ['facilityEvaluation', 'lastUpdatedAt'],
      render: v => formatFullDate(v),
    },
    {
      title: t('zTable.actions'),
      dataIndex: '_actions',
      width: 70,
      fixed: 'right',
      align: 'center',
      render(_: any, record: any) {
        return (
          <Tooltip title={t('zTable.edit')}>
            <Button
              icon={<EditOutlined />}
              style={{ backgroundColor: '#d6eaf8', borderRadius: 0 }}
              type="ghost"
              size="small"
              onClick={() => {
                onEdit(record);
              }}
            ></Button>
          </Tooltip>
        );
      },
    },
  ];
  return cols;
};
