import { KeyOutlined, ToolOutlined, SaveOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import ZTable, { IColumnType, IFilterItem, ITableRef } from '../../components/ZTable';
import MainContent from '../../Layout/MainContent';
import { VALIDATE_REQUIRED } from '../../constants';
import { ApiSdk } from '../../httpclient';
import { useHistory } from 'react-router';
import { User } from '@/__generated';
import { APP_ROLE, isGuideOnly } from '@/services/roleService';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const PAGE_TITLE = 'ユーザー';

const columns: IColumnType<User>[] = [
  {
    width: 50,
    fixed: 'left',
    title: 'No.',
    dataIndex: 'createdAt',
    render: (v, t, i) => <b>{`${i + 1}`}</b>,
    align: 'center',
    sorter: true,
  },
  {
    title: 'ユーザー名 ',
    dataIndex: 'userName',
    render: v => <b>{v}</b>,
  },
  {
    title: 'フルネーム',
    dataIndex: 'fullName',
  },
  {
    title: '役割',
    dataIndex: 'roleClaims',
    render: (v: string[]) => v?.map(r => <Tag>{<Trans>{r}</Trans>}</Tag>),
  },
  {
    title: 'メール',
    dataIndex: 'email',
  },
  {
    title: 'メール確認済み',
    dataIndex: 'emailConfirmed',
    render: (value: any) => {
      return value ? <Tag color="#108ee9">Yes</Tag> : <Tag color="#f50">No</Tag>;
    },
    align: 'center',
  },
  {
    title: 'ロックアウトが有効',
    dataIndex: 'lockoutEnabled',
    render: (value: any) => {
      return value ? <Tag color="#108ee9">Yes</Tag> : <Tag color="#f50">No</Tag>;
    },
    align: 'center',
  },
];

const filters: Array<IFilterItem> = [
  { type: 'TEXT', name: 'fullName', label: 'フルネーム' },
  { type: 'TEXT', name: 'userName', label: 'ユーザー名' },
  { type: 'TEXT', name: 'email', label: 'メール' },
  {
    type: 'LIST',
    name: 'role',
    label: '役割',
    listData: Object.keys(APP_ROLE).map(r => ({
      label: r,
      value: r,
    })),
  },
];

export default () => {
  const [t] = useTranslation();
  const history = useHistory();
  const zTb = React.useRef<ITableRef>();
  const [form] = Form.useForm();
  const [formVisible, setFormVisible] = useState(false);
  const [isNewForm, setIsNewForm] = useState(false);

  const [roleForm] = Form.useForm();
  const [roles, setRoles] = useState<string[]>([]);
  const [roleFormVisible, setRoleFormVisible] = useState(false);

  const saveUser = values => {
    ApiSdk.UserService.save({ body: values })
      .then(response => {
        if (response && response.success) {
          setFormVisible(false);
          zTb.current?.reload();
        } else {
          if (response.message) {
            alert(response.message);
          }
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  const getRoles = () => {
    ApiSdk.RoleService.getRoleNames().then(res => {
      setRoles(res);
    });
  };

  const updateRoles = values => {
    ApiSdk.UserService.updateRoles({ body: values })
      .then(response => {
        if (response && response.success) {
          setRoleFormVisible(false);
          zTb.current?.reload();
        } else {
          if (response.message) {
            alert(response.message);
          }
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  useEffect(() => {
    getRoles();
  }, []);

  const getExtraButtons = () => {
    let extrabtns = [
      <Button
        key="roles"
        style={{ background: '#f59c1a', color: '#fff' }}
        icon={<KeyOutlined />}
        onClick={() => {
          history.push('/cms/roles');
        }}
      >
        {t('Role')}
      </Button>,
    ];

    return extrabtns;
  };

  return (
    <MainContent title={PAGE_TITLE} extraBtns={getExtraButtons()} icon={<UserOutlined style={{ fontSize: 16 }} />}>
      <Helmet title={PAGE_TITLE} />
      <div className="ant-layout-content-body">
        <ZTable<User>
          columns={columns}
          primaryKey="id"
          tableRef={zTb}
          url="/users/get-users"
          bordered
          order_by="FullName"
          order_by_asc={true}
          allowEdit={true}
          onEdit={record => {
            setIsNewForm(false);
            setFormVisible(true);
            form.setFieldsValue(record);
          }}
          allowDelete={false}
          delete_url="/user"
          filters={filters}
          extraActions={[
            {
              render: record => {
                return isGuideOnly(record.roleClaims) ? null : (
                  <Button
                    size="small"
                    style={{ borderRadius: 0 }}
                    type="primary"
                    icon={<ToolOutlined />}
                    onClick={() => {
                      setRoleFormVisible(true);
                      roleForm.setFieldsValue({ ...record });
                    }}
                  ></Button>
                );
              },
            },
          ]}
        ></ZTable>
        <Modal
          className="nta-custom-form"
          visible={formVisible}
          maskClosable={false}
          width="50%"
          title={isNewForm ? t('RegisterUser') : t('UpdateUser')}
          forceRender={true}
          okText={t('staffSearch_save')}
          okButtonProps={{ icon: <SaveOutlined /> }}
          onCancel={() => {
            form.resetFields();
            setFormVisible(false);
          }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                saveUser(values);
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Form form={form} size="middle" layout="vertical">
            <Form.Item name="id" className="hidden">
              <Input type="hidden" />
            </Form.Item>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label={t('UserName')} name="userName" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input disabled={!isNewForm} className="input-value-color" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label={t('FullName')} name="fullName" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label={t('Email')} name="email" rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Input type="email" disabled className="input-value-color" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label={t('PhoneNumber')} name="phoneNumber">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {isNewForm && (
              <Row gutter={16}>
                <Col md={12}>
                  <Form.Item label={t('Password')} name="password" rules={[{ required: true, message: t('staffSearch_passwordValidation') }]}>
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label={t('ConfirmPassword')}
                    name="confirmPassword"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      { required: true, message: t('staffSearch_passwordValidation') },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(t('staffPasswordNotMatch'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row gutter={16}>
              <Col md={24} xs={24}>
                <Form.Item name="emailConfirmed" label="" valuePropName="checked">
                  <Checkbox>{t('EmailConfirmed')}</Checkbox>
                </Form.Item>
              </Col>

              <Col md={24} xs={24}>
                <Form.Item name="lockoutEnabled" label="" valuePropName="checked">
                  <Checkbox>{t('LockoutEnabled')}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          visible={roleFormVisible}
          maskClosable={false}
          title={t('RoleUpdateTile')}
          className="nta-custom-form"
          forceRender={true}
          okText={t('staffSearch_save')}
          okButtonProps={{ icon: <SaveOutlined /> }}
          onCancel={() => {
            roleForm.resetFields();
            setRoleFormVisible(false);
          }}
          onOk={() => {
            roleForm
              .validateFields()
              .then(values => {
                updateRoles(values);
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Form form={roleForm} size="middle" layout="vertical">
            <Form.Item name="id" className="hidden">
              <Input type="hidden" />
            </Form.Item>
            <Row>
              <Col md={24}>
                <Form.Item name="roleClaims" label={t('Role')} rules={[{ required: true }]}>
                  <Checkbox.Group>
                    <Row>
                      {roles
                        .filter(r => r !== APP_ROLE.Guide)
                        .map((role, index) => (
                          <Col md={24} key={index}>
                            <Checkbox value={role} style={{ lineHeight: '32px' }}>
                              {<Trans>{role}</Trans>}
                            </Checkbox>
                          </Col>
                        ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </MainContent>
  );
};
