import EnumSelect from '@/components/app/formItem/EnumSelect';
import { TourGuideStatusType, BooleanEnumType } from '@/config';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { formatCurrency } from '@/helpers/form';
import { TourGuideDetailModel } from '@/__generated';
import { Row, Col, Space, Checkbox, Input, InputNumber } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import lodash, { findLast } from 'lodash';
import GuideAttachment from './GuideAttachment';
import BooleanEnumInput from '@/components/app/formItem/BooleanEnumInput';
type Props = {
  guide: TourGuideDetailModel;
};

export default function ReadOnlyGuideInfo({ guide }: Props) {
  const { t } = useTranslation();
  const [displayCanceledDate, setDisplayCanceledDate] = React.useState<boolean>(false);

  const businessDays = React.useMemo(() => {
    return lodash.orderBy(guide.businessDays, d => {
      if (d.date) {
        return dayjs(d.date).unix();
      }
      return Number.MAX_VALUE;
    });
  }, [guide]);

  return (
    <div className="guide-info">
      <Row gutter={6} style={{ marginBottom: 5 }}>
        <Col md={8} xs={24}>
          <Row gutter={6}>
            <Col md={6} xs={6} className="form-item-label mobile-row">
              {t('Guide Name')}
            </Col>
            <Col md={18} xs={18}>
              <Input disabled={true} className="input-value-color" value={guide.guideName} />
            </Col>
          </Row>
        </Col>

        <Col md={8} xs={24}>
          <Row gutter={6}>
            <Col md={6} xs={6} className="form-item-label mobile-row">
              {t('Status')}
            </Col>
            <Col md={18} xs={18}>
              <EnumSelect
                enumData={TourGuideStatusType}
                placeholder={t('Status')}
                className="w-100 input-value-color"
                disabled
                value={guide.status}
              />
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24} style={{ textAlign: 'right' }}>
          <Row gutter={6}>
            <Col md={4} xs={6} className="form-item-label ">
              {t('Duration')}
            </Col>
            <Col md={20} xs={18}>
              <Space>
                <Input
                  className="w-100 input-value-color"
                  disabled
                  value={guide?.fromDate ? dayjs(guide?.fromDate).format(DEFAULT_DATE_FORMAT) : ''}
                />
                <label>{' ~ '}</label>
                <Input className="w-100 input-value-color" disabled value={guide?.toDate ? dayjs(guide?.toDate).format(DEFAULT_DATE_FORMAT) : ''} />
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={6} style={{ marginBottom: 5 }}>
        <Col md={8} xs={24}>
          <Row gutter={6}>
            <Col md={6} xs={6} className="form-item-label mobile-row">
              {t('Language')}
            </Col>
            <Col md={18} xs={18}>
              <Input disabled={true} className="input-value-color" value={guide.languageName} />
            </Col>
          </Row>
        </Col>

        <Col md={16} xs={24} style={{ textAlign: 'right', lineHeight: '32px' }}>
          <Checkbox checked={displayCanceledDate} onChange={() => setDisplayCanceledDate(d => !displayCanceledDate)}>
            {t('Display cancelled dates')}
          </Checkbox>
        </Col>
      </Row>

      <div className="business-day-info">
        <div style={{ display: 'inline-flex' }}>
          <div className="title-container">
            <div className="title-item">{t('Business Day')}</div>
            <div className="title-item">{t('Daily Allowance')}</div>
            <div className="title-item">{t('Availability')}</div>
            {/* <div className="title-item">{t('Stay overnight?')}</div>
            <div className="title-item">{t('Pre night?')}</div> */}
          </div>
          <div className="day-container">
            {businessDays.map((day, index) => {
              if (!displayCanceledDate && day.isCancelled && day.isConfirmed) {
                return null;
              }

              let allowPreNight = index === 0;
              if (!allowPreNight) {
                const currentDate = day.date;
                let preDay = findLast(businessDays, (x, i) => !x.isCancelled && i < index);
                if (preDay?.date && currentDate) {
                  allowPreNight = !dayjs(currentDate)
                    .add(-1, 'day')
                    .isSame(preDay?.date, 'day');
                }
              }
              if (day.isCancelled) {
                allowPreNight = false;
              }
              return (
                <div className={`day-item ${day.isCancelled ? ' --canceled' : ''}`} key={day.id}>
                  {day.isCancelled && <div className="canceled-text">{t('Cancelled')}</div>}
                  <Input
                    style={{ marginBottom: 10 }}
                    className="w-100 input-value-color"
                    disabled
                    value={day?.date ? dayjs(day?.date).format(DEFAULT_DATE_FORMAT) : ''}
                  />

                  <InputNumber
                    style={{ marginBottom: 10 }}
                    value={day.fee}
                    className="w-100 input-value-color"
                    min={0}
                    precision={0}
                    formatter={v => formatCurrency(v)}
                    disabled={true}
                  />

                  <BooleanEnumInput style={{ marginBottom: 10 }} value={day.availability} disabled={true} className="input-value-color w-100" />
                  {/* <EnumSelect
                    style={{ marginBottom: 10 }}
                    value={day.stayOvernight}
                    enumData={BooleanEnumType}
                    disabled={true}
                    className="input-value-color w-100"
                  />
                  {allowPreNight && (
                    <EnumSelect
                      style={{ marginBottom: 10 }}
                      value={day.stayLastNight}
                      enumData={BooleanEnumType}
                      disabled={true}
                      className="input-value-color w-100"
                    />
                  )} */}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <GuideAttachment guide={guide} />
    </div>
  );
}
