import { Select, SelectProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends SelectProps<any> {
  enumData: any;
}

export default function EnumSelect({ enumData, ...props }: Props) {
  const { t } = useTranslation();
  return (
    <Select maxTagCount="responsive" allowClear {...props}>
      {Object.keys(enumData)
        .filter(x => !x.startsWith('__'))
        .map((k, i) => {
          return (
            <Select.Option key={i} value={enumData[k]}>
              {enumData.__customLabel ? enumData.__customLabel[k] : t(k)}
            </Select.Option>
          );
        })}
    </Select>
  );
}
