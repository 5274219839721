import EnumSelect from '@/components/app/formItem/EnumSelect';
import DatePicker from '@/components/DatePicker';
import { ReservationForm, TourGuideStatusType, TourPurposeType, TourStatusType } from '@/config';
import { DEFAULT_DISPLAY_DATE_FORMAT, TourSeisanStatus } from '@/constants';
import { useAppContext } from '@/contexts/AppContext';
import { isGuide, isGuideOnly, isGuideSupport } from '@/services/roleService';
import { TourDetailModel } from '@/__generated';
import { Col, Row, Input, Table, Checkbox, Form, Button } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { totalBookedPassengers, totalEstimatedPassengers } from '../validation';
import { MailOutlined } from '@ant-design/icons';
import TourDraftRequest, { TourDraftRequestProps } from '@/pages/TourDraftRequest';
import { useTourContext } from '@/contexts/TourContext';
import tourService from '@/services/tourService';

type Props = {
  tour: TourDetailModel;
  editableField?: string[];
};

const rowTitleStyle: any = {
  float: 'left',
  lineHeight: '32px',
  width: 100,
};
const gutter = 12;
const ESTIMATED_PASSENGERS = [
  { label: 'Adult', name: 'noOfAdultEstimated' },
  { label: 'Child A', name: 'noOfChildAEstimated' },
  { label: 'Child B', name: 'noOfChildBEstimated' },
  { label: 'Infant', name: 'noOfInfantEstimated' },
  { label: 'T/L', name: 'noOfLeadEstimated' },
];
const BOOKED_PASSENGERS = [
  { label: 'Adult', name: 'noOfAdultBooked' },
  { label: 'Child A', name: 'noOfChildABooked' },
  { label: 'Child B', name: 'noOfChildBBooked' },
  { label: 'Infant', name: 'noOfInfantBooked' },
  { label: 'T/L', name: 'noOfLeadBooked' },
];

export default function CreateTourModal({ tour, editableField }: Props) {
  const { t } = useTranslation();
  const { auth } = useAppContext();
  const [tourMessage, setDisplayTourMessage] = React.useState<Omit<TourDraftRequestProps, 'onClose'>>();
  const tourContext = useTourContext();
  const _isGuideOnly = React.useMemo(() => {
    return isGuideOnly(auth?.roleClaims);
  }, [auth]);

  React.useEffect(() => {
    tourContext.setTour(tour);
  }, [tour]);
  const _isGuideSupport = React.useMemo(() => {
    return isGuideSupport(auth?.roleClaims);
  }, [auth]);

  const guideInfo = React.useMemo(() => {
    let guide = tour.tourGuides?.find(x => x);
    return guide
      ? {
          id: guide?.guideId || '',
          fullName: guide.guideName || '',
          status: guide.status,
        }
      : null;
  }, [tour]);
  const displayBookedNoPax = React.useMemo(() => {
    if (!isGuide(auth?.roleClaims)) {
      return true;
    }
    return [
      TourStatusType.Confirmed,
      TourStatusType.ConfirmedAcceptingGuest,
      TourStatusType.ConfirmingBilling,
      TourStatusType.BillingComplete,
    ].includes(tour.status as any);
  }, [auth, tour]);
  return (
    <div>
      <div style={{ marginBottom: 5 }}>
        <Row gutter={gutter}>
          <Col md={2} xs={6} className="form-item-label mobile-row">
            {t('Tour duration')}
          </Col>
          <Col md={3} xs={9}>
            <DatePicker value={tour.fromDate as any} className="w-100 input-value-color" disabled={true} />
          </Col>
          <Col md={3} xs={9}>
            <DatePicker value={tour.toDate as any} className="w-100 input-value-color" disabled={true} />
          </Col>
          <Col md={2} xs={6} className="form-item-label mobile-row">
            {t('Status')}
          </Col>
          <Col md={4} xs={18}>
            <EnumSelect value={tour.status} enumData={TourStatusType} disabled={true} className="input-value-color w-100" />
          </Col>
          <Col md={2} xs={6} className="form-item-label mobile-row">
            {t('Reservation form')}
          </Col>
          <Col md={3} xs={18}>
            <EnumSelect enumData={ReservationForm} disabled={true} className="input-value-color w-100" value={tour.reservationForm} />
          </Col>
          <Col md={2} xs={6} className="form-item-label ">
            {t('Travel purpose')}
          </Col>
          <Col md={3} xs={18}>
            <EnumSelect enumData={TourPurposeType} disabled={true} className="input-value-color w-100" value={tour.tourPurpose} />
          </Col>
        </Row>
      </div>

      <div style={{ marginBottom: 5 }}>
        <Row gutter={gutter}>
          <Col md={2} xs={6} className="form-item-label mobile-row">
            {t('Tour Name')}
          </Col>
          <Col md={12} xs={18}>
            <Input value={tour.tourName} disabled={true} className="input-value-color" />
          </Col>
          <Col md={3} xs={6} className="form-item-label">
            {t('tourDetail_TourNoTourSeisan')}
          </Col>
          <Col md={7} xs={18}>
            <Input value={tour.tourNumber} disabled={true} className="input-value-color" />
          </Col>
        </Row>
      </div>

      <div style={{ marginBottom: 5 }}>
        <Row gutter={gutter}>
          <Col md={2} xs={6} className="form-item-label mobile-row">
            {t('guideSerchnationality')}
          </Col>
          <Col md={6} xs={18}>
            <Input disabled={true} className="input-value-color" value={tour.guestNationalityName} />
          </Col>
          <Col md={2} xs={6} className="form-item-label ">
            {t('Agent')}
          </Col>
          <Col md={4} xs={18}>
            <Input disabled={true} className="input-value-color" value={tour.agentName} />
          </Col>
          <Col md={3} xs={6} className="form-item-label">
            {t('tourDetail_TourSeisanStatus')}
          </Col>
          <Col md={7} xs={18}>
            <Input disabled={true} className="input-value-color" value={TourSeisanStatus.__getLabel(tour.seisanStatus)} />
          </Col>
        </Row>
      </div>
      {/* 3 */}
      <div style={{ marginBottom: 5 }}>
        <Row gutter={gutter}>
          <Col md={8} xs={24}>
            <Row gutter={gutter}>
              <Col md={6} xs={6} className="form-item-label mobile-row">
                {t('Sales')}
              </Col>
              <Col md={18} xs={18}>
                <Input disabled={true} className="input-value-color" value={tour.saleStaffName} />
              </Col>
            </Row>
          </Col>
          <Col md={6} xs={24}>
            <Row gutter={gutter}>
              <Col md={8} xs={6} className="form-item-label mobile-row">
                {t('In charge of tour')}
              </Col>
              <Col md={16} xs={18}>
                <Input disabled={true} className="input-value-color" value={tour.picName} />
              </Col>
            </Row>
          </Col>
          <Col md={3} xs={6} className="form-item-label">
            ツアーID
          </Col>
          <Col md={7} xs={18}>
            <Input value={tour.tourId} disabled={true} className="input-value-color" />
          </Col>
        </Row>
      </div>
      <div style={{ marginBottom: 5 }}>
        <Row gutter={gutter}>
          <Col md={8} xs={24}>
            <Row gutter={gutter}>
              <Col md={6} xs={6} className="form-item-label mobile-row">
                {t('手配担当')}
              </Col>
              <Col md={18} xs={18}>
                <Input disabled={true} className="input-value-color" value={(tour as any).arrangementPicName} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div>
        <Row gutter={0} className="pax-container-5">
          <Col md={24}>
            <Row gutter={gutter} style={{ marginBottom: 5, borderBottom: '2px solid white', paddingBottom: 5 }}>
              <Col md={24}>
                <div style={rowTitleStyle}>{t('Number of Passengers (Estimated)')}:</div>
                <Row gutter={gutter}>
                  {ESTIMATED_PASSENGERS.map(est => {
                    return (
                      <Col md={4} key={est.name}>
                        <Row gutter={gutter} className="mobile-row">
                          <Col md={8} xs={8} className="form-item-label" style={{ width: 40 }}>
                            {t(est.label)}
                          </Col>
                          <Col md={16} xs={16}>
                            <Input disabled={true} className="input-value-color" value={tour[est.name]} />
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                  <Col md={4}>
                    <Row gutter={gutter} className="mobile-row">
                      <Col xs={8} md={8} className="form-item-label" style={{ width: 40 }}>
                        {t('Total')}
                      </Col>
                      <Col md={16} xs={16}>
                        <Input disabled={true} className="input-value-color" value={totalEstimatedPassengers(tour)} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {displayBookedNoPax && (
              <Row>
                <Col md={24}>
                  <div style={rowTitleStyle}>{t('Number of Passenger')}:</div>
                  <Row gutter={gutter} style={{ marginBottom: 5 }}>
                    {BOOKED_PASSENGERS.map(est => {
                      return (
                        <Col md={4} key={est.name}>
                          <Row gutter={gutter} key={est.name} className="mobile-row">
                            <Col md={8} xs={8} className="form-item-label" style={{ width: 40 }}>
                              {t(est.label)}
                            </Col>
                            <Col md={16} xs={16}>
                              <Input disabled={true} className="input-value-color" value={tour[est.name]} />
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                    <Col md={4}>
                      <Row gutter={gutter} className="mobile-row">
                        <Col xs={8} md={8} className="form-item-label" style={{ width: 40 }}>
                          {t('Total')}
                        </Col>
                        <Col xs={16} md={16}>
                          <Input disabled={true} className="input-value-color" value={totalBookedPassengers(tour)} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
      <Row style={{ marginTop: 5 }}>
        <Col xs={0} md={18}>
          <Table
            bordered
            size="small"
            pagination={false}
            columns={[
              {
                title: t('Guide Name'),
                dataIndex: 'name',
              },
              {
                title: t('Language'),
                dataIndex: 'languageName',
              },
              {
                title: t('Status'),
                dataIndex: 'status',
                render: v => t(TourGuideStatusType.__getValue(v)),
              },
              {
                title: t('Duration'),
                dataIndex: 'fromDate',
                render: (fromDate, { toDate, guideId, name, status }) => {
                  const _fromDate = fromDate ? dayjs(fromDate).format(DEFAULT_DISPLAY_DATE_FORMAT) : '';
                  const _toDate = toDate ? dayjs(toDate).format(DEFAULT_DISPLAY_DATE_FORMAT) : '';
                  return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>{`${_fromDate || ''} ~ ${_toDate || ''}`}</span>
                      {_isGuideSupport && tourService.displayEmailEFaxBtn(tour) ? (
                        <Button
                          size="small"
                          type="primary"
                          icon={<MailOutlined />}
                          onClick={() => {
                            setDisplayTourMessage({
                              guideId: guideId || '',
                              guideName: name || '',
                              seisanNumber: tour.tourId || '',
                              tourId: tour.id || '',
                              tourName: tour.tourName || '',
                            });
                          }}
                        >
                          {t('tourMessagePage_SendFax')}
                        </Button>
                      ) : null}
                    </div>
                  );
                },
              },
            ]}
            dataSource={tour.gridTourGuides}
          ></Table>
        </Col>
        {_isGuideOnly && tour && guideInfo && tourService.displayEmailEFaxBtn(tour) ? (
          <Col md={6} style={{ textAlign: 'right', marginBottom: 10 }} xs={24}>
            <Button
              type="primary"
              icon={<MailOutlined />}
              onClick={() => {
                setDisplayTourMessage({
                  guideId: guideInfo.id,
                  guideName: guideInfo.fullName,
                  seisanNumber: tour.tourId || '',
                  tourId: tour.id || '',
                  tourName: tour.tourName || '',
                });
              }}
            >
              {t('tourMessagePage_SendFax')}
            </Button>
          </Col>
        ) : (
          <Col md={6} style={{ textAlign: 'right', marginBottom: 10 }} xs={0}>
            {editableField?.includes('hideGuides') ? (
              <Form.Item name="hideGuides" valuePropName="checked">
                <Checkbox>{t('hideOtherGuideInfo')} </Checkbox>
              </Form.Item>
            ) : (
              <div>
                <Checkbox checked={tour.hideGuides} disabled>
                  <span className="input-value-color"> {t('hideOtherGuideInfo')}</span>
                </Checkbox>
              </div>
            )}
          </Col>
        )}
      </Row>

      {tourMessage ? (
        <TourDraftRequest
          guideId={tourMessage.guideId}
          tourName={tourMessage.tourName || ''}
          seisanNumber={tourMessage.seisanNumber || ''}
          tourId={tourMessage.tourId || ''}
          guideName={tourMessage.guideName}
          onClose={() => {
            setDisplayTourMessage(undefined);
          }}
        />
      ) : null}
    </div>
  );
}
