import { Result } from 'antd';
import React from 'react';

type Props = {};

export default function Page403({}: Props) {
  return (
    <div>
      <Result status="403" title="403" subTitle="Sorry, you are not authorized to access this page." />,
    </div>
  );
}
