import { TourDetailModel, TourGuideDetailModel } from '@/__generated';
import { Row, Col, Input, Modal, Button, InputNumber, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { createNtaCommentsHtml } from '@/helpers/string';
import { ShortTourGuideInfo } from '../../TourFeedback/TourEvaluation';
import GuideSeisanButton from '../GuideSeisan/GuideSeisanButton';
import { useTourContext } from '@/contexts/TourContext';
import tourService from '@/services/tourService';
type Props = {
  guide: TourGuideDetailModel;
  tour?: TourDetailModel | null | undefined;
  showGuideEvaluation?: (shortGuideInfo: ShortTourGuideInfo) => void;
};
const gutter = 8;
const labeCol = {
  md: 11,
  xs: 24,
};
const dateCol = {
  md: 8,
  xs: 14,
};
const timeCol = {
  md: 5,
  xs: 10,
};
export default function ReadOnlyGuideOtherInfo({ guide, tour, showGuideEvaluation }: Props) {
  const { t } = useTranslation();
  const tourContext = useTourContext();

  const comments = React.useMemo(() => {
    if (guide?.ntaComments) {
      return orderBy(guide?.ntaComments, x => moment(x.createdAt), 'desc');
    }
    return [];
  }, [guide]);
  const amountCarrying = React.useMemo(() => {
    if (tourContext.tour && guide) {
      return tourContext.tour.tourGuides?.find(x => x.id === guide.id)?.seisanAmountCarrying || 0;
    }
    return 0;
  }, [tourContext.tour, guide]);
  return (
    <div>
      {/* <Row gutter={6} style={{ marginBottom: 5, marginTop: 10 }}>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col md={11} xs={24} className="form-item-label mobile-row">
              {t('Briefing')}
            </Col>
            <Col md={13} xs={24}>
              <EnumSelect enumData={BriefingType} className="w-100 input-value-color" disabled value={guide.briefingType} />
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={gutter}>
            <Col {...labeCol} className="form-item-label mobile-row">
              {t('Briefing Date And Time')}
            </Col>
            <Col {...dateCol}>
              <Input className="w-100 input-value-color" disabled value={formatDisplayDate(guide.briefingDate)} />
            </Col>
            <Col {...timeCol}>
              <Input className="w-100 input-value-color" disabled value={timeOnly2String(guide.briefingTime) || ''} />
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={6}>
            <Col md={11} xs={24} className="form-item-label">
              {t('Expected Date Of Arrival Of Materials')}
            </Col>
            <Col md={13} xs={24}>
              <Input className="w-100 input-value-color" disabled value={formatDisplayDate(guide?.expectedDateOfMaterials)} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={6} style={{ marginBottom: 5 }}>
        <Col md={8} xs={24}>
          <Row gutter={6}>
            <Col {...labeCol} className="form-item-label mobile-row">
              {t('Briefing desired day 1')}
            </Col>
            <Col {...dateCol}>
              <Input className="w-100 input-value-color" disabled value={formatDisplayDate(guide.desiredBriefingDate1)} />
            </Col>
            <Col {...timeCol}>
              <Input className="w-100 input-value-color" disabled value={guide.desiredBriefingTime1} />
            </Col>
          </Row>
        </Col>

        <Col md={8} xs={24}>
          <Row gutter={6}>
            <Col {...labeCol} className="form-item-label mobile-row">
              {t('Briefing desired date 2')}
            </Col>
            <Col {...dateCol}>
              <Input className="w-100 input-value-color" disabled value={formatDisplayDate(guide.desiredBriefingDate2)} />
            </Col>
            <Col {...timeCol}>
              <Input className="w-100 input-value-color" disabled value={guide.desiredBriefingTime2} />
            </Col>
          </Row>
        </Col>
        <Col md={8}>
          <Row gutter={6}>
            <Col {...labeCol} className="form-item-label ">
              {t('Briefing desired date 3')}
            </Col>
            <Col {...dateCol}>
              <Input className="w-100 input-value-color" disabled value={formatDisplayDate(guide.desiredBriefingDate3)} />
            </Col>
            <Col {...timeCol}>
              <Input className="w-100 input-value-color" disabled value={guide.desiredBriefingTime3} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={6} style={{ marginBottom: 5 }}>
        <Col md={8} xs={24}>
          <Row gutter={6}>
            <Col {...labeCol} className="form-item-label mobile-row">
              {t('Material arrival desired date and time 1')}
            </Col>
            <Col {...dateCol}>
              <Input className="w-100 input-value-color" disabled value={formatDisplayDate(guide.desiredDateOfMaterial1)} />
            </Col>
            <Col {...timeCol}>
              <Input className="w-100 input-value-color" disabled value={guide.desiredTimeOfMaterial1} />
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={6}>
            <Col {...labeCol} className="form-item-label mobile-row">
              {t('Material arrival desired date and time 2')}
            </Col>
            <Col {...dateCol}>
              <Input className="w-100 input-value-color" disabled value={formatDisplayDate(guide.desiredDateOfMaterial2)} />
            </Col>
            <Col {...timeCol}>
              <Input className="w-100 input-value-color" disabled value={guide.desiredTimeOfMaterial2} />
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={6}>
            <Col {...labeCol} className="form-item-label ">
              {t('Material arrival desired date and time 3')}
            </Col>
            <Col {...dateCol}>
              <Input className="w-100 input-value-color" disabled value={formatDisplayDate(guide.desiredDateOfMaterial3)} />
            </Col>
            <Col {...timeCol}>
              <Input className="w-100 input-value-color" disabled value={guide.desiredTimeOfMaterial3} />
            </Col>
          </Row>
        </Col>
      </Row> */}

      <Row gutter={gutter}>
        <Col flex={'175px'} xs={24}>
          {t('Contact Items From NTA')}
          {comments?.length ? (
            <div
              style={{ fontSize: 12, marginTop: 5 }}
              className="span-link"
              onClick={() => {
                Modal.info({
                  title: t('Contact Items From NTA'),
                  width: 700,
                  content: <div dangerouslySetInnerHTML={{ __html: createNtaCommentsHtml(comments) }} style={{ maxHeight: 600, overflow: 'auto' }} />,
                });
              }}
            >
              <InfoCircleOutlined style={{ marginRight: 5 }} />
              {t('Show previous comments')}
            </div>
          ) : null}
        </Col>
        <Col xs={24} flex="auto">
          <Input.TextArea disabled={true} value={comments?.length ? comments[0].comment : ''} className="input-value-color" rows={5} />
        </Col>
      </Row>
      {guide && (
        <Row gutter={gutter} style={{ marginTop: 5 }}>
          <Col flex={'175px'} xs={24} className="form-item-label">
            {t('Notes for Guides')}
          </Col>
          <Col xs={24} flex="auto">
            <Input.TextArea disabled={true} className="input-value-color" value={guide.guideComments} rows={5} />
          </Col>
        </Row>
      )}
      {/* <Row gutter={gutter} style={{ marginTop: 5 }}>
        <Col flex={'175px'} xs={24} className="form-item-label">
          {t('tourDetail_TourSeisanAmountCarrying')}
        </Col>
        <Col xs={24} flex="auto">
          <InputNumber disabled={true} className="input-value-color" value={amountCarrying} />
        </Col>
      </Row> */}
      <div style={{ marginTop: 10, textAlign: 'end' }}>
        <Space>
          {tour && guide && guide.isRequested && showGuideEvaluation && tourService.displayFeedbackBtn(guide.status) ? (
            <Button
              onClick={() => {
                showGuideEvaluation({
                  guideId: guide.id || '',
                });
              }}
              icon={<PlusOutlined />}
              style={{
                border: 'none',
                backgroundColor: '#FFBDE9',
                color: 'white',
              }}
            >
              {t('feedback')}
            </Button>
          ) : null}
          <GuideSeisanButton tour={tour} tourGuide={guide} />
        </Space>
      </div>
    </div>
  );
}
