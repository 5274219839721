import { PrefecturesType } from '@/config';
import { Select, SelectProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends SelectProps<any> {}

export default function PrefectureInput({ ...props }: Props) {
  const { i18n } = useTranslation();
  return (
    <Select
      maxTagCount="responsive"
      allowClear
      {...props}
      filterOption={(input, option) => {
        return (option?.children as string)?.toLowerCase().includes(input.toLowerCase());
      }}
      showSearch={true}
    >
      {Object.keys(PrefecturesType).map((k, i) => {
        return (
          <Select.Option key={i} value={k}>
            {i18n.language === 'ja' ? PrefecturesType[k] : k}
          </Select.Option>
        );
      })}
    </Select>
  );
}
