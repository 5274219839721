import { MailOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import ZTable, { ITableRef } from '../../../components/ZTable';
import MainContent from '../../../Layout/MainContent';
import { VALIDATE_REQUIRED } from '../../../constants';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';
import { ApiSdk } from '../../../httpclient';

export default () => {
  const [t] = useTranslation();
  const zTb = React.useRef<ITableRef>();
  const [form] = Form.useForm();
  const [formVisible, setFormVisible] = useState(false);
  const [isNewForm, setIsNewForm] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState<{ [key: string]: string }>({});

  const getEmailTemplateNames = () => {
    ApiSdk.EmailTemplateService.emailTemplateNames()
      .then(response => {
        let _dict = {};
        response.forEach(d => {
          _dict[d.key || ''] = d.name;
        });
        setEmailTemplates(_dict);
      })
      .catch(error => {
        alert(error);
      });
  };

  const createEmailTemplate = values => {
    ApiSdk.EmailTemplateService.create({ body: values })
      .then(response => {
        if (response?.success) {
          setFormVisible(false);
          zTb.current?.reload();
        } else {
          if (response.message) {
            alert(response.message);
          }
        }
      })
      .catch(error => {
        alert(error);
      });
  };
  const columns = React.useMemo(() => {
    return [
      {
        title: 'No.',
        width: 50,
        align: 'center',
        render: (v, r, i) => {
          return <b>{i + 1}</b>;
        },
      },
      {
        title: t('NameEmailTemplate'),
        dataIndex: 'name',
        render: v => emailTemplates[v] || '',
      },
      {
        title: t('Subject'),
        dataIndex: 'subject',
      },

      {
        title: t('Attachment'),
        dataIndex: 'includeAttachment',
        render: (value: any) => {
          return value ? <Tag color="#108ee9">Yes</Tag> : <Tag color="#f50">No</Tag>;
        },
      },
      {
        title: t('staffSearch_active'),
        dataIndex: 'enabled',
        render: (value: any) => {
          return value ? <Tag color="#108ee9">Yes</Tag> : <Tag color="#f50">No</Tag>;
        },
      },
    ];
  }, [emailTemplates]);

  const updateEmailTemplate = values => {
    ApiSdk.EmailTemplateService.update({ body: values })
      .then(response => {
        if (response?.success) {
          setFormVisible(false);
          zTb.current?.reload();
        } else {
          if (response.message) {
            alert(response.message);
          }
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  useEffect(() => {
    getEmailTemplateNames();
  }, []);

  return (
    <MainContent
      title={'メールテンプレート管理'}
      extraBtns={[
        <Button
          key="create"
          type="primary"
          className="nta-create-btn"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            form.resetFields();
            setIsNewForm(true);
            setFormVisible(true);
          }}
        >
          {t('guideSearch_new')}
        </Button>,
      ]}
      icon={<MailOutlined style={{ fontSize: 16 }} />}
    >
      <div className="ant-layout-content-body">
        <ZTable
          tableRef={zTb}
          columns={columns}
          primaryKey="id"
          url="/email-templates/get-email-templates"
          bordered
          order_by="Name"
          order_by_asc={true}
          allowEdit={true}
          onEdit={record => {
            form.resetFields();
            setIsNewForm(false);
            setFormVisible(true);
            form.setFieldsValue(record);
          }}
          allowDelete={true}
          delete_url="/email-templates/delete"
        ></ZTable>
        <Modal
          className="nta-custom-form"
          visible={formVisible}
          maskClosable={false}
          style={{ top: 10 }}
          title={isNewForm ? t('CreateEmailTemplate') : t('UpdateEmailTemplate')}
          forceRender={true}
          okText={t('staffSearch_save')}
          width={1300}
          okButtonProps={{ icon: <SaveOutlined />, className: 'save-btn' }}
          onCancel={() => {
            form.resetFields();
            setFormVisible(false);
          }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                if (values.id) {
                  updateEmailTemplate(values);
                } else {
                  createEmailTemplate(values);
                }
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Form form={form} size="middle" layout="vertical">
            <Form.Item name="id" className="hidden">
              <Input type="hidden" />
            </Form.Item>
            <Row gutter={16}>
              <Col md={10}>
                <Form.Item name="name" label={t('NameEmailTemplate')} rules={[{ required: true, message: VALIDATE_REQUIRED }]}>
                  <Select placeholder={t('NameEmailTemplatePlaceholder')}>
                    {Object.keys(emailTemplates).map(k => (
                      <Select.Option key={k} value={k || ''}>
                        {emailTemplates[k]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="toEmails" label={t('ToEmail')}>
                  <Input />
                </Form.Item>
                <Form.Item name="ccEmails" label={t('CcEmail')}>
                  <Input />
                </Form.Item>
                <Form.Item name="bccEmails" label={t('BccEmail')}>
                  <Input />
                </Form.Item>
                <Space>
                  <Form.Item name="includeAttachment" valuePropName="checked">
                    <Checkbox>{t('Attachment')}</Checkbox>
                  </Form.Item>
                  <Form.Item name="enabled" valuePropName="checked">
                    <Checkbox>{t('staffSearch_active')}</Checkbox>
                  </Form.Item>
                </Space>
              </Col>
              <Col md={14}>
                <Form.Item name="subject" label={t('Subject')}>
                  <Input />
                </Form.Item>
                <Form.Item name="body" label={t('Body')}>
                  <TextArea rows={20} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </MainContent>
  );
};
