import { fileService } from '@/services/fileService';
import { Button, Typography } from 'antd';
import React from 'react';

type Props = {
  fileName: string | null | undefined;
  fileId: string | null | undefined;
  onDownloadClientFile?: () => void;
  loading?: boolean;
  className?: string;
};

export default function FileDownload({ fileName, fileId, onDownloadClientFile, loading, className }: Props) {
  const downloadFile = (fileId: string | undefined | null, fileName: string | undefined | null) => {
    fileService.downloadFileById(fileId || '', null, fileName);
  };

  return (
    <Button
      className={className}
      style={{ width: '100%' }}
      type="link"
      loading={loading}
      onClick={() => {
        if (fileId) {
          downloadFile(fileId, fileName);
        } else if (onDownloadClientFile) {
          onDownloadClientFile();
        }
      }}
    >
      <Typography.Text ellipsis={true} style={{ color: '#1890ff' }}>
        {fileName}
      </Typography.Text>
    </Button>
  );
}
