import ApiAccessLogs from './pages/ApiAccessLogs';
import AuditLogs from './pages/AuditLogs';
import Calendars from './pages/Calendars';
import Department from './pages/Department';
import GuidesProfile from './pages/GuidesProfile';
import GuidesSchedule from './pages/GuidesSchedule';
import Countries from './pages/MasterData/Countries';
import Holidays from './pages/MasterData/Holidays';
import Languages from './pages/MasterData/Languages';
import Facilities from './pages/MasterData/Facility';

import Notifications from './pages/Notifications';
import Staffs from './pages/Staffs';
import SubDivision from './pages/SubDivision';
import EmailTemplates from './pages/SystemSettings/EmailTemplates';
import EmailMessages from './pages/EmailMessages';

import Tours from './pages/Tours';
import Roles from './pages/Users/Roles';
import SmtpSetting from './pages/SystemSettings/SmtpSettings';
import Users from './pages/Users';
import TourFeedback from './pages/TourFeedback';

//
import { APP_ROLE } from './services/roleService';
import TourMessagePage from './pages/TourMessage';
import GuideSeisanListing from './pages/GuideSeisanListing';

// -------------
const ROLES_ADMIN = [APP_ROLE.SuperAdmin, APP_ROLE.Admin];
const ROLES_STAFF = [APP_ROLE.ReferenceUser, APP_ROLE.RegularUser, APP_ROLE.SupervisorUser, APP_ROLE.Assign];
type RouteType = {
  path: string;
  component: any;
  exact: true;
  roles?: APP_ROLE[] | '*';
};
// ------------------------------------------------------

const routes: Array<RouteType> = [
  { path: '/cms/tours', component: Tours, exact: true, roles: '*' },
  { path: '/cms/guides-schedule', component: GuidesSchedule, exact: true, roles: [...ROLES_ADMIN, ...ROLES_STAFF] },
  { path: '/cms/calendars', component: Calendars, exact: true, roles: '*' },
  {
    path: '/cms/staffs',
    component: Staffs,
    exact: true,
    roles: [...ROLES_ADMIN, ...ROLES_STAFF],
  },
  { path: '/cms/notifications', component: Notifications, exact: true, roles: [...ROLES_ADMIN, ...ROLES_STAFF] },

  {
    path: '/cms/guides-profile',
    component: GuidesProfile,
    exact: true,
    roles: [...ROLES_ADMIN, ...ROLES_STAFF],
  },

  {
    path: '/cms/tour-feedbacks',
    component: TourFeedback,
    exact: true,
    roles: '*',
  },
  {
    path: '/cms/tour-messages',
    component: TourMessagePage,
    exact: true,
    roles: '*',
  },
  {
    path: '/cms/guide-seisans',
    component: GuideSeisanListing,
    exact: true,
    roles: '*',
  },
  // admin
  { path: '/cms/master-data/languages', component: Languages, exact: true, roles: ROLES_ADMIN },
  { path: '/cms/master-data/countries', component: Countries, exact: true, roles: ROLES_ADMIN },
  { path: '/cms/master-data/holidays', component: Holidays, exact: true, roles: ROLES_ADMIN },
  { path: '/cms/master-data/department', component: Department, exact: true, roles: ROLES_ADMIN },
  { path: '/cms/master-data/subdivision', component: SubDivision, exact: true, roles: ROLES_ADMIN },
  { path: '/cms/master-data/facilities', component: Facilities, exact: true, roles: ROLES_ADMIN },

  { path: '/cms/email-templates', component: EmailTemplates, exact: true, roles: ROLES_ADMIN },
  { path: '/cms/email-messages', component: EmailMessages, exact: true, roles: ROLES_ADMIN },
  { path: '/cms/audit-logs', component: AuditLogs, exact: true, roles: ROLES_ADMIN },
  { path: '/cms/api-access-logs', component: ApiAccessLogs, exact: true, roles: ROLES_ADMIN },

  // supper admin
  { path: '/cms/smtp-settings', component: SmtpSetting, exact: true, roles: [APP_ROLE.SuperAdmin] },
  { path: '/cms/users', component: Users, exact: true, roles: [APP_ROLE.SuperAdmin] },
  { path: '/cms/roles', component: Roles, exact: true, roles: [APP_ROLE.SuperAdmin] },
];

export default routes;
