export const COLUMNS_CONFIG_KEY = 'COLUMNS_CONFIG_KEY';
export const getColConfig = (_path: string, key?: string): number[] | null => {
  const path = _path + key || '';
  try {
    const configString = localStorage.getItem(COLUMNS_CONFIG_KEY);
    if (configString) {
      const config = JSON.parse(configString);
      if (config && config[path]) return config[path].split(',').map(e => parseInt(e));
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const saveColConfig = (_path: string, items: number[] | null, key?: string) => {
  let config = {};
  const path = _path + key || '';
  try {
    const configString = localStorage.getItem(COLUMNS_CONFIG_KEY) || '';
    config = JSON.parse(configString) || {};
  } catch {}
  if (!items) {
    delete config[path];
  } else {
    config[path] = items.join(',');
  }
  localStorage.setItem(COLUMNS_CONFIG_KEY, JSON.stringify(config));
};
